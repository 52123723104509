import React, { useContext } from 'react'
import { Group } from '@visx/group'
import { Zoom } from '@visx/zoom'
import { ParentSize } from '@visx/responsive'

import colors from 'lib/colors'
import { AddIconSolid, MinusIcon, RefreshIconSolid } from 'components/icons'
import IconButton from 'components/buttons/icon'
import { FlowChartTheme } from './hooks/flow-chart-context'
import Node from './node'
import Edge from './edge'

import styles from './styles.module.less'

const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 100,
  translateY: 40,
  skewX: 0,
  skewY: 0,
}

const FlowChart = React.memo((props: {
  graph: any,
  handleNodeClick: any,
  selected: boolean
}) => {
  const { graph, handleNodeClick, selected } = props
  const theme = useContext(FlowChartTheme)
  const margin = 100
  const marginTop = 50
  const width = graph.graph().width + margin * 2
  const height = graph.graph().height + marginTop * 2

  if (!width || !height) return null

  return (
    <ParentSize>
      {({ width: containerWidth }) =>
        <Zoom<SVGSVGElement>
          width={width}
          height={height}
          scaleXMin={1 / 2}
          scaleXMax={4}
          scaleYMin={1 / 2}
          scaleYMax={4}
          initialTransformMatrix={initialTransform}
          wheelDelta={() => ({ scaleX: 1, scaleY: 1 })}
        >
          {(zoom) => (
            <div className={styles.relative}>
              <svg
                width={containerWidth}
                height={'100%'}
                viewBox={`0 0 ${width} ${height}`}
                style={{ touchAction: 'none', cursor: zoom.isDragging ? 'grabbing' : 'grab' }}
                ref={zoom.containerRef}
              >
                <defs>
                  <marker id="arrowhead" markerWidth="6" markerHeight="5"
                    refX="5" refY="2.5" orient="auto">
                    <polygon points="0 0, 6 2.5, 0 5" fill={colors('chart', 'primary')} />
                  </marker>
                </defs>

                <rect width={width} height={height} rx={2} fill={theme.background} />
                <Group
                  transform={zoom.toString()}
                  onTouchStart={zoom.dragStart}
                  onTouchMove={zoom.dragMove}
                  onTouchEnd={zoom.dragEnd}
                  onMouseDown={zoom.dragStart}
                  onMouseMove={zoom.dragMove}
                  onMouseUp={zoom.dragEnd}
                  onMouseLeave={() => {
                    if (zoom.isDragging) zoom.dragEnd()
                  }}
                  onClick={() => { }} // click event propagates down to Node component
                >
                  {graph.nodes().map((id, index) => {
                    const node = graph.node(id)
                    return (
                      <Node key={index} node={node} handleNodeClick={handleNodeClick} selected={id === selected} />
                    )
                  })}
                  {graph.edges().map((id, index) => {
                    const edge = graph.edge(id)
                    const fromNode = graph.node(id.v)
                    const toNode = graph.node(id.w)
                    return (
                      <Edge key={index} edge={edge} to={toNode} />
                    )
                  })}
                </Group>
              </svg>
              <div className={styles.controls}>
                <IconButton
                  icon={<AddIconSolid />}
                  className={styles.button}
                  onClick={() => zoom.scale({ scaleX: 1.2, scaleY: 1.2 })}
                />
                <IconButton
                  icon={<MinusIcon />}
                  className={styles.button}
                  onClick={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })}
                />
                <IconButton
                  icon={<RefreshIconSolid />}
                  className={styles.button}
                  onClick={zoom.reset} />
              </div>

            </div>
          )}
        </Zoom>}
    </ParentSize>
  )
})

export default FlowChart
