import regionGroups from './regions.json'

export const REGIONS = Object.entries(regionGroups).reduce((acc, [area, regions]) => {
  const formatted = Object.entries(regions).map(([region, name]) => ({
    region,
    name,
    area
  }))
  return [...acc, ...formatted]
}, [])
