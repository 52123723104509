import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { isEmpty } from 'lodash'

import LoadingDots from 'components/loading-dots/index'
import { displayPaymentProblems } from 'components/notification'
import { ROUTES } from 'containers/routes'
import { useOrganizationsQuery, useUserQuery } from 'hooks/api'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import AuthorizedRoutes from './authorized'
import UnauthorizedRoutes from './unauthorized'
import Analytics from 'lib/analytics'

const Routes = () => {
  const history = useHistory()
  const location = useLocation()

  const { authenticated, session, initialUserLoading } = useUser()
  const { organization: currentOrganization, account: currentAccount } = useOrganization()
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizationsQuery()

  // const currentAccountNotMatching = !isEmpty(organizations) && isEmpty(findById(organizations, currentAccount?.id))
  const organizationChecked = !(initialUserLoading || isLoadingOrganizations)

  const { data: user, isLoading: isLoadingUser } = useUserQuery(!isEmpty(organizations) && organizationChecked)

  // Analytics identify
  const analyticsIdentify = () => {
    if (!authenticated || isLoadingOrganizations || !user?.id) return
    Analytics.identify(user, currentAccount, currentOrganization)
  }
  const analyticsIdentifyDependencies = [
    user?.id, currentOrganization?.id, currentAccount?.id, authenticated, isLoadingOrganizations
  ]

  // Analytics page
  const analyticsPage = () => {
    if (initialUserLoading || isLoadingOrganizations || isLoadingUser) return
    Analytics.page(location.pathname)
  }
  const analyticsPageDependencies = [
    location.pathname, initialUserLoading, isLoadingOrganizations, isLoadingUser
  ]

  // Onboarding
  const initializeOnboarding = () => {
    if (!session || isLoadingOrganizations || !currentOrganization || currentOrganization?.onboarded) return

    const matchablePaths = [
      '/auth/connect-aws/:token',
      '/onboarding/:token'
    ]

    const match = matchablePaths
      .map(path => matchPath(location?.pathname, { path }))
      .find(match => match?.params?.token)

    if (match) {
      history.push(`/onboarding/${match.params.token}`)
      return
    }

    history.push('/onboarding')
  }
  const initializeOnboardingDependencies = [currentOrganization, session]

  // Google Analytics
  const initializeGA = () => {
    ReactGA.initialize('UA-93410800-1')
  }

  // Payment problems
  const checkPaymentProblems = () => {
    if (!currentOrganization || !currentOrganization.paymentProblems) return

    displayPaymentProblems({ onClick: () => history.push(ROUTES.settings.subscriptions.path) })
  }
  const checkPaymentProblemsDependencies = [currentOrganization && currentOrganization.paymentProblems]

  useEffect(analyticsIdentify, analyticsIdentifyDependencies)
  useEffect(analyticsPage, analyticsPageDependencies)
  useEffect(initializeOnboarding, initializeOnboardingDependencies)
  useEffect(initializeGA, [])
  useEffect(checkPaymentProblems, checkPaymentProblemsDependencies)

  if (!organizationChecked) return <LoadingDots />
  if (!authenticated) return <UnauthorizedRoutes />

  return <AuthorizedRoutes />
}

export default Routes
