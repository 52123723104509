import React from 'react'
import { GlobeIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

const Region = ({ reg }) => {
  return <div><GlobeIcon className={styles.icon} />{reg}</div>
}

export default Region
