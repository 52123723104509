import React from 'react'
import { Col, Row } from 'antd'
import { chunk } from 'lodash'
import { GlobeAmericaIcon, GlobeEuropeIcon, GlobeAsiaIcon } from 'components/icons'

import RegionButton from './region-button'
import styles from './styles.module.less'

const AREAS = {
  america: { title: 'North America / South America', icon: <GlobeAmericaIcon size='lg' /> },
  europe: { title: 'Europe / Middle East / Africa', icon: <GlobeEuropeIcon size='lg' /> },
  asia: { title: 'Asia Pacific', icon: <GlobeAsiaIcon size='lg' /> }
}

const AreaColumn = ({ updating, area, regions, errors, handleChange }) => {
  const groupedRegions = chunk(regions, 2)

  return (
    <Col span={8} className={styles.column}>
      <div className={styles.title}>
        {AREAS[area].icon}
        <h3>{AREAS[area].title}</h3>
      </div>

      {
        groupedRegions.map(([region1, region2], i) => (
          <Row key={i} type='flex' justify='center'>
            <RegionButton region={region1} updating={updating} errors={errors} handleChange={handleChange} />
            <RegionButton region={region2} updating={updating} errors={errors} handleChange={handleChange} />
          </Row>
        ))
      }
    </Col>
  )
}

export default AreaColumn
