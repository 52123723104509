import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Lambda from './lambda'
import RequestContent from './appsync-request'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { TraceContext, TraceContextProvider } from './x-ray/hooks/trace-context'

import styles from './styles.module.less'

const InvocationDetails = props => {
  const { type } = props
  const { invocationHash } = useParams()

  switch (type) {
    case INVENTORY_SERVICES.Lambda.id:
      return <Lambda {...props} hash={props.hash ? props.hash : invocationHash} />
    case INVENTORY_SERVICES.AppSyncGraphQLApi.id:
      return <RequestContent {...props} hash={props.hash ? props.hash : invocationHash} />
    default:
      return <div>Not implemented</div>
  }
}

const DetailsWrapper = (props) => {
  const { invocationHash } = useParams()

  const { setTooltipData } = useContext(TraceContext)

  useEffect(() => {
    setTooltipData(null)
  }, [props?.hash, invocationHash])

  return (
    <div className={styles.wrapper}>
      <InvocationDetails {...props} />
    </div>
  )
}

const ResponsiveDrawer = (props) => {
  return (
    <TraceContextProvider>
      <DetailsWrapper {...props} />
    </TraceContextProvider>)
}

export default ResponsiveDrawer
