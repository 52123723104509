import React from 'react'
import { useSelector } from 'react-redux'
import { get, sum } from 'lodash'

import CurrentBillingPeriod from 'components/billing/stripe/current-period'

import { GET_SUBSCRIPTIONS } from 'actions/billing.actions'
import getBillingPeriod from './period-from-subscription'
import { useOrgResourcesUsageSummaryQuery, useOrgIngestionSummaryQuery } from 'hooks/api'

const prepareUsageData = (subscriptions, usage) => {
  const reservedInventory = get(subscriptions, 'reserved.items.inventory.quantity', 0)
  const reservedDataIngestion = get(subscriptions, 'reserved.items.dataIngestion.quantity', 0)
  const onDemandInventory = get(usage, 'inventory', 0)
  const onDemandDataIngestion = get(usage, 'dataIngestion', 0)

  const currentResources = onDemandInventory < reservedInventory ? 0 : onDemandInventory - reservedInventory
  const currentDataIngestion = onDemandDataIngestion < reservedDataIngestion ? 0 : onDemandDataIngestion - reservedDataIngestion
  const usedReservedInventory = reservedInventory <= onDemandInventory ? reservedInventory : onDemandInventory
  const usedReservedDataIngestion = reservedDataIngestion <= onDemandDataIngestion ? reservedDataIngestion : onDemandDataIngestion
  return {
    onDemand: {
      inventory: currentResources,
      dataIngestion: currentDataIngestion
    },
    reserved: {
      inventory: {
        used: usedReservedInventory,
        total: reservedInventory
      },
      dataIngestion: {
        used: usedReservedDataIngestion,
        total: reservedDataIngestion
      }
    }
  }
}

const calculateUsageOverAccounts = (resources = {}, ingestion = {}) => {
  const getResourceCount = usage => Math.ceil(get(usage, 'usage', 0))
  const getIngestedGbs = usage => Math.ceil(get(usage, 'usage.gbs.sum', 0))
  return {
    inventory: sum(Object.values(resources).map(getResourceCount)),
    dataIngestion: sum(Object.values(ingestion).map(getIngestedGbs))
  }
}

const BillingCycle = () => {
  const loadingSubscriptions = useSelector(state => state.waiting.list[GET_SUBSCRIPTIONS])

  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const { data: resources, isLoading: loadingResourceUsage } = useOrgResourcesUsageSummaryQuery()
  const { data: ingestion, isLoading: loadingIngestionUsage } = useOrgIngestionSummaryQuery()
  const usage = calculateUsageOverAccounts(resources, ingestion)
  const loading = loadingSubscriptions || loadingResourceUsage || loadingIngestionUsage

  const onDemandSubscription = get(subscriptions, 'onDemand')
  const period = getBillingPeriod(onDemandSubscription)

  const { onDemand, reserved } = prepareUsageData(subscriptions, usage)
  return (
    <CurrentBillingPeriod
      loading={loading}
      period={period}
      onDemand={onDemand}
      reserved={reserved}
    />
  )
}

export default BillingCycle
