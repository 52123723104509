import { findInventoryByType } from 'lib/resources/constants'

const isGroupTarget = target => target && target.startsWith('group:')
const cleanGroupTarget = target => target.substring(6)

export const ALARMS_FILTERS = [
  { key: 'service', filter: (selected, alarm) => selected.service.includes(findInventoryByType(alarm.resourceType)?.service) },
  {
    key: 'region',
    filter: (selected, alarm) => selected.region.map(region => {
      if (alarm?.violations?.length === 0) return false
      return alarm?.violations?.map(item => item?.resource?.region === region)
    }).some(item => item)
  }, {
    key: 'delegation',
    filter: (selected, alarm) => selected.delegation.map(del => {
      if (alarm?.violations?.length === 0) return false
      return alarm?.violations?.map(item => item?.resource?.delegation === del)
    }).some(item => item)
  }, {
    key: 'group',
    filter: (selected, alarm, idsInGroups) => alarm?.targets?.map(target => {
      if (isGroupTarget(target)) return selected?.group?.includes(cleanGroupTarget(target))
      return idsInGroups?.includes(target)
    }).some(item => item)
  }, {
    key: 'resource',
    filter: (selected, alarm) => {
      return alarm.violations.map(item => selected.resource.includes(item.resource.id)).some(item => item)
    }
  }
]
