import React from 'react'
import { isEmpty } from 'lodash'

import { formatBoolean, objectify } from 'lib/utils'
import Metadata from 'containers/inventory/shared/metadata'
import Enabled from 'containers/inventory/shared/enabled'
import PrettyPrinter from 'components/json-pretty'
import Region from '../rds/region'

const OpenSearchConfig = ({ attributes, inverse }) => {
  const data = objectify(attributes)
  const serviceSoftwareOpts = JSON.parse(data.serviceSoftwareOptions)
  const cluster = JSON.parse(data.clusterConfig)
  const storage = JSON.parse(data.ebsOptions)
  const security = JSON.parse(data.advancedSecurityOptions)
  const vpc = JSON.parse(data.vpcOptions)

  const formatted = [{
    title: 'Domain arn',
    value: data.arn
  }, {
    title: 'General',
    children: [
      { key: 'Version', value: data.engineVersion },
      { key: 'Service software version', value: `${serviceSoftwareOpts?.currentVersion} ${formatBoolean(serviceSoftwareOpts?.updateAvailable) ? '(update available)' : ''}` }
    ]
  }, {
    title: 'Data nodes',
    children: [
      { key: 'Availability zones', value: vpc?.availabilityZones?.map(item => <Region key={item} reg={item} />) },
      { key: 'Instance type', value: cluster?.instanceType || '-' },
      { key: 'Number of nodes', value: cluster?.instanceCount || '-' },
      { key: 'Storage type', value: (storage.eBSEnabled || storage.ebsEnabled) ? 'EBS' : 'Instance (default)' },
      { key: 'EBS volume type', value: `${storage.volumeType} (SSD)` },
      { key: 'EBS volume size', value: `${storage.volumeSize} GiB` }
    ]
  }, {
    title: 'Dedicated master nodes',
    children: [
      { key: 'Enabled', value: <Enabled data={cluster?.dedicatedMasterEnabled} /> },
      { key: 'Instance type', value: cluster?.dedicatedMasterType },
      { key: 'Number of nodes', value: cluster?.dedicatedMasterCount }
    ]
  }, {
    title: 'Warm and cold data storage',
    children: [
      { key: 'UltraWarm data nodes', value: <Enabled data={cluster.warmEnabled} /> },
      { key: 'Instance type', value: cluster?.warmType },
      { key: 'Number of nodes ', value: cluster?.warmCount },
      { key: 'Cold storage', value: <Enabled data={cluster.coldStorageOptions.enabled} /> }
    ]
  }, {
    title: 'Snapshot start hour',
    value: `${JSON.parse(data.snapshotOptions)?.automatedSnapshotStartHour} UTC`
  }, {
    title: 'Security & authentication',
    children: [
      { key: 'Fine  grained access control', value: <Enabled data={security.enabled} /> },
      { key: 'Internal user database', value: <Enabled data={security.internalUserDatabaseEnabled} /> },
      { key: 'Cognito ', value: <Enabled data={JSON.parse(data.cognitoOptions).enabled} /> },
      { key: 'Saml', value: <Enabled data={!isEmpty(security.samlOptions?.idp)} /> }
    ]
  }, {
    title: 'Encryption',
    children: [
      { key: 'Encryption at rest', value: <Enabled data={JSON.parse(data?.encryptionAtRestOptions)?.enabled} /> },
      { key: 'HTTPS required', value: JSON.parse(data?.domainEndpointOptions).enforseHTTPS ? 'YES' : 'NO' },
      { key: 'Node to node encryption', value: JSON.parse(data?.nodeToNodeEncryptionOptions).enabled ? 'YES' : 'NO' }

    ]
  }, {
    title: 'VPC Options',
    children: [
      { key: 'VPC', value: vpc?.vpcId },
      { key: 'Subnet IDs', value: vpc?.subnetIds?.map(item => <p key={item}>{item}</p>) },
      { key: 'Security groups', value: vpc?.securityGroupIds?.map(item => <p key={item}>{item}</p>) }
    ]
  }, {
    title: 'Access policies',
    value: <PrettyPrinter jsonString={JSON.parse(data?.accessPolicies)} collapsed={false} />
  }]

  return <Metadata items={formatted} inverse={inverse} />
}

export default OpenSearchConfig
