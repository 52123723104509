import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'EVENTBRIDGE_RULE_INVOCATIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_FAILED_INVOCATIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations.failed',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_DLQ_INVOCATIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations.dlq',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_INVOCATIONS_SENT_TO_DLQ',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations.dlq.sent',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_INVOCATIONS_SENT_TO_DLQ_FAILED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations.dlq.failed',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_THROTTLED_RULES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'rules.throttled',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'EVENTBRIDGE_RULE_TRIGGERED_RULES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'rules.triggered',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}]

export default metrics
