import React from 'react'

import styles from './styles.module.less'

const MetadataType = ({ className, name, value }) => {
  return (
    <div className={className}>
      <span className={styles.type}>{value}</span>
      <span className={styles.name}>{name}</span>
    </div>
  )
}

export default MetadataType
