import React from 'react'

import numeral from 'numeral'
import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KINESIS_DS_OUTGOING_AGE } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-datastream'

const KinesisDataStreamOutgoingAgeChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    age: data.KINESIS_DATA_STREAM_MESSAGE_AGE
  })

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [KINESIS_DS_OUTGOING_AGE]: metric.age_avg,
      diff: [metric.age_min, metric.age_max]
    }
  })

  if (!metrics.length) return <Empty height={height} />

  const formatValue = value => numeral(value / 1000).format('00:00:00')

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') {
      return [`${formatValue(value[0])} - ${formatValue(value[1])}`, undefined]
    }
    return [formatValue(value), undefined]
  }

  return (
    <ChartContainer
      height={height}
      data={formatted}
      span={span}
      tabKey={KINESIS_DS_OUTGOING_AGE}
      formatTab={formatValue}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatValue}
    >
      <Line name='Average' dataKey={KINESIS_DS_OUTGOING_AGE} stroke={colors('chart', 'primary')} strokeWidth={1} type='monotone' dot={false} />
      <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Min - max' />
    </ChartContainer>
  )
}

export default KinesisDataStreamOutgoingAgeChart
