import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { channelsPaths as paths, CHANNELS_LAST_NOTIFICATIONS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getLastNotifications = async (account, identity) => {
  const { data } = await apiFetch(paths.lastNotifications(account, identity))
  return data
}

export const useLastNotificationsQuery = (channelId) => {
  const { account } = useOrganization()

  return useQuery(
    [...CHANNELS_LAST_NOTIFICATIONS, account?.id, channelId],
    () => getLastNotifications(account?.id, channelId),
    {
      staleTime: 1000 * 60, // 1 minute
      enabled: !!account?.id && !!channelId
    }
  )
}
