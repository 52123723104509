import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATION_INVITATIONS } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getOrganizationInvitations = async (organization) => {
  const data = await apiFetch(paths.invitations(organization))
  return data
}

export const useOrganizationInvitationsQuery = () => {
  const { organization } = useOrganization()

  return useQuery(
    [...ORGANIZATION_INVITATIONS, organization?.id],
    () => getOrganizationInvitations(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      retry: false
    }
  )
}
