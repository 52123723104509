import React from 'react'
import { Popconfirm, Button } from 'antd'

const CancelButton = ({ onConfirm, loading, show, className } = {}) => {
  if (!show) return null

  return (
    <Popconfirm
      title={`You will not be refunded for unused time.`}
      onConfirm={onConfirm}
      okText='I understand'
      cancelText='Cancel'
    >
      <Button
        type='danger'
        block
        className={className}
        loading={loading}
      >Cancel reserved subscription</Button>
    </Popconfirm>
  )
}

export default CancelButton
