import React, { useMemo } from 'react'

// import { useAccountRoutes } from 'containers/routes'
import { useResourceGroupQuery } from 'hooks/api'
import Loading from 'components/layout/content/loading'
import Events from './events'
import EditableTitle from 'features/editable-title'
import { getEventsData } from './helpers'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import Actions from '../actions'

import styles from '../styles.module.less'

const EventsWidget = ({ widget, events, options, onDelete, onEdit, onDuplicate, onUpdateName, staticWidget }) => {
  // const routes = useAccountRoutes()
  const { data: group } = useResourceGroupQuery(widget?.definition?.resourceGroup)
  const data = useMemo(() => getEventsData(widget, events, options, group), [widget, events, options, group])

  if (!data.length && events.loading) return <Loading height='100%' />

  return (
    <div className={styles.widget_container}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            value={widget?.name || 'Events'}
            onSubmit={value => onUpdateName(widget, value)}
            displayContent={widget?.name || 'Events'}
            readOnly={staticWidget}
            size='xs'
            solid
            uppercase
          />
        </div>
        {!staticWidget && (
          <span className={styles.actions}>
            <IconButton icon={<DragIcon />} className='dbDragHandle' />
            <Actions
              widget={widget}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
            />
          </span>)}
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapper}>
        <Events events={data} />
      </div>
    </div>
  )
}

export default EventsWidget
