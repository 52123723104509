import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { useDispatch } from 'react-redux'

import Content from 'components/layout/content'
import BillingInfo from './billing-info'
import PaymentMethods from './payment-methods'
import { useOrganization } from 'hooks/context/organization-context'

import { API } from 'actions/api'

const Payments = () => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()

  useEffect(() => {
    if (!organization.id) return
    dispatch(API.organizations.id(organization.id).billing.customers.active().get())
  }, [organization.id])

  return (
    <Content item breadcrumbs={['settings', 'payment-methods']}>
      <Row gutter={16}>
        <Col span={12}>
          <BillingInfo />
        </Col>
        <Col span={12}>
          <PaymentMethods />
        </Col>
      </Row>
    </Content>
  )
}

export default Payments
