
import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'

import { useUser } from '../context/user-context'

export const useUpdateUser = () => {
  const { user, setUser } = useUser()

  return useMutation(
    (attributes) => Auth.updateUserAttributes(user, attributes),
    {
      onSuccess: async () => {
        const userInfo = await Auth.currentUserInfo()

        setUser(userInfo)
      }
    }
  )
}
