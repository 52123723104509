import colors from 'lib/colors'

export function generateGridBackground ({ cellSize }) {
  const margin = 10
  const dashColor = colors('chart', 'primary')
  const dotRadius = 1

  return {
    backgroundImage: `radial-gradient(${dashColor} ${dotRadius}px, transparent 0)`,
    backgroundSize: `${cellSize.width + margin}px ${cellSize.height + margin}px`,
    backgroundPosition: `${-(cellSize.width) / 2}px ${(-cellSize.height) / 2}px`,
    backgroundRepeat: 'repeat'
  }
}
