import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import querystring from 'query-string'
import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { INSIGHTS_LIST } from './index'
import { eventPaths as paths } from '../index'
import { useAllResourcesQuery } from 'hooks/api'

const mapResourcesToInsights = (insights, resources) => {
  if (!insights) return undefined
  if (!resources) return insights

  return insights?.map(insight => {
    return {
      ...insight,
      resource: resources[insight.resource]
    }
  })?.filter(item => item.resource)
}

const memoizedResponse = memoizeOne(mapResourcesToInsights, isEqual)

const getInsights = async (account, filters = {}) => {
  const query = querystring.stringify(omitEmpty({ status: 'open', type: 'insight', ...filters }))
  const data = await apiFetch(paths.filter(paths.events(account), query))
  return data?.data
}

export const useInsightsQuery = (query) => {
  const { account } = useOrganization()

  const { data: resources } = useAllResourcesQuery()

  const queryOpts = useQuery(
    [...INSIGHTS_LIST, query && query, account?.id],
    () => getInsights(account?.id, query),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 5 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, resources)

  return {
    ...queryOpts,
    data
  }
}
