import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import createRootReducer from '../reducers'

/**
 * Entirely optional, this tiny library adds some functionality to
 * your DevTools, by logging actions/state to your console. Used in
 * conjunction with your standard DevTools monitor gives you great
 * flexibility!
 */
export const history = createBrowserHistory()

const finalCreateStore = compose(
  // Middleware you want to use in development:
  // add logger as a first argument to enable redux logger
  applyMiddleware(routerMiddleware(history), thunk)
)(createStore)

export default function configureStore (initialState) {
  const rootReducer = createRootReducer(history)
  const store = finalCreateStore(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined)

  return store
}
