import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { policyPaths as paths, NOTIFICATION_POLICY, NOTIFICATION_POLICY_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const updateNotificationPolicy = async (account, policy, payload) => {
  const data = await apiFetch(paths.policy(account, policy), { method: 'PUT', body: JSON.stringify(payload) })
  return data?.data
}

export const useUpdateNotificationPolicyQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    ({ policy, payload }) => updateNotificationPolicy(account.id, policy, payload),
    {
      onMutate: ({ policy, payload }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policy])
        const previousPolicyList = queryClient.getQueryData([...NOTIFICATION_POLICY_LIST, account.id])

        const newPolicy = { ...previousPolicy, name: payload.name }

        const newList = previousPolicyList?.map(item => {
          if (item.id === policy) return newPolicy
          else return item
        })

        queryClient.setQueryData([NOTIFICATION_POLICY, policy], newPolicy)
        queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], newList)

        return { previousPolicy, previousPolicyList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([NOTIFICATION_POLICY, context.previousPolicy.id], context.previousPolicy)
          queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], context.previousPolicyList)
        }
      },
      onSuccess: (data) => {
        queryClient.setQueryData([NOTIFICATION_POLICY, data?.id], data)
      }
    }
  )
}
