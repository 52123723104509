import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { eventsStoragePaths, INVOCATION_LOGS } from './index'

export const getLogs = async (account, identity) => {
  const data = await apiFetch(eventsStoragePaths.logs(account, identity))
  return data
}

export const useInvocationLogsQuery = (hash) => {
  const { account } = useOrganization()

  return useQuery(
    [...INVOCATION_LOGS, hash, account?.id],
    () => getLogs(account?.id, hash),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!hash,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
