import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { first, isEmpty, debounce } from 'lodash'
import { Row, Col, Button } from 'antd'

import { AnimatedAxis } from '@visx/react-spring'
import { scaleLinear } from '@visx/scale'
import { ParentSize } from '@visx/responsive'

import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'
import Input from 'components/antd/input'
import { SearchIcon } from 'components/icons'
import RadioSmall from 'components/antd/radio/small'
import { Typography } from 'components/typography'

import { useNodes } from 'hooks/nodes'
import { TraceContext } from 'features/invocations/details/x-ray/hooks/trace-context'
import useTracesFormatter from './use-traces-formatter'

import { FilterIcon, AngleDownIcon } from 'components/icons/font-awesome'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import Select from 'components/antd/select'

import SegmentRow from './segment-row'

import styles from './styles.module.less'

const CollapsableTraces = ({ traces, width }) => {
  const { metrics, transformed, search, searchByServices } = useNodes(traces)
  const { tooltipData, handleSegmentClick } = useContext(TraceContext)
  const [openSegments, setOpenSegments] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [serviceFilter, setServiceFilter] = useState([])
  const [filterType, setFilterType] = useState('with-parents')
  const [serviceFilterOpen, setServiceFilterOpen] = useState(false)
  const openSegmentsRef = useRef(openSegments)

  const fullScreen = useMemo(() => width > 800, [width])
  const textColWidth = fullScreen ? 400 : 300
  const svgWidth = Math.abs(width - textColWidth - 20)
  const chartWidth = fullScreen ? svgWidth - 120 : svgWidth // margin on the right to show overflow text

  const serviceGroups = Object.values(INVENTORY_SERVICE_GROUPS)

  const traceStart = metrics?.min_start_time
  const traceEnd = metrics?.max_end_time

  const { filtered, flattened: flattenedAll } = useTracesFormatter({
    transformed,
    traceStart,
    traceEnd,
    search,
    searchByServices,
    query: searchQuery,
    serviceFilter,
    filterType
  })

  const handleSegmentOpen = useCallback((e, segment) => {
    e?.stopPropagation()
    if (openSegments?.includes(segment.id) || openSegmentsRef?.current?.includes(segment.id)) {
      openSegmentsRef.current = openSegmentsRef.current?.filter(item => item !== segment.id)
      return setOpenSegments(openSegments?.filter(item => item !== segment.id))
    }
    openSegmentsRef.current = [...openSegments, ...openSegmentsRef.current, segment.id]
    return setOpenSegments([...openSegments, ...openSegmentsRef?.current, segment.id])
  }, [])

  const handleSearchQuery = useMemo(() => {
    return debounce((e) => setSearchQuery(e.target.value), 300)
  }, [])

  useEffect(() => {
    return () => {
      handleSearchQuery.cancel()
    }
  }, [])

  const trace = first(traces)

  const xScale = useMemo(() => scaleLinear({
    domain: [0, trace.duration * 1000],
    range: [0, chartWidth],
    nice: false
  }), [trace, width])

  useEffect(() => {
    if (flattenedAll?.length === 0) return

    openSegmentsRef.current = flattenedAll?.filter(segment => segment.level < 5)?.map(segment => segment.id)
    setOpenSegments(flattenedAll?.filter(segment => segment.level < 5)?.map(segment => segment.id))
  }, [flattenedAll])

  useEffect(() => {
    if (isEmpty(searchQuery) && isEmpty(serviceFilter)) setFilterType('with-parents')
  }, [searchQuery, serviceFilter])

  if (isEmpty(trace) || isEmpty(flattenedAll) || isEmpty(openSegmentsRef?.current) || width === 0) {
    return null
  }

  return (
    <>
      <div className={styles.search_form} >
        <Row gutter={[8, 8]}>
          <Col flex='auto'>
            <Input solid placeholder='Search from traces' onChange={handleSearchQuery} allowClear />
            <SearchIcon className={styles.search_icon} />
          </Col>
          <Col flex='20px'>
            <Button onClick={() => setServiceFilterOpen(!serviceFilterOpen)} className={styles.filter_btn}>
              <FilterIcon /> <AngleDownIcon rotate={serviceFilterOpen} className={styles.filter_icon} />
            </Button>
          </Col>
        </Row>
        {serviceFilterOpen && <Select
          mode='multiple'
          placeholder='Filter by service'
          defaultValue={[]}
          value={serviceFilter}
          onChange={value => setServiceFilter(value)}
          options={serviceGroups.map(group => ({ ...group, value: group?.service }))}
          className={styles.filter_wrapper}
          getPopupContainer={triggerNode => triggerNode?.parentNode}
          solid allowClear
        />}
        {(!isEmpty(searchQuery) || !isEmpty(serviceFilter)) && <div className={styles.radio_wrapper}>
          <Typography.Paragraph size='sm'>Show search results: </Typography.Paragraph>
          <RadioSmall className={styles.radio} defaultValue='with-parents' onChange={e => setFilterType(e.target.value)} items={[
            { value: 'with-parents', label: 'With parents' },
            { value: 'standalone', label: 'Standalone' }
          ]} />
        </div>}
      </div>

      {!isEmpty(filtered)
        ? (
          <div className={styles.traces}>
            <Row className={styles.header}>
              <Col flex={`${textColWidth}px`}>
                <Row gutter={4} wrap={false}>
                  <Col span={18} className={styles.col_title}>Name</Col>
                  <Col span={3} className={styles.col_title}>Method</Col>
                  <Col span={3} className={styles.col_title}>Status</Col>
                </Row>
              </Col>
              <Col flex={'auto'}></Col>
            </Row>
            {filtered?.map(segment => {
              return (
                <SegmentRow
                  key={segment.id}
                  segment={segment}
                  svgWidth={svgWidth}
                  textColWidth={textColWidth}
                  xScale={xScale}
                  showConnections={filterType === 'with-parents'}
                  showSegment={(!isEmpty(searchQuery) || !isEmpty(serviceFilter)) ? true : segment?.parents?.map(item => item.id)?.every(parent => openSegmentsRef?.current?.includes(parent))}
                  segmentOpen={(!isEmpty(searchQuery) || !isEmpty(serviceFilter)) ? true : openSegmentsRef?.current?.includes(segment.id)}
                  selected={segment?.id === tooltipData?.id}
                  showContent={fullScreen}
                  handleSegmentOpen={handleSegmentOpen}
                  handleSegmentClick={handleSegmentClick}
                />
              )
            })}
            <Row wrap={false} gutter={8}>
              <Col flex={`${textColWidth}px`} className={styles.border}></Col>
              <Col flex='auto'>
                <svg height={30} width={svgWidth}>
                  <AnimatedAxis
                    scale={xScale}
                    top={5}
                    left={10}
                    numTicks={width > 1000 ? 10 : 5}
                    stroke={colors('chart', 'grid')}
                    tickStroke={colors('chart', 'text')}
                    tickFormat={num => formatDuration(num)}
                    animationTrajectory={'min'}
                  />
                </svg>
              </Col>
            </Row>
          </div>)
        : <Typography.Paragraph>Traces not found</Typography.Paragraph >}
    </>
  )
}

const CollapsableResponsiveTraces = (props) => {
  return (
    <ParentSize>
      {({ width }) => <CollapsableTraces width={width} {...props} />}
    </ParentSize>
  )
}

export default CollapsableResponsiveTraces
