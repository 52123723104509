import React, { useEffect, useState } from 'react'
import { Button, Form } from 'antd'
import classnames from 'classnames'
import { isEmpty } from 'lodash'

import { useOrganizationQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import Input from 'components/antd/input'
import AddNewModal from '../../../components/add-new-modal'
import UsersPermissionsTable from './permissions-table'

import styles from './styles.module.less'

const steps = (permissions, owner) => [
  { title: 'Email' },
  { title: 'Permissions', disabled: isEmpty(permissions?.filter(item => item.role !== 'no-access')) && !owner },
  { title: 'Invite' }
]

const AddUserModal = ({ visible, setVisible, sendInvite }) => {
  const { data: org } = useOrganizationQuery()

  const [step, setStep] = useState(0)
  const [newUser, setNewUser] = useState('')
  const [orgOwner, setOrgOwner] = useState(false)
  const [permissions, setPermissions] = useState([])

  const isEmailValid = (email) => /\S+@\S+\.\S+/.test(email)

  const reset = () => {
    setStep(0)
    setNewUser('')
    setOrgOwner(false)
  }

  const closeModal = (success) => {
    setVisible(false)
    if (!success) {
      return
    }

    sendInvite({
      email: newUser,
      role: orgOwner ? 'owner' : 'user',
      permissions: permissions.map(permission => {
        return {
          role: permission.role,
          account: permission.account.id
        }
      })
    })
    reset()
  }

  useEffect(() => {
    if (!org || !org?.accounts) return
    setPermissions(org.accounts.map(account => {
      return {
        account,
        role: 'no-access'
      }
    }))
  }, [org])

  return (
    <AddNewModal
      visible={visible}
      closeModal={closeModal}
      destroyOnClose
      title={'Add new user'}
      steps={steps(permissions, orgOwner)}
      currentStep={step}
      setStep={setStep}
      isEmailValid={isEmailValid(newUser)}
    >
      {step === 0 &&
        <div className={styles.step_container}>
          <h4 className={styles.step_title}>Create a new user by adding their email</h4>
          <Form className={styles.form_container} layout='vertical'>
            <Form.Item label='Add email address: '>
              <Input solid placeholder='bob@email.com' value={newUser} onChange={e => setNewUser(e.target.value)} />
            </Form.Item>
          </Form>
        </div>
      }
      {step === 1 &&
        <div className={styles.step_container}>
          <div className={styles.align_row}>
            <p className={styles.user_info}>Invited user: <span className={styles.user_emailSmall}>{newUser}</span></p>
            <PermissionsGate scopes={[SCOPES.canManageAllUsers]}>
              <Button onClick={() => setOrgOwner(!orgOwner)} className={styles.make_owner}>
                {!orgOwner ? 'Make owner' : 'Revert'}
              </Button>
            </PermissionsGate>
          </div>
          {orgOwner
            ? <p className={styles.owner_info}>Owners can manage all users, accounts, settings and billing</p>
            : <>
              <h4 className={styles.step_title}>Add user access to accounts</h4>
              <UsersPermissionsTable permissions={permissions} setPermissions={setPermissions} />
            </>
          }
        </div>
      }
      {step === 2 &&
        <div className={classnames(styles.step_container, styles.align_center)}>
          <p className={styles.user_email}>{newUser}</p>
          <p className={styles.user_invite}>Invite your teammate to Dashbird</p>
          <p className={styles.user_info}>Send them a friendly invite with sign in instructions</p>
        </div>
      }
    </AddNewModal>
  )
}

export default AddUserModal
