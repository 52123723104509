import React from 'react'

// import { useAccountRoutes } from 'containers/routes'
import Loading from 'components/layout/content/loading'
import EditableTitle from 'features/editable-title'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import Actions from '../actions'
import Resources from './resources'

import styles from '../styles.module.less'

const ResourcesWidget = ({ widget, groupResources, onDelete, onEdit, onDuplicate, onUpdateName, staticWidget }) => {
  if (groupResources?.loading) return <Loading height='100%' />

  return (
    <div className={styles.widget_container}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            value={widget?.name || 'Resources'}
            onSubmit={value => onUpdateName(widget, value)}
            displayContent={widget?.name || 'Resources'}
            readOnly={staticWidget}
            size='xs'
            solid
            uppercase
          />
        </div>
        {!staticWidget && <span className={styles.actions}>
          <IconButton icon={<DragIcon />} className='dbDragHandle' />
          <Actions
            widget={widget}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </span>}
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapper}>
        <Resources resources={groupResources?.data} />
      </div>
    </div>
  )
}

export default ResourcesWidget
