import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'EVENTBRIDGE_EVENTBUS_START_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.start',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}]

export default metrics
