import React from 'react'
import { Row, Col, Switch } from 'antd'

import styles from './styles.module.less'

const BillingSwitch = ({ onChange } = {}) => {
  return (
    <Row type='flex' justify='center'>
      <Col>
        <Switch
          defaultChecked={false}
          checkedChildren='Yearly'
          unCheckedChildren='Monthly'
          onChange={onChange}
          className={styles.billingSwitch}
        />
      </Col>
    </Row>
  )
}

export default BillingSwitch
