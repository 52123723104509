import React, { useContext } from 'react'
import numeral from 'numeral'
import classnames from 'classnames'
import { AwsServiceIcon } from 'components/icons'
import { Item } from 'components/items'
import { findInventoryByType } from 'lib/resources/constants'
import LogsParser from 'features/log/search'
import { formatDuration } from 'components/charts/utils'
import { SearchContext } from 'hooks/context/search-context'
import { ScrollContext } from 'components/layout/content'

import styles from '../styles.module.less'

const SearchResult = ({ item, resource, url, selected, bordered }) => {
  const { keywords } = useContext(SearchContext)
  const { width } = useContext(ScrollContext)

  const service = findInventoryByType(resource?.type)
  const duration = item?.data?.duration && formatDuration(item.data.duration)
  const memory = (item?.data?.maxMemoryUsed && item?.data?.memorySize) && numeral(item.data.maxMemoryUsed / item.data.memorySize).format('0%')
  const latestLogTimestamp = item?.logs?.[item?.logs?.length - 1]?.timestamp
  return (
    <div className={classnames({ [styles.bordered]: !!bordered })}>
      <Item.Execution
        gap='sm'
        responsive={width}
        icon={<AwsServiceIcon service={service?.service} size='sm' />}
        title={resource?.name}
        region={resource?.region}
        delegation={resource?.delegationName}
        identity={item?.requestId}
        duration={duration}
        memory={memory}
        timestamp={latestLogTimestamp || item?.timestamp}
        url={url}
        selected={selected === item?.requestId}
        className={classnames({ [styles.invocation_border]: !bordered })}
      />
      <LogsParser hits={item?.logs} keywords={keywords} />
    </div>
  )
}

export default SearchResult
