import React from 'react'
import { Button, Tooltip } from 'antd'

const BuyButton = ({ onClick, loading, title, disabled, tooltip, className } = {}) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        type='primary'
        block
        className={className}
        loading={loading}
        disabled={disabled}
        onClick={onClick}
      >{title}</Button>
    </Tooltip>
  )
}

export default BuyButton
