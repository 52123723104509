import React, { useEffect } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import SplitPane from 'features/layout/split-pane'
import List from './list'
import Details from './details'
import AlarmsDashboard from './dashboard'
import ModifyGroup from 'features/resource-groups-list/edit'

const Alarms = () => {
  const { alarmId } = useParams()
  const match = useRouteMatch()

  useEffect(() => {
    document.title = 'Dashbird.io - Alarms'
  }, [])

  return (
    <SplitPane
      left={<List />}
      right={
        <Switch>
          <Route path={`${match.path}/resource-groups/:groupId?/edit`}>
            <ModifyGroup view='alarms' />
          </Route>
          <Route path={`${match.path}/resource-groups/new`}>
            <ModifyGroup view='alarms' />
          </Route>
          <Route path={match.path}>
            {alarmId
              ? <Details />
              : <AlarmsDashboard />}
          </Route>
        </Switch>
      }
    />
  )
}

export default Alarms
