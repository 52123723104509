import React from 'react'
import { groupBy, countBy, sum } from 'lodash'

import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { BarStackHorizontal } from '@visx/shape'
import { Group } from '@visx/group'
import { AxisLeft } from '@visx/axis'
import { scaleLinear, scaleBand, scaleOrdinal } from '@visx/scale'

import Section from 'components/layout/content/section'
import colors from 'lib/colors'
import { SEVERITY_TYPES } from 'lib/event-constants'

import styles from './styles.module.less'

export const PILLARS = [
  {
    key: 'operational',
    name: 'OPERATIONAL EXCELLENCE'
  }, {
    key: 'cost',
    name: 'COST OPTIMIZATION'
  }, {
    key: 'performance',
    name: 'PERFORMANCE EFFICIENCY'
  }, {
    key: 'reliability',
    name: 'RELIABILITY'
  }, {
    key: 'security',
    name: 'SECURITY'
  }]

const Pillars = ({ rules, loading, selected = [], onSelect }) => {
  const groupedByPillars = groupBy(rules, 'pillar')

  const formattedPillars = PILLARS.map(pillar => ({
    ...pillar,
    rules: groupedByPillars[pillar.key]
  }))

  const data = formattedPillars?.map(pillar => {
    const pillarTotalCount = pillar?.rules?.length || 0
    const rulesWithViolations = pillar?.rules?.filter(rule => rule?.count > 0)
    const failures = countBy(rulesWithViolations, 'severity')
    const totalFailures = sum(Object.values(failures))

    const calculatePercentage = (part = 0) => part / pillarTotalCount * 100

    return {
      key: pillar.key,
      name: pillar.name,
      ok: calculatePercentage(pillarTotalCount - totalFailures),
      critical: calculatePercentage(failures[SEVERITY_TYPES.CRITICAL]),
      warning: calculatePercentage(failures[SEVERITY_TYPES.WARNING]),
      info: calculatePercentage(failures[SEVERITY_TYPES.INFO]),
      okCount: pillarTotalCount - totalFailures,
      criticalCount: failures[SEVERITY_TYPES.CRITICAL],
      warningCount: failures[SEVERITY_TYPES.WARNING],
      infoCount: failures[SEVERITY_TYPES.INFO]
    }
  })

  const keys = ['ok', SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING, SEVERITY_TYPES.INFO]

  const percentageScale = scaleLinear({
    domain: [0, 100]
  })

  const typeScale = scaleBand({
    domain: data.map(item => item.name),
    padding: 0.2
  })

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [colors('chart', 'green'), colors('chart', 'red'), colors('chart', 'yellow'), colors('chart', 'grid')]
  })

  const height = 225
  const barHeight = 3
  const backgroundColor = '#fff'
  const textColor = '#666666' // colors('chart', 'text')
  const textSelectedColor = colors('chart', 'textDark')

  return (
    <Section title=''
      loading={loading}
      loadingHeight={3}
      titleUpperCase
      className={styles.wrapper}
      contentWrapperClassName={styles.pillars_height}>
      <ParentSize>
        {({ width }) => {
          percentageScale.rangeRound([0, width])
          typeScale.rangeRound([height, 0])
          return <svg width={width} height={height}>
            <Group width={width + 3} height={height}>
              <BarStackHorizontal
                data={data}
                keys={keys}
                y={(d) => d.name}
                height={height}
                xScale={percentageScale}
                yScale={typeScale}
                color={colorScale}
              >
                {(barStacks) => {
                  return barStacks.map(barStack => {
                    return barStack.bars.map(bar => {
                      const count = bar.bar.data[`${bar.key}Count`]
                      return (
                        <Group
                          key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                          height={45}
                          onClick={() => onSelect(bar.bar.data.key)}>
                          <rect
                            cursor='pointer'
                            fill={backgroundColor}
                            height={35} width={width}
                            x={bar.x}
                            y={bar.y - 9} />
                          <rect
                            x={bar.x}
                            y={bar.y + 23}
                            width={bar.width}
                            height={barHeight}
                            fill={bar.color}
                          />
                          <rect
                            x={bar.x + bar.width - 3}
                            y={bar.y + 23}
                            height={barHeight}
                            width={3}
                            fill={backgroundColor} />
                          {bar.key === 'info' && selected.includes(bar.bar.data.key) && <rect
                            fill={'none'}
                            height={35} width={width}
                            x={0}
                            y={bar.y - 9}
                            style={{
                              outlineColor: colors('chart', 'primaryLight'),
                              outlineStyle: 'solid',
                              outlineWidth: '0.5px',
                              outlineOffset: '-0.5px'
                            }}
                          />}
                          <text
                            fill={selected.includes(bar.bar.data.key) ? textSelectedColor : textColor}
                            fontSize={20}
                            fontWeight={300}
                            textAnchor={'end'}
                            x={bar.x + bar.width - 5}
                            y={bar.y + 12}
                          >{count}</text>
                        </Group>
                      )
                    }
                    )
                  })
                }
                }
              </BarStackHorizontal>
              <AxisLeft
                hideAxisLine
                hideTicks
                scale={typeScale}
                top={-15}
                left={12}
                tickLabelProps={(barName) => {
                  const selectedPillar = PILLARS.find(pillar => pillar.name === barName)
                  return ({
                    fill: selected.includes(selectedPillar.key) ? textSelectedColor : textColor,
                    fontSize: 10,
                    fontWeight: 500,
                    textAnchor: 'start',
                    dy: '0.33em',
                    pointerEvents: 'none'
                  })
                }
                }
              />
            </Group>
          </svg>
        }
        }
      </ParentSize >
    </Section >
  )
}

export default Pillars
