import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { isNil } from 'lodash'
import { useQueryClient } from 'react-query'

import { Tooltip, Menu, Layout } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faHeartRate } from '@fortawesome/pro-light-svg-icons/faHeartRate'
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb'
import { faChartTreeMap } from '@fortawesome/pro-light-svg-icons/faChartTreeMap'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock'
import Profile from './profile'
import accountPickerMenuItems, { useSwitchAccount } from './account-picker'

import { useOrganizationsQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'
import { useAccountRoutes } from 'containers/routes'
import Analytics from 'lib/analytics'
import { IconLinkButton } from 'components/buttons'
import { DashbirdIcon, UserIcon } from 'components/icons'

import styles from './styles.module.less'

const { Sider } = Layout

const IconContainer = ({ mobileView, children }) => {
  if (!mobileView) return children

  return <div className={styles.icon_container}>{children}</div>
}

const MenuItem = ({ title, url, icon, handleMenuClose, mobileView }) => (
  <Tooltip placement='right' onClick={handleMenuClose} title={mobileView ? null : title} overlayClassName={styles.tooltip}>
    <Link to={url} onClick={handleMenuClose} className={styles.menu_item}>
      <IconContainer mobileView={mobileView}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
      </IconContainer>
      {mobileView ? <span className={styles.menu_title}>{title}</span> : null}
    </Link>
  </Tooltip>
)

const Navigation = ({ collapsed, setCollapsed, className }) => {
  const location = useLocation()
  const history = useHistory()
  const routes = useAccountRoutes()

  const queryClient = useQueryClient()
  const [selectedKeys, setSelectedKeys] = useState([])
  const [mobileView, setMobileView] = useState(false)

  const { organization, account } = useOrganization()
  const { logout } = useUser()

  const { data: organizations } = useOrganizationsQuery()
  const { switchAccount } = useSwitchAccount({ organizations })
  const organizationCreated = !isNil(organization)
  const onBoardingCompleted = organization?.onboarded

  const keys = {
    dashboards: '/dashboards',
    inventory: '/inventory',
    errors: '/errors',
    alarms: '/alarms',
    insights: '/insights',
    resourceGroups: '/resource-groups',
    search: '/search',
    scheduledSearch: '/scheduled-search',
    settings: '/settings'
  }

  useEffect(() => {
    let selectedKey = '/'
    for (const k in keys) {
      const key = keys[k]
      const pattern = `^/[A-Za-z0-9-]*${key}`
      if (location.pathname.startsWith(key) || location.pathname.match(pattern)) {
        selectedKey = key
      }
    }
    setSelectedKeys([selectedKey])
  }, [location.pathname])

  useEffect(() => {
    const link = document.getElementById('leftnav-dashbird-documentation')
    Analytics.trackLink(link, 'User accessed help')
  }, [])

  const handleBreakpoint = (broken) => {
    setCollapsed(broken)
    setMobileView(broken)
  }

  const handleMenuClose = () => {
    if (!mobileView) return

    setCollapsed(true)
  }

  const menuProps = { handleMenuClose, mobileView }

  const handleUserMenuClick = ({ keyPath }) => {
    switchAccount(keyPath)
  }

  const handleCloseMobileMenu = () => {
    if (!mobileView) return

    setCollapsed(true)
  }

  return (
    <Sider
      collapsible
      breakpoint='lg'
      width={mobileView ? '100%' : 60}
      collapsed={collapsed}
      collapsedWidth='0'
      trigger={null}
      onBreakpoint={handleBreakpoint}
      className={className}
    >
      <div className={styles.menu_wrapper}>
        {!mobileView && (
          <>
            <IconLinkButton icon={<DashbirdIcon />} to={!onBoardingCompleted ? 'onboarding' : ''} />
            <div className={styles.separator} />
          </>)}
        {onBoardingCompleted &&
          <Menu
            theme='dark'
            className={styles.navigation}
            mode='vertical'
            selectedKeys={selectedKeys}
            items={[
              { key: '/', label: <MenuItem title='Dashboard' icon={faHeartRate} url={'/'} {...menuProps} /> },
              { key: keys.inventory, label: <MenuItem title='Inventory' icon={faServer} url={routes.inventory.url()} {...menuProps} /> },
              { key: keys.errors, label: <MenuItem title='Errors' icon={faBug} url={routes.errors.url()} {...menuProps} /> },
              { key: keys.alarms, label: <MenuItem title='Alarms' icon={faExclamationTriangle} url={routes.alarms.url()} {...menuProps} /> },
              { key: keys.insights, label: <MenuItem title='Insights' icon={faLightbulb} url={routes.insights.url()} {...menuProps} /> },
              { key: keys.dashboards, label: <MenuItem title='Dashboards' icon={faChartTreeMap} url={routes.dashboards.url()} {...menuProps} /> },
              { key: keys.scheduledSearch, label: <MenuItem title='Scheduled search' icon={faCalendarClock} url={routes.scheduledSearch.url()} {...menuProps} /> },
              { key: keys.search, label: <MenuItem title='Search' icon={faSearch} url={routes.search.url()} {...menuProps} /> }
            ]}
          />}

        <Menu
          theme={mobileView ? 'dark' : 'light'}
          className={styles.user}
          mode={mobileView ? 'inline' : 'vertical'}
          selectedKeys={[keys.settings, `${organization?.id}:${account?.id}`]}
          onSelect={handleUserMenuClick}
          inlineIndent={0}
          items={[
            {
              key: keys.settings,
              label: (
                <span className={styles.menu_item}>
                  <IconContainer mobileView={mobileView}>
                    <UserIcon className={styles.icon} />
                  </IconContainer>
                  {mobileView && <span className={styles.menu_title}>user</span>}
                </span>
              ),
              popupClassName: styles.user_submenu,
              children: [
                {
                  key: 'profile',
                  label: <Profile className={styles.section} />
                },

                ...(organizationCreated ? accountPickerMenuItems({ organizations, organization, account }) : []),

                { key: 'settings', label: <Link to={routes.settings.url()} onClick={handleCloseMobileMenu}>Settings</Link> },
                { key: 'notifications', label: <Link to={routes.settings.notifications.url()} onClick={handleCloseMobileMenu}>Notifications</Link> },
                { key: 'documentation', label: <a target='_blank' href='https://dashbird.io/docs' rel='noreferrer' id='leftnav-dashbird-documentation'>Documentation</a> },
                { type: 'divider' },
                {
                  key: 'logout',
                  label: (
                    <a onClick={() => {
                      logout()
                      queryClient.clear()
                      history.push('/auth/login')
                      handleCloseMobileMenu()
                    }}>
                      Sign out
                    </a>)
                }
              ]
            }
          ]} />
      </div >
    </Sider >
  )
}

export default Navigation
