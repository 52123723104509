import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { alarmsPaths as paths, ALARM_ACTIVE } from './index'

const getAlarm = async (account, identity) => {
  const data = await apiFetch(paths.alarm(account, identity))
  return data
}

export const useAlarmQuery = () => {
  const { alarmId } = useParams()
  const { account } = useOrganization()
  return useQuery(
    [...ALARM_ACTIVE, alarmId],
    () => getAlarm(account.id, alarmId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!alarmId,
      refetchOnWindowFocus: false
    }
  )
}
