import NAMESPACES from 'containers/inventory/details/resource/metrics/namespaces'
import STATISTICS from 'containers/inventory/details/resource/metrics/statistics'

const metrics = [
  {
    id: 'ELB_TARGETGROUP_HEALTHY',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'healthy',
      stats: [STATISTICS.MIN]
    }
  },
  {
    id: 'ELB_TARGETGROUP_UNHEALTHY',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'unhealthy',
      stats: [STATISTICS.MAX]
    }
  },
  {
    id: 'ELB_TARGETGROUP_RESPONSE_TIME',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'response.time',
      stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
    }
  },
  {
    id: 'ELB_TARGETGROUP_REQUESTS_2XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'response.code.2XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_TARGETGROUP_REQUESTS_3XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'response.code.3XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_TARGETGROUP_REQUESTS_4XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'response.code.4XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_TARGETGROUP_REQUESTS_5XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'response.code.5XX',
      stats: [[STATISTICS.SUM]]
    }
  }
]

export default metrics
