import NAMESPACES from 'containers/inventory/details/resource/metrics/namespaces'
import STATISTICS from 'containers/inventory/details/resource/metrics/statistics'

const metrics = [
  {
    id: 'ELB_LOADBALANCER_LCU',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'lcu',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'connections.active',
      stats: [STATISTICS.AVERAGE]
    }
  },
  {
    id: 'ELB_LOADBALANCER_PROCESSED_BYTES',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'processed.bytes',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_PROCESSED_PACKETS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'processed.packets',
      stats: [STATISTICS.SUM]
    }
  }
]

export default metrics
