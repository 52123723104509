import { api as billing } from './billing.actions'

export const API = {
  organizations: {
    id: (organization) => {
      return {
        billing: billing(organization)
      }
    }
  }
}
