import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash'

import { apiFetch } from 'lib/action-helpers'
import { VIOLATION_SELECTED } from './index'
import { eventPaths as paths, useAllResourcesQuery } from '../index'

const transformViolation = (violation, resources) => {
  if (!violation) return undefined
  if (!resources) return violation
  return { ...violation, resource: resources[violation.resource] }
}
const memoizedResponse = memoizeOne(transformViolation, isEqual)

const getViolation = async (account, violation) => {
  return await apiFetch(paths.event(account, violation))
}

export const useViolationQuery = (violationId) => {
  const { account } = useOrganization()
  const { data: resources } = useAllResourcesQuery()

  const queryOpts = useQuery(
    [...VIOLATION_SELECTED, violationId],
    () => getViolation(account.id, violationId),
    {
      enabled: !!account?.id && !!violationId,
      staleTime: Infinity
    }
  )

  const data = memoizedResponse(queryOpts?.data, resources)

  return {
    ...queryOpts,
    data
  }
}
