import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { delegationPaths as paths, DELEGATION_ACTIVE } from './index'

const getDelegation = async (account, identity) => {
  const data = await apiFetch(paths.delegation(account, identity))
  return data
}

export const useDelegationQuery = () => {
  const { delegationId } = useParams()
  const { account } = useOrganization()

  return useQuery(
    [...DELEGATION_ACTIVE, delegationId],
    () => getDelegation(account.id, delegationId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!delegationId,
      refetchOnWindowFocus: false
    }
  )
}
