import React from 'react'
import Numeral from 'numeral'

import { Line, YAxis } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { yAxisProps } from 'components/charts/utils'
import { KDA_UPTIME, KDA_DOWNTIME, KDA_RESTARTS } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const KinesisAnalyticsUptimeDowntimeRestarts = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KDA_UPTIME]: data[KDA_UPTIME],
    [KDA_DOWNTIME]: data[KDA_DOWNTIME],
    [KDA_RESTARTS]: data[KDA_RESTARTS]
  })

  if (!metrics.length) return <Empty height={height} />

  const formatUptime = value => `${Numeral(value / 1000 / 60 / 60).format('0')}h`
  const formatDowntime = value => `${Numeral(value / 1000).format('00:00:00')}`
  const formatCount = value => Numeral(value).format('0 a')

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === KDA_RESTARTS) {
      return [formatCount(value), undefined]
    }

    if (dataKey === KDA_UPTIME) {
      return [formatUptime(value), undefined]
    }

    return [formatDowntime(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={KDA_UPTIME}
      formatTab={formatUptime}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatUptime}
    >
      <YAxis yAxisId='restarts' orientation='right' tickFormatter={formatCount} {...yAxisProps} />
      <Line type='monotone' dataKey={KDA_UPTIME} stroke={colors('chart', 'primary')} strokeWidth={1} name='Uptime' dot={false} />
      <Line type='monotone' dataKey={KDA_DOWNTIME} stroke={colors('chart', 'red')} strokeWidth={1} name='Downtime' dot={false} />
      <Line type='monotone' dataKey={KDA_RESTARTS} yAxisId='restarts' stroke={colors('chart', 'redLight')} strokeWidth={1} name='Full restarts' dot={false} />
    </ChartContainer>
  )
}

export default KinesisAnalyticsUptimeDowntimeRestarts
