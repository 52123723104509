import React from 'react'
import { Dropdown } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import IconButton from 'components/buttons/icon'
import { EllipsisVIcon } from 'components/icons'

import styles from './styles.module.less'

const items = [
  {
    key: 'update',
    label: 'Edit'
  },
  {
    key: 'duplicate',
    label: 'Duplicate'
  },
  {
    key: 'delete',
    label: 'Delete'
  }
]

const Actions = ({ widget, onDelete, onEdit, onDuplicate }) => {
  if (!widget) return null

  const onClick = ({ key }) => {
    switch (key) {
      case 'update':
        onEdit(widget)
        break
      case 'duplicate':
        onDuplicate(widget)
        break
      case 'delete':
        onDelete(widget)
        break
      default:
        break
    }
  }

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <IconButton icon={<EllipsisVIcon />} className={styles.extra} />
      </Dropdown>
    </PermissionsGate>
  )
}

export default Actions
