import React from 'react'
import { Checkbox } from 'antd'

import { InsightRules } from 'lib/policy-constants'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import TargetSelector from 'features/target-selector'

import { InsightIcon } from 'components/icons'
import Select from 'components/antd/select'
import Actions from './actions'

import styles from './styles.module.less'

const blacklist = ['AWS::ApiGateway::RestApi', 'AWS::ApiGateway::Resource']

const filter = (resources) => {
  return resources.filter(resource => !blacklist.includes(resource.type))
}

const InsightForm = ({ mutations, condition, lambdas, resources, groups }) => {
  const selectable = filter(resources)
  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <InsightIcon /><span>Insights</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>services</span>
          <span className={styles.value}>{condition.rules && condition.rules.length ? condition.rules.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition.targets && condition.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} />
        </div>
      </div>
      <section>
        <span className={styles.label}>Severities</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Checkbox.Group
            options={['critical', 'warning', 'info']}
            defaultValue={condition.severities}
            onChange={(values) => handlers.update({ severities: values })}

          />
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Filter by services</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Select
            mode='multiple'
            defaultValue={condition.rules}
            placeholder='Select services'
            solid
            onChange={(values) => handlers.update({ rules: values })}
            options={InsightRules.map(item => ({ id: item.value, title: item.title, value: item.value }))}
          />
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Filter by resources</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <TargetSelector
            lambdas={lambdas}
            resources={selectable}
            resourceGroups={groups}
            value={condition.targets}
            placeholder='Select resources'
            prefixValues
            onSelect={(value) => handlers.targets(value).add()}
            onDeselect={(value) => handlers.targets(value).remove()}
          />
        </PermissionsGate>
      </section>
    </div>
  )
}

export default InsightForm
