import React from 'react'

import IconButton from 'components/buttons/icon'

import styles from './styles.module.less'
import { CloseIcon } from '../../icons'

const RemoveButton = (props) => {
  return (
    <IconButton {...props} icon={<CloseIcon />} className={styles.remove} />
  )
}

export default RemoveButton
