import React from 'react'

const ApiGatewayIconSvg = ({ width, height, x, y }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 41.739 40" x={x} y={y}>
      <g transform="matrix(1.7392 0 0 1.7392 -13.621 -14.253)">
        <defs>
          <linearGradient id="idNpth8Pu26g-1691345225" x1="7.6674" x2="31.162" y1="8.3715" y2="8.3715" gradientTransform="scale(1.0215 .97895)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#a166ff" offset="0" />
            <stop stopColor="#4D27A8" offset="1" />
          </linearGradient>
        </defs>
        <path d="m25.103 31.189c-0.102 0-0.201-0.031-0.283-0.091-0.126-0.089-0.201-0.234-0.202-0.388v-5.268h-1.439v-0.958h1.439v-9.579h-1.439v-0.958h1.439v-5.268c1e-3 -0.167 0.088-0.321 0.23-0.407 0.145-0.093 0.327-0.102 0.48-0.024l6.235 3.041c0.163 0.08 0.267 0.245 0.268 0.426v16.926c0.011 0.216-0.125 0.412-0.331 0.479l-6.234 2.069c-0.054 8e-3 -0.109 8e-3 -0.163 0zm0.479-21.739v20.594l5.276-1.748v-16.284zm-11.035 21.739c-0.051 7e-3 -0.103 7e-3 -0.154 0l-6.234-2.069c-0.204-0.068-0.338-0.264-0.326-0.479v-16.926c1e-3 -0.181 0.105-0.346 0.268-0.426l6.235-3.041c0.152-0.078 0.335-0.069 0.479 0.024 0.141 0.087 0.226 0.241 0.226 0.407v5.268h0.945v0.958h-0.945v9.579h0.945v0.958h-0.945v5.268c-1e-3 0.154-0.076 0.299-0.202 0.388-0.084 0.064-0.187 0.096-0.292 0.091zm-5.755-2.874 5.275 1.749v-20.595l-5.275 2.572zm12.949-13.41h-1.439v-0.958h1.439zm-2.878 0h-1.439v-0.958h1.439zm2.878 10.537h-1.439v-0.958h1.439zm-2.878 0h-1.439v-0.958h1.439zm-1.146-4.1-1.803-1.537c-0.107-0.091-0.168-0.224-0.168-0.364s0.061-0.273 0.168-0.364l1.798-1.538 0.624 0.728-1.372 1.188 1.372 1.173zm4.216 0-0.624-0.728 1.372-1.159-1.377-1.169 0.624-0.747 1.803 1.533c0.107 0.091 0.168 0.224 0.168 0.364s-0.061 0.273-0.168 0.364zm-3.521 1.01 1.906-5.631 0.909 0.307-1.906 5.631z" fill="url(#idNpth8Pu26g-1691345225)" />
      </g>
    </svg>

  )
}

export default ApiGatewayIconSvg
