import React, { useContext } from 'react'
import { Line } from 'recharts'

import { ServiceContext } from '../../index'
import transform from '../transform'
import { CHART_COLORS } from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { S3_BUCKET_OBJECTS, formatObjectCount } from 'containers/inventory/details/resource/metrics-tabs/services/s3'

const tooltipFormatter = (value, name, props) => {
  return [formatObjectCount(value), undefined]
}

const S3BucketSizeChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null
  const { relativeSpan, range } = useContext(ServiceContext)

  const metrics = transform({
    [S3_BUCKET_OBJECTS]: data.S3_BUCKET_OBJECTS
  })

  if (!metrics.length) {
    if (relativeSpan && range <= 1440) {
      return <Empty height={height} message="S3 metrics are published daily. Increase the relative time window to see yesterday's data."/>
    }
    return <Empty height={height} />
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={S3_BUCKET_OBJECTS}
      formatYTicks={formatObjectCount}
      formatTab={formatObjectCount}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={S3_BUCKET_OBJECTS} stroke={CHART_COLORS.primary} strokeWidth={1} name='Average' dot={false} />
    </ChartContainer>
  )
}

export default S3BucketSizeChart
