import { useMemo } from 'react'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

const useGroupResources = ({ resources, resourceGroup }) => {
  const formattedResources = useMemo(() => {
    if (!resources || !resourceGroup) return []
    const inventoryTypes = Object.values(INVENTORY_SERVICES).filter(item => !item.excludeFromList).map(item => item.type)
    const mappedResources = resourceGroup?.resources?.map(resourceId => resources[resourceId])
    return mappedResources?.filter(item => item && inventoryTypes.includes(item?.type))
  }, [resourceGroup, resources])

  return { data: formattedResources, loading: !resourceGroup || !resources }
}

export default useGroupResources
