import React from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { Row, Col, Tooltip } from 'antd'
import { Group } from '@visx/group'

import { AngleDownIcon } from 'components/icons'
import SegmentBar, { BAR_HEIGHT } from '../segment-bar'

import styles from './styles.module.less'

const SegmentInfo = ({ seg, level, handleSegmentToggle, handleSegmentClick, open, showConnections }) => {
  const collapsible = !isEmpty(seg?.children?.filter(item => !item.inferred)) && showConnections
  const isError = seg?.reference?.error || seg?.data?.response >= 400 || !isEmpty(seg.errors)
  return (
    <Row key={seg.id} gutter={4} align='middle' onClick={() => handleSegmentClick(seg)} className={styles.segment_info}>
      <Col span={18} style={{ paddingLeft: showConnections ? level * 9 : 0 }}>
        <Row align='middle' wrap={false}>
          {showConnections && (
            collapsible
              ? <AngleDownIcon className={classnames(styles.icon)} rotate={open} onClick={e => handleSegmentToggle(e, seg)} />
              : <div className={styles.icon} />)}
          <Tooltip title={isError ? `${seg.errors?.[0]?.name} -  ${seg.errors?.[0]?.message}` : ''} mouseEnterDelay={1} >
            <p className={classnames(styles.trace_title, { [styles.error]: isError })}>{seg.name}</p>
          </Tooltip>
        </Row>
      </Col>
      <Col span={3}>
        <p className={classnames(styles.trace_title, { [styles.error]: isError })}>{seg?.data?.method}</p>
      </Col>
      <Col span={3}>
        <p className={classnames(styles.trace_title, { [styles.error]: isError })}>{seg?.data?.response}</p>
      </Col>
    </Row >
  )
}

const SegmentRow = React.memo((props) => {
  const {
    segment,
    svgWidth,
    textColWidth,
    xScale,
    showSegment,
    segmentOpen,
    selected,
    showContent,
    showConnections,
    handleSegmentOpen,
    handleSegmentClick
  } = props
  if (segment?.inferred) return null

  return (
    <>
      {(showSegment || segment.level === 0)
        ? (
          <Row
            gutter={8}
            className={classnames(styles.segment_row, { [styles.selected]: !!selected })}
            wrap={false}
          >
            <Col flex={`${textColWidth}px`} className={styles.border}>
              <SegmentInfo
                seg={segment}
                level={segment.level}
                showConnections={showConnections}
                handleSegmentToggle={handleSegmentOpen}
                handleSegmentClick={handleSegmentClick}
                open={segmentOpen}
              />
            </Col>
            <Col flex='auto'>
              <svg
                height={BAR_HEIGHT}
                width={svgWidth - 20}
                overflow='unset'
                onClick={() => handleSegmentClick(segment)}
                cursor={'pointer'}
              >
                <Group left={10}>
                  <SegmentBar
                    item={segment}
                    showContent={showContent}
                    showConnections={showConnections}
                    xScale={xScale}
                    connectedParents={segment.connectedParents}
                  />
                </Group>
              </svg>
            </Col>
          </Row>)
        : null}
    </>
  )
})

export default SegmentRow
