import React from 'react'
import { Button } from 'antd'

const getText = ({ isTrial, isTrialEnded } = {}) => {
  if (isTrialEnded) return 'Upgrade'
  if (isTrial) return 'Upgrade after trial'
  return 'Manage subscription'
}

const ManageSubscriptionButton = ({ isTrial, isTrialEnded, onClick, className } = {}) => {
  const text = getText({ isTrial, isTrialEnded })
  return (
    <Button type='primary' color='green' block onClick={onClick} className={className}>{text}</Button>
  )
}

export default ManageSubscriptionButton
