export const RESOURCE_GROUPS = 'resourceGroups'
export const RESOURCE_GROUPS_LIST = [RESOURCE_GROUPS, 'list']
export const RESOURCE_GROUP_ACTIVE = [RESOURCE_GROUPS, 'active']
export const RESOURCE_GROUP_PREVIEW = [RESOURCE_GROUPS, 'preview']

export * from './get-resource-groups'
export * from './get-resource-group'
export * from './get-resource-group-preview'
export * from './update-resource-group'
export * from './create-resource-group'
export * from './delete-resource-group'

export const resourceGroupsPaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  groups: (account) => `${resourceGroupsPaths.account(account)}/resource-groups`,
  group: (account, identity) => `${resourceGroupsPaths.account(account)}/resource-groups/${identity}`,
  resources: (account, identity) => `${resourceGroupsPaths.group(account, identity)}/resources`,
  preview: (account) => `${resourceGroupsPaths.groups(account)}/preview`
}
