import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, PAYMENT_METHODS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const createMethod = async (identity, payload) => {
  const data = apiFetch(paths.paymentMethods(identity), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreatePaymentMethodQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ payload }) => createMethod(organization.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...PAYMENT_METHODS, organization.id])
      }
    }
  )
}
