export const AggregationTypes = [{
  value: 'avg',
  title: 'average'
}, {
  value: 'max',
  title: 'maximum'
}, {
  value: 'min',
  title: 'minimum'
}, {
  value: 'sum',
  title: 'sum'
}]

const MINUTE = 60
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
export const AlarmTermDurations = [{
  title: '15 minutes',
  value: 15 * MINUTE
}, {
  title: '30 minutes',
  value: 30 * MINUTE
}, {
  title: '1 hour',
  value: HOUR
}, {
  title: '2 hours',
  value: 2 * HOUR
}, {
  title: '4 hours',
  value: 4 * HOUR
}, {
  title: '8 hours',
  value: 8 * HOUR
}, {
  title: '12 hours',
  value: 12 * HOUR
}, {
  title: '1 day',
  value: DAY
}, {
  title: '2 days',
  value: 2 * DAY
}]

export const TermOperators = [{
  value: 'above',
  title: 'above'
}, {
  value: 'below',
  title: 'below'
}]

export const TermDurations = [{
  title: '1 minute',
  value: 1
}, {
  title: '2 minutes',
  value: 2
}, {
  title: '3 minutes',
  value: 3
}, {
  title: '4 minutes',
  value: 4
}, {
  title: '5 minutes',
  value: 5
}, {
  title: '10 minutes',
  value: 10
}, {
  title: '15 minutes',
  value: 15
}, {
  title: '1 hour',
  value: 60
}, {
  title: '4 hours',
  value: 180
}, {
  title: '1 day',
  value: 1440
}, {
  title: '7 days',
  value: 10080
}, {
  title: '14 days',
  value: 20160
}]

export const TrackedTypes = [{
  value: 'lambda',
  title: 'invocations'
}, {
  value: 'lambda.error',
  title: 'errors'
}, {
  value: 'lambda.retry',
  title: 'retries'
}, {
  value: 'lambda.coldstart',
  title: 'coldstarts'
}]

export const MetricTypes = [{
  value: 'duration',
  title: 'duration (in ms)'
}, {
  value: 'billed_duration',
  title: 'billed duration (in ms)'
}, {
  value: 'max_memory_used',
  title: 'max memory used (in MB)'
}, {
  value: 'percentage_memory_used',
  title: '% of memory used'
}, {
  value: 'count',
  title: 'invocations count'
}, {
  value: 'error.count',
  title: 'fatal errors count'
}, {
  value: 'coldstart.count',
  title: 'coldstarts count'
}, {
  value: 'retry.count',
  title: 'retry count'
}, {
  value: 'cost',
  title: 'cost (in dollars)'
}]

export const ErrorTermEvents = [{
  value: 'new',
  title: 'new'
}]

export const ErrorTermTypes = [{
  value: '*',
  title: 'error'
}, {
  value: 'crash',
  title: 'crash'
}, {
  value: 'timeout',
  title: 'timeout'
}, {
  value: 'out_of_memory',
  title: 'out of memory'
}, {
  value: 'early_exit',
  title: 'early exit'
}, {
  value: 'configuration',
  title: 'configuration issue'
}]

export const IncidentPreferenceTypes = [{
  title: 'policy',
  value: 'policy'
}, {
  title: 'condition',
  value: 'condition'
}, {
  title: 'target',
  value: 'target'
}]

export const PolicyTypes = {
  METRICS: 'metrics',
  ERRORS: 'errors'
}

export const ChannelTypes = {
  EMAIL: 'email',
  SLACK: 'slack',
  SNS: 'sns',
  WEBHOOK: 'webhook',
  TEAMSBOT: 'teamsbot'
}
