import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { channelsPaths as paths, NOTIFICATION_CHANNEL_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createNotificationChannel = async (account, payload) => {
  const data = await apiFetch(paths.channels(account), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateNotificationChannelQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    ({ accountId = account.id, payload }) => createNotificationChannel(accountId, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...NOTIFICATION_CHANNEL_LIST, account.id])
      }
    }
  )
}
