import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'antd'
import classnames from 'classnames'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import Input from 'components/antd/input'
import IconButton from 'components/buttons/icon'
import { ResolvedIcon, EditIcon } from 'components/icons'
import DeleteButton from 'components/buttons/delete'
import SkeletonLine from 'components/skeleton-line'
import { Typography } from 'components/typography'

import styles from './styles.module.less'

const Title = ({ displayContent, size, color, readOnly, setEditMode, uppercase }) => {
  const handleStartEdit = () => {
    if (readOnly) return

    setEditMode(true)
  }

  return (
    <div className={classnames(styles.header, { [styles[`size_${size}`]]: !!size })}>
      <span className={classnames(styles.title, { [styles[`text_${color}`]]: !!color, [styles.uppercase]: !!uppercase })} onClick={() => handleStartEdit()}>
        {displayContent}
      </span>
      {!readOnly && (
        <span className={classnames(styles.edit_icon_wrapper, { [styles[`size_${size}`]]: !!size })}>
          <EditIcon />
          <Typography.Paragraph uppercase>edit</Typography.Paragraph>
        </span>)}
    </div>
  )
}

const EditableTitle = ({
  value,
  className,
  wrapperClassName,
  readOnly,
  displayContent,
  onSubmit,
  loading,
  size,
  solid,
  color,
  uppercase
}) => {
  const [form] = Form.useForm()
  const controlsRef = useRef()
  const [editMode, setEditMode] = useState(false)

  const handleBlur = (e) => {
    if (e?.currentTarget?.contains(controlsRef?.current)) return
    setEditMode(false)
  }

  const handleSubmit = (values) => {
    onSubmit(values?.title)
    setEditMode(false)
  }

  const handleCancel = () => {
    setEditMode(false)
  }

  useEffect(() => {
    form.setFieldsValue({ title: value })
  }, [value])

  const showTitle = displayContent && !editMode && value

  if (loading) return <SkeletonLine size={'lg-25'} />

  return (
    <Form
      layout='inline'
      form={form}
      onFinish={handleSubmit}
      className={classnames(styles.container, { [styles[`size_${size}`]]: !!size, [styles.edit]: editMode }, wrapperClassName)}
      initialValues={{ title: value }}
      onBlur={(e) => handleBlur(e)}
    >
      {
        showTitle
          ? (
            <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ readOnly: true }}>
              <Title
                displayContent={displayContent}
                setEditMode={setEditMode}
                size={size}
                readOnly={readOnly}
                color={color}
                uppercase={uppercase}
              />
            </PermissionsGate>)
          : (
            <Form.Item name='title' className={classnames(styles.form_item)}>
              <Input
                className={classnames(styles.input, className, { [styles.readOnly]: readOnly })}
                autoFocus
                solid={solid}
                disabled={readOnly}
              />
            </Form.Item>)
      }
      <div className={classnames(styles.controls, { [styles.visible]: editMode, [styles[`size_${size}`]]: !!size })} ref={controlsRef}>
        <Form.Item className={styles.form_item}>
          <IconButton
            icon={<ResolvedIcon />}
            className={classnames(styles.button, { [styles.solid]: !!solid })}
            title='Save'
            htmlType='submit'
          />
        </Form.Item>
        <Form.Item className={styles.form_item}>
          <DeleteButton
            title='Cancel'
            className={classnames(styles.button, { [styles.solid]: !!solid })}
            onClick={() => handleCancel()}
          />
        </Form.Item>
      </div>
    </Form>
  )
}

export default EditableTitle
