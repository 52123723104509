import { QUERY_ACTIVE, queryPaths } from 'hooks/api/queries'
import { apiFetch } from 'lib/action-helpers'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'
import { useQuery } from 'react-query'

const getQuery = async (account, queryId) => {
  if (!queryId) return
  return await apiFetch(queryPaths.query(account, queryId))
}

export const useGetQueryQuery = () => {
  const { queryId } = useParams()
  const { account } = useOrganization()

  return useQuery(
    [...QUERY_ACTIVE, queryId],
    () => getQuery(account.id, queryId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!queryId && queryId !== 'requests',
      refetchOnWindowFocus: false
    }
  )
}
