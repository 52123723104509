import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { suspensionPaths as paths, ORGANIZATIONS_LIST, ORGANIZATIONS_ACTIVE } from '../../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'

const suspendOrganization = async (organization) => {
  const data = await apiFetch(paths.organizationSuspension(organization), { method: 'POST' })

  return data
}

export const useSuspendOrganizationQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()
  const { user } = useUser()

  return useMutation(
    () => suspendOrganization(organization.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ORGANIZATIONS_LIST, user.attributes.email])
        queryClient.invalidateQueries([...ORGANIZATIONS_ACTIVE, organization.id])
      }
    }
  )
}
