import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { USERS_LIST, organizationsPaths as paths } from '../index'

import { apiFetch } from 'lib/action-helpers'

const deleteUser = async (organization, userId) => {
  const data = await apiFetch(paths.user(organization, userId), { method: 'DELETE' })

  return data
}

export const useDeleteUserQuery = () => {
  const queryClient = useQueryClient()

  const { organization } = useOrganization()

  const queryKey = [...USERS_LIST, organization?.id]

  return useMutation(
    ({ userId }) => deleteUser(organization.id, userId),
    {
      onMutate: ({ userId }) => {
        const previousList = queryClient.getQueryData(queryKey)

        const newList = previousList.filter(({ id }) => id !== userId)
        queryClient.setQueryData(queryKey, newList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (!err) return
        queryClient.setQueryData(queryKey, context.previousList)
      }
    }
  )
}
