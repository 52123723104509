import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, PAYMENT_METHODS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const cancelMethodSetup = async (identity, methodIdentity) => {
  const data = apiFetch(paths.paymentMethod(identity, methodIdentity), { method: 'DELETE' })

  return data
}

export const useDeletePaymentMethodQuery = () => {
  const queryClient = useQueryClient()

  const { organization } = useOrganization()

  return useMutation(
    ({ methodId }) => cancelMethodSetup(organization.id, methodId),
    {
      onMutate: ({ methodId }) => {
        const previousList = queryClient.getQueryData([...PAYMENT_METHODS, organization.id])

        const updatedList = previousList?.filter(item => item.id !== methodId)
        queryClient.setQueryData([...PAYMENT_METHODS, organization.id], updatedList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...PAYMENT_METHODS, organization.id], context.previousList)
        }
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries([...PAYMENT_METHODS, organization.id])
      }
    }
  )
}
