import { useMutation } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const validateVAT = async (organization, payload) => {
  const data = await apiFetch(paths.validateVAT(organization), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useValidateVatQuery = () => {
  const { organization } = useOrganization()

  return useMutation(
    ({ payload }) => validateVAT(organization.id, payload)
  )
}
