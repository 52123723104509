import { useMutation, useQueryClient } from 'react-query'
import queryString from 'query-string'

import { useOrganization } from 'hooks/context/organization-context'
import { ORGANIZATION_INVITATIONS, organizationsPaths as paths } from '../index'

import { apiFetch, omitEmpty } from 'lib/action-helpers'

const deleteInvitation = async (organization, invitationId, email) => {
  const query = queryString.stringify(omitEmpty({ email }))
  const data = await apiFetch(paths.filter(paths.invitation(organization, invitationId), query), { method: 'DELETE' })

  return data
}

export const useDeleteInvitationQuery = () => {
  const queryClient = useQueryClient()

  const { organization } = useOrganization()

  const queryKey = [...ORGANIZATION_INVITATIONS, organization?.id]

  return useMutation(
    ({ invitationId, email }) => deleteInvitation(organization.id, invitationId, email),
    {
      onMutate: ({ invitationId }) => {
        const previousList = queryClient.getQueryData(queryKey)

        const newList = previousList.filter(({ id }) => id !== invitationId)
        queryClient.setQueryData(queryKey, newList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (!err) return
        queryClient.setQueryData(queryKey, context.previousList)
      },
      onSuccess: () => queryClient.invalidateQueries(queryKey)
    }
  )
}
