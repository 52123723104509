import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Modal, Steps, Skeleton, Button, Tooltip, message } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import {
  useGetPaymentMethods,
  useGetBillingQuery,
  useGetNextInvoiceQuery,
  useGetSubscriptionQuery,
  useUpdateSubscriptionQuery,
  useGetPromotionQuery,
  useCreatePaymentMethodQuery,
  Subscription,
  SubscriptionItem,
} from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import { formatStripePrice } from 'lib/billing-helpers'
import { Tier, TIERS } from './tier-config'

import deriveFromSubscription from '../derivePlanFromSubscription'
import { usePrices, BillingInterval, Extras } from './usePrices'
import TierOptions from './tier-options'
import ConfigurePlan from './configure-plan'
import Checkout from './checkout'
import CheckoutEnterprise from './checkout/checkout-enterprise'
import compileSubscriptionItemChanges from './compileSubscriptionItems'
import PaymentMethodModal from '../../payment/add-method-modal'

import styles from './styles.module.less'

const ChargeNotice = ({ total = 0, currency }) => {
  if (total === 0) return null
  return (
    <p className={styles.margin_top}>
      You will be {total >= 0 ? 'charged' : 'credited'} {formatStripePrice(total, currency)} immediately
    </p>
  )
}

const isConfigurable = (tier: Tier | null) => {
  return tier === TIERS.business
}

// assumes there's only one for each type
const findItemByType = (type: string, items?: SubscriptionItem[]) => items?.find(item => item.price?.metadata?.type === type)
const findTierItem = findItemByType.bind(null, 'tier')
const findInvocationsItem = findItemByType.bind(null, 'invocations')


const ManageSubscriptionModal = ({ visible, setVisible }) => {
  const { organization } = useOrganization()

  // query to v1 billing: customer, subscriptions, paymentMethods <-- stripe objects. Only subscriptionId is used
  const { data: billing } = useGetBillingQuery()
  const subscriptionId = billing?.subscriptions?.[0]?.id

  // stripe prices. Takes 1+s to load, hence the pricesLoading added here
  const { prices, calculate, isLoading: pricesLoading } = usePrices()

  // get full subscription and upcomingInvoice by the subscriptionId
  const { data: subscription, isLoading: subscriptionLoading } = useGetSubscriptionQuery({ subscriptionId })
  const { data: invoiceUpcoming } = useGetNextInvoiceQuery({ subscriptionId })
  const current = React.useMemo(() => deriveFromSubscription(subscription?.items), [subscription])

  // to check if any is added
  const { data: paymentMethods } = useGetPaymentMethods()

  const { data: promotion } = useGetPromotionQuery({ payload: 'code' })

  const { mutateAsync: updateSubscription, isLoading: subscriptionUpdating } = useUpdateSubscriptionQuery()
  const { mutateAsync: createMethod } = useCreatePaymentMethodQuery()

  enum Step {
    // Removed
    ChooseTier = 99,
    Configure = 0,
    Checkout = 1,
  }
  const InitialStep = Step.Configure
  const [currentStep, setCurrentStep] = useState<Step>(InitialStep)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [selectedTier, setSelectedTier] = useState<Tier>(TIERS.business)
  const [billingInterval, setBillingInterval] = useState<BillingInterval>(BillingInterval.month)
  const [extras, setExtras] = useState<Extras>({
    lambdaInvocations: 0,
    awsAccounts: 0,
  })

  const [invoicePreview, setInvoicePreview] = useState<any>({})
  const [stripePromise] = useState(() => loadStripe(CONFIG.STRIPE_API_KEY))

  const IN_TRIAL = subscription?.status === 'trialing'

  const hasScheduledSubscription = React.useMemo(() => {
    const upcomingTierItem = findTierItem(invoiceUpcoming?.lines)
    const notTrialItem = upcomingTierItem?.price?.metadata?.tier !== 'trial'

    return IN_TRIAL && notTrialItem
  }, [subscription, invoiceUpcoming])

  const isSubscriptionSame = React.useMemo(() => {
    return (
      selectedTier === current.tier &&
      billingInterval === current.billingInterval &&
      extras.lambdaInvocations === current.extras.lambdaInvocations &&
      extras.awsAccounts === current.extras.awsAccounts
    )
  }, [selectedTier, billingInterval, extras])

  const currentInvocationsItem = findInvocationsItem(hasScheduledSubscription ? invoiceUpcoming?.lines : subscription?.items)
  const currentPriceItem = currentInvocationsItem?.price

  const hasPaymentMethod = paymentMethods?.length > 0
  const currentTier = subscription?.items?.find(item => item.price?.metadata?.type === 'tier')?.price?.metadata?.tier

  const summary = React.useMemo(() => {
    return selectedTier && calculate(selectedTier, billingInterval, extras) || null
  }, [selectedTier, extras, billingInterval])

  useEffect(() => {
    if (isEmpty(prices) || isEmpty(subscription) || subscriptionUpdating) return
    // TODO: sort this out. This seems to
    //       on every subscription update(supposedly after checkout as well)
    //       - if scheduled update take tier from upcoming invoice
    //       - if not scheduled update then
    //        - if in trial and has not scheduled update(it's checked twice) act like user has chosen business tier
    //        - if not in trial use organization tier
    // All in all: maps trial to scheduled tier or business tier or organization tier
    const tier = hasScheduledSubscription ? currentPriceItem?.metadata?.tier : IN_TRIAL ? TIERS.business : organization?.tier
    handleSelectPrice(undefined, currentPriceItem?.interval, TIERS.business)
  }, [prices, subscription])

  // Should only ever happen once when subscription is initially loaded
  useEffect(() => {
    if (current.tier) {
      // Avoid resetting the tier to anything else than Business
      // setSelectedTier(current.tier)
    }
    setBillingInterval(current.billingInterval)
    setExtras(current.extras)
  }, [current])

  const handleSelectPrice = (_value = '0', _interval = 'month', givenTier = selectedTier) => {
    setSelectedTier(givenTier)
  }

  const handleSubscriptionChange = async () => {
    if (!summary || !subscription) {
      return null
    }
    const scheduled = IN_TRIAL ? { scheduled: true } : {}
    const discount = isEmpty(promotion) ? {} : { coupon: promotion?.[0]?.coupon?.id }

    const payload = {
      items: compileSubscriptionItemChanges(subscription.items, summary.items),
      ...scheduled,
      ...discount
    }

    try {
      await updateSubscription({ identity: subscriptionId, payload })

      setVisible(false)
      setCurrentStep(InitialStep)
      message.success('Subscription plan updated successfully')
    } catch (error) {
      message.error('Something went wrong.')
      console.log(error)
    }
  }

  const handleContactSales = () => {
    const email = 'sales@dashbird.io'
    const subject = 'Dashbird Enterprise Subscription'
    const body = `My organization ${organization?.name} is interested in the Enterprise plan`
    const newWindow = window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

    setVisible(false)
    setCurrentStep(InitialStep)
  }

  const fromFreeToFree = currentTier === TIERS.free && selectedTier === TIERS.free

  const steps = [/*{
    title: 'choose tier',
    next: {
      title: 'Next',
      action: () => setCurrentStep(isConfigurable(selectedTier) ? Step.Configure : Step.Checkout),
      disabled: { condition: pricesLoading || !selectedTier }
    }
  }, */{
    title: 'configure plan',
    next: {
      title: 'Continue to checkout',
      action: () => setCurrentStep(Step.Checkout),
      disabled: { condition: isSubscriptionSame, title: 'Already subscribed to this item' }
    },
    // back: { title: 'Back', action: () => setCurrentStep(InitialStep) }
  }, {
    title: 'finish',
    next: {
      title: selectedTier === TIERS.free ? 'Switch to free' : selectedTier === TIERS.business ? 'Confirm' : 'Contact sales',
      action: selectedTier === TIERS.enterprise ? () => handleContactSales() : () => handleSubscriptionChange(),
      disabled: {
        condition: (selectedTier === TIERS.business && !hasPaymentMethod) || fromFreeToFree,
        title: fromFreeToFree ? 'You are already in the free tier' : 'Add a payment method before subscribing'
      }
    },
    back: { title: 'Back', action: () => setCurrentStep(isConfigurable(selectedTier) ? Step.Configure : InitialStep) }
  }]

  if (!subscription || subscriptionLoading) {
    return <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      width={850}
      footer={null}
    >
      <Skeleton />
    </Modal>
  }

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      width={850}
      footer={null}
    >
      {/*
      <Steps
        className={styles.steps_container}
        labelPlacement={'vertical'}
        current={currentStep}
        items={
          steps.map(({ title }) => ({ title }))
        }
      />
      */}
      {currentStep === Step.ChooseTier && <TierOptions selectedTier={selectedTier} setSelectedTier={setSelectedTier} loading={pricesLoading} />}
      {currentStep === Step.Configure && selectedTier === TIERS.business && (
        <ConfigurePlan
          tier={selectedTier}
          billingInterval={billingInterval}
          setBillingInterval={setBillingInterval}
          extras={extras}
          setExtras={setExtras}
          summary={summary}
      />)}
      {currentStep === Step.Checkout && selectedTier !== TIERS.enterprise && selectedTier && (
        <Checkout
          summary={summary}
          tier={selectedTier}
          subscription={subscription}
          billingInterval={billingInterval}
          preview={invoicePreview}
          setPreview={setInvoicePreview}
      />)}
      {currentStep === Step.Checkout && selectedTier === TIERS.enterprise && <CheckoutEnterprise />}
      <div className={styles.modal_buttons}>
        <div>{steps[currentStep].back && <Button type='primary' onClick={steps[currentStep].back?.action}>{steps[currentStep].back?.title}</Button>}</div>
        {currentStep === Step.Checkout && selectedTier === TIERS.business && !hasPaymentMethod && (
          <Button
            onClick={() => setShowPaymentModal(true)}
            className={styles.add_payment_btn}
            type='default'
          >Add a payment Method</Button>
        )}
        {steps[currentStep].next && (
          <div className={styles.confirm_btns}>
            <Tooltip title={steps[currentStep].next.disabled?.condition ? steps[currentStep].next.disabled?.title : ''}>
              <Button
                type='primary'
                onClick={steps[currentStep].next.action}
                loading={subscriptionUpdating || pricesLoading}
                disabled={steps[currentStep].next?.disabled?.condition}>
                {steps[currentStep].next.title}
              </Button>
            </Tooltip>
            {currentStep === Step.Checkout && !isEmpty(invoicePreview) && IN_TRIAL &&
              <ChargeNotice total={IN_TRIAL && selectedTier === TIERS.free ? 0 : invoicePreview?.total} currency={invoicePreview?.currency} />}
          </div>
        )}
      </div>
      {!hasPaymentMethod && (
        <Elements stripe={stripePromise}>
          <PaymentMethodModal visible={showPaymentModal} setVisible={setShowPaymentModal} createMethod={createMethod} customer={undefined} />
        </Elements>
      )}
    </Modal>
  )
}

export default ManageSubscriptionModal
