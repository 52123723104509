import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { eventsStoragePaths, INVOCATION_ACTIVE } from './index'

export const getInvocation = async (account, identity) => {
  const data = await apiFetch(eventsStoragePaths.event(account, identity))
  return data ? data[0] : {}
}

export const useInvocationQuery = (hash) => {
  const { account } = useOrganization()

  return useQuery(
    [...INVOCATION_ACTIVE, hash, account?.id],
    () => getInvocation(account?.id, hash),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!hash,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
