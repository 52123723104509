import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { objectify } from 'lib/utils'

import Metrics from 'components/metrics'
import TotalMetrics from './services'
import { RDS_STORAGE } from './services/rds-instance'
import { OPENSEARCH_ULTRAWARM } from './services/opensearch'

const getItems = ({ service, item, data, selected, onSelect, metadata }) => {
  const attributes = [data, item, metadata]

  const selectedValue = useSelector((state) => state.metric.active)

  const RDSAurora = objectify(item?.attributes)?.engine === 'aurora'
  const opensearchUltrawarmDisabled = objectify(item?.attributes)?.clusterConfig && !JSON.parse(objectify(item?.attributes)?.clusterConfig)?.warmEnabled

  if (!Array.isArray(TotalMetrics[service])) {
    throw new Error(`Missing metrics definition for ${service}`)
  }

  return TotalMetrics[service].map(metric => {
    if (metric.key === RDS_STORAGE && RDSAurora) return undefined
    if (metric.key === OPENSEARCH_ULTRAWARM && opensearchUltrawarmDisabled) return undefined
    return {
      ...metric,
      value: selected === metric.key && (selectedValue || {})[selected] ? selectedValue[selected] : metric?.filter.apply(null, attributes),
      subvalue: metric.subvalue && metric.subvalue.apply(null, attributes),
      selected: metric.key === selected,
      onClick: metric.selectable && (() => onSelect(metric.key))
    }
  })
}

const InventoryMetrics = ({ service, loading, item, data, selected, onSelect, metadata, fixed, drawer }) => {
  if (loading || isEmpty(data) || !service) return null

  const items = getItems({ service, item, data, selected, onSelect, metadata })
  return <Metrics items={items} fixed={fixed} drawer={drawer} />
}

export default InventoryMetrics
