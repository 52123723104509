import React from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'

import { useAccountRoutes } from 'containers/routes'
import { getLogsKey } from 'lib/dashboards-helpers'

import EditableTitle from 'features/editable-title'
import OnDemandResults from 'features/on-demand-search/results'
import Loading from 'components/layout/content/loading'
import Empty from 'components/layout/content/empty'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'
import SearchResult from 'containers/search/details/item'

import Actions from '../actions'
import styles from '../styles.module.less'

const GroupedByInvocation = ({ results, selected, dashboardId, routes }) => {
  if (results?.length === 0) return <Empty title='No results found' />
  return (
    results.map((result, idx) => {
      return (
        <SearchResult
          key={idx}
          bordered
          selected={selected?.requestId}
          item={result}
          resource={result?.resource}
          url={(result?.resource || result?.resource?.id) && { pathname: routes.dashboards.invocation.url({ dashboardId, resourceId: result?.resource?.id, invocationHash: result.eventId }) }}
        />
      )
    })
  )
}

const LogsWidget = ({ widget, logs, staticWidget, onDelete, onEdit, onDuplicate, onUpdateName }) => {
  const { dashboardId } = useParams()
  const routes = useAccountRoutes()
  const key = getLogsKey(widget)
  const data = logs?.data[key]?.data || []

  if (!data.length && logs?.loading) return <Loading height='100%' />

  return (
    <div className={classnames(styles.text_wrapper, styles.widget_container)}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            displayContent={widget?.name || 'Logs'}
            value={widget?.name || 'Logs'}
            onSubmit={(value) => onUpdateName(widget, value)}
            color='dark'
            size={'xs'}
            uppercase
          />
        </div>
        <div className={styles.actions}>
          {!staticWidget && <IconButton icon={<DragIcon />} className='dbDragHandle' />}
          <Actions
            widget={widget}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </div>
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapper}>
        {widget?.definition?.service === 'lambda'
          ? (
            <>
              <GroupedByInvocation
                results={data}
                routes={routes}
                dashboardId={dashboardId}
              />
            </>)
          : (
            <OnDemandResults logs={data} />)}
      </div>
    </div >
  )
}

export default LogsWidget
