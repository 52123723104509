import React from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

export default function (props) {
  return (
    <Link
      className={props.className}
      target={props.target}
      style={props.style}
      to={props.to}>
      <LinkOutlined style={{ paddingRight: 2 }} />
      {props.children}
    </Link>
  )
}
