import React from 'react'
import Numeral from 'numeral'
import { isEmpty } from 'lodash'

import { Line, Area } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KDA_CPU_UTILIZATION } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const KinesisAnalyticsCPUUtilizationChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KDA_CPU_UTILIZATION]: data[KDA_CPU_UTILIZATION]
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') return [`${Numeral(value[0]).format('0.[00]')} - ${Numeral(value[1]).format('0.[00]')}%`, undefined]
    return [`${Numeral(value).format('0.[00]')}%`, undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [KDA_CPU_UTILIZATION]: metric[`${KDA_CPU_UTILIZATION}_avg`],
      diff: [metric[`${KDA_CPU_UTILIZATION}_min`], metric[`${KDA_CPU_UTILIZATION}_max`]]
    }
  })

  const showDiff = !isEmpty(formatted[0]?.diff)

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0]'
      span={span}
      tabKey={KDA_CPU_UTILIZATION}
      formatTab={value => `${Numeral(value).format('0.[00]')}%`}
      formatTooltip={tooltipFormatter}
      percentageChart
    >
      {data[KDA_CPU_UTILIZATION] && <Line type='monotone' dataKey={KDA_CPU_UTILIZATION} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />}
      {showDiff && <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Min - Max' />}

    </ChartContainer>
  )
}

export default KinesisAnalyticsCPUUtilizationChart
