import React, { useCallback } from 'react'

import { Bar } from 'recharts'
import { groupBy } from 'lodash'

import ChartContainer from 'components/charts/container'
import { chartHeight } from 'components/charts/utils'
import colors from 'lib/colors'
import Section from 'components/layout/content/section'

const ScheduledQueryChart = ({ metrics, filtered, loading }) => {
  const mapEventsData = useCallback((total, filtered) => {
    if (!total) return []
    const allData = [
      ...total.datapoints.default.map(item => ({ date: item.date, occurrences: item.sum })),
      ...filtered?.datapoints?.default?.map(item => ({ date: item.date, filtered: item.sum })) || []
    ]

    const grouped = groupBy(allData, 'date')
    return Object.keys(grouped).map(date => {
      const values = grouped[date]
      return {
        date,
        occurrences: values[0]?.occurrences || values[1]?.occurences || 0,
        filtered: values[0]?.filtered || values[1]?.filtered || 0
      }
    })
  }, [metrics, filtered])

  const chartData = mapEventsData(metrics, filtered)

  return (
    <Section solid>
      <ChartContainer
        data={chartData}
        height={chartHeight}
        loading={loading}
      >
        <Bar dataKey='occurrences' name='Events' fill={colors('chart', 'primary')} barSize={4} />
        {filtered && <Bar dataKey='filtered' name='Filtered' fill={colors('chart', 'redLight')} barSize={4} />}
      </ChartContainer>
    </Section>
  )
}

export default ScheduledQueryChart
