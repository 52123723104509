import React, { useState, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { v4 as uuid } from 'uuid'
import { Button } from 'antd'

import MarketingColumnLayout from 'components/layout/column-marketing'
import OnboardingHexagons from './hexagons'
import LoadingDots from 'components/loading-dots'
import OrgAccountForm from './org-account-form'
import ConnectAws from './connect-aws'
import PollingScreen from './polling'
import Invitation from './invitation'

import { useSwitchAccount } from 'features/layout/navigation/account-picker'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery, useDelegationsQuery, useCreateDelegationQuery, useUserInvitationsQuery } from 'hooks/api'

import styles from './styles.module.less'

const CREATE_ORGANIZATION = 0
const CONNECT_AWS = 1
const POLLING = 2

const ACCEPT_INVITATION = -1

const isDefaultOrganization = (organizations) => {
  if (organizations?.length === 1 &&
    organizations?.[0]?.accounts.length === 1 &&
    organizations?.[0]?.accounts?.[0]?.name === 'Production' &&
    organizations?.[0]?.organization?.name === 'Default Organization') return true
  return false
}

const Onboarding = () => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const [step, setStep] = useState(CREATE_ORGANIZATION)
  const [pollingDelegations, setPollingDelegations] = useState(false)
  const [pollingOrganizations, setPollingOrganizations] = useState(false)

  const [externalId, setExternalId] = useState(uuid)
  const [delegationName, setDelegationName] = useState('')

  const { user, logout } = useUser()
  const { organization, account } = useOrganization()

  const { mutate: createDelegation, isLoading: creatingDelegation } = useCreateDelegationQuery()
  const { data: invitations, isLoading: invitationsLoading, isRefetching: refetchingInvitations } = useUserInvitationsQuery()
  const { data: delegations } = useDelegationsQuery({ refetchInterval: pollingDelegations })
  const { data: organizations, isLoading: initialOrganizationLoading, isRefetching } = useOrganizationsQuery({ refetchInterval: pollingOrganizations })
  const { switchAccount } = useSwitchAccount({ organizations })

  const handlePollingDelegations = (value) => {
    if (!isEmpty(delegations)) return
    setPollingDelegations(30 * 1000)
    setStep(POLLING)
    setDelegationName(value)
  }

  const handleCreateDelegationManual = (values) => {
    const { externalId, arn, delegationName } = values
    setDelegationName(delegationName)
    setExternalId(externalId)
    createDelegation({ name: delegationName, externalId, roleArn: arn })
    setStep(POLLING)
  }

  const handlePollingOrganizations = () => {
    if (isEmpty(delegations)) return
    setPollingOrganizations(30 * 1000)
  }

  const handleSignOut = () => {
    logout()
    queryClient.clear()
  }

  const cancelStack = () => {
    setPollingDelegations(false)
    setPollingOrganizations(false)
    setStep(CONNECT_AWS)
  }

  useEffect(() => {
    if (!organization?.id || !account?.id) return
    if (organization?.onboarded) {
      setPollingOrganizations(false)
      ReactGA.event({ category: 'Onboarding', action: 'Onboarding successful', label: user?.attributes?.email.replace('@', '.at.') })
      return history.push('/')
    }
  }, [organization])

  useEffect(() => {
    if (isEmpty(delegations)) return

    setPollingDelegations(false)
    setStep(POLLING)
    handlePollingOrganizations()
  }, [delegations])

  useEffect(() => {
    if (organizations?.length === 0) return
    if (isDefaultOrganization(organizations)) return setStep(CREATE_ORGANIZATION)
    setStep(CONNECT_AWS)
  }, [organizations])

  useEffect(() => {
    if (isEmpty(invitations)) return

    setStep(ACCEPT_INVITATION)
  }, [invitations])

  return (
    <MarketingColumnLayout
      view={step === 1 ? 'onboarding_aws' : 'onboarding_org'}
      title={step === 1 ? 'Sleep well while you trust us with your data' : 'Organize your data the way you like'}
      subtitle={step === 1 ? 'Dashbird only requires a CloudFormation stack which provides read-only access to your account.' : 'Gather all resources from multiple AWS account under one place for easier monitoring and troubleshooting.'}
      cta={{ text: step === 1 ? 'Preview the template' : 'Learn more', url: step === 1 ? CONFIG.CLOUDFORMATION_URL : 'https://dashbird.io/docs/' }}
      hexagons={<OnboardingHexagons step={step} />}
    >
      <div className={styles.wrapper}>
        <div>
          {initialOrganizationLoading || invitationsLoading
            ? <LoadingDots />
            : step === ACCEPT_INVITATION
              ? <Invitation goBack={() => setStep(CREATE_ORGANIZATION)} refetchingOrgs={isRefetching || refetchingInvitations || invitations?.length === 0} />
              : step === CREATE_ORGANIZATION
                ? <OrgAccountForm viewInvitations={() => setStep(ACCEPT_INVITATION)} />
                : step === CONNECT_AWS
                  ? <ConnectAws
                    handlePollingDelegations={handlePollingDelegations}
                    createDelegation={handleCreateDelegationManual}
                    creatingDelegation={creatingDelegation}
                    externalId={externalId}
                    delegationName={delegationName}
                  />
                  : <PollingScreen
                    cancelStackCreation={cancelStack}
                    externalId={externalId}
                    handlePollingOrganizations={handlePollingOrganizations} />}
        </div>
        <div className={styles.footer_buttons}>
          {organizations?.length > 1
            ? <div className={styles.account_picker}>
              <p>SWITCH TO ANOTHER ACCOUNT:</p>
              {organizations?.map(org => {
                if (org.organization.id === organization?.id) return null
                return (
                  <>
                    {org?.accounts?.map(acc => {
                      const keyPath = [`${org.organization.id}:${acc.id}`, 'accounts']
                      return <Button key={acc.id} type='link' onClick={() => switchAccount(keyPath)}>{org.organization.name} - {acc.name}</Button>
                    })}
                  </>
                )
              })
              }
            </div>
            : null}

          <Button type='link' onClick={() => handleSignOut()}>Sign out</Button>
        </div>
      </div>

    </MarketingColumnLayout>
  )
}

export default Onboarding
