import React, { useContext } from 'react'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons'
import { faWaveSquare } from '@fortawesome/pro-light-svg-icons/faWaveSquare'

import { formatDuration } from 'components/charts/utils'

import Metrics from 'components/metrics'
import { ScrollContext } from 'components/layout/content'

const tabs = (alarm, count = 0, metric, fixed) => [{
  title: 'Violations',
  icon: faBellExclamation,
  value: count,
  selected: !fixed
}, {
  title: 'severity',
  icon: faExclamationTriangle,
  value: alarm?.severity.toUpperCase()
}, {
  title: 'threshold',
  icon: faWaveSquare,
  value: `${alarm?.threshold} ${metric?.unit || ''}`
}, {
  title: 'frequency',
  icon: faClock,
  value: formatDuration(alarm?.duration * 1000)
}]

const AlarmMetrics = ({ alarm, count, service }) => {
  const { fixed: fixedHeader } = useContext(ScrollContext)

  const metric = service?.metrics?.find(item => item.value === alarm?.metric)

  return (
    <>
      <Metrics items={tabs(alarm, count, metric)} />
      {fixedHeader && <Metrics items={tabs(alarm, count, metric, fixedHeader)} fixed />}
    </>
  )
}

export default AlarmMetrics
