import React from 'react'
import PrettyPrinter from 'components/json-pretty'
import Empty from 'components/layout/content/empty'
import styles from './styles.module.less'

const Reference = ({ data }) => {
  if (!data) return <Empty title='No reference object found' />

  const reference = {
    ...data
  }

  delete reference?.children
  delete reference?.subsegments
  delete reference?.deepCountChildren

  return (
    <div className={styles.scroll_wrapper}>
      <PrettyPrinter
        jsonString={reference}
        collapsed={false}
      />
    </div>
  )
}

export default Reference
