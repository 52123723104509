import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { OPENSEARCH_SHARDS } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchShardsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [OPENSEARCH_SHARDS]: data.OPENSEARCH_CLUSTER_SHARDS_ACTIVE,
    unassigned: data.OPENSEARCH_CLUSTER_SHARDS_UNASSIGNED,
    delayedUnassigned: data.OPENSEARCH_CLUSTER_SHARDS_DELAYED_UNASSIGNED,
    primaryActive: data.OPENSEARCH_CLUSTER_PRIMARY_SHARDS_ACTIVE,
    initializing: data.OPENSEARCH_CLUSTER_SHARDS_INITIALIZING,
    relocating: data.OPENSEARCH_CLUSTER_SHARDS_RELOCATING
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={OPENSEARCH_SHARDS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={OPENSEARCH_SHARDS} name='Active' stackId='a' fill={colors('chart', 'primary')} barSize={4} />
      <Bar dataKey='unassigned' name='Unassigned' stackId='a' fill={colors('chart', 'redLight')} barSize={4} />
      <Bar dataKey='primaryActive' name='Primary active' stackId='a' fill={colors('chart', 'primaryLight')} barSize={4} />
      <Bar dataKey='initializing' name='Initializing' stackId='a' fill={colors('chart', 'green')} barSize={4} />
      <Bar dataKey='relocating' name='Relocating' stackId='a' fill={colors('chart', 'yellowLight')} barSize={4} />
      <Bar dataKey='delayedUnassigned' name='Delayed unassigned' stackId='a' fill={colors('chart', 'red')} barSize={4} />
    </ChartContainer>
  )
}

export default OpensearchShardsChart
