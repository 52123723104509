import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import querystring from 'query-string'
import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { ALARMS_LIST } from './index'
import { eventPaths as paths, useAllResourcesQuery } from '../index'

const mapResourcesToAlarms = (alarms, resources) => {
  if (!alarms) return undefined
  if (!resources) return alarms

  return alarms?.map(alarm => {
    return {
      ...alarm,
      resource: resources[alarm?.resource]
    }
  })?.filter(item => item.resource)
}

const memoizedResponse = memoizeOne(mapResourcesToAlarms, isEqual)

const getAlarms = async (account, filters = {}) => {
  const query = querystring.stringify(omitEmpty({ status: 'open', type: 'alarm', ...filters }))
  const data = await apiFetch(paths.filter(paths.events(account), query))
  return data?.data
}

export const useAlarmsQuery = (query) => {
  const { account } = useOrganization()
  const { data: resources } = useAllResourcesQuery()

  const queryOpts = useQuery(
    [...ALARMS_LIST, query && query, account?.id],
    () => getAlarms(account?.id, query),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 5 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, resources)

  return {
    ...queryOpts,
    data
  }
}
