import React, { useContext } from 'react'
import { format } from 'date-fns'
import { Divider, Layout } from 'antd'

import { DrawerContext } from 'components/layout/drawer'
import { TraceContext } from '../hooks/trace-context'
import Metrics from 'components/metrics'
import Section from 'components/layout/content/section'
import FixedHeader from 'components/layout/fixed-header'
import { ArrowLeftIcon, ArrowRightIcon, AwsServiceIcon, ClockIcon } from 'components/icons'
import { formatDuration } from 'components/charts/utils'
import Overview from './overview'
import ResourceTab from './resource'
import Metadata from './metadata'
import Reference from './reference'

import styles from './styles.module.less'

const { Sider } = Layout

const TracesExplorer = () => {
  const { expanded, mobileView } = useContext(DrawerContext)
  const { tooltipData: data } = useContext(TraceContext)


  if (!expanded) return null
  // overlay sider when mobile --> width 100%
  return (
    <Sider
      width={mobileView ? '100%' : 700}
      theme={'light'}
      className={styles.container}
      trigger={<button style={{ position: 'absolute' }}>Close</button>}
    >
      <FixedHeader
        title={data?.name || 'Traces explorer'}
        className={styles.header}
        icon={data && <AwsServiceIcon service={data?.service} solid />}
      />
      <Metrics items={data ? [
        { title: 'Start', value: format(data?.startTime * 1000, 'HH:mm:ss.SSS (dd/MM/yyyy)'), icon: <ArrowLeftIcon /> },
        { title: 'End', value: format(data?.endTime * 1000, 'HH:mm:ss.SSS (dd/MM/yyyy)'), icon: <ArrowRightIcon /> },
        { title: 'Duration', value: formatDuration(data.endTime * 1000 - data.startTime * 1000), icon: <ClockIcon /> }] : [{ title: '', icon: <></> }]}
        fixed className={styles.metrics} />
      {!data
        ? 'Click on trace segments to explore'
        : <>
          <Section title='Flags' titleUpperCase className={styles.subtitle}>
            <Overview data={data} />
          </Section>
          <ResourceTab data={data?.reference} />
          {data?.reference?.metadata && (
            <>
              <Divider />
              <Section title='Metadata' titleUpperCase solid>
                <Metadata data={data?.reference} />
              </Section>
            </>)}
          <Section title='Reference' titleUpperCase solid>
            <Reference data={data?.reference} />
          </Section>
        </>
      }
    </Sider >
  )
}

export default TracesExplorer
