import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Dropdown, Popconfirm } from 'antd'

import { useAccountRoutes } from 'containers/routes'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { WIDGET_KIND } from 'lib/dashboards-constants'
import { isStatic } from 'lib/dashboards-helpers'

import IconButton from 'components/buttons/icon'
import { AddIcon, SpinnerIcon, EllipsisVIcon } from 'components/icons'

import styles from './styles.module.less'

export const Actions = ({ item, onDelete, onDuplicate, loading }) => {
  if (!item || isStatic(item)) return null

  const onClick = ({ key }) => {
    switch (key) {
      case 'duplicate':
        onDuplicate(item.id)
        break
      default:
        break
    }
  }

  const confirm = `Are you sure you want to delete ${item.name}?`
  const items = [
    {
      key: 'duplicate',
      label: <div className={styles.item}>Duplicate</div>
    },
    {
      key: 'delete',
      label: (
        <Popconfirm onClick={(e) => e?.stopPropagation()} title={confirm} placement='bottomRight' onConfirm={() => onDelete(item.id)} overlayStyle={{ width: 300 }}>
          <div className={styles.item}>Delete dashboard</div>
        </Popconfirm>)
    }
  ]

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Dropdown menu={{ items, onClick }} trigger={['click']} overlayClassName={styles.dropdown}>
        <IconButton icon={loading ? <SpinnerIcon /> : <EllipsisVIcon />} className={styles.extra} />
      </Dropdown>
    </PermissionsGate>
  )
}

export const TitleActions = ({ item, group, loading, onCreateWidget }) => {
  const routes = useAccountRoutes()
  if (!item || loading) return null

  if (isStatic(item) && group?.id) {
    return (
      <PermissionsGate scopes={[SCOPES.canEdit]}>
        <Link className={styles.action_button} to={routes.dashboards.resourceGroups.edit.url({ groupId: group.id })}>edit group</Link>
      </PermissionsGate>
    )
  }

  const items = [
    {
      key: 'metrics',
      label: 'Metrics widget'
    }, {
      key: 'invocations',
      label: 'Invocations widget'
    }, {
      key: 'events',
      label: 'Events widget'
    }, {
      key: 'logs',
      label: 'Logs widget'
    }, {
      key: 'text',
      label: 'Text widget'
    }
  ]

  const onClick = ({ key }) => {
    switch (key) {
      case 'metrics':
        onCreateWidget(item, WIDGET_KIND.METRICS)
        break
      case 'invocations':
        onCreateWidget(item, WIDGET_KIND.INVOCATIONS)
        break
      case 'events':
        onCreateWidget(item, WIDGET_KIND.EVENTS)
        break
      case 'logs':
        onCreateWidget(item, WIDGET_KIND.LOGS)
        break
      case 'text':
        onCreateWidget(item, WIDGET_KIND.TEXT)
        break
      default:
        break
    }
  }

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <div className={styles.actions}>
          <Button
            type='link'
            size='small'
            className={styles.action_button}
          >
            Add widget
            <AddIcon />
          </Button>
        </div>
      </Dropdown>
    </PermissionsGate>
  )
}
