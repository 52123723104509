import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { differenceInMinutes, getTime, sub } from 'date-fns'

import EventListItems from './event-list-items'
import DashboardLambdas from './lambda-list-items'
import OnboardingChecklist from './onboarding-checklist'
import GeneralStatistics from './statistics'
import DashboardDatePicker from './date-picker'
import { useOrganization } from 'hooks/context/organization-context'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import styles from './styles.module.less'

const gutter = { xs: 8, sm: 8, md: 10, lg: 12 }

const DashboardMainView = () => {
  const { account, organization } = useOrganization()

  const [start, setStart] = useState(sub(new Date(), { days: 1 }))
  const [end, setEnd] = useState(new Date())
  const [range, setRange] = useState(24 * 60)
  const [relativeSpan, setRelativeSpan] = useState(24 * 60)

  const selectRange = (start, end, relativeSpan) => {
    setStart(start)
    setEnd(end)
    setRelativeSpan(relativeSpan || false)
    setRange(differenceInMinutes(end, start))
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {organization?.name &&
          <>
            <h1 className={styles.title}>{`${organization.name} - ${account.name}`}</h1>
            <DashboardDatePicker
              start={start}
              end={end}
              selectRange={selectRange}
              relativeSpan={relativeSpan}
            />
          </>}
      </div>

      <Row gutter={[gutter, gutter]}>
        <PermissionsGate scopes={[SCOPES.canViewOnboardingChecklist]} errorProps={{ sm: 24, lg: 24 }}>
          <Col xs={24} sm={12} lg={18}>
            <GeneralStatistics start={getTime(start)} end={getTime(end)} span={range} gutter={gutter} />
          </Col>
        </PermissionsGate>
        <PermissionsGate scopes={[SCOPES.canViewOnboardingChecklist]}>
          <Col xs={24} sm={12} lg={6}>
            <OnboardingChecklist className={styles.container_height} />
          </Col>
        </PermissionsGate>
        <Col xs={24} md={8}>
          <EventListItems types={['error']} limit={10} />
        </Col>
        <Col xs={24} md={8}>
          <EventListItems types={['alarm', 'insight']} />
        </Col>
        <Col xs={24} md={8}>
          <DashboardLambdas title='Top errored functions' type='errors' />
          <DashboardLambdas title='Most active functions' />
        </Col>
      </Row>
    </div>
  )
}

export default DashboardMainView
