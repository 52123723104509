import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { isUndefined } from 'lodash'

import { apiFetch } from 'lib/action-helpers'
import { integrationsPaths as paths, INTEGRATION_ACTIVE, DELEGATION_INTEGRATIONS_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const serialize = (payload) => {
  const body = {
    ...(payload?.interval && { interval: payload.interval }),
    ...(payload?.filters && { filters: payload.filters }),
    ...(!isUndefined(payload?.disabled) && { disabled: payload.disabled })
  }
  return JSON.stringify(body)
}

const updateIntegration = async (account, identity, payload) => {
  return apiFetch(paths.integration(account, identity), { method: 'PATCH', body: serialize(payload) })
}

export const useUpdateIntegrationQuery = () => {
  const queryClient = useQueryClient()
  const { integrationId } = useParams()
  const { account } = useOrganization()
  return useMutation(
    payload => updateIntegration(account.id, integrationId, payload),
    {
      onSuccess: updatedIntegration => {
        queryClient.setQueryData([...INTEGRATION_ACTIVE, integrationId], updatedIntegration)
        queryClient.invalidateQueries([DELEGATION_INTEGRATIONS_LIST])
      }
    }
  )
}
