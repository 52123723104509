import React from 'react'
import moment from 'moment'
import { flatten, isEmpty } from 'lodash'

import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip, Tag } from 'antd'
import { useOrganization } from 'hooks/context/organization-context'
import { useGetInvoicesQuery } from 'hooks/api'
import { formatStripePrice } from 'lib/billing-helpers'

import Content from 'components/layout/content'
import LoadMoreButton from 'components/buttons/load-more'

import styles from './styles.module.less'

const Invoices = () => {
  const { organization } = useOrganization()

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetInvoicesQuery({})
  const invoices = flatten(data?.pages.map(page => page.data))

  const Title = () => <h1>Invoices for <span className={styles.highlighted}>{organization.name}</span> organization</h1>

  return (
    <Content item title={<Title />} breadcrumbs={['settings', 'invoices']} loading={isLoading}>
      {!isEmpty(invoices) && invoices.map(item => {
        return (
          <div className={styles.item_container} key={item.number}>
            <div>
              <div className={styles.row}>
                <p className={styles.item_no}>Invoice no {item.number}</p>
                <Tag color={item.isPaid ? 'purple' : 'red'} className={styles.paid}>{item.isPaid ? 'Paid' : 'Not paid'}</Tag>
              </div>
              <div className={styles.row}>
                <p className={styles.label}>Billing period:  </p><p className={styles.text}>{moment.unix(item.start).format('MMMM Do YYYY')} - {moment.unix(item.end).format('MMMM Do YYYY')}</p>
              </div>
              <div className={styles.row}>
                <p className={styles.label}>Total: </p><p className={styles.text}>{formatStripePrice(item.total, item.currency)}</p>
              </div>
            </div>
            <div className={styles.actions}>
              <Button type='link' size='small' className={styles.invoice_link} href={item.hostedInvoiceUrl} target='_blank' rel='noopener noreferrer'>View invoice</Button>
              <Tooltip title='Download invoice' mouseEnterDelay={0} placement='topRight'>
                <Button icon={<DownloadOutlined />} size='small' className={styles.download_icon} href={item.invoiceUrl} />
              </Tooltip>
            </div>
          </div>
        )
      })}

      <LoadMoreButton onClick={fetchNextPage} isLoading={isFetchingNextPage} hidden={hasNextPage} />
    </Content>
  )
}

export default Invoices
