import React from 'react'
import { Card, Typography, Row, Col } from 'antd'

import { useOrganization } from 'hooks/context/organization-context'
import { useGetPaymentMethodsV1, useGetPaymentMethods } from 'hooks/api'
import { STATUSES } from 'lib/organization-constants'

import SuspensionButton from './suspension-button'

const { Text, Title } = Typography

const SuspensionText = ({ suspended }) => {
  const title = `${suspended ? 'Unsuspend' : 'Suspend'} your organization`
  const text = suspended
    ? 'Once you unsuspend your organization, we will start ingesting data and discovering resources again. A subscription will be created for you and any reserved subscriptions that have not expired will still be valid.'
    : 'Once you suspend your organization, we will stop ingesting data and stop discovering resources. Your subscription will be canceled and you will be charged based on the outstanding data usage. If you have bought a reserved subscription, it will be active until the end your the billing period.'

  return (
    <Row>
      <Col>
        <Title level={4}>{title}</Title>
      </Col>
      <Col>
        <Text>{text}</Text>
      </Col>
    </Row>
  )
}

const DangerZone = ({ className }) => {
  const { organization } = useOrganization()

  const BILLING_V2 = organization?.metadata?.billing === 'v2'
  const { data: paymentMethods, isLoading } = useGetPaymentMethodsV1({ enabled: !BILLING_V2 })
  const { data: paymentMethodsV2, isLoading: paymentMethodsV2Loading } = useGetPaymentMethods({ enabled: BILLING_V2 })

  const loading = isLoading || paymentMethodsV2Loading

  const hasPaymentMethod = BILLING_V2 ? paymentMethodsV2?.length : paymentMethods?.length
  const isSuspended = (organization || {}).status === STATUSES.suspended

  if (isSuspended) {
    return null
  }

  return (
    <Card
      title='Danger Zone'
      className={className}
      loading={loading}
      headStyle={{ backgroundColor: '#ff4d4f', color: '#fff' }}
    >
      <Row>
        <Col span={14}>
          <SuspensionText suspended={isSuspended} />
        </Col>
        <Col offset={2} span={8}>
          <SuspensionButton suspended={isSuspended} paymentMethodExists={hasPaymentMethod} loading={loading} />
        </Col>
      </Row>
    </Card>
  )
}

export default DangerZone
