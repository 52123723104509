import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { RESOURCE_GROUPS_LIST, resourceGroupsPaths as paths, RESOURCE_GROUP_PREVIEW } from './index'

const createResourceGroup = async (account, payload) => {
  const data = apiFetch(paths.groups(account), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateResourceGroupQuery = () => {
  const queryClient = useQueryClient()

  const { account } = useOrganization()

  return useMutation(
    (payload) => createResourceGroup(account.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...RESOURCE_GROUPS_LIST, account.id])
        queryClient.removeQueries([...RESOURCE_GROUP_PREVIEW])
      }
    }
  )
}
