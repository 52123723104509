import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiFetch } from 'lib/action-helpers'

import { NOTIFICATION_POLICY, policyPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createNotificationPolicyConditionTarget = async (account, policy, condition, target, payload) => {
  const data = await apiFetch(paths.target(account, policy, condition, target), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateNotificationPolicyConditionTargetQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ policyId, conditionId, target, payload }) => createNotificationPolicyConditionTarget(account.id, policyId, conditionId, target, payload),
    {
      onMutate: ({ policyId, conditionId, target }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])

        const conditions = previousPolicy.conditions.map(item => {
          if (item.id !== conditionId) return item
          return { ...item, targets: [...item.targets, target] }
        })
        const newPolicy = { ...previousPolicy, conditions }
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)

        return { previousPolicy }
      },
      onError: (err, newItem, context) => {
        if (err) queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
      }
    }
  )
}

const deleteNotificationPolicyConditionTarget = async (account, policy, condition, target) => {
  const data = await apiFetch(paths.target(account, policy, condition, target), { method: 'DELETE' })

  return data
}
export const useDeleteNotificationPolicyConditionTargetQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ policyId, conditionId, target }) => deleteNotificationPolicyConditionTarget(account.id, policyId, conditionId, target),
    {
      onMutate: ({ policyId, conditionId, target }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])

        const conditions = previousPolicy.conditions.map(item => {
          if (item.id !== conditionId) return item
          return { ...item, targets: item.targets.filter(targetItem => targetItem !== target) }
        })

        const newPolicy = { ...previousPolicy, conditions }
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)

        return { previousPolicy }
      },
      onError: (err, newItem, context) => {
        if (err) queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
      }
    }
  )
}
