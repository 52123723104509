export const isDelegationOutOfDate = (delegation) => {
  if (!CONFIG.LATEST_CF_STACK_VERSION || !delegation?.roleArn) return false
  const newestVersion = new Date(CONFIG.LATEST_CF_STACK_VERSION)
  if (!delegation?.version) return true
  const delegationVersion = new Date(delegation.version)
  return newestVersion.getTime() > delegationVersion.getTime()
}

export const extractCFStackRegion = (delegation) => {
  if (!delegation?.stackId) return
  // stackId example "arn:aws:cloudformation:us-east-1:AWS_ACCOUNT_ID:stack/STACK_NAME/GUID"
  const parts = delegation.stackId.split(':')
  return parts[3]
}

export default {
  isDelegationOutOfDate,
  extractCFStackRegion
}
