import React from 'react'
import styles from './styles.module.less'

const Attribute = ({ name, value }) => {
  return (
    <div key={name} className={styles.attribute_block}>
      <h4 className={styles.attribute_name}>{name}</h4>
      <span className={styles.attribute_value}>{value}</span>
    </div>
  )
}

export default Attribute
