import { useInfiniteQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { SEARCH, searchPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const searchLogMatches = async (account, payload, nextToken) => apiFetch(paths.logMatches(account), { method: 'POST', body: JSON.stringify({ ...payload, nextToken }) })

const serializePayload = (payload) => {
  if (!payload) return null
  return {
    service: payload.service,
    targets: payload.targets,
    ...(payload.filters && {
      filters: {
        ...(payload.filters.queryIds && { queryIds: payload.filters.queryIds })
      }
    }),
    ...(payload.start && { start: payload.start }),
    ...(payload.end && { end: payload.end }),
    ...(payload.nextToken && { nextToken: payload.nextToken }),
    ...(payload.limit && { limit: payload.limit }),
    ...(payload.groupBy && { groupBy: payload.groupBy })
  }
}

export const useSearchLogMatchesQuery = (data) => {
  const { account } = useOrganization()
  const payload = serializePayload(data)

  return useInfiniteQuery(
    [SEARCH, payload, payload?.filters, account?.id],
    ({ pageParam }) => searchLogMatches(account?.id, payload, pageParam),
    {
      getNextPageParam: (lastPage, pages) => lastPage.next,
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!payload,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
