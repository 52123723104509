import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Button, Radio, Skeleton, Checkbox, Form } from 'antd'

import { PermissionsGate, SCOPES, useUserPermissions } from 'features/permissions-gate'
import Input from 'components/antd/input'
import { isUserRoleSuperior } from './index'

import styles from './styles.module.less'

const submit = (values, saveUser, orgOwner) => {
  // filter out accounts where current user does not have rights to change
  const formattedPermissions = Object.entries(values.permissions)
    .filter(([key, value]) => key !== 'owner' && value !== 'no-access')
    .reduce((obj, [key, value]) => {
      obj[key] = values.permissions[key]
      return obj
    }, {})

  const permissions = orgOwner
    ? { role: 'owner', permissions: {} }
    : { role: 'user', permissions: { ...formattedPermissions } }

  const firstName = isEmpty(values.givenName) ? {} : { givenName: values.givenName }
  const lastName = isEmpty(values.familyName) ? {} : { familyName: values.familyName }

  const user = {
    ...firstName,
    ...lastName
  }
  saveUser(user, permissions)
}

const EditUser = ({ user, accounts, loading, saveUser, saving }) => {
  const { hasPermission, hasPermissionsIn } = useUserPermissions()
  const [orgOwner, setOrgOwner] = useState(isEmpty(user?.permissions) && user?.role === 'owner')
  const history = useHistory()

  useEffect(() => {
    setOrgOwner(isEmpty(user?.permissions) && user?.role === 'owner')
  }, [user?.role])

  if (loading || !user || isEmpty(accounts)) return <Skeleton />

  const initialValuesAccounts = accounts?.reduce((acc, item) => ({ [item.id]: 'no-access', ...acc }), {})

  return (
    <Form
      className={styles.form}
      layout='vertical'
      onFinish={values => submit(values, saveUser, orgOwner)}
      initialValues={{
        email: user?.email,
        givenName: user?.givenName || '',
        familyName: user?.familyName || '',
        permissions: {
          owner: isEmpty(user?.permissions),
          ...initialValuesAccounts,
          ...user.permissions
        }
      }}
    >
      <Form.Item name='email' label='User email' className={styles.form_item}>
        <Input placeholder={`${user?.email}`} disabled />
      </Form.Item>

      <Form.Item name='givenName' label='User first name' className={styles.form_item}>
        <PermissionsGate scopes={[SCOPES.canManageAllUsers]} errorProps={{ disabled: true }}>
          <Input placeholder={`${user?.givenName || 'User first name'}`} />
        </PermissionsGate>
      </Form.Item>

      <Form.Item name='familyName' label='User last name' className={styles.form_item}>
        <PermissionsGate scopes={[SCOPES.canManageAllUsers]} errorProps={{ disabled: true }}>
          <Input placeholder={`${user?.familyName || 'User last name'}`} />
        </PermissionsGate>
      </Form.Item>

      <PermissionsGate
        scopes={[SCOPES.canManageAllUsers]}
        RenderError={() => orgOwner ? <p className={styles.info}>User role is owner in this organization.</p> : <></>}
      >
        <Form.Item
          name={['permissions', 'owner']}
          label='User permissions'
          className={styles.form_item}
          valuePropName='checked'
        >
          <>
            {user?.role === 'owner' && (
              <p className={styles.description}>
                Removing this will add no-access roles to all available accounts, you can change this below.
              </p>)}
            <Checkbox onChange={() => setOrgOwner(!orgOwner)} checked={orgOwner}>
              Owner for this organization
            </Checkbox>
          </>
        </Form.Item>
      </PermissionsGate>

      {accounts.map(account => {
        const isOwnerInAccount = !user.permissions || (isEmpty(user?.permissions) && user.role === 'owner')
        const role = isOwnerInAccount ? 'owner' : user.permissions[account.id] || 'no-access'
        const disabled = !hasPermissionsIn([SCOPES.canManageUsersAndViewers], account.id)
        const superior = isUserRoleSuperior({ role })

        return (
          <Form.Item
            key={account.id}
            label={`User role for ${account.name}`}
            name={['permissions', account.id]}
            hidden={orgOwner}
            className={styles.form_item}
            tooltip={disabled
              ? 'Admins are allowed to assign User/Viewer roles only in accounts where they are an Admin'
              : superior
                ? 'Only owners are allowed to change Admin user roles'
                : null}
          >
            <Radio.Group
              name={['role', account.id]}
              disabled={disabled}
              options={[
                { value: 'owner', label: 'Owner', disabled: true },
                { value: 'admin', label: 'Admin', disabled: !hasPermission([SCOPES.canManageAllUsers]) },
                { value: 'user', label: 'User', disabled: !hasPermission([SCOPES.canManageAllUsers]) && superior },
                { value: 'viewer', label: 'Viewer', disabled: !hasPermission([SCOPES.canManageAllUsers]) && superior },
                { value: 'no-access', label: 'No access', disabled: !hasPermission([SCOPES.canManageAllUsers]) && superior }
              ]}
              optionType='button'
            />
          </Form.Item>
        )
      })}
      <Form.Item className={styles.form_buttons}>
        <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
        <Button type='link' onClick={history.goBack}>Cancel</Button>
      </Form.Item>
    </Form>
  )
}

export default EditUser
