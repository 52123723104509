import React, { useContext, useState } from 'react'

import Section from 'components/layout/content/section'
import Chart from './charts'
import Metrics from './metrics-tabs'

import { findInventoryById } from 'lib/resources/constants'
import { ScrollContext } from 'components/layout/content'
import FixedChartWrapper from 'components/charts/fixed-chart-wrapper'
import { ServiceContext } from './index'

const InventoryResourceContainer = ({ item, metadata, service, initialChart, children }) => {
  const { fixed: fixedHeader } = useContext(ScrollContext)
  const { metricsData, loadingMetrics, range } = useContext(ServiceContext)

  const [chart, setChart] = useState(initialChart)
  const [fixedChart, setFixedChart] = useState(null)

  const selectFixedChart = (key) => {
    if (fixedChart === key) return setFixedChart(null)
    setFixedChart(key)
  }

  return <>
    <Section cancelMargin>
      <Metrics
        loading={loadingMetrics || !item}
        data={metricsData}
        item={item}
        service={service}
        selected={chart}
        metadata={{ ...metadata }}
        onSelect={setChart}
      />
    </Section>
    <Section solid disableUserSelection >
      <Chart
        loading={loadingMetrics}
        type={chart}
        item={item}
        data={metricsData}
        span={range}
        service={findInventoryById(service)?.service} />
    </Section>
    <Section>
      {children}
    </Section>
    {fixedHeader && (
      <Metrics
        loading={loadingMetrics || !item}
        data={metricsData}
        item={item}
        service={service}
        selected={fixedChart}
        metadata={{ ...metadata }}
        onSelect={selectFixedChart}
        fixed
      />
    )}
    {fixedHeader && fixedChart && (
      <FixedChartWrapper>
        <Chart
          loading={loadingMetrics}
          type={fixedChart}
          data={metricsData}
          span={range}
          service={findInventoryById(service)?.service}
          height={180} />
      </FixedChartWrapper>)
    }
  </>
}

export default InventoryResourceContainer
