import React, { useMemo } from 'react'
import { Card } from 'antd'
import { capitalize } from 'lodash'
import { formatDistanceToNowStrict } from 'date-fns'
import classnames from 'classnames'
import ListItem from 'components/dashboards/list-item'
import SeverityBadge from 'components/badge/severity'
import SkeletonLine from 'components/skeleton-line'
import { findInventoryByType } from 'lib/resources/constants'
import { useAccountRoutes } from 'containers/routes'
import { ErrorIcon, AlarmIcon, AwsServiceIcon, InsightIcon } from 'components/icons'
import { useAllResourcesQuery, useErrorsQuery, useInsightsQuery, useAlarmsQuery } from 'hooks/api'
import { getEventUrl } from 'lib/event-helpers'

import styles from './styles.module.less'

const EventListItems = (props) => {
  const { types, limit } = props
  const routes = useAccountRoutes()

  const { data: resourcesById, isLoading: resourcesLoading } = useAllResourcesQuery()
  const { data: errors, isLoading: loadingErrors } = useErrorsQuery()
  const { data: insights, isLoading: loadingInsights } = useInsightsQuery()
  const { data: alarms, isLoading: loadingAlarms } = useAlarmsQuery()

  const filterItems = (items = []) => items?.sort((a, b) => b.lastOccurredAt - a.lastOccurredAt)?.slice(0, limit || 5)

  const loading = useMemo(() => (loadingAlarms || loadingInsights || loadingErrors || resourcesLoading), [loadingAlarms, loadingInsights, loadingErrors, resourcesLoading])
  const events = useMemo(() => {
    const e = types?.reduce((acc, type) => {
      if (type === 'error') return [...acc, ...filterItems(errors)]
      if (type === 'alarm') return [...acc, ...filterItems(alarms)]
      if (type === 'insight') return [...acc, ...filterItems(insights)]
      return acc
    }, [])

    return e
  }, [errors, alarms, insights, resourcesById])

  const icon = (event) => {
    if (event.type === 'insight') {
      return event.resource.type
        ? <AwsServiceIcon service={findInventoryByType(event.resource.type)?.service} />
        : <InsightIcon status={event?.severity} />
    }

    if (event.type === 'error') {
      return <ErrorIcon status='critical' />
    }

    return <AlarmIcon status={event?.severity} />
  }

  const item = (event) => {
    const { resource } = event
    const delegation = resource?.delegationName ? `| ${resource.delegationName}` : ''

    return {
      title: event?.name,
      info: (<>
        {resourcesLoading ? <SkeletonLine /> : <p className={styles.info}>{`${resource?.name || '<resource deleted or missing>'} ${delegation}`}</p>}
        {event.count && <p className={styles.error_count}>{event.count}</p>}
        {event.severity && <SeverityBadge severity={event.severity} />}
      </>),
      details: `${formatDistanceToNowStrict(event.lastOccurredAt || event.openedAt)} ago`,
      icon: icon(event),
      url: getEventUrl(event, routes),
      type: event.type
    }
  }

  const pluralize = (str) => `${str}s`

  const getTitle = () => {
    const plurals = types.map(pluralize)

    return capitalize(plurals.join(' and '))
  }

  const getNoEventsTitle = () => {
    const plurals = types.map(pluralize)

    return `No ${plurals.join(' or ')} found`
  }

  const getNoEventsIcon = () => {
    if (types[0] === 'error') {
      return <ErrorIcon status='critical' />
    }

    return types[0] === 'alarm' ? <AlarmIcon /> : <InsightIcon />
  }

  return (
    <Card className={classnames({ [styles.loading_padding]: loading })} title={getTitle()} loading={loading}>
      {events?.length
        ? events?.map(event => <ListItem key={event?.id} item={item(event)} />)
        : <ListItem item={{ title: getNoEventsTitle(), type: types[0], icon: getNoEventsIcon() }} />
      }
    </Card>
  )
}

export default EventListItems
