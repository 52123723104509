import React from 'react'
import Numeral from 'numeral'

import { Bar, Line, YAxis } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import { yAxisProps } from 'components/charts/utils'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KINESIS_DS_OUTGOING } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-datastream'

const KinesisDataStreamOutgoingChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KINESIS_DS_OUTGOING]: data.KINESIS_DATA_STREAM_OUTGOING_RECORDS,
    throttles: data.KINESIS_DATA_STREAM_THROTTLES_READS,
    bytes: data.KINESIS_DATA_STREAM_OUTGOING_BYTES
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'bytes') return [Numeral(value).format('0.[00] b'), undefined]
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[0] b'
      span={span}
      tabKey={KINESIS_DS_OUTGOING}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <YAxis yAxisId='records' orientation='right' domain={[0, 'dataMax']} {...yAxisProps} tickFormatter={value => Numeral(value).format('0.[00]a')} />
      <Bar dataKey={KINESIS_DS_OUTGOING} yAxisId='records' stackId='a' fill={colors('chart', 'primary')} name='Records' barSize={4} dot={false} />
      <Bar dataKey='throttles' yAxisId='records' stackId='a' fill={colors('chart', 'red')} name='Throttles' />
      <Line type='monotone' dataKey='bytes' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Bytes' dot={false} />
    </ChartContainer>
  )
}

export default KinesisDataStreamOutgoingChart
