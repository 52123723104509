export const QUERIES = 'queries'
export const QUERIES_LIST = [QUERIES, 'list']
export const QUERY_ACTIVE = [QUERIES, 'active']

export * from './get-query'
export * from './get-queries'
export * from './create-query'
export * from './delete-query'
export * from './update-query'

export const queryPaths = {
  account: (identity) => `/v1/accounts/${identity}`,
  search: (identity) => `/v1/accounts/${identity}/search`,
  queries: (account) => `${queryPaths.search(account)}/queries`,
  query: (account, queryId) => `${queryPaths.queries(account)}/${queryId}`
}
