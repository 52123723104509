import React from 'react'
import Numeral from 'numeral'

import { Bar, Line, YAxis } from 'recharts'

import transform from '../../transform'
import { getDifferenceFromTotal } from '../../../metrics-tabs/helpers'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { yAxisProps } from 'components/charts/utils'
import { KDA_INCOMING_RECORDS, KDA_INCOMING_RECORDS_RATE, KDA_OUTGOING_RECORDS_RATE } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const KinesisAnalyticsRecords = ({ loading, data, height, span, chartKey }) => {
  if (loading || !data) return null

  const recordsPerSecondKey = chartKey === KDA_INCOMING_RECORDS
    ? KDA_INCOMING_RECORDS_RATE
    : KDA_OUTGOING_RECORDS_RATE

  const metrics = transform({
    [chartKey]: getDifferenceFromTotal(data, chartKey),
    [recordsPerSecondKey]: data[recordsPerSecondKey]
  })

  if (!metrics.length) return <Empty height={height} />

  const formatValue = value => Numeral(value).format('0.[00]a')

  const tooltipFormatter = (value) => {
    return [formatValue(value), undefined]
  }

  // First datapoint needs to be removed.
  // KDA_INCOMING_RECORDS and KDA_OUTGOING_RECORDS are reported as records total,
  // but we transform it into usual periodical metric.
  // Total is transformed to diff in getDifferenceFromTotal()
  const formatted = metrics.slice(1)

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={chartKey}
      formatTab={formatValue}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatValue}
    >
      <YAxis yAxisId='rps' orientation='right' domain={[0, 'dataMax']} tickFormatter={formatValue} {...yAxisProps} />
      <Bar dataKey={chartKey} fill={colors('chart', 'primary')} name='Records' barSize={4} />
      <Line type='monotone' dataKey={recordsPerSecondKey} yAxisId='rps' stroke={colors('chart', 'primary')} strokeWidth={1} name='Records per second' dot={false} />
    </ChartContainer>
  )
}

export default KinesisAnalyticsRecords
