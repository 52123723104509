import React from 'react'
import { isEmpty } from 'lodash'
import Badge from 'components/badge/severity'

import styles from './styles.module.less'

const Overview = ({ data }) => {
  if (!data?.startTime) return null
  return (
    <div className={styles.wrapper}>
      <table>
        <tbody>
          <tr>
            <td>Error:</td>
            <td>{
              isEmpty(data?.errors)
                ? <Badge text={'NONE'} severity='ok' bordered />
                : <span className={styles.error}>{data?.errors?.[0]?.message}</span>
            }</td>
          </tr>
          <tr>
            <td>Fault:</td>
            <td>{
              data?.reference?.fault
                ? <Badge text={'YES'} severity='warning' bordered />
                : <Badge text={'NONE'} severity='ok' bordered />}
            </td>
          </tr>
          <tr>
            <td>Throttle:</td>
            <td>{
              data?.reference?.throttle
                ? <Badge text={'YES'} severity='warning' bordered />
                : <Badge text={'NONE'} severity='ok' bordered />}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Overview
