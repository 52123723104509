import numeral from 'numeral'

import { faNetworkWired, faServer, faWind } from '@fortawesome/pro-light-svg-icons'

import { CONNECTIONS, DATA, LCU } from './elb-application-load-balancer'
import { getSparklineByName, getLast, getSum } from '../helpers'

const metrics = [{
  key: CONNECTIONS,
  icon: faNetworkWired,
  title: 'Connections',
  filter: (collection) => numeral(getLast(collection, 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE')).format('0a'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE'),
  selectable: true
},
{
  key: LCU,
  icon: faServer,
  title: 'Consumed LCUs',
  filter: (collection) => numeral(getSum(collection, 'ELB_LOADBALANCER_LCU')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_LCU'),
  selectable: true
},
{
  key: DATA,
  icon: faWind,
  title: 'Data Throughput',
  filter: (collection) => numeral(getSum(collection, 'ELB_LOADBALANCER_PROCESSED_BYTES')).format('0.00b'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_PROCESSED_BYTES'),
  selectable: true
}] // possibly add processed packets metrics?

export default metrics
