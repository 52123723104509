import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, DASHBOARDS_LIST } from './index'
import { useAccountRoutes } from 'containers/routes'

const deleteDashboard = async (account, identity) => {
  const data = await apiFetch(paths.dashboard(account, identity), { method: 'DELETE' })
  return data
}

export const useDeleteDashboard = () => {
  const queryClient = useQueryClient()

  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()

  return useMutation(
    dashboardId => deleteDashboard(account.id, dashboardId),
    {
      onMutate: (dashboardId) => {
        const previousDashboards = queryClient.getQueryData([...DASHBOARDS_LIST, account.id])

        const newDashboards = previousDashboards?.filter(item => item.id !== dashboardId)
        queryClient.setQueryData([...DASHBOARDS_LIST, account.id], newDashboards)

        return { previousDashboards }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...DASHBOARDS_LIST, account.id], context.previousDashboards)
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...DASHBOARDS_LIST, account.id])
        history.push(routes.dashboards.url())
      }
    }
  )
}
