import React from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'

import { objectify, formatBoolean } from 'lib/utils'

import Metadata from 'containers/inventory/shared/metadata'
import PrettyPrinter from 'components/json-pretty'
import SecurityGroups from '../security-groups'
import Region from '../region'
import Enabled from 'containers/inventory/shared/enabled'
import Endpoint from '../endpoint'

import styles from '../styles.module.less'

const formatDate = (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a')

const OptionGroups = ({ data }) => {
  return data.map(group => (
    <p key={group.optionGroupName}>{group.optionGroupName} (status: {group.status})</p>
  ))
}

const Networking = ({ data }) => {
  return (
    <>
      <div className={styles.region_wrapper}>AVAILABLE IN <Region reg={data?.availabilityZone} /></div>
      <p>SUBNET GROUP</p>
      <PrettyPrinter jsonString={JSON.parse(data.dbSubnetGroup)} collapsed={false} />
    </>
  )
}

const RDSInstanceConfig = ({ attributes, inverse }) => {
  const data = objectify(attributes)

  const items = [{
    title: 'General',
    children: [
      { key: 'Licence mode', value: data?.licenseModel },
      { key: 'Option groups', value: <OptionGroups data={JSON.parse(data?.optionGroupMemberships)} /> },
      { key: 'Instance resource ID', value: data?.dbiResourceId },
      { key: 'Instance create time', value: formatDate(data?.instanceCreateTime) },
      { key: 'Deletion protection', value: <Enabled data={data.deletionProtection} /> }
    ]
  }, {
    title: 'Storage',
    children: [
      { key: 'Encryption', value: <Enabled data={data.storageEncrypted} /> },
      { key: 'Storage type', value: data.storageType },
      { key: 'Allocated storage', value: `${data?.allocatedStorage}GiB` },
      { key: 'Max allocated storage', value: `${data?.maxAllocatedStorage}GiB` }
    ]
  }, {
    title: 'Availability',
    children: [
      { key: 'IAM DB authentication', value: <Enabled data={data.iamDatabaseAuthenticationEnabled} /> },
      { key: 'Multi AZ', value: formatBoolean(data?.multiAZ) ? 'YES' : 'NO' }
    ]
  }]

  const itemsAfter = [{
    title: 'Endpoint',
    value: <Endpoint {...JSON.parse(data?.endpoint)} />
  }, {
    title: 'Networking',
    value: <Networking data={data} />
  }, {
    title: 'Security',
    children: [
      { key: 'Publicly accessible', value: data?.publiclyAccessible },
      { key: 'VPC Security Groups', value: <SecurityGroups groups={JSON.parse(data.vpcSecurityGroups)} /> },
      { key: 'Certificate authority', value: data?.caCertificateIdentifier }
    ]
  }, {
    title: 'Backups',
    children: [
      { key: 'Automated backups', value: `${data?.backupRetentionPeriod} days` },
      { key: 'Copy tags to snapshots', value: <Enabled data={data.copyTagsToSnapshot} /> },
      { key: 'Latest restore time', value: formatDate(data?.latestRestorableTime) },
      { key: 'Backup window', value: `${data?.preferredBackupWindow} UTC (GMT)` }
    ]
  }, {
    title: 'Maintenance',
    children: [
      { key: 'Auto minor vers. upgrade', value: <Enabled data={data.autoMinorVersionUpgrade} /> },
      { key: 'Maintenance window', value: `${data?.preferredMaintenanceWindow} UTC (GMT)` }
    ]
  }]

  const dbEngineItem = { title: 'DB engine', value: `${data?.engine} (version: ${data.engineVersion})` }
  const dbInstanceClassItem = { title: 'DB Instance class', value: data?.dbInstanceClass }
  const perfInsightsItem = { title: 'Performance insights', value: <Enabled data={data?.performanceInsightsEnabled} /> }
  const dbActivityStreamItem = { title: 'DB activity stream', value: data?.activityStreamStatus }

  return (
    <>
      <Row gutter={12}>
        <Col span={12}><Metadata items={[dbEngineItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[dbInstanceClassItem]} inverse={inverse} /></Col>
      </Row>

      <Metadata items={items} inverse={inverse} />

      <Row gutter={12}>
        <Col span={12}><Metadata items={[perfInsightsItem]} inverse={inverse} /></Col>
        <Col span={12}><Metadata items={[dbActivityStreamItem]} inverse={inverse} /></Col>
      </Row>

      <Metadata items={itemsAfter} inverse={inverse} />
    </>
  )
}

export default RDSInstanceConfig
