import React from 'react'
import { StreamIcon } from 'components/icons/font-awesome'
import { AwsServiceIcon } from 'components/icons'
import ResolverItem from './item'
import Section from 'components/layout/content/section'

const getFieldType = (fieldType) => {
  if (!fieldType.ofType && !fieldType.kind) return fieldType.name

  switch (fieldType.kind) {
    case 'NON_NULL':
      return `${fieldType.ofType.name}!`
    case 'LIST':
      return `[ ${fieldType.ofType.name} ]`
  }
}

const Resolvers = ({ loading, resolvers, dataSources, type, index, selectedResolverId }) => {
  if (loading) return null

  const typeResolvers = resolvers?.filter(resolver => type.name === resolver.attributes.typeName)
  const definition = type?.attributes?.definition ? JSON.parse(type.attributes.definition) : {}

  const formatted = definition?.fields?.map((field, index) => {
    let icon, iconTitle, iconUrl, url

    const isPrimitive = !field.args.length
    const args = field.args.length && field.args.map(arg => ({ name: arg.name, type: getFieldType(arg.type) }))
    const fieldType = field.type.ofType
      ? getFieldType(field.type)
      : field.type.name

    const title = isPrimitive ? `${field.name}: ${fieldType}` : field.name

    const resolver = typeResolvers.find(resolver => resolver.name === field.name)
    if (!resolver) {
      return { id: index, title, args: !isPrimitive && args, returns: !isPrimitive && fieldType, isPrimitive }
    }

    if (resolver.attributes?.kind === 'PIPELINE') {
      icon = <StreamIcon />
      iconTitle = 'Pipeline function'
    } else {
      const dataSource = dataSources?.find(source => source.name === resolver.attributes?.dataSourceName)
      icon = dataSource?.attributes?.dynamodbConfig
        ? <AwsServiceIcon service='dynamodb' />
        : <AwsServiceIcon service='lambda' />
      iconTitle = dataSource?.name
      iconUrl = dataSource?.id ? `/inventory/${dataSource.id}` : ''
      url = resolver?.id ? `/inventory/${resolver.id}` : ''
    }

    return {
      id: resolver.id,
      metrics: resolver.metrics,
      title,
      url,
      icon,
      iconTitle,
      iconUrl,
      args: !isPrimitive && args,
      returns: !isPrimitive && fieldType,
      isPrimitive,
      selected: selectedResolverId === resolver.id
    }
  })

  if (!formatted) return null

  return <Section title={type.name} titleUpperCase>
    {formatted.map((item, index) => <ResolverItem key={index} item={item} />)}
  </Section>
}

export default Resolvers
