import { useQueries } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { useOrganization } from 'hooks/context/organization-context'

import {
  SEARCH,
  searchPaths as paths,
  serializeExecutionsPayload as serializePayload
} from './index'

const searchExecutions = async (account, payload, queryId) => {
  const data = await apiFetch(paths.executions(account), { method: 'POST', body: JSON.stringify({ ...payload }) })

  return { ...data, id: queryId }
}

export const useSearchExecutionsBatchQuery = (payloads = [], refreshInterval) => {
  const { account } = useOrganization()

  return useQueries(
    payloads.map(({ id: queryId, payload: data }) => {
      const payload = serializePayload(data)

      return {
        queryKey: [SEARCH, payload, payload?.filters, account?.id, queryId],
        queryFn: () => searchExecutions(account.id, payload, queryId),
        staleTime: refreshInterval ? 5 * 60 * 1000 : Infinity,
        enabled: !!account?.id && !!payload,
        refetchInterval: refreshInterval,
        refetchOnWindowFocus: false
      }
    })
  )
}
