import React from 'react'
import { Row, Col, Typography } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const format = (coupon, currency) => {
  if (coupon.amountOff) return `- ${formatStripePrice(coupon.amountOff, currency)}`

  if (coupon.percentOff) return `- ${coupon.percentOff}%`

  return '-'
}

const Discount = ({ data, currency } = {}) => {
  if (!data) return null

  const couponName = data.name

  return (
    <Row>
      <Col span={12}>
        <Text>{couponName ? `Discount (${couponName})` : 'Discount'}</Text>
      </Col>
      <Col span={12}>
        <Text>{format(data, currency)}</Text>
      </Col>
    </Row>
  )
}

export default Discount
