import React, { useEffect } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import SplitPane from 'features/layout/split-pane'
import List from './list'
import Dashboard from './dashboard'
// import AlarmsDashboard from './dashboard'
import ModifyGroup from 'features/resource-groups-list/edit'
import DashboardsOverview from './overview'

const Dashboards = () => {
  const { dashboardId } = useParams()
  const match = useRouteMatch()

  useEffect(() => {
    document.title = 'Dashbird.io - Dashboards'
  }, [])

  return (
    <SplitPane
      left={<List />}
      right={
        <Switch>
          <Route path={`${match.path}/resource-groups/:groupId?/edit`}>
            <ModifyGroup view='dashboards' />
          </Route>
          <Route path={`${match.path}/resource-groups/new`}>
            <ModifyGroup view='dashboards' />
          </Route>
          <Route path={`${match.path}/resource-groups/:groupId`}>
            <Dashboard />
          </Route>
          <Route path={match.path}>
            {dashboardId
              ? <Dashboard />
              : <DashboardsOverview />}
          </Route>
        </Switch>
      }
    />
  )
}

export default Dashboards
