import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.less'

export const BurgerMenuIcon = (props) => {
  const { className, open } = props
  return (
    <div {...props} className={classnames(styles.icon, className, { [styles.open]: !!open })} >
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
