import React from 'react'
import ExternalLinkButton from 'components/buttons/external-link'
import Tabs from 'components/antd/tabs'
import XRayTrace from './traces/expanded'
import CollapsableTraces from './traces/collapsed'
import TracesFlowChart from './network'

import styles from './styles.module.less'

const TracesDisabled = () => {
  return (
    <div>
      <span>Tracing not enabled </span>
      <ExternalLinkButton
        link='https://dashbird.io/docs/quickstart/enable-x-ray/'
        title='Click here to see how to set it up'
      />
    </div>
  )
}

const Empty = () => {
  return <div>Trace not found</div>
}

const Trace = ({ loading, enabled, traces, segmentId }) => {
  if (!enabled) return <TracesDisabled />
  if (loading) return null
  if (!traces || !traces.length) return <Empty />

  return (
    <Tabs defaultActiveKey="collapsed" className={styles.tabs_wrapper} items={[
      { key: 'collapsed', label: 'Collapsed', children: <CollapsableTraces traces={traces} segmentId={segmentId} /> },
      { key: 'expanded', label: 'Expanded', children: <XRayTrace traces={traces} /> },
      { key: 'flow', label: 'Flow graph ', children: <TracesFlowChart traces={traces} /> }
    ]} />
  )
}

export default Trace
