import React, { useContext } from 'react'
import { Row, Col } from 'antd'
import Content from 'components/layout/content'
import TotalCharts from './total-charts'
import SeverityGroups from './severity-groups'
import ErrorTypeGroups from './type-groups'
import LastOccurredErrors from './last-occurred'
import ErroredResourcesList from './resources'
import GroupedByHashList from './grouped-by-hash'
import RegionsList from './regions'
import DelegationsList from './delegations'
import ResourceGroupsList from 'features/resource-groups-list'
import GlobalDatePicker from 'components/date-picker/global'
import { ErrorMetricsContext } from '../index'

const ErrorsDashboard = () => {
  const { dashboardStart, dashboardEnd, onChangeDashboardDates, dashboardRelativeSpan } = useContext(ErrorMetricsContext)

  return (
    <Content
      title='Errors'
      item
      breadcrumbs={['errors']}
      actions={<GlobalDatePicker
        start={dashboardStart}
        end={dashboardEnd}
        onChange={onChangeDashboardDates}
        relativeSpan={dashboardRelativeSpan}
        refetchHidden
      />}

    >
      <Row gutter={[8, 12]}>
        <Col span={24}>
          <TotalCharts />
        </Col>
        <Col xs={24} lg={7}>
          <SeverityGroups />
        </Col>
        <Col xs={24} lg={17}>
          <ErrorTypeGroups />
        </Col>
        <Col span={24}>
          <LastOccurredErrors />
        </Col>
        <Col xs={24} md={8}>
          <ErroredResourcesList />
          <ResourceGroupsList view='errors' hover />
        </Col>
        <Col xs={24} md={8}>
          <GroupedByHashList />
        </Col>
        <Col xs={24} md={8}>
          <RegionsList />
          <DelegationsList />
        </Col>
      </Row>
    </Content>
  )
}

export default ErrorsDashboard
