import React from 'react'
import classnames from 'classnames'
import { Checkbox } from 'antd'
import { SEVERITY_TYPES } from 'lib/event-constants'

import styles from './styles.module.less'

const ListItem = ({
  title,
  details,
  left,
  right,
  selected,
  severity,
  icon,
  onClick,
  onBulkSelect,
  checked,
  className,
  titleClassName,
  detailsBelowTitle = false,
  type,
  iconDivider
}) => {
  const checkboxChanged = e => {
    onBulkSelect(e.target.checked)
  }

  const getSeverityClass = (severity, type) => {
    const severityType = severity ? SEVERITY_TYPES[severity.toUpperCase()] : ''
    return styles[severityType] ?? null
  }

  return (
    <div onClick={onClick} className={classnames(styles.item, className, selected && styles.selected, getSeverityClass(severity, type))}>
      {icon && <div className={classnames(styles.icon, iconDivider && styles.divider)}>{icon}</div>}
      <div className={styles.area}>
        <div className={styles.header}>
          {onBulkSelect && <Checkbox className={styles.checkbox} onChange={checkboxChanged} checked={checked} onClick={e => e.stopPropagation()} />}
          <div className={styles.counters}>{left}</div>
          {right && <div className={styles.timestamp}>{right}</div>}
        </div>
        {
          !detailsBelowTitle && <div className={styles.details}>{details}</div>
        }
        <div className={classnames(styles.title, titleClassName)}>{title}</div>
        {
          detailsBelowTitle && <div className={styles.details}>{details}</div>
        }
      </div>
    </div>
  )
}

export default ListItem
