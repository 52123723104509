import React from 'react'
import Numeral from 'numeral'
import { Line } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { CONNECTIONS } from 'containers/inventory/details/resource/metrics-tabs/services/elb-application-load-balancer'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [CONNECTIONS]: data.ELB_LOADBALANCER_CONNECTIONS_ACTIVE
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={CONNECTIONS}
      formatTab={value => `${Numeral(value).format('0a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={CONNECTIONS} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average concurrent connections' dot={false} />
    </ChartContainer>
  )
}

export default ELBRequestsChart
