import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import queryString from 'query-string'

import { logSourcesPaths as paths, getLogGroupName, LOG_SOURCE } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getLogSourceSubscriptionRetry = async (account, delegation, region, logGroupName) => {
  const query = queryString.stringify({ region, logGroupName })

  return apiFetch(paths.filter(paths.logSourceRetry(account, delegation), query), { method: 'GET' }
  )
}
export const useRetryLogSourceQuery = ({ delegation, region, name }) => {
  const queryClient = useQueryClient()

  const { account } = useOrganization()
  const logGroupName = getLogGroupName(name)

  return useMutation(
    () => getLogSourceSubscriptionRetry(account?.id, delegation?.id, region, logGroupName),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([LOG_SOURCE, delegation?.id, region, logGroupName], data)
      }
    }
  )
}
