import React from 'react'
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'

import styles from './styles.module.less'

export const DashbirdIcon = ({ className }) => {
  return (
    <ReactSVG src='/static/dashbird-logo.svg' className={classnames(styles.logo, className)} />
  )
}
