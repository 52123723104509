import React from 'react'
import { ceil } from 'lodash'
import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'
import ChartContainer from 'components/charts/container'
import { LAMBDA_DURATION } from 'containers/inventory/details/resource/metrics-tabs/services/lambda'

const DurationsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    duration: data.INVOCATIONS_DURATION
  }, ceil)

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [LAMBDA_DURATION]: metric.duration_avg,
      diff: [metric.duration_min, metric.duration_max]
    }
  })

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') return [`${formatDuration(value[0])} - ${formatDuration(value[1])}`, undefined]
    return [`${formatDuration(value)}`, undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={LAMBDA_DURATION}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
      formatYTicks={value => formatDuration(value)}
    >
      <Line type='monotone' dataKey={LAMBDA_DURATION} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />
      <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Min - max' />
    </ChartContainer>
  )
}

export default DurationsChart
