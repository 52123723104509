import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { useOrganization } from 'hooks/context/organization-context'
import { metricsPaths as paths } from './index'

const getMetrics = async (account, payload) => {
  return await (apiFetch(paths.metrics(account), { method: 'POST', body: JSON.stringify(payload) }))
}

const buildPayload = (key, options) => {
  switch (key) {
    case 'total-errors-warnings': return {
      namespace: 'dashbird/event/error',
      metric: 'errors',
      dimensions: {
        severities: ['critical', 'warning'],
        ...options?.dimensions
      },
      group: options?.group || 'severity',
      stats: ['sum'],
      fill: 'gapfill'
    }

    case 'errors': return {
      namespace: 'dashbird/event/error',
      metric: 'count',
      dimensions: {
        ...options?.dimensions
      },
      group: options?.group || 'resource',
      stats: ['sum'],
      fill: 'gapfill'
    }

    case 'scheduled-query': return {
      namespace: 'dashbird/event/log-matches',
      metric: 'occurrences',
      stats: ['sum'],
      dimensions: {
        ...options?.dimensions
      },
      ...(options.group ? { group: options.group } : {}),
      fill: 'gapfill'
    }

    default: return {}
  }
}

export const useErrorMetricsQuery = (load, start, end) => {
  const { account } = useOrganization()

  const payload = {
    end,
    start,
    ...buildPayload(load?.key, load?.options)
  }

  const hash = [load?.options?.dimensions] || []

  return useQuery(
    [`${load?.key}`, ...hash, start, end, account?.id],
    () => getMetrics(account.id, payload),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!payload.start && !!payload.end && !!load
    }
  )
}
