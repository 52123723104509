import React from 'react'
import { Radio, Checkbox } from 'antd'

import { SEVERITY_TYPES } from 'lib/event-constants'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import TargetSelector from 'features/target-selector'

import { SearchIcon } from 'components/icons'
import Actions from './actions'

import styles from './styles.module.less'

const LogMatchForm = ({ mutations, condition, lambdas, groups }) => {
  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.search_icon}>
            <SearchIcon />
          </span>
          <span>Log match event</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>error types</span>
          <span className={styles.value}>{condition?.errors?.length ? condition.errors.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition?.targets?.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} />
        </div>
      </div>
      <section>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Radio.Group
            defaultValue={condition.occurrence}
            onChange={(e) => handlers.update({ occurrence: e.target.value })}
          >
            <Radio.Button value='new'>Only new and reopened occurrences</Radio.Button>
            <Radio.Button value='all'>All occurrences</Radio.Button>
          </Radio.Group>
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Severities</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Checkbox.Group
            options={[SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING, SEVERITY_TYPES.INFO]}
            defaultValue={condition.severities}
            onChange={(values) => handlers.update({ severities: values })}
          />
        </PermissionsGate>
      </section>

      <section>
        <span className={styles.label}>Filter by resources</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <TargetSelector
            resourceGroups={groups}
            lambdas={lambdas}
            onSelect={(value) => handlers.targets(value).add()}
            onDeselect={(value) => handlers.targets(value).remove()}
            value={condition.targets}
            prefixValues
            placeholder='Select resources'
          />
        </PermissionsGate>
      </section>
    </div>
  )
}

export default LogMatchForm
