import { paths } from '../index'

export const BILLING = 'billing'
export const PAYMENT_METHODS = [BILLING, 'payment-methods']
export const CUSTOMER = [BILLING, 'customer']
export const SUBSCRIPTION = [BILLING, 'subscription']
export const INVOICE = [BILLING, 'invoice']
export const INVOICES_LIST = [...INVOICE, 'list']
export const PROMOTION = [...INVOICE, 'promotion']
export const PRICES = [BILLING, 'prices']

export * from './get-billing'
export * from './get-prices'

export * from './payment/get-payment-methods'
export * from './payment/create-payment-method'
export * from './payment/delete-payment-method'
export * from './payment/method-setup'
export * from './payment/update-payment-method'

export * from './customer/get-customer'
export * from './customer/put-customer'
export * from './customer/validate-vat'

export * from './subscription/get-subscription'
export * from './subscription/put-subscription'

export * from './invoices/get-all-invoices'
export * from './invoices/get-next-invoice'
export * from './invoices/create-invoice-preview'
export * from './invoices/get-promotion'

export const billingPaths = {
  filter: (path, query) => paths.filter(path, query),
  organization: (identity) => `${paths.organization(identity)}/billing/v2`,
  billing: (identity) => `${billingPaths.organization(identity)}/billing`,
  invoices: (organization) => `${billingPaths.organization(organization)}/invoices`,
  customer: (organization, customerId) => `${billingPaths.organization(organization)}/customers/${customerId}`,
  invoicesUpcoming: (organization) => `${billingPaths.invoices(organization)}/upcoming`,
  invoicesPreview: (organization) => `${billingPaths.invoices(organization)}/preview`,
  paymentMethods: (organization) => `${billingPaths.organization(organization)}/payment-methods`,
  paymentMethod: (organization, identity) => `${billingPaths.paymentMethods(organization)}/${identity}`,
  paymentSetups: (organization) => `${billingPaths.organization(organization)}/setup-intents`,
  paymentSetup: (organization, identity) => `${billingPaths.paymentSetups(organization)}/${identity}`,
  subscription: (organization, subscriptionId) => `${billingPaths.organization(organization)}/subscriptions/${subscriptionId}`,
  validateVAT: (organization) => `${billingPaths.organization(organization)}/validate/vat`,
  discountPromotion: (organization) => `${billingPaths.organization(organization)}/discounts/promotions`,
  prices: (organization) => `${billingPaths.organization(organization)}/prices`
}

export const billingPathsV1 = {
  billing: (organization) => `${paths.organization(organization)}/billing`,
  customers: (organization) => `${billingPathsV1.billing(organization)}/customers`,
  customer: (organization) => `${billingPathsV1.billing(organization)}/customer`,
  payment: (organization) => `${billingPathsV1.customer(organization)}/payment`,
  paymentMethods: (organization) => `${billingPathsV1.payment(organization)}/methods`
}
