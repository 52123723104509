import React from 'react'
import { Dropdown, Popconfirm } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import { isUserRoleSuperior } from './index'

import styles from './styles.module.less'

const UserActions = ({ user, handleActionClick, handleDelete }) => {
  if (!user) return null

  const USER_PENDING_EXPIRED = user?.expiresAt

  const resendMenuItem = { key: 'resend', label: 'Resend invite' }

  const deleteMenuItem = {
    key: 'delete',
    label: (
      <Popconfirm
        onClick={(e) => e.stopPropagation()}
        title={`Delete user ${user?.email} from organization?`}
        onConfirm={handleDelete}
        placement='bottomRight'
      >
        <div>Delete</div>
      </Popconfirm>
    )
  }

  return (
    <PermissionsGate
      scopes={[SCOPES.canManageAllUsers]}
      errorProps={{
        menu: {
          items: [
            { ...resendMenuItem, disabled: isUserRoleSuperior(user) },
            { ...deleteMenuItem, disabled: isUserRoleSuperior(user) }
          ]
        }
      }}>
      <Dropdown
        trigger={['click']}
        overlayClassName={styles.configure_dropdown}
        menu={{
          items: [
            ...(USER_PENDING_EXPIRED ? [resendMenuItem] : [{}]),
            deleteMenuItem
          ],
          onClick: handleActionClick
        }}
      >
        <ActionsDropdownButton />
      </Dropdown>
    </PermissionsGate>
  )
}

export default UserActions
