import React from 'react'

import { DownloadIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

const DownloadButton = ({ link, title }) => {
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <IconButton icon={<DownloadIcon />} title={title || 'Download'} />
    </a>
  )
}

export default DownloadButton
