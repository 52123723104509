import React from 'react'
import numeral from 'numeral'
import { get, has, every, identity, reduce, zipWith, first, isEmpty, some } from 'lodash'
import { YAxis, ReferenceLine } from 'recharts'
import ChartContainer from 'components/charts/container'
import ChartMetrics, { defaultMetricFormatter, defaultMetricDataFormatter, getDefaultMetricComponent } from 'features/violations/metrics'
import MetricGroupNotFoundError from 'lib/errors/charts/MetricGroupNotFound'

import { chartHeight, yAxisProps } from 'components/charts/utils'
import Loading from 'components/layout/content/loading'
import Empty from 'components/charts/empty'
import colors from 'lib/colors'

const fetchMetric = metric => {
  if (has(ChartMetrics, `${metric.metric}.${metric.stat}`)) {
    return get(ChartMetrics, `${metric.metric}.${metric.stat}`)
  }

  if (has(ChartMetrics, `${metric.metric}.default`)) {
    return get(ChartMetrics, `${metric.metric}.default`)
  }

  return getDefaultMetricComponent(metric)
}

const fetchMetricComponent = metric => {
  const metricGroup = fetchMetric(metric)
  if (!metricGroup) throw new MetricGroupNotFoundError(metric)
  return metricGroup.component(metric)
}

const fetchMetricFormatter = (metric) => {
  const metricGroup = get(ChartMetrics, metric, null)
  if (!metricGroup) return defaultMetricFormatter
  return metricGroup.formatter
}

const fetchMetricDataFormatter = (metric) => {
  const metricGroup = get(ChartMetrics, metric, null)
  if (!metricGroup) return defaultMetricDataFormatter
  return metricGroup.dataFormatter
}

const tooltipFormatter = (val, name, props) => {
  const formatter = fetchMetricFormatter(props.dataKey)
  if (!formatter) return [val, undefined]
  return [formatter(val), undefined]
}

const transform = (data) => {
  return reduce(data, (acc, cur) => {
    const pairs = zipWith(cur.timestamps, cur.values, (timestamp, value) => {
      const formatter = fetchMetricDataFormatter(`${cur.metric}.${cur.stat}`)
      const formatted = formatter ? formatter(value) : value
      return ({ date: timestamp, [`${cur.metric}.${cur.stat}`]: formatted })
    })

    return zipWith(acc, pairs, (existing, pair) => existing ? { ...existing, ...pair } : pair)
  }, [])
}

const yAxisFormatter = ({ metric, stat } = {}) => {
  const metricGroup = get(ChartMetrics, `${metric}.${stat}`)
  if (!metricGroup || !metricGroup.yAxisFormatter) return value => numeral(value).format('0 a')

  return metricGroup.yAxisFormatter
}

const extraAxisExists = (data) => {
  const metricWithExtraYAxis = data.find(metric => {
    const metricGroup = get(ChartMetrics, `${metric.metric}.${metric.stat}`)
    if (!metricGroup || !metricGroup.extraYAxisFormatter) return false

    return true
  })

  if (!metricWithExtraYAxis) return false
  return true
}

const extraYAxisFormatter = (data) => {
  const metricWithExtraYAxis = data.find(metric => {
    const metricGroup = get(ChartMetrics, `${metric.metric}.${metric.stat}`)
    if (!metricGroup || !metricGroup.extraYAxisFormatter) return false

    return true
  })

  if (!metricWithExtraYAxis) return () => null

  return get(ChartMetrics, `${metricWithExtraYAxis.metric}.${metricWithExtraYAxis.stat}`)?.extraYAxisFormatter
}

const isPercentageChart = ({ metric, stat } = {}) => {
  const metricGroup = get(ChartMetrics, `${metric}.${stat}`)
  return metricGroup?.percentageChart
}

const InsightViolationChart = ({ data, loading, height = chartHeight, span, threshold }) => {
  if (loading || !every(data, identity)) return <Loading height={height} />

  if (isEmpty(data) || isEmpty(data.filter(metric => !isEmpty(metric.values)))) return <Empty height={height} />

  const formattedData = transform(data)

  return (
    <ChartContainer
      height={height}
      data={formattedData}
      loading={loading}
      formatTooltip={tooltipFormatter}
      formatYTicks={yAxisFormatter(data[0])}
      percentageChart={isPercentageChart(first(data))}
    >
      <YAxis yAxisId='auto'{...yAxisProps} orientation='right' hide={!extraAxisExists(data)} tickFormatter={extraYAxisFormatter(data)} />
      {data.map(metric => fetchMetricComponent(metric))}
      {threshold !== null && <ReferenceLine y={threshold} type='monotone' name='Threshold' stroke={colors('chart', 'redLight')} strokeWidth={2} dot={false} ifOverflow='extendDomain' />}
    </ChartContainer>
  )
}

const ViolationChart = ({ data, range, violation }) => {
  const mapData = (data) => data?.map(({ data: metricData }) => {
    return {
      start: metricData?.start,
      end: metricData?.end,
      period: metricData?.period,
      metric: metricData?.metric,
      namespace: metricData?.namespace,
      stat: metricData?.stats?.[0],
      resources: [{ id: violation?.resource?.id }],
      values: metricData?.datapoints?.default?.map(datapoint => datapoint?.[metricData?.stats?.[0]]),
      timestamps: metricData?.datapoints?.default?.map(datapoint => datapoint?.date)
    }
  })

  return (
    <InsightViolationChart data={mapData(data)} loading={some(data, 'isLoading')} span={range} threshold={violation?.threshold} />
  )
}

export default ViolationChart
