import React, { useState } from 'react'
import { get, omitBy, isNil } from 'lodash'

import LoadMoreButton from 'components/buttons/load-more'

const FETCH_MORE_COUNT = 5

const LoadButton = ({ fetch, next }) => {
  const nextId = get(next, 'id')

  const [isLoading, setIsLoading] = useState(false)

  const params = omitBy({
    limit: FETCH_MORE_COUNT,
    next: nextId
  }, isNil)

  const loadMore = async () => {
    setIsLoading(true)
    await fetch(params)
    setIsLoading(false)
  }

  return (
    <LoadMoreButton isLoading={isLoading} onClick={loadMore} hidden={!nextId} />
  )
}

export default LoadButton
