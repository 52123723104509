import { getLocalPeriod } from 'lib/metric-helpers'
import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'INVOCATIONS_FATAL_ERRORS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_DETECTED_ERRORS',
  filter: {
    namespace: `${NAMESPACES.LAMBDA}`,
    metric: 'errors',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_COLDSTARTS',
  filter: {
    namespace: `${NAMESPACES.LAMBDA}`,
    metric: 'coldstarts',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_TOTAL',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_MEMORY',
  filter: {
    namespace: NAMESPACES.LAMBDA,
    metric: 'percentage_memory_used',
    stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_DURATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'duration',
    stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_COST',
  filter: {
    namespace: NAMESPACES.LAMBDA,
    metric: 'gb_second',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_CONCURRENCY_MAX',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'invocations.concurrent',
    stats: [STATISTICS.MAX],
    fill: 'gapfill'
  },
  period: getLocalPeriod
}, {
  id: 'INVOCATIONS_THROTTLES_SUM',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'throttles',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}]

export default metrics
