import React, { useMemo, useState } from 'react'
import { Bar } from 'recharts'

const useBars = ({ keys, tab, cursor = 'default', fill, onClick, stacked }) => {
  const [activeBar, setActiveBar] = useState(null)

  const stack = stacked ? { stackId: 'a' } : null

  const bars = useMemo(() => {
    return keys.map((key) => (
      <Bar
        key={key}
        dataKey={key}
        {...stack}
        barSize={8}
        cursor={cursor}
        isAnimationActive={false}
        fill={fill && fill({ tab, key })}
        onMouseEnter={() => setActiveBar(key)}
        onClick={() => onClick && onClick({ tab, key })}
      />
    ))
  }, [keys, tab])

  return [bars, activeBar]
}

export default useBars
