import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { Button, Radio, Form, Input as AntdInput } from 'antd'

import Section from 'components/layout/content/section'
import DeleteButton from 'components/buttons/delete'
import IconButton from 'components/buttons/icon'
import Input from 'components/antd/input'
import { ResolvedIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

const TagField = ({ name, filters, handlePreview, onTagRemove, remove, ...restField }) => {
  const inputRef = useRef(null)
  const valueRef = useRef(null)
  const [showAdd, setShowAdd] = useState(isEmpty(filters?.values[name]))

  useEffect(() => {
    if (inputRef?.current && isEmpty(filters?.values[name])) {
      inputRef.current.focus({
        cursor: 'start'
      })
    }
  }, [])

  const onBlur = (e) => {
    if (e.currentTarget.contains(e.relatedTarget)) return
    setShowAdd(false)
  }

  const onSave = () => {
    handlePreview()
    setShowAdd(false)
  }

  const handleTagSubmit = (e) => {
    if (e.keyCode !== 13) return

    e.preventDefault()
    onSave()
    valueRef.current.blur()
    inputRef.current.blur()
  }

  return (
    <div
      className={styles.tags}
      onFocus={() => setShowAdd(true)}
      onBlur={(e) => onBlur(e)}
    >
      <AntdInput.Group compact>
        <Form.Item
          style={{ width: 'calc(50% - 25px)' }}
          {...restField}
          name={[name, 'key']}
          className={styles.formItem}
          rules={[{ required: true, message: 'Tag key must not be empty' }]}
        >
          <Input
            ref={inputRef}
            placeholder='key'
            onKeyDown={(e) => handleTagSubmit(e)}
            className={classnames(styles.tag, styles.tagKey)}
          />
        </Form.Item>
        <div className={styles.divider} />
        <Form.Item
          style={{ width: !showAdd ? 'calc(50%)' : 'calc(50% - 25px)' }}
          {...restField}
          name={[name, 'value']}
          className={styles.formItem}
        >
          <Input
            ref={valueRef}
            placeholder='value'
            onKeyDown={(e) => handleTagSubmit(e)}
            className={classnames(styles.tag)}
          />
        </Form.Item>
        <Form.Item {...restField} className={classnames(styles.formItem)}>
          <IconButton
            hidden={!showAdd}
            icon={<ResolvedIcon />}
            className={classnames(styles.icon, styles.icon_add)}
            title='Add/change tag'
            onClick={() => onSave()}
          />
        </Form.Item>
        <Form.Item {...restField} className={styles.formItem}>
          <DeleteButton
            title='Remove tag'
            className={classnames(styles.icon)}
            onClick={() => onTagRemove(name, remove)}
          />
        </Form.Item>
      </AntdInput.Group>
    </div>
  )
}

const Operator = ({ onChange }) => {
  return (
    <Form.Item name='tagOperator' defaultValue='and' className={styles.operator}>
      <Radio.Group size='small' onChange={() => onChange()}>
        <Radio.Button value='or'>ANY</Radio.Button>
        <Radio.Button value='and'>ALL</Radio.Button>
      </Radio.Group>
    </Form.Item>
  )
}

const TagFilters = ({ handlePreview, filters }) => {
  const onTagRemove = (name, remove) => {
    remove(name)
    handlePreview()
  }

  return (
    <Section title='Tags' titleUpperCase>
      <div className={styles.content}>
        <span>Resources with tags matching <Operator onChange={handlePreview} /> of</span>
        <Form.List name='tags'>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <TagField key={key} name={name} {...restField} handlePreview={handlePreview} filters={filters} remove={remove} onTagRemove={onTagRemove} />
              ))}
              <Button type='primary' ghost onClick={() => add()} block className={styles.btn_add}>ADD NEW TAG</Button>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </div>
    </Section>
  )
}

export default TagFilters
