import { combineReducers } from 'redux'

function active (state = null, action) {
  switch (action.type) {
    case 'ACTIVE_METRIC_UPDATE':
      if (!action.payload) {
        return {}
      }
      return {
        ...state,
        ...action.payload
      }
  }
  return state
}

export default combineReducers({
  active
})
