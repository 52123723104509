import React from 'react'
import { parseISO } from 'date-fns'
import { Bar } from 'recharts'

import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'

const SearchResultsChart = ({ loading, data, height, span }) => {
  if (loading || !data?.length) return null

  const metrics = data.map(metric => ({ count: metric.count, date: parseISO(metric.date) }))

  if (!metrics.length) return <Empty height={height} />

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0'
      span={span}
      tabKey='search-results'
    >
      <Bar dataKey='count' stackId='a' fill={colors('chart', 'primary')} name='Hits' barSize={4} />
    </ChartContainer>
  )
}

export default SearchResultsChart
