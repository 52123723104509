import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { parse } from '@dashbird/dashql'

import { useAccountRoutes } from 'containers/routes'
import InvocationsList from 'features/invocations'
import { SearchContext } from 'hooks/context/search-context'

const LogMatchInvocations = ({ loading, invocations, query }) => {
  const { queryId } = useParams()
  const { search } = useLocation()
  const routes = useAccountRoutes()
  const history = useHistory()
  const { setKeywords, findKeywords } = useContext(SearchContext)

  const onDrawerClose = () => {
    const pathname = routes.scheduledSearch.query.url({ queryId })
    history.push({ pathname, search })
  }

  const handleKeywords = () => {
    if (!query) return
    const result = parse(query?.query)
    const commands = result?.commands?.filter(command => command.type === 'search')

    const keywords = []
    for (const command of (commands || [])) {
      keywords.push(...findKeywords(command.spec))
    }
    setKeywords(keywords)
  }

  useEffect(handleKeywords, [query])

  return (
    <InvocationsList
      loading={loading}
      invocations={invocations?.map(item => ({ ...item, data: item?.invocation?.data }))}
      service={'Lambda'}
      title='Occurrences'
      onClose={onDrawerClose}
      url={(execution) => ({ pathname: routes.scheduledSearch.logMatch.url({ queryId, invocationHash: execution.logEventId }), search })}
      logMatch
      hideFilters
    />
  )
}

export default LogMatchInvocations
