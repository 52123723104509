import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'
import { countBy, reduce, groupBy, sum, first } from 'lodash'
import { differenceInMinutes, sub } from 'date-fns'

import { useAllResourcesQuery, useChartMetricsQuery, useResolversQuery, useRequestsQuery } from 'hooks/api'
import { getMetricResourceDimensions } from 'lib/metric-helpers'
import { hasMoreDelegations } from 'lib/resources/transformations'

import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import { AwsServiceIcon } from 'components/icons'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import Section from 'components/layout/content/section'
import { ChartMetrics } from 'containers/inventory/details/resource/metrics'
import Metrics from 'containers/inventory/details/resource/metrics-tabs'
import Chart from 'containers/inventory/details/resource/charts'
import { APPSYNC_RESOLVER_REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/appsync-resolver'
import { ProgressItem } from 'components/items/progress-item'
import GlobalDatePicker from 'components/date-picker/global'

import styles from './styles.module.less'

const renderEmpty = () => {
  return <Empty title='No resolver to show.' />
}

const RequestContent = ({ onClose, className, start: initialStart, end: initialEnd, range: initialRange, relativeSpan: initialRelativeSpan }) => {
  const { resolverId } = useParams()
  const [chart, setChart] = useState(APPSYNC_RESOLVER_REQUESTS)
  const { data: resources, isLoadingResources } = useAllResourcesQuery()
  const resolver = resources && resources[resolverId]
  const [metrics, setMetrics] = useState({})
  const serviceChartMetrics = ChartMetrics[INVENTORY_SERVICES.AppSyncResolver.id]

  const [start, setStart] = useState(initialStart || sub(new Date(), { days: 1 }))
  const [end, setEnd] = useState(initialEnd || new Date())
  const [range, setRange] = useState(initialRange || 24 * 60)
  const [relativeSpan, setRelativeSpan] = useState(initialRelativeSpan || 24 * 60)
  const [refreshInterval, setRefreshInterval] = useState(false)

  const { data: resolverEvents, isLoading: isLoadingResolverEvents } = useResolversQuery({ resolverArn: resolver?.arn })
  const requestIdOccurrences = countBy(resolverEvents, r => r.requestId)
  const requestIds = Object.keys(requestIdOccurrences)

  const { data: requests, isLoading: isLoadingRequests } = useRequestsQuery({ requestIds: requestIds.join(','), from: start.getTime(), to: end.getTime() })

  const groupedRequests = groupBy(requests, 'queryHash') || {}
  const requestsWithCount = reduce(groupedRequests, (carry, requests) => {
    const title = (requests.find(request => request.operation) || {}).operation
    const count = sum(requests.map(request => requestIdOccurrences[request.requestId] || 0))
    const { requestId, query } = first(requests) || {}
    carry.push({ title, count, query, requestId })
    return carry
  }, []).sort((a, b) => b.count - a.count) || []

  const formatPayload = metric => {
    if (!resolver) return

    const metricsPayload = {
      dimensions: {
        resources: getMetricResourceDimensions(resolver)
      },
      ...metric.filter,
      start: metric.start ? metric.start(start, end) : start,
      end: metric.end ? metric.end(end) : end
    }

    return metricsPayload
  }

  const chartPayloads = resolver ? serviceChartMetrics?.map(metric => ({ id: metric.id, payload: formatPayload(metric) })) : []

  const delegationName = resources && hasMoreDelegations(Object.values(resources)) && resolver?.delegationName
  const isLoadingEvents = isLoadingResolverEvents || isLoadingRequests

  const metricsData = useChartMetricsQuery(chartPayloads, relativeSpan, refreshInterval)
  const metricDataPeriod = metricsData ? metricsData[0]?.data?.period : 3600

  const loadingMetrics = metricsData.some(item => item.isLoading)
  const fetching = metricsData.some(item => item.isFetching)

  const formatMetrics = () => {
    if (fetching || loadingMetrics) return

    const mappedMetrics = metricsData?.reduce((acc, item) => {
      acc[item?.data?.metricId] = item.data
      return acc
    }, {})

    setMetrics(mappedMetrics)
  }

  useEffect(formatMetrics, [loadingMetrics, fetching, resolverId])

  const selectRange = (start, end) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMinutes(end, start))
    setRefreshInterval(false)
    setRelativeSpan(false)
  }

  const handleIntervalChange = (e) => {
    const valueInMs = e.target.value
    if (valueInMs === 'false') return setRefreshInterval(false)
    setRefreshInterval(parseInt(valueInMs))
  }

  const handleManualRefetch = (e) => {
    e?.stopPropagation()
    metricsData.map(item => item?.refetch())
  }

  if (!resolver) return null

  return (
    <Content
      breadcrumbs={[delegationName, resolver?.region]}
      loading={isLoadingResources}
      item={resolver}
      title={`Resolver: ${resolver?.name}`}
      renderEmpty={renderEmpty}
      titleRowActions={(
        <GlobalDatePicker
          max={new Date()}
          min={sub(new Date(), { months: 1 })}
          start={start}
          end={end}
          onChange={selectRange}
          refreshInterval={refreshInterval}
          handleIntervalChange={handleIntervalChange}
          setRelativeSpan={setRelativeSpan}
          relativeSpan={relativeSpan}
          fetching={fetching}
          handleManualRefetch={handleManualRefetch}
          period={metricDataPeriod} />)}
      className={classnames(className, styles.resolverContent)}
      onDrawerClose={onClose}
      drawer
      icon={<AwsServiceIcon service={INVENTORY_SERVICES.AppSyncResolver.service} />}
    >
      <Section cancelMargin>
        <Metrics
          loading={loadingMetrics || !resolver}
          data={metrics}
          item={resolver}
          service={INVENTORY_SERVICES.AppSyncResolver.id}
          selected={chart}
          onSelect={setChart}
          drawer
        />
      </Section>
      <Section cancelMargin solid className={styles.chart}>
        <Chart loading={loadingMetrics} type={chart} data={metrics} span={range} service={INVENTORY_SERVICES.AppSyncResolver.service} />
      </Section>
      <Section title='Occurrences in requests' loading={isLoadingEvents} titleUpperCase>
        <div className={styles.occurrences}>
          {requestsWithCount?.map(request => {
            return (
              <ProgressItem
                key={request.requestId}
                title={request.title}
                id={request.requestId}
                count={request.count}
                total={requests?.length}
                description={request.query}
                selected={[]}
              />
            )
          })}
        </div>
      </Section>
    </Content>
  )
}

export default RequestContent
