import { merge, map, sortBy } from 'lodash'

const getEmpty = keys => {
  return merge({}, ...map(keys, key => { return { [key]: 0 } }))
}

const transform = (metrics, transformValue = value => value) => {
  const series = Object.keys(metrics)
  const empty = getEmpty(series)

  const transformed = series?.reduce((timeseries, serie) => {
    if (!metrics[serie]) return timeseries

    const datapoints = metrics[serie]?.datapoints?.default

    const stats = metrics[serie]?.stats

    datapoints?.forEach(point => {
      if (!timeseries[point.date]) timeseries[point.date] = { date: point.date, ...empty }
      const addStatToName = stats?.length > 1
      stats?.forEach(stat => {
        const serieKey = addStatToName ? `${serie}_${stat}` : serie
        timeseries[point.date][serieKey] = transformValue(point[stat])
      })
    })

    return timeseries
  }, {})

  return sortBy(Object.values(transformed), 'date')
}

export default transform
