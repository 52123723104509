import numeral from 'numeral'

import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip'
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen'
import { faPenNib } from '@fortawesome/pro-light-svg-icons/faPenNib'
import { faPlug } from '@fortawesome/pro-light-svg-icons/faPlug'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faHdd } from '@fortawesome/pro-light-svg-icons/faHdd'
import { faWaveSquare } from '@fortawesome/pro-light-svg-icons/faWaveSquare'

import { objectify } from 'lib/utils'
import { getValue, getSparklineByName, getSum, getLast } from '../helpers'
import { formatDuration } from 'components/charts/utils'

export const RDS_CPU = 'rds-cpu'
export const RDS_CPU_MEMORY = 'rds-cpu-memory'
export const RDS_NETWORK_TRAFFIC = 'rds-network-traffic'
export const RDS_CONNECTIONS = 'rds-connections'
export const RDS_READ = 'rds-read'
export const RDS_WRITE = 'rds-write'
export const RDS_STORAGE = 'rds-storage'
export const RDS_REPLICA_LAG = 'rds-replica-lag'

const metrics = [{
  key: RDS_CPU_MEMORY,
  icon: faMicrochip,
  title: 'CPU | MEMORY',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_CPU_UTILIZATION')).format('0.[00]')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'RDS_CPU_UTILIZATION'),
  selectable: true
}, {
  key: RDS_NETWORK_TRAFFIC,
  icon: faWaveSquare,
  title: 'Network traffic',
  filter: (collection) => numeral(getSum(collection, 'RDS_NETWORK_INCOMING')).format('0.0b'),
  subvalue: (collection) => getSparklineByName(collection, 'RDS_NETWORK_INCOMING'),
  selectable: true
}, {
  key: RDS_CONNECTIONS,
  icon: faPlug,
  title: 'Connections',
  filter: (collection) => numeral(getSum(collection, 'RDS_DATABASE_CONNECTIONS')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'RDS_DATABASE_CONNECTIONS'),
  selectable: true
}, {
  key: RDS_READ,
  icon: faBookOpen,
  title: 'Reads',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_READ_OPERATIONS')).format('0.[00]a')}`,
  subvalue: (collection) => getSparklineByName(collection, 'RDS_READ_OPERATIONS'),
  selectable: true
}, {
  key: RDS_WRITE,
  icon: faPenNib,
  title: 'Writes',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_WRITE_OPERATIONS')).format('0.[00]a')}`,
  subvalue: (collection) => getSparklineByName(collection, 'RDS_WRITE_OPERATIONS'),
  selectable: true
}, {
  key: RDS_STORAGE,
  icon: faHdd,
  title: 'Used storage',
  filter: (collection, item) => {
    const maxStorage = objectify(item?.attributes)?.allocatedStorage * 1024 * 1024 * 1024
    const last = getLast(collection, 'RDS_FREE_STORAGE_SPACE') // last
    const used = maxStorage - last

    return numeral(used).format('0.0b')
  },
  subvalue: (collection) => getSparklineByName(collection, 'RDS_FREE_STORAGE_SPACE'),
  selectable: true
}, {
  key: RDS_REPLICA_LAG,
  icon: faCopy,
  title: 'Replica lag',
  filter: (collection) => formatDuration(getValue(collection, 'RDS_REPLICA_LAG')),
  subvalue: (collection) => getSparklineByName(collection, 'RDS_REPLICA_LAG'),
  selectable: true
}]

export default metrics
