import { format, isValid, parseISO } from 'date-fns'
const HOURS = 2 * 60
const DAYS = 24 * 60

const formatDate = (givenDate = 0, size) => {
  if (!isValid(givenDate) && !isValid(parseISO(givenDate))) return 'NaN'
  const date = !isValid(givenDate) ? parseISO(givenDate) : givenDate

  if (size <= HOURS) return format(date, 'HH:mm')
  if (size <= DAYS) return format(date, 'HH:mm')
  return format(date, 'dd MMM HH:mm')
}

export default formatDate
