import React from 'react'
import { ReactSVG } from 'react-svg'
import { AlertIcon, BugIcon } from './font-awesome'

import classnames from 'classnames'
import styles from './styles.module.less'

export const Icon = ({ type, status, size, className }) => {
  if (type === 'error') {
    return (
      <div className={classnames(
        styles.icon_fa,
        styles.icon,
        styles[type],
        size && styles[size],
        ['info', 'low'].includes(status) && styles.low,
        ['warning', 'medium'].includes(status) && styles.medium,
        ['critical', 'high'].includes(status) && styles.high,
        className
      )}>
        <BugIcon />
      </div>
    )
  }

  if (type === 'alarm') {
    return (
      <div className={classnames(
        styles.icon,
        styles[type],
        size && styles[size],
        ['info', 'low'].includes(status) && styles.low,
        ['warning', 'medium'].includes(status) && styles.medium,
        ['critical', 'high'].includes(status) && styles.high,
        className
      )}>
        <AlertIcon />
      </div>
    )
  }

  return (
    <ReactSVG
      src={`/static/vendor/icons/${type}.svg`}
      className={classnames(
        styles.icon,
        styles[type],
        size && styles[size],
        ['info', 'low'].includes(status) && styles.low,
        ['warning', 'medium'].includes(status) && styles.medium,
        ['critical', 'high'].includes(status) && styles.high,
        className
      )}
    />
  )
}
