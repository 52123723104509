// EU member states where vat applies, is compiled from:
// https://ec.europa.eu/taxation_customs/sites/taxation/files/resources/documents/taxation/vat/how_vat_works/rates/vat_rates_en.pdf
export const EU_MEMBER_STATES = {
  BE: true,
  BG: true,
  CZ: true,
  DK: true,
  DE: true,
  EE: true,
  IE: true,
  EL: true,
  ES: true,
  FR: true,
  HR: true,
  IT: true,
  CY: true,
  LV: true,
  LT: true,
  LU: true,
  HU: true,
  MT: true,
  NL: true,
  AT: true,
  PL: true,
  PT: true,
  RO: true,
  SI: true,
  SK: true,
  FI: true,
  SE: true,
  UK: true
}

export const SUBSCRIPTION_TYPES = {
  ON_DEMAND: 'onDemand',
  RESERVED: 'reserved'
}

export const TIER_NAMES = {
  free: 'Community',
  pro: 'Professional',
  enterprise: 'Enterprise'
}

export const BILLABLE_SERVICES = {
  'AWS::SQS::Queue': {
    name: 'SQS Queue',
    settingsLink: '/settings/integrations',
    integration: 'sqs'
  },
  'AWS::Lambda::Function': {
    name: 'Lambda Function',
    settingsLink: '/settings/integrations',
    integration: 'lambda'
  },
  'AWS::ECS::Service': {
    name: 'ECS Service',
    settingsLink: '/settings/integrations',
    integration: 'ecs'
  },
  'AWS::DynamoDB::Table': {
    name: 'DynamoDB Table',
    settingsLink: '/settings/integrations',
    integration: 'dynamodb'
  },
  'AWS::ApiGateway::Stage': {
    name: 'API Gateway Stage',
    settingsLink: '/settings/integrations',
    integration: 'apigateway.rest'
  },
  'AWS::Kinesis::Stream': {
    name: 'Kinesis Stream',
    settingsLink: '/settings/integrations',
    integration: 'kinesis.data-streams'
  },
  'AWS::KinesisFirehose::DeliveryStream': {
    name: 'Kinesis Firehose Delivery Stream',
    settingsLink: '/settings/integrations',
    integration: 'kinesis.firehose'
  },
  'AWS::StepFunctions::StateMachine': {
    name: 'Step Functions State Machine',
    settingsLink: '/settings/integrations',
    integration: 'stepfunctions'
  }
}
