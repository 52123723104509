import { combineReducers } from 'redux'
import {
  GET_PAYMENT_METHODS,
  POST_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  SET_PRIMARY_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_SETUP,
  CANCEL_PAYMENT_METHOD_SETUP
} from 'actions/billing.actions'

function intent (state = null, action) {
  switch (action.type) {
    case CREATE_PAYMENT_METHOD_SETUP:
      return action.payload
    case CANCEL_PAYMENT_METHOD_SETUP:
      return null
  }
  return state
}

function methods (state = [], action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return action.payload
    case POST_PAYMENT_METHOD:
      const newMethod = { ...action.payload, isPrimary: true }
      const existingMethods = state.map(method => ({ ...method, isPrimary: false }))
      return [...existingMethods, newMethod]
    case DELETE_PAYMENT_METHOD:
      return state.filter(method => method.stripeId !== action.payload.id)
    case SET_PRIMARY_PAYMENT_METHOD:
      return state.map(method => { return { ...method, isPrimary: method.stripeId === action.payload.id } })
  }
  return state
}

export default combineReducers({
  intent, methods
})
