import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { RESOURCE_GROUPS_LIST, resourceGroupsPaths as paths, RESOURCE_GROUP_PREVIEW, RESOURCE_GROUP_ACTIVE } from './index'

const updateResourceGroup = async (account, groupId, payload) => {
  const data = await apiFetch(paths.group(account, groupId), { method: 'PUT', body: JSON.stringify(payload) })

  return data
}

export const useUpdateResourceGroupQuery = () => {
  const { groupId } = useParams()
  const queryClient = useQueryClient()

  const { account } = useOrganization()

  return useMutation(
    (payload) => updateResourceGroup(account.id, groupId, payload),
    {
      onSuccess: updatedGroup => {
        queryClient.invalidateQueries([...RESOURCE_GROUPS_LIST, account.id])
        queryClient.removeQueries([...RESOURCE_GROUP_PREVIEW])
        queryClient.setQueryData([...RESOURCE_GROUP_ACTIVE, groupId], updatedGroup)
      }
    }
  )
}
