import React, { useState } from 'react'
import { Input } from 'antd'
import classnames from 'classnames'

import useQueryParams from 'lib/hooks/use-query-params'

import styles from './styles.module.less'

const ListSearch = ({ className, onSearchChange }) => {
  const { getValue: query, setValue: setQuery } = useQueryParams('search', '')
  const [queryTimeout, setQueryTimeout] = useState(0)

  const handleOnChange = (e) => {
    const { value } = e.target
    clearTimeout(queryTimeout)

    setQueryTimeout(setTimeout(() => onSearchChange ? onSearchChange(value) : setQuery(value === '' ? undefined : value), 500))
  }

  return <div className={classnames(className, styles.search_container)}>
    <Input.Search
      onChange={handleOnChange}
      defaultValue={query || ''}
      placeholder='Search'
      bordered={false}
      size='large'
      allowClear />
  </div>
}

export default ListSearch
