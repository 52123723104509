import React from 'react'

import classnames from 'classnames'
import styles from './styles.module.less'

const ChartLegend = props => {
  return <div className={classnames(styles.legend)}>
    <p className={classnames(styles.legend_label)}>
            Time
    </p>
    {props.payload.map((item, index) => {
      return (
        <div className={classnames(styles.legend_item)} key={index}>
          <span className={classnames(styles.legend_dot)} style={{ backgroundColor: item.color }} />
          {item.value}
        </div>
      )
    })}</div>
}

export default ChartLegend
