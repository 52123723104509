import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.less'

const Badge = ({ severity = 'info', text, size = 'xs', bordered }) => {
  return (
    <p
      className={classnames(
        styles.severity,
        styles[severity.toLowerCase()],
        styles[size],
        { [styles.bordered]: !!bordered })}
    >
      {text || severity.toLowerCase()}
    </p>
  )
}

export default Badge
