import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { organizationsPaths as paths, ORGANIZATION_INVITATIONS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const putInvitation = async (organization, invitationId, payload) => {
  const data = await apiFetch(paths.invitation(organization, invitationId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useResendInvitationQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ invitationId, payload }) => putInvitation(organization, invitationId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ORGANIZATION_INVITATIONS, organization.id])
      }
    }
  )
}
