import { apiFetch } from 'lib/action-helpers'
import { QUERIES_LIST, queryPaths } from 'hooks/api/queries/index'
import { useOrganization } from 'hooks/context/organization-context'
import { useQuery } from 'react-query'

const getQueries = async (account) => {
  return await apiFetch(queryPaths.queries(account))
}

export const useGetQueriesQuery = (options = {}) => {
  const { account } = useOrganization()

  return useQuery(
    [...QUERIES_LIST, account?.id],
    () => getQueries(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      retry: false,
      ...options
    }
  )
}
