import React from 'react'
import Numeral from 'numeral'

import { Line, Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { DYNAMOBD_WRITES } from 'containers/inventory/details/resource/metrics-tabs/services/dynamodb'

const DynamoWritesChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [DYNAMOBD_WRITES]: data.DYNAMODB_WRITES,
    throttles: data.DYNAMODB_THROTTLES_READS,
    capacity: data.DYNAMODB_CAPACITY_WRITES
  })

  if (!metrics.length) return <Empty height={height} />

  const period = data.DYNAMODB_WRITES.period
  const formatted = metrics.map((metric) => {
    return {
      ...metric,
      [DYNAMOBD_WRITES]: metric[DYNAMOBD_WRITES] / period
    }
  })

  const tooltipFormatter = (value, name, props) => {
    return [`${Numeral(value).format(value < 1 ? '0.[00000]a' : '0.[00]a')}WCU`, undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0000]a'
      span={span}
      tabKey={DYNAMOBD_WRITES}
      formatTab={value => `${Numeral(value).format('0.[000] a')} WCU`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={DYNAMOBD_WRITES} stackId='a' fill={colors('chart', 'primary')} name='Writes' barSize={4} />
      <Bar dataKey='throttles' stackId='a' fill={colors('chart', 'red')} name='Throttles' barSize={4} />
      <Line dataKey='capacity' type='stepAfter' stroke={colors('chart', 'red')} strokeWidth={2} name='Capacity' dot={false} />
    </ChartContainer>
  )
}

export default DynamoWritesChart
