import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'DYNAMODB_READS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'read',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'DYNAMODB_WRITES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'write',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'DYNAMODB_CAPACITY_READS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'capacity.read',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_CAPACITY_WRITES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'capacity.write',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_THROTTLES_READS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'throttles.read',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'DYNAMODB_THROTTLES_WRITES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'throttles.write',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'DYNAMODB_ERRORS_USER',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.user',
    stats: [STATISTICS.COUNT]
  }
}, {
  id: 'DYNAMODB_ERRORS_SYSTEM',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.system',
    stats: [STATISTICS.COUNT]
  }
}, {
  id: 'DYNAMODB_LATENCY_SCAN',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.scan',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_LATENCY_QUERY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.query',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_LATENCY_ITEM_GET',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.item.get',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_LATENCY_ITEM_PUT',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.item.put',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'DYNAMODB_LATENCY_ITEM_UPDATE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.item.update',
    stats: [STATISTICS.AVERAGE]
  }
}]

export default metrics
