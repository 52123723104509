import numeral from 'numeral'
import { last, head } from 'lodash'

import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'
import { faContainerStorage } from '@fortawesome/pro-light-svg-icons/faContainerStorage'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip'

import { getLast, getSparklineByName } from '../helpers'
import { ECS_CPU, ECS_MEMORY } from './ecs-cluster'

const metrics = [{
  key: ECS_CPU,
  icon: faMicrochip,
  title: 'CPU',
  filter: (collection) => `${numeral(getLast(collection, 'ECS_CPU_UTILIZATION')).format('0.[00]')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'ECS_CPU_UTILIZATION'),
  selectable: true
},
{
  key: ECS_MEMORY,
  icon: faMemory,
  title: 'Memory',
  filter: (collection) => `${numeral(getLast(collection, 'ECS_MEM_UTILIZATION')).format('0a')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'ECS_MEM_UTILIZATION'),
  selectable: true
}, {
  key: 'tasks',
  icon: faContainerStorage,
  title: 'Tasks',
  filter: (collection, item, metadata) => `${getLast(collection, 'ECS_SERVICE_TASKS')} / ${metadata.attributes.desiredCount}`
}, {
  key: 'launch_type',
  icon: faServer,
  title: 'Launch type',
  filter: (c, i, metadata) => metadata.attributes.launchType
}, {
  key: 'definition',
  icon: faFileAlt,
  title: 'Definition',
  filter: (c, i, metadata) => last(last(metadata.attributes.taskDefinition.split('/')).split(':')),
  subvalue: (c, i, metadata) => head(last(metadata.attributes.taskDefinition.split('/')).split(':'))
}]

export default metrics
