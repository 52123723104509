import React from 'react'
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { sortBy } from 'lodash'

import { Row, Col, Tooltip, Tag, Table, Badge } from 'antd'
import Section from 'components/layout/content/section'

import filterResources from 'lib/filter-resources'
import { findInventoryByType } from 'lib/resources/constants'
import { useResourcesQuery } from 'hooks/api'

import styles from './styles.module.less'

const ResourceList = ({ integration }) => {
  const { data: resources, isLoading: loading } = useResourcesQuery()

  const { included, excluded } = filterResources(Object.values(resources || {}), integration)

  const columns = [{
    title: 'Name',
    key: uuid(),
    render: item => <Tooltip title={item.description}><Link to={`/inventory/${item?.id}`}>{item?.title}</Link></Tooltip>,
    sorter: (a, b) => a.arn.localeCompare(b.arn)
  }, {
    title: 'Type',
    key: uuid(),
    render: item => findInventoryByType(item.type)?.shortTitle,
    sorter: (a, b) => a.arn.localeCompare(b.arn)
  }, {
    title: 'Region',
    dataIndex: 'region',
    key: uuid(),
    render: region => <Tag>{region}</Tag>,
    sorter: (a, b) => a.region.localeCompare(b.region)
  }]

  return (
    <Row gutter={32} className={styles.resourceList}>
      <Col span={12}>
        <Section
          title={<span>Included resources <Badge count={included?.length} overflowCount={999} className={styles.active} /></span>}
          loading={loading}
          titleUpperCase
        >
          <Table
            rowKey={(record) => record.id}
            size='small'
            pagination={false}
            locale={{ emptyText: excluded?.length ? 'All resources are excluded! :(' : '' }}
            dataSource={sortBy(included, 'name')}
            columns={columns}
          />
        </Section>
      </Col>
      <Col span={12}>
        <Section
          title={<span>Excluded resources <Badge count={excluded?.length} overflowCount={999} /></span>}
          loading={loading}
          titleUpperCase
        >
          <Table
            rowKey={(record) => record.id}
            size='small'
            pagination={false}
            locale={{ emptyText: included?.length ? 'Great, all resources are monitored by Dashbird!' : '' }}
            dataSource={sortBy(excluded, 'name')}
            columns={columns}
          />
        </Section>
      </Col>
    </Row>
  )
}

export default ResourceList
