import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { uniqBy, groupBy, isEqual } from 'lodash'
import memoizeOne from 'memoize-one'

import { apiFetch } from 'lib/action-helpers'
import { INSIGHT_RULES_LIST, insightsPaths as paths } from './index'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import { useInsightsQuery } from '../index'

const mapInsightRules = (rules, insights) => {
  if (!rules) return undefined
  if (!insights) return rules

  const groupByRule = groupBy(insights, 'rule')

  const result = rules?.map(rule => {
    return {
      ...rule,
      count: groupByRule[rule.id]?.length || 0,
      regions: uniqBy(groupByRule[rule.id], 'resource.region')?.map(insight => insight.resource.region),
      service: Object.values(INVENTORY_SERVICE_GROUPS).find(item => rule?.id?.includes(item.service))?.service,
      delegations: uniqBy(groupByRule[rule.id], 'resource.delegation').map(insight => insight.resource.delegation) || []
    }
  })
  return result
}
const memoizedResponse = memoizeOne(mapInsightRules, isEqual)

const getRules = async (account) => {
  const data = await apiFetch(paths.rules(account))
  return data?.data
}

export const useInsightsRulesQuery = () => {
  const { account } = useOrganization()

  const { data: insights } = useInsightsQuery()

  const queryOpts = useQuery(
    [...INSIGHT_RULES_LIST, account?.id],
    () => getRules(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      cacheTime: 30 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, insights)

  return {
    ...queryOpts,
    data
  }
}
