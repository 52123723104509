import { throwAsyncError } from 'hooks/nodes/transform/error'

const ERROR_INCOMPLETE_SEGMENT = 'Incomplete segment'

const exceptionToMessage = (exception) => {
  const { stack } = exception
  if (stack && stack.length > 0) {
    const { path, line, label } = stack[0]
    return `${path}:${line} ${label}`
  }

  throwAsyncError('exceptionToMessage for node not implemented', exception)
}

const getErrors = (node) => {
  const errors = []

  if (node.cause?.exceptions) {
    errors.push(...node.cause.exceptions.map(ex => ({
      name: ex.type,
      message: `${ex.message || ''} ${exceptionToMessage(ex)}`
    })))
  }

  if (!node.end_time) {
    errors.push({
      name: ERROR_INCOMPLETE_SEGMENT,
      message: 'missing end time'
    })
  }

  if (node.error) {
    errors.push({
      name: 'Error',
      message: 'This node or its children have errors'
    })
  }

  return errors
}

export { getErrors }
