import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Typography } from 'components/typography'

import styles from './styles.module.less'
import Section from 'components/layout/content/section'

const RuleDescription = ({ text }) => {
  return (
    <Section solid contentWrapperClassName={styles.wrapper}>
      <Typography.Title level={4} upperCase>
        Description
      </Typography.Title>
      <ReactMarkdown
        components={{
          h1: (props) => <Typography.Title {...props} level={1} />,
          h2: (props) => <Typography.Title {...props} level={2} />,
          h3: (props) => <Typography.Title {...props} level={3} />,
          h4: (props) => <Typography.Title {...props} level={4} upperCase />,
          p: (props) => <Typography.Paragraph {...props} dark className={styles.paragraph} />,
          ul: (props) => <ul className={styles.ul} {...props} />,
          ol: (props) => <ol className={styles.ol} {...props} />,
          code: (props) => <span className={styles.code_wrapper} ><code {...props} /></span>
        }}
      >
        {text}
      </ReactMarkdown>
    </Section>
  )
}

export default RuleDescription
