import { createContext } from 'react'
import { CHART_COLORS } from 'lib/colors'

export const FlowChartTheme = createContext<{
  background: string,
  fixedHeight?: number
}>({
  background: CHART_COLORS.backgroundPrimary,
  fixedHeight: undefined
})
