import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { organizationsPaths as paths, ACCOUNTS_LIST, ORGANIZATIONS_LIST } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'

const createAccount = async (organization, account) => {
  const data = await apiFetch(paths.accounts(organization), { body: JSON.stringify(account), method: 'POST' })

  return data
}

export const useCreateAccountQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()
  const { user } = useUser()

  return useMutation(
    (payload) => createAccount(organization.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ACCOUNTS_LIST, organization.id])
        queryClient.invalidateQueries([...ORGANIZATIONS_LIST, user.attributes.email])
      }
    }
  )
}
