/**
 * Based on the current environment variable, we need to make sure
 * to exclude any DevTools-related code from the production builds.
 */
import prodStore, { history as prodHistory } from './store.prod'
import devStore, { history as devHistory } from './store.dev'

let exportedStore, exportedHistory
if (CONFIG.NODE_ENV === 'production') {
  exportedStore = prodStore
  exportedHistory = prodHistory
} else {
  exportedStore = devStore
  exportedHistory = devHistory
}

export const history = exportedHistory
export default exportedStore
