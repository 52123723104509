import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import { Button, Form } from 'antd'
import { first, last } from 'lodash'

import { PermissionsGate, SCOPES } from 'features/permissions-gate/index'
import { findInventoryByType } from 'lib/resources/constants'
import { alarmMetricToConventionalMetric } from 'lib/dashboards-helpers'
import { AggregationTypes } from 'lib/alerting-constants'
import { getInventoryResources } from 'lib/resources/filters'

import Section from 'components/layout/content/section'
import Targets from 'features/target-selector'
import ChartPreview from './chart'
import Select from 'components/antd/select'
import RadioSmall from 'components/antd/radio/small'

import styles from './styles.module.less'

const selectableMetrics = (resourceType) => {
  const metrics = findInventoryByType(resourceType)?.metrics

  return metrics?.map(metric => {
    const { value, title } = metric
    return {
      // HACK: transform metric to conventional metric name,
      // only applies to certain TIMESCALE lambda metrics
      value: alarmMetricToConventionalMetric(value),
      title: title.toLowerCase()
    }
  }) || []
}

const selectableAggregationTypes = (metric) => {
  const WITHOUT_SUM_AGGREGATION = ['memory_size', 'max_memory_used', 'percentage_memory_used']

  const isDisabled = (value) => {
    if (value === 'sum') {
      return WITHOUT_SUM_AGGREGATION.includes(metric)
    }

    return false
  }

  return AggregationTypes.map(type => ({
    value: type.value,
    label: type.title,
    disabled: isDisabled(type.value)
  }))
}

const getInitialValues = (editing, widget) => {
  if (editing) {
    return {
      payloads: widget.definition.metrics.map(metric => ({
        resourceType: metric.resourceType,
        targets: [metric.resource],
        metric: metric.metric,
        stats: metric.stats?.length > 1 ? 'avg' : metric?.stats?.[0] || 'avg'
      }))
    }
  }

  // Default form values
  return {
    payloads: [{
      targets: [],
      resourceType: null,
      metric: null,
      stats: null
    }]
  }
}

const MetricDefinition = ({ form, resources, payload, name: metricIndex, remove }) => {
  const targetRef = useRef()
  const statsFieldName = ['payloads', metricIndex, 'stats']
  const targetsFieldName = ['payloads', metricIndex, 'targets']
  const resourceTypeFieldsName = ['payloads', metricIndex, 'resourceType']

  const resourcesOptions = useMemo(() => getInventoryResources(resources), [resources])
  const metricsOptions = useMemo(() => selectableMetrics(payload?.resourceType), [payload?.resourceType])
  const aggregationTypesOptions = useMemo(() => selectableAggregationTypes(payload?.metric), [payload?.metric, payload?.stats])

  // If stat becomes disabled (for example when metric changes),
  // make sure to remove it from selected stats
  useEffect(() => {
    aggregationTypesOptions.forEach(stat => {
      if (stat.disabled && payload?.stats === stat) {
        form.setFieldValue(statsFieldName, null)
      }
    })
  }, [payload?.stats, aggregationTypesOptions])

  // When target is selected,
  // set resourceType based on selected resource
  useEffect(() => {
    if (!payload) return
    const resourceId = first(payload?.targets)
    const resource = resources[resourceId]
    const typeChange = resource?.type !== form.getFieldValue(resourceTypeFieldsName)

    form.setFieldValue(resourceTypeFieldsName, resource?.type)

    // When target resourceType is changed,
    // clear metric selection
    if (typeChange) form.setFieldValue(['payloads', metricIndex, 'metric'], null)
  }, [payload?.targets])

  // CHEAP HACK ALERT! When one target is selected and user selects another one,
  // remove first selected target.
  useEffect(() => {
    if (payload?.targets?.length > 1) {
      form.setFieldValue(targetsFieldName, [last(payload?.targets)])
    }
  }, [payload?.targets])

  return (
    <Section>
      <Form.Item name={[metricIndex, 'targets']} rules={[{ required: true, message: 'Please choose a resource' }]} required={false}>
        <Targets
          resources={resourcesOptions}
          filterOption={true}
          placeholder='Select resource'
          allowClear={false}
          ref={targetRef}
          onChange={() => targetRef?.current?.blur()}
        />
      </Form.Item>

      <Form.Item
        name={[metricIndex, 'metric']}
        label='Metric'
        rules={[{ required: true, message: 'Please choose a metric' }]}
        required={false}
      >
        <Select
          solid
          options={metricsOptions}
          disabledextra={!payload?.resourceType}
          placeholder='Choose metric'
        />
      </Form.Item>

      <Form.Item
        name={[metricIndex, 'stats']}
        label='Statistic'
        rules={[{ required: true, message: 'Please choose a statistic' }]}
      >
        <RadioSmall items={aggregationTypesOptions} />
      </Form.Item>

      {
        false && <Button onClick={() => remove(metricIndex)}>
          Remove chart
        </Button>
      }
    </Section>
  )
}

const MetricsWidgetDetails = ({ editing, widget, kind, resources, handlers, onClose }) => {
  const [form] = Form.useForm()
  const payloads = Form.useWatch('payloads', form)

  const handleFormSubmit = useCallback((payload) => {
    if (editing) {
      handlers.widgets.update(widget, payload)
    } else {
      handlers.widgets.add(kind, payload)
    }

    onClose()
  }, [handlers, widget, editing, kind])

  return (
    <>
      {payloads && (
        <ChartPreview
          className={styles.chart_wrapper}
          resources={resources}
          values={{ payloads }}
        />
      )}
      <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true }}>
        <Form
          initialValues={getInitialValues(editing, widget)}
          layout='vertical'
          form={form}
          autoComplete="off"
          onFinish={handleFormSubmit}
        >
          <Form.List name='payloads'>
            {(fields, { add, remove }) => (
              <>
                {fields.map((data, index) => (
                  <MetricDefinition
                    key={index}
                    form={form}
                    resources={resources}
                    payload={payloads && payloads[data.key]}
                    remove={remove}
                    {...data}
                  />
                ))}
                {
                  false && <Form.Item>
                    <Button type='dashed' onClick={() => add()} block>
                      Add chart
                    </Button>
                  </Form.Item>
                }
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
            >
              {editing ? 'Update widget' : 'Add widget'}
            </Button>
          </Form.Item>
        </Form>
      </PermissionsGate>

    </>
  )
}

export default MetricsWidgetDetails
