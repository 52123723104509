import React from 'react'
import { formatDuration } from 'components/charts/utils'
import Metadata from 'containers/inventory/shared/metadata'
const LambdaMetadata = ({ attributes = {}, inverse }) => {
  const description = attributes.description || attributes.Description
  const runtime = attributes.runtime || attributes.Runtime
  const memorySize = attributes.memorySize || attributes.MemorySize
  const timeout = attributes.timeout || attributes.Timeout
  const role = attributes.role || attributes.Role
  const tracing = attributes.tracingConfig
  const version = attributes.version || attributes.Version
  const vpcConfig = JSON.parse(attributes.vpcConfig || null)

  const data = [{
    title: 'General',
    children: [
      { key: 'Description', value: description },
      { key: 'Memory size', value: `${memorySize} MB` },
      { key: 'Timeout', value: formatDuration(parseInt(timeout) * 1000) }
    ]
  }, {
    title: 'Runtime',
    value: runtime
  }, {
    title: 'IAM role',
    value: role
  }, {
    title: 'Tracing configuration',
    value: tracing
  }, {
    title: 'Version',
    value: version
  }, {
    title: 'VPC configuration',
    children: vpcConfig?.map(item => ({ key: item?.name, value: item?.value }))
  }]

  return <Metadata items={data} inverse={inverse} />
}

export default LambdaMetadata
