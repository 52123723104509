import numeral from 'numeral'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faUsdCircle } from '@fortawesome/pro-light-svg-icons/faUsdCircle'
import { faRectangleVerticalHistory } from '@fortawesome/pro-light-svg-icons/faRectangleVerticalHistory'

import { getLastNonZero, getSum, getSparklineByName, getValues, average } from '../helpers'
import { formatDuration } from 'components/charts/utils'

export const LAMBDA_INVOCATIONS = 'lambda-invocations'
export const LAMBDA_ERRORS = 'lambda-errors'
export const LAMBDA_DURATION = 'lambda-duration'
export const LAMBDA_MEMORY = 'lambda-memory'
export const LAMBDA_COST = 'lambda-cost'
export const LAMBDA_CONCURRENCY = 'lambda-concurrency'

const metrics = [{
  key: LAMBDA_INVOCATIONS,
  icon: faSigma,
  title: 'Invocations',
  filter: (collection) => numeral(getSum(collection, 'INVOCATIONS_TOTAL')).format('0.[0]a'),
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_TOTAL'),
  selectable: true
},
{
  key: LAMBDA_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => numeral(getSum(collection, 'INVOCATIONS_DETECTED_ERRORS')).format('0.[0]a'),
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_DETECTED_ERRORS'),
  selectable: true
},
{
  key: LAMBDA_DURATION,
  icon: faClock,
  title: 'Duration',
  filter: (collection) => {
    const values = getValues(collection, 'INVOCATIONS_DURATION')?.map(item => item.value)
    return formatDuration(average(values))
  },
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_DURATION'),
  selectable: true
},
{
  key: LAMBDA_MEMORY,
  icon: faMemory,
  title: 'Memory',
  filter: (collection) => numeral(getLastNonZero(collection, 'INVOCATIONS_MEMORY')).format('0%'),
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_MEMORY'),
  selectable: true
},
{
  key: LAMBDA_CONCURRENCY,
  icon: faRectangleVerticalHistory,
  title: 'Concurrency',
  filter: (collection) => {
    const values = getValues(collection, 'INVOCATIONS_CONCURRENCY_MAX')?.map(item => item.value)
    return numeral(average(values)).format('0.[0]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_CONCURRENCY_MAX'),
  selectable: true
},
{
  key: LAMBDA_COST,
  icon: faUsdCircle,
  title: 'Cost',
  filter: (collection) => numeral(getSum(collection, 'INVOCATIONS_COST')).format('0.00$'),
  subvalue: (collection) => getSparklineByName(collection, 'INVOCATIONS_COST'),
  selectable: true
}]

export default metrics
