import React, { createContext, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { differenceInMinutes, sub } from 'date-fns'

import { objectify } from 'lib/utils'
import { getDelegationName, hasMoreDelegations } from 'lib/resources/transformations'
import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'
import { useAccountRoutes } from 'containers/routes'

import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'

import APIGateway from './services/apigateway'
import AppSyncGraphQLApi from './services/appsync/graphql-api'
import DynamoDB from './services/dynamodb'
import ECSCluster from './services/ecs/cluster'
import ECSService from './services/ecs/service'
import ELBApplicationLoadBalancer from './services/elb/application-load-balancer'
import ELBGatewayLoadBalancer from './services/elb/gateway-load-balancer'
import ELBNetworkLoadBalancer from './services/elb/network-load-balancer'
import ELBTargetGroup from './services/elb/target-group'
import EventBridgeEventBus from './services/eventbridge/eventbus'
import EventBridgeRule from './services/eventbridge/rule'
import KinesisAnalytics from './services/kinesis/analytics'
import KinesisDataStream from './services/kinesis/datastream'
import KinesisFirehoseDeliveryStream from './services/kinesis/firehose'
import Lambda from './services/lambda'
import OpenSearch from './services/opensearch'
import RDSCluster from './services/rds/rds-cluster'
import RDSInstance from './services/rds/rds-instance'
import RDSProxy from './services/rds/rds-proxy'
import S3 from './services/s3'
import SNS from './services/sns'
import SQS from './services/sqs'
import StepFunctions from './services/stepfunctions'

import { AwsServiceIcon, AwsIcon } from 'components/icons'
import ExternalLinkButton from 'components/buttons/external-link'
import GlobalDatePicker from 'components/date-picker/global'
import { useDelegationsQuery, useAllResourcesQuery, useResourceQuery, useChartMetricsQuery } from 'hooks/api'
import { ChartMetrics } from './metrics'
import { getMetricResourceDimensions } from 'lib/metric-helpers'

import styles from './styles.module.less'

export const ServiceContext = createContext({ metricsData: {}, loadingMetrics: false, range: 24 * 60, relativeSpan: 24 * 60 })

const ResourceDetails = ({ resource, start, end }) => {
  const serviceProps = {
    item: resource,
    start,
    end
  }

  const itemService = findInventoryByType(resource.type)

  switch (itemService?.id) {
    case INVENTORY_SERVICES.AppSyncGraphQLApi.id:
      return <AppSyncGraphQLApi {...serviceProps} />
    case INVENTORY_SERVICES.Lambda.id:
      return <Lambda {...serviceProps} />
    case INVENTORY_SERVICES.SQS.id:
      return <SQS {...serviceProps} />
    case INVENTORY_SERVICES.DynamoDB.id:
      return <DynamoDB {...serviceProps} />
    case INVENTORY_SERVICES.APIGatewayRest.id:
      return <APIGateway {...serviceProps} />
    case INVENTORY_SERVICES.APIGatewayHttp.id:
      return <APIGateway {...serviceProps} />
    case INVENTORY_SERVICES.ECSCluster.id:
      return <ECSCluster {...serviceProps} />
    case INVENTORY_SERVICES.ECSService.id:
      return <ECSService {...serviceProps} />
    case INVENTORY_SERVICES.ELBApplicationLoadBalancer.id:
      return <ELBApplicationLoadBalancer {...serviceProps} />
    case INVENTORY_SERVICES.ELBGatewayLoadBalancer.id:
      return <ELBGatewayLoadBalancer {...serviceProps} />
    case INVENTORY_SERVICES.ELBNetworkLoadBalancer.id:
      return <ELBNetworkLoadBalancer {...serviceProps} />
    case INVENTORY_SERVICES.ELBTargetGroup.id:
      return <ELBTargetGroup {...serviceProps} />
    case INVENTORY_SERVICES.EventBridgeEventBus.id:
      return <EventBridgeEventBus {...serviceProps} />
    case INVENTORY_SERVICES.EventBridgeRule.id:
      return <EventBridgeRule {...serviceProps} />
    case INVENTORY_SERVICES.StepFunctions.id:
      return <StepFunctions {...serviceProps} />
    case INVENTORY_SERVICES.KinesisAnalytics.id:
      return <KinesisAnalytics {...serviceProps} />
    case INVENTORY_SERVICES.KinesisDataStream.id:
      return <KinesisDataStream {...serviceProps} />
    case INVENTORY_SERVICES.KinesisFirehose.id:
      return <KinesisFirehoseDeliveryStream {...serviceProps} />
    case INVENTORY_SERVICES.SNS.id:
      return <SNS {...serviceProps} />
    case INVENTORY_SERVICES.RDSCluster.id:
      return <RDSCluster {...serviceProps} />
    case INVENTORY_SERVICES.RDSInstance.id:
      return <RDSInstance {...serviceProps} />
    case INVENTORY_SERVICES.RDSProxy.id:
      return <RDSProxy {...serviceProps} />
    case INVENTORY_SERVICES.OpenSearch.id:
      return <OpenSearch {...serviceProps} />
    case INVENTORY_SERVICES.S3.id:
      return <S3 {...serviceProps} />
    default:
      return <div>Resource detail view for {itemService?.id} not implemented</div>
  }
}

const ResourceEmpty = () => {
  return <Empty title='Resource not found' />
}

const Resource = () => {
  const { resourceId, groupId } = useParams()
  const routes = useAccountRoutes()

  const [start, setStart] = useState(sub(new Date(), { days: 1 }))
  const [end, setEnd] = useState(new Date())
  const [range, setRange] = useState(24 * 60)
  const [relativeSpan, setRelativeSpan] = useState(24 * 60)
  const [refreshInterval, setRefreshInterval] = useState(false)
  const [metrics, setMetrics] = useState([])

  const { data: resource, isLoading, isError } = useResourceQuery(resourceId)
  const { data: delegations } = useDelegationsQuery()
  const { data: resources } = useAllResourcesQuery()
  const loading = isLoading || !resource

  const service = findInventoryByType(resource?.type)
  const serviceChartMetrics = ChartMetrics[service?.id]

  const formatPayload = (metric) => {
    if (!resource || !metric) return
    const lambdaPeriod = service.id === INVENTORY_SERVICES.Lambda.id ? { period: metric.period ? metric.period(start, end) : 0 } : {}
    const region = service.id === INVENTORY_SERVICES.Lambda.id ? { regions: [resource.region] } : {}
    const metricsPayload = {
      dimensions: {
        resources: getMetricResourceDimensions(resource, resources, metric.filter?.metric),
        ...region
      },
      ...metric.filter,
      start: metric.start ? metric.start(start, end) : start,
      end: metric.end ? metric.end(end) : end,
      ...lambdaPeriod
    }

    return metricsPayload
  }

  const chartPayloads = useMemo(() => resource ? serviceChartMetrics?.map(metric => ({ id: metric.id, payload: formatPayload(metric), overrideRelative: metric.overrideRelative })) : [], [resource, resources, start, end])

  const metricsData = useChartMetricsQuery(chartPayloads, relativeSpan, refreshInterval)

  const loadingMetrics = metricsData.some(item => item.isLoading)
  const fetching = metricsData.some(item => item.isFetching)

  const formatMetrics = () => {
    if (fetching || loadingMetrics) return

    const mappedMetrics = metricsData?.reduce((acc, item) => {
      acc[item?.data?.metricId] = item.data
      return acc
    }, {})

    setMetrics(mappedMetrics)
  }

  const handleManualRefetch = (e) => {
    e?.stopPropagation()
    metricsData.map(item => item?.refetch())
  }

  const handleSelectRange = (start, end, relativeSpan) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMinutes(end, start))
    setRefreshInterval(false)
    setRelativeSpan(relativeSpan || false)
  }

  const handleIntervalChange = (e) => {
    const valueInMs = e.target.value
    if (valueInMs === 'false') return setRefreshInterval(false)
    setRefreshInterval(parseInt(valueInMs))
  }

  const getSubtitle = (service, resource) => {
    if (!resource || isEmpty(resource)) return

    switch (service?.id) {
      case INVENTORY_SERVICES.APIGatewayRest.id:
        return resource.attributes.filter(attr => attr.name === 'baseInvokeUrl').map(attr => attr.value) || ''
      case INVENTORY_SERVICES.ECSService.id: {
        const attributes = objectify(resource.attributes)
        return attributes.clusterArn && attributes.clusterArn.split('/')[1]
      }
      default:
        return ''
    }
  }

  const delegationName = () => {
    if (!resources) return null

    return hasMoreDelegations(Object.values(resources)) && getDelegationName(delegations, resource)
  }

  const metricDataPeriod = metricsData ? metricsData[0]?.data?.period : 3600

  useEffect(() => {
    if (!resource && isEmpty(resource)) return

    document.title = `Dashbird.io - ${service?.title} - ${resource?.region + ' - '}${resource?.title}`
  }, [resourceId])

  useEffect(formatMetrics, [loadingMetrics, fetching, resourceId, relativeSpan])

  const parentResource = resource?.parent ? (resources && resources[resource?.parent?.id]) : true

  return (
    <Content
      item={resource}
      title={resource?.title}
      subtitle={getSubtitle(service, resource)}
      backRoute={groupId ? routes.resourceGroups.group.url({ groupId }) : routes.inventory.url()}
      loading={isError ? false : loading}
      icon={<AwsServiceIcon className={styles.icon} service={service?.service} solid />}
      breadcrumbs={[delegationName(), resource?.region]}
      actions={(
        <GlobalDatePicker
          max={new Date()}
          min={sub(new Date(), { months: 1 })}
          start={start}
          end={end}
          onChange={handleSelectRange}
          refreshInterval={refreshInterval}
          handleIntervalChange={handleIntervalChange}
          relativeSpan={relativeSpan}
          fetching={fetching}
          handleManualRefetch={handleManualRefetch}
          period={metricDataPeriod} />)}
      titleRowActions={parentResource && <ExternalLinkButton link={service?.awsLink(resource, parentResource)}><AwsIcon className={styles.aws_icon} /></ExternalLinkButton>}
      renderEmpty={ResourceEmpty}
      fixed
    >
      {!isEmpty(resource) && resourceId === resource?.id &&
        <ServiceContext.Provider value={{ metricsData: metrics, loadingMetrics, start, end, range, relativeSpan }}>
          <ResourceDetails resource={resource} start={start} end={end} />
        </ServiceContext.Provider>}
    </Content>
  )
}

export default Resource
