import numeral from 'numeral'

import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faBellOn } from '@fortawesome/pro-light-svg-icons/faBellOn'
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload'

import { getSum, getSparklineByName, getValues, average } from '../helpers'

export const SNS_MESSAGES = 'messages-published'
export const SNS_NOTIFICATIONS = 'notifications-delivered'
export const SNS_PAYLOAD_SIZE = 'payload-size'

const metrics = [{
  key: SNS_MESSAGES,
  icon: faUpload,
  title: 'Msg published',
  filter: (collection) => numeral(getSum(collection, 'SNS_MESSAGES_PUBLISHED')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'SNS_MESSAGES_PUBLISHED'),
  selectable: true
}, {
  key: SNS_NOTIFICATIONS,
  icon: faBellOn,
  title: 'Notif. delivered',
  filter: (collection) => numeral(getSum(collection, 'SNS_NOTIFICATIONS_DELIVERED')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'SNS_NOTIFICATIONS_DELIVERED'),
  selectable: true
}, {
  key: SNS_PAYLOAD_SIZE,
  icon: faMemory,
  title: 'Avg payload size',
  filter: (collection) => {
    const values = getValues(collection, 'SNS_PAYLOAD_SIZE')?.map(item => item.value)
    return numeral(average(values)).format('0.[00] b')
  },
  subvalue: (collection) => getSparklineByName(collection, 'SNS_PAYLOAD_SIZE'),
  selectable: true
}]

export default metrics
