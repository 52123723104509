import APIGateway from './apigateway/chart'
import AppSyncAPI from './appsync/chart'
import AppSyncResolver from './appsync/resolvers/chart'
import DynamoDB from './dynamodb/chart'
import ECSCluster from './ecs/cluster/chart'
import ECSService from './ecs/service/chart'
import ELBApplicationLoadBalancer from './elb/application-load-balancer/chart'
import ELBGatewayLoadBalancer from './elb/gateway-load-balancer/chart'
import ELBNetworkLoadBalancer from './elb/network-load-balancer/chart'
import ELBTargetGroup from './elb/target-group/chart'
import EventBridgeEventBus from './eventbridge/eventbus/chart'
import EventBridgeRule from './eventbridge/rule/chart'
import KinesisAnalytics from './kinesis/analytics/chart'
import KinesisDataStream from './kinesis/data-stream/chart'
import KinesisFirehose from './kinesis/firehose/chart'
import Lambda from './lambda/chart'
import OpenSearch from './opensearch/chart'
import RDSCluster from './rds/cluster/chart'
import RDSInstance from './rds/instance/chart'
import RDSProxy from './rds/proxy/chart'
import S3 from './s3/chart'
import SNS from './sns/chart'
import SQS from './sqs/chart'
import StepFunctions from './step-functions/chart'

export const ChartMetrics = {
  APIGatewayHttp: APIGateway,
  APIGatewayRest: APIGateway,
  AppSyncAPI,
  AppSyncResolver,
  DynamoDB,
  ECSCluster,
  ECSService,
  ELBApplicationLoadBalancer,
  ELBGatewayLoadBalancer,
  ELBNetworkLoadBalancer,
  ELBTargetGroup,
  EventBridgeEventBus,
  EventBridgeRule,
  KinesisAnalytics,
  KinesisDataStream,
  KinesisFirehose,
  Lambda,
  S3,
  SQS,
  StepFunctions,
  SNS,
  RDSInstance,
  RDSCluster,
  RDSProxy,
  OpenSearch
}
