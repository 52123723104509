import React, { useState, useEffect } from 'react'
import { Input, Button, Tooltip } from 'antd'
import classnames from 'classnames'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { CloseIcon } from 'components/icons'
import { TAG_FILTER, TAG_SEPARATOR, NAME_FILTER } from './constants'

import styles from './styles.module.less'

const DeleteButton = ({ onClick, loading, enabled }) => {
  if (!enabled) return null
  return (
    <Tooltip title='Delete' className={styles.filter_delete_btn}>
      <Button icon={<CloseIcon />} onClick={onClick} loading={loading} />
    </Tooltip>
  )
}

const FilterDisplay = ({ type, editable, value }) => {
  if (type === NAME_FILTER) {
    return <Input className={classnames(styles.filter_value, styles.filter_value_with_border, { [styles.non_editable]: !editable })} value={value} disabled />
  } else if (type === TAG_FILTER) {
    const [key, val] = value?.split(TAG_SEPARATOR)
    return (
      <>
        <Input className={classnames(styles.filter_key, { [styles.non_editable]: !editable })} value={key} disabled />
        <Input className={classnames(styles.tag_separator, { [styles.non_editable]: !editable })} placeholder='/' disabled />
        <Input className={classnames(styles.filter_value, { [styles.non_editable]: !editable })} value={val} disabled />
      </>
    )
  }
  return null
}

const TooltipWrapper = ({ show, children }) => {
  if (!show) return children
  return (
    <Tooltip title='This filter is included from your delegation filters'>
      {children}
    </Tooltip>
  )
}

const Filter = ({ id, type, value, remove, updating, editable }) => {
  const [isBeingRemoved, setIsBeingRemoved] = useState(false)

  const handleRemove = (id) => {
    setIsBeingRemoved(true)
    remove(id)
  }

  useEffect(() => {
    if (!updating && isBeingRemoved) {
      setIsBeingRemoved(false)
    }
  }, [updating])

  return (
    <TooltipWrapper show={!editable}>
      <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ disabled: true }}>
        <Input.Group compact className={styles.filter_row}>
          <FilterDisplay editable={editable} type={type} value={value} />
          <DeleteButton onClick={() => handleRemove(id)} loading={isBeingRemoved && updating} enabled={editable} />
        </Input.Group>
      </PermissionsGate>
    </TooltipWrapper>
  )
}

export default Filter
