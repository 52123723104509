import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import Filter from './filter'
import AddFilter from './add'
import { TAG_FILTER, TAG_SEPARATOR, NAME_FILTER } from './constants'

import styles from './styles.module.less'

const formatFilter = ({ type, key, value }) => {
  if (type === TAG_FILTER) {
    return [{ type, value: `${key}${TAG_SEPARATOR}${value}` }]
  } else if (type === NAME_FILTER) {
    return [{ type, value }]
  }
  return []
}

const FilterList = ({ filters = [], handleRemove, updating, parentFilters = [] }) => {
  return (
    <>
      {
        parentFilters.map((filter, idx) => <Filter key={idx} type={filter.type} value={filter.value} />)
      }
      {
        filters.map((filter, idx) => <Filter key={idx} id={idx} type={filter.type} value={filter.value} remove={handleRemove} updating={updating} editable />)
      }
    </>
  )
}

const NameAndTagFilters = ({ whitelist, blacklist, handleChange, updating, parentWhitelist, parentBlacklist }) => {
  const [addingWl, setAddingWl] = useState(false)
  const [addingBl, setAddingBl] = useState(false)

  useEffect(() => {
    if (!updating) {
      setAddingWl(false)
      setAddingBl(false)
    }
  }, [updating])

  const handleAddWhitelist = (value) => {
    setAddingWl(true)
    handleChange({ whitelist: [...(whitelist || []), ...formatFilter(value)], blacklist })
  }
  const handleAddBlacklist = (value) => {
    setAddingBl(true)
    handleChange({ blacklist: [...(blacklist || []), ...formatFilter(value)], whitelist })
  }
  const handleRemoveWhitelist = (id) => {
    const wl = whitelist.slice()
    wl.splice(id, 1)
    handleChange({ whitelist: wl, blacklist })
  }
  const handleRemoveBlacklist = (id) => {
    const bl = blacklist.slice()
    bl.splice(id, 1)
    handleChange({ whitelist, blacklist: bl })
  }

  return (
    <Section solid>
      <Row className={styles.filters}>
        <Col xs={24} xl={12}>
          <Section title='Include' titleUpperCase contentWrapperClassName={styles.filter_list}>
            <FilterList filters={whitelist} handleRemove={handleRemoveWhitelist} updating={updating} parentFilters={parentWhitelist} />
            <AddFilter title='Add include filter' addFilter={handleAddWhitelist} updating={addingWl} />
          </Section>
        </Col>
        <Col xs={24} xl={12}>
          <Section title='Exclude' titleUpperCase contentWrapperClassName={styles.filter_list}>
            <FilterList filters={blacklist} handleRemove={handleRemoveBlacklist} updating={updating} parentFilters={parentBlacklist} />
            <AddFilter title='Add exclude filter' addFilter={handleAddBlacklist} updating={addingBl} />
          </Section>
        </Col>
      </Row>
    </Section>
  )
}

export default NameAndTagFilters
