import React from 'react'
import { countBy, flatten } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { getInventoryResources } from 'lib/resources/filters'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { REGIONS } from 'lib/aws-regions'
import Section from 'components/layout/content/section'

import { Region } from 'components/items/progress-item'
import { useAllResourcesQuery, useAlarmsRulesQuery } from 'hooks/api'

const AlarmsRegions = () => {
  const { data: alarms, isLoading: loading } = useAlarmsRulesQuery()
  const { data: resourcesAll, isLoading: loadingResources } = useAllResourcesQuery()
  const resources = resourcesAll && getInventoryResources(resourcesAll)

  const { getValue: regions, setValue: setRegions } = useQueryParams('region', [])

  const loadingGeneral = loadingResources || !resources || loading

  const violatedRegions = flatten(alarms?.map(alarm => {
    return alarm?.violations?.map(item => item?.resource?.region).filter(item => item)
  }))

  const countByRegions = countBy(violatedRegions)

  const items = Object.keys(countByRegions).filter(item => item)?.map(region => {
    const regionData = REGIONS.find(item => item.region === region)

    return {
      title: region,
      description: regionData?.name,
      count: countByRegions[region],
      total: violatedRegions?.length,
      area: regionData?.area,
      id: region
    }
  })

  const handleRegionsFilter = (item) => {
    setRegions(filterHelper(item, regions))
  }

  return (
    <Section title='Regions' loading={loadingGeneral} titleUpperCase>
      {items?.length !== 0
        ? items.sort((a, b) => b.count - a.count).map(region =>
          <Region {...region} key={region.id} selected={regions} onSelect={handleRegionsFilter} />
        )
        : <p>No violations found</p>}
    </Section>
  )
}

export default AlarmsRegions
