import React from 'react'
import classnames from 'classnames'

import { Item } from 'components/items/item'
import { AwsIcon, AwsServiceIcon, ErrorIcon, GlobeAsiaIcon, GlobeAmericaIcon, GlobeEuropeIcon } from 'components/icons'
import SkeletonLine from 'components/skeleton-line'
import { resourceTitle } from 'lib/resources/transformations'
import { findInventoryByType } from 'lib/resources/constants'

import styles from './styles.module.less'

const Icon = ({ size, children }) => {
  if (!children) return null

  return <div className={classnames(styles.icon, { [styles[`size_${size}`]]: !!size })}>{children}</div>
}

const ProgressItem = ({ title, count, total, id, description, icon, status, selected = [], extra, onSelect, solid, size = 'md', gap = 'md', url, className, hoverable, statusBar, loading, onDescriptionClick, ...props }) => {
  const showCount = count >= 0
  const handler = () => onSelect(id)

  const Description = onDescriptionClick
    ? <p className={classnames(styles.description, styles['description-hoverable'])} onClick={(e) => onDescriptionClick(e)}>{description}</p>
    : <p className={styles.description}>{description}</p>

  return (
    <Item
      {...props}
      layout='rows'
      size={size}
      gap={gap}
      separator={!!icon}
      outlined
      solid={solid}
      icon={<Icon size={size}>{icon}</Icon>}
      count={count}
      total={total}
      status={status}
      selected={selected.includes(id)}
      className={classnames(styles.item, className)}
      onClick={onSelect && handler}
      hoverable={hoverable}
      url={url}
      statusBar={statusBar}
    >
      <div className={classnames(styles.content, { [styles[`size_${size}`]]: !!size })}>
        <h5 className={styles.title}>{title}</h5>
        {!loading ? Description : <SkeletonLine flex />}
      </div>
      {showCount && <div className={styles.count}>{count}</div>}
      {extra}
    </Item>
  )
}

const REGION_ICONS = {
  asia: <GlobeAsiaIcon className={styles.dark} />,
  america: <GlobeAmericaIcon className={styles.dark} />,
  europe: <GlobeEuropeIcon className={styles.dark} />
}

const Region = (item) => {
  return <ProgressItem {...item} icon={item.icon || REGION_ICONS[item.area]} />
}

const Delegation = (item) => {
  return <ProgressItem
    {...item}
    description={item.description || 'name not specified'}
    icon={<AwsIcon className={styles.dark} />}
  />
}

const InfoItem = ({ item }) => item ? <span style={{ marginLeft: 6 }}>| {item}</span> : null

const Resource = (item) => {
  return (
    <ProgressItem
      {...item}
      icon={<AwsServiceIcon service={findInventoryByType(item?.type)?.service} size='md' />}
      title={(
        <>
          <span>{item?.region}</span>
          <InfoItem item={item?.delegationName} />
          <InfoItem item={item?.time} />
        </>)}
      description={resourceTitle(item)}
    />
  )
}

const Error = (item) => {
  return <ProgressItem {...item} icon={<ErrorIcon status={item.severity} />} />
}

export { ProgressItem, Region, Delegation, Resource, Error }
