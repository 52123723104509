import React from 'react'
import { Route } from 'react-router-dom'

const accountRoutes = (account, routes) => {
  const basicRoutes = routes.map(route =>
    <Route key={`route.${route.path}`} path={route.path} component={route.component} />
  )

  return basicRoutes.concat(routes.map(route =>
    <Route key={`route.account.${route.path}`} path={`/:accountId${route.path}`} component={route.component}/>
  ))
}

export default accountRoutes
