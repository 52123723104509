import { notification } from 'antd'

export const displayPaymentProblems = ({ onClick } = {}) => {
  return notification.error({
    message: 'Payment Problems',
    description:
      'We have detected some payment problems with your organization. Please review your billing, payment methods and invoices settings!',
    placement: 'bottomLeft',
    duration: 0,
    onClick
  })
}
