import React from 'react'
import { isEmpty } from 'lodash'

import ListItem from 'features/side-list/item'
import { AwsServiceIcon } from 'components/icons'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import Badge from 'components/badge/info'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'

import styles from './styles.module.less'

const LeftDetails = ({ item, serviceGroup }) => {
  const resourceText = (count) => count === 1 ? `${count} resource` : `${count} resources`
  return (
    <>
      <Badge text={isEmpty(item.targets) ? 'all' : resourceText(item?.targets?.length)} />
      <span className={styles.margin_left}>{serviceGroup?.title}</span>
    </>
  )
}

const SearchListItem = ({ item, selected, onSelect }) => {
  const serviceGroup = INVENTORY_SERVICE_GROUPS[item.service]
  return (
    <ListItem
      key={item.id}
      icon={<AwsServiceIcon service={item.service} />}
      title={item.name || item.query || <span className={styles.italic}>latest logs</span>}
      selected={item.id.toString() === selected}
      left={<LeftDetails item={item} serviceGroup={serviceGroup} />}
      right={`${formatDistanceToNowStrict(parseISO(item.updatedAt))} ago`}
      onClick={() => onSelect(item)}
    />
  )
}

export default SearchListItem
