import classnames from 'classnames'
import React from 'react'
import { Tooltip, Button, Row } from 'antd'
import { isEmpty } from 'lodash'

import { useDelegationsQuery, useLogSourceQuery, useRetryLogSourceQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import Section from 'components/layout/content/section'
import { getIcon, getText, getTooltipText } from './helpers'

import styles from './styles.module.less'

const LogGroupStatus = ({ item }) => {
  const { data: delegations } = useDelegationsQuery()
  const delegation = delegations?.find(delegation => delegation.id === item.delegation)

  const { data: logSource, isLoading } = useLogSourceQuery({ delegation, region: item.region, name: item.name })
  const logSourceError = logSource?.error

  const { mutate: retrySubscription, isLoading: retrying } = useRetryLogSourceQuery({ delegation, region: item.region, name: item.name })

  const loading = isLoading || retrying

  const indicatorIcon = getIcon(loading, logSource, logSourceError)
  const indicatorText = getText(loading, logSource, logSourceError)
  const indicatorTooltipText = getTooltipText(loading, logSource, logSourceError)
  const indicatorClassNames = classnames(styles.indicator, {
    [styles.indicator_active]: !loading && !isEmpty(logSource) && !logSource?.failure,
    [styles.indicator_error]: !loading && !isEmpty(logSource) && logSource?.failure,
    [styles.indicator_disabled]: !loading && ((!isEmpty(logSource) && !logSource?.failure && logSource?.disabled) || logSourceError)
  })

  return (
    <Section title='Log group subscription' titleUpperCase>
      <Row className={styles.indicatorContainer}>
        Status:

        <Tooltip title={indicatorTooltipText}>
          <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true, onClick: () => { } }}>
            <Button
              loading={loading}
              shape='circle'
              size='small'
              className={indicatorClassNames}
              onClick={() => retrySubscription()}
            >
              {indicatorIcon}
            </Button>
          </PermissionsGate>
        </Tooltip>

        {indicatorText}
      </Row>
    </Section>
  )
}

export default LogGroupStatus
