import { useQueries } from 'react-query'
import querystring from 'query-string'
import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { useOrganization } from 'hooks/context/organization-context'
import { useAllResourcesQuery } from 'hooks/api'
import { EVENTS, eventsPaths as paths } from './index'

const mapResourcesToEvents = (data, resources) => {
  if (!data) return undefined
  if (!resources) return data

  const mappedEvents = data?.data
    ?.map(event => {
      return {
        ...event,
        resource: resources[event.resource],
        //...(event.type === "error" && { countLastDay: sum(metrics?.datapoints[event.id]?.map(item => item.sum))})
      }
    })
    ?.filter(event => event.resource)

  return { ...data, data: mappedEvents }
}

const memoizedResponse = memoizeOne(mapResourcesToEvents, isEqual)

const getEvents = async (account, payload, queryId) => {
  const query = querystring.stringify(omitEmpty({
    status: payload.status || 'open',
    type: payload.type || 'errors',
    ...payload.filters
  }))

  const data = await apiFetch(paths.filter(account, query))

  return { data: data?.data, id: queryId }
}

export const useEventsBatchQuery = (payloads = [], refreshInterval, start, end) => {
  const { account } = useOrganization()
  const { data: resources } = useAllResourcesQuery()
  //const { data: metrics } = useErrorMetricsQuery({ key: 'errors' }, start, end)

  const queries = useQueries(
    payloads.map(({ id: queryId, payload }) => {
      return {
        queryKey: [EVENTS, payload, payload?.filters, account?.id, queryId],
        queryFn: () => getEvents(account.id, payload, queryId),
        staleTime: refreshInterval ? 5 * 60 * 1000 : Infinity,
        enabled: !!account?.id && !!payload,
        refetchInterval: refreshInterval,
        refetchOnWindowFocus: false
      }
    })
  )

  return queries.map(query => {
    const data = memoizedResponse(query?.data, resources)
    return { ...query, data }
  })
}
