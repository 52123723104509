import React from 'react'

import { Tooltip, Button } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { ButtonHTMLType } from 'antd/lib/button/button'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { SpinnerIcon } from 'components/icons'

import classnames from 'classnames'
import styles from './styles.module.less'

const renderLoading = ({ size = 'sm', className }: { size?: SizeProp, className?: string }) => {
  return (
    <button className={classnames(styles.button, styles.loading, className)}>
      <SpinnerIcon size={size} spin />
    </button>
  )
}

const IconButton = ({
  loading,
  onClick,
  disabled,
  icon,
  className,
  title,
  titlePlacement = 'top',
  hidden,
  size,
  htmlType = 'button'
}: {
  icon: JSX.Element,
  loading?: boolean,
  onClick: React.MouseEventHandler<HTMLElement>,
  disabled?: boolean,
  className?: string,
  title?: string,
  titlePlacement?: TooltipPlacement,
  hidden?: boolean,
  size?: SizeProp,
  iconColor?: string,
  htmlType?: ButtonHTMLType
}) => {
  if (hidden) return null
  if (loading) return renderLoading({ size, className })

  return (
    <Tooltip
      title={title || ''}
      placement={titlePlacement}
      mouseEnterDelay={0}
      trigger='hover'
      overlayClassName={styles.tooltip}>
      <Button
        htmlType={htmlType}
        disabled={disabled}
        className={classnames(styles.button, className)}
        onClick={onClick}
      >
        {icon}
      </Button>
    </Tooltip>
  )
}

export default IconButton
