import React from 'react'
import { differenceInMinutes } from 'date-fns/esm'
import { ParentSize } from '@visx/responsive'
import { Bar, Circle, Line } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleLinear } from '@visx/scale'
import { AnimatedAxis } from '@visx/react-spring'
import { Text } from '@visx/text'

import Section from 'components/layout/content/section'
import colors from 'lib/colors'
import useQueryParams from 'lib/hooks/use-query-params'
import { BUCKETS } from '../list/filters'
import { useErrorsQuery } from 'hooks/api'

import styles from './styles.module.less'

const LastOccurredErrors = () => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('time-bucket')

  const { isLoading, data: errors } = useErrorsQuery()

  const formatted = BUCKETS.map(group => ({
    ...group,
    count: errors?.filter(error => {
      const minutesFromLast = differenceInMinutes(new Date(), error.lastOccurredAt)
      if (group.value === 30 * 24 * 60) return minutesFromLast > group?.from
      return minutesFromLast < group?.value && minutesFromLast > group?.from
    })?.length || 0
  }))

  const colorScale = scaleLinear({
    range: [colors('chart', 'redLight'), colors('chart', 'redDark')],
    domain: [0, Math.max(...formatted?.map(item => item?.count))]
  })

  const handleSelectGroup = (value) => {
    setSelected(value.toString() === selected ? undefined : value)
  }

  const totalHeight = 62
  const barHeight = 12
  const padding = 20
  const barsPadding = 3
  const axisPlacement = 44
  const axisColor = colors('chart', 'grid')
  const selectedLine = colors('chart', 'primary')
  const tickRadius = 3

  const selectedBucket = BUCKETS.find(item => item.value?.toString() === selected)

  const firstSelected = selected === BUCKETS[0]?.value?.toString()
  const lastSelected = selected === BUCKETS[5]?.value?.toString()

  return (
    <Section title='Last occurred' loading={isLoading || !errors} titleUpperCase loadingHeight={1} cancelMargin>
      <div className={styles.overflow}>
        <ParentSize parentSizeStyles={{ minWidth: 550 }}>
          {({ width }) => {
            const groupScale = scaleLinear({
              domain: [0, 100],
              range: [0, width - padding]
            })

            return (
              <svg width={width} height={totalHeight}>
                <Group left={8}>
                  {formatted?.map(group => {
                    const width = Math.abs(groupScale(group.tick) - groupScale(group.start) - barsPadding * 2)

                    return (
                      <Group key={group.value} onClick={() => handleSelectGroup(group.value)} className={styles.bucket_wrapper}>
                        <Text
                          x={groupScale(group.tick) - 4}
                          y={2}
                          fill={colors('chart', selected === group.value.toString() ? 'textDark' : 'text')}
                          verticalAnchor='start'
                          textAnchor='end'
                          className={styles.bucket_text}
                        >
                          {group.count}
                        </Text>
                        <Bar
                          height={barHeight}
                          width={width}
                          fill={colorScale(group.count)}
                          x={groupScale(group.start) + barsPadding}
                          y={25}
                        />
                      </Group>)
                  }
                  )}
                  <Group>
                    <Circle cx={-4.5} cy={axisPlacement} r={tickRadius} stroke={axisColor} fill={axisColor} />
                    <AnimatedAxis
                      scale={groupScale}
                      top={axisPlacement}
                      animationTrajectory='min'
                      tickValues={BUCKETS.map(item => item.tick).slice(0, -1)}
                      hideTicks
                      tickComponent={props => {
                        const text = BUCKETS.find(item => item.tick.toString() === props.formattedValue)?.name
                        return <Group>
                          <Circle cx={0} cy={-8} r={tickRadius - 1} stroke={axisColor} fill={axisColor} />
                          <Text
                            textAnchor='middle'
                            verticalAnchor='start'
                            fill={colors('chart', 'text')}
                            fontSize={11}>
                            {text}
                          </Text>
                        </Group>
                      }}
                      tickTransform='translate(0 -10)'
                      stroke={axisColor}
                      strokeWidth={2}
                      rangePadding={padding / 2}
                    />
                    <Circle cx={width - padding / 2 - 3} cy={axisPlacement} r={tickRadius} stroke={axisColor} fill={axisColor} />

                  </Group>

                  {selected && (
                    <Group>
                      <Circle
                        cx={firstSelected ? -4.5 : groupScale(selectedBucket.start)}
                        cy={axisPlacement} fill='#fff' stroke={selectedLine} r={tickRadius}
                      />
                      <Line
                        strokeWidth={2}
                        from={{ x: firstSelected ? -2 : (groupScale(selectedBucket.start) + barsPadding), y: axisPlacement }}
                        to={{ x: lastSelected ? groupScale(100) + 7.5 : (groupScale(selectedBucket.tick) - barsPadding), y: axisPlacement }}
                        stroke={selectedLine}
                      />
                      <Circle
                        cx={lastSelected ? groupScale(100) + 7.5 : groupScale(selectedBucket.tick)}
                        cy={axisPlacement}
                        fill='#fff'
                        stroke={selectedLine}
                        r={tickRadius}
                      />
                    </Group>)
                  }

                </Group>
              </svg>
            )
          }}
        </ParentSize>
      </div>
    </Section>
  )
}

export default LastOccurredErrors
