
const initServiceMetrics = () => ({
  max_duration: null,
  min_duration: null,
  total_duration: null
})

const initMetrics = () => ({
  min_start_time: null,
  max_end_time: null,
  dynamodb: initServiceMetrics(),
  lambda: initServiceMetrics()
})

const getBasicMetrics = (segment) => ({
  duration: segment.end_time - segment.start_time
})

const setDurationMetrics = (basicMetrics, serviceMetrics) => {
  if (basicMetrics.duration > serviceMetrics.max_duration || !serviceMetrics.max_duration) {
    serviceMetrics.max_duration = basicMetrics.duration
  }

  if (basicMetrics.duration < serviceMetrics.min_duration || !serviceMetrics.min_duration) {
    serviceMetrics.min_duration = basicMetrics.duration
  }

  if (!serviceMetrics.total_duration) {
    serviceMetrics.total_duration = basicMetrics.duration
  } else {
    serviceMetrics.total_duration += basicMetrics.duration
  }
}

const setDynamodbMetrics = (basicMetrics, metrics) => {
  const serviceMetrics = metrics.dynamodb
  setDurationMetrics(basicMetrics, serviceMetrics)
}

const setLambdaMetrics = (basicMetrics, metrics) => {
  const serviceMetrics = metrics.lambda
  setDurationMetrics(basicMetrics, serviceMetrics)
}

const setMetrics = (segment, metrics) => {
  const basicMetrics = getBasicMetrics(segment)

  if (segment.start_time < metrics.min_start_time || !metrics.min_start_time) {
    metrics.min_start_time = segment.start_time
  }

  if (segment.end_time > metrics.max_end_time || !metrics.max_end_time) {
    metrics.max_end_time = segment.end_time
  }

  switch (segment.name) {
    case 'DynamoDB':
      setDynamodbMetrics(basicMetrics, metrics)
      break
    case 'Lambda':
      setLambdaMetrics(basicMetrics, metrics)
      break
  }
}

export { setMetrics, initMetrics }
