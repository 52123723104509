import { INVENTORY_SERVICES } from 'lib/resources/constants'

export const getInventoryResources = (resources = []) => {
  const inventoryTypes = Object.values(INVENTORY_SERVICES).filter(item => !item.excludeFromList).map(item => item.type)
  return Object.values(resources)?.filter(item => inventoryTypes.includes(item.type)) || []
}

export const getResourcesByType = (resources = [], type) => {
  return Object.values(resources)?.filter(item => item.type === type) || []
}

export const getResourcesByNotType = (resources = [], type) => {
  return Object.values(resources)?.filter(item => item.type !== type) || []
}
