const INFO_PLACEHOLDER_SEGMENT = 'Placeholder segment'

const getInfo = (node) => {
  const info = []

  if (node.origin === 'Dashbird') {
    info.push({
      name: INFO_PLACEHOLDER_SEGMENT,
      message: 'this segment was created by Dashbird because it contains segments that have a parent id which is not included with the traces'
    })
  }

  return info
}

export { getInfo }
