import React, { useMemo, useCallback } from 'react'
import { Route, useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { sub } from 'date-fns'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { Button, Tooltip } from 'antd'

import Drawer from 'components/layout/drawer'
import WidgetDetails from 'features/widgets/details'
import { IconWrapper } from 'components/icons'
import Content from 'components/layout/content'
import GlobalDatePicker from 'components/date-picker/global'
import { RELATIVE_VALUES } from 'components/date-picker/global/relative'
import EditableTitle from 'features/editable-title'
import InvocationDetails from 'features/invocations/details'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import { useAccountRoutes } from 'containers/routes'
import DashboardContainer from 'hooks/context/dashboard-context'
import { useAllResourcesQuery, useDashboardQuery, useWidgetsQuery, useResourceGroupQuery } from 'hooks/api'
import { useMutations, useMetricsFetcher, useInvocationsFetcher, useEventsFetcher, useLogsFetcher, useGroupResources } from '../hooks'
import { useGlobalDatePicker } from 'hooks'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import { ALLOWED_WIDTHS, DASHBOARD_KIND, SPARKLINE, WIDGET_KIND, WIDGET_SIZE_LIMITS } from 'lib/dashboards-constants'
import { isStatic } from 'lib/dashboards-helpers'
import { Actions, TitleActions } from './actions'
import DashboardGrid from './grid'

import styles from './styles.module.less'

const Title = ({ onSubmit, item, resourceGroup }) => (
  item
    ? <EditableTitle
      value={item?.name}
      onSubmit={onSubmit}
      displayContent={`${item?.name}${resourceGroup ? `: ${resourceGroup.title}` : ''}`}
      readOnly={!!resourceGroup}
    />
    : null
)

const InvocationDetailsDrawer = ({ resources, onClose }) => {
  const { resourceId, invocationHash } = useParams()

  return (
    <Drawer
      open
      onClose={onClose}
      mask={false}
      closable={false}
      size='large'
    >
      <InvocationDetails
        type={INVENTORY_SERVICES.Lambda.id}
        resource={resources[resourceId]}
        onClose={onClose}
        hash={invocationHash}
      />
    </Drawer>
  )
}

const Dashboard = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const match = useRouteMatch()
  const { groupId } = useParams()

  const { data: dashboard, isLoading: loadingDashboard } = useDashboardQuery()
  const { data: widgets, isLoading: loadingWidgets } = useWidgetsQuery()
  const { data: resources, isLoading: loadingResources } = useAllResourcesQuery()
  const { data: resourceGroup } = useResourceGroupQuery(groupId)

  const handlers = useMutations({ dashboard, widgets })

  // initial values are null because we want to initialize datepicker only when dashboard is loaded
  const {
    start,
    end,
    range,
    relativeSpan,
    refreshInterval,
    handleSelectRange,
    handleIntervalChange
  } = useGlobalDatePicker({ relativeSpan: dashboard?.defaultRelativeTimespan, disabled: !dashboard, refresh: dashboard?.id })

  const fetchEnabled = !!dashboard && !!start && !!end

  const metrics = useMetricsFetcher(fetchEnabled ? { widgets, resources, resourceGroup, start, end, relativeSpan, refreshInterval } : {})
  const logs = useLogsFetcher(fetchEnabled ? { widgets, resources, start, end, relativeSpan, refreshInterval } : {})
  const invocations = useInvocationsFetcher(fetchEnabled ? { widgets, start, end, refreshInterval } : {})
  const events = useEventsFetcher(fetchEnabled ? { widgets, start, end, refreshInterval } : {})
  const groupResources = useGroupResources({ resources, resourceGroup })

  const period = useMemo(() => metrics ? Object.values(metrics)[0]?.period : 3600, [metrics])
  const loading = useMemo(() => loadingDashboard || loadingWidgets || loadingResources, [loadingDashboard, loadingWidgets, loadingResources])

  const handleWidgetDragStop = useCallback((layout, oldItem, newItem) => {
    const hasPositionChanged = oldItem.x !== newItem.x || oldItem.y !== newItem.y

    if (hasPositionChanged) {
      handlers.update.layout(layout)
    }
  }, [handlers.update.layout])

  const handleWidgetResize = useCallback((layout, oldItem, newItem, placeholder) => {
    const hasSizeChanged = oldItem.w !== newItem.w || oldItem.h !== newItem.h

    if (hasSizeChanged) {
      const widget = widgets.find(widget => widget.id === newItem.i)
      if (!widget) return

      const { w, h } = newItem
      const { upper, lower } = WIDGET_SIZE_LIMITS[widget.kind]
      if (!upper || !lower) return

      if (!ALLOWED_WIDTHS.includes(w)) {
        const increasing = w > oldItem.w && w !== upper.w
        const decreasing = w < oldItem.w && w !== lower.w

        if (increasing) {
          // Add WIDTH upper bound as max value
          let closestValue = upper.w
          const higherValues = ALLOWED_WIDTHS.filter(item => item > w)
          higherValues.forEach(item => {
            if (item < closestValue) closestValue = item
          })

          newItem.w = closestValue
          placeholder.w = closestValue
        }

        if (decreasing) {
          // Add WIDTH lower bound as min value
          let closestValue = lower.w
          const lowerValues = ALLOWED_WIDTHS.filter(item => item < w)
          lowerValues.forEach(item => {
            if (item > closestValue) closestValue = item
          })

          newItem.w = closestValue
          placeholder.w = closestValue
        }
      }

      // Check HEIGHT upper bound
      if (h >= upper.h) {
        newItem.h = upper.h
        placeholder.h = upper.h
      }

      // Check HEIGHT lower bound
      if (h <= lower.h) {
        newItem.h = lower.h
        placeholder.h = lower.h
      }

      // special constraint - does not let metrics widget be sparkline and wider than 4 columns
      if (widget.kind === WIDGET_KIND.METRICS) {
        if (h === SPARKLINE.HEIGHT && w > SPARKLINE.WIDTH) {
          newItem.h = 2
          placeholder.h = 2
        }
      }
    }
  }, [widgets])

  const handleWidgetResizeStop = useCallback((layout, oldItem, newItem) => {
    const hasSizeChanged = oldItem.w !== newItem.w || oldItem.h !== newItem.h

    if (hasSizeChanged) {
      handlers.update.layout(layout)
    }
  }, [handlers.update.layout])

  const handleWidgetDetailsClose = () => {
    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: dashboard.id }) })
  }

  const handleInvocationsDetailsClose = () => {
    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: dashboard.id }) })
  }

  const handleWidgetCreate = (dashboard, kind) => {
    history.push({ pathname: routes.dashboards.widget.url({ dashboardId: dashboard.id, widgetId: kind }) })
  }

  const handleManualRefetch = () => {
    metrics.refetch()
    invocations.refetch()
    events.refetch()
  }

  return (
    <Content
      loading={loading}
      item={dashboard}
      breadcrumbs={['Dashboards']}
      backRoute={routes.dashboards.url()}
      title={<Title onSubmit={handlers.update.title} item={dashboard} resourceGroup={resourceGroup} />}
      icon={<IconWrapper icon={faLayerGroup} color='primary' solid />}
      fixed
      bordered
      fixedTitle={dashboard?.name}
      className={styles.wrapper}
      actions={(
        fetchEnabled && <>
          <GlobalDatePicker
            max={new Date()}
            min={sub(new Date(), { months: 1 })}
            start={start}
            end={end}
            refreshInterval={refreshInterval}
            relativeSpan={relativeSpan}
            period={period}
            fetching={metrics.fetching || invocations.fetching}
            onChange={handleSelectRange}
            handleIntervalChange={handleIntervalChange}
            handleManualRefetch={handleManualRefetch}
          >
            {!isStatic(dashboard) && (
              <PermissionsGate scopes={[SCOPES.canEdit]}>
                <div className={styles.datepicker_extra}>
                  <span>Add {RELATIVE_VALUES[relativeSpan]} as default time span?</span>
                  <Tooltip
                    title={dashboard?.defaultRelativeTimespan === relativeSpan ? `Default timespan is already ${RELATIVE_VALUES[relativeSpan]}` : ''}
                  >
                    <Button
                      size='small'
                      onClick={() => handlers.update.timespan(relativeSpan)}
                      className={styles.btn}
                      disabled={dashboard?.defaultRelativeTimespan === relativeSpan}>
                      Save
                    </Button>
                  </Tooltip>
                </div>
              </PermissionsGate>)}
          </GlobalDatePicker>
          <Actions
            item={dashboard}
            onDelete={handlers.remove}
            onDuplicate={handlers.duplicate}
            loading={handlers.duplicating || handlers.deleting}
          />
        </>
      )}
      titleRowActions={
        <TitleActions
          item={dashboard}
          loading={loading}
          group={resourceGroup}
          onCreateWidget={handleWidgetCreate}
        />
      }
    >
      {dashboard?.kind !== DASHBOARD_KIND.RESOURCE_GROUP &&
        <Route path={`${match.path}/widgets/:widgetId`}>
          <Drawer
            open
            size='large'
            mask={false}
            closable={false}
          >
            <WidgetDetails
              loading={loading}
              dashboard={dashboard}
              widgets={widgets}
              resources={resources}
              resourceGroup={resourceGroup}
              handlers={handlers}
              onClose={handleWidgetDetailsClose}
            />
          </Drawer>
        </Route>}
      {
        !loadingResources && (
          <Route path={`${match.path}/inventory/:resourceId/requests/:invocationHash`} >
            <InvocationDetailsDrawer
              resources={resources}
              onClose={handleInvocationsDetailsClose}
            />
          </Route>
        )
      }
      <DashboardContainer
        handlers={handlers}
        metrics={metrics}
        invocations={invocations}
        events={events}
        logs={logs}
        groupResources={groupResources}
        options={{ start, end, range }}
      >
        <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ viewOnly: true }}>

          <DashboardGrid
            widgets={widgets}
            kind={dashboard?.kind}
            onDragStop={handleWidgetDragStop}
            onResize={handleWidgetResize}
            onResizeStop={handleWidgetResizeStop}
          />
        </PermissionsGate>
      </DashboardContainer>
    </Content >
  )
}

export default Dashboard
