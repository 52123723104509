import { combineReducers } from 'redux'
import { last, get } from 'lodash'
import { GET_INVOICES } from 'actions/billing.actions'

function list (state = [], action) {
  switch (action.type) {
    case GET_INVOICES:
      const lastItem = last(state)
      if (!action.next || action.next !== get(lastItem, 'id')) return action.payload
      return [ ...state, ...action.payload ]
  }
  return state
}

export default combineReducers({
  list
})
