import React from 'react'
import { orderBy } from 'lodash'

import Section from 'components/layout/content/section'
import { Item } from 'components/items'
import { AwsServiceIcon } from 'components/icons'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'

import styles from './styles.module.less'

const ServicesList = ({ rules, loading, selected = [], onSelect }) => {
  const formattedByService = Object.values(INVENTORY_SERVICE_GROUPS).map(group => {
    const count = rules.filter(rule => rule.id.includes(group.service) && rule.count > 0)?.length

    return {
      icon: <AwsServiceIcon service={group?.service} size='md' />,
      count,
      total: rules?.filter(item => item?.count > 0)?.length,
      title: group.title,
      id: group.service
    }
  })

  const servicesWithViolations = orderBy(formattedByService.filter(item => item.count > 0), 'count', 'desc')

  return (
    <Section title='Services' loading={loading} className={styles.wrapper} titleUpperCase>
      {servicesWithViolations.length !== 0
        ? servicesWithViolations.map(item =>
          <Item.Filter
            key={item.id}
            {...item}
            selected={selected}
            onSelect={onSelect} />)
        : <p>No violations found</p>}
    </Section>
  )
}

export default ServicesList
