import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { isEmpty } from 'lodash'

import { SEARCH_METRICS, searchPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const fetchMetrics = async (account, payload) => apiFetch(paths.metrics(account), {
  method: 'POST',
  body: JSON.stringify(payload)
})

const serializePayload = (payload) => {
  if (!payload) return {}
  return {
    query: payload.query,
    service: payload.service,
    ...(payload.targets && { targets: payload.targets }),
    ...(payload.start && { start: payload.start }),
    ...(payload.end && { end: payload.end }),
    ...(payload.indices && { indices: payload.indices })
  }
}

export const useSearchMetricsQuery = (data) => {
  const { account } = useOrganization()
  const payload = serializePayload(data)

  return useQuery(
    [SEARCH_METRICS, payload, account?.id],
    () => fetchMetrics(account?.id, payload),
    {
      staleTime: 60 * 1000,
      enabled: !!account?.id && !isEmpty(payload),
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
