import React from 'react'
import { groupBy } from 'lodash'
import { formatDistanceToNowStrict } from 'date-fns'

import Section from 'components/layout/content/section'
import { Error } from 'components/items/progress-item'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useErrorsQuery } from 'hooks/api'

const GroupedByHashList = () => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('groupHash', [])

  const { data: errors, isLoading } = useErrorsQuery()

  const groupByHash = groupBy(errors, 'group')

  const groupedItems = Object.keys(groupByHash).map(hash => {
    const items = groupByHash[hash]?.sort((a, b) => b.lastOccurredAt - a.lastOccurredAt)
    if (items?.length <= 1) return null

    return ({
      title: `${formatDistanceToNowStrict(items[0].lastOccurredAt)} ago`,
      description: items[0]?.name,
      severity: items[0]?.severity,
      id: hash,
      count: items?.length
    })
  })?.filter(item => item)

  return (
    <Section title='Similar errors' titleUpperCase loading={isLoading || !errors}>
      {groupedItems?.length !== 0
        ? groupedItems?.sort((a, b) => b.count - a.count)?.map(group => (
          <Error
            {...group}
            key={group?.id}
            onSelect={(item) => setSelected(filterHelper(item, selected))}
            selected={selected}
          />))
        : <p>No grouped errors where found</p>
      }
    </Section>
  )
}

export default GroupedByHashList
