import React from 'react'
import { Alert, Button } from 'antd'

import styles from './styles.module.less'

const MigrationAlert = ({ onMigrate, loading }) => (
  <>
    <Alert
      message='The free tier is temporarily unavailable with the new billing system. If you would like to keep using the free plan, please do not migrate over.'
      type='info'
      className={styles.additional}
      showIcon
    />
    <Alert
      message='Migrate to new billing'
      description={
        <div className={styles.description}>
          <p>Dashbird has moved over to usage based pricing. To continue using Dashbird, migrate over to new billing. The migration will take up to 24 hours, however you can still use your account. This will also enable new features and include a 14 day trial.</p>
          <Button type='primary' className={styles.migration_button} loading={loading} onClick={onMigrate}>Migrate</Button>
        </div>
      }
      type='warning'
      showIcon />
  </>
)

export default MigrationAlert
