import React from 'react'
import { countBy } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { getInventoryResources } from 'lib/resources/filters'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { Region } from 'components/items/progress-item'
import { REGIONS } from 'lib/aws-regions'
import Section from 'components/layout/content/section'
import { useAllResourcesQuery } from 'hooks/api'

const InventoryRegions = () => {
  const { data: resourcesAll, isLoading } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)

  const { getValue: regions, setValue: setRegions } = useQueryParams('region', [])

  const loadingResources = isLoading || !resources

  const countByRegions = countBy(resources, 'region')

  const items = Object.keys(countByRegions).map(region => {
    const regionData = REGIONS.find(item => item.region === region)

    return {
      title: region,
      description: regionData?.name,
      area: regionData?.area,
      count: countByRegions[region],
      total: resources?.length,
      id: region
    }
  })

  const handleRegionsFilter = (item) => {
    setRegions(filterHelper(item, regions))
  }

  return (
    <Section title='Regions' loading={loadingResources} titleUpperCase>
      {items.sort((a, b) => b.count - a.count).map(region =>
        <Region {...region} key={region.id} selected={regions} onSelect={handleRegionsFilter} />
      )}
    </Section>
  )
}

export default InventoryRegions
