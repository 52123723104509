import React from 'react'

const DashbirdIconSvg = ({ width, height, x, y }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 24" x={x} y={y} fill="#4a46c6">
      <path d="M0 0v9.4l4.472 4.549v9.044l9.232-9.39V7.635h3.018l-3.018-3.07L8.95 9.401 0 0z"></path>
    </svg>
  )
}

export default DashbirdIconSvg
