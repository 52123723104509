import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Content from 'components/layout/content'
import Form from './form'

import { useAccountRoutes } from 'containers/routes'
import { useResourceGroupQuery, useCreateResourceGroupQuery, useUpdateResourceGroupQuery } from 'hooks/api'

export const combineFilters = (values) => {
  const tagExpressions = values?.tags?.length > 0 && values.tags.filter(tag => tag).map(({ key, value }) => ({ type: 'tag', key, value }))
  const serviceExpressions = values?.services?.length > 0 && values.services.map(service => ({ type: 'service', value: service }))
  return (tagExpressions || serviceExpressions) && {
    operator: 'and',
    expressions: [
      ...(tagExpressions ? [{ expressions: tagExpressions, operator: values.tagOperator }] : []),
      ...(serviceExpressions ? [{ expressions: serviceExpressions, operator: values.serviceOperator }] : [])
    ]
  }
}

const ModifyGroup = ({ view = 'resourceGroups' }) => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()

  const { groupId } = useParams()

  const { data: group, isLoading } = useResourceGroupQuery(groupId)

  const { mutate: createResourceGroup } = useCreateResourceGroupQuery()
  const { mutate: updateResourceGroup } = useUpdateResourceGroupQuery()

  const onCancel = () => {
    history.push({ pathname: routes[view].url(), search })
  }

  const onSubmit = async (values) => {
    const payload = {
      title: values.title,
      description: values.description ? values.description : '',
      resources: values.selectedResources || [],
      filters: combineFilters(values) || {}
    }

    if (groupId && group && group.id) {
      updateResourceGroup(payload)
    } else {
      createResourceGroup(payload)
    }
    history.push({ pathname: routes[view].url(), search })
  }

  return (
    <Content
      title={groupId ? 'Edit resource group' : 'Create new resource group'}
      breadcrumbs={['Resource groups']}
      item='Show content'
      loading={isLoading}
    >
      <Form
        key={groupId}
        onSubmit={onSubmit}
        onCancel={onCancel}
        group={groupId && group}
        loading={isLoading}
      />
    </Content>
  )
}

export default ModifyGroup
