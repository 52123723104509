import React from 'react'
import Numeral from 'numeral'

import { Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KINESIS_DS_RATIO } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-datastream'

const DifferenceChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    incomingRecords: data.KINESIS_DATA_STREAM_INCOMING_RECORDS,
    incomingBytes: data.KINESIS_DATA_STREAM_INCOMING_BYTES
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    const incomingRatio = metric.incomingBytes / metric.incomingRecords

    return {
      ...metric,
      [KINESIS_DS_RATIO]: incomingRatio
    }
  })

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[00] b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0] b'
      span={span}
      tabKey={KINESIS_DS_RATIO}
      formatTab={value => `${Numeral(value).format('0.[00] b')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={KINESIS_DS_RATIO} stroke={colors('chart', 'primaryDark')} strokeWidth={1} name='Incoming bytes per record' dot={false} />
    </ChartContainer>
  )
}

export default DifferenceChart
