import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_INCOMING_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_INCOMING_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_INCOMING_THROTTLES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.throttles',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.elasticsearch.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.redshift.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.s3.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.splunk.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.elasticsearch.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.redshift.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.s3.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.splunk.records',
    stats: [STATISTICS.SUM]
  }
}]

export default metrics
