import React from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import ServiceNode from './service'
import styles from './styles.module.less'

export const Label = ({ label, shape, className }: { label: string, shape: string, className?: string }) => {
  return (
    <div className={classnames(styles.label, shape && styles[shape], className)}>
      {label}
    </div>
  )
}

export const Shape = ({ node, selected }: { node: any, selected?: boolean }) => {
  switch (node.shape) {
    case 'circle':
      return (
        <circle
          cx={node.x}
          cy={node.y}
          r={node.width / 2}
          className={node.className}
        />
      )
    case 'cascade':
      return (
        <g>
          <rect
            width={node.width}
            height={node.height}
            x={node.x - node.width / 2 - 2 * 3}
            y={node.y - node.height / 2 - 2 * 3}
            rx={2}
            className={classnames(styles.node, node.className)}

          />
          <rect
            width={node.width}
            height={node.height}
            x={node.x - node.width / 2 - 3}
            y={node.y - node.height / 2 - 3}
            rx={2}
            className={classnames(styles.node, node.className)}
            style={{ fill: '#fff' }}
          />
          <rect
            width={node.width}
            height={node.height}
            x={node.x - node.width / 2}
            y={node.y - node.height / 2}
            rx={2}
            className={classnames(styles.node, node.className)}
            style={{ fill: '#fff' }}
          />
        </g>
      )
    case 'service':
      const hasError = !isEmpty(node?.node?.errors)
      return (
        <circle
          cx={node.x}
          cy={node.y}
          r={node.width / 2}
          className={classnames(styles.service, { [styles.error]: hasError, [styles.selected]: !!selected })}
        />)
    default:
      return (
        <rect
          width={node.width}
          height={node.height}
          x={node.x - node.width / 2}
          y={node.y - node.height / 2}
          rx={2}
          className={classnames(styles.node, node.className)}
        />
      )
  }
}

const Node = ({ node, handleNodeClick, selected }) => {
  if (!node || !node.x || !node.y) return null

  switch (node.type) {
    case 'service-map':
      return <ServiceNode node={node} handleNodeClick={handleNodeClick} selected={selected} />
    default:
      return (
        <g key={node.id}>
          <Shape node={node} />
          <foreignObject
            x={node.x - node.width / 2}
            y={node.y - (node.shape === 'circle' ? node.width : node.height) / 2}
            width={node.width}
            height={node?.shape === 'circle' ? node.width : node.height + 100}
          >
            <Label label={node.label} shape={node.shape} className={node.className} />
          </foreignObject>
        </g>
      )
  }
}

export default Node
