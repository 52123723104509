import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { dashboardsPaths as paths, DASHBOARDS_LIST } from './index'
import { useAccountRoutes } from 'containers/routes'

const duplicateDashboard = async (account, dashboard) => {
  const data = apiFetch(paths.duplicate(account, dashboard), { method: 'POST' })

  return data
}

export const useDuplicateDashboardQuery = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()

  return useMutation(
    (dashboardId) => duplicateDashboard(account.id, dashboardId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...DASHBOARDS_LIST, account.id])
        history.push(routes.dashboards.dashboard.url({ dashboardId: data?.id }))
      }
    }
  )
}
