import React from 'react'
import Numeral from 'numeral'

import { Cell, Line, Bar, ReferenceLine } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SQS_MESSAGES } from 'containers/inventory/details/resource/metrics-tabs/services/sqs'

const MessagesChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    messages: data.SQS_MESSAGES,
    messagesIn: data.SQS_MESSAGES_IN,
    messagesOut: data.SQS_MESSAGES_OUT
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    const difference = metric.messagesIn - metric.messagesOut
    return {
      ...metric,
      difference,
      increasing: difference > 0,
      [SQS_MESSAGES]: metric.messages
    }
  })

  const tooltipFormatter = (value, name, props) => {
    if (props.dataKey === 'difference') {
      return props.payload.increasing ? [`+${value}`, undefined] : [`${value}`, undefined]
    } else {
      return [Numeral(value).format('0.[000]a'), undefined]
    }
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0] a'
      span={span}
      tabKey={SQS_MESSAGES}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
    >
      <ReferenceLine y={0} stroke={colors('chart', 'grid')} />
      <Bar dataKey='difference' stackId='a' fill='#ababab' name='Difference' barSize={4}>
        {
          formatted.map((entry, index) => (
            <Cell key={index} fill={entry.increasing ? colors('chart', 'primaryDark') : colors('chart', 'red')} />
          ))
        }
      </Bar>
      <Line type='monotone' dataKey={SQS_MESSAGES} stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Messages' dot={false} />
    </ChartContainer>
  )
}

export default MessagesChart
