import React from 'react'
import Numeral from 'numeral'

import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SNS_PAYLOAD_SIZE } from 'containers/inventory/details/resource/metrics-tabs/services/sns'

const DelayChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    size: data.SNS_PAYLOAD_SIZE
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [SNS_PAYLOAD_SIZE]: metric.size_avg,
      diff: [metric.size_min, metric.size_max]
    }
  })

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') {
      return [`${Numeral(value[0]).format('0.[00] b')} - ${Numeral(value[1]).format('0.[00] b')}`, undefined]
    }
    return [Numeral(value).format('0.[00] b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0 b'
      span={span}
      tabKey={SNS_PAYLOAD_SIZE}
      formatTab={value => `${Numeral(value).format('0.[00] b')}`}
      formatTooltip={valueFormatter}
    >
      <Line type='monotone' dataKey={SNS_PAYLOAD_SIZE} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />
      <Area type='monotone' dataKey='diff' dot={false} fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} name='Min - max' />
    </ChartContainer>
  )
}

export default DelayChart
