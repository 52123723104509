import numeral from 'numeral'

import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip'
import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen'
import { faPenNib } from '@fortawesome/pro-light-svg-icons/faPenNib'
import { faPlug } from '@fortawesome/pro-light-svg-icons/faPlug'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faWaveSquare } from '@fortawesome/pro-light-svg-icons/faWaveSquare'

import { getValue, getSparklineByName, getSum } from '../helpers'
import { formatDuration } from 'components/charts/utils'
import { RDS_CPU, RDS_NETWORK_TRAFFIC, RDS_READ, RDS_WRITE, RDS_CONNECTIONS, RDS_REPLICA_LAG } from './rds-instance'

const metrics = [{
  key: RDS_CPU,
  icon: faMicrochip,
  title: 'CPU',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_CPU_UTILIZATION')).format('0.[00]')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'RDS_CPU_UTILIZATION'),
  selectable: true
}, {
  key: RDS_NETWORK_TRAFFIC,
  icon: faWaveSquare,
  title: 'Network traffic',
  filter: (collection) => {
    const sum = getSum(collection, 'RDS_NETWORK_INCOMING')
    return `${numeral(sum).format('0.0b')}${sum < 1 ? 'B' : ''}`
  },
  subvalue: (collection) => getSparklineByName(collection, 'RDS_NETWORK_INCOMING'),
  selectable: true
}, {
  key: RDS_CONNECTIONS,
  icon: faPlug,
  title: 'Connections',
  filter: (collection) => numeral(getSum(collection, 'RDS_DATABASE_CONNECTIONS')).format('0.[00]a'), // average
  subvalue: (collection) => getSparklineByName(collection, 'RDS_DATABASE_CONNECTIONS'),
  selectable: true
}, {
  key: RDS_READ,
  icon: faBookOpen,
  title: 'Reads',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_READ_OPERATIONS')).format('0.[00]a')}`, // avg
  subvalue: (collection) => getSparklineByName(collection, 'RDS_READ_OPERATIONS'),
  selectable: true
}, {
  key: RDS_WRITE,
  icon: faPenNib,
  title: 'Writes',
  filter: (collection) => `${numeral(getValue(collection, 'RDS_WRITE_OPERATIONS')).format('0.[00]a')}`, // avg
  subvalue: (collection) => getSparklineByName(collection, 'RDS_WRITE_OPERATIONS'),
  selectable: true
}, {
  key: RDS_REPLICA_LAG,
  icon: faCopy,
  title: 'Replica lag',
  filter: (collection) => formatDuration(getValue(collection, 'RDS_REPLICA_LAG')), // last value
  subvalue: (collection) => getSparklineByName(collection, 'RDS_REPLICA_LAG'),
  selectable: true
}]

export default metrics
