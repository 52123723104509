import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Card } from 'antd'
import MigrationAlert from 'components/billing/migration-alert'

import { API } from 'actions/api'
import { GET_CUSTOMER, POST_CUSTOMER } from 'actions/billing.actions'
import { useOrganization } from 'hooks/context/organization-context'

const Migrations = () => {
  const loadingCustomer = useSelector(state => state.waiting.list[GET_CUSTOMER])
  const loadingCreateCustomer = useSelector(state => state.waiting.list[POST_CUSTOMER])

  const { organization } = useOrganization()

  const dispatch = useDispatch()
  const create = () => dispatch(API.organizations.id(organization.id).billing.customers.post({ name: organization.name }))

  return (
    <Card loading={loadingCustomer}>
      <MigrationAlert loading={loadingCreateCustomer} onMigrate={create} />
    </Card>
  )
}

export default Migrations
