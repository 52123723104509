import {
  SubscriptionItem,
} from 'hooks/api'

export type FeatureParams = {
  lambdaInvocations: number;
  awsAccounts: number;
  users: number;
  insights: boolean;
  dashboards: boolean;
  scheduledSearch: boolean;
}

export class Features {
  lambdaInvocations = 0
  awsAccounts = 0
  users = 0

  insights = false
  dashboards = false
  scheduledSearch = false

  constructor(params: Partial<FeatureParams> = {}) {
    if (params.lambdaInvocations) this.lambdaInvocations = params.lambdaInvocations
    if (params.awsAccounts) this.awsAccounts = params.awsAccounts
    if (params.users) this.users = params.users
    if (params.insights) this.insights = params.insights
    if (params.dashboards) this.dashboards = params.dashboards
    if (params.scheduledSearch) this.scheduledSearch = params.scheduledSearch
  }

  addFromSubscriptionItem({ quantity = 1, price: { metadata } }: SubscriptionItem) {
    if (!metadata) return

    if (metadata['lambdaInvocations']) {
      const value = parseInt(metadata['lambdaInvocations'])
      this.lambdaInvocations += quantity * (value > 0 ? value : 0)
    } else if (metadata['invocations']) {
      // only take one or the other into account
      const value = parseInt(metadata['invocations'])
      this.lambdaInvocations += quantity * (value > 0 ? value : 0)
    }
    if (metadata['awsAccounts']) {
      const value = parseInt(metadata['awsAccounts'])
      this.awsAccounts += quantity * (value > 0 ? value : 0)
    }
    if (metadata['users']) {
      const value = parseInt(metadata['users'])
      this.users += quantity * (value > 0 ? value : 0)
    }
    if (metadata['insights'] && metadata['insights'] === 'true') {
      this.insights = true
    }
    if (metadata['dashboards'] && metadata['dashboards'] === 'true') {
      this.dashboards = true
    }
    if (metadata['scheduledSearch'] && metadata['scheduledSearch'] === 'true') {
      this.scheduledSearch = true
    }
  }
}
