import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import configureStore, { history } from './store'
import SetupAmplify from './lib/setup-amplify'
import SetupSentry from './lib/setup-sentry'
import { UserProvider } from 'hooks/context/user-context'
import { OrganizationProvider } from 'hooks/context/organization-context'

import Routes from './routes'

import './styles/root.module.less'
import './styles/react-grid-layout-defaults.css'

const store = configureStore()
const queryClient = new QueryClient()

const loader = document.getElementById('loading-spinner')
const hideLoader = () => loader?.classList.add('loading-spinner-hide')

SetupAmplify()

if (CONFIG.NODE_ENV !== 'development') {
  SetupSentry()
}

globalThis.APP_VERSION = CONFIG.APP_VERSION || 'dev'

const App = ({ hideLoader }) => {
  useEffect(() => {
    hideLoader()
  })

  return (
    <UserProvider>
      <OrganizationProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </Provider>
          <ReactQueryDevtools position='bottom-right' />
        </QueryClientProvider>
      </OrganizationProvider>
    </UserProvider>
  )
}

ReactDOM.render(<App hideLoader={hideLoader} />, document.getElementById('app'))
