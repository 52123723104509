import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { APIGW_REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/apigateway'

const ApigatewayRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [APIGW_REQUESTS]: data.APIGATEWAY_REQUESTS,
    errorsInternal: data.APIGATEWAY_ERRORS_5XX,
    errorsClient: data.APIGATEWAY_ERRORS_4XX
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={APIGW_REQUESTS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={APIGW_REQUESTS} stackId='a' fill={colors('chart', 'primary')} name='Requests' barSize={4} />
      <Bar dataKey='errorsClient' stackId='a' fill={colors('chart', 'redLight')} name='4XX Errors' barSize={4} />
      <Bar dataKey='errorsInternal' stackId='a' fill={colors('chart', 'red')} name='5XX Errors' barSize={4} />
    </ChartContainer>
  )
}

export default ApigatewayRequestsChart
