import React from 'react'
import { Row, Col, Typography, Button } from 'antd'

import TierFeatures from './tier-features'
import Prices from './prices'

import styles from './styles.module.less'

const { Text } = Typography

const SwitchButton = ({ show } = {}) => {
  if (!show) return null
  return (
    <>
      <Row type='flex' justify='center' gutter={48} className={styles.topMargin}>
        <Col span={10}>
          <Button type='primary' block disabled>Switch to Free</Button>
        </Col>
      </Row>
      <Row type='flex' justify='center' gutter={48} style={{ marginTop: 10 }}>
        <Col>
          <Text type='secondary'>Automatic switching to free tier is currently unavailable</Text>
        </Col>
      </Row>
      <Row type='flex' justify='center' gutter={48}>
        <Col>
          <Text type='secondary'>Please contact support@dashbird.io</Text>
        </Col>
      </Row>
    </>
  )
}

const FreeTierInfo = ({ price = {}, selected } = {}) => {
  return (
    <>
      <TierFeatures tier='free' />
      <Prices data={price.onDemand} />
      <SwitchButton show={!selected} />
    </>
  )
}

export default FreeTierInfo
