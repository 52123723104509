import React from 'react'
import { useParams } from 'react-router-dom'
import Numeral from 'numeral'

import { Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { objectify } from 'lib/utils'
import { useResourceQuery } from 'hooks/api'
import { OPENSEARCH_STORAGE } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchStorageChart = ({ loading, data, height, span }) => {
  const { resourceId } = useParams()
  const { data: resource } = useResourceQuery(resourceId)

  const period = data.OPENSEARCH_DATANODES_FREE_STORAGE?.period

  const dataFrequency = period / 60 // in minutes

  if (loading || !data) return null

  const ultrawarmEnabled = JSON.parse(objectify(resource?.attributes).clusterConfig)?.warmEnabled

  const metrics = transform({
    free: data.OPENSEARCH_DATANODES_FREE_STORAGE, // in MB
    warmFree: data.OPENSEARCH_ULTRAWARM_FREE_STORAGE // in MB
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics?.map(metric => ({
    ...metric,
    [OPENSEARCH_STORAGE]: metric.free / dataFrequency * 1000000,
    warmFree: metric.warmFree / dataFrequency * 1000000
  }))

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') return [`${Numeral(value[0]).format('0.0b')} - ${Numeral(value[1]).format('0.0b')}`, undefined]
    return [Numeral(value).format('0.00b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0 b'
      span={span}
      tabKey={OPENSEARCH_STORAGE}
      formatTab={value => `${Numeral(value).format('0.00b')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line dataKey={OPENSEARCH_STORAGE} name='Free in nodes' stroke={colors('chart', 'primary')} strokeWidth={1} type='monotone' dot={false} />
      {ultrawarmEnabled && <Line dataKey='warmFree' name='Warm free storage' stroke={colors('chart', 'yellow')} strokeWidth={1} type='monotone' dot={false} />}
    </ChartContainer>
  )
}

export default OpensearchStorageChart
