import React from 'react'
import numeral from 'numeral'

import MetadataType from './type'
import Attribute from './attribute'
import Metadata from 'containers/inventory/shared/metadata'
import styles from './styles.module.less'

const DynamoMetadata = ({ title, attributes, className, keySchema, inverse }) => {
  let definitions
  if (attributes.attributeDefinitions) {
    definitions = JSON.parse(attributes.attributeDefinitions)
  }

  const data = [{
    title,
    value: (
      <div className={styles.row}>
        {definitions && <div className={styles.properties}>
          <div className={styles.primary_keys}>
            {definitions && <div className={styles.types}>
              <h4 className={styles.types_title}>Attributes</h4>
              {definitions.map(definition => <MetadataType key={definition.name} name={definition.name} value={definition.value} />)}
            </div>}
          </div>
        </div>}

        <div className={!definitions ? styles.usage : ''}>
          <div className={styles.row}>
            <Attribute name='Items' value={numeral(attributes.itemCount).format('0.[00]a')} />
            <Attribute name='Size' value={numeral(attributes.tableSize || attributes.indexSize).format('0.[00] b')} />
          </div>
          <div>
            {keySchema.map(schema => <Attribute key={schema.name} name={schema.name} value={schema.value} />)}
            {attributes.ttl && <Attribute name='TTL' value='Enabled' />}
          </div>
        </div>
      </div>
    )
  }]

  return <Metadata items={data} inverse={inverse} />
}

export default DynamoMetadata
