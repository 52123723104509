import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Typography as AntTypography, Form } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import Input from 'components/antd/input'
import { Typography } from 'components/typography'

import styles from './styles.module.less'

const { Text } = AntTypography

const submit = (values, handlers, channel) => {
  const payload = { name: values.name, uri: values.uri }
  channel ? handlers.update(payload) : handlers.create({ ...payload, type: 'webhook' })
}

const Webhook = ({ channel, handlers, saving }) => {
  const history = useHistory()

  return (
    <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>

      <Form layout='vertical' onFinish={values => submit(values, handlers, channel)} initialValues={{ name: channel?.name, uri: channel?.uri, secret: channel?.secret }}>
        <Form.Item name='name' label='Name' rules={[{ required: true }]}>
          <Input placeholder='name' />
        </Form.Item>
        <Form.Item name='uri' label='URI' rules={[{ required: true }]}>
          <Input placeholder='uri' />
        </Form.Item>
        <Form.Item name='secret' label='Secret'>
          <Input disabled placeholder='secret' />
        </Form.Item>
        <Typography.Title level={2}>Verifying Request Signature</Typography.Title>
        <Typography.Paragraph>
          Dashbird signs webhook requests (<Text code>x-dashbird-signature</Text> header) <br />
          so you can verify that requests are sent by us.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Here&apos;s an example Javascript code to generate signatures for comparison:
        </Typography.Paragraph>
        <pre className={styles.code}>
          {'const crypto = require(\'crypto\')\n\n' +
            'const signature = crypto.createHmac(\'sha256\', webhookSecret)\n\t' +
            '.update(requestURL)\n\t' +
            '.update(requestBody)\n\t' +
            '.digest(\'hex\')'}
        </pre>
        <Typography.Paragraph>
          Read more from here:&nbsp;
          <a href={'https://dashbird.io/docs/quickstart/notification-channels/'} target={'_blank'} rel="noreferrer">
            https://dashbird.io/docs/quickstart/notification-channels/
          </a>
        </Typography.Paragraph>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
          <Button type='link' onClick={history.goBack}>Cancel</Button>
        </Form.Item>
      </Form>
    </PermissionsGate>
  )
}

export default Webhook
