import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, sum } from 'lodash'
import { Row, Col } from 'antd'
import moment from 'moment'

import ContactSales from 'components/billing/contact-sales'
import BillingCycle from './billing-cycle'
import CustomerStatus from './status'
import UsageSection from './usage'
import UpcomingPayments from './upcoming-payments'
import SubscriptionModal from './modal'
import Migration from './migration'
import Content from 'components/layout/content'

import { STATUSES } from 'lib/organization-constants'
import getBillingPeriod from './billing-cycle/period-from-subscription'

import { GET_SUBSCRIPTIONS, POST_CUSTOMER } from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrgResourcesUsageSummaryQuery, useOrgIngestionSummaryQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

const calculateEstimatedUsage = (subscriptions, resourcesUsage = {}, ingestionUsage = {}) => {
  const { start, end } = getBillingPeriod(get(subscriptions, 'onDemand'))
  if (!start || !end) return

  const calculateDataIngestion = accountData => {
    const now = moment()
    const dataIngested = parseFloat(get(accountData, 'usage.gbs.sum', 0))
    const daysFromStart = now.diff(start, 'days')
    const usagePerDay = daysFromStart === 0 ? dataIngested : dataIngested / daysFromStart
    const daysUntilEnd = end.diff(now, 'days')
    return dataIngested + (daysUntilEnd * usagePerDay)
  }
  const calculateInventory = accountData => get(accountData, 'usage', 0)

  return {
    inventory: sum(Object.values(resourcesUsage).map(calculateInventory)),
    dataIngestion: sum(Object.values(ingestionUsage).map(calculateDataIngestion))
  }
}

const Payments = ({ status, loading }) => {
  if (status !== STATUSES.normal) return null
  return (
    <Col span={24}><UpcomingPayments loading={loading} /></Col>
  )
}

const SubscriptionsManagement = () => {
  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)

  const loadingSubscriptions = useSelector(state => state.waiting.list[GET_SUBSCRIPTIONS])
  const loadingCreateCustomer = useSelector(state => state.waiting.list[POST_CUSTOMER])
  const loading = loadingSubscriptions || loadingCreateCustomer
  const { organization } = useOrganization()
  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const customer = useSelector(state => get(state, 'billing.customers.active'))

  useEffect(() => {
    if (!organization.id) return
    dispatch(API.organizations.id(organization.id).billing.customers.active().get())
    dispatch(API.organizations.id(organization.id).billing.subscriptions.get())
    dispatch(API.organizations.id(organization.id).billing.prices.get())
    dispatch(API.organizations.id(organization.id).billing.payment.methods.get())
  }, [organization.id])

  if (!customer) return <Migration />

  const billingPeriodStart = get(subscriptions, 'onDemand.periodStart', 0)
  const from = subscriptions && moment.unix(billingPeriodStart).startOf('minute').valueOf()
  const until = moment().valueOf()

  const { data: resourcesUsage } = useOrgResourcesUsageSummaryQuery({ start: moment().startOf('month').startOf('day').valueOf(), end: moment().endOf('month').endOf('day').valueOf(), onlyBillable: true })
  const { data: ingestionUsage } = useOrgIngestionSummaryQuery({ start: from, end: until }, from)
  const estimatedUsage = calculateEstimatedUsage(subscriptions, resourcesUsage, ingestionUsage)

  const topRight = organization.status === STATUSES.trial ? <ContactSales /> : <BillingCycle />

  return (
    <Content item breadcrumbs={['settings', 'subscriptions']}>
      <Row gutter={[24, 24]} type='flex'>
        <Col span={10}>
          <CustomerStatus onClick={() => setShowModal(true)} />
        </Col>
        <Col span={14}>{topRight}</Col>
        <Payments status={organization.status} loading={loading} />
        <Col span={24}>
          <UsageSection estimatedUsage={estimatedUsage} />
        </Col>
      </Row>
      <SubscriptionModal visible={showModal} setVisibility={setShowModal} estimatedUsage={estimatedUsage} />

    </Content>
  )
}

export default SubscriptionsManagement
