import React from 'react'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import LambdaSvg from './lambda'
import S3Svg from './s3'
import DynamoSvg from './dynamo-db'
import ApiGatewaySvg from './apigateway'
import { SettingsIcon } from '../font-awesome'
import DashbirdSvg from './dashbird'

const ICONS = {
  [INVENTORY_SERVICES.Lambda.service]: LambdaSvg,
  [INVENTORY_SERVICES.S3.service]: S3Svg,
  [INVENTORY_SERVICES.DynamoDB.service]: DynamoSvg,
  [INVENTORY_SERVICES.APIGatewayRest.service]: ApiGatewaySvg,
  [INVENTORY_SERVICES.APIGatewayHttp.service]: ApiGatewaySvg,
  dashbird: DashbirdSvg,
  default: SettingsIcon
}

const ServiceMapIcons = ({ service, x, y, width, height }) => {
  const Icon = ICONS[service]

  if (!Icon) return <SettingsIcon x={x} y={y} width={width} height={height} />

  return <Icon x={x} y={y} width={width} height={height} />
}

export default ServiceMapIcons
