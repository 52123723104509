import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { resourceGroupsPaths as paths, RESOURCE_GROUP_ACTIVE } from './index'

const getResourceGroup = async (account, id) => {
  const data = await apiFetch(paths.group(account, id))
  return data
}

export const useResourceGroupQuery = (groupId) => {
  const { account } = useOrganization()

  return useQuery(
    [...RESOURCE_GROUP_ACTIVE, groupId],
    () => getResourceGroup(account.id, groupId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!groupId,
      refetchOnWindowFocus: false
    }
  )
}
