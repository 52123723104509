
import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { RDS_CLIENT_CONNECTIONS } from 'containers/inventory/details/resource/metrics-tabs/services/rds-proxy'

const ConnectionsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [RDS_CLIENT_CONNECTIONS]: data.RDS_CLIENT_CONNECTIONS
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={RDS_CLIENT_CONNECTIONS}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={RDS_CLIENT_CONNECTIONS} fill={colors('chart', 'primary')} barSize={4} name='Client Connections' dot={false} />
    </ChartContainer>
  )
}

export default ConnectionsChart
