export const RESOURCES = 'resources'
export const RESOURCES_LIST = [RESOURCES, 'list']
export const RESOURCE_ACTIVE = [RESOURCES, 'active']
export const RESOURCE_METRICS = [RESOURCES, 'metrics']

export * from './get-resource'
export * from './get-all-resources'
export * from './get-infinite-resources'
export * from './get-resources'

export const resourcePaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  resources: (account) => `${resourcePaths.account(account)}/resources`,
  all: (account) => `${resourcePaths.resources(account)}/all`,
  metrics: (account) => `${resourcePaths.all(account)}/metrics`
}
