import { SubscriptionItem } from 'hooks/api'

type Item = {
  id?: string;
  priceId?: string;
  quantity?: number;
  deleted?: boolean;
}

// Stripes needs changes to items not just the new set of items.
// The current strategy is to naively delete all previous ones and add new.
export const compileSubscriptionItemChanges = (currentSubscriptionItems: SubscriptionItem[], desiredItems: Item[]) => {
  const desiredMap = new Map(desiredItems.map((i) => [i.priceId, i]))
  const oldItemChanges: Item[] = []

  for (const item of currentSubscriptionItems) {
    const { id, quantity, price: { id: priceId } = {} } = item
    if (!id && !priceId) {
      console.trace('Invalid subscriptionitem', item)
      continue
    }
    const newItem = desiredMap.get(priceId)
    if (newItem) {
      desiredMap.delete(priceId)
      if ((newItem?.quantity ?? 1) === quantity) {
        // nothing has changed, skip
        continue
      }

      oldItemChanges.push({
        id,
        priceId,
        quantity: newItem?.quantity ?? 1
      })
      continue
    }
    oldItemChanges.push({
      id,
      priceId,
      deleted: true,
      // BE doesn't share `plan` so client updating subscription while on metered Enterprise plan just errors
      // Move to Free or Business plan manually first
      // ...(plan.usage_type === 'metered' && { clearUsage: true })
    })
  }

  return oldItemChanges.concat(...desiredMap.values())
}

export default compileSubscriptionItemChanges
