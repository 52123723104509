import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { LoadingOutlined } from '@ant-design/icons'

import styles from './styles.module.less'

const LoginProviderContainer = () => {
  const { provider } = useParams()

  useEffect(() => {
    Auth.federatedSignIn({ customProvider: provider })
  })

  return (
    <div>
      <p className={styles.info}>Logging in with provider <LoadingOutlined spin /></p>
    </div>
  )
}

export default LoginProviderContainer
