import { combineReducers } from 'redux'

function list (state = {}, action) {
  switch (action.type) {
    case 'WAITING':
      return { ...state, [action.payload.key]: action.payload.state }
  }
  return state
}

export default combineReducers({
  list
})
