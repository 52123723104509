import React from 'react'

import { Select, Form } from 'antd'

import Input from 'components/antd/input'
import { EU_MEMBER_STATES } from 'lib/billing-constants'
import countries from 'components/billing/countries'

const vatValidator = (validator, country) => async (rule, value) => {
  if (!value) return Promise.resolve()

  const { payload } = await validator({ payload: { country, vat: value } })
  if (!payload.valid) {
    throw new Error(rule.message)
  }

  Promise.resolve()
}

const BillingForm = React.forwardRef(({ customer, validateVat }, ref) => {
  const handleSearch = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Form
      ref={ref}
      name='basic'
      labelAlign='left'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        country: customer?.address?.country,
        line1: customer?.address?.line1,
        line2: customer?.address.line2,
        city: customer?.address?.city,
        state: customer?.address?.state,
        postalCode: customer?.address?.postalCode,
        vat: customer?.vat,
        email: customer?.email,
        name: customer?.name
      }}
    >
      <Form.Item name='country' label='Country' rules={[{ required: true, message: 'Please select a country' }]}>
        <Select
          showSearch
          filterOption={handleSearch}
        >
          {
            countries.map((country) => (
              <Select.Option
                key={country.value}
              >
                {country.label}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>

      <Form.Item name='line1' label='Street' rules={[{ required: true, message: 'Please enter street.' }]}>
        <Input placeholder='Street' />
      </Form.Item>

      <Form.Item name='line2' label='Building number' rules={[{ message: 'Please enter building number.' }]}>
        <Input placeholder='Number' />
      </Form.Item>

      <Form.Item name='city' label='City' rules={[{ required: true, message: 'Please enter city.' }]}>
        <Input placeholder='City' />
      </Form.Item>

      <Form.Item name='state' label='State'
        rules={[{ required: true, message: 'Please enter state.' }]}>
        <Input placeholder='State' />
      </Form.Item>

      <Form.Item name='postalCode' label='Zip/Postal code'
        rules={[{ required: true, message: 'Please enter zip/postal code' }]}>
        <Input placeholder='Zip/Postal code' />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          EU_MEMBER_STATES[getFieldValue('country') || customer?.address?.country]
            ? (<Form.Item
              name='vat' label='VAT number'
              validateTrigger='onBlur'
              rules={[{ required: false, validator: vatValidator(validateVat, ref?.current?.getFieldValue('country') || customer?.address?.country), message: 'VAT validation failed' }]}
            >
              <Input placeholder='VAT number' />
            </Form.Item>)
            : null
        }
      </Form.Item>

      <Form.Item name='email' label='Contact email'
        rules={[{
          required: true,
          message: 'Please enter contact email'
        }, {
          type: 'email',
          message: 'Please enter valid contact email'
        }]}>
        <Input placeholder='Contact email' />
      </Form.Item>

      <Form.Item name='name' label='Contact name' rules={[{ required: true, message: 'Please enter contact name' }]}>
        <Input placeholder='Contact name' />
      </Form.Item>
    </Form >
  )
})

export default BillingForm
