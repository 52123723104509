import { sum } from 'lodash'
import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { metricsPaths as paths } from './index'

import RegionCosts from 'lib/lambda/prices'
import { useOrganization } from 'hooks/context/organization-context'

const isCostMetric = (metric) => metric === 'gb_second'

export const mapGroupedByToDefault = (data) => {
  if (!data) return {}
  const { datapoints } = data
  const keys = Object.keys(data?.datapoints || {})
  if (!keys || !keys.length) return {}
  if (keys.length === 1 && keys[0] === 'default') return data

  return {
    ...data,
    datapoints: {
      default: datapoints?.[keys[0]]?.map((datapoint, index) => {
        return {
          ...datapoint,
          sum: isCostMetric(data?.metric)
            ? sum(keys.map(key => {
              const value = datapoints?.[key]?.[index]?.sum
              return value * RegionCosts[key].duration
            }))
            : sum(keys.map(key => datapoints?.[key]?.[index]?.sum))
        }
      })
    }
  }
}

const getMetrics = async (account, payload) => {
  const data = await (apiFetch(paths.metrics(account), { method: 'POST', body: JSON.stringify(payload) }))
  return mapGroupedByToDefault(data)
}

export const buildTotalMetricsPayload = (key, options) => {
  switch (key) {
    case 'invocations': return {
      namespace: 'dashbird/lambda',
      metric: 'invocations',
      dimensions: {
        ...options?.dimensions
      },
      group: options?.group,
      fill: 'gapfill'
    }

    case 'warnings': return {
      namespace: 'dashbird/event/error',
      metric: 'errors',
      dimensions: {
        severities: ['warning'],
        ...options?.dimensions
      },
      group: options?.group,
      fill: 'gapfill'
    }

    case 'errors': return {
      namespace: 'dashbird/event/error',
      metric: 'errors',
      dimensions: {
        severities: ['critical'],
        ...options?.dimensions
      },
      group: options?.group,
      fill: 'gapfill'
    }

    case 'resource-errors': return {
      namespace: 'dashbird/lambda',
      metric: 'errors',
      dimensions: {
        ...options?.dimensions
      },
      group: options?.group,
      fill: 'gapfill'
    }

    case 'cost': return {
      namespace: 'dashbird/lambda',
      metric: 'gb_second',
      dimensions: {
        ...options?.dimensions
      },
      group: 'region',
      fill: 'locf'
    }

    case 'billed-duration': return {
      namespace: 'dashbird/lambda',
      metric: 'billed_duration',
      dimensions: {
        ...options?.dimensions
      },
      group: options?.group,
      fill: 'gapfill'
    }
    case 'scheduled-query': return {
      namespace: 'dashbird/event/log-matches',
      metric: 'occurrences',
      stats: ['sum'],
      dimensions: {
        ...options?.dimensions
      },
      fill: 'gapfill'
    }
    default: return {}
  }
}

export const useTotalCountQuery = (load, start, end) => {
  const { account } = useOrganization()

  const payload = {
    end: new Date(parseInt(end)),
    start: new Date(parseInt(start)),
    stats: ['sum'],
    ...buildTotalMetricsPayload(load?.key, load?.options)
  }

  return useQuery(
    [`total-${load.key}-count`, start, end, load?.options, account?.id],
    () => getMetrics(account?.id, payload),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!payload
    }
  )
}
