import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SNS_MESSAGES } from 'containers/inventory/details/resource/metrics-tabs/services/sns'

const MessagesChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [SNS_MESSAGES]: data.SNS_MESSAGES_PUBLISHED
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={SNS_MESSAGES}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={SNS_MESSAGES} fill={colors('chart', 'primary')} barSize={4} name='Messages published' dot={false} />
    </ChartContainer>
  )
}

export default MessagesChart
