import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { Row, Col, Input, Form } from 'antd'

import { GET_INVOICE_PREVIEW, PUT_SUBSCRIPTION } from 'actions/billing.actions'
import { API } from 'actions/api'

import ApplyButton from './buttons/apply'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const discountValidator = (setValidateStatus, fetch) => async (rule, value) => {
  setValidateStatus('validating')
  if (!value || value === '') {
    setValidateStatus('')
    return Promise.resolve()
  }

  const promotion = await fetch(value)
  const promotionId = get(promotion, 'payload.stripeId')
  if (!promotionId) {
    setValidateStatus('error')
    return Promise.reject(new Error(rule.message))
  }
  setValidateStatus('success')
  return Promise.resolve()
}

const OndemandDiscountForm = React.forwardRef(({ onSubmit } = {}, ref) => {
  const { organization } = useOrganization()

  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const subscriptionId = get(subscriptions, 'onDemand.stripeId')
  if (!subscriptionId) return null

  const dispatch = useDispatch()

  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))
  const loadingPreview = useSelector(state => state.waiting.list[GET_INVOICE_PREVIEW])
  const updatingSubscription = useSelector(state => state.waiting.list[PUT_SUBSCRIPTION])
  const fetchDiscount = (code) => dispatch(API.organizations.id(organization.id).billing.promotions.id(code).get())

  const [validateStatus, setValidateStatus] = useState('')
  const loading = loadingPreview || updatingSubscription
  const hasNoPaymentMethod = paymentMethods.length < 1

  return (
    <Form ref={ref}>
      <Row className={styles.topMargin}>
        <Col offset={14} span={10}>
          <Form.Item name='promotion'
            validateTrigger='onBlur'
            validateStatus={validateStatus}
            hasFeedback
            rules={[{ validator: discountValidator(setValidateStatus, fetchDiscount), message: 'Invalid code' }]}
          >
            <Input placeholder='Discount code' />
          </Form.Item>
          <ApplyButton
            title='Apply discount'
            disabled={hasNoPaymentMethod || validateStatus !== 'success'}
            onClick={() => onSubmit(subscriptionId)}
            loading={loading}
            tooltip={hasNoPaymentMethod ? 'Please add a payment method before purchasing' : null}
          />
        </Col>
      </Row>
    </Form>
  )
})

export default OndemandDiscountForm
