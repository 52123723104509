import { v4 as uuid } from 'uuid'
import { getContent } from 'hooks/nodes/transform/get-content'
import { getData } from 'hooks/nodes/transform/get-data'
import { getName } from 'hooks/nodes/transform/get-name'
import { getErrors } from 'hooks/nodes/transform/get-errors'
import { getService } from 'hooks/nodes/transform/get-service'
import { getSegmentGroup } from 'hooks/nodes/transform/get-service-group'
import { getInfo } from 'hooks/nodes/transform/get-info'

const flatten = (node) => { }

/**
 * @typedef {Object} TransformedSegment
 * @property {string} id
 * @property {string} name
 * @property {string} content
 * @property {number} startTime
 * @property {number} endTime
 * @property {string} service
 * @property {Array<Error>} errors
 * @property {Object} data
 * @property {Array<TransformedSegment>} children
 * @property {Object} reference Original object from X-Ray
 */

/**
 * Transforms segments from X-Ray format to Dashbird format
 * @param node
 * @param parent
 * @returns {TransformedSegment}
 */

const transform = (node, parent, inferred) => {
  const transformed = {
    id: node.id || uuid(),
    parentId: node.parent_id,
    name: getName(node),
    content: getContent(node, parent),
    startTime: node.start_time,
    endTime: node.end_time,
    service: getService(node),
    segmentGroup: getSegmentGroup(node, parent),
    errors: getErrors(node),
    info: getInfo(node),
    data: getData(node)
  }

  // make a string representation of the node that can be used for searching by query
  const searchable = JSON.stringify({
    ...transformed,
    reference: {
      ...node,
      children: [],
      subsegments: []
    }
  }).toLowerCase()

  return {
    ...transformed,
    searchable,
    reference: node,
    children: node.children
      .filter(child => inferred ? true : !child.inferred)
      .map(child => transform(child, transformed, inferred))
      .sort((a, b) => a.startTime - b.startTime)
  }
}

export default { transform, flatten }
export { transform, flatten }
