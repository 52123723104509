import { differenceInMinutes } from 'date-fns/esm'

export const BUCKETS = [
  { value: 10, from: 0, name: '10 mins', width: 7, start: 0, tick: 7 },
  { value: 60, from: 10, name: '60 mins', width: 10, start: 7, tick: 17 },
  { value: 12 * 60, from: 60, name: '12 hours', width: 13, start: 17, tick: 30 },
  { value: 24 * 60, from: 12 * 60, name: '1 day', width: 15, start: 30, tick: 50 },
  { value: 7 * 24 * 60, from: 24 * 60, name: '7 days', width: 20, start: 50, tick: 70 },
  { value: 30 * 24 * 60, from: 7 * 24 * 60, name: '30 days', width: 25, start: 70, tick: 100 }
]

export const ERRORS_FILTERS = [
  {
    key: 'severity',
    name: 'severity',
    filter: (selected, error) => selected.severity.includes(error?.severity)
  },
  {
    key: 'type',
    name: 'errorType',
    filter: (selected, error) => selected.type.includes(error.errorType.toLowerCase())
  },
  {
    key: 'muted',
    name: 'muted',
    filter: (selected, error) => {
      if (selected.muted.includes('hide')) return !error.mutedAt
      return true
    }
  },
  {
    key: 'status',
    name: 'errorStatus',
    filter: (selected, error) => {
      if (selected.status.includes('open')) return error.status === 'open'
      return error.status === 'resolved'
    }
  },
  {
    key: 'time-bucket',
    name: 'time-bucket',
    filter: (selected, error) => {
      const minutesFromLast = differenceInMinutes(new Date(), error.lastOccurredAt)
      const group = BUCKETS.find(bucket => selected['time-bucket'].includes(bucket?.value.toString()))
      if (group.value === 30 * 24 * 60) return minutesFromLast > group?.from
      return minutesFromLast < group.value && minutesFromLast > group.from
    }
  },
  {
    key: 'group',
    name: 'group',
    filter: (selected, error, idsInGroups) => idsInGroups?.includes(error?.resource?.id)
  },
  {
    key: 'resource',
    name: 'resource',
    filter: (selected, error) => selected?.resource?.includes(error?.resource?.id || error?.resource)
  },
  {
    key: 'groupHash',
    name: 'groupHash',
    filter: (selected, error) => selected?.groupHash?.includes(error?.group)
  },
  {
    key: 'region',
    name: 'region',
    filter: (selected, error) => selected.region.includes(error?.resource?.region)
  }, {
    key: 'delegation',
    name: 'delegation',
    filter: (selected, error) => selected.delegation.includes(error?.resource?.delegation)
  }
]
