import React, { useEffect, useState } from 'react'

import ForgotPassword from './form'
import ForgotPasswordConfirm from './confirm-form'

import { useQuery } from 'lib/hooks/utils'
import GenericAction from 'containers/auth/generic-action'
import { useConfirmLogin, useForgotPassword, useForgotPasswordSubmit, useLogin } from 'hooks/auth'
import { useUser } from 'hooks/context/user-context'
import MFAVerification from 'containers/auth/mfa-verification'

const ForgotPasswordContainer = () => {
  const query = useQuery()

  const queryEmail = query.get('email')
  const queryVerification = query.get('verification')
  const forgotPasswordConfirm = queryEmail && queryVerification

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { user } = useUser()

  const { mutate: signIn } = useLogin()
  const { mutate: confirmSignIn, isLoading: loadingConfirmSignIn } = useConfirmLogin()
  const { mutate: forgotPasswordSubmit, isSuccess: forgotPasswordSubmitSuccess, isLoading: forgotPasswordSubmitLoading } = useForgotPasswordSubmit()
  const { mutate: forgotPassword, isSuccess: forgotPasswordEmailSent, isLoading: forgotPasswordLoading } = useForgotPassword()

  const userConfirmLogin = user && user.challengeName === 'SOFTWARE_TOKEN_MFA'

  const onForgotPassword = async (values) => {
    const { email } = values
    setEmail(email)
    forgotPassword({ email })
  }

  const onForgotPasswordSubmit = async (values) => {
    const { password } = values
    setPassword(password)
    forgotPasswordSubmit({
      email: queryEmail,
      code: queryVerification,
      password
    })
  }

  const onSubmitConfirmLogin = (values) => {
    const { code } = values

    confirmSignIn({ code })
  }

  const onResendVerification = async () => {
    if (!email) return
    forgotPassword({ email })
    // TODO resend auth message MSG_RESENT_PASSWORD_REQUEST
  }

  useEffect(() => {
    if (forgotPasswordSubmitSuccess) {
      signIn({ email: queryEmail, password })
    }
  }, [forgotPasswordSubmitSuccess])

  const getForm = () => {
    if (userConfirmLogin) {
      return <MFAVerification onSubmit={onSubmitConfirmLogin} loading={loadingConfirmSignIn} />
    } if (forgotPasswordConfirm) {
      return <ForgotPasswordConfirm onSubmit={onForgotPasswordSubmit} loading={forgotPasswordSubmitLoading} />
    } else if (forgotPasswordEmailSent) {
      return <GenericAction onSubmit={onResendVerification} loading={forgotPasswordLoading} text='Resend verification' />
    }

    return <ForgotPassword onSubmit={onForgotPassword} loading={forgotPasswordLoading} />
  }

  return getForm()
}

export default ForgotPasswordContainer
