import React from 'react'
import classnames from 'classnames'
import Section from 'components/layout/content/section'
import { Typography } from 'components/typography'

import styles from '../styles.module.less'

const CheckoutEnterprise = () => {
  return (
    <Section className={styles.checkout_enterprise}>
      <h2 className={styles.title}>Checkout overview</h2>
      <Typography.Title level={4} className={styles.label}>Selected tier: </Typography.Title>
      <p className={styles.tier}>enterprise</p>
      <p className={classnames(styles.text, styles.margin_large)}>To understand your infrastructure and needs better please contact our sales team.</p>
    </Section>
  )
}

export default CheckoutEnterprise
