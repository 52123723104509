import React, { createContext, useEffect, useState } from 'react'

export const ViewContext = createContext({
  start: null,
  end: null,
  pageTitle: '',
  pageSubtitle: '',
  relativeTime: null,
  range: null,
  scrollRef: null,
  scrollTop: 0
})

const ViewContainer = ({ scrollRef, children }) => {
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [relativeTime, setRelativeTime] = useState(null)
  const [range, setRange] = useState(null)

  const [pageTitle, setPageTitle] = useState('')
  const [pageSubtitle, setPageSubtitle] = useState('')

  const [scrollTop, setScrollTop] = useState(0)

  const handleScroll = () => {
    setScrollTop(scrollRef?.current?.scrollTop)
  }

  useEffect(() => {
    if (!scrollRef || !scrollRef.current) return

    scrollRef?.current?.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef])

  return (
    <ViewContext.Provider value={{ start, end, pageTitle, pageSubtitle, relativeTime, range, scrollTop, scrollRef }}>
      {children}
    </ViewContext.Provider>
  )
}

export default ViewContainer
