import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { STEPFUNCTIONS_ERRORS } from 'containers/inventory/details/resource/metrics-tabs/services/stepfunctions'

const StepFunctionsFailedChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [STEPFUNCTIONS_ERRORS]: data.STEPFUNCTIONS_EXECUTIONS_FAILED,
    timeouts: data.STEPFUNCTIONS_EXECUTIONS_TIMEOUTS,
    throttles: data.STEPFUNCTIONS_EXECUTIONS_THROTTLES,
    aborted: data.STEPFUNCTIONS_EXECUTIONS_ABORTED
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => [Numeral(value).format('0.[000]a'), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[00]'
      span={span}
      tabKey={STEPFUNCTIONS_ERRORS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey='aborted' stackId='a' fill={colors('chart', 'redLight')} barSize={4} name='Aborted' />
      <Bar dataKey='timeouts' stackId='a' fill='#000000' barSize={4} name='Timeouts' />
      <Bar dataKey='throttles' stackId='a' fill={colors('chart', 'primary')} barSize={4} name='Throttles' />
      <Bar dataKey={STEPFUNCTIONS_ERRORS} stackId='a' fill={colors('chart', 'red')} barSize={4} name='Failed' />
    </ChartContainer>
  )
}

export default StepFunctionsFailedChart
