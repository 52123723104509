import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Switch, Tooltip } from 'antd'

import { useAccountRoutes } from 'containers/routes'
import { useInsightRuleQuery, useInsightsQuery, useUpdateInsightRule, useAllResourcesQuery, useDelegationsQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import { INSIGHT_RULE_STATUSES } from 'lib/event-constants'

import Content from 'components/layout/content'
import Violations from 'features/violations'
import RuleDescription from './rule-description'
import RuleConfiguration from './rule-configuration'
import InsightMetrics from './metrics'
import { parseInsightRule } from '../index'

import styles from './styles.module.less'

const ToggleEnabled = ({ status }) => {
  if (!status) return null

  const { mutate: updateStatus, isLoading } = useUpdateInsightRule()

  const handleStatusUpdate = (value) => {
    updateStatus({ status: value ? INSIGHT_RULE_STATUSES.ACTIVE : INSIGHT_RULE_STATUSES.DISABLED })
  }

  const switchEnabled = status === INSIGHT_RULE_STATUSES.ACTIVE || status === INSIGHT_RULE_STATUSES.INACTIVE

  return (
    <PermissionsGate
      scopes={[SCOPES.canEdit]}
      errorProps={!switchEnabled ? { disabled: true, unCheckedChildren: 'DISABLED' } : null}
    >
      <Tooltip title={`${switchEnabled ? 'Disable' : 'Enable'} insight rule`} placement='bottomRight'>
        <Switch
          disabled={status === INSIGHT_RULE_STATUSES.SUSPENDED}
          defaultChecked={switchEnabled}
          checked={switchEnabled}
          onChange={handleStatusUpdate}
          loading={isLoading} />
      </Tooltip>
    </PermissionsGate>
  )
}

const InsightDetails = () => {
  const { ruleId } = useParams()
  const routes = useAccountRoutes()
  const rule = parseInsightRule(ruleId)

  const { data: activeRule, isLoading: loadingRule } = useInsightRuleQuery(rule)
  const { data: insights, isLoading } = useInsightsQuery()
  useAllResourcesQuery()
  useDelegationsQuery()

  const violations = insights?.filter(item => item.rule === rule)

  const [filteredCount, setFilteredCount] = useState(0)

  const configRule = isEmpty(activeRule?.metrics)

  const violationsCount = filteredCount > violations?.length ? filteredCount : violations?.length

  return (
    <Content
      title={activeRule?.name}
      breadcrumbs={['insights']}
      actions={<ToggleEnabled status={activeRule?.status} ruleId={rule} />}
      item={{}}
      loading={loadingRule || !activeRule}
      backRoute={routes.insights.url()}
      className={styles.scroll_wrapper}
      fixed
    >
      <InsightMetrics
        rule={activeRule}
        count={filteredCount !== violations?.length ? `${filteredCount} / ${violationsCount}` : violationsCount}
        loading={isLoading}
      />
      <>
        <RuleDescription text={activeRule?.description || 'No description found'} />
        <RuleConfiguration rule={activeRule} />
      </>
      <Violations violations={violations} configRule={configRule} loading={isLoading} handleCount={setFilteredCount} />
    </Content>
  )
}

export default InsightDetails
