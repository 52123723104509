import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation'

const ERROR_DESCRIPTIONS = {
  'log-source.log-source-not-found': 'Log group not found'
}

const FAILURE_DESCRIPTIONS = {
  LimitExceededException: 'We could not create a subcription since the log group already has 2 or more subscriptions. Please, remove one or all subscriptions manually.',
  ResourceNotFoundException: 'Log group for this lambda does not exist. This could happen if lambda has not been executed yet.',
  CredentialsError: 'Dashbird coundn\'t access your AWS account to create a subscription.',
  ThrottlingException: 'Throttling exception occurred while trying to create a subscription.'
}

const icon = (x) => <FontAwesomeIcon icon={x} color='#e9e8e8' />

const getIcon = (loading, logSource, logSourceError) => {
  if (loading) return null

  if (!logSource || logSourceError) return icon(faExclamation)

  if (logSource?.failure) {
    return icon(faTimes)
  }

  return icon(faCheck)
}

const getText = (loading, logSource, logSourceError) => {
  if (loading) return null

  if (!logSource || logSource.failure || logSourceError) {
    return 'Error!'
  }

  if (logSource.disabled || logSource.suspended) {
    return 'Disabled!'
  }

  return 'All good!'
}

const getTooltipText = (loading, logSource, logSourceError) => {
  if (loading) return null
  if (!logSource || logSourceError) {
    return ERROR_DESCRIPTIONS[logSourceError?.code] || 'Unknown error'
  }

  if (logSource.failure) {
    return FAILURE_DESCRIPTIONS[logSource.failure.type] || `${logSource.failure.type}: ${logSource.failure.reason}`
  }

  if (logSource.suspended) {
    return 'Subscription is suspended!'
  }

  if (logSource.disabled) {
    return 'Subscription is disabled!'
  }

  return 'Everything looks good!'
}

export {
  getIcon,
  getText,
  getTooltipText
}
