import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, DASHBOARDS_LIST, DASHBOARD_ACTIVE } from './index'

const updateDashboard = async (account, identity, payload) => {
  const data = await apiFetch(paths.dashboard(account, identity), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateDashboardQuery = () => {
  const queryClient = useQueryClient()
  const { dashboardId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    payload => updateDashboard(account.id, dashboardId, payload),
    {
      onSuccess: updatedDashboard => {
        const previousList = queryClient.getQueryData([...DASHBOARDS_LIST, account.id])
        const updatedList = previousList?.map(item => item.id === updatedDashboard.id ? updatedDashboard : item)
        queryClient.setQueryData([...DASHBOARDS_LIST, account.id], updatedList)
        queryClient.setQueryData([...DASHBOARD_ACTIVE, dashboardId], updatedDashboard)
      }
    }
  )
}
