import { useEffect, useState } from 'react'

export const Breakpoint = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl'
}

export const Operator = {
  greaterThan: '>',
  lessThan: '<',
  greaterThanOrEqual: '>=',
  lessThanOrEqual: '<='
}

const BreakpointMap = {
  [Breakpoint.xs]: 480,
  [Breakpoint.sm]: 576,
  [Breakpoint.md]: 768,
  [Breakpoint.lg]: 992,
  [Breakpoint.xl]: 1200,
  [Breakpoint.xxl]: 1600
}

const Breakpoints = [
  { value: Breakpoint.xs, width: BreakpointMap[Breakpoint.xs] },
  { value: Breakpoint.sm, width: BreakpointMap[Breakpoint.sm] },
  { value: Breakpoint.md, width: BreakpointMap[Breakpoint.md] },
  { value: Breakpoint.lg, width: BreakpointMap[Breakpoint.lg] },
  { value: Breakpoint.xl, width: BreakpointMap[Breakpoint.xl] },
  { value: Breakpoint.xxl, width: BreakpointMap[Breakpoint.xxl] }
]

export const getBreakpoint = () => {
  return Breakpoints.find(b => b.width > window.innerWidth)?.value || Breakpoint.xxl
}

export const getDimensions = () => ({
  height: window.innerHeight,
  width: window.innerWidth
})

/**
 * @param {Breakpoint} breakpoint - breakpoint to check
 * @param {Breakpoint} currentBreakpoint - current breakpoint
 * @param {Operator} operator - logical operator to use
 * @returns {boolean}
 */
const checkVisibility = (breakpoint, currentBreakpoint, operator) => {
  const breakpointWidth = BreakpointMap[breakpoint]
  const currentBreakpointWidth = BreakpointMap[currentBreakpoint]

  switch (operator) {
    case Operator.greaterThan:
      return breakpointWidth > currentBreakpointWidth
    case Operator.lessThan:
      return breakpointWidth < currentBreakpointWidth
    case Operator.greaterThanOrEqual:
      return breakpointWidth >= currentBreakpointWidth
    case Operator.lessThanOrEqual:
      return breakpointWidth <= currentBreakpointWidth
    // ...
    default:
      return breakpoint === currentBreakpoint
  }
}

export const useResize = () => {
  const [dimensions, setDimensions] = useState(getDimensions())
  const [currentBreakpoint, setCurrentBreakpoint] = useState(getBreakpoint())

  /**
   * Returns the result of the logical comparison done between the given breakpoint and the current breakpoint
   * @enum {Breakpoint} breakpoint
   * @param {Operator} operator
   * @returns {boolean}
   */
  const isVisible = (breakpoint, operator) => {
    return checkVisibility(breakpoint, currentBreakpoint, operator)
  }

  /**
   * Returns true if the current breakpoint is greater than the given breakpoint
   * @enum {Breakpoint} breakpoint
   * @returns {boolean}
   */
  const isVisibleUntil = (breakpoint) => {
    return isVisible(breakpoint, Operator.greaterThan)
  }

  /**
   * Returns true if the current breakpoint is less than or equal to the given breakpoint
   * @enum {Breakpoint} breakpoint
   * @returns {boolean}
   */
  const isVisibleFrom = (breakpoint) => {
    return isVisible(breakpoint, Operator.lessThanOrEqual)
  }

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
      setCurrentBreakpoint(getBreakpoint())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    dimensions,
    currentBreakpoint,
    /** @deprecated Use isVisibleUntil instead */
    isShownUntil: isVisibleUntil,
    /** @deprecated Use isVisibleFrom instead */
    isShownFrom: isVisibleFrom,
    isVisible,
    isVisibleUntil,
    isVisibleFrom
  }
}
