const formatInvocations = (value) => {
  if (value > 999999) {
    return `${(value / 1000000).toFixed(1)}M`
  }

  if (value > 999) {
    return `${(value / 1000).toFixed(1)}K`
  }

  return value
}

export default formatInvocations
