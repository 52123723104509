import React from 'react'
import { Area, Line } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { formatDuration } from 'components/charts/utils'
import { RESPONSE_TIME } from 'containers/inventory/details/resource/metrics-tabs/services/elb-target-group'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    time: data.ELB_TARGETGROUP_RESPONSE_TIME
  }, value => value * 1000)

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [RESPONSE_TIME]: metric.time_avg,
      diff: [metric.time_min, metric.time_max]
    }
  })

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') return [`${formatDuration(value[0])} - ${formatDuration(value[1])}`, undefined]
    return [formatDuration(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={RESPONSE_TIME}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
      formatYTicks={value => formatDuration(value)}
    >
      <Line type='monotone' dataKey={RESPONSE_TIME} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />
      <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Min - max' />
    </ChartContainer>
  )
}

export default ELBRequestsChart
