
import React from 'react'
import { useParams } from 'react-router-dom'
import Numeral from 'numeral'

import { Bar, Line, YAxis } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { formatDuration, yAxisProps } from 'components/charts/utils'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { useResourceQuery } from 'hooks/api'

const RDSReadsWritesChart = ({ chartKey, loading, data, height, span }) => {
  const { resourceId } = useParams()
  const { data: resource } = useResourceQuery(resourceId)

  if (loading || !data) return null

  const chartType = chartKey.split('-')[1]?.toUpperCase()

  const metrics = transform({
    [chartKey]: data[`RDS_${chartType}_OPERATIONS`],
    latency: data[`RDS_${chartType}_LATENCY`],
    throughput: data[`RDS_${chartType}_THROUGHPUT`],
    queue: data.RDS_QUEUE_DEPTH
  })

  const formatted = metrics?.map((metric) => {
    return {
      ...metric,
      latency: metric.latency * 10000 // should be sec --> ms, hence * 1000 but this way matches metrics in aws
    }
  })

  if (!formatted.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'latency') return [formatDuration(value), undefined]
    if (dataKey === 'throughput') return [Numeral(value).format('0.00b'), undefined]
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0 a'
      span={span}
      tabKey={chartKey}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
    >
      {data[`RDS_${chartType}_OPERATIONS`] && <Bar dataKey={chartKey} fill={colors('chart', 'redLight')} barSize={4} name='Read IOPS' dot={false} />}
      {data[`RDS_${chartType}_THROUGHPUT`] && <Line dataKey='throughput' name='Throughput' type='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />}
      <Line yAxisId='latency' dataKey='latency' name='Latency' type='monotone' stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={1} dot={false} />
      {data.RDS_QUEUE_DEPTH && <Line dataKey='queue' name='Disk queue depth' type='monotone' stroke={colors('chart', 'red')} strokeWidth={1} dot={false} />}
      <YAxis yAxisId='latency' orientation={resource.type === INVENTORY_SERVICES.RDSCluster.type ? 'left' : 'right'} domain={[0, 'dataMax']} tickFormatter={value => formatDuration(value)} {...yAxisProps} />

    </ChartContainer>
  )
}

export default RDSReadsWritesChart
