import React, { useEffect, useRef, useState } from 'react'

import { Modal, Skeleton, message } from 'antd'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { useOrganization } from 'hooks/context/organization-context'
import { useCreatePaymentMethodSetupQuery, useDeletePaymentMethodSetupQuery, useUpdateCustomerQuery } from 'hooks/api/billing/index'
import BillingForm from './billing-form'
import validateBillingInfo from 'containers/settings/billing/payment/billing-info/modal/validate'

import styles from './styles.module.less'

const submitMethod = async (stripe, elements, setup, organization) => {
  if (!stripe || !elements || !setup) throw new Error('Stripe is not loaded')

  const result = await stripe.confirmCardSetup(setup.secret, {
    payment_method: {
      card: elements.getElement(CardElement),
      metadata: { organization: organization?.id }
    }
  })

  if (result.error) {
    throw new Error(result.error.message)
  }

  return { stripeId: result.setupIntent.payment_method, type: result.setupIntent.payment_method_types[0] }
}

const AddMethodModal = ({ customer, visible, setVisible, createMethod }) => {
  const stripe = useStripe()
  const elements = useElements()
  const formRef = useRef()

  const { organization } = useOrganization()
  const { mutateAsync: updateCustomer } = useUpdateCustomerQuery()
  const { mutateAsync: createMethodSetup, isLoading: loadingSetup } = useCreatePaymentMethodSetupQuery()
  const { mutate: deleteMethodSetup } = useDeletePaymentMethodSetupQuery()

  const [setupIntent, setSetupIntent] = useState(false)
  const [creatingStripeMethod, setCreatingStripeMethod] = useState(false)

  const [error, setError] = useState(null)

  const shouldUpdateAddress = customer && Object.values(customer?.address).every(x => (!x || x === ''))

  const handleCreateSetupIntent = async () => {
    try {
      const data = await createMethodSetup()
      setSetupIntent(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!visible) return
    handleCreateSetupIntent()
  }, [visible])

  const handleClose = () => {
    deleteMethodSetup({ intentId: setupIntent.id })
    setVisible(false)
  }

  const handleSubmitMethod = async () => {
    let billingInfo
    if (shouldUpdateAddress) {
      billingInfo = await validateBillingInfo(formRef?.current)
      if (!billingInfo) {
        return null
      }
    }
    setError(null)
    setCreatingStripeMethod(true)

    try {
      const payload = await submitMethod(stripe, elements, setupIntent, organization)
      await createMethod({ payload })

      if (shouldUpdateAddress) {
        try {
          await updateCustomer({ identity: customer.id, payload: billingInfo })
        } catch (error) { console.log(error) }
      }

      message.success('Payment method created')
      setVisible(false)
    } catch (error) {
      setError(error.message)
    } finally {
      setCreatingStripeMethod(false)
    }
  }

  return (
    <Modal
      open={visible}
      onOk={handleSubmitMethod}
      onCancel={handleClose}
      confirmLoading={creatingStripeMethod}
      className={styles.modal}
      title='Add new payment method'
    >
      <Skeleton loading={loadingSetup}>
        {shouldUpdateAddress && <BillingForm customer={customer} ref={formRef} />}
        <CardElement className={styles.card_input} />
        {error && <p className={styles.error}>{error}</p>}
      </Skeleton>
    </Modal>
  )
}

export default AddMethodModal
