import { pathsV2 } from '../index'

export const INTEGRATIONS = 'integrations'
export const REGIONS = 'regions'
export const DELEGATION_INTEGRATIONS_LIST = ['delegation', INTEGRATIONS, 'list']
export const INTEGRATION_REGIONS = [INTEGRATIONS, REGIONS, 'list']
export const INTEGRATION_SERVICE_REGIONS = [INTEGRATIONS, REGIONS, 'service', 'list']
export const INTEGRATION_ACTIVE = [INTEGRATIONS, 'active']

export * from './get-delegation-integrations'
export * from './get-provider-regions'
export * from './get-integration'
export * from './update-integration'

export const integrationsPaths = {
  filter: (path, query) => `${path}?${query}`,
  delegation: (account, identity) => `${pathsV2.account(account)}/delegations/${identity}`,
  delegationIntegrations: (account, delegation) => `${integrationsPaths.delegation(account, delegation)}/integrations`,
  integrations: (account) => `${pathsV2.account(account)}/integrations`,
  integrationRegions: (account) => `${integrationsPaths.integrations(account)}/regions`,
  integration: (account, identity) => `${integrationsPaths.integrations(account)}/${identity}`
}
