import React from 'react'
import { Switch, Dropdown, Popconfirm } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ActionsDropdownButton } from 'components/buttons'

const PolicyActions = ({ policy, handlers }) => {
  return (
    <>
      {policy && (
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Switch
            onChange={(value) => handlers.update({ disabled: !value })}
            checked={policy?.id && !policy?.disabled}
            defaultChecked={policy?.id && !policy?.disabled}
          />
        </PermissionsGate>
      )}
      <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'delete',
                label: (
                  <Popconfirm
                    title={`Are you sure you want to delete ${policy?.name}?`}
                    onConfirm={handlers.remove}
                  >
                    <span>Delete</span>
                  </Popconfirm>)
              }
            ]
          }}
        >
          <ActionsDropdownButton />
        </Dropdown>
      </PermissionsGate>
    </>
  )
}

export default PolicyActions
