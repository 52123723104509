import React from 'react'
import { Item as LogRowItem } from 'components/items/item'
import styles from './styles.module.less'
import { extractParsedJson, isJsonString, stripMatchTags, transformJson } from './log-helper'
import PrettyPrinter from 'components/json-pretty/index'
import JsonPrettifier from 'components/json-pretty/prettifier'
import Highlighter from 'react-highlight-words'

const FullLogContent = ({ json, logline, highlighted }) => {
  const isJson = isJsonString(logline)

  if (isJson) return <div className={styles.json_wrapper}><JsonPrettifier jsonObj={extractParsedJson(logline)} collapsed={false} /></div>

  if (!json) {
    return (
      <pre className={styles.full_log}>
        <Highlighter
          autoEscape
          textToHighlight={logline}
          searchWords={highlighted}
          className={styles.log}
          highlightClassName={styles.highlightedLog}
        />
      </pre>
    )
  }

  const beforeJson = <Highlighter
    autoEscape
    textToHighlight={logline.substr(0, logline.indexOf('{'))}
    searchWords={highlighted}
    className={styles.log}
    highlightClassName={styles.highlightedLog}
  />
  const afterJson = <Highlighter
    autoEscape
    textToHighlight={logline.substr(logline.lastIndexOf('}') + 1)}
    searchWords={highlighted}
    className={styles.log}
    highlightClassName={styles.highlightedLog}
  />

  return (
    <div className={styles.full_log}>
      <PrettyPrinter
        beforeJson={beforeJson}
        afterJson={afterJson}
        collapsed={false}
        jsonString={json}
      />
    </div>
  )
}

const LogRow = ({ timestamp, logline, highlighted = [] }) => {
  const strippedString = stripMatchTags(logline)

  const potentialJson = strippedString.substring(strippedString.indexOf('{'), strippedString.lastIndexOf('}') + 1)
  const transformedJson = transformJson(potentialJson)

  let json = null

  try {
    json = transformedJson ? JSON.parse(transformedJson) : JSON.parse(potentialJson)
  } catch { }

  const status = () => {
    const ERROR_TYPES = ['crash', 'error', 'out-of-memory', 'timeout', 'early_exit']
    const messageType = String(json?.messageType).toLowerCase()

    if (messageType.includes('warn')) return 'warning'
    else if (ERROR_TYPES.includes(messageType)) return 'error'
    else return null
  }

  const stringify = (data) => typeof data === 'string' ? data : JSON.stringify(data)

  return (
    <LogRowItem
      gap='sm'
      size='xs'
      className={styles.log_row}
      layout='rows'
      statusBar={status()}
      content={<FullLogContent json={json} logline={logline} highlighted={highlighted} />}
    >
      <span className={styles.timestamp}>{timestamp}</span>
      <Highlighter
        autoEscape
        textToHighlight={stringify(json?.msg || json?.message || logline)}
        searchWords={highlighted}
        className={styles.log}
        highlightClassName={styles.highlightedLog}
      />
    </LogRowItem>
  )
}

export default LogRow
