import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { AwsServiceIcon } from 'components/icons'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Violations from 'features/violations'
import EditableTitle from 'features/editable-title'
import Actions from './actions'
import AlarmCondition from './condition'

import mutations from './mutations'
import { useAccountRoutes } from 'containers/routes'
import { getResourcesByType } from 'lib/resources/filters'

import { findInventoryByType } from 'lib/resources/constants'
import { useAlarmQuery, useAllResourcesQuery, useAlarmsQuery, useResourceGroupsQuery, useAddAlarmTargetQuery, useDeleteAlarmTargetQuery, useDeleteAlarm, useUpdateAlarmQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import AlarmMetrics from './metrics'

const Title = ({ onSubmit, item }) => (
  item
    ? <EditableTitle
      value={item?.name}
      onSubmit={onSubmit}
      displayContent={item?.name}
    />
    : null
)

const AlarmDetails = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { account } = useOrganization()
  const { data: alarm, isLoading: loadingAlarm } = useAlarmQuery()
  const { data: resources, isLoading: loadingResources } = useAllResourcesQuery()
  const { data: violatedAlarms, isLoading: loadingAlarms } = useAlarmsQuery()
  const { data: groups, isLoading: loadingGroups } = useResourceGroupsQuery()
  const { mutate: deleteAlarm } = useDeleteAlarm()
  const { mutate: updateAlarm } = useUpdateAlarmQuery()
  const { mutate: addAlarmTarget } = useAddAlarmTargetQuery()
  const { mutate: deleteAlarmTarget } = useDeleteAlarmTargetQuery()

  const loading = loadingAlarm || loadingResources || loadingGroups || loadingAlarms

  const handlers = mutations({ history, alarm, groups, routes, deleteAlarm, updateAlarm, addAlarmTarget, deleteAlarmTarget })

  const filtered = getResourcesByType(resources, alarm?.resourceType)

  const didMount = useRef(false)
  useEffect(() => {
    if (!account?.id) return
    if (didMount.current) {
      history.push(routes.alarms.url())
    } else didMount.current = true
  }, [account?.id])

  const violations = violatedAlarms?.filter(item => item.rule === alarm?.id)

  const inventoryService = alarm && findInventoryByType(alarm.resourceType)

  return (
    <Content
      loading={loading}
      item={alarm}
      breadcrumbs={['Alarms']}
      backRoute={routes.alarms.url()}
      title={<Title onSubmit={handlers.update.title} item={alarm} />}
      icon={<AwsServiceIcon service={alarm && inventoryService?.service} solid />}
      fixed
      fixedTitle={alarm?.name}
      actions={
        <Actions
          item={alarm}
          onSwitch={handlers.update.status}
          onDelete={handlers.remove}
        />
      }
    >
      <Section>
        <AlarmMetrics alarm={alarm} count={violations?.length} service={inventoryService} />
        <AlarmCondition
          alarm={alarm}
          groups={groups}
          resources={filtered}
          onAddTarget={handlers.targets.add}
          onRemoveTarget={handlers.targets.remove}
          onChange={handlers.update.condition}
        />
      </Section>
      <Violations violations={violations} threshold={alarm?.threshold} />
    </Content>
  )
}

export default AlarmDetails
