import React, { useState, useEffect } from 'react'
import { Input, Select, Button, Tooltip } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { CloseIcon, AddIcon, SpinnerIcon } from 'components/icons'
import { TAG_FILTER, NAME_FILTER } from './constants'

import styles from './styles.module.less'

const AddFilter = ({ title, addFilter, updating }) => {
  const [showFields, setShowFields] = useState(false)
  const [filterType, setFilterType] = useState(TAG_FILTER)
  const [key, setKey] = useState(null)
  const [value, setValue] = useState(null)
  const [payload, setPayload] = useState(null)
  let submitTimer

  const reset = () => {
    setPayload(null)
    setFilterType(TAG_FILTER)
    setKey(null)
    setValue(null)
    setShowFields(false)
  }

  const handleOnBlur = () => {
    if (filterType === TAG_FILTER) {
      if (!key || !value) return
      setPayload({ type: filterType, key, value })
    } else if (filterType === NAME_FILTER) {
      if (!value) return
      setPayload({ type: filterType, value })
    }
  }

  const handleOnFocus = () => {
    submitTimer && clearTimeout(submitTimer)
    setPayload(null)
  }

  useEffect(() => {
    if (!payload) return
    submitTimer = setTimeout(() => {
      addFilter(payload)
      reset()
    }, 100)
    return () => {
      clearTimeout(submitTimer)
    }
  }, [payload])

  if (updating) return <SpinnerIcon />

  if (!showFields) {
    return (
      <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ disabled: true }}>
        <Button type='dashed' className={styles.add_filter_btn} onClick={() => setShowFields(true)}>
          <AddIcon className={styles.icon} />
          <span>{title}</span>
        </Button>
      </PermissionsGate>
    )
  }

  return (
    <Input.Group compact className={styles.add_filter_row} onBlur={handleOnBlur} onFocus={handleOnFocus}>
      <Select defaultValue={TAG_FILTER} className={styles.filter_select} onChange={(value) => setFilterType(value)}>
        <Select.Option value={TAG_FILTER}>Tag</Select.Option>
        <Select.Option value={NAME_FILTER}>Name</Select.Option>
      </Select>
      {filterType === TAG_FILTER &&
        <>
          <Input className={styles.filter_key} placeholder='Key' onChange={(e) => setKey(e?.target?.value)} />
          <Input className={styles.tag_separator} placeholder='/' disabled />
        </>
      }
      <Input
        className={styles.filter_value}
        placeholder='Value'
        onChange={(e) => setValue(e?.target?.value)}
      />
      <Tooltip title='Cancel' className={styles.filter_cancel_btn}>
        <Button icon={<CloseIcon />} onClick={reset} />
      </Tooltip>
    </Input.Group>
  )
}

export default AddFilter
