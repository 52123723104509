import React from 'react'

import Content from 'components/layout/content'
import Delegations from './delegations'

import { useDelegationsQuery } from 'hooks/api/delegations'

const ManageIntegrations = () => {
  const { data: delegations, isLoading } = useDelegationsQuery()

  return (
    <Content item={{}} title='' breadcrumbs={['settings', 'integrations']} loading={isLoading}>
      <Delegations loading={isLoading} delegations={delegations} />
    </Content>
  )
}

export default ManageIntegrations
