import React, { createContext, useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { isAfter, parseISO, sub } from 'date-fns'
import sha1 from 'sha1'
import uuidv4 from 'uuid/v4'
import { parse } from '@dashbird/dashql'

import useQueryParams from 'lib/hooks/use-query-params'
import { ContentState, EditorState } from 'draft-js'
import { decorator } from 'containers/search/details/form/query-editor'
import { useGetQueryQuery } from 'hooks/api/queries'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { useOrganization } from 'hooks/context/organization-context'

export const SearchContext = createContext({
  recentQueries: [],
  setRecentQueries: () => { },
  selected: '',
  setSelected: () => { },
  currentQuery: {},
  setCurrentQuery: () => { }
})

export const SEARCH_HISTORY = 'search_history'
export const SEARCH_HISTORY_TTL_DAYS = 14
export const SEARCH_HISTORY_MAX = 15

const SearchContainer = ({ children, form }) => {
  const { data: query } = useGetQueryQuery()
  const { getValue: urlQuery, setValue: setUrlQuery } = useQueryParams('query', '')
  const { account } = useOrganization()

  const [recentQueries, setRecentQueries] = useState([])
  const [selected, setSelected] = useState('')
  const [currentQuery, setCurrentQuery] = useState({})
  const [keywords, setKeywords] = useState([])
  const [errorMsg, setErrorMsg] = useState(false)

  const [editorState, _setEditorState] = useState(() => EditorState.createEmpty(decorator))
  const queryRef = useRef(editorState?.getCurrentContent()?.getPlainText() || '')

  const findKeywords = (action, keywords = []) => {
    if (typeof action === 'string') {
      // remove quotes
      return [action.replace(/["']/g, '')]
    }
    if (action?.op === 'not') return []
    const children = []
    for (const value of (action?.values || [])) {
      children.push(...findKeywords(value, keywords))
    }
    return [...keywords, ...children]
  }

  const validateQuery = (query = queryRef.current) => {
    const result = parse(query)

    if (!isEmpty(result?.errors)) {
      setErrorMsg(result)
      setKeywords([])
      return { isValid: false, commands: [], keywords: [] }
    }

    const keywords = []
    const commands = result?.commands?.filter(command => command.type === 'search')
    for (const command of (commands || [])) {
      keywords.push(...findKeywords(command.spec))
    }

    setErrorMsg(false)
    setKeywords(keywords)
    return { isValid: true, commands, keywords }
  }

  const setEditorState = newEditorState => {
    queryRef.current = newEditorState?.getCurrentContent().getPlainText()
    _setEditorState(newEditorState)
  }

  const getSearchHistoryFromStorage = () => {
    const searchHistory = JSON.parse(localStorage.getItem(SEARCH_HISTORY || '[]'))

    if (isEmpty(searchHistory)) return { filtered: [], all: [] }

    const filtered = searchHistory[account.id] || []
      .filter(item => isAfter(parseISO(item.updatedAt), sub(new Date(), { days: SEARCH_HISTORY_TTL_DAYS })))
      .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0))

    return { filtered, all: searchHistory }
  }

  const getQueryHash = (query) => {
    return sha1(JSON.stringify({
      query: query.query,
      service: query.service,
      targets: query.targets
    }))
  }

  const handleSetLocalStorage = (values) => {
    const item = { ...values, updatedAt: new Date().toISOString(), id: uuidv4(), hash: getQueryHash(values) }
    const { filtered: recent, all } = getSearchHistoryFromStorage()

    // check for duplicates
    const recentIndex = recent.findIndex(recentQuery => {
      if (!recentQuery.hash) {
        recentQuery.hash = getQueryHash(recentQuery)
      }

      return recentQuery.hash === item.hash
    })

    if (isEmpty(recent)) {
      setRecentQueries([item])
      localStorage.setItem(SEARCH_HISTORY, JSON.stringify({ ...all, [account.id]: [item] }))
    } else if (recentIndex !== -1) {
      recent.splice(recentIndex, 1)
      const updated = [item, ...recent]
      setRecentQueries(updated)
      localStorage.setItem(SEARCH_HISTORY, JSON.stringify({ ...all, [account.id]: updated }))
    } else if (recent?.length >= SEARCH_HISTORY_MAX) {
      const updated = [item, ...recent.slice(0, SEARCH_HISTORY_MAX - 1)]
      setRecentQueries(updated)
      localStorage.setItem(SEARCH_HISTORY, JSON.stringify({ ...all, [account.id]: updated }))
    } else {
      const updated = [item, ...recent]
      setRecentQueries(updated)
      localStorage.setItem(SEARCH_HISTORY, JSON.stringify({ ...all, [account.id]: updated }))
    }

    setUrlQuery(JSON.stringify(item))
  }

  const handleClearHistory = () => {
    const searchHistory = JSON.parse(localStorage.getItem(SEARCH_HISTORY || '{}'))
    delete searchHistory[account.id]
    localStorage.setItem(SEARCH_HISTORY, JSON.stringify(searchHistory))
    setRecentQueries([])
  }

  const setInitialHistoryItems = () => {
    const { filtered, all } = getSearchHistoryFromStorage()
    localStorage.setItem(SEARCH_HISTORY, JSON.stringify({ ...all, [account.id]: filtered }))
    setRecentQueries(filtered)
  }

  const handleUrlQueryChange = () => {
    const parsedQuery = JSON.parse(urlQuery || '{}')
    setSelected(parsedQuery?.id)
    setCurrentQuery(parsedQuery)
    const stateWithContent = EditorState.createWithContent(ContentState.createFromText(parsedQuery?.query || ''), decorator)
    setEditorState(EditorState.moveFocusToEnd(stateWithContent))
    if (form) form.setFieldsValue({ query: parsedQuery?.query, targets: parsedQuery?.targets || [], service: parsedQuery?.service || INVENTORY_SERVICES.Lambda.service })
  }

  const handleQueryChange = () => {
    setSelected(query?.id)
    setCurrentQuery(query)
    validateQuery(query?.query)
    const stateWithContent = EditorState.createWithContent(ContentState.createFromText(query?.query || ''), decorator)
    setEditorState(EditorState.moveFocusToEnd(stateWithContent))
    if (form) form.setFieldsValue({ query: query?.query, targets: query?.targets || [], service: query?.service || INVENTORY_SERVICES.Lambda.service })
  }

  const setFormValues = () => {
    if (isEmpty(urlQuery)) return handleQueryChange()

    handleUrlQueryChange()
  }

  useEffect(setInitialHistoryItems, [account?.id])
  useEffect(setFormValues, [urlQuery, query])

  return (
    <SearchContext.Provider value={{ recentQueries, handleClearHistory, handleSetLocalStorage, selected, setSelected, editorState, currentQuery, setEditorState, queryRef, keywords, setKeywords, errorMsg, validateQuery, findKeywords }} >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContainer
