import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'APIGATEWAY_REQUESTS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'requests',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'APIGATEWAY_ERRORS_5XX',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.5xx',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'APIGATEWAY_ERRORS_4XX',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.4xx',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'APIGATEWAY_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'APIGATEWAY_LATENCY_INTEGRATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency.integration',
    stats: [STATISTICS.AVERAGE]
  }
}]

export default metrics
