import React, { useState, useEffect } from 'react'
import { Typography, Row, Col } from 'antd'
import { get, sum } from 'lodash'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text, Title } = Typography

const TitleRow = ({ title, main } = {}) => {
  const child = main ? <Title level={4}>{title}</Title> : <Text strong>{title}</Text>
  return (
    <Row gutter={[0, 16]}>
      <Col>{child}</Col>
    </Row>
  )
}

const Total = ({ amount, currency, info } = {}) => {
  return (
    <Row gutter={[0, 16]}>
      <Col>
        <Row type='flex' justify='space-between'>
          <Col><Title level={4}>{formatStripePrice(amount, currency)}</Title></Col>
          <Col>{info ? <Text type='secondary'>{info}</Text> : null }</Col>
        </Row>
      </Col>
    </Row>
  )
}

const Totals = ({ resources, dataIngested, prices = {}, info } = {}) => {
  const inventoryPrice = get(prices, 'inventory.amount', 0)
  const dataIngestionPrice = get(prices, 'dataIngestion.amount', 0)
  const currency = get(prices, 'inventory.currency')

  const [resourceCount, setResourceCount] = useState(0)
  const [usage, setUsage] = useState(0)
  const [resourceTotal, setResourceTotal] = useState(0)
  const [usageTotal, setUsageTotal] = useState(0)

  useEffect(() => {
    const counts = Object.values(resources).map(resource => resource.enabled ? resource.count : 0)
    const resourceCount = counts.length ? sum(counts) : 0
    setResourceCount(resourceCount)
    setResourceTotal(resourceCount * inventoryPrice)
  }, [resources, prices])

  useEffect(() => {
    const usages = Object.values(dataIngested).map(resource => resource.estimatedNotFixed)
    const usage = usages.length ? sum(usages) : 0
    setUsage(parseFloat(usage).toFixed(2))
    setUsageTotal(usage * dataIngestionPrice)
  }, [dataIngested, prices])

  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(resourceTotal + usageTotal)
  }, [resourceTotal, usageTotal])

  return (
    <Row type='flex' justify='center' gutter={64}>
      <Col>
        <TitleRow title='Inventory' />
        <TitleRow title='Data ingestion' />
        <TitleRow title='Total' main />
      </Col>
      <Col>
        <Row gutter={[0, 16]}>
          <Col>
            <Text strong>{`${resourceCount} resources x ${formatStripePrice(inventoryPrice, currency)} = ${formatStripePrice(resourceTotal, currency)}`}</Text>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col>
            <Text strong>{`${usage} GB x ${formatStripePrice(dataIngestionPrice, currency)} = ${formatStripePrice(usageTotal, currency)}`}</Text>
          </Col>
        </Row>
        <Total amount={total} currency={currency} info={info} />
      </Col>
    </Row>
  )
}

export default Totals
