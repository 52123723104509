import React from 'react'
import moment from 'moment'
import { Tooltip } from 'recharts'
import { tooltipProps } from 'components/charts/utils'
import colors from 'lib/colors'

const Content = ({ data, className, activeBar, heading, value }) => {
  const { active, payload, label } = data[0]
  if (!active || !activeBar) return null

  const stack = payload?.find(item => item.dataKey === activeBar)
  if (!stack) return null

  return (
    <div className={className}>
      <p style={{ color: colors('chart', 'text') }}>{moment(label).format('DD MMM')}</p>
      <p style={{ color: stack.color }}>{stack.name}</p>
      <p>{heading + value(stack.value)}</p>
    </div>
  )
}

const useTooltip = ({ className, activeBar, heading, value }) => {
  return (
    <Tooltip
      cursor={false}
      content={(...data) => (
        <Content data={data} className={className} activeBar={activeBar} heading={heading} value={value} />
      )}
      {...tooltipProps}
    />
  )
}

export default useTooltip
