import React from 'react'
import { Button } from 'antd'
import styles from './styles.module.less'

const LoadMore = ({ show, setShow }) => {
  return (
    show && <Button
      type='link'
      onClick={() => setShow(false)}
      className={styles.more}
    >
      Load more
    </Button>
  )
}

export default LoadMore
