import React from 'react'
import { Row, Col, Tooltip } from 'antd'

const formatDate = date => {
  return date.format('MMMM Do YYYY')
}

const BillingPeriod = ({ start, end, showTitle = true, centered = false, tooltip, className }) => {
  return (
    <Row
      type='flex'
      justify={centered ? 'center' : 'start'}
      className={className}
    >
      {showTitle ? <Col span={3}><b>Billing Period:</b></Col> : ''}
      <Tooltip title={tooltip ? 'Billing period' : ''}>
        <Col>{`${formatDate(start)} - ${formatDate(end)}`}</Col>
      </Tooltip>
    </Row>
  )
}

export default BillingPeriod
