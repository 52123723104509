import React from 'react'
import { Typography, Table, InputNumber } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const DataIngestionCalculator = ({ dataIngested, price = {}, changeDataIngested } = {}) => {
  const columns = [
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'type'
    },
    {
      title: 'Usage (GB)',
      dataIndex: 'estimatedNotFixed',
      key: 'estimatedNotFixed',
      render: (value, data) => {
        return (
          <InputNumber
            min={0}
            defaultValue={value}
            onChange={(val) => changeDataIngested(data.type, val)}
          />
        )
      }
    },
    {
      title: 'Price per GB',
      key: 'price',
      render: () => <Text>{formatStripePrice(price.amount, price.currency)}</Text>
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={Object.values(dataIngested)}
      pagination={false}
    />
  )
}

export default DataIngestionCalculator
