import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { flatten, orderBy } from 'lodash'

import { useAccountRoutes } from 'containers/routes'
import { useAlarmsRulesQuery, useAllResourcesQuery, useResourceGroupsQuery } from 'hooks/api'
import { SplitViewContext } from 'features/layout/split-pane'

import useQueryParams, { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { getInventoryResources } from 'lib/resources/filters'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListHeader from 'features/side-list/header'
import ListSearch from 'features/side-list/search'
import { NameAlphabetical, Namespace, SeverityCount, Status } from 'features/side-list/sorter'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import AlarmsListItem from './item'
import { ALARMS_FILTERS } from './filters'
import AddNewAlarm from './header'

const AlarmsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { alarmId: selected } = useParams()
  const { getValue: groupsInUrl } = useQueryParams('group', [])
  const { handleListClose } = useContext(SplitViewContext)

  const { data: alarmRules, isLoading } = useAlarmsRulesQuery()
  const { data: resourceGroups } = useResourceGroupsQuery()
  const { data: resourcesAll } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)

  const loading = isLoading || !alarmRules

  const sorters = [SeverityCount, NameAlphabetical, Namespace, Status]
  const [sorter, setSorter] = useState(sorters[0])
  const [searchItems, setSearchItems] = useState(null)

  const onSelect = (identity) => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })

    history.push({ pathname: routes.alarms.alarm.url({ alarmId: identity }), search: newValue })
    handleListClose()
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const searchUrl = () => {
    if (!alarmRules) return

    const groupsResourceIds = flatten(groupsInUrl?.map(id => resourceGroups?.find(item => item.id === id)?.resources))
    const results = filterListFromQuery(alarmRules, ALARMS_FILTERS, search, groupsResourceIds)
    setSearchItems(sortItems(results))
  }

  const sortItems = (items) => {
    return sorter.sort
      ? sorter.sort(items)
      : orderBy(items, sorter.apply, (sorter?.order || 'asc'))
  }

  const sortAlarms = () => {
    if (!searchItems) return

    const sortedResults = sortItems(searchItems)
    setSearchItems(sortedResults)
  }

  useEffect(searchUrl, [search, alarmRules, resources?.length])
  useEffect(sortAlarms, [sorter])

  return (
    <List
      loading={loading}
      items={searchItems}
      selected={selected}
      renderItem={AlarmsListItem}
      onSelect={(item) => onSelect(item.id)}
      search={<ListSearch />}
      header={
        <ListHeader
          sorters={sorters}
          onSorterChange={onSorterChange}
          selectedSorter={sorter.key}
          total={alarmRules?.length}
          searchTotal={searchItems?.length}
          overviewURL={routes.alarms.url()}
          extra={<AddNewAlarm />}
          filters={ALARMS_FILTERS.reduce((acc, item) => {
            acc[item.key] = item.key
            return acc
          }, {})}
          view='alarms'
        />
      }
      renderEmpty={<ListEmpty title='No alarms found' />}
    />
  )
}

export default AlarmsList
