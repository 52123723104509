import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SNS_NOTIFICATIONS } from 'containers/inventory/details/resource/metrics-tabs/services/sns'

const NotificationsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [SNS_NOTIFICATIONS]: data.SNS_NOTIFICATIONS_DELIVERED,
    notificationsFailed: data.SNS_NOTIFICATIONS_FAILED,
    notificationsFiltered: data.SQS_NOTIFICATIONS_FILTERED
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={SNS_NOTIFICATIONS}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar stackId='a' dataKey='notificationsFailed' fill={colors('chart', 'red')} name='Failed' barSize={4} />
      <Bar stackId='a' dataKey='notificationsFiltered' fill={colors('chart', 'primaryLight')} name='Filtered' barSize={4} />
      <Bar stackId='a' dataKey={SNS_NOTIFICATIONS} fill={colors('chart', 'primary')} barSize={4} name='Delivered' dot={false} />
    </ChartContainer>
  )
}

export default NotificationsChart
