import React, { useEffect, useRef, useState } from 'react'
import { onLocationChanged } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import { Layout as AntdLayout } from 'antd'
import classnames from 'classnames'

import AlertBar from './alert-bar'
import Navigation from './navigation'
import PageError from './page-error'
import MobileHeader from './mobile-header'

import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import ViewContainer from 'hooks/context/view-context'

import styles from './styles.module.less'

const { Content } = AntdLayout

const Layout = ({ children }) => {
  const scrollRef = useRef()
  const { user } = useUser()
  const { organization, account: currentAccount, accountError } = useOrganization()
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    onLocationChanged(() => {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    })
  }, [])

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser({ email: user?.username })
      scope.setTag('organization_name', organization?.name)
    })
  }, [])

  return (
    <ViewContainer scrollRef={scrollRef}>
      <AntdLayout className={classnames(styles.layout, { [styles.collapsed]: !!collapsed })}>
        {/* Header in mobile view */}
        <MobileHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <AntdLayout className={styles.mobile_layout}>
          {organization?.onboarded && (<>
            <Navigation collapsed={collapsed} setCollapsed={setCollapsed} className={styles.navbar} /></>
          )}
          <Content className={classnames(styles.main_content, { [styles.onboarding]: !organization?.onboarded })} ref={scrollRef}>
            {currentAccount && <AlertBar />}
            {!accountError
              ? (
                <Sentry.ErrorBoundary fallback={({ resetError }) => <PageError reset={resetError} />}>
                  {children}
                </Sentry.ErrorBoundary>)
              : <PageError accountError />}
          </Content>
        </AntdLayout>
      </AntdLayout >
    </ViewContainer>
  )
}

export default Layout
