import React from 'react'

import ListItem from 'features/side-list/item'
import { DashboardIcon } from 'components/icons'

import styles from './styles.module.less'

const DashboardListItem = ({ data, index, style }) => {
  const item = data?.items[index]

  return (
    <div style={style}>
      <ListItem
        key={item.id}
        icon={<DashboardIcon size='2x' className={styles.list_icon} />}
        title={item.name}
        selected={item.id.toString() === data?.selected}
        onClick={() => data?.onSelect(item)}
      />
    </div>
  )
}

export default DashboardListItem
