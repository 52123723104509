import React from 'react'
import { Row } from 'antd'
import { get, filter } from 'lodash'

import Loading from 'components/layout/content/loading'
import countries from 'components/billing/countries'

import Column from 'components/billing/stripe/address-column'
import Empty from 'components/billing/stripe/empty'

const country = (code) => {
  const item = countries.find((country) => country.value === code)
  if (item) {
    return item.label
  }

  return 'Unknown country'
}

const contactData = customer => {
  return customer ? filter([{
    key: 'Contact name',
    value: customer.name
  }, {
    key: 'Email',
    value: customer.email
  }]) : []
}

const addressData = customer => {
  return customer ? filter([{
    key: 'Street',
    value: get(customer, 'address.street')
  }, {
    key: 'City',
    value: get(customer, 'address.city')
  }, {
    key: 'Zip code',
    value: get(customer, 'address.zip')
  }, {
    key: 'State',
    value: get(customer, 'address.state')
  }, {
    key: 'Country',
    value: country(get(customer, 'address.country'))
  }, customer.vat && {
    key: 'Vat',
    value: customer.vat
  }]) : []
}

const Details = ({ customer, loading, showOverlay }) => {
  if (loading) {
    return <Loading theme='light' />
  }

  if (!customer || !customer.address) {
    return <Empty onClick={showOverlay} text='Billing info has not been entered yet. Click here to add it.' />
  }

  const left = contactData(customer)
  const right = addressData(customer)
  return (
    <>
      <Row>
        <Column data={left} />
        <Column data={right} />
      </Row>
    </>
  )
}

export default Details
