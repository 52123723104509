import { forEach, map, merge, reduce, sortBy } from 'lodash'

const getEmpty = keys => {
  return merge({}, ...map(keys, key => { return { [key]: 0 } }))
}

const transform = (metrics, transformValue = value => value) => {
  const series = Object.keys(metrics)
  const empty = getEmpty(series)

  const transformed = reduce(series, (timeseries, series) => {
    forEach(metrics[series], entry => {
      const date = entry.start
      if (!timeseries[date]) timeseries[date] = { date, ...empty }
      timeseries[date][series] = transformValue(entry.value)
    })
    return timeseries
  }, {})

  return sortBy(Object.values(transformed), 'date')
}

export default transform
