import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { times } from 'lodash'
import { Skeleton } from 'antd'
import classnames from 'classnames'

import Breadcrumbs from 'components/breadcrumbs'
import { PageHeader } from 'components/page-header'
import FixedHeader from 'components/layout/fixed-header'
import { Breakpoint, useResize } from 'hooks/resize'

import styles from './styles.module.less'
import { ViewContext } from 'hooks/context/view-context'

export const ScrollContext = createContext({ width: 0 })

const renderContent = (content, item) => {
  if (typeof content === 'function') {
    return content(item)
  }
  return content
}

const renderEmptyContent = (render) => {
  if (!React.isValidElement(render)) return render()
  if (React.isValidElement(render)) return render
  return null
}

const Actions = ({ actions, item }) => {
  const content = renderContent(actions, item)
  if (!content) return null
  return <div className={styles.actions}>{content}</div>
}

const Loading = () => (
  <>
    <div style={{ height: '12px' }} />
    {
      times(4).map(index => (
        <Skeleton key={index} active title={false} paragraph={{ rows: 4 }} className={styles.skeleton} />
      ))
    }
  </>
)

const Content = ({
  loading,
  title,
  subtitle,
  icon,
  item,
  breadcrumbs = [],
  breadcrumbsLoading,
  backRoute,
  actions,
  error,
  renderEmpty,
  className,
  fixed,
  fixedTitle,
  drawer,
  bordered,
  HEADER_HEIGHT = 70,
  onDrawerClose,
  titleRowActions,
  hideTitleActions,
  children
}) => {
  const contentRef = useRef()
  const { scrollRef, scrollTop } = useContext(ViewContext)
  const [showFixed, setShowFixed] = useState(false)
  const [width, setWidth] = useState(0)
  const { isVisibleFrom } = useResize()

  const fixedHeaderEnabled = isVisibleFrom(Breakpoint.md)

  useEffect(() => {
    if (!contentRef?.current) return
    const resizeObserver = new ResizeObserver(() => {
      setWidth(contentRef?.current?.offsetWidth)
    })
    resizeObserver.observe(contentRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (!fixed) return
    setShowFixed(scrollTop > HEADER_HEIGHT)
  }, [scrollTop])

  const handleScrollTop = () => {
    if (!scrollRef?.current) return
    scrollRef.current.scrollTop = 0
  }

  if (error) return error
  if (!loading && !item) return renderEmptyContent(renderEmpty)
  const shouldRenderHeader = item && (!!title || !!actions) && !drawer

  return (
    <ScrollContext.Provider value={{ fixed: showFixed && fixedHeaderEnabled, width, handleScrollTop }}>
      {(drawer || (showFixed && fixed && fixedHeaderEnabled)) && (
        <FixedHeader
          title={fixedTitle || title}
          breadcrumbs={breadcrumbs}
          icon={icon}
          onClose={onDrawerClose}
          bordered={bordered}
          topBarActions={!drawer && actions}
          titleBarActions={!hideTitleActions && titleRowActions} />
      )}
      <div className={classnames(styles.content, { [styles.drawer]: drawer }, className)} ref={contentRef}>
        {!drawer && (
          <div className={styles.top_wrapper}>
            <Breadcrumbs breadcrumbs={breadcrumbs} backRoute={backRoute} loading={breadcrumbsLoading} />
            <Actions actions={actions} item={item} />
          </div>
        )}
        {shouldRenderHeader &&
          <PageHeader
            icon={icon}
            title={title}
            subtitle={subtitle}
            extra={titleRowActions}
          />}
        {loading
          ? <Loading />
          : children}
      </div>
    </ScrollContext.Provider>
  )
}

export default Content
