import { Auth } from '@aws-amplify/auth'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useUser } from 'hooks/context/user-context'
import { MSG_RESENT_VERIFICATION_LINK } from 'containers/auth/messages'

export const useResendSignUp = () => {
  const { setAuthMessage } = useUser()
  const [successMessage, setSuccessMessage] = useState(MSG_RESENT_VERIFICATION_LINK)

  return useMutation(
    ({ email, message }) => {
      if (message) setSuccessMessage(message)

      return Auth.resendSignUp(email)
    },
    {
      onSuccess: () => {
        setAuthMessage(successMessage)
      },
      onError: (error) => {
        setAuthMessage({ title: 'Some error occurred', message: error.message, type: 'error' })
      }
    }
  )
}
