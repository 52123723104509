import React from 'react'
import { useParams } from 'react-router-dom'
import { Switch, Select } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { useDelegationsQuery, useIntegrationQuery, useProviderRegionsQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'

import { AwsServiceIcon } from 'components/icons'
import { Typography } from 'components/typography'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Regions from '../polling-regions'
import Filters from '../polling-filters'
import ResourceList from './resource-list'

import { POLLING_INTERVALS } from './intervals'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import { useMutations } from './hooks'

const Integration = () => {
  const { delegationId } = useParams()
  const routes = useAccountRoutes()

  const handlers = useMutations()
  const { data: integration, isLoading: loadingIntegration } = useIntegrationQuery()
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()
  const { data: regions, isLoading: isLoadingRegions } = useProviderRegionsQuery({ provider: integration?.provider }, { skip: !integration })

  const availableRegions = regions?.[integration?.service] || []
  const loading = loadingIntegration || loadingDelegations || !integration || isLoadingRegions

  const activeDelegation = delegations?.find(item => item.id === integration?.delegation?.id)
  const delegationName = activeDelegation?.name ? activeDelegation?.name : activeDelegation?.provider?.account

  const handleRegionChange = (regions) => {
    const filters = { ...integration?.filters, regions }
    handlers.update.regions(filters)
  }

  const handleFiltersChange = ({ whitelist, blacklist }) => {
    const filters = {
      ...integration?.filters,
      ...(whitelist && { whitelist }),
      ...(blacklist && { blacklist })
    }
    handlers.update.filters(filters)
  }

  const title = !loadingIntegration && `${INVENTORY_SERVICE_GROUPS[integration?.service]?.title} | ${delegationName}`

  return (
    <Content
      item
      loading={loading}
      title={title}
      breadcrumbs={['settings', 'integrations']}
      backRoute={routes.settings.integrations.delegation.url({ delegationId })}
      icon={!loadingIntegration && <AwsServiceIcon service={integration?.service} solid />}
      actions={
        <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ disabled: true }}>
          <Switch
            onChange={(value) => handlers?.update?.status(!value)}
            loading={handlers.update.isUpdatingStatus}
            defaultChecked={integration && !integration?.disabled}
            checked={!integration?.disabled}
            disabled={integration?.suspended}
          />
        </PermissionsGate>
      }
    >
      <Section
        title='Polling interval'
        titleUpperCase
      >
        <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ disabled: true }}>
          <Select
            options={POLLING_INTERVALS.map(item => ({ value: item.value, label: item.title }))}
            onChange={(value) => handlers?.update.interval(value)}
            value={integration?.interval || 30}
            loading={handlers.update.isUpdatingInterval || loadingIntegration}
          />
        </PermissionsGate>
      </Section>
      <Section
        title='Regions'
        titleUpperCase
      >
        <Regions
          regions={integration?.filters?.regions}
          parentRegions={integration?.boundaries?.regions}
          availableRegions={availableRegions}
          handleChange={handleRegionChange}
          updating={handlers.update.isUpdatingRegions || loadingIntegration}
          errors={integration?.errors}
        />
      </Section>
      <Section
        title='Filter resources'
        titleUpperCase
      >
        <Filters
          whitelist={integration?.filters?.whitelist}
          blacklist={integration?.filters?.blacklist}
          parentWhitelist={integration?.boundaries?.whitelist}
          parentBlacklist={integration?.boundaries?.blacklist}
          handleChange={handleFiltersChange}
          updating={handlers.update.isUpdatingFilters || loadingIntegration}
        />
      </Section>
      <Section
        title='Preview'
        titleUpperCase
      >
        <Typography.Paragraph>Filter changes take effect during the next polling cycle.</Typography.Paragraph>
        <ResourceList integration={integration} />
      </Section>
    </Content>
  )
}

export default Integration
