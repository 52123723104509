import React from 'react'
import { Form } from 'antd'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import { AlertIcon, ArrowLeftIcon, SearchIcon, SigmaIcon } from 'components/icons/font-awesome'
import SearchContext from 'hooks/context/search-context'
import Metrics from 'components/metrics'
import QueryForm from './query-form'
import { useAccountRoutes } from 'containers/routes'

const metrics = () => [
  { title: 'Events', icon: <SigmaIcon />, value: '...' },
  { title: 'Configure', icon: <SearchIcon />, value: '<query>', selected: true },
  { title: 'Severity', icon: <AlertIcon />, value: 'info' },
  { title: 'Resources', icon: <ArrowLeftIcon />, value: 'ALL' }
]

const NewScheduledQuery = () => {
  const [form] = Form.useForm()
  const routes = useAccountRoutes()

  return (
    <>
      <SearchContext form={form}>
        <Content item title='<new query>' breadcrumbs={['scheduled search']} backRoute={routes.scheduledSearch.url()}>
          <Section cancelMargin>
            <Metrics items={metrics()} />
          </Section>
          <QueryForm ref={form} service='lambda' />
        </Content>
      </SearchContext>
    </>
  )
}

export default NewScheduledQuery
