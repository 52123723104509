import React from 'react'
import { ceil } from 'lodash'
import { Line, Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { LAMBDA_CONCURRENCY } from 'containers/inventory/details/resource/metrics-tabs/services/lambda'

const ConcurrencyChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [LAMBDA_CONCURRENCY]: data.INVOCATIONS_CONCURRENCY_MAX,
    throttles: data.INVOCATIONS_THROTTLES_SUM
  }, ceil)

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={LAMBDA_CONCURRENCY}
      formatTab={value => value}
    >
      <Line type='monotone' dataKey={LAMBDA_CONCURRENCY} stroke={colors('chart', 'primary')} strokeWidth={1} name='Concurrency' dot={false} />
      <Bar dataKey='throttles' stackId='a' fill={colors('chart', 'red')} name='Throttles' barSize={4} />
    </ChartContainer>
  )
}

export default ConcurrencyChart
