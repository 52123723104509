export const TIERS = {
  trial: 'trial',
  free: 'free',
  pro: 'pro',
  enterprise: 'enterprise'
}

export const STATUSES = {
  normal: 'normal',
  trial: 'trial',
  suspended: 'suspended'
}

export const tiersInfo = [{
  id: TIERS.free,
  title: 'Developer',
  price: '$0',
  priceTitle: 'Free forever',
  subTitle: 'New to serverless plan',
  text: 'A perfect serverless monitoring tool with built in failure detection for your private projct',
  invocations: '1M invocations per month',
  list: {
    title: 'Free plan includes:',
    items: [
      'Access to all aws managed services',
      '1 AWS account',
      'Lambda monitoring',
      'Infrastructure monitoring',
      'Failure detection',
      'Log analytics',
      'Full-text search',
      'Well-Architected Lens',
      '5 microservice dashboards',
      'Unlimited users',
      'Email and Slack notifications',
      'Multi-factor authentication (MFA)',
      'Email/chat support'
    ]
  }
}, {
  id: TIERS.pro,
  title: 'Professional',
  price: '$99/mo',
  priceTitle: 'Starting from',
  subTitle: 'Large serverless environments',
  text: 'Ideal when scaling your serverless infrastructure',
  invocations: 'Up to 15M invocations per month',
  list: {
    title: 'Basic plan, Plus:',
    items: [
      'Access to all aws managed services',
      'Unlimited AWS accounts',
      'Automatic insights',
      'Metric alarms',
      'Unlimitd microservice dashboards',
      'All notification channels',
      'All integrations'
    ]
  }
}, {
  id: TIERS.enterprise,
  title: 'Enterprise',
  price: '',
  priceTitle: 'Contact us',
  subTitle: 'High-volume infrastructure',
  text: 'Custon pricing and enterprise-grade features',
  invocations: 'Custom pricing',
  list: {
    title: 'Professional plan, plus',
    items: [
      'Single sign-on (SSO)',
      'Service level agreement (SLA)',
      'Advanced insights and alarms',
      'Custom event tracking',
      'Enterprise level support',
      'Tailored pricing'
    ]
  }
}]
