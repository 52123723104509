import React, { useRef, useEffect } from 'react'
import { Typography, Form } from 'antd'

import GenericFormButton from 'components/antd/form/generic-form-button'
import Submit from 'components/antd/form/submit'
import Input from 'components/antd/input'

import styles from './styles.module.less'

const MFAVerification = ({ loading, disabled, onSubmit, onCancel, error, login }) => {
  const mfaRef = useRef()
  const { Text } = Typography

  useEffect(() => {
    if (!mfaRef?.current) return
    mfaRef.current.focus()
  }, [mfaRef?.current])

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return (
    <Form onFinish={onSubmitWrapper} layout='vertical' requiredMark={login ? 'optional' : '*'}>
      <Form.Item label='Code' name='code' rules={[{ required: true, message: 'Please submit your MFA mfa-verification code' }]}>
        <Input solid ref={mfaRef} autoComplete="on" />
      </Form.Item>

      {error && <div className={styles.error}>
        <Text type='danger'>{error}</Text>
      </div>}

      {onCancel && <GenericFormButton onClick={onCancel} text='Cancel' />}
      <Submit loading={loading} text={login ? 'Sign in' : 'Verify'} disabled={disabled} titleUpperCase />
    </Form>
  )
}

export default MFAVerification
