import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

import NextPayments from 'components/billing/stripe/next-payments'

import {
  GET_SUBSCRIPTIONS,
  POST_SUBSCRIPTION,
  PUT_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_ITEM,
  GET_SUBSCRIPTION_INVOICE,
  GET_PRICES
} from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrganization } from 'hooks/context/organization-context'

const getOnDemandTriggers = (subscriptions = {}) => {
  const subscription = subscriptions.onDemand || {}
  const id = subscription.stripeId
  const discountId = get(subscription, 'discount.coupon.stripeId')
  return [id, discountId]
}

const getReservedTriggers = (subscriptions = {}) => {
  const subscription = subscriptions.reserved || {}
  const id = subscription.stripeId
  const discountId = get(subscription, 'discount.coupon.stripeId')
  const inventoryCount = get(subscription, 'items.inventory.quantity')
  const dataIngestionCount = get(subscription, 'items.dataIngestion.quantity')
  return [id, discountId, inventoryCount, dataIngestionCount]
}

const UpcomingPayments = () => {
  const dispatch = useDispatch()

  const loadingListSubscriptions = useSelector(state => state.waiting.list[GET_SUBSCRIPTIONS])
  const loadingCreateSubscription = useSelector(state => state.waiting.list[POST_SUBSCRIPTION])
  const loadingUpdateSubscription = useSelector(state => state.waiting.list[PUT_SUBSCRIPTION])
  const loadingDeleteSubscription = useSelector(state => state.waiting.list[DELETE_SUBSCRIPTION])
  const loadingDeleteSubscriptionItem = useSelector(state => state.waiting.list[DELETE_SUBSCRIPTION_ITEM])
  const loadingSubscriptions = loadingListSubscriptions || loadingCreateSubscription || loadingUpdateSubscription || loadingDeleteSubscription || loadingDeleteSubscriptionItem
  const loadingInvoices = useSelector(state => state.waiting.list[GET_SUBSCRIPTION_INVOICE])
  const loadingPrices = useSelector(state => state.waiting.list[GET_PRICES])
  const loading = loadingSubscriptions || loadingInvoices || loadingPrices

  const { organization } = useOrganization()
  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const invoices = useSelector(state => get(state, 'billing.subscriptions.invoices'))
  const prices = useSelector(state => get(state, 'billing.prices.list'))

  const onDemandId = get(subscriptions, 'onDemand.stripeId')
  const reservedId = get(subscriptions, 'reserved.stripeId')

  useEffect(() => {
    if (!organization.id || !onDemandId) return
    dispatch(API.organizations.id(organization.id).billing.subscriptions.id(onDemandId).invoice.get())
  }, [organization.id, ...getOnDemandTriggers(subscriptions)])

  useEffect(() => {
    if (!organization.id || !reservedId) return
    dispatch(API.organizations.id(organization.id).billing.subscriptions.id(reservedId).invoice.get('reserved'))
  }, [organization.id, ...getReservedTriggers(subscriptions)])

  return (
    <NextPayments
      onDemandInvoice={(invoices || {}).onDemand}
      reservedInvoice={(invoices || {}).reserved}
      prices={get(prices, organization.tier)}
      loading={loading}
    />
  )
}

export default UpcomingPayments
