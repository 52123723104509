import Amplify from '@aws-amplify/auth'

export default () => {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: CONFIG.AWS_COGNITO_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: CONFIG.AWS_COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: CONFIG.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: CONFIG.AWS_COGNITO_USER_POOL_AUTHENTICATION_FLOW_TYPE,

      oauth: {
        domain: CONFIG.AWS_COGNITO_OAUTH_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: CONFIG.AWS_COGNITO_OAUTH_REDIRECT_URI,
        redirectSignOut: CONFIG.AWS_COGNITO_OAUTH_REDIRECT_URI,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    }
  })
}
