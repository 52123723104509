import { throwAsyncError } from 'hooks/nodes/transform/error'
import { isRequest, isRuntime } from 'hooks/nodes/transform/checkers'

const getRequestData = (node) => {
  const getDefault = () => ({
    method: node.http?.request?.method,
    url: node.http?.request?.url,
    response: node.http?.response?.status,
    responseSize: node.http?.response?.content_length
  })

  switch (node.name) {
    case 'Lambda':
    case 'DynamoDB':
      return getDefault()
  }

  switch (node.origin) {
    case 'AWS::ApiGateway::Stage':
    case 'AWS::Lambda':
      return getDefault()
  }

  if (isRuntime(node)) {
    return {
      ...getDefault(),
      url: node.metadata?.default?.path
    }
  }

  if (isRequest(node)) {
    return {
      ...getDefault()
    }
  }

  throwAsyncError('getRequestData for node not implemented', node)
}

const getData = (node) => {
  switch (node.name) {
    case 'Lambda':
    case 'DynamoDB':
      return getRequestData(node)
    case 'Invocation':
    case 'Initialization':
    case 'Overhead':
    case 'connect':
    case 'request':
    case 'attempt':
    case 'marshal':
    case 'unmarshal':
    case 'response':
    case 'dns':
    case 'dial':
    case 'tls':
    case 'tls.connect':
      return {}
  }

  switch (node.origin) {
    case 'AWS::ApiGateway::Stage':
    case 'AWS::Lambda':
      return getRequestData(node)
  }

  const runTime = isRuntime(node)
  const request = isRequest(node)
  if (runTime || request) return getRequestData(node)

  return {}
}

export { getData }
