import React from 'react'
import { Area, Line } from 'recharts'

import colors from 'lib/colors'
import { findInventoryByType } from 'lib/resources/constants'
import { conventionalMetricToAlarmMetric, getMetricKey } from 'lib/dashboards-helpers'
import { PRETTY_STATS, SPARKLINE } from 'lib/dashboards-constants'
import { difference, indexOf } from 'lodash'

import transform from 'containers/inventory/details/resource/charts/transform'
import { mapGroupedByToDefault } from 'hooks/api/metrics'

export const isSparkline = (widget) => widget.layout.height <= SPARKLINE.HEIGHT && widget.layout.width <= SPARKLINE.WIDTH

export const hasMinMaxStats = (stats) => {
  const hasMin = indexOf(stats, 'min') > -1
  const hasMax = indexOf(stats, 'max') > -1

  return hasMin && hasMax
}

export const getMetricDefinition = (widget) => {
  const metricDefinition = widget.definition.metrics[0]
  const alarmMetricName = conventionalMetricToAlarmMetric(metricDefinition?.metric)

  const inventory = findInventoryByType(metricDefinition?.resourceType)
  return inventory?.metrics?.find(m => m.value === alarmMetricName)
}

export const getChartContents = (widget) => {
  return widget.definition.metrics.map((metric) => {
    const metricId = getMetricKey(widget, metric)

    // Determine if there's MIN and MAX stats.
    // If so, draw Area chart that represents difference between MAX and MIN values
    const metricDefinitionHasMinMaxStats = hasMinMaxStats(metric.stats)
    const minMaxDiffAreaChart = metricDefinitionHasMinMaxStats && (
      <Area
        key={`${metricId}_diff`}
        type='monotone'
        dataKey={`${metricId}_diff`}
        fill={colors('chart', 'primaryWithOpacity')}
        stroke={colors('chart', 'primaryWithOpacity')}
        strokeWidth='0.5'
        name='Min - Max'
      />
    )

    // Determine which stats should be drawn.
    // Do NOT draw MIN and MAX stats if they are present (these stats are drawn as Area above)
    const metricStatsToRender = metricDefinitionHasMinMaxStats
      ? difference(metric.stats, ['min', 'max'])
      : metric.stats

    const renderedStats = metricStatsToRender?.map(stat => {
      const metricKey = metric.stats.length === 1 ? metricId : `${metricId}_${stat}`
      return (
        <Line
          key={metricKey}
          type='monotone'
          dataKey={metricKey}
          stroke={colors('chart', isSparkline(widget) ? 'grid' : 'primary')}
          strokeWidth={1}
          name={PRETTY_STATS[stat] || stat}
          dot={false}
        />
      )
    })

    if (isSparkline(widget)) return renderedStats

    return <React.Fragment key={metricId}>
      {renderedStats}
      {minMaxDiffAreaChart}
    </React.Fragment>
  })
}

export const getChartData = (widget, metrics, metricDefinition) => {
  // Transform data into chronological slices that recharts lib understands
  const slices = transform(widget.definition.metrics.reduce((accumulator, metric) => {
    const metricId = getMetricKey(widget, metric)
    const metricData = metrics.data[metricId]

    return {
      ...accumulator,
      [metricId]: widget?.definition?.resourceGroup ? mapGroupedByToDefault(metricData) : metricData
    }
  }, {}))

  return slices.map((slice) => {
    // Calculate min-max diff stat for each slice if there's MIN and MAX stat available
    const diffs = widget.definition.metrics.reduce((accumulator, metric) => {
      const metricId = getMetricKey(widget, metric)
      const minMaxDiff = hasMinMaxStats(metric.stats)
        ? { [`${metricId}_diff`]: [slice[`${metricId}_min`], slice[`${metricId}_max`]] }
        : {}

      return {
        ...accumulator,
        ...minMaxDiff
      }
    }, {})

    const slices = { ...slice, ...diffs }

    // Format each data point
    return Object.keys(slices).reduce((accumulator, sliceKey) => {
      if (sliceKey === 'date') return accumulator

      let v = slices[sliceKey]

      // If formatData function is provided, use it
      if (metricDefinition?.formatData) {
        const format = metricDefinition.formatData

        if (sliceKey.endsWith('_diff')) v = v.map(x => format(x))
        else v = format(v)
      }

      return { ...accumulator, [sliceKey]: v }
    }, slices)
  })
}

export const getTooltipFormatter = (widget, metricDefinition) => {
  const formatter = (value, dataKey) => {
    let v = value

    // If formatTooltip function is provided, use it
    if (metricDefinition?.formatTooltip) {
      const format = metricDefinition.formatTooltip

      if (dataKey.endsWith('_diff')) v = `${format(v[0])} - ${format(v[1])}`
      else v = format(v)
    }

    return v
  }

  return (value, name, { dataKey }) => [formatter(value, dataKey), undefined]
}
