import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { channelsPaths as paths, NOTIFICATION_CHANNEL_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createChannelConnection = async (account, payload) => {
  const data = await apiFetch(paths.connect(account, 'slack'), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

// not.channels.connections.post
export const useCreateSlackConnectionQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    (payload) => createChannelConnection(account.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...NOTIFICATION_CHANNEL_LIST, account.id])
      }
    }
  )
}
