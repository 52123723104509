import React from 'react'
import { Row, Col } from 'antd'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import InventoryTags from 'containers/inventory/shared/tags'
import InventoryResourceContainer from '../../container'
import Events from 'containers/inventory/shared/events'

import OpenSearchConfig from './config'
import { OPENSEARCH_DATANODES } from '../../metrics-tabs/services/opensearch'
import OpenSearchHealth from './health'
import Tabs from 'components/antd/tabs'
import Section from 'components/layout/content/section'
import SearchContextWrapper from 'hooks/context/search-context'
import LogsSearch from 'features/on-demand-search'

const Drawer = ({ item }) => {
  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <OpenSearchConfig attributes={item?.attributes} inverse />
  </>)
}

const OpenSearch = ({ item, actions, start, end, range, configOpen, closeConfig, loading }) => {
  return (
    <InventoryResourceContainer item={item} start={start} end={end} range={range} datepicker={actions}
      configOpen={configOpen} closeConfig={closeConfig} loading={loading} service={INVENTORY_SERVICES.OpenSearch.id} initialChart={OPENSEARCH_DATANODES}>
      <Row gutter={12}>
        <Col xs={24} md={16}>
          <Section>
            <Tabs defaultActiveKey='health' items={[
              { label: 'Health', key: 'health', children: <OpenSearchHealth item={item} start={start} end={end} service={INVENTORY_SERVICES.OpenSearch.id} /> },
              {
                label: 'Logs',
                key: 'logs',
                children: (
                  <SearchContextWrapper>
                    <LogsSearch resource={item} />
                  </SearchContextWrapper>)
              },
              { label: 'Configuration', key: 'configuration', children: <Drawer item={item} /> }
            ]} />
          </Section>
        </Col>
        <Col xs={24} md={8}>
          <Events resourceId={item.id} />
        </Col>
      </Row>
    </InventoryResourceContainer>
  )
}

export default OpenSearch
