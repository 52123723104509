import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { USERS_LIST, organizationsPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const roles = ['admin', 'user', 'viewer']

const putUserPermissions = async (organization, userId, payload) => {
  const permissions = Object.entries(payload.permissions)
    .filter(([_, role]) => roles.includes(role))
    .reduce((result, [key, value]) => {
      result[key] = value
      return result
    }, {})

  const data = await apiFetch(paths.userPermissions(organization, userId), { method: 'PUT', body: JSON.stringify({ ...payload, permissions }) })
  return data
}

export const useUpdateUserPermissionsQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  const queryKey = [...USERS_LIST, organization.id]

  return useMutation(
    ({ userId, payload }) => putUserPermissions(organization.id, userId, payload),
    {
      onMutate: ({ userId, payload }) => {
        const previousList = queryClient.getQueryData(queryKey)

        const newList = previousList.map(user => {
          if (user.id !== userId) return user
          return {
            ...user,
            permissions: payload.permissions
          }
        })

        queryClient.setQueryData(queryKey, newList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (!err) return
        queryClient.setQueryData(queryKey, context.previousList)
      }
    }
  )
}
