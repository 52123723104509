import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { channelsPaths as paths, NOTIFICATION_CHANNEL } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getNotificationChannel = async (account, identity) => {
  const data = await apiFetch(paths.channel(account, identity))

  return data?.data
}

export const useNotificationChannelQuery = (channelId) => {
  const { account } = useOrganization()

  return useQuery(
    [NOTIFICATION_CHANNEL, channelId],
    () => getNotificationChannel(account.id, channelId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!channelId,
      refetchOnWindowFocus: false
    }
  )
}
