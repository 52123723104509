import React from 'react'
import Items from 'containers/inventory/shared/items'

const ECSServices = ({ services }) => {
  const formatted = services?.map(service => {
    return {
      id: service._id,
      url: `/inventory/${service._id}`,
      title: service.name
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default ECSServices
