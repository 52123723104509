import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'
import { useUser } from '../context/user-context'
import {
  getSignupUserAlreadyExistsMessage
} from 'containers/auth/messages'
import { useState } from 'react'

export const useSignup = () => {
  const { setUser, setAuthMessage } = useUser()
  const [email, setEmail] = useState('')

  return useMutation(
    ({ email, password, givenName, familyName, options = {} }) => {
      setEmail(email)
      return Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: givenName,
          family_name: familyName
        },
        ...options
      })
    },
    {
      onSuccess: (cognitoUser) => {
        setUser(cognitoUser)
      },
      onError: (error) => {
        if (error.code === 'UsernameExistsException') return setAuthMessage(getSignupUserAlreadyExistsMessage(email))
        setAuthMessage({ title: 'Some error occurred', message: error.message, type: 'error' })
      }
    }
  )
}
