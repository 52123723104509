import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { useParams } from 'react-router-dom'

import { INTEGRATION_ACTIVE, integrationsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getIntegration = async (account, integration) => apiFetch(paths.integration(account, integration), { method: 'GET' })

export const useIntegrationQuery = () => {
  const { integrationId } = useParams()
  const { account } = useOrganization()

  return useQuery(
    [...INTEGRATION_ACTIVE, integrationId],
    () => getIntegration(account?.id, integrationId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!integrationId,
      refetchOnWindowFocus: false
    }
  )
}
