import React from 'react'
import { useLocation } from 'react-router-dom'

import Items from 'containers/inventory/shared/items'
import { useAccountRoutes } from 'containers/routes'

const ELBTargetGroups = ({ targetGroups }) => {
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const formatted = targetGroups?.map(targetGroup => {
    return {
      id: targetGroup.targetGroupArn,
      title: targetGroup.targetGroupName,
      url: { pathname: routes.inventory.resource.url({ resourceId: targetGroup.id }), search },
      description: targetGroup.targetGroupArn
    }
  })
  return (
    <Items items={formatted} />
  )
}

export default ELBTargetGroups
