import React from 'react'
import Items from 'containers/inventory/shared/items'
import { AwsServiceIcon } from 'components/icons'

const EventBridgeRuleTargets = ({ targets }) => {
  const formatted = targets?.map(target => {
    return {
      id: target.id,
      url: `/inventory/${target._id}`,
      title: target.name,
      type: target.type,
      icon: <AwsServiceIcon service={target.service} />
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default EventBridgeRuleTargets
