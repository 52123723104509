import React from 'react'
import moment from 'moment'
import { Row, Col, Typography } from 'antd'
const { Text } = Typography

const BillDate = ({ date } = {}) => {
  if (!date) return null

  const billDate = moment.unix(date).format('MMMM Do YYYY')
  return (
    <Row>
      <Col span={12}><Text strong>Next bill date</Text></Col>
      <Col span={12}><Text strong>{billDate}</Text></Col>
    </Row>
  )
}

export default BillDate
