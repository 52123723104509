import React from 'react'
import { Typography } from 'components/typography/index'
import styles from './styles.module.less'

const AuthTitle = ({ title, subtitle }) => {
  return (
    <div className={styles.wrapper}>
      <Typography.Title level={3} className={styles.auth_title}>{title}</Typography.Title>
      <Typography.Paragraph className={styles.subtitle} size='xs' align='center'>{subtitle}</Typography.Paragraph>
    </div>
  )
}

export default AuthTitle
