import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'
import { MSG_VERIFICATION_EXPIRED } from 'containers/auth/messages'
import { useUser } from 'hooks/context/user-context'
import { useState } from 'react'
import { useResendSignUp } from 'hooks/auth/resend-sign-up'

export const useConfirmSignup = () => {
  const { setAuthMessage } = useUser()
  const [email, setEmail] = useState('')
  const { mutate: resendSignUp } = useResendSignUp()

  return useMutation(
    ({ email, code, options = {} }) => {
      setEmail(email)
      return Auth.confirmSignUp(email, code, options)
    },
    {
      onError: (error) => {
        if (error.code === 'ExpiredCodeException') {
          return resendSignUp({ email, message: MSG_VERIFICATION_EXPIRED })
        }

        setAuthMessage({ title: 'Some error occurred', message: error.message, type: 'error' })
      }
    }
  )
}
