import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'SQS_MESSAGES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'SQS_MESSAGES_IN',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.in',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'SQS_MESSAGES_OUT',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.out',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'SQS_PAYLOAD_SIZE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.size',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'SQS_MESSAGES_AGE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.age',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}]

export default metrics
