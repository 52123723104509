import React from 'react'
import { isUndefined, last } from 'lodash'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/pro-regular-svg-icons/faInfinity'
import { objectify } from 'lib/utils'

import Metadata from 'containers/inventory/shared/metadata'
const SQSMetadata = ({ attributes, inverse }) => {
  const redrive = attributes.redrivePolicy && objectify(JSON.parse(attributes.redrivePolicy))

  const data = [{
    title: 'Message',
    children: [
      { key: 'Max size', value: `${attributes.maximumMessageSize / 1024}KB` },
      { key: 'TTL', value: moment().add(attributes.messageRetentionPeriod, 'second').toNow(true) }
    ]
  }, {
    title: 'Queue',
    children: [
      { key: 'Type', value: isUndefined(attributes.contentBasedDeduplication) ? 'STANDARD' : 'FIFO' },
      { key: 'Delay', value: `${attributes.delaySeconds} s` }
    ]
  }, {
    title: 'Consumer',
    children: [
      { key: 'Retries', value: redrive ? redrive.maxReceiveCount : <FontAwesomeIcon icon={faInfinity} /> },
      { key: 'Processing time', value: `${attributes.visibilityTimeout} s` },
      { key: 'Long polling', value: `${attributes.receiveMessageWaitTimeSeconds} s` }
    ]
  }, {
    title: 'Dead letter',
    value: last(redrive?.deadLetterTargetArn.split(':')) || '-'
  }]

  return <Metadata items={data} inverse={inverse} />
}

export default SQSMetadata
