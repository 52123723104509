import numeral from 'numeral'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faWaveSquare } from '@fortawesome/pro-light-svg-icons/faWaveSquare'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'

import { getLast, getSparklineByName, getSQSDifferenceSparkline, getValues, average, getSum } from '../helpers'

export const SQS_MESSAGES = 'sqs-messages'
export const SQS_DIFFERENCE = 'sqs-difference'
export const SQS_DELAY = 'sqs-delay'
export const SQS_PAYLOAD = 'sqs-payload'

const metrics = [{
  key: SQS_MESSAGES,
  icon: faSigma,
  title: 'Last queue size',
  filter: (collection) => {
    return numeral(getLast(collection, 'SQS_MESSAGES')).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'SQS_MESSAGES'),
  selectable: true
}, {
  key: SQS_DIFFERENCE,
  icon: faWaveSquare,
  title: 'Difference',
  filter: (collection) => {
    const messagesIn = getSum(collection, 'SQS_MESSAGES_IN')
    const messagesOut = getSum(collection, 'SQS_MESSAGES_OUT')
    return numeral(messagesIn - messagesOut).format('0.[00]a')
  },
  subvalue: (collection) => getSQSDifferenceSparkline(collection, 'SQS_MESSAGES_IN', 'SQS_MESSAGES_OUT'),
  selectable: true
}, {
  key: SQS_DELAY,
  icon: faClock,
  title: 'Avg delay',
  filter: (collection) => numeral(getLast(collection, 'SQS_MESSAGES_AGE')).format('00:00:00'),
  subvalue: (collection) => getSparklineByName(collection, 'SQS_MESSAGES_AGE'),
  selectable: true
}, {
  key: SQS_PAYLOAD,
  icon: faMemory,
  title: 'Avg payload size',
  filter: (collection) => {
    const values = getValues(collection, 'SQS_PAYLOAD_SIZE')?.map(item => item.value)
    return numeral(average(values)).format('0.[00] b')
  },
  subvalue: (collection) => getSparklineByName(collection, 'SQS_PAYLOAD_SIZE'),
  selectable: true
}]

export default metrics
