import { paths } from '../index'

export const USAGE = 'usage'
export const ORG_INGESTION = [USAGE, 'org', 'ingestion']
export const ORG_INGESTION_SUMMARY = [...ORG_INGESTION, 'summary']
export const ORG_RESOURCES = [USAGE, 'org', 'resources']
export const ORG_RESOURCES_SUMMARY = [...ORG_RESOURCES, 'summary']

export * from './get-org-ingestion'
export * from './get-org-ingestion-summary'
export * from './get-org-resources-usage'
export * from './get-org-resources-usage-summary'

export const usageServicePaths = {
  accountUsage: (account) => `${paths.account(account)}/usage`,
  organizationUsage: (organization) => `${paths.organization(organization)}/usage`,
  resourcesHistogram: (account) => `${usageServicePaths.accountUsage(account)}/usage-histogram-report`,
  dataIngestionHistogram: (account) => `${usageServicePaths.accountUsage(account)}/data-ingested-histogram-report`,
  resourceSummary: (account) => `${usageServicePaths.accountUsage(account)}/usage-summary-report`,
  dataIngestionSummary: (account) => `${usageServicePaths.accountUsage(account)}/data-ingested-summary-report`,

  organizationResourcesHistogram: (organization) => `${usageServicePaths.organizationUsage(organization)}/resources/histogram`,
  organizationDataIngestionHistogram: (organization) => `${usageServicePaths.organizationUsage(organization)}/data/histogram`,

  organizationResourcesSummary: (organization) => `${usageServicePaths.organizationUsage(organization)}/resources/summary`,
  organizationDataIngestionSummary: (organization) => `${usageServicePaths.organizationUsage(organization)}/data/summary`
}
