import {
  Subscription,
} from 'hooks/api'

import { Features } from './Features'
import { BillingInterval, Extras } from './manage-plan-modal/usePrices'
import { Tier, TIERS } from './manage-plan-modal/tier-config'

// Derives billing v3 parameters from Stripe subscription
const deriveFromSubscription = (items?: Subscription['items']) => {
  let billingInterval = BillingInterval.month
  let tier: Tier | null = null
  // configurable features
  const extras = new Features()
  // enables a way to tweak parameters with any of the subscription items
  // will include values from tier item as well as all the subscription items that
  // have a metadata property with the same key
  const base = new Features()
  let total = 0

  if (!items || !items.length) {
    return {
      tier,
      billingInterval,
      extras
    }
  }

  const intervalValue = items[0]?.price?.interval
  if (intervalValue && BillingInterval[intervalValue]) {
    billingInterval = BillingInterval[intervalValue]
  }

  for (const item of items) {
    const { quantity, price } = item
    if (!price) {
      continue
    }
    total += (quantity ?? 1) * price.amount
    if (price.metadata?.type && price.metadata?.type in extras) {
      extras.addFromSubscriptionItem(item)
      continue
    }
    base.addFromSubscriptionItem(item)
    if (price.metadata?.type === 'tier') {
      if (price.metadata.tier && TIERS[price.metadata.tier]) {
        tier = TIERS[price.metadata.tier]
        continue
      }
      console.warn(`Invalid tier item ${price.id} (${price.metadata.tier})`)
      continue
    }
    console.warn(`Unknown type ${price.metadata?.type} of ${price.id} (${price.metadata?.type})`)
  }
  return {
    tier,
    billingInterval,
    extras,
    limits: {
      lambdaInvocations: extras.lambdaInvocations + base.lambdaInvocations,
      awsAccounts: extras.awsAccounts + base.awsAccounts,
    },
    total,
  }
}

export default deriveFromSubscription;
