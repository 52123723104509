import React from 'react'
import Numeral from 'numeral'

import { Bar, Line, YAxis } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { yAxisProps } from 'components/charts/utils'
import { KINESIS_DS_INCOMING } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-datastream'

const KinesisDataStreamIncomingChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KINESIS_DS_INCOMING]: data.KINESIS_DATA_STREAM_INCOMING_RECORDS,
    throttles: data.KINESIS_DATA_STREAM_THROTTLES_WRITE,
    bytes: data.KINESIS_DATA_STREAM_INCOMING_BYTES
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'bytes') return [Numeral(value).format('0.[00] b'), undefined]
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[0] b'
      span={span}
      tabKey={KINESIS_DS_INCOMING}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <YAxis yAxisId='records' orientation='right' domain={[0, 'dataMax']} tickFormatter={value => Numeral(value).format('0.[00]a')} {...yAxisProps} />
      <Bar dataKey={KINESIS_DS_INCOMING} yAxisId='records' stackId='a' fill={colors('chart', 'redLight')} barSize={4} name='Records' dot={false} />
      <Bar dataKey='throttles' yAxisId='records' stackId='a' fill={colors('chart', 'red')} barSize={4} name='Throttles' />
      <Line type='monotone' dataKey='bytes' stroke={colors('chart', 'primaryDark')} strokeWidth={1} name='Bytes' dot={false} />

    </ChartContainer>
  )
}

export default KinesisDataStreamIncomingChart
