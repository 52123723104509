import { combineReducers } from 'redux'
import { GET_CUSTOMER, PUT_CUSTOMER, POST_CUSTOMER } from 'actions/billing.actions'

function active (state = {}, action) {
  switch (action.type) {
    case GET_CUSTOMER:
      return action.payload
    case PUT_CUSTOMER:
      return action.payload
    case POST_CUSTOMER:
      return action.payload
  }
  return state
}

export default combineReducers({
  active
})
