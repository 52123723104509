export type Tier = keyof typeof TIERS

export const TIERS = {
  trial: 'trial',
  free: 'free',
  // @deprecated New flexible mid-tier account is now called Business
  pro: 'pro',
  business: 'business',
  enterprise: 'enterprise'
} as const

export const STATUSES = {
  normal: 'normal',
  trial: 'trial',
  suspended: 'suspended'
}

export const TIER_CONFIG = {
  [TIERS.free]: {
    id: TIERS.free,
    title: 'Developer',
    price: '$0',
    priceTitle: 'Free forever',
    subTitle: 'New to serverless plan',
    text: 'A perfect serverless monitoring tool with built in failure detection for your private project',
    invocations: '100,000 invocations per month',
    list: {
      title: 'Free plan includes:',
      items: [
        'Access to all aws managed services',
        '1 AWS account',
        'Lambda monitoring',
        'Infrastructure monitoring',
        'Failure detection',
        'Log analytics',
        'Full-text search',
        'Well-Architected Lens',
        '5 microservice dashboards',
        '3 users',
        'Email and Slack notifications',
        'Multi-factor authentication (MFA)',
        'Email/chat support'
      ]
    }
  },
  [TIERS.business]: {
    id: TIERS.business,
    title: 'Business',
    price: '$99/mo',
    priceTitle: 'Starting from',
    subTitle: 'Large serverless environments',
    text: 'Ideal when scaling your serverless infrastructure',
    invocations: 'Up to 95M invocations per month',
    list: {
      title: 'Basic plan, Plus:',
      items: [
        'Access to all aws managed services',
        'Unlimited AWS accounts',
        'Automatic insights',
        'Metric alarms',
        'Unlimitd microservice dashboards',
        'All notification channels',
        'All integrations'
      ]
    }
  },
  [TIERS.enterprise]: {
    id: TIERS.enterprise,
    title: 'Enterprise',
    price: '',
    priceTitle: 'Contact us',
    subTitle: 'High-volume infrastructure',
    text: 'Custon pricing and enterprise-grade features',
    invocations: 'Custom pricing',
    list: {
      title: 'Professional plan, plus',
      items: [
        'Single sign-on (SSO)',
        'Service level agreement (SLA)',
        'Advanced insights and alarms',
        'Custom event tracking',
        'Enterprise level support',
        'Tailored pricing'
      ]
    }
  }
}
