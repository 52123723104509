import { ApiError } from 'lib/api-fetch/api-error'

export const fetchJSON = async function () {
  const args = Array.from(arguments)

  const res = await fetch.apply(this, args)
  const json = res.status !== 204 && res.status !== 404
    ? await res.json()
    : null

  if (!res.ok) {
    throw new ApiError(res, args, json)
  }

  return { json, status: res.status }
}
