import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { delegationPaths as paths, DELEGATIONS_LIST, DELEGATION_ACTIVE } from './index'
import { INTEGRATION_ACTIVE } from '../integrations'
import { useOrganization } from 'hooks/context/organization-context'

const updateDelegation = async (account, identity, payload) => {
  const data = await apiFetch(paths.delegation(account, identity), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateDelegationQuery = (delegationId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    (payload) => updateDelegation(account.id, delegationId, payload),
    {
      onMutate: ({ payload }) => {
        const openDelegation = queryClient.getQueryData([...DELEGATION_ACTIVE, delegationId])

        queryClient.setQueryData([...DELEGATION_ACTIVE, delegationId], { ...openDelegation, ...payload })
        return openDelegation
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...DELEGATION_ACTIVE, delegationId], context.openDelegation)
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...DELEGATIONS_LIST, account.id])
        queryClient.invalidateQueries([...DELEGATION_ACTIVE, delegationId])
        queryClient.invalidateQueries([...INTEGRATION_ACTIVE])
      }
    }
  )
}
