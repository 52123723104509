import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Popconfirm, Tooltip } from 'antd'
import classnames from 'classnames'
import { reduce, groupBy } from 'lodash'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { useResourceGroupsQuery, useDeleteResourceGroupQuery } from 'hooks/api'
import useQueryParams from 'lib/hooks/use-query-params'
import { useAccountRoutes } from 'containers/routes'

import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { AddIcon, AlarmIcon, EllipsisVLightIcon, ResourceGroupIcon, InsightIcon, ErrorIcon } from 'components/icons'
import Section from 'components/layout/content/section'
import { Item } from 'components/items'
import styles from './styles.module.less'

const stats = (events) => {
  if (!events) return

  const severities = reduce(groupBy(events, event => event.severity), (result, value, key) => {
    result[key] = { severity: key, events: value }
    return result
  }, {})

  const severity = severities.critical || severities.warning || severities.info
  return severity
}

const ExtraDetails = ({ errors, alarms, insights, group, handleDelete, view = 'resourceGroups' }) => {
  const routes = useAccountRoutes()
  const [visible, setVisible] = useState(false)

  const handleVisibility = (e) => {
    e.stopPropagation()
    setVisible(!visible)
  }

  return (
    <div className={styles.extra_wrapper}>
      <div className={styles.stats}>
        {insights && <span><InsightIcon size='small' className={classnames(styles.event_icon, styles[insights?.severity])} />{insights?.events?.length}</span>}
        {alarms && <span><AlarmIcon size='small' className={classnames(styles.event_icon, styles[insights?.severity])} />{alarms?.events?.length}</span>}
        {errors && <span><ErrorIcon size='small' className={classnames(styles.event_icon, styles[insights?.severity])} />{errors?.events?.length}</span>}
      </div>
      <PermissionsGate scopes={[SCOPES.canEdit]}>
        <Dropdown
          trigger={['click']}
          className={styles.ellipsis_icon}
          overlayClassName={styles.groups_dropdown}
          menu={{
            items: [
              {
                key: 'overview',
                label: <Link to={routes.dashboards.resourceGroups.group.url({ groupId: group.id })} onClick={e => e.stopPropagation()}>Group overview</Link>
              },
              { key: 'edit', label: <Link to={routes[view].resourceGroups.edit.url({ groupId: group.id })} onClick={e => e.stopPropagation()}>Edit</Link> },
              {
                key: 'delete',
                label: (
                  <Popconfirm
                    title={`Are you sure you want to delete ${group.title}`}
                    onConfirm={(e) => handleDelete(e, group.id)}
                    onCancel={e => handleVisibility(e)}
                    placement='right'
                  >
                    <span onClick={e => handleVisibility(e)}>Delete</span>
                  </Popconfirm>
                )
              }
            ]
          }}>
          <EllipsisVLightIcon onClick={e => e.stopPropagation()} className={styles.ellipsis_icon} />
        </Dropdown>
      </PermissionsGate>
    </div>
  )
}

const ResourceGroups = ({ view, className, hover, alarms, insights, errors }) => {
  const routes = useAccountRoutes()

  const { data: groups, isLoading: loadingGroups } = useResourceGroupsQuery()
  const { mutate: deleteGroup } = useDeleteResourceGroupQuery()

  const { getValue: selected, setValue: setSelected } = useQueryParams('group', [])

  const handleSelect = (item) => {
    if (view === 'dashboards') return null
    setSelected(filterHelper(item, selected))
  }

  const handleDelete = async (e, groupId) => {
    e.stopPropagation()
    deleteGroup(groupId)
  }

  return (
    <Section
      title='Resource groups'
      loading={loadingGroups || !groups}
      titleUpperCase
      className={groups?.length > 2 && hover ? styles.wrapper_hide : className}
      actions={(
        <PermissionsGate scopes={[SCOPES.canEdit]}>
          <Tooltip title='Add new group'>
            <Link to={routes[view].resourceGroups.new.url()} className={styles.new_btn}><AddIcon /></Link>
          </Tooltip>
        </PermissionsGate>
      )}
    >
      {groups?.length !== 0
        ? groups?.map(group => {
          const alarmsCount = stats(alarms?.filter(item => group?.resources?.includes(item?.resource?.id || item?.resource)))
          const errorsCount = stats(errors?.filter(item => group?.resources?.includes(item?.resource?.id || item?.resource)))
          const insightsCount = stats(insights?.filter(item => group?.resources?.includes(item?.resource?.id || item?.resource)))

          return (
            <Item.Filter
              key={group.id}
              id={group.id}
              title={group.title}
              description={group.description}
              icon={<ResourceGroupIcon />}
              extra={<ExtraDetails errors={errorsCount} insights={insightsCount} alarms={alarmsCount} group={group} handleDelete={handleDelete} view={view} />}
              url={view === 'dashboards' ? routes.dashboards.resourceGroups.group.url({ groupId: group.id }) : null}
              onSelect={handleSelect}
              selected={selected}
            />)
        })
        : <p>No resource groups created.</p>}
    </Section>
  )
}

export default ResourceGroups
