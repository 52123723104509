import React, { useEffect, useRef, useState, createContext } from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { Breakpoint, useResize } from 'hooks/resize'
import { useSwipeHorizontal } from './use-swipe-horizontal'
import ToggleListBtn from './toggle-button'

import styles from './styles.module.less'

const SIZES = {
  small: 'small',
  medium: 'medium'
}

export const SplitViewContext = createContext({
  handleListClose: () => { }
})

const PAGE_LAYOUT = 'page_layout'

const SplitView = ({ left, right, size }) => {
  const { search } = useLocation()
  const didMount = useRef(false)
  const { isVisibleUntil } = useResize()
  const mobileView = isVisibleUntil(Breakpoint.lg)

  const [listFixed, setListFixed] = useState(true)
  const [listFiltered, setListFiltered] = useState(false)

  const { onTouchStart, onTouchEnd, onTouchMove } = useSwipeHorizontal({
    handleSwipeLeft: () => setListFixed(false),
    handleSwipeRight: () => setListFixed(true)
  })

  const pageLayout = JSON.parse(localStorage.getItem(PAGE_LAYOUT))

  const handleListClose = () => {
    if (listFixed && mobileView) {
      setListFixed(false)
    }
  }

  useEffect(handleListClose, [mobileView])

  useEffect(() => {
    if (!pageLayout) return

    setListFixed(pageLayout.isSidelistFixed)
  }, [])

  useEffect(() => {
    const data = { isSidelistFixed: listFixed }

    // Resize event forces dashboard to recalculate widget widths
    window.dispatchEvent(new Event('resize'))

    localStorage.setItem(PAGE_LAYOUT, JSON.stringify(data))
  }, [listFixed])

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      return
    }

    if (listFixed) return

    setListFiltered(true)
    setTimeout(() => setListFiltered(false), 1000)
  }, [search])

  return (
    <SplitViewContext.Provider value={{ handleListClose }}>
      <div className={classnames(styles.container, { [styles.fixed_height]: listFixed && mobileView })} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <div className={classnames({
          [styles.sidebar]: true,
          [styles.fixed]: listFixed,
          [styles.small]: size === SIZES.small
        })}>
          <div className={classnames({
            db_sidebar_wrapper: true,
            db_sidebar_wrapper_layover: !listFixed,
            db_sidebar_small: size === SIZES.small
          })}>
            <div
              className={classnames({
                [styles.db_sidebar_content]: true,
                db_sidebar_layover_content: !listFixed
              })}>
              {left}
            </div>
            <ToggleListBtn toggle={setListFixed} listFixed={listFixed} search={search} showFilter={listFiltered} />
          </div>
        </div>

        <div className={classnames(styles.right, { [styles.overview_hidden]: listFixed && mobileView })}>{right}</div>
      </div>
    </SplitViewContext.Provider>
  )
}

export default SplitView
