import React from 'react'
import { Tag, Typography, Row, Col } from 'antd'
const { Text } = Typography

const TabTitle = ({ title, tag, tagTitle } = {}) => {
  return (
    <Row type='flex' justify='center' gutter={8}>
      <Col><Text strong>{title}</Text></Col>
      <Col><Tag color={tag}>{tagTitle}</Tag></Col>
    </Row>
  )
}

export default TabTitle
