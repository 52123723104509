import React from 'react'
import Metadata from 'containers/inventory/shared/metadata'
const KinesisDataStreamMetadata = ({ attributes, inverse }) => {
  const data = [{
    title: 'Encryption type',
    value: attributes.encryptionType
  }, {
    title: 'Retention period',
    value: `${attributes.retentionPeriodHours}h`
  }, {
    title: 'Stream status',
    value: attributes.streamStatus
  }]
  return <Metadata items={data} inverse={inverse} />
}

export default KinesisDataStreamMetadata
