import { useQueries } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { sub } from 'date-fns'
import { flatten } from 'lodash'

import { apiFetch } from 'lib/action-helpers'
import { metricsPaths as paths, METRICS_TOTAL } from './index'

const getMetrics = async (account, payload) => {
  return await apiFetch(paths.metrics(account), { method: 'POST', body: JSON.stringify(payload) })
}

const namespaces = {
  lambda: 'dashbird/lambda',
  appsyncRequests: 'dashbird/appsync/requests',
  appsyncResolvers: 'dashbird/appsync/resolvers'
}

const metrics = {
  [namespaces.lambda]: [
    { name: 'errors', stats: ['sum'] },
    { name: 'invocations', stats: ['sum'] },
    { name: 'duration', stats: ['avg'] }
  ],
  [namespaces.appsyncRequests]: [
    { name: 'errors', stats: ['sum'] },
    { name: 'requests', stats: ['sum'] },
    { name: 'duration', stats: ['avg'] }
  ],
  [namespaces.appsyncResolvers]: [
    { name: 'errors', stats: ['sum'] },
    { name: 'requests', stats: ['sum'] },
    { name: 'duration', stats: ['avg'] }
  ]
}

const buildPayload = (query, metric, namespace, stats) => {
  const end = query?.end || new Date()
  const start = query?.start || sub(end, { days: 1 })

  return {
    dimensions: {},
    namespace,
    metric,
    stats,
    start,
    end,
    group: 'resource'
  }
}

const buildPayloads = (query) => {
  const queryNamespaces = query?.namespaces || [
    namespaces.lambda,
    namespaces.appsyncRequests
  ]

  const payloads = queryNamespaces.map(namespace => {
    return metrics[namespace].map(metric => buildPayload(query, metric.name, namespace, metric.stats))
  })

  return flatten(payloads)
}

export const useResourcesMetricsQuery = (query) => {
  const { account } = useOrganization()
  const payloads = buildPayloads(query)

  const addTime = query?.start && query?.end

  return useQueries(
    payloads.map(payload => {
      const { namespace, metric, start, end } = payload
      return {
        queryKey: [...METRICS_TOTAL, account?.id, namespace, metric, addTime ? `${start}-${end}` : ''],
        queryFn: () => getMetrics(account.id, payload),
        staleTime: Infinity,
        enabled: !!account?.id,
        refetchOnWindowFocus: false // take away later?,
      }
    })
  )
}
