import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [
  // Health chart
  {
    id: 'OPENSEARCH_STATUS_GREEN',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.status.green',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_STATUS_YELLOW',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.status.yellow',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_STATUS_RED',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.status.red',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_WRITES_BLOCKED',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.index.writes.blocked',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_MASTER_CONNECTION_STATUS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'master.reachable.from.node',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_AUTOMATED_SNAPSHOT_FAILURE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.automated.snapshot.failure',
      stats: [STATISTICS.AVERAGE]
    }
  },

  // SHARDS chart
  {
    id: 'OPENSEARCH_CLUSTER_SHARDS_ACTIVE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.shards.active',
      stats: [STATISTICS.MAX]
    }
  }, {
    id: 'OPENSEARCH_CLUSTER_SHARDS_UNASSIGNED',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.shards.unassigned',
      stats: [STATISTICS.MAX]
    }
  }, {
    id: 'OPENSEARCH_CLUSTER_SHARDS_DELAYED_UNASSIGNED',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.shards.delayedUnassigned',
      stats: [STATISTICS.MAX]
    }
  }, {
    id: 'OPENSEARCH_CLUSTER_PRIMARY_SHARDS_ACTIVE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.primary.shards.active',
      stats: [STATISTICS.MAX]
    }
  }, {
    id: 'OPENSEARCH_CLUSTER_SHARDS_INITIALIZING',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.shards.initializing',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_CLUSTER_SHARDS_RELOCATING',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.shards.relocating',
      stats: [STATISTICS.SUM]
    }
  },

  {
    id: 'OPENSEARCH_CLUSTER_NODES',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.nodes',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  },

  // Documents chart
  {
    id: 'OPENSEARCH_DOCUMENTS_SEARCH',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.documents.searchable',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_DOCUMENTS_DELETED',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.documents.deleted',
      stats: [STATISTICS.AVERAGE]
    }
  },

  // {
  //   id: 'OPENSEARCH_CPU_CREDIT_BALANCE',
  //   filter: {
  //     namespace: NAMESPACES.AWS,
  //     metric: 'cluster.cpu.credit.balance',
  //     stats: [STATISTICS.AVERAGE]
  //   }
  // },

  // Storage chart
  {
    id: 'OPENSEARCH_DATANODES_FREE_STORAGE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.free.storage.space',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_ULTRAWARM_FREE_STORAGE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'warm.free.storage.space',
      stats: [STATISTICS.SUM]
    }
  },

  // Datanodes CPU/Memory chart
  {
    id: 'OPENSEARCH_DATANODES_CPU_UTILIZATION',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.cpu.utilization',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  }, {
    id: 'OPENSEARCH_DATANODES_MEMORY',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.jvm.memory.pressure',
      stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
    }
  },
  // Master CPU/Memory chart
  {
    id: 'OPENSEARCH_MASTER_CPU_UTILIZATION',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'master.cpu.utilization',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  }, {
    id: 'OPENSEARCH_MASTER_MEMORY',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'master.jvm.memory.pressure',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  },
  // Ultrawarm CPU/Memory chart
  {
    id: 'OPENSEARCH_ULTRAWARM_CPU_UTILIZATION',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'warm.cpu.utilization',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  }, {
    id: 'OPENSEARCH_ULTRAWARM_MEMORY',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'warm.jvm.memory.pressure',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  },

  // Requests chart
  {
    id: 'OPENSEARCH_INVALID_HOST_REQUESTS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.invalid.host.requests',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_REQUESTS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.opensearch.requests',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_ELASTIC_REQUESTS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.elasticsearch.requests',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_2XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.requests.2xx',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_3XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.requests.3xx',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_4XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.requests.4xx',
      stats: [STATISTICS.SUM]
    }
  }, {
    id: 'OPENSEARCH_5XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'cluster.requests.5xx',
      stats: [STATISTICS.SUM]
    }
  },

  // EBS Volume metrics
  // Latency chart
  {
    id: 'OPENSEARCH_LATENCY_READ',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'ebs.latency.read',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_LATENCY_WRITE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'ebs.latency.write',
      stats: [STATISTICS.AVERAGE]
    }
  },

  // // Data nodes metrics
  {
    id: 'OPENSEARCH_INSTANCE_LATENCY_INDEXING',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.latency.indexing',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  }, {
    id: 'OPENSEARCH_INSTANCE_LATENCY_SEARCH',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.latency.search',
      stats: [STATISTICS.AVERAGE, STATISTICS.MAX, STATISTICS.MIN]
    }
  },
  {
    id: 'OPENSEARCH_INSTANCE_RATE_INDEXING',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.rate.indexing',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_INSTANCE_RATE_SEARCH',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.rate.search',
      stats: [STATISTICS.AVERAGE]
    }
  }, {
    id: 'OPENSEARCH_INSTANCE_WRITE_QUEUE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.write.queue',
      stats: [STATISTICS.MAX]
    }
  }, {
    id: 'OPENSEARCH_INSTANCE_SEARCH_QUEUE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'instance.search.queue',
      stats: [STATISTICS.MAX]
    }
  }
]

export default metrics
