import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { isBefore, parseISO } from 'date-fns'

import Login from '../login/form'
import Invite from './form'
import GenericAction from 'containers/auth/generic-action'
import MFAVerification from 'containers/auth/mfa-verification'

import {
  MSG_VERIFICATION_LINK,
  MSG_INVITATION_ERROR,
  MSG_INVITATION_NOT_FOUND,
  MSG_USER_ALREADY_EXISTS,
  MSG_INVITATION_EXPIRED,
  signinErrors
} from 'containers/auth/messages'

import { useQuery } from 'lib/hooks/utils'
import { getEmailConfirmedKey, getOnEmailConfirmedAction, listen } from 'lib/auth/local-storage-listener'
import { useUser } from 'hooks/context/user-context'
import { useInvitationQuery, useAcceptInvitationQuery } from 'hooks/api'
import { useLogin, useConfirmLogin, useSignup, useResendSignUp } from 'hooks/auth'
import LoadingDots from 'components/loading-dots'

const InviteContainer = () => {
  const history = useHistory()
  const params = useQuery()
  const token = params.get('token')
  const queryEmail = params.get('email')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [statelessSignInLoading, setStatelessSignInLoading] = useState(false)
  const [statelessUser, setStatelessUser] = useState(false)
  const [confirmSignUpSuccess, setConfirmSignUpSuccess] = useState(false)

  const { user, setAuthMessage } = useUser()
  // got no idea why but this runs multiple times (eg when logging in from invite pages, it updates the query and shows loading for a short time)
  const { data: invitation, isError: invitationError, error, isLoading: invitationLoading } = useInvitationQuery()
  const { mutateAsync: acceptInvitation, isLoading: acceptInvitationLoading } = useAcceptInvitationQuery()
  const { mutate: signIn, isLoading: signInLoading } = useLogin()
  const { mutate: confirmLogin, isLoading: loadingConfirmSignIn } = useConfirmLogin()
  const { mutate: signUp, isLoading: signUpLoading } = useSignup()
  const { mutate: resendSignUp, isLoading: resendLoading } = useResendSignUp()

  const invitationNotFound = invitationError && error?.status === 404
  const invitationExpired = invitation && isBefore(parseISO(invitation?.expiresAt), new Date())
  const userConfirmed = invitation?.userStatus === 'CONFIRMED'
  const userUnconfirmed = invitation?.userStatus === 'UNCONFIRMED'
  const userHasCodeDelivery = user && user.codeDeliveryDetails
  const userConfirmLogin = user && user.challengeName === 'SOFTWARE_TOKEN_MFA'

  const onSignIn = async (values) => {
    const { email, password } = values
    setEmail(email)
    setPassword(password)
    signIn({ email, password })
  }

  const onSubmitConfirmLogin = async (values) => {
    const { code } = values

    if (statelessUser) {
      try {
        await Auth.confirmSignIn(statelessUser, code, statelessUser.challengeName)

        acceptInvite(email, token)
        setStatelessUser(false)
        setAuthMessage(false)
        return
      } catch (err) { setAuthMessage({ message: err.message }) }
    }

    confirmLogin({ code })
  }

  const onSignUp = async (values) => {
    const { email } = invitation
    const { password } = values
    const options = {
      clientMetadata: { inviteToken: token }
    }

    setEmail(email)
    setPassword(password)
    signUp({ email, password, options })
  }

  const onSignInAcceptInvite = async (values) => {
    const { email, password } = values
    setEmail(email)
    setPassword(password)

    await statelessSignIn(email, password, token)
  }

  const statelessSignIn = async (email, password, token) => {
    // stateless sign in for token, then check for MFA and accept invite with token and refresh session for new token
    setStatelessSignInLoading(true)
    setAuthMessage(false)

    try {
      const response = await Auth.signIn(email, password)
      if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setStatelessUser(response)
        return
      }
      acceptInvite(email, token)
    } catch (err) {
      setStatelessSignInLoading(false)
      setAuthMessage(signinErrors(err))
    }
  }

  const onResendVerification = async () => {
    if (!email) return
    resendSignUp({ email })
  }

  const handleSignUpSuccess = async () => {
    if (!confirmSignUpSuccess) return
    await onSignInAcceptInvite({ email, password })
  }

  const acceptInvite = async (email, token) => {
    try {
      const accept = acceptInvitation({ invitationId: invitation.id, payload: { email, token } })
      ReactGA.event({ category: 'Invitation', action: 'Invitation accepted', label: email.replace('@', '.at.') })
    } catch { console.log('err') }
    setStatelessSignInLoading(false)

    history.push('/')
  }

  useEffect(handleSignUpSuccess, [confirmSignUpSuccess])

  useEffect(() => {
    if (!invitationError) return

    if (invitationNotFound) {
      setAuthMessage(MSG_INVITATION_NOT_FOUND, true)
      return
    }

    setAuthMessage(MSG_INVITATION_ERROR, true)
  }, [invitationError])

  useEffect(() => {
    if (userHasCodeDelivery) {
      setAuthMessage(MSG_VERIFICATION_LINK, true)
    }
  }, [user])

  useEffect(() => {
    if (!invitation) return

    if (invitationExpired) {
      setAuthMessage(MSG_INVITATION_EXPIRED, true)
      return
    }

    if (userConfirmed) {
      setAuthMessage(MSG_USER_ALREADY_EXISTS, true)
    }

    if (userUnconfirmed) {
      resendSignUp({ email: queryEmail })
      // MSG should be resent email but this is already in hook (refactor out from hook maybe)
      setAuthMessage(MSG_VERIFICATION_LINK)
    }

    if (invitation?.token && !invitationExpired && !userConfirmed && !userUnconfirmed) {
      setAuthMessage({
        title: `${invitation.email}`,
        message: `You have been invited to join ${invitation.organization.name}!`
      })
    }
  }, [invitation])

  // check if use has verified his email (confirmed sign up) in this browser
  useEffect(() => {
    if (!userUnconfirmed && !userHasCodeDelivery) return

    const emailConfirmedKey = getEmailConfirmedKey(queryEmail)
    const onEmailConfirmedAction = getOnEmailConfirmedAction(emailConfirmedKey, setConfirmSignUpSuccess)
    return listen(emailConfirmedKey, onEmailConfirmedAction)
  }, [userUnconfirmed, userHasCodeDelivery])

  const getForm = () => {
    if (invitationLoading) {
      // loading invitation
      return <LoadingDots />
    } else if (invitationNotFound || invitationExpired) {
      // invitation not found or expired, already created users can just log in
      // but they need a new invitation to access organization data
      return <Login onSubmit={onSignIn} loading={signInLoading} email={queryEmail} />
    } else if (userConfirmed && !statelessUser) {
      // invitation found and user exists and is confirmed but needs to accept invitation after authentication
      return <Login
        onSubmit={onSignInAcceptInvite}
        loading={statelessSignInLoading || acceptInvitationLoading}
        email={queryEmail}
        password={password}
      />
    } else if (userHasCodeDelivery || userUnconfirmed) {
      // invitation found and user is created but needs to confirm email with code
      return <GenericAction
        onSubmit={() => onSignInAcceptInvite({ email: queryEmail, password })}
        loading={statelessSignInLoading || signInLoading}
        text='Log in' altLoading={resendLoading}
        onAltClick={onResendVerification}
        altText='Resend verification'
      />
    } else if (token && invitation && !statelessUser) {
      // invitation found and flow not yet started
      return <Invite
        onSubmit={onSignUp}
        loading={invitationLoading || signUpLoading}
        email={queryEmail}
      />
    } else if (userConfirmLogin || statelessUser) {
      return <MFAVerification onSubmit={onSubmitConfirmLogin} loading={loadingConfirmSignIn || statelessSignInLoading} />
    }

    return <Login onSubmit={onSignIn} loading={signInLoading} email={queryEmail} />
  }

  return getForm()
}

export default InviteContainer
