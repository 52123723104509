import React, { useEffect } from 'react'
import { Form } from 'antd'

import SplitView from 'features/layout/split-pane'
import SearchDetails from './details'
import SearchList from './list'
import SearchContext from 'hooks/context/search-context'

const SearchContainer = () => {
  const [form] = Form.useForm()

  useEffect(() => {
    document.title = 'Dashbird.io - Search'
  }, [])

  return (
    <SearchContext form={form}>
      <SplitView
        left={<SearchList />}
        right={<SearchDetails form={form} />}
      />
    </SearchContext>
  )
}

export default SearchContainer
