import dagre from 'dagre'
import { StateMachine, State } from './asl-types'

import styles from './styles.module.less'

type Handler<T> = (g: typeof dagre.graphlib.Graph, context: Record<string, State>, name: keyof typeof context, def: Extract<State, { Type: T }>, end: string, parent: null | string) => void
type HandlerMap = {
  [T in State['Type']]: Handler<T>
}
type HandlerFor<T> = T extends (a: any, b: any, s: infer S) => any ? S : never

type A = Handler<'Task'>

const END = '___end___'
const START = '___start___'

enum EdgeType {
  Success = 'success',
  Failure = 'fail',
}
const handlers: HandlerMap = {
  Task: (g, context, name, def, end, parent) => {
    const { Type: type, Catch, End, Next, ...rest } = def
    g.setNode(name, { label: name, type, rest })
    parent && g.setParent(name, parent)
    if (Catch) {
      console.error('handle Task.Catch')
    }
    if (End) {
      return g.setEdge(name, end)
    }
    if (Next) {
      g.setEdge(name, Next)
      return runHandler(g, context, Next, context[Next], end, parent)
    }
  },
  Choice: (g, context, name, def, end, parent) => {
    const { Type: type, Next, Default, End, Choices, ...rest } = def
    g.setNode(name, { label: name, type, rest })
    parent && g.setParent(name, parent)

    // Choices are shown as a split in the graph
    if (Next) {
      return console.error('handle Choice.Next')
      // g.setEdge(name, def.Next)
    }
    if (End) {
      return console.error('handle Choice.End')
      // g.setEdge(name, def.Next)
    }
    if (Array.isArray(Choices)) {
      Choices.forEach(({ Next, ...params }) => {
        if (Next) {
          g.setEdge(name, Next)
          runHandler(g, context, Next, context[Next], end, parent)
        } else {
          return console.error('handle choice without next')
        }
      })
    }
    if (Default) {
      g.setEdge(name, Default)
      runHandler(g, context, Default, context[Default], end, parent)
    }
  },
  Map: (g, context, name, def, end, parent) => {
    const { Type: type, Iterator, ItemProcessor, Next, End, ...rest } = def
    const endId = `${name}:end`
    const mapParentId = `${name}:group`
    g.setNode(mapParentId, { label: `Map`, type, rest, shape: 'cascade' })

    g.setNode(name, { label: 'S', type, rest, isVirtual: true, className: styles.node_hidden })
    g.setParent(name, mapParentId)
    g.setNode(endId, { label: 'E', type, rest, isVirtual: true, className: styles.node_hidden })
    g.setParent(endId, mapParentId)

    if (Iterator) {
      const { StartAt, States } = Iterator
      g.setEdge(name, StartAt)
      // overwrite parent here for children to group them under
      runHandler(g, States, StartAt, States[StartAt], endId, mapParentId)
    } else if (ItemProcessor) {
      const { StartAt, States } = ItemProcessor
      g.setEdge(name, StartAt)
      runHandler(g, States, StartAt, States[StartAt], endId, mapParentId)

    } else {
      console.error('map without steps')
    }
    if (End) {
      return g.setEdge(endId, end)
    }
    if (Next) {
      g.setEdge(endId, Next)
      return runHandler(g, context, Next, context[Next], end, parent)
    }
    return console.error('Not implemented', def)
  },
  Fail: (g, context, name, def, end, parent) => {
    const { Type: type, ...rest } = def
    g.setNode(name, { label: name, type, rest, className: styles.node_fail })
    parent && g.setParent(name, parent)
    g.setEdge(name, end)
  },
  Succeed: (g, context, name, def, end, parent) => {
    const { Type: type, ...rest } = def
    g.setNode(name, { label: name, type, rest, className: styles.node_succeed })
    parent && g.setParent(name, parent)
    g.setEdge(name, end)
  },
  Pass: (g, context, name, def, end, parent) => {
    const { Type: type, Next, End, ...rest } = def
    g.setNode(name, { label: name, type, rest })
    parent && g.setParent(name, parent)
    if (End) {
      return g.setEdge(name, end)
    }
    if (Next) {
      g.setEdge(name, Next)
      return runHandler(g, context, Next, context[Next], end, parent)
    }
    return console.error('Not implemented', def)
  },
  Wait: (g, context, name, def, end, parent) => {
    const { Type: type, Next, End, ...rest } = def
    g.setNode(name, { label: name, type, rest })
    parent && g.setParent(name, parent)
    if (End) {
      return g.setEdge(name, end)
    }
    if (Next) {
      g.setEdge(name, Next)
      return runHandler(g, context, Next, context[Next], end, parent)
    }
    return console.error('Not implemented', def)
  },
  Parallel: (g, context, name, def, end, parent) => {
    const { Type: type, Branches, Next, End, ...rest } = def
    const endId = `${name}:end`
    const parallelParentId = `${name}:group`
    g.setNode(parallelParentId, { label: 'Parallel', type, rest })
    parent && g.setParent(parallelParentId, parent)

    g.setNode(name, { label: 'S', type, rest, isVirtual: true, className: styles.node_hidden })
    g.setParent(name, parallelParentId)
    g.setNode(endId, { label: 'E', type, rest, isVirtual: true, className: styles.node_hidden })
    g.setParent(endId, parallelParentId)

    for (const { StartAt, States } of Branches) {
      g.setEdge(name, StartAt)
      // overwrite parent here for children to group them under
      runHandler(g, States, StartAt, States[StartAt], endId, parallelParentId)
    }
    if (End) {
      return g.setEdge(endId, end)
    }
    if (Next) {
      g.setEdge(endId, Next)
      return runHandler(g, context, Next, context[Next], end, parent)
    }
    return console.error('Not implemented', def)
  },
} as const

const runHandler = (g: dagre.graphlib.Graph, context: Record<string, State>, name: string, def, end: string, parent: string | null) => {
  if (def[g.MARKER_VISITED]) {
    return
  }
  def[g.MARKER_VISITED] = true
  if (handlers[def.Type]) {
    return handlers[def.Type](g, context, name, def, end, parent)
  }
  return console.error(`${def.Type} not handled`)
}

export const graphFromStepFunctionDefinition = (definition: StateMachine) => {
  const g = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  })
  g.MARKER_VISITED = Symbol('VISITED')

  g.setGraph({ rankdir: 'LR' })
  g.setDefaultEdgeLabel(function () { return {} })

  g.setNode(START, { label: 'START', shape: 'circle', className: styles.node_start_end })
  g.setNode(END, { label: 'END', shape: 'circle', className: styles.node_start_end })

  g.setEdge(START, definition.StartAt)


  runHandler(g, definition.States, definition.StartAt, definition.States[definition.StartAt], END, null)

  return g
}

const runXrayHandler = (g: dagre.graphlib.Graph, node: any, parent: any, segmentsList: any) => {
  if (segmentsList?.includes(node.id)) {
    g.setNode(node.id, {
      description: node.content || 'Remote',
      type: 'service-map',
      service: node.service || 'default',
      label: node.name || node.content,
      shape: 'service',
      node: node,
      className: styles.node_service
    })
    if (parent?.id) g.setEdge(parent.id, node.id)

    if (node.children) {
      node?.children.map(child => {
        runXrayHandler(g, child, node, segmentsList)
      })
    }
    return
  }
  else {
    if (node.children) {
      node?.children.map(child => {
        runXrayHandler(g, child, parent, segmentsList)
      })
    }
    return
  }
}

export const graphFromXRayTraces = (tracesTree: any, segmentsList: any) => {
  const main = {
    id: START,
    service: 'dashbird',
    content: 'Client',
    name: 'Client',

  }

  const g = new dagre.graphlib.Graph({
    compound: true,
    directed: true,
  })

  g.setGraph({ rankdir: 'LR', ranksep: 75 })
  g.setDefaultEdgeLabel(function () { return {} })
  g.setNode(START, { label: 'Client', shape: 'service', content: 'Client', service: 'dashbird', type: 'service-map', className: styles.node_service })

  runXrayHandler(g, tracesTree, main, segmentsList)

  return g
}

