import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'KINESIS_DATA_STREAM_INCOMING_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_DATA_STREAM_INCOMING_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_DATA_STREAM_OUTGOING_RECORDS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.records',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_DATA_STREAM_OUTGOING_BYTES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.bytes',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_DATA_STREAM_MESSAGE_AGE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.age',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'KINESIS_DATA_STREAM_THROTTLES_READS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'throttles.read',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'KINESIS_DATA_STREAM_THROTTLES_WRITE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'throttles.write',
    stats: [STATISTICS.SUM]
  }
}]

export default metrics
