import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { userPaths as paths, USER_INVITATIONS } from './index'
import { useUser } from 'hooks/context/user-context'

const getUserInvitations = async (userId) => {
  const data = await apiFetch(paths.invitations(userId))
  return data
}

export const useUserInvitationsQuery = () => {
  const { user } = useUser()

  return useQuery(
    [...USER_INVITATIONS, user?.attributes?.sub],
    () => getUserInvitations(user?.attributes?.sub),
    {
      staleTime: Infinity,
      enabled: !!user?.attributes?.sub,
      retry: false,
      isError: (err) => {
        console.log(err)
      }
    }
  )
}
