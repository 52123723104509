import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { message } from 'antd'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import Section from 'components/layout/content/section'
import RawLog from './raw-log'
import PrettyLog from './pretty-log'
import IconButton from 'components/buttons/icon'
import { CodeIcon, CopyIcon, EyeIcon } from 'components/icons/font-awesome'

import { clearStr } from './log-helper'
import { SearchContext } from 'hooks/context/search-context'

import styles from './styles.module.less'

const CopyBtn = ({ text = '' }) => {
  return (
    <CopyToClipboard text={clearStr(text)}>
      <IconButton
        title='Copy'
        icon={<CopyIcon />}
        className={styles.button}
        onClick={() => message.success('Log row copied')} />
    </CopyToClipboard>
  )
}

const RawPrintBtn = ({ showRaw, setShowRaw }) => {
  return <IconButton
    title={showRaw ? 'Prettify' : 'Show raw data'}
    icon={showRaw ? <EyeIcon /> : <CodeIcon />}
    onClick={() => setShowRaw(!showRaw)}
    className={styles.button} />
}

const LogRow = ({ logs, errorId, simplify, text, extraAction, isStacktrace = false, collapsed = true, className, title, collapseStringAfterLength, loading, drawer, highlighted }) => {
  const { keywords } = useContext(SearchContext)

  const [showRaw, setShowRaw] = useState(false)

  const textToCopy = text || logs?.map(item => item?.logline)?.join('\n')
  const invocationStartTime = logs && logs.length > 0 ? logs[0].timestamp : null

  return (
    <Section
      title={title}
      titleUpperCase
      wrapperClassName={className}
      loading={loading}
      actionsClassName={styles.buttons_wrapper}
      actions={(
        <>
          <CopyBtn text={textToCopy} />
          <RawPrintBtn showRaw={showRaw} setShowRaw={setShowRaw} />
          {extraAction}
        </>)}
    >
      {(!text && !logs)
        ? <p className={styles.pre}>{`No ${title || 'logs'} found`}</p>
        : showRaw
          ? <RawLog
            dataToDisplay={text || logs}
            isText={text}
            errorId={errorId}
            logStyle={classnames(styles.pre, drawer && styles.no_padding)}
            simplify={simplify}
            isStacktrace={isStacktrace} />
          : <PrettyLog
            dataToDisplay={text || logs}
            isText={text}
            errorId={errorId}
            highlighted={keywords}
            logStyle={classnames(styles.pre, drawer && styles.no_padding)}
            simplify={simplify}
            invocationStartTime={invocationStartTime}
            collapsed={collapsed}
            collapseStringAfterLength={collapseStringAfterLength} />}
    </Section>
  )
}

export default LogRow
