import React from 'react'
import { Form } from 'antd'

import Password from 'containers/auth/form-items/password'
import ConfirmPassword from 'containers/auth/form-items/confirm-password'
import Submit from 'components/antd/form/submit'

const ResetPassword = ({ loading, onSubmit }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  const validatePasswords = (rule, value) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(rule.message))
  }

  return <Form form={form} onFinish={onSubmitWrapper}>
    <Password />
    <ConfirmPassword validator={validatePasswords} />
    <Submit loading={loading} text='Reset password' />
  </Form>
}

export default ResetPassword
