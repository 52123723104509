import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'
import { Form, Row, Col, Button, Radio } from 'antd'
import { isEmpty } from 'lodash'
import { sub } from 'date-fns'

import Submit from 'components/antd/form/submit'
import TargetSelector from 'features/target-selector'
import Badge from 'components/badge/severity'
import { Typography } from 'components/typography'
import { SearchIcon, AwsServiceIcon, ChevronDownIcon } from 'components/icons'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { useAllResourcesQuery, useResourceGroupsQuery } from 'hooks/api'
import { ScrollContext } from 'components/layout/content'
import QueryEditor from './query-editor'
import { useGetQueryQuery, useUpdateQueryQuery } from 'hooks/api/queries'
import { SearchContext } from 'hooks/context/search-context'
import { getResourcesByType } from 'lib/resources/filters'
import { Breakpoint, useResize } from 'hooks/resize'

import styles from './styles.module.less'

const SearchForm = ({ form, start, end, relativeSpan, setPayload, loading }) => {
  const resourcesWrapperRef = useRef()
  const { queryId } = useParams()
  const targetSelectorRef = useRef()
  const serviceValue = Form.useWatch('service', form)
  const targetsValue = Form.useWatch('targets', form)

  const { isVisibleUntil } = useResize()
  const { data: query } = useGetQueryQuery()
  const { data: resourcesAll } = useAllResourcesQuery()
  const { data: resourceGroups } = useResourceGroupsQuery()
  const { mutate: updateQuery } = useUpdateQueryQuery()
  const lambdas = getResourcesByType(resourcesAll, INVENTORY_SERVICES.Lambda.type)
  const targetsBadgeValue = targetsValue?.length === 0 && serviceValue === INVENTORY_SERVICES.Lambda.service ? 'all' : targetsValue?.length === 0 ? '...' : targetsValue?.length

  const { handleSetLocalStorage, editorState, setEditorState, queryRef, currentQuery, validateQuery, errorMsg } = useContext(SearchContext)
  const { fixed: fixedHeader, width, handleScrollTop } = useContext(ScrollContext)
  const [resourcesOpen, setResourcesOpen] = useState()
  const [layout, setLayout] = useState({ query: { flex: 'auto' }, targets: {}, btn: {} })

  const getServiceResources = (service) => {
    switch (service) {
      case INVENTORY_SERVICES.Lambda.service:
        return lambdas
      case INVENTORY_SERVICES.KinesisAnalytics.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.KinesisAnalytics.type)
      case INVENTORY_SERVICES.ECSService.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.ECSService.type)
      case INVENTORY_SERVICES.OpenSearch.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.OpenSearch.type)
      default: return []
    }
  }

  const prefixTarget = (target) => {
    if (!(resourceGroups || []).map(group => group.id).includes(target)) return target
    return `group:${target}`
  }

  const onSubmit = async (values) => {
    const validation = validateQuery(queryRef?.current)
    if (!validation.isValid) return

    if (queryId) {
      const payload = {
        ...form.getFieldsValue(),
        query: queryRef.current,
        name: query?.name || queryRef.current,
        targets: values?.targets?.map(identity => prefixTarget(identity)),
        ...(values?.service === 'opensearch' && { indices: ['INDEX_SLOW_LOGS', 'SEARCH_SLOW_LOGS', 'ES_APPLICATION_LOGS', 'AUDIT_LOGS'] }),
        relativeTimespan: 15
      }

      await updateQuery({ payload, id: query?.id })
    } else {
      if (!isEmpty(queryRef?.current)) {
        handleSetLocalStorage({ ...values, query: queryRef.current })
      }
    }

    setPayload({
      start: relativeSpan ? sub(new Date(), { minutes: relativeSpan }) : new Date(start).toISOString(),
      end: relativeSpan ? new Date().toISOString() : new Date(end).toISOString(),
      query: queryRef.current,
      targets: values?.targets?.map(identity => prefixTarget(identity)),
      service: values?.service,
      ...(values?.service === 'opensearch' && { indices: ['INDEX_SLOW_LOGS', 'SEARCH_SLOW_LOGS', 'ES_APPLICATION_LOGS', 'AUDIT_LOGS'] }),
      groupBy: 'executions',
      limit: 30
    })

    setResourcesOpen(false)
  }

  // handles submit with enter in editor
  const handleSubmit = () => {
    const values = form.getFieldsValue()
    onSubmit(values)
    setResourcesOpen(false)
  }

  const handleFormLayout = () => {
    const blocks = editorState.getCurrentContent().getBlocksAsArray()?.length

    if (blocks > 1 || isVisibleUntil(Breakpoint.md)) return setLayout({ query: { span: 24 }, targets: { span: 18 }, btn: { span: 6 } })
    else return setLayout({ query: { flex: 'auto' }, targets: {}, btn: {} })
  }

  // handles the layout change when editor has more than one row
  useEffect(handleFormLayout, [editorState])

  // handles fetch when opening saved or history queries
  useEffect(() => {
    const validation = validateQuery(currentQuery?.query)
    if (isEmpty(currentQuery) || !validation.isValid) return
    form.setFieldsValue({ targets: currentQuery?.targets?.map(target => target.includes(':') ? target?.split(':')[1] : target) })

    setPayload({
      start: relativeSpan ? sub(new Date(), { minutes: relativeSpan }) : new Date(start).toISOString(),
      end: relativeSpan ? new Date().toISOString() : new Date(end).toISOString(),
      query: currentQuery?.query,
      targets: currentQuery?.targets?.map(identity => prefixTarget(identity)),
      service: currentQuery?.service,
      ...(currentQuery?.service === 'opensearch' && { indices: ['INDEX_SLOW_LOGS', 'SEARCH_SLOW_LOGS', 'ES_APPLICATION_LOGS', 'AUDIT_LOGS'] }),
      groupBy: 'executions',
      limit: 30
    })
  }, [currentQuery?.id])

  useEffect(() => {
    if (!targetSelectorRef?.current) return
    if (resourcesOpen) {
      return targetSelectorRef.current.focus()
    }
    return targetSelectorRef.current.blur()
  }, [resourcesOpen])

  useEffect(() => {
    if (!form.isFieldTouched('service')) return
    // when currentQuery targets matches changes serviceValue, set these as targets
    form.setFieldsValue({ targets: currentQuery?.service === serviceValue ? currentQuery?.targets : [] })
    form.validateFields()
  }, [serviceValue])

  return (
    <>
      <Form form={form} className={styles.form} onFinish={onSubmit}>
        <Row gutter={[0, 0]} wrap={layout?.query?.flex !== 'auto'}>
          <Col {...layout.query} className={styles.editor_container}>
            <Form.Item name='query' >
              <div className={styles.editor_wrapper}>
                <QueryEditor editorState={editorState} setEditorState={setEditorState} handleSubmit={handleSubmit} />
              </div>
            </Form.Item>
          </Col>
          <Col {...layout.targets}>
            <Button className={classnames(styles.resources_btn, { [styles.column]: !isEmpty(layout.targets) })} onClick={() => setResourcesOpen(!resourcesOpen)}>
              <AwsServiceIcon service={serviceValue} />
              Resources
              <span className={styles.badge_wrapper}>
                <Badge text={targetsBadgeValue} size='sm'></Badge>
              </span>
              <ChevronDownIcon className={styles.arrow} rotation={resourcesOpen ? 180 : 0} />
            </Button>
          </Col>
          <Col {...layout.btn}>
            <Submit
              disabled={errorMsg}
              text={queryId ? 'Save & search' : 'Search'}
              className={styles.search_btn}
              loading={loading} />
          </Col>

          <div className={classnames(styles.resources_wrapper, { [styles.visible]: resourcesOpen })} ref={resourcesWrapperRef}>
            <Form.Item name='service' className={styles.radio_wrapper}>
              <Radio.Group>
                <Radio.Button value={INVENTORY_SERVICES.Lambda.service} className={styles.radio}>
                  <span className={styles.radio_content}>
                    Lambda <AwsServiceIcon service={INVENTORY_SERVICES.Lambda.service} size='sm' />
                  </span>
                </Radio.Button>
                <Radio.Button value={INVENTORY_SERVICES.ECSService.service} className={styles.radio}>
                  <span className={styles.radio_content}>
                    ECS <AwsServiceIcon service={INVENTORY_SERVICES.ECSService.service} size='sm' />
                  </span>
                </Radio.Button>
                <Radio.Button value={INVENTORY_SERVICES.KinesisAnalytics.service} className={styles.radio}>
                  <span className={styles.radio_content}>
                    Kinesis Analytics <AwsServiceIcon service={INVENTORY_SERVICES.KinesisAnalytics.service} size='sm' />
                  </span>
                </Radio.Button>
                <Radio.Button value={INVENTORY_SERVICES.OpenSearch.service} className={styles.radio}>
                  <span className={styles.radio_content}>
                    OpenSearch <AwsServiceIcon service={INVENTORY_SERVICES.OpenSearch.service} size='sm' />
                  </span>
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            {/* limit to 20 resources */}
            <div className={styles.select_wrapper}>
              <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.service !== curValues.service}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name='targets'
                      rules={[{ required: getFieldValue('service') !== INVENTORY_SERVICES.Lambda.service, type: 'array', max: 20, message: 'Choose at least one target resource' }]}>
                      <TargetSelector
                        solid={false}
                        resources={getServiceResources(getFieldValue('service'))}
                        resourceGroups={getFieldValue('service') === INVENTORY_SERVICES.Lambda.service ? resourceGroups : null}
                        open={resourcesOpen}
                      />
                    </Form.Item>)
                }}
              </Form.Item>
            </div>
          </div>
        </Row>
      </Form>

      {fixedHeader && (
        <div className={styles.fixed_bar} style={{ width }} onClick={handleScrollTop}>
          <Row justify='space-between'>
            <Col flex='auto' className={styles.box}>
              <div className={styles.row}>
                <Typography.Paragraph uppercase size='sm'>Query </Typography.Paragraph>
                <SearchIcon />
              </div>
              <Typography.Paragraph uppercase>{form.getFieldsValue(['query']).query}</Typography.Paragraph>
            </Col>
            <Col flex='200px' className={classnames(styles.box, styles.resources_box)}>
              <Typography.Paragraph uppercase size='sm'>Resources</Typography.Paragraph>
              <Typography.Paragraph uppercase dark bold margin='sm'>{targetsBadgeValue}</Typography.Paragraph>
            </Col>
          </Row>
        </div>)}
    </>
  )
}

export default SearchForm
