import { combineReducers } from 'redux'
import { GET_PRICES } from 'actions/billing.actions'

function list (state = [], action) {
  switch (action.type) {
    case GET_PRICES:
      return action.payload
  }
  return state
}

export default combineReducers({ list })
