import { apiVersion, paths } from '../index'

export const ORGANIZATIONS = 'organizations'
export const ORGANIZATIONS_ACTIVE = [ORGANIZATIONS, 'active']
export const ORGANIZATIONS_LIST = [ORGANIZATIONS, 'list']

export const ORGANIZATION_INVITATIONS = [ORGANIZATIONS, 'invitations', 'list']

export const USERS = 'users'
export const USER_ACTIVE = [USERS, 'active']
export const USERS_LIST = [USERS, 'list']

export const ACCOUNTS = 'accounts'
export const ACCOUNTS_LIST = [ACCOUNTS, 'list']

export * from './get-organizations'
export * from './create-organization'
export * from './get-organization'
export * from './get-organization-invitations'
export * from './update-organization'

export * from './users/get-users'
export * from './users/get-user'
export * from './users/update-user'
export * from './users/update-user-permissions'
export * from './users/delete-user'
export * from './users/delete-invitation'
export * from './users/put-invitation'
export * from './users/post-invitation'

export * from './accounts/get-accounts'
export * from './accounts/create-account'
export * from './accounts/update-account'

export * from './suspension/suspend-organization'
export * from './suspension/unsuspend-organization'

export const organizationsPaths = {
  filter: (path, query) => paths.filter(path, query),
  organizations: () => `${apiVersion}/organizations`,
  organization: (organizationId) => `${organizationsPaths.organizations()}/${organizationId}`,
  users: (organizationId) => `${organizationsPaths.organization(organizationId)}/users`,
  user: (organizationId, userId) => `${organizationsPaths.users(organizationId)}/${userId}`,
  userPermissions: (organizationId, userId) => `${organizationsPaths.user(organizationId, userId)}/permissions`,
  accounts: (organizationId) => `${organizationsPaths.organization(organizationId)}/accounts`,
  account: (organizationId, accountId) => `${organizationsPaths.accounts(organizationId)}/${accountId}`,
  invitations: (organizationId) => `${organizationsPaths.organization(organizationId)}/invitations`,
  invitation: (organizationId, invitationId) => `${organizationsPaths.invitations(organizationId)}/${invitationId}`
}

export const suspensionPaths = {
  organizationSuspension: (organization) => `${apiVersion}/organizations/${organization}/suspension`,
  accountSuspension: (account) => `${apiVersion}/accounts/${account}/suspension`
}
