import React from 'react'
import { Dropdown, Popconfirm, Switch } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ActionsDropdownButton } from 'components/buttons'

const Extra = ({ item, onDelete }) => {
  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <Dropdown
        menu={{
          items: [{
            key: 'delete',
            label: (
              <Popconfirm
                title={`Are you sure you want to delete ${item.name}?`}
                onConfirm={() => onDelete(item.id)}
              >
                <span>Delete</span>
              </Popconfirm>
            )
          }]
        }}>
        <ActionsDropdownButton />
      </Dropdown >
    </PermissionsGate>
  )
}

const Actions = ({ item, onSwitch, onDelete }) => {
  if (!item) return null

  return (
    <React.Fragment>
      <PermissionsGate
        scopes={[SCOPES.canEdit]}
        errorProps={item.disabled ? { disabled: true, unCheckedChildren: 'MUTED' } : null}
      >
        <Switch defaultChecked={!item.disabled} checked={!item.disabled} onChange={onSwitch} />
      </PermissionsGate>
      <Extra item={item} onDelete={onDelete} />
    </React.Fragment >
  )
}

export default Actions
