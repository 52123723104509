import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListHeader from 'features/side-list/header'
import ListSearch from 'features/side-list/search'
import IconButton from 'components/buttons/icon/index'
import DashboardListItem from './item'

import { useAccountRoutes } from 'containers/routes'
import { useDashboardsQuery, useCreateDashboardQuery } from 'hooks/api'
import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { SplitViewContext } from 'features/layout/split-pane'

import { AddIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

const mutations = ({ createDashboard }) => {
  return {
    create: () => {
      const payload = {
        name: 'My dashboard'
      }

      createDashboard(payload)
    }
  }
}

const DashboardsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { dashboardId: selected } = useParams()
  const { handleListClose } = useContext(SplitViewContext)

  const [searchItems, setSearchItems] = useState([])

  const { data: dashboards, isLoading } = useDashboardsQuery()
  const { mutate: createDashboard } = useCreateDashboardQuery(handleListClose)

  const loading = isLoading || !dashboards
  const handlers = mutations({ createDashboard })

  const onSelect = (identity) => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })

    history.push({ pathname: routes.dashboards.dashboard.url({ dashboardId: identity }), search: newValue })
    handleListClose()
  }

  const handleSearch = () => {
    if (!dashboards) return
    const items = filterListFromQuery(dashboards, [], search)
    setSearchItems(items)
  }

  useEffect(handleSearch, [search, dashboards])

  return (
    <List
      loading={loading}
      items={searchItems}
      selected={selected}
      renderItem={DashboardListItem}
      itemHeight={41}
      onSelect={(item) => onSelect(item.id)}
      search={<ListSearch />}
      header={
        <ListHeader
          total={dashboards?.length}
          searchTotal={dashboards?.length}
          overviewURL={routes.dashboards.url()}
          extra={
            <PermissionsGate scopes={[SCOPES.canEdit]}>
              <IconButton
                className={styles.action}
                icon={<AddIcon />}
                title='New dashboard'
                onClick={() => handlers.create()}
              />
            </PermissionsGate>
          }
          title='New dashboard'
        />
      }
      renderEmpty={<ListEmpty title='No dashboards found' />}
    />
  )
}

export default DashboardsList
