import { useMemo } from 'react'
import { flatten } from 'lodash'

import { useResourceGroupsQuery, useSearchLogMatchesQuery } from 'hooks/api'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { prefixTarget } from 'lib/targets-helpers'

const formatPayload = ({ query, start, end, groups }) => {
  if (!query || !groups || query?.service !== INVENTORY_SERVICES?.Lambda.service) return null

  return {
    start,
    end,
    filters: {
      queryIds: [query?.id]
    },
    groupBy: 'executions',
    targets: query?.targets?.map(identity => prefixTarget(identity, groups)),
    limit: 100

  }
}

const useLogMatchesFetcher = ({ query, start, end }) => {
  const { data: groups } = useResourceGroupsQuery()

  const payload = useMemo(() => formatPayload({ query, start, end, groups }), [query, start, end, groups])

  const { data, isFetching, isSuccess } = useSearchLogMatchesQuery(payload)
  const flattened = flatten(data?.pages?.map(page => page.data))

  return useMemo(() => ({
    data: flattened,
    isFetching,
    isSuccess
  }), [data, isFetching, isSuccess])
}

export default useLogMatchesFetcher
