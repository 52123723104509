import React from 'react'
import { Line } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import { formatDuration } from 'components/charts/utils'
import ChartContainer from 'components/charts/container'
import { EVENTBRIDGE_START_LATENCY } from 'containers/inventory/details/resource/metrics-tabs/services/eventbridge-eventbus'

const EventBridgeLatencyChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [EVENTBRIDGE_START_LATENCY]: data.EVENTBRIDGE_EVENTBUS_START_LATENCY
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => [formatDuration(value), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={EVENTBRIDGE_START_LATENCY}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
      formatYTicks={value => formatDuration(value)}
    >
      <Line name='Latency' dataKey={EVENTBRIDGE_START_LATENCY} type='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />
    </ChartContainer>
  )
}

export default EventBridgeLatencyChart
