import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { Changeset, insightsPaths as paths, INSIGHTS_LIST, INSIGHT_ACTIVE, INSIGHT_RULES_LIST } from './index'
import { parseInsightRule } from 'containers/insights'

const updateInsight = async (account: string, identity: string, payload: Changeset) => {
  const data = await apiFetch(paths.rule(account, identity), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateInsightRule = () => {
  const { ruleId } = useParams() as any
  const rule = parseInsightRule(ruleId)
  const queryClient = useQueryClient()

  const { account } = useOrganization() as any

  return useMutation(
    (payload: Changeset) => updateInsight(account?.id, rule, payload),
    {
      onMutate: (payload: Changeset) => {
        const previousRulesList: object[] | undefined = queryClient.getQueryData([...INSIGHT_RULES_LIST, account.id])
        const previousRule: object | undefined = queryClient.getQueryData([...INSIGHT_ACTIVE, rule])
        if (!previousRule) return { previousRule, previousRulesList }

        const updatedRule: any = { ...previousRule, ...payload }
        queryClient.setQueryData([...INSIGHT_ACTIVE, rule], updatedRule)
        queryClient.setQueryData([...INSIGHT_RULES_LIST, account.id], previousRulesList?.map((rule: any) => rule.id === updatedRule.id ? updatedRule : rule))

        return { previousRule, previousRulesList }
      },  
      onError: (err, newItem, context: any) => {
        if (!err) return
        queryClient.setQueryData([...INSIGHT_RULES_LIST, account.id], context.previousRulesList)
        queryClient.setQueryData([...INSIGHT_ACTIVE, rule], context.previousRule)
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...INSIGHTS_LIST, account.id])
      }
    }
  )
}
