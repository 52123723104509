import React from 'react'
import ReactJson from 'react-json-view'

const JsonPrettifier = ({ jsonObj, collapsed = true, collapseStringAfterLength = 100, displayDataTypes = false, name = false, enableClipboard = false }) => {
  return (
    <ReactJson style={{ width: '100%', overflowWrap: 'anywhere', fontSize: '12px' }} src={jsonObj} enableClipboard={enableClipboard} collapsed={collapsed} collapseStringsAfterLength={collapseStringAfterLength} displayDataTypes={displayDataTypes} name={name} />
  )
}

export default JsonPrettifier
