import React from 'react'
import { Tooltip } from 'antd'
import Tag from './tag'

import styles from './styles.module.less'

const InventoryTags = ({ tags, inverse }) => {
  return (
    <div className={styles.container}>
      <h5 className={styles.title}>Tags</h5>
      <div className={styles.tags}>
        {tags.map(tag =>
          <Tooltip mouseEnterDelay={1} title={`${tag.name}: ${tag.value}`} key={tag.name}>
            <Tag name={tag.name} value={tag.value} className={inverse ? styles['inverse-tag'] : styles.tag} />
          </Tooltip>)}
      </div>
    </div>
  )
}

export default InventoryTags
