import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { alarmsPaths as paths, ALARMS_RULES_LIST } from './index'
import { useAccountRoutes } from 'containers/routes'

const deleteAlarm = async (account, identity) => {
  const data = await apiFetch(paths.alarm(account, identity), { method: 'DELETE' })
  return data
}

export const useDeleteAlarm = () => {
  const queryClient = useQueryClient()

  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()
  return useMutation(
    alarmId => deleteAlarm(account.id, alarmId),
    {
      onMutate: (alarmId) => {
        const previousAlarmRules = queryClient.getQueryData([...ALARMS_RULES_LIST, account.id])

        const newList = previousAlarmRules?.filter(item => item.id !== alarmId)
        queryClient.setQueryData([...ALARMS_RULES_LIST, account.id], newList)

        return { previousAlarmRules }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...ALARMS_RULES_LIST, account.id], context.previousAlarmRules)
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...ALARMS_RULES_LIST, account.id])
        history.push(routes.alarms.url())
      }
    }
  )
}
