import { combineReducers } from 'redux'
import { get } from 'lodash'
import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_INVOICE,
  POST_SUBSCRIPTION,
  PUT_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_ITEM,
  GET_INVOICE_PREVIEW
} from 'actions/billing.actions'

function list (state = [], action) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
      return action.payload
    case POST_SUBSCRIPTION:
      return { ...state, [action.subscriptionType]: action.payload }
    case PUT_SUBSCRIPTION:
      return { ...state, [action.subscriptionType]: action.payload }
    case DELETE_SUBSCRIPTION:
      return { ...state, [action.subscriptionType]: undefined }
    case DELETE_SUBSCRIPTION_ITEM:
      const type = action.subscriptionType
      const items = get(state, [type, 'items'], [])
      delete items[action.itemType]
      return {
        ...state,
        [type]: {
          ...(get(state, type, [])),
          items
        }
      }
  }
  return state
}

function invoices (state = [], action) {
  switch (action.type) {
    case GET_SUBSCRIPTION_INVOICE:
      return { ...state, [action.subscriptionType]: action.payload }
    case DELETE_SUBSCRIPTION:
      return { ...state, [action.subscriptionType]: undefined }
  }
  return state
}

function invoiceTotalPreview (state = [], action) {
  switch (action.type) {
    case GET_INVOICE_PREVIEW:
      return action.payload
  }
  return state
}

export default combineReducers({
  list, invoices, invoiceTotalPreview
})
