import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { usageServicePaths as paths, ORG_INGESTION } from './index'
import { keyBy } from 'lodash'
import { sub, getTime } from 'date-fns'
import { useOrganization } from 'hooks/context/organization-context'

const getOrganizationDataIngestionHistogram = async (organization, { from, until }) => {
  const body = JSON.stringify({ from, until })
  const data = await apiFetch(paths.organizationDataIngestionHistogram(organization), { method: 'POST', body })

  return keyBy(data?.filter(item => !item.error), (accountUsage) => accountUsage?.account?.id)
}

export const useOrgIngestionQuery = (payload, condition = true) => {
  const { organization } = useOrganization()

  const from = payload?.start || getTime(sub(new Date(), { days: 30 }))
  const until = payload?.end || Date.now()

  return useQuery(
    [...ORG_INGESTION, organization?.id],
    () => getOrganizationDataIngestionHistogram(organization?.id, ({ from, until })),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!condition
    }
  )
}
