import { ChannelTypes } from 'lib/alerting-constants'

const mutations = ({ channel, history, createChannel, deleteChannel, updateChannel }) => {
  const allowedTypes = [
    ChannelTypes.EMAIL,
    ChannelTypes.WEBHOOK,
    ChannelTypes.SNS
  ]

  return {
    create: async (payload) => {
      if (!allowedTypes.includes(payload.type)) return
      createChannel({ payload })
      history.goBack()
    },
    update: async (payload) => {
      updateChannel(payload)
      history.goBack()
    },
    remove: async () => {
      deleteChannel(channel?.id)
      history.goBack()
    }
  }
}

export default mutations
