import React, { useCallback } from 'react'
import { Bar } from 'recharts'

import colors from 'lib/colors'
import { chartHeight } from 'components/charts/utils'
import Loading from 'components/layout/content/loading'
import ChartContainer from 'components/charts/container'
import FixedChartWrapper from 'components/charts/fixed-chart-wrapper'

import { useErrorMetricsQuery } from 'hooks/api'

const ErrorsChart = ({ error, start, end, range, showFixed }) => {
  const { data, isLoading } = useErrorMetricsQuery({ key: 'errors', options: { dimensions: { resources: [{ id: error?.errorHash }] } } }, start, end)

  const mapData = useCallback((data) => {
    return data?.datapoints?.[error?.errorHash]?.map(datapoint => ({
      date: datapoint?.date,
      occurrences: datapoint.sum
    }))
  }, [data])
  if (isLoading) return <Loading height={chartHeight} />
  return (
    <>
      <ChartContainer height={chartHeight} data={mapData(data)} span={range}>
        <Bar dataKey='occurrences' fill={colors('chart', 'red')} barSize={4} name='Errors' />
      </ChartContainer>
      {showFixed && (
        <FixedChartWrapper>
          <ChartContainer height={180} data={mapData(data)} span={range} fixed>
            <Bar dataKey='occurrences' fill={colors('chart', 'red')} barSize={4} name='Errors' />
          </ChartContainer>
        </FixedChartWrapper>
      )}
    </>
  )
}

export default ErrorsChart
