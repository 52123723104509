import { ScrollContext } from 'components/layout/content'
import React, { createContext, useContext } from 'react'
import styles from './styles.module.less'
export const FixedChartContext = createContext(false)

const FixedChartWrapper = ({ children }) => {
  const { width } = useContext(ScrollContext)
  return (
    <FixedChartContext.Provider value={{ fixed: true, height: 180 }}>
      <section className={styles.fixed_chart} style={{ width }}>
        {children}
      </section>
    </FixedChartContext.Provider>
  )
}

export default FixedChartWrapper
