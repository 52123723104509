import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, billingPathsV1, PAYMENT_METHODS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getPaymentMethods = async (identity) => {
  const data = await apiFetch(paths.paymentMethods(identity))
  return data?.data
}

export const useGetPaymentMethods = ({ enabled } = { enabled: true }) => {
  const { organization } = useOrganization()

  return useQuery(
    [...PAYMENT_METHODS, organization.id],
    () => getPaymentMethods(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && enabled,
      retry: false
    }
  )
}

const getPaymentMethodsV1 = async (identity) => {
  const data = await apiFetch(billingPathsV1.paymentMethods(identity))
  return data
}

export const useGetPaymentMethodsV1 = ({ enabled = true }) => {
  const { organization } = useOrganization()

  return useQuery(
    [...PAYMENT_METHODS, 'v1', organization.id],
    () => getPaymentMethodsV1(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && enabled,
      retry: false
    }
  )
}
