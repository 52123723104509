import React, { memo, useContext } from 'react'

import ServiceMapIcons from 'components/icons/service-map-icons'
import { Shape } from '../index'

import styles from './styles.module.less'

const PADDING = 20

const ServiceNode = memo<{
  node: any,
  handleNodeClick: any,
  selected: boolean
}>(({ node, handleNodeClick, selected }) => {
  return (
    <g key={node.id} onClick={() => handleNodeClick(node.node)} style={{ cursor: 'pointer' }}>
      <g>
        <Shape node={node} selected={selected} />
        <ServiceMapIcons
          service={node.service}
          x={node.x - ((node.width - PADDING) / 2)}
          y={node.y - ((node.height - PADDING) / 2)}
          width={node.width - PADDING}
          height={node.height - PADDING}
        />
      </g>
      <foreignObject
        x={node.x - node.width / 2 - PADDING}
        y={node.y + node.height / 2 + 6}
        width={node.width + PADDING * 2}
        height={40}
        className={styles.container}
      >
        <p className={styles.label}>{node.label}</p>
        <p className={styles.description}>{node.description}</p>
      </foreignObject>
    </g>
  )
})

export default ServiceNode
