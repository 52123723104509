export const ALARMS = 'alarms'
export const ALARMS_RULES_LIST = [ALARMS, 'rule', 'list']
export const ALARM_ACTIVE = [ALARMS, 'active']
export const ALARMS_LIST = [ALARMS, 'list']

export * from './get-alarms'
export * from './get-alarm'
export * from './create-alarm'
export * from './update-alarm'
export * from './delete-alarm'
export * from './get-alarms-rules'
export * from './change-alarm-targets'

export const alarmsPaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (idenitity) => `/v1/accounts/${idenitity}`,
  alarms: (account) => `${alarmsPaths.account(account)}/alarms`,
  alarm: (account, identity) => `${alarmsPaths.alarms(account)}/${identity}`,
  targets: (account, alarm, identity) => `${alarmsPaths.alarm(account, alarm)}/targets/${identity}`
}
