import numeral from 'numeral'

import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'

import { getSparklineByName, getLast } from '../helpers'
import { formatDuration } from 'components/charts/utils'

export const HEALTHY = 'elb-healthy'
export const RESPONSE_TIME = 'elb-response-time'

const metrics = [{
  key: HEALTHY,
  icon: faBullseyeArrow,
  title: 'Targets',
  filter: (collection) => `${numeral(getLast(collection, 'ELB_TARGETGROUP_HEALTHY')).format('0a')}`,
  subvalue: (collection) => getSparklineByName(collection, 'ELB_TARGETGROUP_HEALTHY'),
  selectable: true
}, {
  key: RESPONSE_TIME,
  icon: faClock,
  title: 'Response time',
  filter: (collection) => formatDuration(getLast(collection, 'ELB_TARGETGROUP_RESPONSE_TIME') * 1000),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_TARGETGROUP_RESPONSE_TIME'),
  selectable: true
}]

export default metrics
