import React from 'react'
import { get } from 'lodash'
import { Divider } from 'antd'

import Subtotal from './subtotal'
import Tax from './tax'
import Discount from './discount'
import BillDate from './bill-date'
import Total from './total'

const Totals = ({ invoice, showDate = true, helpText } = {}) => {
  if (!invoice) return '-'
  const currency = get(invoice, 'currency')

  return (
    <>
      <Subtotal amount={get(invoice, 'subtotal', 0)} currency={currency} />
      <Tax amount={get(invoice, 'tax')} currency={currency} />
      <Discount data={get(invoice, 'discount.coupon')} currency={currency} />
      <Divider dashed />
      {showDate ? <BillDate date={get(invoice, 'billDate')} /> : null }
      <Total amount={get(invoice, 'total', 0)} currency={currency} tooltip={helpText} />
    </>
  )
}

export default Totals
