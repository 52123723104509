import React from 'react'

import styles from './styles.module.less'

const Table = (item, index, inverse) => {
  return <div key={index}>
    {item.title && <h4 className={styles.header}>{item.title}</h4>}
    <div className={inverse ? styles['inverse-details'] : styles.details}>
      <table>
        <tbody>
          {item.children.map(child => <tr key={child.key}>
            <th>{child.key}</th>
            <td>{child.value}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>
}

const Field = (item, index, inverse) => {
  return <div key={index}>
    {item.title && <h4 className={styles.header}>{item.title}</h4>}
    {item.value && <div className={inverse ? styles['inverse-details'] : styles.details}>
      <div className={styles.content}>{item.value}</div>
    </div>}
  </div>
}

const Metadata = ({ items = [], inverse }) => {
  return <div className={styles.metadata}>
    {items.map((item, index) => item.children ? Table(item, index, inverse) : Field(item, index, inverse))}
  </div>
}

export default Metadata
