import React from 'react'

import { CloseIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

const DeleteButton = (props) => {
  return <IconButton {...props} icon={<CloseIcon />} />
}

export default DeleteButton
