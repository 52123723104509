import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { APPSYNC_ERRORS } from 'containers/inventory/details/resource/metrics-tabs/services/appsync'

const AppSyncErrorsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [APPSYNC_ERRORS]: data.APPSYNC_ERRORS,
    errorsInternal: data.APPSYNC_ERRORS_5XX,
    errorsClient: data.APPSYNC_ERRORS_4XX
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0'
      span={span}
      tabKey={APPSYNC_ERRORS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={APPSYNC_ERRORS} stackId='a' fill={colors('chart', 'red')} name='Detected Errors' barSize={4} />
      <Bar dataKey='errorsClient' stackId='b' fill={colors('chart', 'redLight')} name='4XX Errors' barSize={4} />
      <Bar dataKey='errorsInternal' stackId='c' fill={colors('chart', 'redDark')} name='5XX Errors' />
    </ChartContainer>
  )
}

export default AppSyncErrorsChart
