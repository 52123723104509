import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const accountRedirects = (account, routes, search) => {
  const redirects = account && account.id
    ? routes.map(route => {
      return <Redirect
        key={`redirect.${route.path}`}
        from={route.path}
        to={{
          pathname: `/${account.id}${route.path}`,
          search
        }}
      />
    })
    : []
  return redirects.concat(routes.map(route =>
    <Route key={`route.${route.path}`} path={`/:accountId${route.path}`} component={route.component} />
  ))
}

export default accountRedirects
