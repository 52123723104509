import React from 'react'
import classnames from 'classnames'
import EditableTitle from 'features/editable-title'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import Actions from '../actions'
import styles from './styles.module.less'

const TextWidget = ({ widget, onDelete, onEdit, onDuplicate, onUpdateName, staticWidget }) => {
  return (
    <div className={classnames(styles.text_wrapper, styles.widget_container)}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            displayContent={widget?.definition?.text}
            value={widget?.definition?.text}
            onSubmit={(value) => onUpdateName(widget, value)}
            color='dark'
            size={widget?.definition?.size}
          />
        </div>
        <div className={styles.actions}>
          {!staticWidget && (<IconButton icon={<DragIcon />} className='dbDragHandle' />)}
          <Actions
            widget={widget}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </div>
      </div>
    </div >
  )
}

export default TextWidget
