import React from 'react'
import { Item } from 'components/items'
import Empty from 'components/layout/content/empty'
import { useDelegationsQuery } from 'hooks/api'
import { getEventUrl } from 'lib/event-helpers'

import { useAccountRoutes } from 'containers/routes'

const Events = ({ events }) => {
  const routes = useAccountRoutes()
  const { data: delegations } = useDelegationsQuery()

  const eventItems = events?.map(event => ({
    url: getEventUrl(event, routes), // open drawer?
    title: event.name,
    type: event.type,
    lastOccurred: event.lastOccurredAt,
    time: event.openedAt,
    severity: event.severity,
    description: `${event?.resource?.name} | ${delegations?.find(item => item.id === event.resource.delegation)?.name}` // add delegation
  }))

  if (!events.length) return <Empty title="No events available" height='100%' />

  return eventItems.map((event, index) => <Item.Event key={index} item={event} />)
}

export default Events
