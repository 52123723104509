import { Auth } from '@aws-amplify/auth'
import { fetchJSON } from 'lib/api-fetch/fetch-json'
import { ApiError } from 'lib/api-fetch/api-error'

export class ApiFetch {
  constructor (options) {
    this._apiBase = CONFIG.API_URL
    this._options = options || {}
  }

  async fetch (path, options) {
    const args = {
      method: 'GET',
      headers: new Headers(),
      ...options
    }

    this.setDefaultContentType(args.headers)
    if (this._options.authorization) {
      await this.setDefaultAuthorization(args.headers)
    }

    const url = this._apiBase + path
    const fetch = () => fetchJSON.apply(window, [url, { ...args }])

    try {
      const { json: result } = await fetch()
      return result
    } catch (error) {
      if (!(error instanceof ApiError)) throw error

      if (error.response.status === 502 || error.response.status === 503) {
        const { json: result } = await fetch()
        return result
      }

      throw error
    }
  }

  setDefaultContentType (headers) {
    if (headers.has('Content-Type')) return

    headers.set('Content-Type', 'application/json')
  }

  async setDefaultAuthorization (headers) {
    if (headers.has('Authorization') && headers.get('Authorization')) return

    const token = await this.getToken()
    if (!token) return

    headers.set('Authorization', `Bearer ${token}`)
  }

  async getToken () {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession()
      return jwtToken
    } catch (e) {
      console.error(e)
    }
  }
}
