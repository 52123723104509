import React from 'react'
import { Divider } from 'antd'

import Badge from 'components/badge/severity'
import Section from 'components/layout/content/section'

import styles from './styles.module.less'

const ResourceTab = ({ data }) => {
  if (!data?.aws?.region && !data?.http) return null

  const { aws } = data
  const responseStatus = data?.http?.response?.status
  const responseSeverity = responseStatus <= 299 ? 'ok' : 'critical'

  return (
    <>
      <Divider />

      <Section title='Resources' titleUpperCase className={styles.wrapper}>
        {aws?.region && aws?.operation && (
          <>
            <table>
              <tbody>
                <tr>
                  <td>Region: </td>
                  <td>{data?.aws?.region?.toUpperCase()}</td>
                </tr>
                <tr>
                  <td>Operation: </td>
                  <td>{data?.aws?.operation}</td>
                </tr>
              </tbody>
            </table>
            <Divider />
          </>)
        }
        {data?.http && (
          <>
            <table>
              <tbody>
                {data?.http?.request?.url &&
                  <>
                    <tr>
                      <td>Request method: </td>
                      <td>{data.http?.request?.method}</td>
                    </tr>
                    <tr>
                      <td>Request URL: </td>
                      <td style={{ fontFamily: 'monospace' }}>{data.http?.request?.url}</td>
                    </tr>
                  </>
                }

                <tr>
                  <td>Response: </td>
                  <td>
                    <Badge text={responseStatus} severity={responseSeverity} bordered />
                  </td>
                </tr>
                {data?.http?.response?.content_length
                  ? (
                    <tr>
                      <td>Response length:</td>
                      <td>{data?.http?.response?.content_length}</td>
                    </tr>)
                  : null}
              </tbody>
            </table>
          </>
        )}
        {aws?.api_gateway && (
          <>
            <Divider />
            <table>
              <tbody>
                <tr>
                  <td>Account ID: </td>
                  <td>{aws.api_gateway?.account_id}</td>
                </tr>
                <tr>
                  <td>Rest api ID: </td>
                  <td>{aws.api_gateway?.rest_api_id}</td>
                </tr>
                <tr>
                  <td>API stage: </td>
                  <td>{data?.aws?.api_gateway?.stage}</td>
                </tr>
                <tr>
                  <td>Request ID: </td>
                  <td>{data?.aws?.api_gateway?.request_id}</td>
                </tr>
              </tbody>
            </table>
          </>)}
      </Section >
    </>
  )
}

export default ResourceTab
