import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'

import Empty from 'components/charts/empty'
import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { RDS_NETWORK_TRAFFIC } from 'containers/inventory/details/resource/metrics-tabs/services/rds-instance'

const RDSNetworkTrafficChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [RDS_NETWORK_TRAFFIC]: data.RDS_NETWORK_INCOMING,
    messagesOut: data.RDS_NETWORK_OUTGOING
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = value => [`${Numeral(value).format('0.[0]b')}${value < 1 ? 'B' : ''}`, undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.0b'
      span={span}
      tabKey={RDS_NETWORK_TRAFFIC}
      formatTab={value => `${Numeral(value).format('0.[0]b')}${value < 1 ? 'B' : ''}`}
      formatTooltip={valueFormatter}
    >
      <Bar dataKey={RDS_NETWORK_TRAFFIC} fill={colors('chart', 'primaryDark')} name='Network recieved' barSize={4} />
      <Bar dataKey='messagesOut' fill={colors('chart', 'red')} name='Network transmitted' barSize={4} />
    </ChartContainer>)
}

export default RDSNetworkTrafficChart
