import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { NOTIFICATION_POLICY_LIST, policyPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const deleteNotificationPolicy = async (account, identity) => {
  await apiFetch(paths.policy(account, identity), { method: 'DELETE' })

  return identity
}

export const useDeleteNotificationPolicyQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    (identity) => deleteNotificationPolicy(account.id, identity),
    {
      onSuccess: (identity) => {
        queryClient.invalidateQueries([...NOTIFICATION_POLICY_LIST, account.id])
      }
    }
  )
}
