import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown } from 'antd'
import { uniq } from 'lodash'

import { useAccountRoutes } from 'containers/routes'
import Section from 'components/layout/content/section'

import IconButton from 'components/buttons/icon'
import { AddIcon, EmailIcon, SlackIcon, AwsServiceIcon, TeamsIcon } from 'components/icons'
import { AlarmIcon } from 'components/icons/font-awesome'
import { Item } from 'components/items'
import { useNotificationPoliciesQuery } from 'hooks/api'

import styles from './styles.module.less'
import { PermissionsGate, SCOPES } from '../../../../features/permissions-gate/index'

const getIcon = (type) => {
  switch (type) {
    case 'email':
      return <EmailIcon className={styles.icon} />
    case 'slack':
      return <SlackIcon className={styles.icon} />
    case 'webhook':
      return <AlarmIcon className={styles.icon} />
    case 'teamsbot':
      return <TeamsIcon className={styles.icon} />
    case 'sns':
      return <AwsServiceIcon service='sns' className={styles.icon} />
  }
}

const CHANNEL_OPTIONS = [
  { key: 'email', label: 'E-mail' },
  { key: 'slack', label: 'Slack' },
  { key: 'sns', label: 'AWS SNS' },
  { key: 'webhook', label: 'Webhook' },
  { key: 'teamsbot', label: 'Microsoft Teams Bot' }
]

const NotificationChannels = ({ loading, channels }) => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { data: policies } = useNotificationPoliciesQuery()

  const channelsWithPolicyAttached = uniq(policies?.reduce((acc, item) => ([...acc, ...item.channels]), []))

  return (
    <Section
      title='channels'
      titleUpperCase
      loading={loading}
      actions={
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]}>
          <Dropdown
            trigger={['click']}
            menu={{
              items: CHANNEL_OPTIONS,
              onClick: ({ key }) => history.push(routes.settings.notifications.channels.new.url({ type: key }))
            }}
          >
            <IconButton type='primary' onClick={() => false} icon={<AddIcon />} title='Connect a new channel' className={styles.icon} />
          </Dropdown>
        </PermissionsGate>
      }
    >
      {
        channels?.map(item => (
          <Item.Filter
            key={item.id}
            title={item.type}
            description={item?.email || item?.name}
            icon={getIcon(item.type)}
            url={routes.settings.notifications.channels.channel.url({ channelId: item.id })}
            statusBar={channelsWithPolicyAttached?.includes(item.id) ? 'success' : 'error'}
          />
        ))
      }
    </Section>
  )
}

export default NotificationChannels
