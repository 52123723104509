import React from 'react'
import Numeral from 'numeral'
import { Bar } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/elb-application-load-balancer'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [REQUESTS]: data.ELB_LOADBALANCER_REQUESTS,
    '2xx': data.ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_2XX,
    '3xx': data.ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_3XX,
    '4xx': data.ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_4XX,
    '5xx': data.ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_5XX
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[0]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={REQUESTS}
      formatTab={value => `${Numeral(value).format('0.[0]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey='2xx' stackId='a' fill={colors('chart', 'primary')} name='2XX' barSize={4} />
      <Bar dataKey='3xx' stackId='a' fill={colors('chart', 'primary')} name='3XX' barSize={4} />
      <Bar dataKey='4xx' stackId='a' fill={colors('chart', 'yellow')} name='4XX' barSize={4} />
      <Bar dataKey='5xx' stackId='a' fill={colors('chart', 'red')} name='5XX' barSize={4} />
    </ChartContainer>
  )
}

export default ELBRequestsChart
