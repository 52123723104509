import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { INSIGHT_ACTIVE, insightsPaths as paths } from './index'

const getInsightRule = async (account, identity) => {
  const data = await apiFetch(paths.rule(account, identity))
  return data
}

export const useInsightRuleQuery = (insightRule) => {
  const { account } = useOrganization()

  return useQuery(
    [...INSIGHT_ACTIVE, insightRule],
    () => getInsightRule(account.id, insightRule),
    {
      enabled: !!account?.id && !!insightRule,
      refetchOnWindowFocus: false,
      staleTime: Infinity
    }
  )
}
