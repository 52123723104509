import React from 'react'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './styles.module.less'

const WrapLink = ({ url, children }) => {
  if (url) {
    return <Link to={url}>{children}</Link>
  }

  return children
}

const Index = (props) => {
  const { title, args, returns, url, icon, iconTitle, iconUrl, isPrimitive } = props.item
  return (
    <div className={classnames(styles.item, isPrimitive && styles.primitive)}>
      <div className={classnames(styles.info)}>
        <div className={styles.header}>
          <WrapLink url={url}>{title}</WrapLink>
        </div>
        {args && <div className={styles.description}>{args}</div>}
        {returns && <div className={styles.description}>{returns}</div>}
      </div>
      <div className={styles.icons}>
        {icon && <Tooltip title={iconTitle}>
          <WrapLink url={iconUrl}>
            {icon}
          </WrapLink>
        </Tooltip>}
      </div>
    </div>
  )
}

export default Index
