import React from 'react'
import { Tooltip } from 'antd'

import styles from './styles.module.less'

export const PageHeader = ({ icon, title, subtitle, extra }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {React.isValidElement(title)
            ? title
            : <Tooltip trigger={'click'} title={title}><h1>{title}</h1></Tooltip>}
        </div>
        {extra && <div className={styles.actions}>{extra}</div>}
      </div>

      {subtitle && <div className={styles.footer}>{subtitle}</div>}
    </div>
  )
}
