import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Form } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import Input from 'components/antd/input'

const submit = (values, saveAccount) => {
  const payload = { name: values.name }
  saveAccount(payload)
}

const EditAccount = ({ account, saveAccount, saving }) => {
  const history = useHistory()

  return (
    <PermissionsGate scopes={[SCOPES.canEditAccount]} errorProps={{ disabled: true }}>
      <Form onFinish={values => submit(values, saveAccount)} layout='vertical'
        rules={[{ required: true }]}
        initialValues={{ name: account?.name }}>
        <Form.Item name='name' rules={[{ required: true, message: 'Account name cannot be empty' }]} label='Account name'>
          <Input placeholder='Account name' />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
          <Button type='link' onClick={history.goBack}>Cancel</Button>
        </Form.Item>
      </Form>
    </PermissionsGate>
  )
}

export default EditAccount
