import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { orderBy } from 'lodash'

import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'
import { EventStatus, Pillar, ServiceType, Severity, SeverityCount, Violations } from 'features/side-list/sorter'
import { parseInsightRule } from '../index'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import { useAccountRoutes } from 'containers/routes'
import RuleItem from './item'
import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { INSIGHTS_FILTERS } from './filters'
import { useAllResourcesQuery, useDelegationsQuery, useInsightsQuery, useInsightsRulesQuery } from 'hooks/api'
import { SplitViewContext } from 'features/layout/split-pane'

const InsightRulesList = () => {
  const { ruleId } = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const routes = useAccountRoutes()
  const { handleListClose } = useContext(SplitViewContext)

  const { data: rules, isLoading: loadingRules } = useInsightsRulesQuery()
  const { data: insights, isLoading: loadingInsights } = useInsightsQuery()
  const { isLoading: loadingResources, isSuccess: resourcesSuccess } = useAllResourcesQuery()
  const { isSuccess: delegationsSuccess } = useDelegationsQuery()

  const sorters = [SeverityCount, ServiceType, Severity, Pillar, Violations, EventStatus]
  const [sorter, setSorter] = useState(sorters[0])

  const [listItems, setListItems] = useState([])

  // useEffect(() => {
  //   if (!rules || !activeRule) return
  //   // setRules(rules.map(rule => rule.id === activeRule.id ? { ...rule, status: activeRule.status } : rule))
  //   setListItems(listItems.map(rule => rule.id === activeRule.id ? { ...rule, status: activeRule.status } : rule))
  // }, [activeRule?.status])

  const searchList = () => {
    if (!rules) return

    const results = filterListFromQuery(rules, INSIGHTS_FILTERS, search)
    setListItems(sortItems(results))
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const sortItems = (items) => {
    return sorter.sort
      ? sorter.sort(items)
      : orderBy(items, sorter.apply, (sorter?.order || 'asc'))
  }

  const sortList = () => {
    if (!rules) return
    const sortedListItems = sortItems(listItems)
    setListItems(sortedListItems)
  }

  const selectRule = (ruleId) => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })
    history.push({ pathname: routes.insights.rule.url({ ruleId: parseInsightRule(ruleId) }), search: newValue })
    handleListClose()
  }

  useEffect(sortList, [sorter])
  useEffect(searchList, [search, rules, insights?.length, resourcesSuccess, delegationsSuccess])

  return (
    <List
      loading={loadingInsights || loadingResources || loadingRules || !rules}
      items={listItems}
      renderItem={RuleItem}
      loadingMetrics={loadingInsights || loadingResources}
      onSelect={selectRule}
      selected={parseInsightRule(ruleId)}
      search={<ListSearch />}
      header={<ListHeader
        searchTotal={listItems?.length}
        total={rules?.length}
        sorters={sorters}
        onSorterChange={onSorterChange}
        selectedSorter={sorter.key}
        filters={INSIGHTS_FILTERS.reduce((acc, item) => {
          acc[item.key] = item.key
          return acc
        }, {})}
        view='insights'
        overviewURL={routes.insights.url()}

      />}
      renderEmpty={<ListEmpty title='No insight rules found' />}
    />
  )
}

export default InsightRulesList
