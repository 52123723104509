import moment from 'moment'
import React, { useMemo } from 'react'
import { flatten, groupBy, isEmpty, mergeWith, toNumber, orderBy } from 'lodash'
import { BarChart, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import colors from 'lib/colors'
import Empty from 'components/layout/content/empty'
import Loading from 'components/layout/content/loading'

import useBars from '../hooks/useBars'
import useTooltip from '../hooks/useTooltip'

import styles from './styles.module.less'
import { yAxisProps } from 'components/charts/utils'

const mergeValuesByDate = histogram => {
  const mergedByKey = histogram.reduce((carry, dayData) => {
    const current = carry[dayData.key] || {}
    carry[dayData.key] = mergeWith({ ...dayData }, current, (v1, v2, k) => {
      if (k === 'key') return v1
      if (v1 && v2) return toNumber(v1) + toNumber(v2)
      return toNumber(v1) || toNumber(v2)
    })
    return carry
  }, {})
  return Object.values(mergedByKey).sort((a, b) => b - a)
}

const prepareHistogram = (data = [], group = 'region', accounts) => {
  const keys = {}

  if (group === 'account') {
    const test = Object.entries(data).map(([accountId, accountData]) => {
      const histogram = accountData.data.map((item) => {
        const groupedStacks = groupBy(item.records, group)

        const all = Object.entries(groupedStacks).reduce((acc, [group, records]) => {
          const value = records.reduce((acc, record) => acc + record.count, 0)

          return acc + value
        }, 0)

        const accountName = accounts.find(item => item.id === accountId).name
        keys[accountName] = true

        return { key: moment(item.timestamp).startOf('day').valueOf(), [accountName]: all }
      })

      return histogram
    })

    return {
      keys: Object.keys(keys),
      histogram: mergeValuesByDate(flatten(test))
    }
  } else {
    const histogram = flatten(Object.values(data).map(value => value.data)).map((item) => {
      const groupedStacks = groupBy(item.records, group)
      const summedStacks = Object.entries(groupedStacks).reduce((acc, [group, records]) => {
        keys[group] = true
        acc[group] = records.reduce((acc, record) => acc + record.count, 0)

        return acc
      }, {})

      return {
        key: moment(item.timestamp).startOf('day').valueOf(),
        ...summedStacks
      }
    })

    return { keys: Object.keys(keys), histogram: mergeValuesByDate(histogram) }
  }
}

const NoData = () => {
  return (
    <Empty title='No data available yet.'>
      <div>There is no accounts selected or no resources usage available for the last 30 days.</div>
    </Empty>
  )
}

const UsageHistogramChart = ({ data, tab, loading, accounts }) => {
  const { keys, histogram } = useMemo(() => prepareHistogram(data, tab, accounts), [data, tab])
  const stacked = true
  const [bars, activeBar] = useBars({ keys, tab, fill: ({ tab, key }) => colors(tab, key), stacked })
  const tooltip = useTooltip({
    activeBar,
    className: styles.tooltip,
    heading: 'Resources: ',
    value: (value) => value
  })

  if (loading) return <Loading height={500} />
  if (isEmpty(data)) return <NoData />

  return (
    <ResponsiveContainer width='100%' height={500} debounce={300}>
      <BarChart data={orderBy(histogram, 'key')} margin={{
        top: 42, left: -8, bottom: 8, right: 8
      }}>
        <XAxis
          dataKey='key'
          stroke={colors('chart', 'grid')}
          tick={{ fill: colors('chart', 'text'), fontSize: 12 }}
          tickFormatter={tick => moment(tick).format('DD.MM.YYYY')}
        />
        <YAxis {...yAxisProps} />
        <Legend />
        {tooltip}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default UsageHistogramChart
