
import React from 'react'
import { useParams } from 'react-router-dom'
import numeral from 'numeral'

import { Line } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'

import { objectify } from 'lib/utils'
import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { useResourceQuery } from 'hooks/api'
import { RDS_STORAGE } from 'containers/inventory/details/resource/metrics-tabs/services/rds-instance'

const RDSStorageChart = ({ loading, data, height, span }) => {
  const { resourceId } = useParams()
  const { data: resource } = useResourceQuery(resourceId)

  if (loading || !data) return null

  const maxStorage = objectify(resource?.attributes)?.allocatedStorage * 1024 * 1024 * 1024

  const metrics = transform({
    storage: data.RDS_FREE_STORAGE_SPACE
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics?.map(metric => ({
    ...metric,
    max: maxStorage,
    [RDS_STORAGE]: maxStorage - metric.storage
  }))

  const tooltipFormatter = (value, name, props) => {
    return [numeral(value).format('0.0 b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={RDS_STORAGE}
      yTicksFormatting='0 b'
      formatTab={value => numeral(value).format('0.0 b')}
      formatTooltip={tooltipFormatter}
    >
      <Line dataKey={RDS_STORAGE} name='Used storage' line='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />
      <Line dataKey='max' name='Max storage' line='monotone' stroke={colors('chart', 'red')} strokeWidth={2} dot={false} />
    </ChartContainer>
  )
}

export default RDSStorageChart
