import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ORGANIZATIONS_ACTIVE } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getOrganization = async (organizationId) => {
  const data = await apiFetch(paths.organization(organizationId))

  return data
}

export const useOrganizationQuery = () => {
  const { organization } = useOrganization()

  return useQuery(
    [...ORGANIZATIONS_ACTIVE, organization.id],
    () => getOrganization(organization.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      refetchOnWindowFocus: false
    }
  )
}
