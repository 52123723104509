import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import classnames from 'classnames'

import styles from './styles.module.less'

export const IconLinkButton = ({ to, icon, tooltipText, onClick, text, className }) => {
  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement='topRight' overlayClassName={styles.tooltip}>
        <Link to={to} className={classnames(styles.link_icon, className)} onClick={onClick} >
          {icon}
        </Link>
      </Tooltip>
    )
  }
  return (
    <Link to={to} onClick={onClick} className={classnames(styles.link_icon, className)}>
      {icon} <span>{text}</span>
    </Link>
  )
}
