import { useInfiniteQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import querystring from 'query-string'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { resourcePaths as paths, RESOURCES_LIST } from './index'
import { useParams } from 'react-router-dom'

const getResources = async (account, { type, parent, page, limit } = {}, next) => {
  const query = querystring.stringify(omitEmpty({ type, parent, page, limit, next }))

  const data = await apiFetch(paths.filter(paths.resources(account), query))
  return data
}

export const useInfiniteResourceQuery = (key, filters) => {
  const { resourceId } = useParams()
  const { account } = useOrganization()

  return useInfiniteQuery(
    [...RESOURCES_LIST, resourceId, key],
    ({ pageParam }) => getResources(account.id, filters, pageParam),
    {
      enabled: !!account?.id && !!resourceId && !!filters,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => lastPage.next
    }
  )
}
