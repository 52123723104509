import { isEmpty } from 'lodash'
import { EVENT_TYPES } from 'lib/event-constants'
import { filterInventoryByService } from 'lib/resources/constants'

const getItemResource = (item) => Object.values(EVENT_TYPES).includes(item.type) ? item.resource : item

const searchDelegation = (item, delegations) => {
  return !isEmpty(delegations) ? delegations.includes(getItemResource(item).delegation) : true
}

const searchServices = (item, services) => {
  return !isEmpty(services) ? services.includes(getItemResource(item).type) : true
}

const searchQuery = (item, query) => {
  return JSON.stringify(item).toLowerCase().includes(query.toLowerCase())
}

const search = (items, query, filters = []) => {
  const delegations = filters.filter(item => item.type === 'delegation').map(item => item?.id)
  const services = filters.filter(item => item.type === 'service')
    .reduce((acc, item) => ([...acc, ...filterInventoryByService(item.id).map(service => service.type)]), [])

  if (isEmpty(query)) {
    if (isEmpty(filters)) return items
    else return items.filter(item => searchDelegation(item, delegations) && searchServices(item, services))
  }

  if (isEmpty(filters)) return items.filter(item => searchQuery(item, query))
  else {
    return items.filter(item =>
      searchDelegation(item, delegations) &&
      searchServices(item, services) &&
      searchQuery(item, query)
    )
  }
}

export default search
