import { useInfiniteQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { isEmpty } from 'lodash'

import { SEARCH, searchPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const searchLogs = async (account, payload, nextToken) => apiFetch(paths.logs(account), {
  method: 'POST',
  body: JSON.stringify({ ...payload, nextToken })
})

const serializePayload = (payload) => {
  if (!payload) return {}
  return {
    query: payload.query,
    service: payload.service,
    ...(payload.targets && { targets: payload.targets }),
    ...(payload.start && { start: payload.start }),
    ...(payload.end && { end: payload.end }),
    ...(payload.nextToken && { nextToken: payload.nextToken }),
    ...(payload.limit && { limit: payload.limit }),
    ...(payload.groupBy && { groupBy: payload.groupBy }),
    ...(payload.indices && { indices: payload.indices })
  }
}

export const useSearchLogsQuery = (data) => {
  const { account } = useOrganization()
  const payload = serializePayload(data)

  return useInfiniteQuery(
    [SEARCH, payload, account?.id],
    ({ pageParam }) => searchLogs(account?.id, payload, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      staleTime: 60 * 1000,
      enabled: !!account?.id && !isEmpty(payload),
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
