import { useEffect, useState } from 'react'
import { transform } from 'hooks/nodes/transform'
import { initMetrics, setMetrics } from 'hooks/nodes/metrics'

const parseSegments = (segments, parent, nodes = {}, edges = {}, metrics = initMetrics()) => {
  if (!segments) {
    return {
      nodes,
      edges
    }
  }

  segments.forEach((segment) => {
    nodes[segment.id] = segment

    if (segment.inferred || segment.parent_id) {
      edges[segment.id] = segment.parent_id
    } else if (parent) {
      edges[segment.id] = parent
    } else {
      edges[segment.id] = undefined
    }

    setMetrics(segment, metrics)

    if (segment.subsegments) {
      parseSegments(segment.subsegments, segment.id, nodes, edges, metrics)
    }
  })

  return {
    nodes,
    edges,
    metrics
  }
}

const deepCountChildren = (node) => {
  return node.children.filter(child => !child.inferred).length + node.children.reduce((acc, child) => {
    return acc + deepCountChildren(child)
  }, 0)
}

const buildTree = (nodes, edges, parent, current) => {
  const childrenIds = Object.keys(edges)
    .filter(key => edges[key] === current)

  const children = childrenIds
    .map(childId => buildTree(nodes, edges, current, childId))
    .sort((a, b) => a.start_time - b.start_time)

  const node = {
    ...nodes[current],
    parent_id: parent,
    children
  }

  return {
    ...node,
    deepCountChildren: deepCountChildren(node)
  }
}

const findMainSegment = (segmentId, node) => {
  if (segmentId === node.id) {
    return node
  }

  return node.children.find(child => findMainSegment(segmentId, child))
}

const search = (node, query, services) => {
  const queryResult = query && query.length ? node.searchable.includes(query.toLowerCase()) : true
  const servicesResult = services && services.length ? services.includes(node.service) : true

  const childrenResults = node.children
    .map(child => search(child, query, services))
    .filter(Boolean)

  const result = queryResult && servicesResult
  if (!result && !childrenResults.length) return

  return {
    ...node,
    children: childrenResults
  }
}

const searchByServices = (node, services = []) => {
  const result = services.includes(node.service)

  const childrenResults = node.children
    .map(child => searchByServices(child, services))
    .filter(Boolean)

  if (!result && !childrenResults.length) return

  return {
    ...node,
    children: childrenResults
  }
}

export const useNodes = (traces, options = {}) => {
  const { segmentId, inferred } = options
  const [tree, setTree] = useState({})
  const [nodes, setNodes] = useState({})
  const [metrics, setMetrics] = useState({})
  const [transformed, setTransformed] = useState({})
  const [mainSegment, setMainSegment] = useState({})

  useEffect(() => {
    const start = traces[0]
    const formatted = start?.segments?.map(container => container.Document)
    const { nodes, edges, metrics } = parseSegments(formatted, null)

    setNodes(nodes)
    setMetrics(metrics)

    // find nodes that have a parent, but it doesn't exist in our nodes
    const weirdNodes = Object.keys(edges).filter(key => !nodes[edges[key]])
    const weirdTree = {
      name: 'All Segments',
      origin: 'Dashbird',
      start_time: metrics.min_start_time,
      end_time: metrics.max_end_time,
      children: weirdNodes
        .map(node => buildTree(nodes, edges, node.parent_id, node))
        .sort((a, b) => a.start_time - b.start_time)
    }

    const transformed = transform(weirdTree, null, inferred)
    const mainSegment = findMainSegment(segmentId, transformed)

    setMainSegment(mainSegment)
    setTransformed(transformed)

    setTree(weirdTree)
  }, [traces.map(t => t.id).join('')])

  return {
    tree,
    nodes, // can use Object.keys(nodes).map(key => nodes[key]) to get the array of objects
    metrics,
    transformed,
    mainSegment,
    search,
    searchByServices
  }
}
