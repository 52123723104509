import { apiVersion } from '../index'

export const INVITATION = 'invitation'
export const USER_INVITATIONS = ['user', 'invitations']

export * from './get-invitations'
export * from './update-invitation'
export * from './get-user-invitations'

export const invitationPaths = {
  invitations: () => `${apiVersion}/invitations`,
  invitation: (invitationId) => `${invitationPaths.invitations()}/${invitationId}`
}

export const userPaths = {
  invitations: (userId) => `${apiVersion}/user/${userId}/invitations`
}
