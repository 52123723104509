import React, { useContext } from 'react'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { isEmpty } from 'lodash'

import { ScrollContext } from 'components/layout/content'
import Section from 'components/layout/content/section'
import Metrics from 'components/metrics'
import { SigmaIcon, SearchIcon } from 'components/icons'
import { AlertIcon, ArrowLeftIcon, ClockIcon, HistoryIcon } from 'components/icons/font-awesome'

const ScheduledSearchMetricsTabs = ({ selectedTab, setSelectedTab, query, count, queryLoading, metricsLoading }) => {
  const { fixed: fixedHeader, handleScrollTop } = useContext(ScrollContext)

  const handleTabClick = (tab) => {
    if (fixedHeader) return handleScrollTop()
    return setSelectedTab(tab)
  }

  const metricsTabs = [
    { title: 'Events', icon: <SigmaIcon />, value: count, selected: selectedTab === 'events' && !fixedHeader, onClick: () => handleTabClick('events'), loading: queryLoading || metricsLoading },
    { title: 'Configure', icon: <SearchIcon />, value: query?.query, selected: selectedTab === 'form' && !fixedHeader, onClick: () => handleTabClick('form'), queryLoading },
    { title: 'Severity', icon: <AlertIcon />, value: query?.severity, queryLoading },
    { title: 'Resources', icon: <ArrowLeftIcon />, value: isEmpty(query?.targets) ? 'ALL' : query?.targets?.length },
    { title: 'Frequency', icon: <ClockIcon />, value: `${query?.frequency} min` },
    ...(query?.lastOccurrenceAt ? [{ title: 'Last Occurrence', icon: <HistoryIcon />, value: `${formatDistanceToNowStrict(parseISO(query?.lastOccurrenceAt))} ago` }] : [])
  ]

  return (
    <>
      <Section cancelMargin>
        <Metrics items={metricsTabs} />
      </Section>

      {fixedHeader && (
        <Metrics
          items={metricsTabs}
          fixed
        />
      )}
    </>
  )
}

export default ScheduledSearchMetricsTabs
