import React from 'react'
import { countBy } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { getInventoryResources } from 'lib/resources/filters'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import Section from 'components/layout/content/section'
import { Delegation } from 'components/items/progress-item'
import { useAllResourcesQuery, useDelegationsQuery } from 'hooks/api'

const InventoryDelegations = () => {
  const { data: resourcesAll, isLoading } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()

  const { getValue: selectedDelegations, setValue: setDelegations } = useQueryParams('delegation', [])

  const loading = isLoading || !resources || loadingDelegations

  const countByDelegation = countBy(resources, 'delegation')

  const items = Object.keys(countByDelegation).map(item => {
    const delegation = delegations?.find(del => del.id === item)

    return {
      title: delegation?.provider?.account,
      description: delegation?.name,
      count: countByDelegation[item],
      total: resources?.length,
      id: item
    }
  })

  const handleDelegationsFilter = (item) => {
    setDelegations(filterHelper(item, selectedDelegations))
  }

  return (
    <Section title='Delegations' loading={loading} titleUpperCase>
      {items.sort((a, b) => b.count - a.count).map(delegation =>
        <Delegation
          {...delegation}
          key={delegation.id}
          selected={selectedDelegations}
          onSelect={handleDelegationsFilter}
        />
      )}
    </Section>
  )
}

export default InventoryDelegations
