import React from 'react'
import { useSelector } from 'react-redux'
import { Popconfirm } from 'antd'

import RemoveButton from 'components/buttons/remove'

import { DELETE_SUBSCRIPTION } from 'actions/billing.actions'

const RemoveSubscriptionItemButton = ({ onClick, hide } = {}) => {
  if (hide) return null
  const deletingSubscription = useSelector(state => state.waiting.list[DELETE_SUBSCRIPTION])

  return (
    <Popconfirm
      title='You will not be refunded for unused time'
      onConfirm={onClick}
      okText='I understand'
      cancelText='Cancel'
    >
      <RemoveButton title='Cancel' loading={deletingSubscription} />
    </Popconfirm>
  )
}

export default RemoveSubscriptionItemButton
