import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { alarmsPaths as paths, ALARMS_RULES_LIST, ALARM_ACTIVE } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const updateAlarm = async (account, identity, payload) => {
  const data = await apiFetch(paths.alarm(account, identity), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateAlarmQuery = () => {
  const queryClient = useQueryClient()
  const { alarmId } = useParams()
  const { account } = useOrganization()
  return useMutation(
    payload => updateAlarm(account.id, alarmId, payload),
    {
      onSuccess: updatedAlarm => {
        const previousList = queryClient.getQueryData([...ALARMS_RULES_LIST, account.id])
        const updatedList = previousList?.map(item => item.id === updatedAlarm.id ? updatedAlarm : item)
        queryClient.setQueryData([...ALARMS_RULES_LIST, account.id], updatedList)
        queryClient.setQueryData([...ALARM_ACTIVE, alarmId], updatedAlarm)
      }
    }
  )
}
