import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiFetch } from 'lib/action-helpers'

import { policyPaths as paths, NOTIFICATION_POLICY } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const updateNotificationPolicyCondition = async (account, policy, condition, payload) => {
  const data = await apiFetch(paths.condition(account, policy, condition), { method: 'PUT', body: JSON.stringify(payload) })
  return data?.data
}

export const useUpdateNotificationPolicyConditionQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ policyId, conditionId, payload }) => updateNotificationPolicyCondition(account.id, policyId, conditionId, payload),
    {
      onMutate: ({ policyId, conditionId, payload }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])

        const conditions = previousPolicy.conditions.map(item => {
          if (item.id !== conditionId) return item
          return { ...item, ...payload }
        })

        const newPolicy = { ...previousPolicy, conditions }
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)

        return { previousPolicy }
      },
      onError: (err, newItem, context) => {
        if (err) queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
      }
    }
  )
}
