import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { policyPaths as paths, NOTIFICATION_POLICY_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getPolicies = async (account) => {
  const data = await apiFetch(paths.policies(account))
  return data?.data
}

export const useNotificationPoliciesQuery = () => {
  const { account } = useOrganization()

  return useQuery(
    [...NOTIFICATION_POLICY_LIST, account?.id],
    () => getPolicies(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      retry: false
    }
  )
}
