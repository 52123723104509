import React, { useRef, useEffect } from 'react'
import { Form } from 'antd'
import Input from 'components/antd/input'

const Password = (props) => {
  const pwdRef = useRef()
  const { hidden, focus } = props

  useEffect(() => {
    if (!pwdRef?.current || props?.hidden || !focus) return
    pwdRef.current?.focus()
  }, [props?.hidden])

  return <Form.Item
    hidden={hidden}
    name='password'
    label='Password'
    rules={[{ required: true, message: 'Please input your password' }]}>
    <Input type='password' solid ref={pwdRef} />
  </Form.Item>
}

export default Password
