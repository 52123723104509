import React from 'react'

const LambdaIcon = ({ width, height, x, y }) => {
  return (
    <svg width={width} height={height} x={x} y={y} viewBox="0 0 38.332 40" version="1.1" >
      <g transform="matrix(1.6667 0 0 1.6667 -15.268 -12.667)">
        <defs>
          <linearGradient id="idF7A7LTZJgg766011677" x1="9.3577" x2="32.852" y1="7.4399" y2="7.4399" gradientTransform="scale(.97893 1.0215)" gradientUnits="userSpaceOnUse">
            <stop stopColor="#f78d05" offset="0" />
            <stop stopColor="#d05c17" offset="1" />
          </linearGradient>
        </defs>
        <path d="m13.472 7.6c-0.258 1e-3 -0.467 0.211-0.468 0.469v5.293c1e-3 0.258 0.21 0.468 0.468 0.469h3.536l8.363 17.504c0.079 0.162 0.243 0.265 0.423 0.265h5.898c0.124 0 0.243-0.049 0.331-0.137s0.137-0.208 0.137-0.332v-5.293c0-0.124-0.049-0.244-0.137-0.332s-0.207-0.137-0.331-0.137h-2.093l-8.332-17.5c-0.077-0.164-0.242-0.269-0.423-0.269zm0.469 0.938h6.608l8.332 17.5c0.077 0.166 0.242 0.27 0.422 0.27h1.924v4.354h-5.138l-8.362-17.5c-0.077-0.164-0.242-0.269-0.423-0.27h-3.363zm2.215 8.412c-0.185-4e-3 -0.355 0.102-0.434 0.269l-6.516 13.704c-0.069 0.146-0.059 0.316 0.027 0.453 0.085 0.136 0.235 0.219 0.396 0.22h6.212c0.181-1e-3 0.346-0.105 0.423-0.269l3.444-7.212c0.061-0.127 0.061-0.276 0-0.403l-3.141-6.497c-0.077-0.158-0.235-0.261-0.411-0.265zm-8e-3 1.554 2.615 5.411-3.217 6.743h-5.176z" fill="url(#idF7A7LTZJgg766011677)" />
      </g>
    </svg>

  )
}

export default LambdaIcon
