import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { DASHBOARDS_LIST, dashboardsPaths as paths} from './index'

const getDashboards = async (account) => {
  const data = await apiFetch(paths.dashboards(account))
  return data
}

export const useDashboardsQuery = (query) => {
  const { account } = useOrganization()

  const queryOpts = useQuery(
    [...DASHBOARDS_LIST, account?.id],
    () => getDashboards(account?.id, query),
    {
      staleTime: Infinity,
      enabled: !!account?.id,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false // take away later
    }
  )

  return queryOpts
}
