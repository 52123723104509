import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, USERS_LIST } from '../index'
import { useOrganization } from 'hooks/context/organization-context'
import { useUserPermissions, SCOPES } from 'features/permissions-gate'

const getOrganizationUsers = async (organization) => {
  const data = await apiFetch(paths.users(organization))
  return data
}

export const useOrganizationUsersQuery = () => {
  const { organization } = useOrganization()
  const { hasPermission } = useUserPermissions()

  return useQuery(
    [...USERS_LIST, organization?.id],
    () => getOrganizationUsers(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && hasPermission([SCOPES.canManageUserSettings])
    }
  )
}
