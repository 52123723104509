import React from 'react'
import { get, reduce } from 'lodash'
import { Modal, Tabs } from 'antd'

import TierTitle from './tier-title'
import ProTier from './pro-tier'
import FreeTier from './free-tier'
import EnterpriseTier from './enterprise-tier'

import { TIER_NAMES } from 'lib/billing-constants'

const prepareData = (activeTier, prices) => {
  return reduce(TIER_NAMES, (data, tierTitle, tier) => {
    data[tier] = {
      isActive: tier === activeTier,
      price: get(prices, tier, {}),
      title: tierTitle
    }
    return data
  }, {})
}

const ManageSubscriptionModal = ({ tier, prices, visible, setVisibility, estimatedUsage } = {}) => {
  const data = prepareData(tier, prices)
  return (
    <Modal
      title='Manage subscription'
      open={visible}
      onCancel={() => setVisibility(false)}
      width='60%'
      footer={null}
      destroyOnClose
    >
      <Tabs
        type='card'
        tabBarGutter={16}
        tabBarStyle={{
          display: 'flex',
          justifyContent: 'center'
        }}
        defaultActiveKey={tier}
        items={[
          { key: 'free', label: <TierTitle title={data.free.title} selected={data.free.isActive} />, children: <FreeTier price={data.free.isActive} selected={data.free.isActive} /> },
          { key: 'pro', label: <TierTitle title={data.pro.title} selected={data.pro.isActive} />, children: <ProTier price={data.pro.price} selected={data.pro.isActive} usage={estimatedUsage} closeModal={() => setVisibility(false)} /> },
          { key: 'enterprise', label: <TierTitle title={data.enterprise.title} selected={data.enterprise.isActive} />, children: <EnterpriseTier price={data.enterprise.price} selected={data.enterprise.isActive} /> }
        ]}
      />
    </Modal>
  )
}

export default ManageSubscriptionModal
