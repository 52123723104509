import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { map, groupBy, keyBy, isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLambda as faLambdaBold } from '@fortawesome/pro-solid-svg-icons/faLambda'
import classnames from 'classnames'

import { filterInventoryByNamespace, findInventoryByType } from 'lib/resources/constants'
import IconButton from 'components/buttons/icon'
import { AwsServiceIcon, ResourceGroupIcon, CloseIcon } from 'components/icons'
import DBSelect from 'components/antd/select'
import { Item } from 'components/items'
import { useAllResourcesQuery, useDelegationsQuery, useOrganizationsQuery } from 'hooks/api'

import styles from './styles.module.less'

const { Option, OptGroup } = Select

const TargetTypes = {
  GROUP: 'group',
  LAMBDA: 'lambda',
  RESOURCE: 'resource',
  ALARM: 'alarm'
}

const Tag = ({ value, closable, label, onClose, resources, delegations, solid, prefix }) => {
  const identity = prefix ? value.split(':')[1] : value
  const resource = resources && resources[identity]
  const item = resource || { title: label[2] || '', icon: <ResourceGroupIcon size='xs' /> }
  item.delegationName = delegations?.[item.delegation]?.name

  return (
    <div className={classnames(styles.item, { [styles.solid]: !solid })}>
      {label}
      {closable && <IconButton onClick={() => onClose()} className={styles.delete_btn} icon={<CloseIcon />} />}
    </div>
  )
}

const Targets = React.forwardRef(({
  value,
  resourceGroups,
  lambdas,
  resources,
  alarms,
  allowAll,
  onChange,
  onSelect,
  onDeselect,
  solid = true,
  allowClear = true,
  prefixValues,
  ...props
}, ref) => {
  const { data: resourcesAll } = useAllResourcesQuery()
  const { data: organizations } = useOrganizationsQuery()
  const [selected, setSelected] = useState([])
  const [delegationsByIds, setDelegationsByIds] = useState({})
  const [accountsByIds, setAccountsByIds] = useState({})
  const { data: delegations } = useDelegationsQuery()

  useEffect(() => {
    if (!value) return
    // removes items which id-s are not found in resources/projects  etc
    const getId = item => prefixValues ? item.split(':')[1] : item
    setSelected(value?.map(target => mapIdsToTargets(target.id || getId(target)))
      .filter(item => item).map(item => prefixValues ? `${item.type}:${item.id}` : item.id))
  }, [value, resources, alarms, resourceGroups])

  useEffect(() => {
    if (!delegations) return
    setDelegationsByIds(keyBy(delegations, 'id'))
  }, [delegations])

  useEffect(() => {
    if (!organizations) return
    const accounts = organizations.flatMap(org => org.accounts)
    setAccountsByIds(keyBy(accounts, 'id'))
  }, [organizations])

  const renderProjectGroup = () => {
    return (
      <OptGroup key='projects' label='Resource groups'>
        {resourceGroups?.map(project => {
          return (
            <Option key={project.id} value={prefixValues ? `group:${project.id}` : project.id} title={project.title}>
              <Item.Filter
                title={accountsByIds[project.account.id]?.name}
                description={project.title}
                size='sm'
                gap={false}
                hoverable={false}
                icon={<ResourceGroupIcon size='xs' />}
                className={styles.list_item}
              />
            </Option>
          )
        })}
      </OptGroup>
    )
  }

  const renderResourceGroup = () => {
    return (
      <OptGroup key='resources' label='Resources'>
        {resources?.map(resource => {
          const delegation = delegationsByIds[resource?.delegation]?.name
          const title = delegation ? `${delegation} | ${resource.region}` : resource.region

          return <Option
            key={resource.id}
            value={prefixValues ? `resource:${resource.id}` : resource.id} title={resource.title}
            className={styles.option}>
            <Item.Filter
              title={!resource.region ? 'GROUP' : title}
              description={resource.title}
              size='sm'
              gap={false}
              hoverable={false}
              icon={<AwsServiceIcon service={findInventoryByType(resource?.type)?.service} size='sm' />}
              className={styles.list_item}
            />
          </Option>
        })}
      </OptGroup>
    )
  }

  const renderAlarmsGroup = () => {
    return (
      <OptGroup key='alarms' label='Alarms'>
        {alarms?.map(alarm => (
          <Option key={alarm.id} value={alarm.id} title={alarm.name}>
            <AwsServiceIcon service={filterInventoryByNamespace(alarm.namespace)?.service} /> {alarm.name}
          </Option>
        ))}
      </OptGroup>
    )
  }

  const renderLambdaGroup = () => {
    const { OptGroup } = Select
    const groupedLambdas = groupBy(lambdas, 'name')

    const lambdaOption = (group) => map(group, lambda => {
      const delegation = delegationsByIds[lambda.delegation]?.name
      const title = delegation ? `${delegation} | ${lambda.region}` : lambda.region

      return <Option key={lambda.id} title={lambda?.title} value={prefixValues ? `resource:${lambda.id}` : lambda.id}>
        <Item.Filter
          title={title}
          description={lambda.title}
          size='sm'
          gap={false}
          icon={<AwsServiceIcon service={'lambda'} size='sm' />}
          className={styles.list_item}
          hoverable={false}
        />
      </Option>
    })

    return (
      <OptGroup key='lambdas' label='Lambdas'>
        {map(groupedLambdas, group => lambdaOption(group))}
      </OptGroup>
    )
  }

  const renderAllGroup = () => {
    return (
      <Option
        key='all'
        title='All'
        value='*'
      >
        <FontAwesomeIcon icon={faLambdaBold} /> ALL
      </Option>
    )
  }

  const mapIdsToTargets = (id) => {
    if (id === '*') return { id: '*', type: TargetTypes.LAMBDA }

    if (resourceGroups?.find(item => item.id === id)) {
      return {
        id,
        type: TargetTypes.GROUP
      }
    } else if (lambdas?.find(item => item.id === id)) {
      return {
        id,
        type: TargetTypes.RESOURCE
      }
    } else if (resources?.find(item => item.id === id)) {
      return {
        id,
        type: TargetTypes.RESOURCE
      }
    } else if (alarms?.find(item => item.id === id)) {
      return {
        id,
        type: TargetTypes.ALARM
      }
    }
  }

  const handleChange = (changed) => {
    setSelected(changed)

    if (onChange) {
      onChange(changed)
    }
  }

  const handleSelect = (value) => {
    if (!onSelect) return
    onSelect(value)
  }

  const handleDeselect = (value) => {
    if (!onDeselect) return
    onDeselect(value)
  }

  return (
    <DBSelect
      solid={solid}
      className={styles.select}
      optionFilterProp='title'
      mode='multiple'
      allowClear={allowClear}
      getPopupContainer={triggerNode => triggerNode?.parentNode}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onChange={handleChange}
      value={selected}
      defaultValue={selected}
      tagRender={(tagProps) => (
        <Tag {...tagProps}
          delegations={delegationsByIds}
          resources={resourcesAll}
          solid={solid}
          prefix={prefixValues}
        />)}
      ref={ref}
      {...props}
    >
      {allowAll && renderAllGroup()}
      {resourceGroups && renderProjectGroup()}
      {!isEmpty(lambdas) && renderLambdaGroup()}
      {!isEmpty(resources) && renderResourceGroup()}
      {alarms && renderAlarmsGroup()}
    </DBSelect>
  )
})

export default Targets
