import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, WIDGETS_LIST } from './index'

const deleteWidget = async (account, dashboardId, widgetId) => {
  const data = await apiFetch(paths.widget(account, dashboardId, widgetId), { method: 'DELETE' })
  return data
}

export const useDeleteWidgetQuery = () => {
  const { dashboardId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    widgetId => deleteWidget(account.id, dashboardId, widgetId),
    {
      onMutate: (widgetId) => {
        const previousList = queryClient.getQueryData([...WIDGETS_LIST, account.id, dashboardId])
        const updatedList = previousList?.filter(item => item.id !== widgetId)
        queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], updatedList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], context.previousList)
        }
      },
    }
  )
}
