import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { isEmpty } from 'lodash'

import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationInvitationsQuery, useOrganizationUsersQuery, useOrganizationQuery, useCreateInvitationQuery } from 'hooks/api'
import Content from 'components/layout/content'
import UsersTable from './users-table'
import AddUserModal from './add-user-modal'

import styles from './styles.module.less'

const UserPermissions = () => {
  const { organization: currentOrganization } = useOrganization()
  const { user } = useUser()
  const { data: invitations, isLoading: invitationsLoading } = useOrganizationInvitationsQuery()
  const { data: users, isLoading } = useOrganizationUsersQuery()
  const { mutateAsync: createInvitation } = useCreateInvitationQuery()
  const { data: org } = useOrganizationQuery()

  const [userModalVisible, setUserModalVisible] = useState(false)
  const [mergedUsers, setMergedUsers] = useState([])

  const sendInvite = async (invitation) => {
    try {
      await createInvitation({ payload: invitation })
      ReactGA.event({ category: 'Invitation', action: 'Invitation successful', label: user?.attributes?.email.replace('@', '.at.') })
    } catch (error) { }
  }

  const mergeUsersInvites = () => {
    if (!users) return
    setMergedUsers(users?.concat(invitations))
  }

  useEffect(mergeUsersInvites, [invitations, users])

  const Title = () => {
    return <h1><span className={styles.highlighted}>{currentOrganization.name}</span> users</h1>
  }

  return (
    <Content item={{}} title={<Title />} breadcrumbs={['Settings', 'Users']} loading={isEmpty(org)}>
      <p className={styles.subtitle}>Create new users, customize user permissions, and remove users from your account.</p>

      <UsersTable users={mergedUsers} org={org} setModalVisible={setUserModalVisible} loading={isLoading || invitationsLoading} />
      <AddUserModal visible={userModalVisible} setVisible={setUserModalVisible} sendInvite={sendInvite} />
    </Content>
  )
}

export default UserPermissions
