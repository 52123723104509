import React from 'react'
import classnames from 'classnames'
import { Layout } from 'antd'
import { BurgerMenuIcon, DashbirdIcon } from 'components/icons'
import { IconLinkButton } from 'components/buttons'
import IconButton from 'components/buttons/icon'

import { useOrganization } from 'hooks/context/organization-context'
import { useScrollDirection } from 'hooks/use-scroll-direction'

import styles from './styles.module.less'

const { Header } = Layout

const MobileHeader = ({ collapsed, setCollapsed }) => {
  const { organization } = useOrganization()
  const scrollDirection = useScrollDirection()

  const onBoardingCompleted = organization?.onboarded

  return (
    <Header className={classnames(styles.mobile_header, { [styles.hide]: scrollDirection === 'down' })}>
      <IconLinkButton icon={<DashbirdIcon />} to={!onBoardingCompleted ? 'onboarding' : ''} />
      <IconButton icon={<BurgerMenuIcon className='trigger' open={!collapsed} />} onClick={() => setCollapsed(!collapsed)} />
    </Header>

  )
}

export default MobileHeader
