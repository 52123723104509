import React from 'react'

import IconButton from 'components/buttons/icon'
import { AwsIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

const URI = 'https://console.aws.amazon.com/cloudwatch/home'

const CloudWatchLink = ({ loading, resource, invocation }) => {
  if (loading || !invocation || !resource) return null

  const uri = `${URI}?region=${resource.region}#logEventViewer:group=${invocation.logGroupName};stream=${invocation.logStreamName};filter="${invocation.requestId}"`
  return (
    <a href={uri} target='_blank' rel='noopener noreferrer'>
      <IconButton
        className={styles.icon}
        icon={<AwsIcon />}
        title='View logs in CloudWatch'
        titlePlacement='topRight'
      />
    </a>
  )
}

export default CloudWatchLink
