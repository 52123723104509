import React, { useState, useMemo } from 'react'
import { differenceInMilliseconds, sub } from 'date-fns'
import { isEmpty } from 'lodash'

import { Item } from 'components/items'
import { Time } from 'components/time'

import Section from 'components/layout/content/section'
import ViolationChart from './chart'
import RangePicker from 'components/date-picker/chart'
import { findInventoryByType } from 'lib/resources/constants'
import { useAccountRoutes } from 'containers/routes'

import { getLocalPeriod, getCalculatedRange, getMetricResourceDimensions } from 'lib/metric-helpers'

import { useChartMetricsQuery } from 'hooks/api'

import styles from './styles.module.less'
import { useHistory } from 'react-router-dom'

const ViolationItem = ({ resources, resourcesLoading, violation, onClick, selected, selectedIds }) => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const NO_METRICS = isEmpty(violation.metrics)

  const [range, setRange] = useState(getCalculatedRange(violation).range)
  const [start, setStart] = useState(getCalculatedRange(violation).start)
  const [end, setEnd] = useState(getCalculatedRange(violation).end)

  const payloads = useMemo(() => violation?.resource?.id && selected
    ? violation?.metrics.map(metric => ({
      payload: {
        dimensions: { resources: getMetricResourceDimensions(violation?.resource, resources, metric.metric) },
        namespace: metric?.namespace,
        metric: metric.metric,
        stats: [metric.stat],
        start: new Date(start),
        end: new Date(end),
        period: getLocalPeriod(start, end)
      }
    }))
    : [], [violation?.resource?.id, selected, start, end])

  const data = useChartMetricsQuery(payloads)

  const onChange = (start, end) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMilliseconds(end, start))
  }

  return (
    <>
      <Item.Resource
        {...(violation?.resource?.id ? violation?.resource : {})}
        service={findInventoryByType(violation?.resource?.type)?.service}
        delegation={violation?.resource?.delegationName}
        id={violation?.id}
        loading={resourcesLoading}
        time={violation.openedAt && <Time value={[violation?.openedAt]} type='short' className={styles.time} />}
        onSelect={!NO_METRICS ? () => onClick(violation?.id) : () => { }}
        onDescriptionClick={(e) => {
          e.stopPropagation()
          history.push(routes.inventory.resource.url({ resourceId: violation?.resource?.id }))
        }}
        selected={selectedIds}
        content={
          !NO_METRICS
            ? (
              <Section solid disableUserSelection cancelMargin>
                <ViolationChart data={data} range={range} violation={violation} />
                <RangePicker
                  onChange={onChange}
                  min={sub(new Date(), { months: 1 })}
                  max={Date.now()}
                  start={start}
                  end={end}
                />
              </Section>)
            : null
        }
      />
    </>
  )
}

export default ViolationItem
