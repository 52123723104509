import React from 'react'
import classnames from 'classnames'
import { Tooltip } from 'antd'

import styles from './styles.module.less'

const InventoryTag = ({ name, value, onClick, className, disabled }) => {
  return (
    <Tooltip mouseEnterDelay={1} title={`${name}: ${value}`}>
      <div className={classnames(styles.container, className, { [styles.disabled]: disabled, [styles.clickable]: !!onClick })} onClick={onClick}>
        <div className={styles.name}>{name}: </div>
        <div className={styles.value}>{value}</div>
      </div>
    </Tooltip>
  )
}

export default InventoryTag
