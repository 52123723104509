import React from 'react'
import classnames from 'classnames'
import { Dropdown } from 'antd'
import IconButton from 'components/buttons/icon'
import { SortIcon } from 'components/icons/font-awesome'
import { sortByLastOccurrence, sortByName, sortByOccurrenceCount, sortByOccurrenceCountLastDay, sortBySeverity, sortByStatus } from './sorters'

import styles from '../styles.module.less'

export const DEFAULT_SORT = {
  key: 'default',
  name: 'Default',
  apply: [item => item]
}

export const OccurrenceCountLastDay = {
  key: 'occurrence_count',
  name: 'Occurrence count',
  sort: (items) => sortByOccurrenceCountLastDay(items),
  apply: [item => -1 * item.countLastDay]
}

export const OccurrenceCount = {
  key: 'occurrence_count',
  name: 'Occurrence count',
  sort: (items) => sortByOccurrenceCount(items),
  apply: [item => -1 * item.countLastDay]
}

export const OccurrenceLast = {
  key: 'occurrence_last',
  name: 'Last occurrence',
  sort: (items, key = 'lastOccurredAt') => sortByLastOccurrence(items, key),
  apply: [item => -1 * (item.lastOccurredAt || item.openedAt || item.updatedAt)]
}

export const NameAlphabetical = {
  key: 'name_alphabetical',
  name: 'Name alphabetical',
  sort: items => sortByName(items),
  apply: [item => item?.title || item?.name]
}

export const Muted = {
  key: 'muted',
  name: 'Muted',
  apply: [item => item?.mutedAt]
}

export const ServiceType = {
  key: 'service_type',
  name: 'Service type',
  apply: [item => item?.type || item?.service]
}

export const Namespace = {
  key: 'namespace',
  name: 'Service type',
  apply: [item => item?.namespace]
}

export const Status = {
  key: 'status',
  name: 'Status',
  apply: [item => item?.disabled]
}

export const SeverityCount = {
  key: 'severity_count',
  name: 'Item severity and count',
  sort: items => sortByStatus(items)
}

export const Severity = {
  key: 'severity',
  name: 'Rule severity',
  sort: items => sortBySeverity(items)
}

export const Pillar = {
  key: 'pillar',
  name: 'WAL Pillars',
  apply: [item => item.pillar]
}

export const Violations = {
  key: 'violation_count',
  name: 'Violation count',
  order: 'desc',
  apply: [item => item.count]
}

export const Delegation = {
  key: 'delegation',
  name: 'Delegation',
  apply: [item => item.delegation]
}

export const EventStatus = {
  key: 'status',
  name: 'Rule active/inactive',
  apply: [item => item.status]
}

const ListSorters = ({ sorters, onSorterChange, selected, extraPadding }) => {
  const handleSelectSort = (e) => {
    onSorterChange(e.key)
  }

  return (
    <Dropdown
      menu={{
        items: sorters.map(sorter => ({ key: sorter.key, label: sorter.name })),
        onClick: handleSelectSort,
        selectedKeys: [selected],
        selectable: true
      }}
      trigger='click'
      getPopupContainer={triggerNode => triggerNode?.parentNode}
      overlayClassName={styles.sort_menu}
    >
      <IconButton title="Sort" icon={<SortIcon />} className={classnames(styles.sort_btn, { [styles.extra]: !!extraPadding })} />
    </Dropdown>
  )
}

export default ListSorters
