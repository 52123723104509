import React from 'react'
import { Col, List } from 'antd'

import styles from './styles.module.less'

const AddressColumn = ({ data = [] }) => {
  return (
    <Col span={12}>
      <List
        split={false}
        dataSource={data}
        renderItem={({ key, value }) => (
          <List.Item className={styles.listItem}>
            { key } <b>{ value }</b>
          </List.Item>
        )}
      />
    </Col>
  )
}

export default AddressColumn
