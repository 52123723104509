import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import queryString from 'query-string'

import { LOG_SOURCE, logSourcesPaths as paths, getLogGroupName } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getLogSourceSubscription = async (account, delegation, region, logGroupName) => {
  const query = queryString.stringify({ region, logGroupName })

  return apiFetch(paths.filter(paths.logSource(account, delegation), query), { method: 'GET' })
}
export const useLogSourceQuery = ({ delegation, region, name }) => {
  const { account } = useOrganization()

  const logGroupName = getLogGroupName(name)

  return useQuery(
    [LOG_SOURCE, delegation?.id, region, logGroupName],
    () => getLogSourceSubscription(account?.id, delegation?.id, region, logGroupName),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!account?.id && !!delegation?.id,
      refetchOnWindowFocus: false
    }
  )
}
