import { flattenDeep, groupBy } from 'lodash'
import moment from 'moment'

const getInvocationsByDays = (data, selectedChart) => {
  return Object.values(data).map(value => value.data)
    .flat()
    .map(item => ({
      key: item.key_as_string,
      ...formatStacksByRegion(item, selectedChart)
    }))
}

const sumInvocationsByDays = (invocationsByDays) => {
  return invocationsByDays.reduce((acc, day) => {
    return acc + day.regions.reduce(
      (regionAcc, region) => regionAcc + region.invocations, 0
    )
  }, 0)
}

export const calculateAvgUsageSince = (data, selectedChart, since) => {
  const invocationsByDays = getInvocationsByDays(data, selectedChart)
  const total = sumInvocationsByDays(invocationsByDays)
  const daysFromStart = moment().diff(since, 'days')
  if (daysFromStart === 0) return total
  if (total === 0) return 0
  const averagePerDay = total / invocationsByDays.length
  return averagePerDay * daysFromStart
}

export const calculateTotalUsageSince = (data, selectedChart, since) => {
  const invocationsByDays = getInvocationsByDays(data, selectedChart)
  const total = sumInvocationsByDays(invocationsByDays)
  return total
}

export const calculateInvocationSum = (data, selectedChart) => {
  const invocationsByDays = getInvocationsByDays(data, selectedChart)
  return sumInvocationsByDays(invocationsByDays)
}

export const formatStacksByRegion = (item, tab) => {
  const regionLambdas = []
  const regions = item.split_by_region.buckets.map(itemByRegion => {
    regionLambdas.push(itemByRegion.split_by_lambda.buckets.map(itemByLambda => {
      return {
        lambda: `${itemByRegion.key}/${itemByLambda.key}`,
        bytes: itemByLambda.sum_bytes.value,
        invocations: itemByLambda.sum_invocations.value
      }
    }).filter(item => item.invocations > 0))

    return {
      region: itemByRegion.key,
      bytes: itemByRegion.sum_bytes.value,
      invocations: itemByRegion.sum_invocations.value
    }
  }).filter(item => item.bytes > 0)

  const lambdas = regionLambdas.flat().sort((a, b) => b.invocations - a.invocations)

  return {
    regions,
    topLambdas: lambdas.slice(0, 5)
  }
}

export const formatStacks = (item, tab) => {
  const stacks = item.split_by_region.buckets.map((itemByRegion) => {
    return itemByRegion.split_by_lambda.buckets.map((itemByLambda) => {
      return {
        region: itemByRegion.key,
        lambda: `${itemByRegion.key}/${itemByLambda.key}`,
        bytes: itemByLambda.sum_bytes.value,
        invocations: itemByLambda.sum_invocations.value
      }
    })
  })

  const filteredStacks = flattenDeep(stacks).filter(stack => stack.bytes > 0)
  return groupBy(filteredStacks, tab)
}
