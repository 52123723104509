import { useQueries } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'
import { metricsPaths as paths, METRICS_CHART } from './index'
import RegionCosts from 'lib/lambda/prices'
import { sub } from 'date-fns'

const isCostMetric = metric => metric === 'gb_second'

const formatCostResponse = (response, payload, metricId) => {
  const region = payload?.dimensions?.regions?.[0]

  if (!region) return response

  return {
    ...response,
    ...(metricId ? { metricId } : {}),
    datapoints: {
      default: response?.datapoints?.default?.map(item => {
        return {
          ...item,
          sum: isCostMetric ? item.sum * RegionCosts[region].duration : item.sum
        }
      })
    }

  }
}

const formatRelativePayload = (payload, relativeSpan) => ({
  ...payload,
  start: sub(new Date(), { minutes: relativeSpan }),
  end: new Date()
})

const getMetrics = async (account, fixedPayload, metricId, relativeSpan) => {
  const payload = relativeSpan
    ? formatRelativePayload(fixedPayload, relativeSpan)
    : fixedPayload

  const data = await apiFetch(paths.metrics(account), { method: 'POST', body: JSON.stringify(payload) })
  if (isCostMetric(data.metric)) {
    return { ...formatCostResponse(data, payload, metricId), ...(metricId ? { metricId } : {}) }
  }
  return { ...data, ...(metricId ? { metricId } : {}) }
}

// metrics = [{id, payload, refreshInterval}]
export const useChartMetricsQuery = (metrics = [], relativeSpan, refreshInterval) => {
  const { account } = useOrganization()
  return useQueries(
    metrics.map(metric => {
      const { start, end, dimensions, metric: metricId } = metric.payload || {}
      const resourceId = dimensions?.resources?.[0]?.id
      return {
        queryKey: [...METRICS_CHART, metric?.id || metricId, resourceId, relativeSpan || `${start}-${end}`],
        queryFn: () => getMetrics(account.id, metric.payload, metric?.id || metricId, !metric.overrideRelative && relativeSpan),
        staleTime: refreshInterval ? 5 * 60 * 1000 : Infinity,
        enabled: !!account?.id && !!metrics,
        refetchInterval: refreshInterval,
        refetchOnWindowFocus: false
      }
    })
  )
}
