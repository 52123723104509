import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Table, Select } from 'antd'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import styles from './styles.module.less'

const ADMIN = { label: 'Admin', value: 'admin' }
const USER = { label: 'User', value: 'user' }
const VIEWER = { label: 'View only', value: 'viewer' }
const NO_ACCESS = { label: 'No access', value: 'no-access' }

const SelectRole = ({ onChange, defaultVal, permission }) => {
  const additionalProps = permission ? { value: permission.role } : {}
  return (
    <PermissionsGate scopes={[SCOPES.canManageAllUsers]} errorProps={{ options: [NO_ACCESS, VIEWER, USER] }}>
      <Select {...additionalProps}
        defaultValue={defaultVal}
        onSelect={permission ? (value) => onChange(value, permission) : onChange}
        className={styles.select_all}
        popupClassName={styles.dropdown}
        dropdownMatchSelectWidth={false}
        disabled={permission?.role === 'owner'}
        options={[NO_ACCESS, VIEWER, USER, ADMIN]}
      />
    </PermissionsGate>
  )
}

const UsersPermissionsTable = ({ permissions, setPermissions }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const rowSelection = {
    selectedRowKeys,
    onChange: changedKeys => setSelectedRowKeys(changedKeys)
  }

  const changePermission = (role, currentPermission) => {
    setPermissions(permissions.map(permission => {
      return permission.account.id === currentPermission.account.id
        ? { account: currentPermission.account, role }
        : permission
    }))
  }

  const changeSelectedPermissions = (role) => {
    setPermissions(permissions.map(permission => {
      return selectedRowKeys.find(accountId => accountId === permission.account.id)
        ? { account: permission.account, role }
        : permission
    }))
  }

  const columns = [
    {
      title: 'Account',
      dataIndex: 'account.name',
      key: 'account.id',
      sorter: (a, b) => a.account.name.localeCompare(b.account.name),
      sortDirections: ['descend', 'ascend'],
      width: '50%',
      render: (record, item) => item.account.name
    },
    {
      title: <>Role{!isEmpty(selectedRowKeys) && <SelectRole defaultVal='no-access' onChange={changeSelectedPermissions} />}</>,
      dataIndex: 'role',
      key: 'role',
      width: '50%',
      render: (record, item) => {
        // const defaultVal = isEmpty(item.role) ? 'no-access' : item.role
        return (<SelectRole defaultVal={item.role} permission={item} onChange={changePermission} />)
      }
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={permissions}
      pagination={false}
      rowSelection={rowSelection}
      rowKey={record => record.account.id}
      size='small'
      bordered
      className={styles.container}
    />
  )
}

export default UsersPermissionsTable
