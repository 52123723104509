import numeral from 'numeral'
import { faMicrochip, faBug, faSlidersH, faFolders, faHdd, faClock, faHeartRate, faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'

import { getValues, getSparklineByName, getLast, getSum, average, getSecondToLast } from '../helpers'
import { objectify } from 'lib/utils'
import { formatDuration } from 'components/charts/utils'

export const OPENSEARCH_DATANODES = 'opensearch-datanodes'
export const OPENSEARCH_MASTER = 'opensearch-master'
export const OPENSEARCH_ULTRAWARM = 'opensearch-ultrawarm'
export const OPENSEARCH_REQUESTS = 'opensearch-requests'
export const OPENSEARCH_SHARDS = 'opensearch-shards'
export const OPENSEARCH_DOCUMENTS = 'opensearch-documents'
export const OPENSEARCH_NODES = 'opensearch-nodes'
export const OPENSEARCH_STORAGE = 'opensearch-storage'
export const OPENSEARCH_LATENCY = 'opensearch-latency'
export const OPENSEARCH_RATE = 'opensearch-rate'
export const OPENSEARCH_SEARCH = 'opensearch-search'
export const OPENSEARCH_INDEXING = 'opensearch-indexing'

const metrics = [
  {
    key: OPENSEARCH_DATANODES,
    icon: faMicrochip,
    title: 'Datanodes',
    filter: (collection) => `${numeral(getLast(collection, 'OPENSEARCH_DATANODES_CPU_UTILIZATION')).format('0.[00]')}%`,
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_DATANODES_CPU_UTILIZATION'),
    selectable: true
  },
  {
    key: OPENSEARCH_MASTER,
    icon: faMicrochip,
    title: 'Master',
    filter: (collection) => `${numeral(getLast(collection, 'OPENSEARCH_MASTER_CPU_UTILIZATION')).format('0.[00]')}%`,
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_MASTER_CPU_UTILIZATION'),
    selectable: true
  },
  {
    key: OPENSEARCH_ULTRAWARM,
    icon: faMicrochip,
    title: 'Ultrawarm',
    filter: (collection) => `${numeral(getLast(collection, 'OPENSEARCH_ULTRAWARM_CPU_UTILIZATION')).format('0.[00]')}%`,
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_ULTRAWARM_CPU_UTILIZATION'),
    selectable: true
  },
  {
    key: OPENSEARCH_REQUESTS,
    icon: faBug,
    title: 'Requests',
    filter: (collection, item) => {
      const engine = objectify(item?.attributes)?.engineVersion.toLowerCase()
      const metricKey = engine.includes('elasticsearch') ? 'OPENSEARCH_ELASTIC_REQUESTS' : 'OPENSEARCH_REQUESTS'
      return numeral(getSum(collection, metricKey)).format('0.[00]a')
    },
    subvalue: (collection, item) => {
      const engine = objectify(item?.attributes)?.engineVersion.toLowerCase()
      const metricKey = engine.includes('elasticsearch') ? 'OPENSEARCH_ELASTIC_REQUESTS' : 'OPENSEARCH_REQUESTS'
      return getSparklineByName(collection, metricKey)
    },
    selectable: true
  },
  {
    key: OPENSEARCH_STORAGE,
    icon: faHdd,
    title: 'Free storage',
    filter: (collection) => {
      if (!collection || !collection.OPENSEARCH_DATANODES_FREE_STORAGE) return

      const freeStorageLast = getSecondToLast(collection, 'OPENSEARCH_DATANODES_FREE_STORAGE')
      const period = collection.OPENSEARCH_DATANODES_FREE_STORAGE?.period

      const used = freeStorageLast / (period / 60) * 1000000

      return numeral(used).format('0.00b')
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_DATANODES_FREE_STORAGE'),
    selectable: true
  },
  {
    key: OPENSEARCH_SHARDS,
    icon: faSlidersH,
    title: 'Shards',
    filter: (collection) => {
      const active = getLast(collection, 'OPENSEARCH_CLUSTER_SHARDS_ACTIVE')
      const unassigned = getLast(collection, 'OPENSEARCH_CLUSTER_SHARDS_UNASSIGNED')
      const delayedUnassigned = getLast(collection, 'OPENSEARCH_CLUSTER_SHARDS_DELAYED_UNASSIGNED')
      const primaryActive = getLast(collection, 'OPENSEARCH_CLUSTER_PRIMARY_SHARDS_ACTIVE')
      const initializing = getLast(collection, 'OPENSEARCH_CLUSTER_SHARDS_INITIALIZING')
      const relocation = getLast(collection, 'OPENSEARCH_CLUSTER_SHARDS_RELOCATING')
      return numeral(active + unassigned + delayedUnassigned + primaryActive + initializing + relocation).format('0.[00] a')
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_CLUSTER_SHARDS_ACTIVE'),
    selectable: true
  },
  {
    key: OPENSEARCH_DOCUMENTS,
    icon: faFolders,
    title: 'Documents',
    filter: (collection) => {
      const search = getLast(collection, 'OPENSEARCH_DOCUMENTS_SEARCH')
      const deleted = getLast(collection, 'OPENSEARCH_DOCUMENTS_DELETED')
      return numeral(deleted + search).format('0.[00] a')
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_DOCUMENTS_SEARCH'),
    selectable: true
  },
  {
    key: OPENSEARCH_NODES,
    icon: faMicrochip,
    title: 'Nodes',
    filter: (collection) => {
      const nodes = getLast(collection, 'OPENSEARCH_CLUSTER_NODES')
      return numeral(nodes).format('0.[00] a')
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_CLUSTER_NODES'),
    selectable: true
  },
  {
    key: OPENSEARCH_SEARCH,
    icon: faMagnifyingGlass,
    title: 'Search',
    filter: (collection) => {
      const values = getValues(collection, 'OPENSEARCH_INSTANCE_LATENCY_SEARCH')?.map(item => item.value)
      return formatDuration(average(values))
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_INSTANCE_LATENCY_SEARCH'),
    selectable: true
  },
  {
    key: OPENSEARCH_INDEXING,
    icon: faHeartRate,
    title: 'Indexing',
    filter: (collection) => {
      const values = getValues(collection, 'OPENSEARCH_INSTANCE_LATENCY_INDEXING')?.map(item => item.value)
      return formatDuration(average(values))
    },
    subvalue: (collection) => getSparklineByName(collection, 'OPENSEARCH_INSTANCE_LATENCY_INDEXING'),
    selectable: true
  }
]

export default metrics
