import numeral from 'numeral'
import { sum } from 'lodash'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faCloud } from '@fortawesome/pro-light-svg-icons/faCloud'

import { formatDuration } from 'components/charts/utils'
import { getSparklineByName, aggregateSparkline, getSum, getLast } from '../helpers'

export const APIGW_REQUESTS = 'requests'
export const APIGW_ERRORS = 'apigw-errors'
export const APIGW_LATENCY = 'apigw-latency'

const metrics = [{
  key: APIGW_REQUESTS,
  icon: faSigma,
  title: 'Requests',
  filter: (collection) => {
    const total = getSum(collection, 'APIGATEWAY_REQUESTS')
    return numeral(total).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'APIGATEWAY_REQUESTS'),
  selectable: true
}, {
  key: APIGW_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const system = getSum(collection, 'APIGATEWAY_ERRORS_5XX')
    return numeral(system).format('0.[00]a')
  },
  subvalue: (collection) => {
    return aggregateSparkline(collection, sum, 'APIGATEWAY_ERRORS_5XX', 'APIGATEWAY_ERRORS_4XX')
  },
  selectable: true
}, {
  key: APIGW_LATENCY,
  icon: faClock,
  title: 'Avg latency',
  filter: (collection) => formatDuration(getLast(collection, 'APIGATEWAY_LATENCY')),
  subvalue: (collection) => getSparklineByName(collection, 'APIGATEWAY_LATENCY'),
  selectable: true
}, {
  icon: faCloud,
  title: 'Stage',
  filter: (_, item) => item.name
}]

export default metrics
