import React, { useContext } from 'react'
import { orderBy, get, sum, isEmpty } from 'lodash'
import { Row, Col } from 'antd'
import { getTime } from 'date-fns'

import Items from '../items'
import { filterInventoryByNamespace } from 'lib/resources/constants'
import { AggregationTypes, AlarmTermDurations } from 'lib/alerting-constants'
import { formatDuration } from 'components/charts/utils'

import { SEVERITY_TYPES } from 'lib/event-constants'
import { getEventUrl } from 'lib/event-helpers'

import { useAlarmsQuery, useResourceErrorsQuery, useInsightsQuery, useErrorMetricsQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'
import { ServiceContext } from 'containers/inventory/details/resource'

const getDescription = (event) => {
  if (event.type !== 'alarm') return null

  const services = filterInventoryByNamespace(event.namespace)
  const service = services ? services[0] : undefined
  if (!service) {
    return null
  }

  const duration = AlarmTermDurations.find(duration => duration.value === event.duration)
  const stat = AggregationTypes.find(agg => agg.value === event.stat)
  const metric = service.metrics.find(metric => metric.value === event.metric) || null
  // Page would crash if metrics are not defined in constants.js but alarm exists
  if (!metric) return ''

  return `${metric?.title} was ${event.operator} ${event.threshold} on ${stat?.title} for ${duration?.title || formatDuration(event?.duration * 1000)}`
}

const getItem = (event, routes) => {
  const { id, name, type, openedAt, countWithinTime, errorType, lastOccurredAt, severity } = event
  return {
    id,
    title: name,
    type,
    url: getEventUrl(event, routes),
    description: getDescription(event),
    time: openedAt,
    count: countWithinTime,
    errorType,
    severity,
    lastOccurred: lastOccurredAt
  }
}

const sortEvents = events => {
  const byTimestamp = event => event.lastOccurred || event.time
  const bySeverity = event => {
    const severity = get(event, 'severity', '').toLowerCase()
    switch (severity) {
      case SEVERITY_TYPES.CRITICAL:
        return 0
      case SEVERITY_TYPES.WARNING:
        return 10
      case SEVERITY_TYPES.INFO:
        return 20
      default:
        return 99
    }
  }
  return orderBy(events, [bySeverity, byTimestamp], ['asc', 'desc'])
}

export const EventContainer = ({ events, type, title, show, routes, loading, loadingCount }) => {
  if (!show) return null

  const items = events?.map(event => getItem(event, routes))
  const sorted = sortEvents(items)
  return (
    <Items title={title} items={sorted} type={type} loading={loading} loadingCount={loadingCount} />
  )
}

const Events = ({ resourceId, errors, alarms = true, insights = true }) => {
  const { start, end } = useContext(ServiceContext)
  const routes = useAccountRoutes()

  const { data: alarmsData, isLoading: alarmsLoading } = useAlarmsQuery({ resource: resourceId })
  const { data: insightsData, isLoading: insightsLoading } = useInsightsQuery({ resource: resourceId })
  const { data: errorsData, isLoading: errorsLoading } = useResourceErrorsQuery({ resource: resourceId, start: getTime(start), end: getTime(end) })

  const errorsWithinTime = errorsData?.filter(error => error.lastOccurredAt > getTime(start))

  const { data: metrics, isLoading } = useErrorMetricsQuery({
    key: 'errors',
    options: {
      dimensions: { resources: errorsWithinTime?.map(error => ({ id: error.errorHash })) }
    }
  }, !isEmpty(errorsWithinTime) && start, end)

  const mappedErrorsData = errorsWithinTime?.map(error => {
    if (isEmpty(metrics)) return error
    const count = sum(metrics?.datapoints[error.errorHash]?.map(item => item.sum)) || null

    return {
      ...error,
      countWithinTime: count
    }
  })

  if (!alarms && !insights && !errors) return null

  const errorItemsContainer = <EventContainer events={mappedErrorsData} type='error' title='Errors' show={errors} routes={routes} loading={errorsLoading} loadingCount={isLoading} />
  return (
    <Row>
      <Col span={24}>
        <EventContainer events={alarmsData} type='alarm' title='Alarms' show={alarms} routes={routes} loading={alarmsLoading} />
      </Col>
      <Col span={24}>
        <EventContainer events={insightsData} type='insight' title='Insights' show={insights} routes={routes} loading={insightsLoading} />
      </Col>
      {errors ? <Col span={24}>{errorItemsContainer}</Col> : null}
    </Row>
  )
}

export default Events
