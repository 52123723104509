import React from 'react'
import Numeral from 'numeral'

import { Bar, Line, YAxis } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import { yAxisProps } from 'components/charts/utils'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KINESIS_FIREHOSE_OUTGOING } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-firehose'

const KinesisFirehoseIncomingChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    bytesS3: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_BYTES,
    bytesElasticSearch: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_BYTES,
    bytesRedshift: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_BYTES,
    bytesSplunk: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_BYTES,
    [KINESIS_FIREHOSE_OUTGOING]: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_RECORDS,
    recordsElasticSearch: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_RECORDS,
    recordsRedshift: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_RECORDS,
    recordsSplunk: data.KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_RECORDS
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey.startsWith('bytes')) return [Numeral(value).format('0.[00] b'), undefined]
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[0] b'
      span={span}
      tabKey={KINESIS_FIREHOSE_OUTGOING}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <YAxis yAxisId='records' orientation='right' domain={[0, 'dataMax']} {...yAxisProps} tickFormatter={value => Numeral(value).format('0.[00]a')} />
      <Bar dataKey={KINESIS_FIREHOSE_OUTGOING} yAxisId='records' stackId='a' fill={colors('chart', 'primary')} barSize={4} name='S3 Records' dot={false} />
      <Bar dataKey='recordsElasticsearch' yAxisId='records' fill={colors('chart', 'primary')} barSize={4} name='ElasticSearch Records' dot={false} />
      <Bar dataKey='recordsRedshift' yAxisId='records' fill={colors('chart', 'primary')} barSize={4} name='Redshift Records' dot={false} />
      <Bar dataKey='recordsSplunk' yAxisId='records' fill={colors('chart', 'primary')} barSize={4} name='Splunk Records' dot={false} />

      <Line dataKey='bytesS3' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='S3 Bytes' dot={false} />
      <Line dataKey='bytesElasticSearch' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='ElasticSearch Bytes' dot={false} />
      <Line dataKey='bytesRedshift' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Redshift Bytes' dot={false} />
      <Line dataKey='bytesSplunk' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Splunk Bytes' dot={false} />
    </ChartContainer>
  )
}

export default KinesisFirehoseIncomingChart
