import React from 'react'
import { Row, Col } from 'antd'
import { isEmpty } from 'lodash'

import { objectify } from 'lib/utils'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import InventoryTags from 'containers/inventory/shared/tags'
import Events from 'containers/inventory/shared/events'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'

import { EVENTBRIDGE_START_LATENCY } from '../../../metrics-tabs/services/eventbridge-eventbus'
import InventoryResourceContainer from '../../../container'
import EventBusConfig from './config'
import RulesList from './rules'
import { useResourcesQuery } from 'hooks/api'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <EventBusConfig attributes={attributes} inverse />
  </>)
}

const EventBridgeEventBus = ({ item }) => {
  const { data: rules } = useResourcesQuery({ type: INVENTORY_SERVICES.EventBridgeRule.type, parent: item.id })
  const hasAttributesOrTags = !isEmpty([...item.tags, ...item.attributes])

  return (
    <>
      <InventoryResourceContainer item={item} metadata={{ rules }} service={INVENTORY_SERVICES.EventBridgeEventBus.id} initialChart={EVENTBRIDGE_START_LATENCY}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey='configuration' items={[
                { key: 'rules', label: 'Rules', children: !isEmpty(rules) && <RulesList rules={rules && Object.values(rules)} /> },
                hasAttributesOrTags && { key: 'configuration', label: 'Configuration', children: hasAttributesOrTags && <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default EventBridgeEventBus
