import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, BILLING } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getBilling = async (identity) => {
  const data = await apiFetch(paths.billing(identity))
  return data
}

export const useGetBillingQuery = () => {
  const { organization } = useOrganization()

  return useQuery(
    [BILLING, organization.id],
    () => getBilling(organization?.id),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      retry: false
    }
  )
}
