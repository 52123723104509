import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import { isEmpty } from 'lodash'

import { useAllResourcesQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import ResourceGroups from 'features/resource-groups-list'
import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import { SettingsIcon } from 'components/icons'
import InventoryServiceGroups from './services'
import InventoryRegions from './regions'
import InventoryDelegations from './delegations'

import styles from './styles.module.less'

const EmptyDashboard = () => (
  <Content
    title='Inventory overview'
    breadcrumbs={['inventory']}
    actions={<SettingsButton />}
    item
  >
    <Empty title='No resources found' />
  </Content>
)

const SettingsButton = () => {
  return (
    <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]}>
      <Link to='settings/integrations' className={styles.settings_btn}>
        Integrations Settings <SettingsIcon className={styles.settings_icon} alt='Configuration' />
      </Link>
    </PermissionsGate>)
}

const InventoryDashboard = () => {
  const { data: resources, isLoading } = useAllResourcesQuery()

  return (
    <Content
      title='Inventory overview'
      breadcrumbs={['inventory']}
      actions={<SettingsButton />}
      item={resources && !isEmpty(resources)}
      loading={isLoading}
      renderEmpty={<EmptyDashboard />}
    >
      <InventoryServiceGroups />
      <Row gutter={8}>
        <Col xs={24} md={8}>
          <ResourceGroups view='inventory' />
        </Col>
        <Col xs={24} md={8}>
          <InventoryRegions />
        </Col>
        <Col xs={24} md={8}>
          <InventoryDelegations />
        </Col>
      </Row>

    </Content>
  )
}

export default InventoryDashboard
