import React, { useEffect, useState, useRef } from 'react'
import { Modal } from 'antd'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { get } from 'lodash'

import { POST_PAYMENT_METHOD, CREATE_PAYMENT_METHOD_SETUP, PAYMENT_METHOD_SETUP, CANCEL_PAYMENT_METHOD_SETUP, isMethodSetup } from 'actions/billing.actions'
import { API } from 'actions/api'

import Loading from 'components/layout/content/loading'
import BillingInfoForm from '../../billing-info/modal/form'
import validateBillingInfo from '../../billing-info/modal/validate'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const submitMethod = async (stripe, elements, intent, organization) => {
  if (!stripe || !elements || !intent) throw new Error('Stripe is not loaded')

  const result = await stripe.confirmCardSetup(intent.secret, {
    payment_method: {
      card: elements.getElement(CardElement),
      metadata: { organization: get(organization, 'id') }
    }
  })

  if (result.error) {
    throw new Error(result.error.message)
  }

  return result.setupIntent.payment_method
}

const AddMethodModal = ({ open, onCancel, onOk, customer }) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const shouldUpdateAddress = !get(customer, 'address')

  const billingInfoFormRef = useRef()
  const [error, setError] = useState(null)

  const { organization } = useOrganization()
  const intent = useSelector(state => state.billing.payment.intent)
  const loadingCreateIntent = useSelector(state => state.waiting.list[CREATE_PAYMENT_METHOD_SETUP])
  const loadingCancelIntent = useSelector(state => state.waiting.list[CANCEL_PAYMENT_METHOD_SETUP])
  const loadingPaymentCreate = useSelector(state => state.waiting.list[POST_PAYMENT_METHOD])
  const settingUpPayment = useSelector(state => state.waiting.list[PAYMENT_METHOD_SETUP])

  const loadingIntent = loadingCreateIntent || loadingCancelIntent

  useEffect(() => {
    if (open) {
      dispatch(API.organizations.id(organization.id).billing.payment.setup.post())
    }
  }, [open])

  const handleOk = async () => {
    let billingInfo
    if (shouldUpdateAddress) {
      billingInfo = await validateBillingInfo(billingInfoFormRef?.current)
      if (!billingInfo) {
        return null
      }
    }
    setError(null)
    dispatch(isMethodSetup(true))

    try {
      const paymentMethod = await submitMethod(stripe, elements, intent, organization)
      dispatch(API.organizations.id(organization.id).billing.payment.methods.post({ stripeId: paymentMethod }))

      if (shouldUpdateAddress) {
        dispatch(API.organizations.id(organization.id).billing.customers.active().put(billingInfo))
      }

      return onOk && onOk()
    } catch (error) {
      setError(error.message)
    } finally {
      dispatch(isMethodSetup(false))
    }
  }

  const handleCancel = async () => {
    if (intent) {
      dispatch(API.organizations.id(organization.id).billing.payment.setup.id(intent.stripeId).delete())
    }
    return onCancel && onCancel()
  }

  const validateVAT = API.organizations.id(organization.id).billing.VAT.validate
  return (
    <Modal
      title='Add a payment method'
      open={open}
      className={styles.modal}
      confirmLoading={loadingPaymentCreate || settingUpPayment}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div>
        {loadingIntent
          ? (
            <Loading theme='light' />
            )
          : (
            <>
              {shouldUpdateAddress && <BillingInfoForm validateVat={(country, vat) => dispatch(validateVAT(country, vat))} customer={customer} ref={billingInfoFormRef} />}
              <CardElement />
            </>
            )}
        {error && <div className={styles.errorText}>{error}</div>}
      </div>
    </Modal>
  )
}

const PaymentMethodsModal = (props) => {
  const [stripePromise] = useState(() => loadStripe(CONFIG.STRIPE_API_KEY))

  return (
    <Elements stripe={stripePromise}>
      <AddMethodModal {...props} />
    </Elements>
  )
}

export default PaymentMethodsModal
