import numeral from 'numeral'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faBug, faNetworkWired, faServer, faWind } from '@fortawesome/pro-light-svg-icons'
import { faBoxes } from '@fortawesome/pro-light-svg-icons/faBoxes'

import { getSparklineByName, getLast, getSum } from '../helpers'

export const REQUESTS = 'elb-requests'
export const ERRORS = 'elb-errors'
export const CONNECTIONS = 'elb-connections'
export const LCU = 'elb-lcu'
export const DATA = 'elb-data'
export const TARGET_GROUPS = 'targetGroups'

const metrics = [{
  key: REQUESTS,
  icon: faSigma,
  title: 'Requests',
  filter: (collection) => numeral(getSum(collection, 'ELB_LOADBALANCER_REQUESTS')).format('0.[0]a'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_REQUESTS'),
  selectable: true
},
{
  key: ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const errors5xx = getSum(collection, 'ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_5XX')
    return numeral(errors5xx).format('0.[0]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_REQUESTS'),
  selectable: true
},
{
  key: CONNECTIONS,
  icon: faNetworkWired,
  title: 'Connections',
  filter: (collection) => numeral(getLast(collection, 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE')).format('0a'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE'),
  selectable: true
},
{
  key: LCU,
  icon: faServer,
  title: 'Consumed LCUs',
  filter: (collection) => numeral(getSum(collection, 'ELB_LOADBALANCER_LCU')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_LCU'),
  selectable: true
},
{
  key: DATA,
  icon: faWind,
  title: 'Throughput',
  filter: (collection) => numeral(getSum(collection, 'ELB_LOADBALANCER_PROCESSED_BYTES')).format('0b'),
  subvalue: (collection) => getSparklineByName(collection, 'ELB_LOADBALANCER_PROCESSED_BYTES'),
  selectable: true
},
{
  key: TARGET_GROUPS,
  icon: faBoxes,
  title: 'Target Groups',
  filter: (c, i, metadata) => metadata.targetGroups.length
}]

export default metrics
