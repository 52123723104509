import React from 'react'

import PrettyPrinter from 'components/json-pretty'
import Metadata from 'containers/inventory/shared/metadata/index'

const EventBridgeEventBusMetadata = ({ attributes, inverse }) => {
  const data = [{
    title: 'Policy',
    value: <PrettyPrinter jsonString={JSON.parse(attributes.Policy)} collapsed={false} />
  }]
  return <Metadata items={data} inverse={inverse} />
}

export default EventBridgeEventBusMetadata
