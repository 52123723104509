import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { Tooltip } from 'antd'

import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { LongArrowRightIcon, ArrowBackIcon } from 'components/icons/font-awesome'
import SkeletonLine from 'components/skeleton-line'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery } from 'hooks/api'
import { Breakpoint, useResize } from 'hooks/resize'

import styles from './styles.module.less'

const BackArrow = ({ backRoute }) => {
  const { search } = useLocation()
  const params = parseQueryString(search)
  const newValue = stringifyQuery({ ...params, selected: undefined })
  const backUrl = { pathname: backRoute || '', search: newValue }

  return (
    <Link to={backUrl} className={styles.back}><ArrowBackIcon /></Link>
  )
}

const Ellipsis = ({ children, show }) => {
  if (!show || !children.length) return children

  return (
    <>...</>
  )
}

const Breadcrumb = ({ text, loading, backRoute, hideArrow, className }) => {
  const showRightArrow = !backRoute && !hideArrow
  const showLoading = loading || !text

  return (
    <>
      {showRightArrow && <LongArrowRightIcon className={styles.arrow}/>}
      {showLoading
        ? <SkeletonLine className={styles.breadcrumb_loading} />
        : <span className={classnames(styles.breadcrumb, className)}>{text}</span>
      }
    </>
  )
}

const TooltipBreadcrumbs = ({ items }) => {
  return (
    <div className={styles.tooltip}>
      {items.map((item, index) => (
        <div key={index}>
          {item.text}
          {index < items.length - 1 && <LongArrowRightIcon className={styles.arrow} />}
        </div>
      ))}
    </div>
  )
}

const Container = ({ showTooltip, tooltipTitle, children, className }) => {
  return showTooltip
    ? <Tooltip title={tooltipTitle} className={classnames(styles.container, className)}>
        {children}
      </Tooltip>
    : <div className={classnames(styles.container, className)}>{children}</div>
}

const Breadcrumbs = ({ breadcrumbs = [], className, backRoute, loading }) => {
  const { isVisibleUntil } = useResize()
  const { organization, account } = useOrganization()
  const { isLoading } = useOrganizationsQuery()

  const showTooltip = isVisibleUntil(Breakpoint.md)

  const filtered = breadcrumbs?.filter(item => item)
  const all = [
    { text: organization?.name, backRoute, hideArrow: !backRoute, loading: isLoading },
    { text: account?.name },
    ...filtered.map(text => ({ text }))
  ]

  // conceal all except last two items as a single ellipsis
  const concealable = isLoading || loading ? [] : all.slice(0, all.length - 2)
  // shrink last two items so that their text overflow has ellipsis
  const shrinkable = isLoading || loading ? all : all.slice(-2)

  const tooltipTitle = <TooltipBreadcrumbs items={all} />

  return (
    <>
      <div className={styles.container}>
        {backRoute && <BackArrow backRoute={backRoute} />}
      </div>
      <Container
        showTooltip={showTooltip}
        tooltipTitle={tooltipTitle}
        className={classnames(styles.container, styles.breadcrumbs, className)}
      >
        <Ellipsis show={showTooltip}>
          {concealable.map((breadcrumb, index) => (
            <Breadcrumb key={`c${index}`} index={index} loading={loading} {...breadcrumb} />
          ))}
        </Ellipsis>

        {shrinkable.map((breadcrumb, index) => (
          <Breadcrumb key={`s${index}`} index={index} loading={loading} {...breadcrumb} className={styles.shrinkable}/>
        ))}
      </Container>
    </>
  )
}

export default Breadcrumbs
