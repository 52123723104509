import { useEffect, useState } from 'react'

export const useLimit = (active, limit) => {
  const [isLimited, setIsLimited] = useState(true)
  const [enabled, setEnabled] = useState(true)

  const getLimitedItems = (items) => {
    if (!items) return
    if (!enabled) return items

    // use setters like this to avoid infinite rerender loop
    const shouldLimit = active && limit < items.length
    if (!shouldLimit && isLimited) {
      setIsLimited(shouldLimit)
    } else if (shouldLimit && !isLimited) {
      setIsLimited(shouldLimit)
    }

    if (!active || !shouldLimit) return items

    return items.slice(0, limit)
  }

  useEffect(() => {
    setIsLimited(enabled && active)
  }, [enabled, active])

  return {
    enabled,
    setEnabled,
    isLimited,
    getLimitedItems
  }
}
