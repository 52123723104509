import { useMutation, useQueryClient } from 'react-query'

import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { SUBSCRIPTION, billingPaths as paths } from '../index'

const updateSubscription = async (organization, identity, payload) => {
  const data = apiFetch(paths.subscription(organization, identity), { method: 'PUT', body: JSON.stringify(payload) })

  return data
}

type Variables = { identity: string, payload: any }

export const useUpdateSubscriptionQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ identity, payload }: Variables) => updateSubscription(organization.id, identity, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...SUBSCRIPTION, data?.id])
      }
    }
  )
}
