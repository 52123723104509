import numeral from 'numeral'
import { sum, get, compact } from 'lodash'

import { faBookOpen } from '@fortawesome/pro-light-svg-icons/faBookOpen'
import { faPenNib } from '@fortawesome/pro-light-svg-icons/faPenNib'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'

import { getSparklineByName, aggregateSparkline, average, getSum, getLast } from '../helpers'
import { differenceInMinutes, parseISO } from 'date-fns'

export const DYNAMODB_READS = 'reads'
export const DYNAMOBD_WRITES = 'writes'
export const DYNAMOBD_ERRORS = 'errors'
export const DYNAMOBD_LATENCY = 'latency'

const metrics = [{
  key: DYNAMODB_READS,
  icon: faBookOpen,
  title: 'Reads',
  filter: (collection) => {
    const sum = getSum(collection, 'DYNAMODB_READS')
    const end = parseISO(get(collection, 'DYNAMODB_READS.end'))
    const start = parseISO(get(collection, 'DYNAMODB_READS.start'))
    const totalTime = differenceInMinutes(end, start) * 60
    return `${numeral(sum / totalTime).format('0.[000]a')} RCU`
  },
  subvalue: (collection) => getSparklineByName(collection, 'DYNAMODB_READS'),
  selectable: true
}, {
  key: DYNAMOBD_WRITES,
  icon: faPenNib,
  title: 'Writes',
  filter: (collection) => {
    const sum = getSum(collection, 'DYNAMODB_WRITES')
    const end = parseISO(get(collection, 'DYNAMODB_WRITES.end'))
    const start = parseISO(get(collection, 'DYNAMODB_WRITES.start'))
    const totalTime = differenceInMinutes(end, start) * 60
    return `${numeral(sum / totalTime).format('0.[000]a')} WCU`
  },
  subvalue: (collection) => getSparklineByName(collection, 'DYNAMODB_WRITES'),
  selectable: true
}, {
  key: DYNAMOBD_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const system = getSum(collection, 'DYNAMODB_ERRORS_SYSTEM')
    const user = getSum(collection, 'DYNAMODB_ERRORS_USER')
    return numeral(system + user).format('0.[00]a')
  },
  subvalue: (collection) => {
    return aggregateSparkline(collection, sum, 'DYNAMODB_ERRORS_SYSTEM', 'DYNAMODB_ERRORS_USER')
  },
  selectable: true
}, {
  key: DYNAMOBD_LATENCY,
  icon: faClock,
  title: 'Avg latency',
  filter: (collection) => {
    const values = compact([
      getLast(collection, 'DYNAMODB_LATENCY_SCAN'),
      getLast(collection, 'DYNAMODB_LATENCY_QUERY'),
      getLast(collection, 'DYNAMODB_LATENCY_ITEM_GET'),
      getLast(collection, 'DYNAMODB_LATENCY_ITEM_PUT'),
      getLast(collection, 'DYNAMODB_LATENCY_ITEM_UPDATE')
    ])

    return `${numeral(average(values)).format('0.[00]a')} ms`
  },
  subvalue: (collection) => {
    return aggregateSparkline(
      collection,
      average,
      'DYNAMODB_LATENCY_SCAN',
      'DYNAMODB_LATENCY_QUERY',
      'DYNAMODB_LATENCY_ITEM_GET',
      'DYNAMODB_LATENCY_ITEM_PUT',
      'DYNAMODB_LATENCY_ITEM_UPDATE'
    )
  },
  selectable: true
}]

export default metrics
