import React from 'react'
import { Typography, Row, Col } from 'antd'

import { BadgeCheckIcon } from 'components/icons'

const { Text } = Typography

const TierTitle = ({ title, selected } = {}) => {
  return (
    <Row type='flex' justify='center' gutter={8}>
      {selected ? <Col><BadgeCheckIcon /></Col> : null}
      <Col><Text strong>{title}</Text></Col>
    </Row>
  )
}

export default TierTitle
