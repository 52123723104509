import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { policyPaths as paths, NOTIFICATION_POLICY } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getNotificationPolicy = async (account, identity) => {
  const data = await apiFetch(paths.policy(account, identity))

  return data?.data
}

export const useNotificationPolicyQuery = (identity) => {
  const { account } = useOrganization()

  return useQuery(
    [NOTIFICATION_POLICY, identity],
    () => getNotificationPolicy(account.id, identity),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!identity,
      refetchOnWindowFocus: false
    }
  )
}
