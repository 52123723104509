import React from 'react'
import { Radio, Checkbox } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ErrorTypes } from 'lib/policy-constants'
import { getResourcesByType } from 'lib/resources/filters'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { SEVERITY_TYPES } from 'lib/event-constants'

import TargetSelector from 'features/target-selector'
import Select from 'components/antd/select'
import { ErrorIcon } from 'components/icons'
import Actions from './actions'

import styles from './styles.module.less'

const ErrorForm = ({ mutations, condition, resources, lambdas, groups }) => {
  const selectableAppsync = getResourcesByType(resources, INVENTORY_SERVICES.AppSyncGraphQLApi.type)

  const handlers = mutations({ condition })
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <ErrorIcon /><span>Errors</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>error types</span>
          <span className={styles.value}>{condition?.errors?.length ? condition.errors.length : 'ALL'}</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>resources</span>
          <span className={styles.value}>{condition?.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} />
        </div>
      </div>
      <section>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Radio.Group
            defaultValue={condition.occurrence}
            onChange={(e) => handlers.update({ occurrence: e.target.value })}
          >
            <Radio.Button value='new'>Only new and reopened events</Radio.Button>
            <Radio.Button value='all'>All events</Radio.Button>
          </Radio.Group>
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Severities</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Checkbox.Group
            options={[SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING, SEVERITY_TYPES.INFO]}
            defaultValue={condition.severities}
            onChange={(values) => handlers.update({ severities: values })}
          />
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Filter by error types</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Select
            solid
            mode='multiple'
            defaultValue={condition.errors}
            placeholder='Select error types'
            onChange={(values) => handlers.update({ errors: values })}
            options={ErrorTypes.map(item => ({ title: item.title, id: item.value, value: item.value }))}
          />
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Filter by resources</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <TargetSelector
            resourceGroups={groups}
            lambdas={lambdas}
            resources={selectableAppsync}
            onSelect={(value) => handlers.targets(value).add()}
            onDeselect={(value) => handlers.targets(value).remove()}
            value={condition.targets}
            prefixValues
            placeholder='Select resources'
          />
        </PermissionsGate>
      </section>
    </div>
  )
}

export default ErrorForm
