import React from 'react'
import { useLocation } from 'react-router-dom'
import { Form } from 'antd'

import Email from 'containers/auth/form-items/email'
import Submit from 'components/antd/form/submit'
import AuthTitle from '../title'

const ForgotPassword = ({ loading, onSubmit }) => {
  const { query } = useLocation()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  return (
    <>
      <AuthTitle title='Forgot password' />
      <Form onFinish={onSubmitWrapper} layout='vertical' requiredMark='optional'>
        <Email initialValue={query} />
        <Submit loading={loading} text='Send email' titleUpperCase />
      </Form>
    </>)
}

export default ForgotPassword
