import React, { useState } from 'react'
import { Alert, message } from 'antd'
import { Auth } from '@aws-amplify/auth'

import { Typography } from 'components/typography'
import Section from 'components/layout/content/section'
import Credentials from './credentials'

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const onSubmit = async (values) => {
    setLoading(true)

    try {
      await Auth.currentSession()

      const { current, password } = values

      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, current, password)
      setError(null)
      message.success('Password updated', 5)
    } catch (e) {
      console.log('ChangePassword error: ', e)
      setError(e.message)
      message.error('Could not update password', 5)
    }

    setLoading(false)
  }

  return <Section title=''>
    <Typography.Paragraph>Change your password or setup MFA</Typography.Paragraph>
    {error && <Alert description={error} type='error' showIcon />}
    <Credentials onSubmit={onSubmit} loading={loading} />
  </Section>
}

export default ChangePassword
