import React from 'react'
import Numeral from 'numeral'
import { Bar } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { HEALTHY } from 'containers/inventory/details/resource/metrics-tabs/services/elb-target-group'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [HEALTHY]: data.ELB_TARGETGROUP_HEALTHY,
    unhealthy: data.ELB_TARGETGROUP_UNHEALTHY
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0'
      span={span}
      tabKey={HEALTHY}
      formatTab={value => `${Numeral(value).format('0a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={HEALTHY} stackId='a' fill={colors('chart', 'primary')} name='Healthy targets' barSize={4} />
      <Bar dataKey='unhealthy' stackId='a' fill={colors('chart', 'red')} name='Unhealthy targets' barSize={4} />
    </ChartContainer>
  )
}

export default ELBRequestsChart
