import { useMutation } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const createMethodSetup = async (identity) => {
  const data = apiFetch(paths.paymentSetups(identity), { method: 'POST' })

  return data
}

export const useCreatePaymentMethodSetupQuery = () => {
  const { organization } = useOrganization()

  return useMutation(
    () => createMethodSetup(organization.id),
    {
      onSuccess: (data) => {
        // queryClient.invalidateQueries([...PAYMENT_METHODS, organization.id])
        // handleListClose()
      }
    }
  )
}

const cancelMethodSetup = async (identity, setupIdentity) => {
  const data = apiFetch(paths.paymentSetup(identity, setupIdentity), { method: 'DELETE' })

  return data
}

export const useDeletePaymentMethodSetupQuery = () => {
  const { organization } = useOrganization()

  return useMutation(
    ({ intentId }) => cancelMethodSetup(organization.id, intentId),
    {
      onSuccess: (data) => {
        // handleListClose()
      }
    }
  )
}
