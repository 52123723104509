import React from 'react'
import { countBy } from 'lodash'

import Section from 'components/layout/content/section'
import { Region } from 'components/items/progress-item'
import { REGIONS } from 'lib/aws-regions'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useErrorsQuery, useAllResourcesQuery } from 'hooks/api'

import styles from './styles.module.less'

const RegionsList = () => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('region', [])

  const { data: errors, isLoading } = useErrorsQuery()
  const { isLoading: resourcesLoading } = useAllResourcesQuery()

  const countByRegion = countBy(errors, 'resource.region')

  const items = Object.keys(countByRegion)?.map(region => {
    if (!region) return {}
    const regionInfo = REGIONS.find(item => item.region === region)
    return {
      title: region,
      count: countByRegion[region],
      total: errors?.length,
      description: regionInfo?.name,
      area: regionInfo?.area,
      id: region
    }
  })

  return (
    <Section title='Regions' loading={isLoading || resourcesLoading} className={styles.regions_wrapper} titleUpperCase>
      {items.length !== 0
        ? items?.sort((a, b) => b.count - a.count).map(item =>
          <Region
            key={item.id}
            {...item}
            selected={selected}
            onSelect={region => setSelected(filterHelper(region, selected))}
          />)
        : <p>No errors found</p>}
    </Section>
  )
}

export default RegionsList
