import React from 'react'
import Numeral from 'numeral'
import { max } from 'lodash'

import { Cell, Line, Bar, ReferenceLine, YAxis } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { yAxisProps } from 'components/charts/utils'
import { KINESIS_DS_DIFFERENCE } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-datastream'

const calculateYAxisDomain = (values) => {
  const bytesDifferenceDomain = max(values.map(item => Math.abs(item.bytesDifference)))
  const recordsDifferenceDomain = max(values.map(item => Math.abs(item[KINESIS_DS_DIFFERENCE])))

  return {
    bytesDifferenceDomain: [-bytesDifferenceDomain, bytesDifferenceDomain],
    recordsDifferenceDomain: [-recordsDifferenceDomain, recordsDifferenceDomain]
  }
}

const DifferenceChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    incomingRecords: data.KINESIS_DATA_STREAM_INCOMING_RECORDS,
    outgoingRecords: data.KINESIS_DATA_STREAM_OUTGOING_RECORDS,
    incomingBytes: data.KINESIS_DATA_STREAM_INCOMING_BYTES,
    outgoingBytes: data.KINESIS_DATA_STREAM_OUTGOING_BYTES
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    const recordsDifference = metric.incomingRecords - metric.outgoingRecords
    const bytesDifference = metric.incomingBytes - metric.outgoingBytes
    return {
      ...metric,
      [KINESIS_DS_DIFFERENCE]: recordsDifference,
      bytesDifference,
      recordsIncreasing: recordsDifference > 0,
      bytesIncreasing: bytesDifference > 0
    }
  })

  const tooltipFormatter = (value, name, props) => {
    if (props.dataKey === KINESIS_DS_DIFFERENCE) {
      return props.payload.recordsIncreasing ? [`+${Numeral(value).format('0.[00]a')}`, undefined] : [`${Numeral(value).format('0.[00]a')}`, undefined]
    } else if (props.dataKey === 'bytesDifference') {
      return props.payload.bytesIncreasing ? [`+${Numeral(value).format('0.[00] b')}`, undefined] : [`${value === 0 ? '' : '-'}${Numeral(value * -1).format('0.[00] b')}`, undefined]
    } else {
      return [Numeral(value).format('0.[000]a'), undefined]
    }
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      formatYTicks='0.[0] b'
      span={span}
      tabKey={KINESIS_DS_DIFFERENCE}
      formatTab={value => `${Numeral(value).format('0.[00] a')}`}
      formatTooltip={tooltipFormatter}
      yDomain={calculateYAxisDomain(formatted)?.recordsDifferenceDomain}
    >
      <ReferenceLine y={0} stroke={colors('chart', 'grid')} />

      <YAxis yAxisId='bytes' orientation='right' tickFormatter={value => {
        if (value > 0) return Numeral(value).format('0.[0] b')
        return `${value === 0 ? '' : '-'}${Numeral(value * -1).format('0.[0] b')}`
      }} {...yAxisProps} domain={calculateYAxisDomain(formatted)?.bytesDifferenceDomain} />
      <Line type='monotone' dataKey='bytesDifference' yAxisId='bytes' stroke={colors('chart', 'primaryDark')} strokeWidth={1} name='Bytes difference' dot={false} />

      <Bar dataKey={KINESIS_DS_DIFFERENCE} stackId='a' fill='#ababab' name='Records difference' barSize={4}>
        {
          formatted.map((entry, index) => (
            <Cell key={index} fill={entry.recordsIncreasing ? colors('chart', 'primaryDark') : colors('chart', 'red')} />
          ))
        }
      </Bar>
    </ChartContainer>
  )
}

export default DifferenceChart
