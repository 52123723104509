import React, { useContext } from 'react'
import classnames from 'classnames'

import IconButton from 'components/buttons/icon'
import { CloseIcon } from 'components/icons/font-awesome'
import Breadcrumbs from 'components/breadcrumbs'
import { ScrollContext } from 'components/layout/content'

import styles from './styles.module.less'

const FixedHeader = ({
  title,
  icon,
  onClose,
  breadcrumbs = [],
  topBarActions,
  titleBarActions,
  bordered,
  className
}: {
  title: any,
  icon: any,
  onClose?: any,
  breadcrumbs?: any,
  topBarActions?: any,
  titleBarActions?: any,
  bordered?: boolean,
  className?: string
}) => {
  const { width } = useContext(ScrollContext)

  return (
    <div className={classnames(styles.wrapper, className, { [styles.bordered]: !!bordered })} style={{ width }}>
      <div className={styles.header}>
        {onClose && <IconButton icon={<CloseIcon />} onClick={onClose} className={styles.close_btn} />}
        {icon}
        <div className={styles.header_content}>
          <div className={classnames(styles.row, styles.top)}>
            <Breadcrumbs breadcrumbs={breadcrumbs} className={styles.breadcrumbs} backRoute={null} loading={false} />
            <div className={styles.actions}>{topBarActions}</div>
          </div>
          <div className={styles.row}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.actions}>{titleBarActions}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FixedHeader
