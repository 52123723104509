import { useEffect, useMemo, useState } from 'react'
import { useChartMetricsQuery } from 'hooks/api'

import { getChartPayloads } from 'lib/dashboards-helpers'

const useMetricsFetcher = ({ widgets, resources, resourceGroup, start, end, relativeSpan, refreshInterval }) => {
  const [metrics, setMetrics] = useState([])

  const payloads = useMemo(() => getChartPayloads(widgets, resources, resourceGroup, start, end), [widgets, resources, resourceGroup, start, end])
  const metricsData = useChartMetricsQuery(payloads, relativeSpan, refreshInterval)

  const loading = useMemo(() => metricsData.some(item => item.isLoading), [metricsData])
  const fetching = useMemo(() => metricsData.some(item => item.isFetching), [metricsData])

  const refetch = (e) => {
    e?.stopPropagation()
    metricsData.map(item => item?.refetch())
  }

  const formatData = () => {
    if (loading || fetching) return

    const mappedMetrics = metricsData?.reduce((acc, item) => {
      acc[item?.data?.metricId] = item.data
      return acc
    }, {})

    setMetrics(mappedMetrics)
  }

  // VERY TRICKY -- BE CAREFUL
  useEffect(formatData, [payloads?.join(x => x.id), loading, fetching, relativeSpan])

  return { data: metrics, loading, fetching, refetch }
}

export default useMetricsFetcher
