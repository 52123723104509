import React, { useEffect, useRef } from 'react'
import { useHistory, useParams, Switch, Route } from 'react-router-dom'

import SplitPane from 'features/layout/split-pane'
import List from './list'
import Resource from 'containers/inventory/details/resource'
import Overview from './overview'

import { useAccountRoutes } from 'containers/routes'
import { useAllResourcesQuery, useResourceGroupQuery } from 'hooks/api'
import { getInventoryResources } from 'lib/resources/filters'

const ResourceGroup = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { groupId, resourceId } = useParams()

  const { data: group, isLoading: loadingGroup } = useResourceGroupQuery(groupId)
  const { data: resourcesAll, isLoading } = useAllResourcesQuery()
  const resources = getInventoryResources(resourcesAll)

  const loading = loadingGroup || isLoading

  useEffect(() => { document.title = 'Dashbird.io - Resource Groups' }, [])

  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) {
      if (!loadingGroup && !group) history.push(routes.resourceGroups.url())
    } else didMount.current = true
  }, [group, loadingGroup])

  const groupResources = Object.values(resources)?.filter(item => group?.resources?.includes(item.id))

  const resourceSelected = resources?.find(item => item.id === resourceId)

  const selectResource = (resource) => {
    history.push(routes.resourceGroups.resource.url({ groupId, resourceId: resource.id }))
  }

  return (
    <SplitPane
      left={
        <List
          selected={resourceSelected?.id}
          resources={groupResources}
          loading={loading}
          onResourceSelect={selectResource}
        />
      }
      right={
        <Switch>
          <Route exact path={routes.resourceGroups.resource.path} render={() => (
            <Resource />
          )} />
          <Route>
            <Overview group={group} resources={groupResources} loadingGroup={loading} />
          </Route>
        </Switch>
      }
    />)
}

export default ResourceGroup
