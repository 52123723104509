import React from 'react'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'

const shorten = (value) => {
  if (value.includes('seconds')) return value.replace('seconds', 's')
  if (value.includes('second')) return value.replace('second', 's')
  if (value.includes('minutes')) return value.replace('minutes', 'min')
  if (value.includes('minute')) return value.replace('minute', 'min')
  if (value.includes('hours')) return value.replace('hours', 'h')
  if (value.includes('hour')) return value.replace('hour', 'h')
  if (value.includes('days')) return value.replace('days', 'd')
  if (value.includes('day')) return value.replace('day', 'd')
  if (value.includes('months')) return value.replace('months', 'm')
  if (value.includes('month')) return value.replace('month', 'm')
  return value
}

const format = (value, type) => {
  if (type === 'short') return shorten(formatDistanceToNowStrict(value))
  return formatDistanceToNowStrict(value)
}

const parse = (value) => {
  if (typeof value === 'string') return parseISO(value)
  return value
}

export const Time = ({ type, value, className }) => {
  const values = value && value.map ? value : [value]

  return (
    <span className={className}>
      {values.map(value => format(parse(value), type)).join(' - ')} ago
    </span>
  )
}
