import React from 'react'
import { AwsServiceIcon } from 'components/icons'
import Metadata from 'containers/inventory/shared/metadata'
import styles from './styles.module.less'

const Attribute = ({ name, value, icon, ...props }) => {
  return (
    <div className={styles.items_container} {...props}>
      <AwsServiceIcon service={icon} className={styles.service_icon} size='small' />
      <div>
        <p className={styles.attribute_name}>{name}</p>
        <p className={styles.attribute_value}>{value}</p>
      </div>
    </div>
  )
}
const KinesisFirehoseMetadata = ({ attributes = {}, inverse }) => {
  const destinations = JSON.parse(attributes?.destinations) || []

  const data = [{
    title: 'Source',
    value: <Attribute key='source' name='' value={attributes?.deliveryStreamType} icon='kinesis.data-streams' />
  }, destinations?.length
    ? { title: 'Destination', value: <Attribute key='destination' name={destinations[0].type} value={''} icon='kinesis.firehose' /> }
    : {}]

  return <Metadata items={data} inverse={inverse} />
}

export default KinesisFirehoseMetadata
