import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'SNS_MESSAGES_PUBLISHED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.published',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'SNS_PAYLOAD_SIZE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'messages.size',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX],
    fill: 'gapfill'
  }
}, {
  id: 'SNS_NOTIFICATIONS_DELIVERED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'notifications.delivered',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'SNS_NOTIFICATIONS_FAILED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'notifications.failed',
    stats: [STATISTICS.SUM]
  }
}, {
  id: 'SNS_NOTIFICATIONS_FILTERED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'notifications.filtered.out',
    stats: [STATISTICS.SUM]
  }
}]

export default metrics
