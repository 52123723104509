import React, { useMemo } from 'react'
import { last, upperFirst } from 'lodash'
import classnames from 'classnames'
import { LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import Empty from 'components/charts/empty'
import Loading from 'components/layout/content/loading'
import WidgetChartContainer from 'features/widgets/charts/container'
import EditableTitle from 'features/editable-title'
import Actions from '../actions'
import { getMetricDefinition, getChartContents, getChartData, getTooltipFormatter, hasMinMaxStats, isSparkline } from './helpers'
import { getMetricKey } from 'lib/dashboards-helpers'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import styles from './styles.module.less'

// ## Wrapper for recharts - workaround for animation being super slow when decresing the widget in size
const CustomResponsiveContainer = (props) => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0
        }}
      >
        <ResponsiveContainer {...props}>
          {props.children}
        </ResponsiveContainer>
      </div>
      <div className={classnames(styles.sparkline_value, { [styles.smaller]: props.smaller })}>
        {props.value}
      </div>
    </div>
  )
}

export const MetricsWidgetChart = ({ widget, metrics, options, staticWidget }) => {
  const metricDefinition = useMemo(() => getMetricDefinition(widget), [widget])
  const chartData = useMemo(() => getChartData(widget, metrics, metricDefinition), [widget, metrics, metricDefinition])
  const chartContents = useMemo(() => getChartContents(widget), [widget])
  const tooltipFormatter = useMemo(() => getTooltipFormatter(widget, metricDefinition), [widget, metricDefinition])

  const showLegend = useMemo(() => widget.layout.width >= 6, [widget.layout])

  if (metrics.loading) return <Loading height='100%' />
  if (!chartData.length) return <Empty height='100%' />

  if (isSparkline(widget)) {
    const hasMinMax = hasMinMaxStats(widget?.definition?.metrics[0]?.stats)
    const metricKey = getMetricKey(widget, widget?.definition?.metrics[0])
    const dataKey = hasMinMax ? `${metricKey}_avg` : metricKey
    const sparklineValue = tooltipFormatter(last(chartData)[dataKey], '', { dataKey })
    return (
      <div className={styles.chart}>
        <CustomResponsiveContainer width='99%' height='100%' debounce={300} value={sparklineValue[0]} smaller={widget?.layout?.height === 1}>
          <LineChart data={chartData} style={{ cursor: staticWidget ? 'auto' : 'pointer' }}>
            <XAxis dataKey='date' hide />
            <YAxis domain={[0, 'dataMax']} hide />
            {chartContents}
          </LineChart>
        </CustomResponsiveContainer>
      </div >
    )
  }

  return (
    <div className={styles.chart}>
      <WidgetChartContainer
        span={options.range}
        data={chartData}
        metricKey={null}
        disableSync={widget.id === 'preview'}
        showLegend={showLegend}
        percentageChart={metricDefinition?.percentageChart || false}
        formatYTicks={metricDefinition?.formatYTicks}
        formatTooltip={tooltipFormatter}
        setHoveredValue={() => null}
        staticWidget={staticWidget}
      >
        {chartContents}
      </WidgetChartContainer>
    </div>
  )
}

const MetricsWidget = ({ widget, metrics, options, onDelete, onEdit, onDuplicate, onUpdateName, staticWidget }) => {
  // ### RELICS OF BYGONE ERA -- DO NOT REMOVE ###
  // const [hoveredValue, setHoveredValue] = useState(null)
  // <div>{hoveredValue ? hoveredValue.x : upperFirst(metricDefinition?.title)}</div>
  // {hoveredValue && hoveredValue.y}

  // Metric definition as defined in lib/resources/constants.js
  const metricDefinition = useMemo(() => getMetricDefinition(widget), [widget])

  const small = isSparkline(widget)

  return (
    <div className={styles.widget_container}>
      <div className={classnames(styles.title, { [styles.small]: !!small })}>
        <div className={classnames({ [styles.sparkline_title]: small })}>
          <EditableTitle
            value={widget?.name || upperFirst(metricDefinition?.title)}
            onSubmit={value => onUpdateName(widget, value)}
            displayContent={widget?.name || upperFirst(metricDefinition?.title)}
            readOnly={small || staticWidget}
            size='xs'
            solid
            uppercase
          />
        </div>

        {!staticWidget && (
          <span className={styles.actions}>
            <IconButton icon={<DragIcon />} className='dbDragHandle' />
            <Actions
              widget={widget}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
            />
          </span>)}
      </div>
      {!small && <div className={styles.separator} />}
      <div className={styles.wrapper} onClick={() => onEdit(widget)}>
        <MetricsWidgetChart
          widget={widget}
          metrics={metrics}
          options={options}
          staticWidget={staticWidget}
        />
      </div>
    </div>
  )
}

export default MetricsWidget
