import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { sortBy, sum } from 'lodash'
import { parseISO } from 'date-fns'

import { useGetQueriesQuery, useErrorMetricsQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import useQueryParams from 'lib/hooks/use-query-params'
import { useAccountRoutes } from 'containers/routes'

import List from 'features/side-list'
import ListHeader from 'features/side-list/header'
import ListSearch from 'features/side-list/search'
import Empty from 'features/side-list/empty'
import { NameAlphabetical, OccurrenceCount, OccurrenceLast } from 'features/side-list/sorter'
import { SplitViewContext } from 'features/layout/split-pane'
import IconButton from 'components/buttons/icon'
import { AddIcon } from 'components/icons'
import Item from './item'
import { SearchMetricsContext } from '../index'

import styles from './styles.module.less'

const ScheduledSearchList = () => {
  const { queryId } = useParams()
  const history = useHistory()
  const routes = useAccountRoutes()
  const { getValue: searchQuery } = useQueryParams()

  const { handleListClose } = useContext(SplitViewContext)
  const { dashboardStart, dashboardEnd } = useContext(SearchMetricsContext)
  const sorters = [OccurrenceCount, OccurrenceLast, NameAlphabetical]

  const { data: savedQueries, isLoading } = useGetQueriesQuery()
  const { data: metrics } = useErrorMetricsQuery({ key: 'scheduled-query', options: { group: 'hash' } }, dashboardStart, dashboardEnd)

  const [scheduledQueries, setScheduledQueries] = useState(null)
  const [sorter, setSorter] = useState(sorters[0])

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const sortQueries = () => {
    setScheduledQueries(sorter?.sort ? sorter.sort(scheduledQueries) : sortBy(scheduledQueries, sorter.apply))
  }

  const onSelect = (query) => {
    history.push(routes.scheduledSearch.query.url({ queryId: query?.id }))
    handleListClose()
  }

  useEffect(() => {
    if (!savedQueries) return

    const scheduled = savedQueries?.filter(item => item?.frequency && (item?.name?.includes(searchQuery) || item?.query?.includes(searchQuery)))
    const scheduledWithCount = scheduled?.map(query => {
      const count = sum(metrics?.datapoints[query.id]?.map(item => item.sum))
      return {
        ...query,
        count,
        ...(query?.lastOccurrenceAt && { lastOccurredAt: parseISO(query.lastOccurrenceAt) })
      }
    })

    const sorted = sorter?.sort ? sorter.sort(scheduledWithCount) : sortBy(scheduledWithCount, sorter.apply)

    setScheduledQueries(sorted)
  }, [savedQueries, searchQuery, metrics])

  const handleCreateNew = () => {
    history.push(routes.scheduledSearch.new.url())
    handleListClose()
  }

  useEffect(sortQueries, [sorter])

  return (
    <List
      renderItem={Item}
      selected={queryId}
      onSelect={onSelect}
      search={<ListSearch />}
      header={<ListHeader
        sorters={sorters}
        selectedSorter={sorter.key}
        onSorterChange={onSorterChange}
        extra={
          <PermissionsGate scopes={[SCOPES.canCreate]}>
            <IconButton
              icon={<AddIcon />}
              title='New search'
              onClick={handleCreateNew}
              className={styles.add_btn}
            />
          </PermissionsGate>}
        overviewURL={routes.scheduledSearch.url()}
      />}
      items={scheduledQueries}
      loading={isLoading}
      renderEmpty={
        <Empty
          title='No scheduled queries found'
          details={<>Go to <Link to={routes.search.url()}>search page</Link> to schedule queries</>}
        />}
      itemHeight={62}
    />
  )
}

export default ScheduledSearchList
