import React from 'react'
import classnames from 'classnames'
import { get } from 'lodash'
import { Skeleton, Card, Row, Col, Typography, Divider } from 'antd'
const { Text } = Typography

import BillingPeriod from '../period'

import styles from './styles.module.less'

const UsageSection = ({ title, inventory, dataIngestion }) => {
  return (
    <>
      <Divider>{title}</Divider>
      <Row>
        <Col span={16}>
          <Text>Resources used</Text>
        </Col>
        <Col span={8}>
          <Text strong>{inventory ?? '-'}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Text>Data ingestion used</Text>
        </Col>
        <Col span={8}>
          <Text strong>{dataIngestion ? `${dataIngestion} GB` : '-'}</Text>
        </Col>
      </Row>
    </>
  )
}

const CurrentBillingPeriod = ({ period, onDemand, reserved, loading, className }) => {
  const reservedInventory = get(reserved, 'inventory.total') ? `${reserved.inventory.used} out of ${reserved.inventory.total}` : null
  const reservedDataIngestion = get(reserved, 'dataIngestion.total') ? `${reserved.dataIngestion.used} out of ${reserved.dataIngestion.total}` : null
  return (
    <Skeleton loading={loading}>
      <Card
        title='Current billing cycle'
        headStyle={{ border: 'none' }}
        bodyStyle={{ paddingTop: 0 }}
        className={classnames(styles.fullHeight, className)}
      >
        <BillingPeriod {...period} showTitle={false} centered className={styles.period} tooltip />
        <Row>
          <Col span={11}>
            <UsageSection
              title='On demand'
              inventory={onDemand.inventory}
              dataIngestion={onDemand.dataIngestion}
            />
          </Col>
          <Col offset={2} span={11}>
            <UsageSection
              title='Reserved'
              inventory={reservedInventory}
              dataIngestion={reservedDataIngestion}
              reserved
            />
          </Col>
        </Row>
      </Card>
    </Skeleton>
  )
}

export default CurrentBillingPeriod
