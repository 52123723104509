import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { Table, Select, Button } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import styles from './styles.module.less'

const { Option } = Select

const TableLayout = ({ columns, data, filterOptions, setDataFilter, setModalVisible, tableTitle, loading, locale, showAddButton, rowClickUrl }) => {
  const history = useHistory()

  const handleRowClick = (e, record) => {
    e.preventDefault()

    if (!rowClickUrl) return null

    history.push(rowClickUrl(record))
  }

  const onRow = (record) => ({ onClick: event => handleRowClick(event, record) })

  return (
    <section className={styles.container}>
      <div className={styles.top_bar}>
        {filterOptions && <div className={styles.status_select}>
          <p className={styles.select_label}>Status:</p>
          <Select defaultValue='all'
            onChange={value => setDataFilter(value)}
            className={styles.select}
            popupClassName={styles.dropdown}
            dropdownMatchSelectWidth={false}
          >
            <Option value='all'>All</Option>
            {filterOptions.map(option => <Option
              key={option.val}
              value={option.val}
            >
              {option.name}
            </Option>)}
          </Select>
        </div>}
        {showAddButton && (
          <PermissionsGate scopes={tableTitle === 'user' ? [SCOPES.canManageUserSettings] : [SCOPES.canCreateAccount]}>
            <Button
              type='primary'
              onClick={() => setModalVisible(true)}
            >
              Create {tableTitle}
            </Button>
          </PermissionsGate>)}
      </div>
      <PermissionsGate
        scopes={[SCOPES.canEditAccount]}
        errorProps={{
          onRow: () => { },
          rowClassName: record => classnames(styles.enabled, { [styles.disabled]: record?.status === 'disabled' })
        }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          onRow={onRow}
          rowClassName={record => classnames(styles.enabled, styles.clickable, { [styles.disabled]: record?.status === 'disabled' })}
          pagination={false}
          locale={locale}
        />
      </PermissionsGate>
    </section>
  )
}

export default TableLayout
