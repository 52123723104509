import React, { useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'

import { parseQueryString, stringifyQuery } from 'lib/hooks/use-query-params'
import { SplitViewContext } from 'features/layout/split-pane'
import { FilterIcon, ChartMixedIcon } from 'components/icons/font-awesome'
import IconButton from 'components/buttons/icon'
import { IconLinkButton } from 'components/buttons'
import ListSorters from '../sorter'
import ListFilters from '../filter'

import styles from './styles.module.less'

const ListHeader = ({
  sorters,
  onSorterChange,
  selectedSorter,
  left,
  overviewURL,
  total = 0,
  searchTotal = 0,
  children,
  handleFiltersClick,
  filters,
  extra,
  loadingFilters,
  view
}) => {
  const { search } = useLocation()
  const { handleListClose } = useContext(SplitViewContext)

  const [showFilter, setShowFilter] = useState(false)

  const showCount = searchTotal !== total
  const countTagWidth = !showCount ? 0 : (6.8 * `${total}${searchTotal}`.length + 9.3 + 6)

  const getLink = () => {
    const params = parseQueryString(search)
    const newValue = stringifyQuery({ ...params, selected: undefined })
    return { pathname: overviewURL || '', search: newValue }
  }

  return (
    <>
      <div className={styles.header}>
        {left || <div />}
        <div className={styles.buttons}>
          {overviewURL && <IconLinkButton to={() => getLink()} onClick={handleListClose} className={styles.overview_btn} tooltipText='Overview' icon={<ChartMixedIcon />} />}
          {filters && (
            <div className={styles.filter_btnWrapper}>
              {total > 0 &&
                <p
                  style={{ width: countTagWidth }}
                  className={classnames(styles.count_wrapper, !showCount && styles.hidden)}
                >
                  <span>{searchTotal} / {total}</span>
                </p>}
              <IconButton
                icon={<FilterIcon />}
                title='Filter'
                loading={loadingFilters}
                onClick={handleFiltersClick || (() => setShowFilter(!showFilter))}
                className={classnames(styles.filter_btn, showCount && styles.bg)}
              />
            </div>)}
          {sorters && (
            <ListSorters
              sorters={sorters.filter(item => item)}
              onSorterChange={onSorterChange} selected={selectedSorter}
              extraPadding={!!extra}
            />
          )}
          {extra}
        </div>
      </div>
      {(filters && showFilter) && <ListFilters filters={filters} view={view} />}
      {children}
    </>
  )
}

export default ListHeader
