import React from 'react'

import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { RDS_CPU } from '../../../metrics-tabs/services/rds-instance'
import InventoryTags from 'containers/inventory/shared/tags'
import InventoryResourceContainer from '../../../container'
import Events from 'containers/inventory/shared/events'
import Config from './config'
import ClusterInstances from './instances'
import Tabs from 'components/antd/tabs'
import { useResourcesQuery } from 'hooks/api'

const Configuration = ({ item }) => {
  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <Config attributes={item.attributes} inverse />
  </>)
}

const RDSCluster = ({ item }) => {
  const { data: instances, loading: loadingInstances } = useResourcesQuery({ parent: item.id })

  return (
    <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.RDSCluster.id} initialChart={RDS_CPU}>
      <Row gutter={12}>
        <Col xs={24} md={16}>
          <Section>
            <Tabs defaultActiveKey="instances" items={[
              { key: 'instances', label: 'Instances', children: <ClusterInstances instances={Object.values(instances || {})} loading={loadingInstances} attributes={item?.attributes} /> },
              { key: 'configuration', label: 'Configuration', children: <Configuration item={item} /> }
            ]} />
          </Section>
        </Col>
        <Col xs={24} md={8}>
          <Events resourceId={item.id} />
        </Col>
      </Row>
    </InventoryResourceContainer>
  )
}

export default RDSCluster
