import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { invitationPaths as paths, USER_INVITATIONS } from './index'
import { useUser } from 'hooks/context/user-context'
import { useOrganizationsQuery } from '../organizations/get-organizations'

const updateInvitation = async (invitationId, payload) => {
  const data = await apiFetch(paths.invitation(invitationId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useAcceptInvitationQuery = () => {
  const queryClient = useQueryClient()
  const { refreshCurrentSession, user } = useUser()
  const { refetch } = useOrganizationsQuery()

  return useMutation(
    ({ invitationId, payload }) => updateInvitation(invitationId, payload),
    {
      onSuccess: () => {
        refreshCurrentSession(refetch)
        queryClient.invalidateQueries([...USER_INVITATIONS, user?.attributes?.sub])
      }
    }
  )
}
