export const DELEGATIONS = 'delegations'
export const DELEGATIONS_LIST = [DELEGATIONS, 'list']
export const DELEGATION_ACTIVE = [DELEGATIONS, 'active']

export * from './get-delegations'
export * from './get-delegation'
export * from './update-delegation'
export * from './delete-delegation'
export * from './create-delegation'

export const delegationPaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  delegations: (account) => `${delegationPaths.account(account)}/delegations`,
  delegation: (account, identity) => `${delegationPaths.delegations(account)}/${identity}`,
  targets: (account, delegation, identity) => `${delegationPaths.delegation(account, delegation)}/targets/${identity}`
}
