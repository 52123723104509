import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { get, last, groupBy, sum, compact, reduce, flatten } from 'lodash'

import Usage from 'components/billing/stripe/usage'

import { GET_SUBSCRIPTIONS, GET_PRICES } from 'actions/billing.actions'
import getBillingPeriod from '../billing-cycle/period-from-subscription'
import { useOrgResourcesUsageQuery, useOrgIngestionQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

export const prepareUsageData = (inventory = [], dataIngestion = []) => {
  const resourcesByType = groupBy(inventory, 'type')
  const resourcesUsage = reduce(resourcesByType, (carry, regionsData, type) => {
    const countsFromRegions = (regionsData || []).map(regionData => get(regionData, 'mostRecent'))
    const totalCount = sum(compact(countsFromRegions))
    carry.push({ type, count: totalCount })
    return carry
  }, [])

  const dataIngestionBytes = dataIngestion.map(buckets => sum(buckets.map(bucket => get(bucket, 'sum_bytes.value', 0))))

  return {
    inventory: resourcesUsage,
    dataIngestion: sum(compact(dataIngestionBytes))
  }
}

const UsageSection = ({ estimatedUsage } = {}) => {
  const loadingSubscriptions = useSelector(state => state.waiting.list[GET_SUBSCRIPTIONS])
  const loadingPrices = useSelector(state => state.waiting.list[GET_PRICES])

  const { organization } = useOrganization()

  const subscriptions = useSelector(state => get(state, 'billing.subscriptions.list'))
  const prices = useSelector(state => get(state, 'billing.prices.list'))

  const onDemandSubscription = get(subscriptions, 'onDemand')
  const billingPeriod = getBillingPeriod(onDemandSubscription)
  const from = billingPeriod.start && billingPeriod.start.valueOf()
  const until = moment().valueOf()

  const { data: resourcesByAccount, isLoading: loadingResourceUsage } = useOrgResourcesUsageQuery({ start: from, end: until }, from)
  const { data: ingestionByAccount, isLoading: loadingIngestionUsage } = useOrgIngestionQuery({ start: from, end: until }, from)

  const loading = loadingResourceUsage || loadingIngestionUsage || loadingSubscriptions || loadingPrices

  const inventoryUsage = flatten(Object.values(resourcesByAccount || {}).map(
    accountResources => get(last(accountResources.data), 'records', []))
  )
  const ingestionUsage = flatten(Object.values(ingestionByAccount || {}).map(
    accountIngestion => compact(accountIngestion.data.map(entry => get(entry, 'split_by_region.buckets'))))
  )
  const usage = prepareUsageData(inventoryUsage, ingestionUsage)

  return (
    <Usage
      loading={loading}
      usage={usage}
      estimated={estimatedUsage}
      prices={get(prices, organization.tier)}
    />
  )
}

export default UsageSection
