import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { useOrganization } from 'hooks/context/organization-context'
import ManageSubscriptionModal from 'components/billing/stripe/manage-subscription-modal'

const Modal = ({ visible, setVisibility, estimatedUsage } = {}) => {
  const { organization } = useOrganization()
  const prices = useSelector(state => get(state, 'billing.prices.list'))

  return (
    <ManageSubscriptionModal
      visible={visible}
      setVisibility={setVisibility}
      tier={get(organization, 'tier')}
      prices={prices}
      estimatedUsage={estimatedUsage}
    />
  )
}

export default Modal
