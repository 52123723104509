import React from 'react'
import classnames from 'classnames'
import numeral from 'numeral'
import { flatten } from 'lodash'

import ListItem from 'features/side-list/item'
import SkeletonLine from 'components/skeleton-line'
import { AwsServiceIcon } from 'components/icons'
import { BugIcon, SigmaIcon, ClockIcon, PinIcon } from 'components/icons/font-awesome'
import { formatDuration } from 'components/charts/utils'
import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'

import styles from './styles.module.less'

const LambdaMetrics = React.memo(function LambdaMetrics ({ metrics, loadingMetrics }) {
  if (loadingMetrics) return <SkeletonLine size='xs' />

  if (!metrics) return null

  return (
    <>
      <span className={styles.metrics_icon}> <SigmaIcon /> {numeral(metrics?.invocations).format('0a') || 0}</span>
      <span className={classnames(styles.metrics_icon, metrics.errors > 0 && styles.bug)}><BugIcon /> {metrics.errors || 0}</span>
      <span className={styles.metrics_icon}><ClockIcon />  {formatDuration(metrics.duration) || 0}</span>
    </>
  )
})

const ApiGatewayMetrics = React.memo(function ApigatewayMetrics ({ attributes, loadingMetrics }) {
  if (loadingMetrics) return <SkeletonLine size='xs' />

  const endpoints = flatten(attributes.filter(attr => attr.name === 'endpoints').map(attr => JSON.parse(attr.value)))
  return (
    <span className={styles.metrics_icon}><PinIcon /> {endpoints?.length || 0} </span>
  )
})

const getMetrics = (serviceId, item, data) => {
  if (!serviceId || !item || !data) return ''

  switch (serviceId) {
    case INVENTORY_SERVICES.Lambda.id:
      return <LambdaMetrics metrics={{ errors: item.errors, invocations: item.invocations, duration: item.duration }} loadingMetrics={data.loadingMetrics} />
    case INVENTORY_SERVICES.AppSyncGraphQLApi.id:
      return <LambdaMetrics metrics={{ errors: item.errors, invocations: item.requests, duration: item.duration }} loadingMetrics={data.loadingMetrics} />
    case INVENTORY_SERVICES.APIGatewayRest.id:
      return <ApiGatewayMetrics attributes={item.attributes} loadingMetrics={data.loadingMetrics} />
    default:
      return ''
  }
}

const InventoryListItem = ({ data, index, style }) => {
  const item = data?.items[index]

  const service = findInventoryByType(item?.type)
  const delegation = item?.delegationName ? `| ${item.delegationName}` : ''
  const hasMetrics = service?.id === INVENTORY_SERVICES.Lambda.id ||
    service?.id === INVENTORY_SERVICES.APIGatewayRest.id ||
    service?.id === INVENTORY_SERVICES.AppSyncGraphQLApi.id

  return (
    <div style={style}>
      <ListItem
        key={item.id}
        icon={<AwsServiceIcon service={service?.service} />}
        title={item?.title}
        left={<span className={hasMetrics ? styles.list_details : ''}>{`${service?.shortTitle} | ${item.region.toUpperCase()} ${delegation}`}</span>}
        right={getMetrics(service.id, item, data)}
        onClick={() => data?.onSelect(item)}
        selected={item.id === data?.selected}
      />
    </div>
  )
}

export default InventoryListItem
