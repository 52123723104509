import React from 'react'
import { useParams } from 'react-router-dom'
import Numeral from 'numeral'

import { Bar, Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'
import { objectify } from 'lib/utils'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { useResourceQuery } from 'hooks/api'
import { OPENSEARCH_REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchRequestsChart = ({ loading, data, height, span }) => {
  const { resourceId } = useParams()
  const { data: resource } = useResourceQuery(resourceId)

  if (loading || !data) return null

  const engine = objectify(resource.attributes).engineVersion.toLowerCase()

  const metrics = transform({
    opensearch: data.OPENSEARCH_REQUESTS,
    elasticsearch: data.OPENSEARCH_ELASTIC_REQUESTS,
    '5xx': data.OPENSEARCH_5XX,
    '4xx': data.OPENSEARCH_4XX,
    '3xx': data.OPENSEARCH_3XX,
    '2xx': data.OPENSEARCH_2XX,
    invalid: data.OPENSEARCH_INVALID_HOST_REQUESTS
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  const formatted = metrics.map(metric => ({
    ...metric,
    [OPENSEARCH_REQUESTS]: engine.includes('elasticsearch') ? metric.elasticsearch : metric.opensearch
  }))

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0 a'
      span={span}
      tabKey={OPENSEARCH_REQUESTS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={OPENSEARCH_REQUESTS} name='Requests' fill={colors('chart', 'primaryLight')} barSize={4} />
      <Bar dataKey='2xx' name='2XX' stackId='a' fill={colors('chart', 'green')} barSize={4} />
      <Bar dataKey='3xx' name='3XX' stackId='a' fill={colors('chart', 'yellowLight')} barSize={4} />
      <Bar dataKey='4xx' name='4XX Errors' stackId='a' fill={colors('chart', 'redLight')} barSize={4} />
      <Bar dataKey='5xx' name='5XX Errors' stackId='a' fill={colors('chart', 'red')} barSize={4} />
      <Line dataKey='invalid' name='Invalid' stroke={colors('chart', 'primary')} lineWidth={1} dot={false} />
    </ChartContainer>
  )
}

export default OpensearchRequestsChart
