import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Form, Dropdown, Alert } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { useGetQueryQuery, useErrorMetricsQuery } from 'hooks/api'
import { useGlobalDatePicker } from 'hooks'
import SearchContext from 'hooks/context/search-context'
import useQueryParams from 'lib/hooks/use-query-params'
import { useAccountRoutes } from 'containers/routes'

import EditableTitle from 'features/editable-title'
import Content from 'components/layout/content'
import GlobalDatePicker from 'components/date-picker/global'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import LogMatchInvocations from './log-match-invocations'
import QueryForm from './query-form'
import ScheduledQueryChart from './chart'
import ScheduledSearchMetricsTabs from './metrics'
import TargetResources from './target-resources'
import { SearchMetricsContext } from '../index'
import { useMutations, useLogMatchesFetcher } from '../hooks'

import styles from './styles.module.less'

const ScheduledSearchDetails = () => {
  const { queryId } = useParams()
  const routes = useAccountRoutes()
  const [form] = Form.useForm()

  const { getValue: selected } = useQueryParams('selected', [])
  const { dashboardStart, dashboardEnd, dashboardRelativeSpan } = useContext(SearchMetricsContext)

  const {
    start,
    end,
    relativeSpan,
    handleSelectRange
  } = useGlobalDatePicker({ start: dashboardStart, end: dashboardEnd, relativeSpan: dashboardRelativeSpan })

  const { data: currentQuery, isLoading: loadingQuery } = useGetQueryQuery()

  const [metricPayload, setMetricPayload] = useState(null)
  const [filteredMetricPayload, setFilteredMetricPayload] = useState(null)
  const [selectedTab, setSelectedTab] = useState('events')
  const [filteredExecutions, setFilteredExecutions] = useState([])
  // const [filteredLogs, setFilteredLogs] = useState([])

  const handlers = useMutations({ query: currentQuery })

  const { data: logMatches, isFetching: loadingExecutions, isSuccess: executionsFetched } = useLogMatchesFetcher({ query: currentQuery, start, end })

  // const { data: logs, isLoading: searchFirstLoading, isFetching: fetchingLogs, isFetchingNextPage, fetchNextPage, hasNextPage } = useLogsFetcher({ query: currentQuery, start, end, relativeSpan })

  const { data: metricsData, isLoading: metricsLoading } = useErrorMetricsQuery(metricPayload, start, end)
  const { data: filteredMetricsData } = useErrorMetricsQuery(filteredMetricPayload, start, end)
  const totalMetricsCount = metricsData?.datapoints?.default?.reduce((acc, item) => (acc + item.sum), 0)

  const getInitialData = () => {
    if (!currentQuery) return
    setSelectedTab('events')

    setMetricPayload({
      key: 'scheduled-query',
      options: {
        dimensions: {
          hashes: [queryId]
        }
      }
    })
  }

  const getFilteredMetrics = () => {
    if (selected?.length === 0) return
    setFilteredMetricPayload({
      key: 'scheduled-query',
      options: {
        dimensions: {
          hashes: [queryId],
          ...(selected ? { resources: selected?.map(item => ({ id: item })) } : {})
        }
      }
    })
  }

  const filterExecutions = () => {
    if (currentQuery?.service !== 'lambda' || !executionsFetched) return

    if (selected?.length === 0) return setFilteredExecutions(logMatches)
    setFilteredExecutions(logMatches?.filter(item => selected.includes(item.resource)))
  }

  useEffect(getInitialData, [currentQuery?.id, start, end])
  useEffect(getFilteredMetrics, [selected])
  useEffect(filterExecutions, [selected, logMatches])

  return (
    <SearchContext form={form}>
      <Content item fixed
        title={
          <EditableTitle
            loading={loadingQuery}
            value={currentQuery?.name}
            displayContent={currentQuery?.name}
            onSubmit={handlers.update.name} />}
        fixedTitle={currentQuery?.name}
        breadcrumbs={['scheduled search']}
        loading={loadingQuery}
        backRoute={routes.scheduledSearch.url()}
        titleRowActions={
          <PermissionsGate scopes={[SCOPES.canEdit]}>
            <Dropdown
              trigger={['click']}
              overlayClassName={styles.configure_dropdown}
              menu={{ items: [{ key: 'delete', label: 'Delete' }], onClick: handlers.delete }}
            >
              <ActionsDropdownButton />
            </Dropdown>
          </PermissionsGate>}
        actions={<GlobalDatePicker
          start={start}
          end={end}
          onChange={handleSelectRange}
          relativeSpan={relativeSpan}
          refetchHidden />}
      >
        {currentQuery?.hasPartialResults &&
          <Alert
            message='Partial search query results'
            description='Your search query results exceed the maximum allowed limit. Please narrow down the search query in the "Configuration" tab and try to avoid very broad keywords without any restrictions.'
            type='info'
            showIcon
            className={styles.broad_query_alert}
          />
        }
        <ScheduledSearchMetricsTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          query={currentQuery}
          queryLoading={loadingQuery}
          count={totalMetricsCount}
          metricsLoading={metricsLoading}

        />
        {selectedTab === 'events' && <ScheduledQueryChart metrics={metricsData} filtered={selected?.length !== 0 ? filteredMetricsData : {}} loading={metricsLoading} />}
        {selectedTab === 'form' && <QueryForm query={currentQuery} service={currentQuery?.service} ref={form} />}
        <>
          <Row gutter={[8, 8]}>
            <Col span={currentQuery?.service === 'lambda' ? 12 : 0}>
              <LogMatchInvocations
                loading={loadingExecutions}
                invocations={filteredExecutions?.sort((a, b) => a.timestamp < b.timestamp)}
                query={currentQuery}
              />

              {/* Logs results for scheduled search
              <Section title='Logs' titleUpperCase loading={searchFirstLoading}>
               <LogsResults
                 logs={!currentQuery?.lastOccurrenceAt || new Date(currentQuery?.lastOccurrenceAt) < start ? [] : filteredLogs}
                 isFetchingNextPage={isFetchingNextPage}
                 fetchNextPage={fetchNextPage}
                 hasNextPage={hasNextPage}
               />
              </Section> */}
            </Col>
            <Col span={currentQuery?.service === 'lambda' ? 12 : 24}>
              <TargetResources
                // data={currentQuery?.service === 'lambda' ? logMatches : logs}
                data={logMatches}
                loadingExecutions={loadingExecutions}
                executionsFetched={executionsFetched}
              />
            </Col>
          </Row>
        </>
      </Content>
    </SearchContext>
  )
}

export default ScheduledSearchDetails
