import React, { useContext, useEffect, useRef, useState } from 'react'
import { format, isAfter, isBefore, startOfMinute } from 'date-fns'
import classnames from 'classnames'
import DatePicker from 'components/antd/datepicker'
import { CalendarIcon, LongArrowRightIcon, RefetchIcon } from 'components/icons/font-awesome'
import { ScrollContext } from 'components/layout/content'
import Tabs from 'components/antd/tabs'
import IconButton from 'components/buttons/icon'
import RelativeDatePicker, { RELATIVE_VALUES } from './relative'
import styles from './styles.module.less'
import { useResize } from 'hooks/resize'

const { RangePicker } = DatePicker

const GlobalDatePicker = (props) => {
  const {
    start,
    end,
    onChange,
    min,
    max,
    relativeSpan,
    fetching,
    handleManualRefetch,
    refetchHidden,
    relativeOptions = RELATIVE_VALUES
  } = props
  const { fixed } = useContext(ScrollContext)
  const datePickerRef = useRef()
  const [opened, setOpened] = useState(false)
  const [timeString, setTimeString] = useState([0, 0])
  const [activeTab, setActiveTab] = useState('relative')
  const { dimensions } = useResize()

  const getRelativeSuffix = (relativeSpan) => {
    if (relativeSpan > 24 * 60) {
      return 'days'
    } else if (relativeSpan >= 60) {
      return 'hours'
    } else if (relativeSpan > 1) {
      return 'minutes'
    }
  }

  const formatString = () => {
    if (relativeSpan) {
      setTimeString([`Last ${relativeOptions[relativeSpan]?.substring(0, relativeOptions[relativeSpan]?.length - 1)} ${getRelativeSuffix(relativeSpan)}`])
      return
    }
    const startFormat = format(start, 'MMM do, HH:mm')
    const endFormat = startOfMinute(end).valueOf() === startOfMinute(new Date()).valueOf() ? 'now' : format(end, 'MMM do , HH:mm')
    setTimeString([startFormat, endFormat])
  }

  const handleAbsoluteTime = (dates) => {
    if (!dates[0]) {
      return
    }
    const startNew = dates[0]
    const endNew = dates[1] || new Date()
    onChange(startNew, endNew)
    setOpened(false)
  }

  const dateDisabled = date => isBefore(date, min) && isAfter(date, max)

  useEffect(formatString, [start, end])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div className={styles.wrapper} ref={datePickerRef}>
      <div className={styles.row}>
        <button onClick={() => setOpened(!opened)} className={classnames(styles.date_btn, { [styles.fixed]: fixed })}>
          {timeString?.length === 2
            ? <span>
              {timeString[0]}
              <LongArrowRightIcon className={styles.arrow_icon} />
              {timeString[1]}
            </span>
            : timeString}
          <CalendarIcon className={classnames(styles.calendar_icon, { [styles.fixed]: fixed })} />
        </button>
        {relativeSpan && !refetchHidden && (
          <IconButton
            title='Refetch metrics'
            titlePlacement='bottomRight'
            className={styles.refetch}
            icon={<RefetchIcon spin={fetching} className={classnames(styles.refetch_icon, { [styles.fixed]: fixed })} />}
            onClick={handleManualRefetch} />)}
      </div>
      {opened && (
        <div className={classnames(styles.datepicker, {
          [styles.absolute]: activeTab === 'absolute'
        })} style={{
          maxWidth: dimensions.width - 18
        }}>
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            items={[
              {
                label: 'Relative',
                key: 'relative',
                children: (
                  <RelativeDatePicker {...props} formatString={formatString} setOpened={setOpened} />
                )
              },
              {
                label: 'Absolute',
                key: 'absolute',
                children: (
                  <RangePicker
                    open={opened && activeTab === 'absolute'}
                    showTime={{ format: 'HH:mm', showNow: true }}
                    disabledDate={date => dateDisabled(date)}
                    onCancel={() => setOpened(false)}
                    onOk={dates => handleAbsoluteTime(dates, 'Absolute')}
                    getPopupContainer={triggerNode => triggerNode?.parentNode}
                    defaultValue={!relativeSpan ? [start, end] : null}
                    allowEmpty={[false, true]}
                  />
                )
              }
            ]} />
        </div>
      )}
    </div>
  )
}

export default GlobalDatePicker
