import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { usageServicePaths as paths, ORG_RESOURCES_SUMMARY } from './index'
import { keyBy } from 'lodash'
import { sub, getTime } from 'date-fns'

const getOrganizationResourcesSummary = async (organization, { from, until }) => {
  const body = JSON.stringify({ from, until })
  const data = await apiFetch(paths.organizationResourcesSummary(organization), { method: 'POST', body })

  return keyBy(data, (accountUsage) => accountUsage.account.id)
}

export const useOrgResourcesUsageSummaryQuery = (payload) => {
  const { organization } = useOrganization()

  const from = payload?.start || getTime(sub(new Date(), { days: 30 }))
  const until = payload?.end || Date.now()

  return useQuery(
    [...ORG_RESOURCES_SUMMARY, organization?.id],
    () => getOrganizationResourcesSummary(organization?.id, ({ from, until })),
    {
      staleTime: Infinity,
      enabled: !!organization?.id
    }
  )
}
