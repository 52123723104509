import { useUpdateQueryQuery, useDeleteQueryQuery, useResourceGroupsQuery, useCreateQueryQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'
import { prefixTarget } from 'lib/targets-helpers'

const formatPayload = ({ query, values, queryRef, groups }) => {
  const payload = {
    service: values?.service || query?.service,
    severity: values?.severity,
    query: queryRef.current,
    name: query?.name || queryRef.current,
    targets: values?.targets?.map(identity => prefixTarget(identity, groups)),
    relativeTimespan: 15,
    frequency: query?.frequency || 30,
    ...(values?.service === 'opensearch' && { indices: ['INDEX_SLOW_LOGS', 'SEARCH_SLOW_LOGS', 'ES_APPLICATION_LOGS', 'AUDIT_LOGS'] })
  }

  return payload
}

const useMutations = ({ query }) => {
  const routes = useAccountRoutes()
  const { data: groups } = useResourceGroupsQuery()
  const { mutate: createQuery, isLoading: isCreating } = useCreateQueryQuery()
  const { mutate: updateQuery, isLoading: isUpdating } = useUpdateQueryQuery()
  const { mutateAsync: deleteQuery } = useDeleteQueryQuery()

  const handlers = {
    create: {
      query: (values, queryRef) => {
        const payload = formatPayload({ query, values, queryRef, groups })
        createQuery(payload)
      },
      loading: isCreating
    },
    update: {
      name: (value) => {
        const payload = {
          service: query?.service,
          targets: query.targets,
          query: query?.query,
          relativeTimespan: query?.relativeTimespan,
          severity: query?.severity,
          name: value
        }

        updateQuery({ payload, id: query.id })
      },
      query: (values, queryRef) => {
        const payload = formatPayload({ query, values, queryRef, groups })
        updateQuery({ payload, id: query.id })
      },
      loading: isUpdating
    },
    delete: () => {
      try {
        deleteQuery(query.id)
        history.push(routes.scheduledSearch.url())
      } catch { }
    }
  }

  return handlers
}

export default useMutations
