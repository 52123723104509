import React from 'react'
import { countBy } from 'lodash'
import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import SeverityItem from 'components/overview-group-item'
import { SEVERITY_TYPES } from 'lib/event-constants'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useErrorsQuery } from 'hooks/api'

import styles from './styles.module.less'

const SeverityGroups = () => {
  const { getValue: selected, setValue: onSelect } = useQueryParams('severity', [])

  const { data: errors, isLoading } = useErrorsQuery()

  const countBySeverity = countBy(errors, 'severity')

  const items = [
    { status: SEVERITY_TYPES.CRITICAL, count: countBySeverity[SEVERITY_TYPES.CRITICAL] },
    { status: SEVERITY_TYPES.WARNING, count: countBySeverity[SEVERITY_TYPES.WARNING] }
  ]

  return (
    <Section loading={isLoading} loadingHeight={1} cancelMargin>
      <Row gutter={[8, 8]} align='center' className={styles.group_wrapper}>
        {items.map(group => (
          <Col span={12} key={group.status} className={styles.group}>
            <SeverityItem {...group} id={group.status} key={group.status} selected={selected} onSelect={(item) => onSelect(filterHelper(item, selected))} />
          </Col>
        ))}
      </Row>
    </Section>
  )
}

export default SeverityGroups
