import { trim } from 'lodash'

const isProductionEnv = CONFIG.NODE_ENV === 'production'

const parsePathname = (path) => {
  if (!path) return {}
  const trimmedPath = trim(path, '/')
  if (!trimmedPath) return { name: 'main' }

  const splitPath = trimmedPath?.split('/')
  // find parts of path with only letters to build name
  return { name: splitPath.filter(p => p && (!/[^a-zA-Z]/.test(p))).join('/') || 'main' }
}

const page = (pathname) => {
  if (isProductionEnv) {
    const { name, properties } = parsePathname(pathname)
    window.analytics.page(name, properties)
  }
}

const trackLink = (link, event) => {
  if (isProductionEnv) {
    window.analytics.trackLink(link, event)
  }
}

const identify = (user, account, organization) => {
  if (isProductionEnv && user?.id) {
    window.analytics.identify(user?.id, {
      email: user?.email,
      ...(organization?.id && {
        company: {
          id: organization?.id,
          name: organization?.name,
          plan: organization?.tier
        }
      }),
      ...(account?.id && {
        accountId: account?.id,
        accountName: account?.name
      }),
      tier: organization?.tier,
      status: organization?.status,
      role: account?.role
    }, {
      Intercom: {
        user_hash: user?.intercomHash
      }
    })
  }
}

const reset = () => {
  if (isProductionEnv) {
    window.analytics.reset()
    window?.Intercom('shutdown')
  }
}

export default {
  page,
  trackLink,
  identify,
  reset
}
