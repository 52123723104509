import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { ORGANIZATIONS_ACTIVE, ORGANIZATIONS_LIST } from 'hooks/api'
import { organizationsPaths as paths } from '../index'
import { useUser } from 'hooks/context/user-context'
import { useOrganization } from 'hooks/context/organization-context'

const updateOrganization = (organizationId, payload) => {
  return apiFetch(
    paths.organization(organizationId),
    {
      method: 'PUT',
      body: JSON.stringify(payload)
    }
  )
}

export const useUpdateOrganizationQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()
  const { user } = useUser()

  return useMutation(
    (payload) => updateOrganization(organization.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...ORGANIZATIONS_LIST, user.attributes.email])
        queryClient.invalidateQueries([...ORGANIZATIONS_ACTIVE, organization.id])
      }
    }
  )
}
