import React from 'react'

import Lambda from './lambda'
import Appsync from './appsync/requests'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

const InvocationsList = props => {
  const { type } = props

  switch (type) {
    case INVENTORY_SERVICES.Lambda.id:
      return <Lambda {...props} />
    case INVENTORY_SERVICES.AppSyncGraphQLApi.id:
      return <Appsync {...props} />
    default:
      return <div>Not implemented</div>
  }
}

export default InvocationsList
