import React from 'react'
import numeral from 'numeral'

import { Line, Area } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SQS_DELAY } from 'containers/inventory/details/resource/metrics-tabs/services/sqs'

import colors from 'lib/colors'

const DelayChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    age: data.SQS_MESSAGES_AGE
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [SQS_DELAY]: metric.age_avg,
      diff: [metric.age_min, metric.age_max]
    }
  })

  const format = value => numeral(value).format('00:00:00')

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') {
      return [`${format(value[0])} - ${format(value[1])}`, undefined]
    }
    return [format(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='00:00:00'
      span={span}
      tabKey={SQS_DELAY}
      formatTab={format}
      formatTooltip={valueFormatter}
      noMargin
    >
      <Line name='Average' dataKey={SQS_DELAY} type='monotone' false stroke={colors('chart', 'primaryDark')} strokeWidth={1} dot={false} />
      <Area name='Min - max' dataKey='diff' type='monotone' dot={false} fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} />
    </ChartContainer>
  )
}

export default DelayChart
