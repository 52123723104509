import { sum } from 'lodash'
import React, { useContext } from 'react'
import { Area } from 'recharts'

import { ServiceContext } from '../../index'
import transform from '../transform'
import { CHART_COLORS } from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { S3_BUCKET_SIZE, SIZE_METRIC_KEYS, formatBucketSize } from 'containers/inventory/details/resource/metrics-tabs/services/s3'

const chartConfig = {
  S3_BUCKET_SIZE_STANDARD: { name: 'Standard', color: CHART_COLORS.primaryDark },
  S3_BUCKET_SIZE_STANDARDIA: { name: 'Standard (IA)', color: CHART_COLORS.primary },
  S3_BUCKET_SIZE_ONEZONEIA: { name: 'One Zone (IA)', color: CHART_COLORS.yellow },
  S3_BUCKET_SIZE_GLACIER: { name: 'Glacier', color: '#28c6e6' },
  S3_BUCKET_SIZE_DEEPARCHIVE: { name: 'Glacier (DA)', color: '#48b1c7' }
}

const sizeMetrics = SIZE_METRIC_KEYS.map((key) => {
  const config = chartConfig[key]
  if (!config) {
    throw new Error(`Missing S3 bucket size chart configuration for ${key}`)
  }
  return { key, ...config }
})

const hasAnyDatapoints = (data) => ({ key }) => {
  return data?.[key]?.datapoints?.default?.length
}
const tooltipFormatter = (value, name, props) => {
  return [formatBucketSize(value), undefined]
}

const S3BucketSizeChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const { relativeSpan, range } = useContext(ServiceContext)

  // filter out metrics for storage classes that are 0-sized for the whole
  // period of the chart to reduce clutter.
  const displayedMetrics = sizeMetrics
    .filter(hasAnyDatapoints(data))

  const metrics = transform(
    Object.fromEntries(
      displayedMetrics.map(({ key }) => [key, data[key]])
    )
  ).map(({ date, ...values }) => {
    const SUM = sum(Object.values(values))
    return {
      date,
      SUM,
      ...values
    }
  })

  if (!metrics.length) {
    if (relativeSpan && range <= 1440) {
      return <Empty height={height} message="S3 metrics are published daily. Increase the relative time window to see yesterday's data."/>
    }
    return <Empty height={height} />
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={S3_BUCKET_SIZE}
      formatYTicks={formatBucketSize}
      formatTab={(value, event) => {
        return formatBucketSize(event?.activePayload?.[0]?.payload?.SUM)
      }}
      formatTooltip={tooltipFormatter}
    >
      {displayedMetrics.map(({ key, name, color }) => (
        <Area key={key} stackId="1" type='monotone' dataKey={key} fill={color} stroke={color} name={name} />
      ))}
    </ChartContainer>
  )
}

export default S3BucketSizeChart
