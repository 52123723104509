import React from 'react'
import colors from 'lib/colors'

const RefLine = ({ isTooltipActive, activeCoordinate, primaryRefLine, showLegend }) => {
  if (!activeCoordinate || !isTooltipActive) return null

  const strokeColor = colors('chart', primaryRefLine ? 'primary' : 'primaryLight')
  const startY = showLegend ? 50 : 15
  const height = 1024

  return (
    <>
      <line
        x1={activeCoordinate.x}
        x2={activeCoordinate.x}
        y1={startY}
        y2={startY + height}
        stroke={strokeColor}
      />
      <circle
        r='5'
        cx={activeCoordinate.x}
        cy={startY + 5}
        fill={strokeColor}
      />
    </>
  )
}

export default RefLine
