import Numeral from 'numeral'
import colors from 'lib/colors'

export const chartMargin = {
  top: 24,
  left: -4,
  bottom: 8,
  right: 8
}

const wrapper = { border: 'none', padding: '6px', background: 'transparent', display: 'flex' }
const wr = { width: '100%', height: '28px' }
const tooltipitem = { color: 'black', display: 'inline-block', margin: '0', padding: '0', minWidth: '120px', fontSize: '16px' }
const tooltipLabel = { color: colors('chart', 'text'), display: 'inline-block', margin: '0', padding: '0', minWidth: '120px', fontSize: '16px' }

export const defaultBarSize = 4
export const defaultLineWidth = 1
export const xAxisProps = { tickLine: false, stroke: colors('chart', 'grid'), tickSize: 4, interval: 0, dataKey: 'date' }
export const yAxisProps = { tick: { fill: colors('chart', 'text'), fontSize: 12 }, stroke: colors('chart', 'grid'), axisLine: false, tickSize: 4 }
export const chartHeight = 320
export const tooltipProps = { wrapperStyle: wr, contentStyle: wrapper, itemStyle: tooltipitem, labelStyle: tooltipLabel, position: { x: 0, y: 20 }, cursor: false }
export const refDotProps = { r: 5, stroke: colors('chart', 'primary'), fill: colors('chart', 'primary') }

export const formatDuration = (ageInMs) => {
  const seconds = (ageInMs / 1000)
  const minutes = seconds / 60
  const hours = minutes / 60

  if (ageInMs <= 1000) return `${Numeral(ageInMs).format('0.[00]')}ms`
  else if (seconds < 60) return `${Numeral(seconds).format(0)}s`
  else if (minutes < 60) return `${Numeral(minutes).format(0)}min`
  return `${Numeral(hours).format(0)}h`
}

// Perhaps formatters below might be refactored into one configurable formatter

export const formatBytes = ({ value, format = '0.[00]', unit = 'b' }) => {
  return Numeral(value).format(`${format} ${unit}`)
}

export const formatCost = ({ value, unit = '$' }) => {
  return `${Numeral(value).format('0.00')}${unit}`
}

export const formatPercentage = ({ value, unit = '%' }) => {
  return `${Numeral(value).format('0.[00]')}${unit}`
}

export const formatThousands = ({ value, format = '0.[00]a' }) => {
  return Numeral(value).format(format)
}
