import ApiGateway from './apigateway'
import AppSync from './appsync'
import AppSyncResolver from './appsync-resolver'
import DynamoDB from './dynamodb'
import ECSCluster from './ecs-cluster'
import ECSService from './ecs-service'
import ELBApplicationLoadBalancer from './elb-application-load-balancer'
import ELBGatewayLoadBalancer from './elb-gateway-load-balancer'
import ELBNetworkLoadBalancer from './elb-network-load-balancer'
import ELBTargetGroup from './elb-target-group'
import EventBridgeEventBus from './eventbridge-eventbus'
import EventBridgeRule from './eventbridge-rule'
import KinesisAnalytics from './kinesis-analytics'
import KinesisDataStream from './kinesis-datastream'
import KinesisFirehose from './kinesis-firehose'
import Lambda from './lambda'
import OpenSearch from './opensearch'
import RDSCluster from './rds-cluster'
import RDSInstance from './rds-instance'
import RDSProxy from './rds-proxy'
import S3 from './s3'
import SNS from './sns'
import SQS from './sqs'
import StepFunctions from './stepfunctions'

const TotalMetrics = {
  APIGatewayHttp: ApiGateway,
  APIGatewayRest: ApiGateway,
  AppSyncAPI: AppSync,
  AppSyncResolver,
  DynamoDB,
  ECSCluster,
  ECSService,
  ELBApplicationLoadBalancer,
  ELBGatewayLoadBalancer,
  ELBNetworkLoadBalancer,
  ELBTargetGroup,
  EventBridgeEventBus,
  EventBridgeRule,
  KinesisAnalytics,
  KinesisDataStream,
  KinesisFirehose,
  Lambda,
  OpenSearch,
  RDSCluster,
  RDSInstance,
  RDSProxy,
  S3,
  SNS,
  SQS,
  StepFunctions
}

export default TotalMetrics
