import React from 'react'
import Numeral from 'numeral'
import { Area } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { DATA } from 'containers/inventory/details/resource/metrics-tabs/services/elb-application-load-balancer'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [DATA]: data.ELB_LOADBALANCER_PROCESSED_BYTES
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.00b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0b'
      span={span}
      tabKey={DATA}
      formatTab={value => `${Numeral(value).format('0.00b')}`}
      formatTooltip={tooltipFormatter}
    >
      <Area type='monotone' dataKey={DATA} fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Processed data' />
    </ChartContainer>
  )
}

export default ELBRequestsChart
