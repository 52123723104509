import React from 'react'
import Numeral from 'numeral'

import { Line, Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { STEPFUNCTIONS_EXECUTION } from 'containers/inventory/details/resource/metrics-tabs/services/stepfunctions'

const StepFunctionsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [STEPFUNCTIONS_EXECUTION]: data.STEPFUNCTIONS_EXECUTIONS_STARTED,
    succeeded: data.STEPFUNCTIONS_EXECUTIONS_SUCCEEDED,
    failed: data.STEPFUNCTIONS_EXECUTIONS_FAILED,
    timeouts: data.STEPFUNCTIONS_EXECUTIONS_TIMEOUTS,
    throttles: data.STEPFUNCTIONS_EXECUTIONS_THROTTLES,
    aborted: data.STEPFUNCTIONS_EXECUTIONS_ABORTED
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => [Numeral(value).format('0.[000]a'), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[00]'
      span={span}
      tabKey={STEPFUNCTIONS_EXECUTION}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={STEPFUNCTIONS_EXECUTION} stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Executions' dot={false} />
      <Bar dataKey='succeeded' stackId='a' fill={colors('chart', 'primary')} maxBarSize={4} name='Succeeded' />
      <Bar dataKey='failed' stackId='a' fill={colors('chart', 'red')} maxBarSize={4} name='Failed' />
      <Bar dataKey='timeouts' stackId='a' fill={colors('chart', 'red')} maxBarSize={4} name='Timeouts' />
      <Bar dataKey='throttles' stackId='a' fill={colors('chart', 'red')} maxBarSize={4} name='Throttles' />
      <Bar dataKey='aborted' stackId='a' fill={colors('chart', 'red')} maxBarSize={4} name='Aborted' />
    </ChartContainer>
  )
}

export default StepFunctionsChart
