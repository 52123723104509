import React, { useContext } from 'react'
import Numeral from 'numeral'
import { isEmpty } from 'lodash'
import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { ECSContext } from '../../services/ecs/service'
import { ECS_MEMORY } from 'containers/inventory/details/resource/metrics-tabs/services/ecs-cluster'

const MemoryChart = ({ loading, data, height, span }) => {
  const { memoryLimit } = useContext(ECSContext)
  if (loading || !data) return null

  const metrics = transform({
    mem: data.ECS_MEM_UTILIZATION,
    reservation: data.ECS_MEM_RESERVATION
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') {
      return [`${Numeral(value[0]).format('0.[0]')}% - ${Numeral(value[1]).format('0.[0]')}%`, undefined]
    }
    return [`${Numeral(value).format('0.[0]')}%`, undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [ECS_MEMORY]: metric.mem_avg,
      reservation: metric.reservation,
      diff: [metric.mem_min, metric.mem_max]
    }
  })

  const showDiff = !isEmpty(formatted[0]?.diff)

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0]'
      span={span}
      tabKey={ECS_MEMORY}
      formatTab={value => `${Numeral(value).format('0.[0]')}%`}
      formatTooltip={valueFormatter}
      percentageChart
    >
      {data.ECS_MEM_UTILIZATION && <Line dataKey={ECS_MEMORY} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />}
      {data.ECS_MEM_RESERVATION && memoryLimit && <Line dataKey='reservation' type='stepAfter' stroke={colors('chart', 'red')} strokeWidth={1} name='Hard Limit' dot={false} />}
      {showDiff && <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} name='Min - max' />}
    </ChartContainer>
  )
}

export default MemoryChart
