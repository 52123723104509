import React, { useState } from 'react'
import { useRouteMatch, Route, useHistory, useLocation, useParams, Link } from 'react-router-dom'
import { Button } from 'antd'

import Section from 'components/layout/content/section'
import Empty from 'components/layout/content/empty'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import SearchResult from '../item'
import InvocationDetails from 'features/invocations/details'
import OnDemandResults from 'features/on-demand-search/results'
import Drawer from 'components/layout/drawer'

import { useAccountRoutes } from 'containers/routes'
import { useAllResourcesQuery } from 'hooks/api'

import styles from './styles.module.less'

const GroupedByInvocation = ({ results, resources, selected, setSelected, routes, search, queryId }) => {
  const handleSelect = (payload, disabled) => {
    if (disabled) return
    setSelected(payload)
  }

  return (
    results.map((searchResult, idx) => {
      const resource = resources[searchResult.resource || searchResult?.logs?.[0].resource]
      const invocationNotFound = !searchResult?.eventId && resource?.type === INVENTORY_SERVICES.Lambda.type
      return (
        <div
          className={styles.results_wrapper}
          key={idx}
          onClick={() => handleSelect({ ...searchResult, resource }, invocationNotFound)}
        >
          <SearchResult
            selected={selected?.requestId}
            item={searchResult}
            resource={resource || {}}
            url={!invocationNotFound && { pathname: routes.search.request.url({ queryId, requestId: searchResult.requestId }), search }}
          />
        </div>
      )
    })
  )
}

const SearchResults = ({ results, loading, nextBtnHidden, isFetchingNextPage, fetchNextPage, service, error, empty }) => {
  const match = useRouteMatch()
  const { queryId } = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const routes = useAccountRoutes()
  const invocationPath = `${match.path}/requests/:requestId`

  const { data: resources, isLoading: resourcesLoading } = useAllResourcesQuery()

  const [selectedExecution, setSelectedExecution] = useState()

  const onDrawerClose = () => {
    // react router thinks that 'requests' is queryId and that's why we eliminate it here....
    const pathname = queryId && queryId !== 'requests' ? routes.search.query.url({ queryId }) : routes.search.url()
    history.push({ pathname, search })
    setSelectedExecution()
  }

  if (empty || error) {
    return (
      <Empty title={!error ? 'No results found' : 'Error occurred with fetching your results'}>
        {error && <>{'Check if logs are enabled in cloudwatch or try to narrow down search query. Also make sure your AWS delegations are up to date under '}
          <Link to={routes.settings.integrations.url()}>Intergations settings</Link></>}
      </Empty>)
  }

  return (
    <Section loading={loading || resourcesLoading}>
      {service === 'lambda'
        ? (
          <>
            <GroupedByInvocation
              results={results}
              resources={resources}
              selected={selectedExecution}
              setSelected={setSelectedExecution}
              routes={routes}
              queryId={queryId}
              search={search} />
            <div className={styles.center}>
              <Button
                type='inverted'
                size='small'
                loading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
                hidden={nextBtnHidden}>
                Show more results...
              </Button>
            </div>
          </>)
        : (
          <OnDemandResults logs={results}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!nextBtnHidden}
          />)}
      <Route path={invocationPath}>
        <Drawer
          open
          onClose={onDrawerClose}
          mask={false}
          closable={false}
          size='large'>
          <InvocationDetails
            type={INVENTORY_SERVICES.Lambda.id}
            resource={selectedExecution?.resource}
            onClose={onDrawerClose}
            hash={selectedExecution?.eventId}
          />
        </Drawer>
      </Route>
    </Section>
  )
}

export default SearchResults
