import { useQuery } from 'react-query'
import queryString from 'query-string'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch, omitEmpty } from 'lib/action-helpers'

import { paths } from '../index'
import { eventsStoragePaths, INVOCATION_TRACES, REQUEST_TRACES } from './index'

export const getTraces = async (path, resourceId) => {
  const query = queryString.stringify(omitEmpty({ resource: resourceId }))

  const data = await apiFetch(paths.filter(path, query))
  return data
}

export const useInvocationTracesQuery = (hash, resourceId, isActive) => {
  const { account } = useOrganization()

  return useQuery(
    [...INVOCATION_TRACES, hash, account?.id],
    () => getTraces(eventsStoragePaths.traces(account?.id, hash), resourceId),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!hash && !!resourceId && !!isActive,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}

export const useRequestTracesQuery = (hash, resourceId, hasTraceId) => {
  const { account } = useOrganization()
  return useQuery(
    [...REQUEST_TRACES, hash, account?.id],
    () => getTraces(eventsStoragePaths.requestTraces(account?.id, hash), resourceId),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!hash && !!resourceId && !!hasTraceId,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
