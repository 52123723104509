import numeral from 'numeral'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faBullseyeArrow, faFileLines } from '@fortawesome/pro-light-svg-icons'

import { getSparklineByName, getSum } from '../helpers'

export const EVENTBRIDGE_RULE_INVOCATIONS = 'eventbridge-rule-invocations'
export const EVENTBRIDGE_RULE_TRIGGERED_RULES = 'eventbridge-rule-triggered-rules'

const metrics = [{
  key: EVENTBRIDGE_RULE_TRIGGERED_RULES,
  icon: faFileLines,
  title: 'Rules',
  filter: (collection) => {
    const sum = getSum(collection, 'EVENTBRIDGE_RULE_TRIGGERED_RULES')

    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'EVENTBRIDGE_RULE_TRIGGERED_RULES'),
  selectable: true
}, {
  key: EVENTBRIDGE_RULE_INVOCATIONS,
  icon: faSigma,
  title: 'Invocations',
  filter: (collection) => {
    const sum = getSum(collection, 'EVENTBRIDGE_RULE_INVOCATIONS')

    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'EVENTBRIDGE_RULE_INVOCATIONS'),
  selectable: true
}, {
  key: 'targets',
  icon: faBullseyeArrow,
  title: 'Targets',
  filter: (c, i, metadata) => {
    return JSON.parse(i.attributes.find(attr => attr.name === 'targets')?.value || '[]')?.length || 0
  }
}]

export default metrics
