import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { QUERIES_LIST, queryPaths as paths } from './index'
import { useAccountRoutes } from 'containers/routes'
import { useHistory } from 'react-router-dom'

const createQuery = async (account, payload) => {
  return await apiFetch(paths.queries(account), { method: 'POST', body: JSON.stringify(payload) })
}

export const useCreateQueryQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()
  const history = useHistory()
  const routes = useAccountRoutes()

  return useMutation(
    (payload) => createQuery(account.id, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...QUERIES_LIST, account.id])
        const path = !data?.frequency ? routes.search.query.url({ queryId: data.id }) : routes.scheduledSearch.query.url({ queryId: data.id })
        history.push(path)
      }
    }
  )
}
