import React from 'react'
import classnames from 'classnames'
import { Radio as AntdRadio } from 'antd'

import styles from './styles.module.less'

const RadioSmall = ({ items, className, ...props }) => {
  return (
    <AntdRadio.Group {...props} className={classnames(styles.radio, className)}>
      {items.map(item => (
        <AntdRadio.Button
          key={item.value}
          {...item}
          className={classnames({ [styles[item.severity]]: !!item.severity })}>
          {item.label}
        </AntdRadio.Button>
      ))}
    </AntdRadio.Group>
  )
}

export default RadioSmall
