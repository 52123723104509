import { paths } from '../index'

export const REQUESTS = 'requests'
export const REQUESTS_LIST = [REQUESTS, 'list']
export const REQUEST_ACTIVE = [REQUESTS, 'active']
export const REQUEST_TRACES = [REQUESTS, 'traces']
export const RESOLVERS = 'resolvers'
export const RESOLVERS_LIST = [RESOLVERS, 'list']
export const INVOCATIONS = 'invocations'
export const INVOCATION_ACTIVE = [INVOCATIONS, 'active']
export const INVOCATION_LOGS = [INVOCATIONS, 'logs']
export const INVOCATION_TRACES = [INVOCATIONS, 'traces']
export const INVOCATION_RETRIES = [INVOCATIONS, 'retries']

export const eventsStoragePaths = {
  eventsStorage: (account) => `${paths.account(account)}/events-storage`,
  events: (account) => `${eventsStoragePaths.eventsStorage(account)}/events`,
  event: (account, identity) => `${eventsStoragePaths.events(account)}/${identity}`,
  logs: (account, identity) => `${eventsStoragePaths.event(account, identity)}/logs`,
  traces: (account, identity) => `${eventsStoragePaths.event(account, identity)}/traces`,
  retries: (account, identity) => `${eventsStoragePaths.event(account, identity)}/retries`,
  requests: (account) => `${eventsStoragePaths.eventsStorage(account)}/appsync/requests`,
  resolvers: (account) => `${eventsStoragePaths.eventsStorage(account)}/appsync/resolvers`,
  request: (account, identity) => `${eventsStoragePaths.requests(account)}/${identity}`,
  requestTraces: (account, identity) => `${eventsStoragePaths.request(account, identity)}/traces`
}

export * from './get-request'
export * from './get-requests'
export * from './get-resolvers'
export * from './get-invocation'
export * from './get-logs'
export * from './get-traces'
export * from './get-retries'
