/**
 * @property {Object} node
 */
export class TracesError extends Error {
  /**
   * @param {String} message
   * @param {Object} node
   */
  constructor (message, node) {
    super(`${message} ${JSON.stringify({
      name: node.name,
      origin: node.origin
    }, null, 2)}`)

    this.name = 'TracesError'
    this.node = node
  }
}

const throwAsyncError = async (message, node) => {
  throw new TracesError(message, node)
}

export { throwAsyncError }
