import React from 'react'

import Content from 'components/layout/content'
import Usage from './content'

const UsageContainer = () => {
  return (
    <Content item title={'Usage report'} breadcrumbs={['settings', 'usage report']}>
      <Usage />
    </Content>
  )
}

export default UsageContainer
