import React from 'react'
import { isEmpty } from 'lodash'

import styles from './styles.module.less'

const Query = ({ loading, item }) => {
  if (loading || !item || isEmpty(item)) return null

  return <pre className={styles.pre}>{item?.query}</pre>
}

export default Query
