import { useEffect, useMemo, useState } from 'react'
import { useSearchExecutionsBatchQuery } from 'hooks/api'

import { WIDGET_KIND } from 'lib/dashboards-constants'
import { getInvocationsKey } from 'lib/dashboards-helpers'

const getPayloads = (widgets, start, end) => {
  if (!widgets) return []

  const invocationsWidgets = widgets?.filter(widget => widget.kind === WIDGET_KIND.INVOCATIONS)

  const payloads = invocationsWidgets.map(widget => {
    const payload = {
      targets: widget.definition.targets,
      service: widget.definition.service,
      ...(widget.definition.flag && { filters: { flag: widget.definition.flag }}),
      start,
      end,
      limit: 100
    }

    return { id: getInvocationsKey(widget), payload }
  })

  return payloads
}

const useInvocationsFetcher = ({ widgets, start, end, refreshInterval }) => {
  const [invocations, setInvocations] = useState([])

  const payloads = useMemo(() => getPayloads(widgets, start, end), [widgets, start, end])
  const data = useSearchExecutionsBatchQuery(payloads, refreshInterval)

  const loading = useMemo(() => data.some(item => item.isLoading), [data])
  const fetching = useMemo(() => data.some(item => item.isFetching), [data])

  const refetch = (e) => {
    e?.stopPropagation()
    data.map(item => item?.refetch())
  }

  const formatData = () => {
    if (loading || fetching) return

    const mappedInvocations = data?.reduce((acc, item) => {
      acc[item?.data?.id] = item.data
      return acc
    }, {})

    setInvocations(mappedInvocations)
  }

  useEffect(formatData, [payloads, loading, fetching])

  return { data: invocations, loading, fetching, refetch }
}

export default useInvocationsFetcher
