import React from 'react'
import PrettyPrinter from 'components/json-pretty'
import Empty from 'components/layout/content/empty'
import styles from './styles.module.less'

const Metadata = ({ data }) => {
  if (!data.metadata) return <Empty title='No metadata found' />
  return (
    <div className={styles.scroll_wrapper}>
      <PrettyPrinter
        jsonString={data.metadata}
        collapsed={false}
      />
    </div>
  )
}

export default Metadata
