import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.less'

const LoadingDots = () => {
  return (
    <div className={styles.loading_wrapper}>
      <div className={classnames(styles.loading_dot, styles.dot1)} />
      <div className={classnames(styles.loading_dot, styles.dot2)} />
      <div className={classnames(styles.loading_dot, styles.dot3)} />
      <div className={classnames(styles.loading_dot, styles.dot4)} />
      <div className={classnames(styles.loading_dot, styles.dot5)} />
    </div>
  )
}

export default LoadingDots
