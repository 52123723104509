import React, { useEffect, useRef } from 'react'
import { Form } from 'antd'
import Input from 'components/antd/input'

const Email = ({ disabled, initialValue, onPressEnter }) => {
  const emailRef = useRef()

  useEffect(() => {
    if (!emailRef?.current) return
    emailRef.current?.focus()
  }, [emailRef?.current])

  return <Form.Item
    name='email'
    label='Email'
    initialValue={initialValue}
    rules={[
      { required: true, message: 'Please submit your email' },
      { type: 'email', message: 'Please submit a valid email' }
    ]}>
    <Input type='email' disabled={disabled} onPressEnter={onPressEnter} solid ref={emailRef} />
  </Form.Item>
}

export default Email
