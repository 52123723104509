import queryString from 'query-string'
import { apiFetch, getDispatch, waitingState, omitEmpty } from 'lib/action-helpers'

const paths = {
  filter: (path, query) => `${path}?${query}`,
  organization: (idenitity) => `/v1/organizations/${idenitity}`,
  billing: (organization) => `${paths.organization(organization)}/billing`,
  customers: (organization) => `${paths.billing(organization)}/customers`,
  customer: (organization) => `${paths.billing(organization)}/customer`,
  payment: (organization) => `${paths.customer(organization)}/payment`,
  paymentMethods: (organization) => `${paths.payment(organization)}/methods`,
  paymentMethod: (organization, paymentMethodId) => `${paths.paymentMethods(organization)}/${paymentMethodId}`,
  paymentMethodPrimary: (organization, paymentMethodId) => `${paths.paymentMethod(organization, paymentMethodId)}/primary`,
  paymentSetups: (organization) => `${paths.payment(organization)}/setup`,
  paymentSetup: (organization, idenitity) => `${paths.payment(organization)}/setup/${idenitity}`,
  validateVAT: (organization) => `${paths.billing(organization)}/validate/vat/`,
  invoices: (organization) => `${paths.billing(organization)}/invoices`,
  invoicePreview: (organization) => `${paths.customer(organization)}/invoice/reserved/preview`,
  subscriptions: (organization) => `${paths.customer(organization)}/subscriptions`,
  subscription: (organization, subscription) => `${paths.subscriptions(organization)}/${subscription}`,
  subscriptionItems: (organization, subscription) => `${paths.subscription(organization, subscription)}/items`,
  subscriptionItem: (organization, subscription, item) => `${paths.subscriptionItems(organization, subscription)}/${item}`,
  subscriptionInvoice: (organization, subscription) => `${paths.subscription(organization, subscription)}/invoice`,
  prices: (organization) => `${paths.billing(organization)}/prices`,
  promotion: (organization, promotion) => `${paths.billing(organization)}/discounts/promotions/${promotion}`,
  marketplace: (organization, payload) => `${paths.billing(organization)}/marketplace`
}

export const api = (organization) => {
  return {
    customers: {
      post: (payload) => createCustomer(organization, payload),
      active: () => {
        return {
          get: () => getCustomer(organization),
          put: (payload) => updateCustomer(organization, payload)
        }
      }
    },
    payment: {
      methods: {
        get: () => getPaymentMethods(organization),
        post: (payload) => createPaymentMethod(organization, payload),
        id: (paymentMethod) => {
          return {
            delete: () => deletePaymentMethod(organization, paymentMethod),
            setPrimary: () => setPrimaryPaymentMethod(organization, paymentMethod)
          }
        }
      },
      setup: {
        post: () => createPaymentMethodSetup(organization),
        id: (intent) => {
          return {
            delete: () => cancelPaymentMethodSetup(organization, intent)
          }
        }
      }
    },
    VAT: {
      validate: (payload) => validateVAT(organization, payload)
    },
    invoices: {
      get: (filters) => getInvoices(organization, filters),
      preview: {
        get: (filters) => getInvoicePreview(organization, filters)
      }
    },
    prices: {
      get: () => getPrices(organization)
    },
    subscriptions: {
      get: () => getSubscriptions(organization),
      post: (payload) => createSubscription(organization, payload),
      id: (subscription) => {
        return {
          put: (payload) => updateSubscription(organization, subscription, payload),
          delete: () => deleteSubscription(organization, subscription),
          invoice: {
            get: (type) => getSubscriptionInvoice(organization, subscription, type)
          },
          items: {
            id: (item) => {
              return {
                delete: (type) => cancelSubscriptionItem(organization, subscription, item, type)
              }
            }
          }
        }
      }
    },
    promotions: {
      id: (promotion) => {
        return {
          get: () => getPromotion(organization, promotion)
        }
      }
    },
    marketplace: {
      post: (payload) => postMarketplaceAttachment(organization, payload)
    }
  }
}

export const POST_CUSTOMER = 'POST_CUSTOMER'
export const POST_CUSTOMER_ERROR = 'POST_CUSTOMER_ERROR'
export const createCustomer = (organization, { name } = {}) => {
  return getDispatch(
    apiFetch(paths.customer(organization), { method: 'POST', body: JSON.stringify({ name }) })
  )
}

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR'
export const getCustomer = (organization) => {
  return getDispatch(
    apiFetch(paths.customer(organization)),
    GET_CUSTOMER,
    GET_CUSTOMER_ERROR
  )
}

export const PUT_CUSTOMER = 'PUT_CUSTOMER'
export const PUT_CUSTOMER_ERROR = 'PUT_CUSTOMER_ERROR'
export const updateCustomer = (organization, payload) => {
  const { email, name, vat, country, city, street, state, zip } = payload
  const address = { country, city, street, state, zip }
  const body = JSON.stringify({ email, name, vat, address })
  return getDispatch(
    apiFetch(paths.customer(organization), { method: 'PUT', body }),
    PUT_CUSTOMER,
    PUT_CUSTOMER_ERROR
  )
}

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR'
export const getPaymentMethods = (organization) => {
  return getDispatch(
    apiFetch(paths.paymentMethods(organization)),
    GET_PAYMENT_METHODS,
    GET_PAYMENT_METHODS_ERROR
  )
}

export const POST_PAYMENT_METHOD = 'POST_PAYMENT_METHOD'
export const POST_PAYMENT_METHOD_ERROR = 'POST_PAYMENT_METHOD_ERROR'
export const createPaymentMethod = (organization, payload) => {
  return getDispatch(
    apiFetch(paths.paymentMethods(organization), { method: 'POST', body: JSON.stringify(payload) }),
    POST_PAYMENT_METHOD,
    POST_PAYMENT_METHOD_ERROR
  )
}

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_ERROR = 'DELETE_PAYMENT_METHOD_ERROR'
export const deletePaymentMethod = (organization, identity) => {
  const action = async () => {
    await apiFetch(paths.paymentMethod(organization, identity), { method: 'DELETE' })
    return { id: identity }
  }

  return getDispatch(
    action(),
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_ERROR
  )
}

export const SET_PRIMARY_PAYMENT_METHOD = 'SET_PRIMARY_PAYMENT_METHOD'
export const SET_PRIMARY_PAYMENT_METHOD_ERROR = 'SET_PRIMARY_PAYMENT_METHOD_ERROR'
export const setPrimaryPaymentMethod = (organization, identity) => {
  const action = async () => {
    await apiFetch(paths.paymentMethodPrimary(organization, identity), { method: 'POST' })
    return { id: identity }
  }

  return getDispatch(
    action(),
    SET_PRIMARY_PAYMENT_METHOD,
    SET_PRIMARY_PAYMENT_METHOD_ERROR
  )
}

export const CREATE_PAYMENT_METHOD_SETUP = 'CREATE_PAYMENT_METHOD_SETUP'
export const CREATE_PAYMENT_METHOD_SETUP_ERROR = 'CREATE_PAYMENT_METHOD_SETUP_ERROR'
export const createPaymentMethodSetup = (organization) => {
  return getDispatch(
    apiFetch(paths.paymentSetups(organization), { method: 'POST' }),
    CREATE_PAYMENT_METHOD_SETUP,
    CREATE_PAYMENT_METHOD_SETUP_ERROR
  )
}

export const CANCEL_PAYMENT_METHOD_SETUP = 'CANCEL_PAYMENT_METHOD_SETUP'
export const CANCEL_PAYMENT_METHOD_SETUP_ERROR = 'CANCEL_PAYMENT_METHOD_SETUP_ERROR'
export const cancelPaymentMethodSetup = (organization, intentId) => {
  return getDispatch(
    apiFetch(paths.paymentSetup(organization, intentId), { method: 'DELETE' }),
    CANCEL_PAYMENT_METHOD_SETUP,
    CANCEL_PAYMENT_METHOD_SETUP_ERROR
  )
}

export const PAYMENT_METHOD_SETUP = 'PAYMENT_METHOD_SETUP'
export const isMethodSetup = setup => async dispatch => {
  dispatch(waitingState(PAYMENT_METHOD_SETUP, setup))
}

export const VALIDATE_VAT = 'VALIDATE_VAT'
export const VALIDATE_VAT_ERROR = 'VALIDATE_VAT_ERROR'
export const validateVAT = (organization, payload) => {
  return getDispatch(
    apiFetch(paths.validateVAT(organization), { method: 'POST', body: JSON.stringify(payload) }),
    VALIDATE_VAT,
    VALIDATE_VAT_ERROR
  )
}

export const GET_INVOICES = 'GET_INVOICES'
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR'
export const getInvoices = (organization, { limit, next } = {}) => {
  const query = queryString.stringify(omitEmpty({ limit, next }))
  return getDispatch(
    apiFetch(paths.filter(paths.invoices(organization), query)),
    GET_INVOICES,
    GET_INVOICES_ERROR,
    false,
    { next }
  )
}

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR'
export const getSubscriptions = (organization) => {
  return getDispatch(
    apiFetch(paths.subscriptions(organization)),
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_ERROR
  )
}

export const POST_SUBSCRIPTION = 'POST_SUBSCRIPTION'
export const POST_SUBSCRIPTION_ERROR = 'POST_SUBSCRIPTION_ERROR'
export const createSubscription = (organization, payload) => {
  const subscriptionType = 'reserved'
  const body = { ...payload, type: subscriptionType }
  return getDispatch(
    apiFetch(paths.subscriptions(organization), { method: 'POST', body: JSON.stringify(body) }),
    POST_SUBSCRIPTION,
    POST_SUBSCRIPTION_ERROR,
    false,
    { subscriptionType }
  )
}

export const PUT_SUBSCRIPTION = 'PUT_SUBSCRIPTION'
export const PUT_SUBSCRIPTION_ERROR = 'PUT_SUBSCRIPTION_ERROR'
export const updateSubscription = (organization, subscription, payload) => {
  return getDispatch(
    apiFetch(paths.subscription(organization, subscription), { method: 'PUT', body: JSON.stringify(payload) }),
    PUT_SUBSCRIPTION,
    PUT_SUBSCRIPTION_ERROR,
    false,
    { subscriptionType: payload.type }
  )
}

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR'
export const deleteSubscription = (organization, subscription) => {
  return getDispatch(
    apiFetch(paths.subscription(organization, subscription), { method: 'DELETE' }),
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_ERROR,
    false,
    { subscriptionType: 'reserved' }
  )
}

export const GET_PRICES = 'GET_PRICES'
export const GET_PRICES_ERROR = 'GET_PRICES_ERROR'
export const getPrices = (organization) => {
  return getDispatch(
    apiFetch(paths.prices(organization)),
    GET_PRICES,
    GET_PRICES_ERROR
  )
}

export const GET_SUBSCRIPTION_INVOICE = 'GET_SUBSCRIPTION_INVOICE'
export const GET_SUBSCRIPTION_INVOICE_ERROR = 'GET_SUBSCRIPTION_INVOICE_ERROR'
export const getSubscriptionInvoice = (organization, subscription, type = 'onDemand') => {
  return getDispatch(
    apiFetch(paths.subscriptionInvoice(organization, subscription)),
    GET_SUBSCRIPTION_INVOICE,
    GET_SUBSCRIPTION_INVOICE_ERROR,
    false,
    { subscriptionType: type }
  )
}

export const DELETE_SUBSCRIPTION_ITEM = 'DELETE_SUBSCRIPTION_ITEM'
export const DELETE_SUBSCRIPTION_ITEM_ERROR = 'DELETE_SUBSCRIPTION_ITEM_ERROR'
export const cancelSubscriptionItem = (organization, subscription, item, type) => {
  return getDispatch(
    apiFetch(paths.subscriptionItem(organization, subscription, item), { method: 'DELETE' }),
    DELETE_SUBSCRIPTION_ITEM,
    DELETE_SUBSCRIPTION_ITEM_ERROR,
    false,
    { itemType: type, subscriptionType: 'reserved' }
  )
}

export const GET_INVOICE_PREVIEW = 'GET_INVOICE_PREVIEW'
export const GET_INVOICE_PREVIEW_ERROR = 'GET_INVOICE_PREVIEW_ERROR'
export const getInvoicePreview = (organization, { subscriptionId, inventory, dataIngestion, discount }) => {
  const query = queryString.stringify(omitEmpty({ subscriptionId, inventory, dataIngestion, discount }))
  return getDispatch(
    apiFetch(paths.filter(paths.invoicePreview(organization), query)),
    GET_INVOICE_PREVIEW,
    GET_INVOICE_PREVIEW_ERROR
  )
}

export const GET_PROMOTION = 'GET_PROMOTION'
export const GET_PROMOTION_ERROR = 'GET_PROMOTION_ERROR'
export const getPromotion = (organization, promotion) => {
  return getDispatch(
    apiFetch(paths.promotion(organization, promotion)),
    GET_PROMOTION,
    GET_PROMOTION_ERROR
  )
}

export const POST_MARKETPLACE_ATTACHMENT = 'POST_MARKETPLACE_ATTACHMENT'
export const POST_MARKETPLACE_ATTACHMENT_ERROR = 'POST_MARKETPLACE_ATTACHMENT_ERROR'
export const postMarketplaceAttachment = (organization, payload) => {
  return getDispatch(
    apiFetch(paths.marketplace(organization), { method: 'POST', body: JSON.stringify(payload) }),
    POST_MARKETPLACE_ATTACHMENT,
    POST_MARKETPLACE_ATTACHMENT_ERROR
  )
}
