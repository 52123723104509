import React, { useCallback, useState } from 'react'
import { Button, Form, Checkbox } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { getInventoryResources } from 'lib/resources/filters'

import Targets from 'features/target-selector'

const DEFAULT_VALUES = {
  targets: [],
  type: ['error']
}

const EventsWidgetDetails = ({ editing, widget, kind, resources, handlers, onClose }) => {
  const [values, setValues] = useState(!editing
    ? DEFAULT_VALUES
    : {
      targets: widget.definition.targets,
      type: widget.definition.type || ['error']
    }
  )

  const [form] = Form.useForm()

  const handleFormSubmit = useCallback((payload) => {
    if (editing) {
      handlers.widgets.update(widget, payload)
    } else {
      handlers.widgets.add(kind, payload)
    }

    onClose()
  }, [handlers, widget, editing, kind])

  const handleFormChange = (change) => setValues({ ...values, ...change })

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true }}>
      <Form
        initialValues={values}
        layout='vertical'
        form={form}
        onFinish={handleFormSubmit}
        onValuesChange={handleFormChange}
      >
        <Form.Item name='type' label='Event types'>
          <Checkbox.Group size='small'>
            <Checkbox value='error'>Errors</Checkbox>
            <Checkbox value='insight'>Insights</Checkbox>
            <Checkbox value='alarm'>Alarms</Checkbox>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item name='targets' label='Targets'>
          <Targets
            resources={getInventoryResources(resources)}
            filterOption={true}
            placeholder='Select resources'
          />
        </Form.Item>

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
          >
            {editing ? 'Update widget' : 'Add widget'}
          </Button>
        </Form.Item>
      </Form>
    </PermissionsGate>
  )
}

export default EventsWidgetDetails
