export const INSIGHTS = 'insights'
export const INSIGHT_RULES_LIST = [INSIGHTS, 'rules', 'list']
export const INSIGHTS_LIST = [INSIGHTS, 'list']
export const INSIGHT_ACTIVE = [INSIGHTS, 'active']

export * from './get-insight-rules'
export * from './get-insight-rule'
export * from './update-insight-rule'
export * from './get-insights'

export const insightsPaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  insights: (account) => `${insightsPaths.account(account)}/insights`,
  rules: (account) => `${insightsPaths.insights(account)}/rules`,
  rule: (account, identity) => `${insightsPaths.rules(account)}/${identity}`
}

export type Changeset = {
  status: 'active' | 'disabled'
  threshold?: number
  severity?: 'info' | 'warning' | 'critical'
  excludes?: { resources: string[] }
}
