import React from 'react'
import Numeral from 'numeral'

import { Bar, Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { OPENSEARCH_DOCUMENTS } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchDocumentsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [OPENSEARCH_DOCUMENTS]: data.OPENSEARCH_DOCUMENTS_SEARCH,
    delete: data.OPENSEARCH_DOCUMENTS_DELETED
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={OPENSEARCH_DOCUMENTS}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line dataKey={OPENSEARCH_DOCUMENTS} name='Searchable' stroke={colors('chart', 'primary')} strokeWidth={1} type='monotone' dot={false} />
      <Bar dataKey='delete' name='Deleted' fill={colors('chart', 'redLight')} barSize={4} />
    </ChartContainer>
  )
}

export default OpensearchDocumentsChart
