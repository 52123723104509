import React from 'react'
import { countBy } from 'lodash'
import { Row, Col } from 'antd'

import { filterInventoryByService, INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import ServiceGroups from 'components/dashboards/services'
import ResourceGroups from 'features/resource-groups-list'
import AlarmsRegions from './regions'
import AlarmsDelegations from './delegations'
import ViolatedResources from './resources'
import { useAlarmsRulesQuery } from 'hooks/api'

const AlarmsDashboard = () => {
  const { data: alarms, isLoading: loading } = useAlarmsRulesQuery()

  const countByType = countBy(alarms, 'resourceType')

  const countByServiceGroup = Object.values(INVENTORY_SERVICE_GROUPS).map(item => {
    const count = filterInventoryByService(item.service).reduce((acc, item) => {
      const addition = countByType[item.type] || countByType[item.resourceType] || 0

      return acc + addition
    }, 0)

    return { service: item.service, title: item.title, count: count || 0 }
  })

  return (
    <Content
      item
      title='Alarms overview'
      breadcrumbs={['alarms']}
      renderEmpty={() => <EmptyAlarms />}
    >
      <ServiceGroups items={countByServiceGroup} loading={loading} />
      <Row gutter={[8, 8]}>
        <Col xs={24} md={8}>
          <ResourceGroups view='alarms' />
        </Col>
        <Col xs={24} md={8}>
          <ViolatedResources />
        </Col>
        <Col xs={24} md={8}>
          <AlarmsRegions />
          <AlarmsDelegations />
        </Col>
      </Row>
    </Content>
  )
}

const EmptyAlarms = () => (
  <Content item title='Alarms overview' breadcrumbs={['alarms']}>
    <Empty title='We could not find any alarms.'>
      You can add alarms from the top of the list.
    </Empty>
  </Content>
)

export default AlarmsDashboard
