import React from 'react'

import classnames from 'classnames'
import styles from './styles.module.less'

const Descriptions = ({ title, className, children }) => {
  return (
    <section className={className}>
      {title && <header className={styles.title}>{title}</header>}
      {children}
    </section>
  )
}

const DescriptionItem = ({ label, layout = 'horizontal', className, titleClassName, children }) => {
  return (
    <div className={classnames(styles.descriptionItem, styles[layout], className)}>
      <div className={classnames(styles.label, titleClassName)}>{label}</div>
      <div className={styles.value}>{children}</div>
    </div>
  )
}

Descriptions.Item = DescriptionItem

export { Descriptions }
