import React, { useContext } from 'react'
import Numeral from 'numeral'
import { isEmpty } from 'lodash'

import { Line, Area } from 'recharts'

import transform from './transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { ECSContext } from '../services/ecs/service'

const CPUChart = ({ loading, data, height, span, service }) => {
  const { cpuLimit } = useContext(ECSContext)
  if (loading || !data) return null

  const cpuDataKey = service ? `${service}-cpu` : 'cpu'
  const cpuUtiliziationKey = `${service?.toUpperCase()}_CPU_UTILIZATION`
  const cpuReservationKey = `${service?.toUpperCase()}_CPU_RESERVATION`

  const metrics = transform({
    [cpuDataKey]: data[cpuUtiliziationKey],
    reservation: data[cpuReservationKey]
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') return [`${Numeral(value[0]).format('0.[00]')} - ${Numeral(value[1]).format('0.[00]')}%`, undefined]
    return [`${Numeral(value).format('0.[00]')}%`, undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [cpuDataKey]: metric[`${cpuDataKey}_avg`],
      reservation: metric.reservation,
      diff: [metric[`${cpuDataKey}_min`], metric[`${cpuDataKey}_max`]]
    }
  })

  const showDiff = !isEmpty(formatted[0]?.diff)

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0]'
      span={span}
      tabKey={cpuDataKey}
      formatTab={value => `${Numeral(value).format('0.[00]')}%`}
      formatTooltip={valueFormatter}
      percentageChart
    >
      {data[cpuUtiliziationKey] && <Line type='monotone' dataKey={cpuDataKey} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />}
      {showDiff && <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Min - Max' />}
      {data[cpuReservationKey] && cpuLimit && <Line type='monotone' dataKey='reservation' stroke={colors('chart', 'red')} strokeWidth={2} name='Hard Limit' dot={false} />}
    </ChartContainer>
  )
}

export default CPUChart
