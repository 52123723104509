import React, { useState } from 'react'
import { Button, Dropdown } from 'antd'
import { format } from 'date-fns'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { useGetPaymentMethods, useUpdatePaymentMethodQuery, useDeletePaymentMethodQuery, useCreatePaymentMethodQuery } from 'hooks/api'

import Section from 'components/layout/content/section'
import { Typography } from 'components/typography'
import { ActionsDropdownButton } from 'components/buttons/actions-dropdown-button'
import CardItem from 'components/billing/stripe/card-item'
import AddMethodModal from './add-method-modal'

import styles from './styles.module.less'

const expiration = method => {
  return `${method.expiryMonth}/${format(new Date(method.expiryYear, 1, 1), 'yy')}`
}

const PaymentMethods = ({ customer }) => {
  const { data: paymentMethods, isLoading: loadingMethods } = useGetPaymentMethods()

  const { mutate: setPrimary } = useUpdatePaymentMethodQuery()
  const { mutate: deleteMethod, isLoading: loadingDelete } = useDeletePaymentMethodQuery()
  const { mutateAsync: createMethod, isLoading: loadingCreate } = useCreatePaymentMethodQuery()

  const [visible, setVisible] = useState(false)
  const [stripePromise] = useState(() => loadStripe(CONFIG.STRIPE_API_KEY))

  const sortedByPrimary = paymentMethods?.sort((a, b) => b.isPrimary - a.isPrimary) || []

  const handleCardActions = (key, item) => {
    switch (key) {
      case 'primary': return setPrimary({ methodId: item.id })
      case 'delete': return deleteMethod({ methodId: item.id })
      default: return () => { }
    }
  }

  return (
    <>
      <div className={styles.header}>
        <div>
          <Typography.Title level={2}>Payment methods</Typography.Title>
          <Typography.Paragraph>Manage your payments methods</Typography.Paragraph>
        </div>
        <Button type='primary' onClick={() => setVisible(true)}>Add new payment method</Button>
      </div>
      <Section loading={loadingMethods || loadingDelete || loadingCreate}>
        {sortedByPrimary?.map(item => {
          return (
            <div key={item.id} className={styles.method_container}>
              <div className={styles.header}>
                <div>
                  <CardItem card={item.card} />
                  <p className={styles.expires_at}>Expires at <span>{expiration(item.card)}</span> </p>
                </div>
                <div className={styles.actions}>
                  {item.isPrimary && <p className={styles.primary}>Primary</p>}
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: [
                        { key: 'primary', label: 'Make primary', disabled: item.isPrimary },
                        { key: 'delete', label: 'Delete', disabled: sortedByPrimary.length === 1 }
                      ],
                      onClick: ({ key }) => handleCardActions(key, item)
                    }}
                  >
                    <ActionsDropdownButton />
                  </Dropdown>
                </div>
              </div>
            </div>
          )
        })}
      </Section>

      <Elements stripe={stripePromise}>
        <AddMethodModal customer={customer} visible={visible} setVisible={setVisible} createMethod={createMethod} />
      </Elements>

    </>
  )
}

export default PaymentMethods
