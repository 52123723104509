import React from 'react'
import Numeral from 'numeral'

import { Area, Line, YAxis } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { yAxisProps } from 'components/charts/utils'
import { KDA_CHECKPOINT_DURATION, KDA_CHECKPOINT_SIZE, KDA_CHECKPOINT_FAILURE } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const KinesisAnalyticsCheckpointsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KDA_CHECKPOINT_DURATION]: data[KDA_CHECKPOINT_DURATION],
    [KDA_CHECKPOINT_SIZE]: data[KDA_CHECKPOINT_SIZE],
    [KDA_CHECKPOINT_FAILURE]: data[KDA_CHECKPOINT_FAILURE]
  })

  if (!metrics.length) return <Empty height={height} />

  const formatDuration = value => `${Numeral(value).format('0')}ms`
  const formatSize = value => Numeral(value).format('0.[00] b')
  const formatFailures = value => Numeral(value).format('0')

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === KDA_CHECKPOINT_SIZE) {
      return [formatSize(value), undefined]
    }

    if (dataKey === KDA_CHECKPOINT_DURATION) {
      return [formatDuration(value), undefined]
    }

    return [formatFailures(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={KDA_CHECKPOINT_DURATION}
      formatTab={formatDuration}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatDuration}
    >
      <YAxis yAxisId='size' orientation='right' domain={[0, 'dataMax']} tickFormatter={formatSize} {...yAxisProps} />
      <Line type='monotone' dataKey={KDA_CHECKPOINT_DURATION} stroke={colors('chart', 'primary')} strokeWidth={1} name='Duration' dot={false} />
      <Area type='monotone' dataKey={KDA_CHECKPOINT_SIZE} yAxisId='size' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} name='Size' dot={false} />
      <Line type='monotone' dataKey={KDA_CHECKPOINT_FAILURE} stroke={colors('chart', 'red')} strokeWidth={1} name='Failures' dot={false} />
    </ChartContainer>
  )
}

export default KinesisAnalyticsCheckpointsChart
