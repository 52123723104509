import React from 'react'
import Numeral from 'numeral'

import { Line, Bar, YAxis, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { formatDuration, yAxisProps } from 'components/charts/utils'
import { OPENSEARCH_SEARCH } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchSearchChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    latency: data.OPENSEARCH_INSTANCE_LATENCY_SEARCH,
    rate: data.OPENSEARCH_INSTANCE_RATE_SEARCH,
    queue: data.OPENSEARCH_INSTANCE_SEARCH_QUEUE
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'rate' || dataKey === 'queue') return [Numeral(value).format('0.[00]a'), undefined]
    if (dataKey === 'diff') return [`${formatDuration(value[0])} - ${formatDuration(value[1])}`, undefined]
    return [formatDuration(value), undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [OPENSEARCH_SEARCH]: metric.latency_avg,
      rate: metric.rate,
      diff: [metric.latency_min, metric.latency_max],
      queue: metric.queue
    }
  })

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={OPENSEARCH_SEARCH}
      formatYTicks={value => formatDuration(value)}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
    >
      <YAxis yAxisId='right' orientation='right' domain={[0, 'dataMax']} {...yAxisProps} tickFormatter={(value => Numeral(value).format('0.[00]a'))} />
      <Bar dataKey='rate' yAxisId='right' name='Rate' fill={colors('chart', 'primary')} barSize={4} />
      <Bar dataKey='queue' yAxisId='right' name='Max Queue' fill={colors('chart', 'red')} barSize={4} />

      <Line type='monotone' dataKey={OPENSEARCH_SEARCH} name='Average Latency' stroke={colors('chart', 'redLight')} strokeWidth={1} dot={false} />
      <Area type='monotone' dataKey='diff' fill={colors('chart', 'redWithOpacity')} stroke={colors('chart', 'redWithOpacity')} strokeWidth='0.5' name='Latency Min - Max' />
    </ChartContainer>
  )
}

export default OpensearchSearchChart
