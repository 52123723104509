import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { APPSYNC_REQUESTS } from 'containers/inventory/details/resource/metrics-tabs/services/appsync'

const AppSyncRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [APPSYNC_REQUESTS]: data.APPSYNC_REQUESTS,
    errors: data.APPSYNC_ERRORS
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={APPSYNC_REQUESTS}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={APPSYNC_REQUESTS} stackId='a' fill={colors('chart', 'primary')} name='Requests' barSize={4} />
      <Bar dataKey='errors' stackId='b' fill={colors('chart', 'red')} name='Detected Errors' barSize={4} />
    </ChartContainer>
  )
}

export default AppSyncRequestsChart
