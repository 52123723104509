import React from 'react'

import PrettyPrinter from 'components/json-pretty'
import Enabled from 'containers/inventory/shared/enabled'
import Metadata from 'containers/inventory/shared/metadata/index'

const EventBridgeRuleMetadata = ({ attributes, inverse, rule }) => {
  const data = [
    { title: 'Rule ARN', value: rule.arn },
    { title: 'Description', value: attributes.description },
    {
      title: 'General',
      children: [
        { key: 'State', value: <Enabled data={attributes.state === 'ENABLED'} /> },
        { key: 'Event Bus Name', value: attributes.eventBusName },
        { key: 'Managed By', value: attributes.managedBy }
      ].filter(item => item.value)
    },
    { title: 'Schedule Expression', value: attributes.scheduleExpression },
    { title: 'Event Pattern', value: <PrettyPrinter jsonString={JSON.parse(attributes.eventPattern || '{}')} collapsed={false} /> }
  ].filter(item => item.value || item.children)

  return <Metadata items={data} inverse={inverse} />
}

export default EventBridgeRuleMetadata
