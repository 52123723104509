import React from 'react'
import { Tooltip } from 'antd'
import { last } from 'lodash'
import classnames from 'classnames'

import { Item } from 'components/items/item'
import { Time } from 'components/time'
import { IconWithText, BugIcon, ClockIcon, ColdstartIcon, MemoryIcon, RetryIcon, GlobeIcon } from 'components/icons'

import styles from './styles.module.less'

const ExecutionItem = ({ icon, bordered, responsive, gap, title, timestamp, identity, duration, memory, bug, warning, coldstart, retry, details, delegation, region, url, selected, isActive, statusBar, className }) => {
  return (
    <Item
      layout='rows'
      size='xs'
      icon={icon}
      bordered={bordered}
      gap={gap}
      className={classnames({ [styles.responsive_wrapper]: !!responsive, [styles.wrap]: responsive < 950 }, className)}
      outlined
      url={url}
      selected={selected}
      statusBar={statusBar}
    >
      <div className={classnames(styles.item, { [styles.responsive]: !!responsive, [styles.wrap]: responsive < 950 })}>
        <div className={styles.left}>
          {title || <Tooltip title={identity}><span className={classnames(styles.guid, { [styles.active]: !!isActive })}>{last(identity?.split('-'))}</span></Tooltip>}
        </div>
        <div className={classnames(styles.right, { [styles.wrap]: responsive < 950 })}>
          <div className={styles.details}>
            {delegation && <span>{delegation}</span>}
            {region && <IconWithText icon={<GlobeIcon />} text={region.toUpperCase()} />}
            {identity && title &&
              <Tooltip title={identity} className={styles.identity}>
                <span>{last(identity.split('-'))}</span>
              </Tooltip>
            }
            <div className={classnames(styles.metrics, { [styles.wrap]: responsive < 950 })}>
              {duration && <IconWithText icon={<ClockIcon />} text={duration} className={classnames(styles.duration, { [styles.wrap]: responsive < 950 })} />}
              {memory &&
                <Tooltip title="Memory usage">
                  <IconWithText icon={<MemoryIcon />} text={memory} />
                </Tooltip>
              }
              {(bug || warning) &&
                <Tooltip title="Critical error">
                  <BugIcon className={classnames(styles.bug, { [styles.warning]: !!warning })} />
                </Tooltip>
              }
              {coldstart &&
                <Tooltip title="Cold start">
                  <ColdstartIcon className={styles.coldstart} />
                </Tooltip>
              }
              {retry &&
                <Tooltip title="Retry">
                  <RetryIcon className={styles.retry} />
                </Tooltip>
              }
            </div>
            {details}
          </div>
          {timestamp && <Time className={styles.timestamp} type='short' value={timestamp} />}
        </div>
      </div>
    </Item>
  )
}

export { ExecutionItem }
