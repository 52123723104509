import React from 'react'
import { get, isEmpty, lowerCase, map, sortBy } from 'lodash'
import { format } from 'date-fns'
import classnames from 'classnames'

import { Timeline, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

import { formatDuration } from 'components/charts/utils'
import { BugIcon, ColdstartIcon, RetryIcon, IconWithText } from 'components/icons'
import { ArrowLeftIcon, ClockIcon } from 'components/icons/font-awesome'
import styles from './styles.module.less'

const Coldstart = ({ item }) => {
  if (!get(item, 'data.initDuration')) return null
  return <div className={styles.retries}><ColdstartIcon /></div>
}

const Bug = ({ item }) => {
  if (!item.hasCriticalErrors) return null
  return <IconWithText icon={<BugIcon />} text={lowerCase(item.error)} className={styles.retries} />
}

const Dot = ({ item, className }) => {
  return <RetryIcon className={classnames(styles.dot, className)} />
}

const RetriesTimeline = ({ itemId, resource, parentItem, parentId }) => {
  const routes = useAccountRoutes()
  const isCurrent = parentItem.eventId === get(resource, 'resource.eventId', resource.eventId)
  const uri = routes.inventory.invocation.url({ resourceId: parentId, invocationHash: resource.eventId })
  const duration = formatDuration(resource.data.duration)
  const start = format(resource.timestamp, 'HH:mm:ss dd.MM.yyyy')

  const CurrentToolTip = ({ children }) => isCurrent ? <Tooltip title='current'>{children}</Tooltip> : children

  return (
    <Timeline.Item
      key={itemId}
      dot={resource.isRetry && <Dot item={resource} />}
      className={classnames(styles.timeline, isCurrent && styles.current)}
    >
      <CurrentToolTip>
        <Link to={uri}>{resource.requestId}</Link>
        <IconWithText
          icon={<ArrowLeftIcon />}
          text={start}
          className={styles.retries}
        />
        <IconWithText
          icon={<ClockIcon />}
          text={duration}
          className={styles.retries}
        />
        <Bug item={resource} />
        <Coldstart item={resource} />
      </CurrentToolTip>
    </Timeline.Item>
  )
}

const Retries = ({ item, resourceId, retries }) => {
  if (!retries || isEmpty(retries) || (retries.length === 1 && !retries[0].isRetry)) return <p>Retries not found</p>

  const sortedRetries = sortBy(retries, 'timestamp')
  return (
    <Timeline>
      {map(sortedRetries, (resource, id) =>
        <RetriesTimeline itemId={id} resource={resource} parentItem={item} parentId={resourceId} />
      )}
    </Timeline>
  )
}

export default Retries
