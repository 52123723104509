import { paths } from '../../index'

export const NOTIFICATION_POLICY = 'notification_policy'
export const NOTIFICATION_POLICY_LIST = [NOTIFICATION_POLICY, 'list']

export * from './get-policies'

export * from './get-policy'
export * from './create-policy'
export * from './update-policy'
export * from './delete-policy'

export * from './change-policy-channels'

export * from './create-policy-condition'
export * from './update-policy-condition'
export * from './delete-policy-condition'

export * from './change-condition-target'

export const policyPaths = {
  policies: (account) => `${paths.account(account)}/notifications/policies`,
  policy: (account, identity) => `${policyPaths.policies(account)}/${identity}`,
  conditions: (account, policy) => `${policyPaths.policy(account, policy)}/conditions`,
  condition: (account, policy, identity) => `${policyPaths.conditions(account, policy)}/${identity}`,
  targets: (account, policy, condition) => `${policyPaths.condition(account, policy, condition)}/targets`,
  target: (account, policy, condition, identity) => `${policyPaths.targets(account, policy, condition)}/${identity}`,
  channel: (account, policy, identity) => `${policyPaths.policy(account, policy)}/channels/${identity}`
}
