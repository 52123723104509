import React from 'react'
import Numeral from 'numeral'
import { Line, Bar } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { EVENTBRIDGE_RULE_TRIGGERED_RULES } from 'containers/inventory/details/resource/metrics-tabs/services/eventbridge-rule'

const StepFunctionsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    triggered: data.EVENTBRIDGE_RULE_TRIGGERED_RULES,
    throttled: data.EVENTBRIDGE_RULE_THROTTLED_RULES
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => [Numeral(value).format('0.[000]a'), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[00]'
      span={span}
      tabKey={EVENTBRIDGE_RULE_TRIGGERED_RULES}
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey='triggered' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Triggered' dot={false} />
      <Bar dataKey='throttled' stackId='a' fill={colors('chart', 'red')} maxBarSize={4} name='Throttled' />
    </ChartContainer>
  )
}

export default StepFunctionsChart
