import React, { useContext } from 'react'

import { MetricsWidget, InvocationsWidget, EventsWidget, PlaceholderWidget, TextWidget, LogsWidget, ResourcesWidget } from 'features/widgets/kinds'
import { WIDGET_KIND } from 'lib/dashboards-constants'

import { DashboardContext } from 'hooks/context/dashboard-context'
import styles from './styles.module.less'

const getWidgetComponent = ({ widget, metrics, invocations, events, logs, groupResources, options, staticWidget, onDelete, onEdit, onDuplicate, onUpdateName }) => {
  const COMMON_PROPS = { widget, staticWidget, onEdit, onDelete, onDuplicate, onUpdateName }

  switch (widget.kind) {
    case WIDGET_KIND.METRICS:
      return (
        <MetricsWidget
          {...COMMON_PROPS}
          metrics={metrics}
          options={options}
        />
      )

    case WIDGET_KIND.INVOCATIONS:
      return (
        <InvocationsWidget
          {...COMMON_PROPS}
          invocations={invocations}
        />
      )

    case WIDGET_KIND.EVENTS:
      return (
        <EventsWidget
          {...COMMON_PROPS}
          events={events}
          options={options}
        />
      )

    case WIDGET_KIND.PLACEHOLDER:
      return <PlaceholderWidget />

    case WIDGET_KIND.TEXT:
      return <TextWidget {...COMMON_PROPS} />

    case WIDGET_KIND.LOGS:
      return <LogsWidget {...COMMON_PROPS} logs={logs} />

    case WIDGET_KIND.RESOURCES:
      return <ResourcesWidget {...COMMON_PROPS} groupResources={groupResources} />
  }
}

const Widget = ({ widget, staticWidget }) => {
  const { handlers, metrics, invocations, events, logs, groupResources, options } = useContext(DashboardContext)

  const handleWidgetDelete = (widget) => {
    handlers.widgets.delete(widget)
  }

  const handleWidgetEdit = (widget) => {
    handlers.widgets.edit(widget)
  }

  const handleWidgetDuplicate = (widget) => {
    handlers.widgets.duplicate(widget)
  }

  const handleWidgetUpdateName = (widget, name) => {
    if (widget.kind === WIDGET_KIND.TEXT) {
      handlers.widgets.update(widget, { text: name, size: widget?.definition?.size })
      return
    }
    handlers.widgets.updateName(widget, name)
  }

  const widgetComponent = getWidgetComponent({
    widget,
    metrics,
    invocations,
    events,
    logs,
    groupResources,
    options,
    staticWidget,
    onDelete: handleWidgetDelete,
    onEdit: handleWidgetEdit,
    onDuplicate: handleWidgetDuplicate,
    onUpdateName: handleWidgetUpdateName
  })

  return (
    <div className={styles.widget}>
      {widgetComponent}
    </div>
  )
}

export default Widget
