import { omitBy, isUndefined, chunk, isArray } from 'lodash'
import { Auth } from '@aws-amplify/auth'
import { ApiFetch } from 'lib/api-fetch'
import { fetchJSON } from 'lib/api-fetch/fetch-json'

const apiFetchInstance = new ApiFetch({
  authorization: true
})
const apiFetchUnauthorizedInstance = new ApiFetch()

export const waitingState = (key, state) => {
  return {
    type: 'WAITING',
    payload: {
      key, state: !!state
    }
  }
}

export const getDispatch = (request, successKey, errorKey, id, params, clearKey) => async (dispatch) => {
  try {
    dispatch(waitingState(successKey, true))
    if (clearKey) await dispatch({ type: clearKey })

    const data = await request

    return dispatch({
      type: successKey,
      payload: id ? { id, data } : data,
      ...params
    })
  } catch (e) {
    return dispatch({
      type: errorKey,
      payload: e
    })
  } finally {
    dispatch(waitingState(successKey, false))
  }
}

export const apiFetch = async function (path, options) {
  return apiFetchInstance.fetch(path, options)
}

export const apiFetchUnauthorized = async function (path, options) {
  return apiFetchUnauthorizedInstance.fetch(path, options)
}

export const omitEmpty = function (data) {
  return omitBy(data, val => isUndefined(val) || val === null)
}

export const apiBatchFetch = async function (path, params, batchSize = 100) {
  const args = {
    method: 'GET',
    headers: new Headers(),
    ...params
  }

  // Set content type it none supplied
  if (!args.headers.get('Content-Type')) {
    args.headers.set('Content-Type', 'application/json')
  }

  // Set authorization if cognito token exists and none supplied
  try {
    const { accessToken } = await Auth.currentSession()
    if (accessToken && !args.headers.get('Authorization')) {
      args.headers.set('Authorization', `Bearer ${accessToken.jwtToken}`)
    }
  } catch (e) {
    console.log('Amplify error: ', e)
  }

  const url = CONFIG.API_URL + path

  const batchPayloads = (body) => {
    if (!params.body) return [undefined]
    const payloads = JSON.parse(body)
    return isArray(payloads) ? chunk(payloads, batchSize) : [payloads]
  }
  const payloadBatches = batchPayloads(params?.body)

  const results = await Promise.all(payloadBatches.map(async payload => {
    const { json: result } = await fetchJSON.apply(this, [url, {
      ...args,
      ...(payload && { body: JSON.stringify(payload) })
    }])
    return result.data ? result.data : result
  }))
  return { data: results }
}
