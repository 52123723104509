import React, { useState, useEffect } from 'react'
import { Card, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { GET_CUSTOMER, GET_PAYMENT_METHODS, POST_PAYMENT_METHOD, DELETE_PAYMENT_METHOD } from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrganization } from 'hooks/context/organization-context'

import PaymentMethodsList from './list'
import Modal from './modal'

const AddButton = ({ methods = [], add, loading } = {}) => {
  if (loading || !methods.length) return null

  return (
    <Button
      size='small'
      type='primary'
      onClick={add}
    >Add</Button>
  )
}

const PaymentMethods = ({ className }) => {
  const dispatch = useDispatch()
  const [overlay, setOverlay] = useState(false)

  const { organization } = useOrganization()
  const loadingCustomer = useSelector(state => get(state, ['waiting', 'list', GET_CUSTOMER]))
  const loadingPaymentMethods = useSelector(state => get(state, ['waiting', 'list', GET_PAYMENT_METHODS]))
  const loadingPaymentMethodsCreate = useSelector(state => get(state, ['waiting', 'list', POST_PAYMENT_METHOD]))
  const loadingPaymentMethodsDelete = useSelector(state => get(state, ['waiting', 'list', DELETE_PAYMENT_METHOD]))
  const customer = useSelector(state => get(state, 'billing.customers.active'))
  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))

  useEffect(() => {
    if (!organization.id) return
    dispatch(API.organizations.id(organization.id).billing.payment.methods.get())
  }, [organization.id])

  const loading = loadingCustomer || loadingPaymentMethods || loadingPaymentMethodsCreate || loadingPaymentMethodsDelete

  const addPaymentMethod = () => {
    setOverlay(true)
  }

  const reloadMethods = () => {
    hideOverlay()
  }
  const hideOverlay = () => setOverlay(false)

  return (
    <Card
      className={className}
      title='Payment methods'
      extra={<AddButton methods={paymentMethods} add={addPaymentMethod} loading={loading} />}
    >
      <PaymentMethodsList
        loading={loading}
        methods={paymentMethods}
        showOverlay={addPaymentMethod}
      />

      <Modal
        open={overlay}
        customer={customer}
        onOk={reloadMethods}
        onCancel={hideOverlay}
      />
    </Card>
  )
}

export default PaymentMethods
