import React from 'react'
import classnames from 'classnames'
import { Popover } from 'antd'

import styles from './styles.module.less'

export const nameColumn = {
  title: 'Account name',
  key: 'account',
  sorter: (a, b) => a.account.localeCompare(b.account),
  sortDirections: ['descend', 'ascend'],
  render: record => <span>{record.account}</span>
}

export const statusColumn = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status'
}

export const accessColumn = {
  title: 'Access',
  dataIndex: 'access',
  key: 'access',
  render: (item, record) => (
    <Popover
      content={item.map(user => <p key={user.id}>{user.email}</p>)}
      placement='right'
      trigger='hover'>
      <button className={classnames(styles.access_count, styles[record.status])}>{item.length} users</button>
    </Popover>)
}

export const resourceCountColumn = {
  title: 'Resource count',
  key: 'resources',
  dataIndex: 'resources'
}
