import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'RDS_CPU_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'cpu.utilization',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'RDS_DATABASE_CONNECTIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'database.connections',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'RDS_READ_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'read.latency',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_WRITE_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'write.latency',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_REPLICA_LAG',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'replica.lag',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_NETWORK_INCOMING',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'network.receive.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_NETWORK_OUTGOING',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'network.transmit.throughput',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'RDS_BURST_BALANCE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'burst.balance',
    stats: [STATISTICS.MIN]
  }
}]

export default metrics
