import { sum } from 'lodash'
import numeral from 'numeral'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'

import {
  aggregateSparkline, average, getSparklineByName,
  getSum,
  getValues
} from 'containers/inventory/details/resource/metrics-tabs/helpers'
import { formatDuration } from 'components/charts/utils'

export const APPSYNC_RESOLVER_REQUESTS = 'appsync-resolver-requests'
export const APPSYNC_RESOLVER_ERRORS = 'appsync-resolver-errors'
export const APPSYNC_RESOLVER_DURATION = 'appsync-resolver-duration'

const metrics = [{
  key: APPSYNC_RESOLVER_REQUESTS,
  icon: faSigma,
  title: 'Requests',
  filter: (collection) => {
    const total = getSum(collection, 'APPSYNC_RESOLVER_REQUESTS')
    return `${numeral(total).format('0.[00]a')}`
  },
  subvalue: (collection) => getSparklineByName(collection, 'APPSYNC_RESOLVER_REQUESTS'),
  selectable: true
}, {
  key: APPSYNC_RESOLVER_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const system = getSum(collection, 'APPSYNC_RESOLVER_ERRORS')
    return numeral(system).format('0.[00]a')
  },
  subvalue: (collection) => {
    return aggregateSparkline(collection, sum, 'APPSYNC_RESOLVER_ERRORS')
  },
  selectable: true
}, {
  key: APPSYNC_RESOLVER_DURATION,
  icon: faClock,
  title: 'Duration',
  filter: (collection) => {
    const values = getValues(collection, 'APPSYNC_RESOLVER_DURATION')?.map(item => item.value)
    return formatDuration(average(values) / 1000000)
  },
  subvalue: (collection) => getSparklineByName(collection, 'APPSYNC_RESOLVER_DURATION'),
  selectable: true
}]

export default metrics
