import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { alarmsPaths as paths, ALARM_ACTIVE } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const addAlarmTarget = async (account, alarm, identity) => {
  const data = apiFetch(paths.targets(account, alarm, identity), { method: 'POST' })

  return data
}

const deleteAlarmTarget = async (account, alarm, identity) => {
  const data = apiFetch(paths.targets(account, alarm, identity), { method: 'DELETE' })

  return data
}

export const useAddAlarmTargetQuery = () => {
  const { alarmId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    target => addAlarmTarget(account.id, alarmId, target),
    {
      onSuccess: updatedAlarm => {
        queryClient.setQueryData([...ALARM_ACTIVE, alarmId], updatedAlarm)
      }
    }
  )
}

export const useDeleteAlarmTargetQuery = () => {
  const { alarmId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    target => deleteAlarmTarget(account.id, alarmId, target),
    {
      onSuccess: updatedAlarm => {
        queryClient.setQueryData([...ALARM_ACTIVE, alarmId], updatedAlarm)
      }
    }
  )
}
