import React, { useEffect } from 'react'

import { Form, Button, message } from 'antd'
import { Typography } from 'components/typography'
import Content from 'components/layout/content'
import Input from 'components/antd/input'

import { useUser } from 'hooks/context/user-context'
import { useUpdateUser } from 'hooks/auth'

const Profile = () => {
  const { user } = useUser()

  const { mutate: updateUser, isLoading, isSuccess } = useUpdateUser()

  const initialValues = {
    email: user?.attributes?.email,
    givenName: user?.attributes?.given_name,
    familyName: user?.attributes?.family_name
  }

  const handleUpdateUser = (values) => {
    const { givenName, familyName } = values

    updateUser({ given_name: givenName, family_name: familyName })
  }

  useEffect(() => {
    if (!isSuccess) return

    message.success('User info updated')
  }, [isSuccess])

  return (
    <Content item={{}} title={'User profile'} breadcrumbs={['Settings', 'Profile']}>
      <Typography.Paragraph>These preferences only apply to you</Typography.Paragraph>
      <Form onFinish={values => handleUpdateUser(values)} initialValues={initialValues} layout='vertical'>
        <Form.Item label='Email' name='email'><Input disabled /></Form.Item>
        <Form.Item label='First name' name='givenName'><Input /></Form.Item>
        <Form.Item label='Last Name' name='familyName'><Input /></Form.Item>
        <Form.Item><Button type='primary' htmlType='submit' loading={isLoading}>Save</Button></Form.Item>
      </Form>
    </Content>
  )
}

export default Profile
