import React from 'react'
import { useParams } from 'react-router-dom'

import { useAccountRoutes } from 'containers/routes'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import Loading from 'components/layout/content/loading'
import Invocations from 'features/invocations'
import EditableTitle from 'features/editable-title'
import { getInvocationsKey } from 'lib/dashboards-helpers'
import IconButton from 'components/buttons/icon'
import { DragIcon } from 'components/icons'

import Actions from '../actions'

import styles from '../styles.module.less'

const InvocationsWidget = ({ widget, invocations, staticWidget, onDelete, onEdit, onDuplicate, onUpdateName }) => {
  const { dashboardId } = useParams()
  const routes = useAccountRoutes()

  const key = getInvocationsKey(widget)
  const data = invocations?.data[key]?.data || []

  if (!data.length && invocations.loading) return <Loading height='100%' />

  return (
    <div className={styles.widget_container}>
      <div className={styles.title}>
        <div>
          <EditableTitle
            value={widget?.name || 'Invocations'}
            onSubmit={value => onUpdateName(widget, value)}
            displayContent={widget?.name || 'Invocations'}
            size='xs'
            solid
            uppercase
          />
        </div>
        <span className={styles.actions}>
          {!staticWidget && <IconButton icon={<DragIcon />} className='dbDragHandle' />}
          <Actions
            widget={widget}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
          />
        </span>
      </div>
      <div className={styles.separator} />
      <div className={styles.wrapper}>
        <Invocations
          loading={data.loading}
          invocations={data}
          service={INVENTORY_SERVICES.Lambda.id}
          resource={{}}
          hideFilters
          onSelect={() => null}
          onClose={() => null}
          onSearch={() => null}
          url={invocation => ({ pathname: routes.dashboards.invocation.url({ dashboardId, resourceId: invocation.resource, invocationHash: invocation.eventId }) })}
        />
      </div>
    </div>
  )
}

export default InvocationsWidget
