import React, { useCallback, useState, useMemo } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { DASHBOARD_KIND, WIDGET_KIND } from 'lib/dashboards-constants'

import { generateGridBackground } from './generate-grid-background'
import Widget from './widget'
import styles from './styles.module.less'

const ResponsiveGridLayout = WidthProvider(Responsive)

const margin = [10, 10]
const rowHeight = 70

// TODO: extract
const widgetsToGridLayout = (widgets = [], kind, viewOnly) => {
  const staticWidget = kind === DASHBOARD_KIND.RESOURCE_GROUP || viewOnly
  const COMMON_WIDGET_PROPS = {
    isDraggable: !staticWidget
  }

  return widgets.map((widget) => {
    return {
      ...COMMON_WIDGET_PROPS,
      isResizable: !(widget.kind === WIDGET_KIND.PLACEHOLDER || staticWidget),
      i: widget.id,
      x: widget.layout.x,
      y: widget.layout.y,
      w: widget.layout.width,
      h: widget.layout.height,
      raw: widget
    }
  })
}

const DashboardGrid = ({ widgets = [], kind, onDragStop, onResize, onResizeStop, viewOnly }) => {
  const [gridWidth, setGridWidth] = useState()
  const [cols, setCols] = useState(16)
  const [widgetMove, setWidgetMove] = useState(false)
  const layout = widgetsToGridLayout(widgets, kind, viewOnly)

  const layouts = {
    lg: layout,
    md: layout,
    sm: layout,
    xs: layout,
    xxs: layout
  }

  const handleDrag = () => {
    setWidgetMove(true)
  }

  const handleDragStop = useCallback((...data) => {
    if (onDragStop) {
      onDragStop(...data)
      setWidgetMove(false)
    }
  }, [onDragStop])

  const handleResize = useCallback((...data) => {
    if (onResize) {
      onResize(...data)
      setWidgetMove(true)
    }
  }, [onResize])

  const handleResizeStop = useCallback((...data) => {
    if (onResizeStop) {
      onResizeStop(...data)
      setWidgetMove(false)
    }
  }, [onResizeStop])

  const handleWidthChange = (containerWidth, margin, cols) => {
    setGridWidth(containerWidth)
    setCols(cols)
  }

  const cellSize = useMemo(() => {
    const marginSlotsCount = cols - 1
    const [horizontalMargin] = margin
    const totalHorizontalMargin = marginSlotsCount * horizontalMargin + 20
    const freeSpace = gridWidth - totalHorizontalMargin
    return {
      width: freeSpace / cols,
      height: rowHeight
    }
  }, [cols, gridWidth])

  const background = useMemo(
    () => generateGridBackground({ cellSize }),
    [cellSize]
  )

  return (
    <ResponsiveGridLayout
      className={styles.grid}
      layouts={layouts}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 16, md: 16, sm: 2, xs: 2, xxs: 2 }}
      rowHeight={rowHeight}
      resizeHandles={['se']}
      onDrag={handleDrag}
      onDragStop={handleDragStop}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      draggableHandle='.dbDragHandle'
      style={widgetMove ? background : {}}
      onWidthChange={handleWidthChange}
      compactType='horizontal'
    >
      {layout.map(widget => (
        <div key={widget.i}>
          <Widget
            widget={widget.raw}
            staticWidget={!widget.isDraggable && !widget.isResizable}
          />
        </div>
      ))}
    </ResponsiveGridLayout>
  )
}

export default DashboardGrid
