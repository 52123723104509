import numeral from 'numeral'
import { find, sum } from 'lodash'

import { faDownload, faUpload } from '@fortawesome/pro-light-svg-icons'

import { getSum, getSparklineByName, aggregateSparkline } from '../helpers'

export const KINESIS_FIREHOSE_INCOMING = 'kinesis-firehose-incoming'
export const KINESIS_FIREHOSE_OUTGOING = 'kinesis-firehose-outgoing'

const metrics = [{
  key: KINESIS_FIREHOSE_INCOMING,
  icon: faDownload,
  title: 'Incoming',
  filter: (collection) => numeral(getSum(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_INCOMING_RECORDS')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_INCOMING_RECORDS'),
  selectable: true
}, {
  key: KINESIS_FIREHOSE_OUTGOING,
  icon: faUpload,
  title: 'Outgoing',
  selectable: true,
  filter: (collection, item) => {
    return numeral(
      getSum(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_RECORDS') +
      getSum(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_RECORDS') +
      getSum(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_RECORDS') +
      getSum(collection, 'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_RECORDS')
    ).format('0.[00]a')
  },
  subvalue: (collection) => {
    return aggregateSparkline(
      collection,
      sum,
      'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_S3_RECORDS',
      'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_ES_RECORDS',
      'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_REDSHIFT_RECORDS',
      'KINESIS_FIREHOSE_DELIVERY_STREAM_OUTGOING_SPLUNK_RECORDS'
    )
  }
}, {
  key: 'destination',
  icon: faUpload,
  title: 'Destination',
  filter: (c, item) => {
    if (!item.attributes) return null

    const result = find(item.attributes, { name: 'destinations' })

    if (!result) return null

    const destinations = JSON.parse(result.value)

    if (!destinations.length) return null

    return destinations[0].type
  }
}]

export default metrics
