import React, { cloneElement } from 'react'
import { useOrganization } from 'hooks/context/organization-context'
import { PERMISSIONS } from './permissions'
import { useOrganizationsQuery } from 'hooks/api'

const hasPermission = ({ permissions = [], requiredScopes }) => {
  const scopesMap = {}

  requiredScopes.forEach(scope => {
    scopesMap[scope] = true
  })

  return permissions?.some((permission) => scopesMap[permission])
}

export const useUserPermissions = () => {
  const { account, organization } = useOrganization()
  const { data: organizations } = useOrganizationsQuery()
  const role = account?.role
  const permissions = PERMISSIONS[role]

  const accounts = organizations?.find(item => item.organization.id === organization.id)?.accounts

  return {
    hasPermission: (scopes) => hasPermission({ permissions, requiredScopes: scopes }),
    hasPermissionsIn: (scopes, accountId) => hasPermission({ permissions: PERMISSIONS[accounts?.find(item => item.id === accountId)?.role], requiredScopes: scopes })
  }
}

export const PermissionsGate = ({
  children,
  scopes = [],
  RenderError = () => <></>,
  errorProps = null
}) => {
  const { hasPermission } = useUserPermissions()

  const permissionGranted = hasPermission(scopes)

  if (!permissionGranted && !errorProps) return <RenderError />

  if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps })

  return (
    <>
      {children}
    </>)
}
