import React from 'react'
import classnames from 'classnames'
import { Input as AntdInput } from 'antd'
import { useResize, Breakpoint } from 'hooks/resize'

import styles from './styles.module.less'

const Input = React.forwardRef(({ className, bordered = false, solid, ...props }, ref) => {
  const { isVisibleUntil } = useResize()
  return <AntdInput
    {...props}
    ref={ref}
    bordered={bordered}
    className={classnames(styles.input, {
      [styles.bordered]: bordered,
      [styles.solid]: solid
    }, className)}
    size={isVisibleUntil(Breakpoint.md) ? 'large' : 'middle'}
  />
})

export default Input
