import React from 'react'
import { Popconfirm, Button, Tooltip } from 'antd'

const BuyButton = ({ total, onConfirm, loading, title, disabled, tooltip, className } = {}) => {
  return (
    <Tooltip title={tooltip}>
      <Popconfirm
        title={`You will be charged ${total} immediately.`}
        onConfirm={onConfirm}
        okText='I understand'
        cancelText='Cancel'
        disabled={disabled}
      >
        <Button
          type='primary'
          block
          className={className}
          loading={loading}
          disabled={disabled}
        >{title}</Button>
      </Popconfirm>
    </Tooltip>
  )
}

export default BuyButton
