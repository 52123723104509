import React from 'react'
import { Typography } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const columns = [
  {
    title: 'Product',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Estimated usage',
    dataIndex: 'usage',
    key: 'usage',
    render: (usage, data) => {
      return (
        <>
          <Text style={{ marginRight: 10 }}>{usage}</Text>
          <Text type='secondary'>{`${data.unit}/month`}</Text>
        </>
      )
    }
  },
  {
    dataIndex: 'unitPrice',
    key: 'unitprice',
    render: (price, data) => <Text type='secondary'>{`x ${formatStripePrice(price, data.currency)} per ${data.unit}`}</Text>
  },
  {
    title: 'Estimated price',
    dataIndex: 'total',
    key: 'total',
    render: (total, data) => <Text>{formatStripePrice(total, data.currency)}</Text>
  }
]

export default columns
