import React from 'react'

import { LineChart, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import transform from './transform'

const Sparkline = ({ loading, data, height, span }) => {
  if (loading || !data || data.length <= 1) return null

  const metrics = transform({ value: data })

  return (
    <ResponsiveContainer width='100%' height={height} debounce={300}>
      <LineChart data={metrics}>
        <XAxis dataKey='date' hide />
        <YAxis domain={[0, 'dataMax']} hide />
        <Line dataKey='value' type='monotoneX' stroke='#ababab' dot={false} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Sparkline
