import React, { useRef } from 'react'
import { times } from 'lodash'
import classnames from 'classnames'
import { Skeleton } from 'antd'
import { Typography } from 'components/typography'

import styles from './styles.module.less'

const Title = ({ title, className, titleUpperCase }) => {
  if (!title) return null

  return (
    <Typography.Title
      level={2}
      className={className}
      upperCase={titleUpperCase}
    >
      {title}
    </Typography.Title>
  )
}

const Actions = ({ actions, className }) => {
  if (!actions) return null

  return (
    <div className={classnames(styles.actions, className)}>
      {actions}
    </div>
  )
}

const Loading = ({ height = 4 }) => {
  return (
    <>
      {times(height).map(index => (
        <Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />
      ))
      }
    </>
  )
}

const ContentWrapper = ({ children, className, solid }) => {
  if (!children) return null
  if (!className && !solid) return children

  return (
    <div className={classnames(className, { [styles.solid]: !!solid })}>
      {children}
    </div>
  )
}

const Section = ({
  title,
  loading,
  loadingHeight,
  actions,
  children,
  solid,
  cancelMargin,
  disableUserSelection,
  titleUpperCase,
  className,
  titleClassName,
  contentWrapperClassName,
  actionsClassName
}: {
  title?: string,
  loading?: boolean,
  loadingHeight?: any,
  actions?: any,
  children?: React.ReactNode,
  solid?: boolean,
  cancelMargin?: boolean,
  disableUserSelection?: boolean,
  titleUpperCase?: boolean,
  className?: string,
  titleClassName?: string,
  contentWrapperClassName?: string,
  actionsClassName?: string
}) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const extraClassNames = {
    [styles.cancel_margin]: cancelMargin,
    [styles.disable_userSelection]: disableUserSelection
  }

  const showHeader = title || actions

  return (
    <section className={classnames(styles.section, className, extraClassNames)}>
      {showHeader && <header className={styles.header} ref={headerRef}>
        <Title title={title} className={titleClassName} titleUpperCase={titleUpperCase} />
        <Actions actions={actions} className={actionsClassName} />
      </header>}
      <ContentWrapper solid={solid} className={contentWrapperClassName}>
        {loading ? <Loading height={loadingHeight} /> : children}
      </ContentWrapper>
    </section>
  )
}

export default Section
