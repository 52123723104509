import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Radio } from 'antd'
import { parse } from 'query-string'
import { useQueryClient } from 'react-query'

import { NOTIFICATION_POLICY, NOTIFICATION_POLICY_LIST, useCreateNotificationChannelQuery, useCreateSlackConnectionQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import { useAccountRoutes } from 'containers/routes'
import { SCOPES, PermissionsGate } from 'features/permissions-gate'

import Input from 'components/antd/input'
import { Typography } from 'components/typography'
import Submit from 'components/antd/form/submit'

import styles from './styles.module.less'

const AddChannelForm = ({ policy }) => {
  const [form] = Form.useForm()
  const typeValue = Form.useWatch('type', form)
  const { code } = parse(location.search)
  const { policyId } = useParams()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { account } = useOrganization()
  const redirectUri = window.location.href

  const routes = useAccountRoutes()

  const { mutateAsync: createChannel, isLoading } = useCreateNotificationChannelQuery()
  const { mutate: createSlackConnection } = useCreateSlackConnectionQuery()

  const onSubmit = async (values) => {
    if (values.type === 'slack') return

    try {
      await createChannel({ payload: { ...values, policies: [policy?.id], ...(values?.type === 'email' ? { name: values?.uri } : {}) } })
      queryClient.invalidateQueries([NOTIFICATION_POLICY, policy?.id])
      queryClient.invalidateQueries([...NOTIFICATION_POLICY_LIST, account.id])
      history.push(routes.settings.notifications.policies.policy.url({ policyId: policy.id }))
    } catch { }
  }

  useEffect(() => {
    console.log('Trying to create a slack connection:', account.id, code)
    if (!account.id || !code) return
    // for connecting with slack channel
    const path = routes.settings.notifications.policies.newChannel.url({ policyId })
    const uri = `${window.location.protocol}//${window.location.host}${path}`

    console.log('Creating a slack connection with:', code, uri)
    createSlackConnection({ code, uri })
  }, [account.id, code])

  return (
    <>
      <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
        <Form form={form} initialValues={{ type: 'email' }} layout="vertical" onFinish={onSubmit}>
          <Form.Item label='type' name='type'>
            <Radio.Group>
              <Radio value='email'>Email</Radio>
              <Radio value='webhook'>Webhook</Radio>
              <Radio value='slack'>Slack</Radio>
              <Radio value='sns'>SNS</Radio>
            </Radio.Group>
          </Form.Item>

          {/* email */}
          {typeValue === 'email' && (<>
            <Form.Item name='uri' label='email'>
              <Input solid />
            </Form.Item></>)}

          {/* webhook */}
          {typeValue === 'webhook' && (
            <>
              <Form.Item name='name' label='Name' rules={[{ required: true }]}>
                <Input placeholder='name' solid />
              </Form.Item>
              <Form.Item name='uri' label='URI' rules={[{ required: true }]}>
                <Input placeholder='uri' solid />
              </Form.Item>
              <Form.Item name='secret' label='Secret'>
                <Input disabled placeholder='secret' solid />
              </Form.Item>
              <Typography.Title level={2}>Verifying Request Signature</Typography.Title>
              <Typography.Paragraph>
                Dashbird signs webhook requests (<p code>x-dashbird-signature</p> header) <br />
                so you can verify that requests are sent by us.
              </Typography.Paragraph>
              <Typography.Paragraph>
                Here&apos;s an example Javascript code to generate signatures for comparison:
              </Typography.Paragraph>
              <pre className={styles.code}>
                {'const crypto = require(\'crypto\')\n\n' +
                  'const signature = crypto.createHmac(\'sha256\', webhookSecret)\n\t' +
                  '.update(requestURL)\n\t' +
                  '.update(requestBody)\n\t' +
                  '.digest(\'hex\')'}
              </pre>
              <Typography.Paragraph>
                Read more from here:&nbsp;
                <a href={'https://dashbird.io/docs/quickstart/notification-channels/'} target={'_blank'} rel="noreferrer">
                  https://dashbird.io/docs/quickstart/notification-channels/
                </a>
              </Typography.Paragraph>
            </>)}

          {/* slack */}
          {typeValue === 'slack' && (
            <>
              <Typography.Paragraph>
                Connect with your Slack account:
              </Typography.Paragraph>
              <Typography.Paragraph>
                <a href={`https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=150752968164.479385330279&redirect_uri=${redirectUri}`}>
                  <img alt='Add to Slack' height='32' width='111' src='https://platform.slack-edge.com/img/add_to_slack.png' srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x' />
                </a>
              </Typography.Paragraph>

            </>)}

          {/* sns */}
          {typeValue === 'sns' && (
            <>
              <Form.Item name='name' label='Name' rules={[{ required: true }]}>
                <Input placeholder='name' solid />
              </Form.Item>
              <Form.Item name='uri' label='Topic ARN' rules={[{ required: true }]}>
                <Input placeholder='topic arn' solid />
              </Form.Item>
              <Typography.Title level={2}>Amazon SNS Access Policy</Typography.Title>
              <Typography.Paragraph>
                In order for Dashbird to be able to publish messages to the SNS topic, our account ID needs to be allowed to do so.
                <br />
                This can be done by adding a statement to the Amazon SNS topic access policy statements list:
              </Typography.Paragraph>
              <pre className={styles.code}>
                {'{\n' +
                  '      "Sid": "DashbirdSNSPublishPolicy",\n' +
                  '      "Effect": "Allow",\n' +
                  '      "Principal": {\n' +
                  '        "AWS": ["458024764010"]\n' +
                  '      },\n' +
                  '      "Action": "SNS:Publish",\n' +
                  '      "Resource": "arn:aws:sns:<your-region>:<your-account-id>:<your-topic-name>"\n' +
                  '}'}
              </pre>
              <Typography.Paragraph>
                Read more from here:&nbsp;
                <a href={'https://dashbird.io/docs/quickstart/notification-channels/'} target={'_blank'} rel="noreferrer">
                  https://dashbird.io/docs/quickstart/notification-channels/
                </a>
              </Typography.Paragraph>
            </>)}
          {typeValue !== 'slack' && <Submit text='Create channel' uppercase loading={isLoading} />}
        </Form>
      </PermissionsGate>
    </>
  )
}

export default AddChannelForm
