import React from 'react'
import { countBy } from 'lodash'

import Section from 'components/layout/content/section'
import { useAlarmsQuery, useAllResourcesQuery } from 'hooks/api'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { Item } from 'components/items'

const ViolatedResources = () => {
  const { getValue: selectedResources, setValue: setSelectedResources } = useQueryParams('resource', [])

  const { data: alarms, isLoading } = useAlarmsQuery()
  const { isLoading: resourcesLoading } = useAllResourcesQuery()

  const countByResource = !resourcesLoading && countBy(alarms, 'resource.id')

  const items = countByResource
    ? Object.keys(countByResource)?.map(resourceId => {
      const alarm = alarms?.find(item => item.resource.id === resourceId)

      return ({
        ...alarm?.resource,
        key: alarm?.resource?.id,
        count: countByResource[resourceId]
      })
    })
    : []

  return (
    <Section title='violated resources' titleUpperCase loading={isLoading || resourcesLoading}>
      {items?.length !== 0
        ? items?.sort((a, b) => b.count - a.count)?.map(item => (
          <Item.Resource
            {...item}
            key={item.id}
            total={alarms?.length}
            selected={selectedResources}
            onSelect={(id) => setSelectedResources(filterHelper(id, selectedResources))} />))
        : <p>No violated resourcees found</p>}
    </Section>
  )
}

export default ViolatedResources
