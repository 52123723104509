const prepareDynamic = (usage = 0, price = {}) => {
  const roundedUsage = Math.ceil(usage)
  return {
    usage: roundedUsage,
    unitPrice: price.amount,
    currency: price.currency,
    total: roundedUsage * price.amount
  }
}

const prepareData = (usage = {}, price = {}) => {
  return [
    {
      key: 'inventory',
      name: 'Resources',
      unit: 'resource',
      ...prepareDynamic(usage.inventory, price.inventory)
    },
    {
      key: 'dataingestion',
      name: 'Data ingestion',
      unit: 'GB',
      ...prepareDynamic(usage.dataIngestion, price.dataIngestion)
    }
  ]
}

export default prepareData
