import React from 'react'
import { countBy } from 'lodash'
import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import SeverityItem from 'components/overview-group-item'
import { SEVERITY_TYPES } from 'lib/event-constants'

import styles from './styles.module.less'

const SeverityGroups = ({ rules, loading, selected = [], onSelect }) => {
  const TOTAL_RULES = rules?.length || 0

  const rulesWithViolation = rules?.filter(rule => rule?.count > 0)
  const rulesCountWithViolation = rulesWithViolation?.length

  const countBySeverity = countBy(rulesWithViolation, 'severity')

  const items = [
    { severity: 'ok', count: TOTAL_RULES - rulesCountWithViolation },
    { severity: SEVERITY_TYPES.CRITICAL, count: countBySeverity[SEVERITY_TYPES.CRITICAL] },
    { severity: SEVERITY_TYPES.WARNING, count: countBySeverity[SEVERITY_TYPES.WARNING] },
    { severity: SEVERITY_TYPES.INFO, count: countBySeverity[SEVERITY_TYPES.INFO] }
  ]

  return (
    <Section
      title='status'
      className={styles.wrapper}
      titleUpperCase
      contentWrapperClassName={styles.severities_height}
      loading={loading}
      loadingHeight={1}
    >
      <Row gutter={6} className={styles.severities_wrapper}>
        {items.map((item) => (
          <Col span={6} key={item.severity}>
            <SeverityItem status={item.severity} id={item.severity} count={item.count} selected={selected} onSelect={onSelect} />
          </Col>
        ))}
      </Row>
    </Section>
  )
}

export default SeverityGroups
