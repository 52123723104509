import React from 'react'
import { reduce, tail } from 'lodash'

import { objectify } from 'lib/utils'
import Items from 'containers/inventory/shared/items'
import styles from './styles.module.less'
import { IconWithText, MemoryIcon, LinuxIcon, WindowsIcon } from 'components/icons'
import { GlobeAmericaIcon, MicroChipIcon, PlugIcon } from 'components/icons/font-awesome'

const ECSServers = ({ servers }) => {
  const getOSIcon = (type) => {
    switch (type) {
      case 'windows':
        return <WindowsIcon />
      case 'linux':
        return <LinuxIcon />
      default:
        return <LinuxIcon />
    }
  }

  const formatted = servers?.map(server => {
    const attributes = objectify(server.attributes)
    const properties = reduce(server.registeredResources, (acc, cur) => {
      acc[cur.name] = cur
      return acc
    }, {})

    return {
      id: server.ec2InstanceId,
      title: tail(server.arn.split('/')),
      description: (
        <div className={styles.attributes}>
          <div className={styles.properties}>
            <IconWithText icon={<MicroChipIcon />} text={properties.CPU.integerValue / 1024} />
            <IconWithText icon={<MemoryIcon />} text={`${properties.MEMORY.integerValue} MiB`} />
            <IconWithText icon={<PlugIcon />} text={properties.PORTS.stringSetValue.length} />
            <IconWithText icon={<GlobeAmericaIcon />} text={attributes['ecs.availability-zone']} />
          </div>
          <div className={styles.properties}>
            <IconWithText icon={getOSIcon(attributes['ecs.os-type'])} text={attributes['ecs.instance-type']} />
          </div>
        </div>
      )
    }
  })
  return (
    <Items items={formatted} />
  )
}

export default ECSServers
