import { findInventoryByType } from 'lib/resources/constants'

export const INVENTORY_FILTERS = [
  { key: 'service', filter: (selected, resource) => selected.service.includes(findInventoryByType(resource.type)?.service) },
  { key: 'region', filter: (selected, resource) => selected.region.includes(resource.region) },
  { key: 'delegation', filter: (selected, resource) => selected.delegation.includes(resource.delegation) },
  {
    key: 'group',
    filter: (selected, resource, idsInGroups) => idsInGroups?.includes(resource.id)
  }
]
