import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { ORGANIZATION_INVITATIONS, organizationsPaths as paths } from '../index'

const roles = ['admin', 'user', 'viewer']

const createInvitation = async (organizationId, payload) => {
  const permissions = payload.permissions ? payload.permissions.filter(permission => roles.includes(permission.role)) : []

  const data = await apiFetch(paths.invitations(organizationId), { method: 'POST', body: JSON.stringify({ ...payload, permissions }) })
  return data
}

export const useCreateInvitationQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ payload }) => createInvitation(organization.id, payload),
    {
      onSuccess: data => {
        queryClient.invalidateQueries([...ORGANIZATION_INVITATIONS, organization.id])
      }
    }
  )
}
