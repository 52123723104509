import numeral from 'numeral'

import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'
import { faBoxes } from '@fortawesome/pro-light-svg-icons/faBoxes'
import { faServer } from '@fortawesome/pro-light-svg-icons/faServer'

import { getSparklineByName, getLast } from '../helpers'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

export const ECS_CPU = `${INVENTORY_SERVICES.ECSCluster.service}-cpu`
export const ECS_MEMORY = 'memory'

const metrics = [{
  key: ECS_CPU,
  icon: faMicrochip,
  title: 'CPU',
  filter: (collection) => `${numeral(getLast(collection, 'ECS_CPU_UTILIZATION')).format('0.[00]')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'ECS_CPU_UTILIZATION'),
  selectable: true
},
{
  key: ECS_MEMORY,
  icon: faMemory,
  title: 'Memory',
  filter: (collection) => `${numeral(getLast(collection, 'ECS_MEM_UTILIZATION')).format('0a')}%`,
  subvalue: (collection) => getSparklineByName(collection, 'ECS_MEM_UTILIZATION'),
  selectable: true
},
{
  key: 'services',
  icon: faBoxes,
  title: 'Services',
  filter: (c, i, metadata) => metadata?.services?.length
}, {
  key: 'servers',
  icon: faServer,
  title: 'Servers',
  filter: (c, i, metadata) => metadata.servers.length
}]

export default metrics
