import React, { useState, useMemo, createContext } from 'react'
import classnames from 'classnames'
import { Drawer, DrawerProps, Tooltip } from 'antd'

import { useResize, Breakpoint } from 'hooks/resize'
import { ChevronLeftSolidIcon, ChevronRightSolidIcon } from 'components/icons'

import styles from './styles.module.less'

export const DrawerContext = createContext<{
  expanded: boolean,
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
  mobileView: boolean
}>({
  expanded: false,
  setExpanded: () => { },
  mobileView: false
})

const ToggleBtn = ({ toggle, expanded }) => {
  return (
    <div className={styles.sidebar_buffer}>
      <Tooltip title={expanded ? 'Shrink drawer' : 'Expand drawer'} placement='right' overlayClassName={styles.tooltip}>
        <button
          onClick={() => toggle(!expanded)}
          className={classnames(styles.toggle, { [styles.expanded]: !!expanded })}>
          {expanded ? <ChevronRightSolidIcon /> : <ChevronLeftSolidIcon />}
        </button>
      </Tooltip>
    </div>
  )
}

const StyledDrawer = (props: DrawerProps & { expandable: boolean }) => {
  const { className, size, expandable, children } = props
  const { isVisibleUntil, dimensions } = useResize()
  const [expanded, setExpanded] = useState(false)
  const mobileView = isVisibleUntil(Breakpoint.lg)

  const getWidth = () => {
    if (mobileView) return '100%'
    if (size === 'large') {
      return expanded ? dimensions.width : 700
    }
    return 500
  }

  const width = useMemo(() => getWidth(), [expanded])

  return (
    <DrawerContext.Provider value={{ expanded, setExpanded, mobileView }}>
      <Drawer
        open
        mask={false}
        closable={false}
        {...props}
        width={width}
        title={false}
        className={classnames(styles.drawer, className)}
      >
        {expandable && !mobileView && <ToggleBtn expanded={expanded} toggle={() => setExpanded(!expanded)} />}
        {children}
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default StyledDrawer
