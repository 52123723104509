import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { NOTIFICATION_POLICY, NOTIFICATION_POLICY_LIST, policyPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const deleteNotificationPolicyCondition = async (account, policy, condition) => {
  const data = await apiFetch(paths.condition(account, policy, condition), { method: 'DELETE' })
  console.log('🚀 ~ file: delete-policy-condition.js ~ line 10 ~ deleteNotificationPolicyCondition ~ data', data)

  return condition
}
export const useDeleteNotificationPolicyConditionQuery = () => {
  const { policyId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    ({ policyId, conditionId }) => deleteNotificationPolicyCondition(account.id, policyId, conditionId),
    {
      onMutate: ({ policyId, conditionId }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])

        const newPolicy = { ...previousPolicy, conditions: previousPolicy?.conditions?.filter(item => item.id !== conditionId) }
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)

        return { previousPolicy }
      },
      onError: (err, newItem, context) => {
        if (err) queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
      },
      onSuccess: (identity) => {
        queryClient.invalidateQueries([NOTIFICATION_POLICY, policyId])

        const policyList = queryClient.getQueryData([...NOTIFICATION_POLICY_LIST, account.id])
        if (!policyList) return

        const newPoliciesList = policyList?.map(item => {
          if (item.id !== policyId) return item
          return {
            ...item,
            conditions: item.conditions.filter(condition => condition.id !== identity)
          }
        })

        queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], newPoliciesList)
      }
    }
  )
}
