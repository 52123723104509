
import React from 'react'

import { Line } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { formatDuration } from 'components/charts/utils'
import { RDS_REPLICA_LAG } from 'containers/inventory/details/resource/metrics-tabs/services/rds-instance'

const RDSReplicaLagChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [RDS_REPLICA_LAG]: data.RDS_REPLICA_LAG
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [formatDuration(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={RDS_REPLICA_LAG}
      formatYTicks={value => formatDuration(value)}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
    >
      <Line dataKey={RDS_REPLICA_LAG} name='Replica lag' line='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />
    </ChartContainer>
  )
}

export default RDSReplicaLagChart
