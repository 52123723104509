import React from 'react'
import { round } from 'lodash'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { LAMBDA_COST } from 'containers/inventory/details/resource/metrics-tabs/services/lambda'

const CostChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({ [LAMBDA_COST]: data.INVOCATIONS_COST }, value => round(value, 3))

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[00]'
      span={span}
      tabKey={LAMBDA_COST}
      formatTab={value => `${Numeral(value).format('0.00')}$`}
    >
      <Bar dataKey={LAMBDA_COST} fill={colors('chart', 'primary')} barSize={4} name='Cost' unit='$' />
    </ChartContainer>
  )
}

export default CostChart
