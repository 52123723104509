import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'APPSYNC_REQUESTS',
  filter: {
    namespace: NAMESPACES.APPSYNC_REQUESTS,
    metric: 'requests',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_ERRORS',
  filter: {
    namespace: NAMESPACES.APPSYNC_REQUESTS,
    metric: 'errors',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_ERRORS_5XX',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.5xx',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_ERRORS_4XX',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'errors.4xx',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_LATENCY',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'latency',
    stats: [STATISTICS.AVERAGE],
    fill: 'gapfill'
  }
}]

export default metrics
