import React from 'react'
import Loading from 'components/layout/content/loading'

const WidgetPlaceholder = () => {
  return (
    <Loading height='100%' />
  )
}

export default WidgetPlaceholder
