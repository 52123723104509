import React from 'react'
import Numeral from 'numeral'

import { Line, Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { DYNAMODB_READS } from 'containers/inventory/details/resource/metrics-tabs/services/dynamodb'

const DynamoReadsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [DYNAMODB_READS]: data.DYNAMODB_READS,
    throttles: data.DYNAMODB_THROTTLES_READS,
    capacity: data.DYNAMODB_CAPACITY_READS
  })

  if (!metrics.length) return <Empty height={height} />
  const period = data.DYNAMODB_READS.period
  const formatted = metrics.map((metric) => {
    return {
      ...metric,
      [DYNAMODB_READS]: metric[DYNAMODB_READS] / period
    }
  })

  const tooltipFormatter = (value, name, props) => {
    return [`${Numeral(value).format(value < 1 ? '0.[00000]a' : '0.[00]a')}RCU`, undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0000]a'
      span={span}
      tabKey={DYNAMODB_READS}
      formatTab={value => `${Numeral(value).format('0.[000]a')} RCU`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={DYNAMODB_READS} stackId='a' fill={colors('chart', 'primary')} name='Reads' barSize={4} />
      <Bar dataKey='throttles' stackId='a' fill={colors('chart', 'red')} name='Throttles' barSize={4} />
      <Line dataKey='capacity' type='stepAfter' stroke={colors('chart', 'red')} strokeWidth={1} name='Capacity' dot={false} />
    </ChartContainer>
  )
}

export default DynamoReadsChart
