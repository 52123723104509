import { sum } from 'lodash'
import numeral from 'numeral'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons/faProjectDiagram'
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase'
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction'

import {
  aggregateSparkline, average, getSparklineByName,
  getSum,
  getValues
} from 'containers/inventory/details/resource/metrics-tabs/helpers'
import { formatDuration } from 'components/charts/utils'

export const APPSYNC_REQUESTS = 'appsync-requests'
export const APPSYNC_ERRORS = 'appsync-errors'
export const APPSYNC_LATENCY = 'appsync-latency'
export const APPSYNC_RESOLVERS = 'appsync-resolvers'
export const APPSYNC_DATA_SOURCES = 'appsync-data-sources'
export const APPSYNC_FUNCTIONS = 'appsync-functions'

const metrics = [{
  key: APPSYNC_REQUESTS,
  icon: faSigma,
  title: 'Requests',
  filter: (collection) => {
    const total = getSum(collection, 'APPSYNC_REQUESTS')
    return `${numeral(total).format('0.[00]a')}`
  },
  subvalue: (collection) => getSparklineByName(collection, 'APPSYNC_REQUESTS'),
  selectable: true
}, {
  key: APPSYNC_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const system = getSum(collection, 'APPSYNC_ERRORS')
    return numeral(system).format('0.[00]a')
  },
  subvalue: (collection) => {
    return aggregateSparkline(collection, sum, 'APPSYNC_ERRORS', 'APPSYNC_ERRORS_5XX', 'APPSYNC_ERRORS_4XX')
  },
  selectable: true
}, {
  key: APPSYNC_LATENCY,
  icon: faClock,
  title: 'Avg latency',
  filter: (collection) => {
    const values = getValues(collection, 'APPSYNC_LATENCY')?.map(item => item.value)
    return formatDuration(average(values))
  },
  subvalue: (collection) => getSparklineByName(collection, 'APPSYNC_LATENCY'),
  selectable: true
}, {
  key: APPSYNC_RESOLVERS,
  icon: faProjectDiagram,
  title: 'Resolvers',
  filter: (collection, item, metadata) => {
    return metadata.resolvers?.length
  }
}, {
  key: APPSYNC_DATA_SOURCES,
  icon: faDatabase,
  title: 'Data Sources',
  filter: (collection, item, metadata) => {
    return metadata.dataSources?.length
  }
}, {
  key: APPSYNC_FUNCTIONS,
  icon: faFunction,
  title: 'Functions',
  filter: (collection, item, metadata) => {
    return metadata.functionConfigurations?.length
  }
}]

export default metrics
