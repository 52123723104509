import React from 'react'
import JsonPrettifier from './prettifier'

import styles from './styles.module.less'

const PrettyPrinter = ({ jsonString = '{}', beforeJson, afterJson, children, collapsed, collapseStringAfterLength }) => {
  return (
    <React.Fragment>
      {beforeJson}
      <div className={styles.wrapper}>
        <JsonPrettifier
          jsonObj={jsonString}
          collapsed={collapsed}
          collapseStringAfterLength={collapseStringAfterLength} />
        {children}
      </div>
      {afterJson}
    </React.Fragment>
  )
}

export default PrettyPrinter
