import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { isNil, omitBy } from 'lodash'
import { Form, InputNumber } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import TargetSelector from 'features/target-selector'

import Section from 'components/layout/content/section'
import RadioSmall from 'components/antd/radio/small'

import { getResourcesByType } from 'lib/resources/filters'
import { SEVERITY_TYPES, UNIT_TYPES } from 'lib/event-constants'

import { useAllResourcesQuery, useUpdateInsightRule } from 'hooks/api'

import styles from './styles.module.less'

const RuleConfiguration = ({ rule }) => {
  const [inputWidth, setInputWidth] = useState(75)
  const [form] = Form.useForm()

  const { mutate: updateStatus, isLoading } = useUpdateInsightRule()
  const { data: resourcesAll } = useAllResourcesQuery()

  const defaultValues = {
    severity: rule?.severity || 'info',
    ...(!isNil(rule?.threshold) && { threshold: rule.threshold }),
    ...(rule?.excludes?.resources && { excludes: rule.excludes.resources })
  }

  const handleInputWidth = (value) => {
    if (!value) return
    const length = value?.toString()?.length

    if (length === 0) setInputWidth(75)
    if (length * 5 > 25) setInputWidth(40 + length * 5)
  }

  useEffect(() => handleInputWidth(rule.threshold), [])

  const onChange = ({ excludes, threshold, severity }) => {
    updateStatus(omitBy({
      excludes: excludes && { resources: [...(rule?.excluded?.resources || []), ...(excludes || [])] },
      threshold: Number(threshold) || undefined,
      severity,
      status: rule.status
    }, isNil))
  }

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  return (
    <Section solid contentWrapperClassName={styles.wrapper}>
      <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true }}>
        <Form form={form} layout='vertical' initialValues={defaultValues} onValuesChange={changed => onChange(changed)}>
          <Form.Item label='Severity' name='severity' className={styles.formItem}>
            <RadioSmall
              items={[
                { value: SEVERITY_TYPES.CRITICAL, label: SEVERITY_TYPES.CRITICAL, severity: SEVERITY_TYPES.CRITICAL },
                { value: SEVERITY_TYPES.WARNING, label: SEVERITY_TYPES.WARNING, severity: SEVERITY_TYPES.WARNING },
                { value: SEVERITY_TYPES.INFO, label: SEVERITY_TYPES.INFO, severity: SEVERITY_TYPES.INFO }
              ]}
            />
          </Form.Item>
          {!isNil(rule.threshold) && <div className={styles.row}>
            <Form.Item label='Threshold' name='threshold' trigger='onBlur' className={classnames(styles.formItem, styles.marginRight)}>
              <InputNumber controls={false} bordered={false} min={0} className={classnames(styles.threshold, styles.input_number)} onChange={handleInputWidth} style={{ width: inputWidth }} disabled={isLoading} />
            </Form.Item>
            <p>{UNIT_TYPES[rule?.unit] || ''}</p>
          </div>}
          <Form.Item name='excludes' label='Excluded Resources' className={classnames(styles.formItem, styles.marginRight)}>
            <TargetSelector placeholder='Select resources' resources={getResourcesByType(resourcesAll, rule?.resource)} bordered solid={false} />
          </Form.Item>
        </Form>
      </PermissionsGate>
    </Section>
  )
}

export default RuleConfiguration
