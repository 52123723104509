import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Section from 'components/layout/content/section'
import { useAccountRoutes } from 'containers/routes'
import { AwsServiceIcon } from 'components/icons'
import { findInventoryByType } from 'lib/resources/constants'
import ListItem from 'features/side-list/item'
import { DetailsBelow } from '../list/item'
import { useErrorsQuery, useErrorQuery, useAllResourcesQuery } from 'hooks/api'
import { Breakpoint, useResize } from 'hooks/resize'
import { useLimit } from 'hooks/limit'

import styles from './styles.module.less'
import LoadMore from 'components/items/load-more'

const SimilarErrorsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const { search } = useLocation()
  const { errorId } = useParams()
  const { isVisibleUntil } = useResize()
  const { getLimitedItems, isLimited, setEnabled } = useLimit(isVisibleUntil(Breakpoint.lg), 5)
  const { data: errors, isLoading } = useErrorsQuery()
  const { isLoading: resourcesLoading } = useAllResourcesQuery()
  const { data: activeError } = useErrorQuery(errorId)

  const filtered = errors
    ?.filter(item => item.group === activeError?.group && item.id !== activeError.id)
    ?.map(error => {
      const delegation = error?.resource?.delegationName ? `${error?.resource?.delegationName} |` : ''
      return ({
        icon: <AwsServiceIcon service={findInventoryByType(error?.resource?.type)?.service} />,
        id: error.id,
        title: error.name,
        onClick: () => history.push({ pathname: routes.errors.error.url({ errorId: error.id }), search }),
        left: `${delegation} ${error?.resource?.region?.toUpperCase()}`,
        detailsBelowTitle: true,
        details: <DetailsBelow loading={resourcesLoading} item={error} />
      })
    })

  const limitedItems = getLimitedItems(filtered)

  return (
    <Section title='Similar errors' titleUpperCase loading={isLoading}>
      {limitedItems?.length !== 0
        ? limitedItems?.map(error => <ListItem {...error} key={error.id} iconDivider className={styles.item} />)
        : <p>No similar errors found</p>
      }
      <LoadMore show={isLimited} setShow={setEnabled} />
    </Section>
  )
}

export default SimilarErrorsList
