import React, { useEffect, useState, createContext } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { sub } from 'date-fns'

import SplitPane from 'features/layout/split-pane'
import List from './list'
import Details from './details'
import Dashboard from './dashboard'
import ModifyGroup from 'features/resource-groups-list/edit'

export const ErrorMetricsContext = createContext(
  {
    dashboardStart: null,
    dashboardEnd: null,
    relativeSpan: null,
    onChangeDashboardDates: () => { }
  })

const Errors = () => {
  const { errorId } = useParams()
  const match = useRouteMatch()

  const [dashboardStart, setDashboardStart] = useState(sub(new Date(), { hours: 25 }))
  const [dashboardEnd, setDashboardEnd] = useState(new Date())
  const [dashboardRelativeSpan, setDashboardRelativeSpan] = useState(24 * 60)

  const onChangeDashboardDates = (start, end, relativeSpan) => {
    setDashboardStart(start)
    setDashboardEnd(end)
    setDashboardRelativeSpan(relativeSpan)
  }

  useEffect(() => {
    document.title = 'Dashbird.io - Errors'
  }, [])

  return (
    <ErrorMetricsContext.Provider value={{ dashboardStart, dashboardEnd, dashboardRelativeSpan, onChangeDashboardDates }}>
      <SplitPane
        left={<List />}
        right={
          <Switch>
            <Route path={`${match.path}/resource-groups/:groupId?/edit`}>
              <ModifyGroup view='errors' />
            </Route>
            <Route path={`${match.path}/resource-groups/new`}>
              <ModifyGroup view='errors' />
            </Route>

            <Route path={match.path}>
              {errorId
                ? <Details />
                : <Dashboard />}
            </Route>
          </Switch>
        }
      />
    </ErrorMetricsContext.Provider>
  )
}

export default Errors
