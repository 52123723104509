import moment from 'moment'
import { find, max } from 'lodash'
import { SLOTS } from 'lib/metric-constants'
import { objectify } from 'lib/utils'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

export const getLocalPeriod = (start, end) => {
  const ticks = 14
  const diff = Math.abs(moment(start).diff(end, 'minutes'))
  const slots = SLOTS.map(Number).sort((a, b) => b - a)
  const best = find(slots, slot => {
    const ticksInSlot = (diff / (slot / 60))
    return ticksInSlot >= ticks
  }) || 60
  return best
}

export const getMetricKey = (metric) => {
  return `event-${metric.metric}-${metric.stat}`
}

const buildELBTargetGroupResourceDimensions = (resource, allResources = []) => {
  const loadBalancerArns = (JSON.parse(objectify(resource?.attributes)?.loadBalancers) || []).map(lb => lb.loadBalancerArn)
  if (!loadBalancerArns?.length) return []
  const loadBalancers = Object.values(allResources).filter(resource => loadBalancerArns.includes(resource.arn))
  if (!loadBalancers?.length) return []
  return loadBalancers.map(elb => ({ id: resource.id, parent: { id: elb.id } }))
}

const buildELBResourceDimensions = (resource, allResources = [], metric = '') => {
  if (!metric.startsWith('targets')) return [{ id: resource.id }]

  const targetGroupArns = (JSON.parse(objectify(resource?.attributes)?.targetGroups) || []).map(lb => lb.targetGroupArn)
  if (!targetGroupArns?.length) return []
  const targetGroups = Object.values(allResources).filter(resource => targetGroupArns.includes(resource.arn))
  if (!targetGroups?.length) return []
  return targetGroups.map(tg => ({ id: resource.id, parent: { id: tg.id } }))
}

export const getMetricResourceDimensions = (resource, allResources = [], metric) => {
  if (resource.type === INVENTORY_SERVICES.ELBTargetGroup.type) return buildELBTargetGroupResourceDimensions(resource, allResources)
  if (
    resource.type === INVENTORY_SERVICES.ELBApplicationLoadBalancer.type ||
    resource.type === INVENTORY_SERVICES.ELBGatewayLoadBalancer.type ||
    resource.type === INVENTORY_SERVICES.ELBNetworkLoadBalancer.type
  ) return buildELBResourceDimensions(resource, allResources, metric)

  return [{ id: resource.id, ...(resource?.parent ? { parent: resource.parent } : []) }]
}

export const getCalculatedRange = (event) => {
  const start = moment(event.openedAt)
  const end = event.resolvedAt ? moment(event.resolvedAt) : moment()

  const diff = start.diff(end)
  const extra = max([diff * 0.1, 15 * 60 * 1000])

  return {
    start: start.subtract(event.checkingInterval).subtract(extra).valueOf(),
    end: event.resolvedAt ? end.add(extra).valueOf() : end.valueOf(),
    range: moment(event.resolvedAt ? end.add(extra) : end).diff(start.subtract(event.checkingInterval).subtract(extra), 'minutes')
  }
}
