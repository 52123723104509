import React from 'react'
import { Route, Switch } from 'react-router-dom'
import InviteContainer from 'containers/auth/invite'
import AuthLayout from 'containers/auth/layout'
import ForgotPasswordContainer from 'containers/auth/forgot-password'
import ResetPasswordContainer from 'containers/auth/reset-password'
import RegisterContainer from 'containers/auth/register'
import LoginProviderContainer from 'containers/auth/login/provider'
import LoginContainer from 'containers/auth/login'
import ConfirmContainer from 'containers/auth/confirm'

const UnauthorizedRoutes = () => {
  return (
    <AuthLayout>
      <Switch>
        <Route path='/auth/password/forgot' component={ForgotPasswordContainer} />
        <Route path='/auth/password/reset' component={ResetPasswordContainer} />
        <Route path='/auth/register' component={RegisterContainer} />
        <Route path='/auth/login/:provider' component={LoginProviderContainer} />
        <Route path='/auth/login' component={LoginContainer} />
        <Route path='/auth/confirm' component={ConfirmContainer} />
        <Route path='/invite' component={InviteContainer} />
        <Route path='/' component={LoginContainer} />
      </Switch>
    </AuthLayout>
  )
}

export default UnauthorizedRoutes
