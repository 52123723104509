import React from 'react'
import { Row, Col } from 'antd'
import Content from 'components/layout/content'
import ResourceGroups from 'features/resource-groups-list'
import OverviewGroupItem from 'components/overview-group-item'
import { DashboardIcon } from 'components/icons'

import { useDashboardsQuery, useAlarmsQuery, useInsightsQuery, useErrorsQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'

import styles from './styles.module.less'

const DashboardsOverview = () => {
  const routes = useAccountRoutes()
  const { data: dashboards, isLoading: loadingDashboards } = useDashboardsQuery()
  const { data: alarms, isLoading: loadingAlarms } = useAlarmsQuery()
  const { data: insights, isLoading: loadingInsights } = useInsightsQuery()
  const { data: errors, isLoading: loadingErrors } = useErrorsQuery()

  const eventsLoading = loadingAlarms || loadingInsights || loadingErrors

  return (
    <Content item title='Dashboards overview' breadcrumbs={['dashboards']} loading={loadingDashboards}>
      {/* List on dashboards as cards */}
      <Row gutter={[8, 8]}>
        {dashboards?.map(item => (
          <Col xl={4} lg={6} md={12} sm={24} key={item.id}>
            <OverviewGroupItem
              url={routes.dashboards.dashboard.url({ dashboardId: item.id })}
              large
              title={item.name}
              count={null}
              icon={<DashboardIcon className={styles.icon} />} />
          </Col>
        ))}
      </Row>

      {/* List of resource groups */}
      <ResourceGroups view='dashboards' className={styles.resource_groups} alarms={alarms} insights={insights} errors={errors} loading={eventsLoading} />
    </Content>
  )
}

export default DashboardsOverview
