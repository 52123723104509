import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'S3_BUCKET_OBJECTS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.objects',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'S3_BUCKET_SIZE_STANDARD',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.size.standard',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'S3_BUCKET_SIZE_STANDARDIA',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.size.standardia',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'S3_BUCKET_SIZE_ONEZONEIA',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.size.onezoneia',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'S3_BUCKET_SIZE_GLACIER',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.size.glacier',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: 'S3_BUCKET_SIZE_DEEPARCHIVE',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'bucket.size.deeparchive',
    stats: [STATISTICS.AVERAGE]
  }
}]

export default metrics
