import { times } from 'lodash'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Skeleton } from 'antd'
import Empty from '../empty'
import Request from './request'

import styles from './styles.module.less'
const AppsyncRequests = ({ items, filter, loading }) => {
  const { pathname } = useLocation()
  if (loading) {
    return times(6).map(index => (
      <Skeleton key={index} active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />
    ))
  }

  if (!items?.length) return <Empty filter={filter} />

  const urlParams = pathname?.includes('request') ? pathname.split('/') : []
  const selectedRequestId = urlParams[urlParams.length - 1]

  const requests = items.map(item => <Request key={item.eventId} item={item} selectedRequestId={selectedRequestId} />)
  return <div className={styles.items}>{requests}</div>
}

export default AppsyncRequests
