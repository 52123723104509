import { paths } from '../index'

export const LOG_SOURCE = 'log-sources-subscription'
export const SUBSCRIPTION_RETRY = [LOG_SOURCE, 'retry']

export * from './get-subscription'
export * from './retry-subscription'

export const getLogGroupName = (name) => `/aws/lambda/${name}`

export const logSourcesPaths = {
  filter: (path, query) => `${path}?${query}`,
  logSource: (account, delegation) => `${paths.account(account)}/log-sources/subscription/${delegation}`,
  logSourceRetry: (account, delegation) => `${logSourcesPaths.logSource(account, delegation)}/retry`
}
