import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import billing from './billing.reducer'
import metric from './metric.reducer'
import waiting from './waiting.reducer'

export default (history) => combineReducers({
  router: connectRouter(history),
  billing,
  metric,
  waiting
})
