import React from 'react'
import { Bar } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { LAMBDA_ERRORS } from 'containers/inventory/details/resource/metrics-tabs/services/lambda'

const ErrorsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [LAMBDA_ERRORS]: data.INVOCATIONS_FATAL_ERRORS,
    detectedErrors: data.INVOCATIONS_DETECTED_ERRORS
  })

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0'
      span={span}
      tabKey={LAMBDA_ERRORS}
      formatTab={value => `${value}`}>
      <Bar dataKey='detectedErrors' fill={colors('chart', 'red')} name='Detected Errors' barSize={4} />
      <Bar dataKey={LAMBDA_ERRORS} fill={colors('chart', 'redDark')} name='Fatal Errors' barSize={4} />
    </ChartContainer>
  )
}

export default ErrorsChart
