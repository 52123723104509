import { throwAsyncError } from 'hooks/nodes/transform/error'
import { isOpenTelemetry, isRequest, isRuntime, isTLS } from 'hooks/nodes/transform/checkers'

const getDynamoDBContent = (node) => {
  if (node.aws?.table_name) {
    return `${node.name} ${node.aws?.operation} ${node.aws?.table_name}`
  } else if (node.aws?.table_names) {
    return node.aws?.table_names.length > 1
      ? `${node.name} ${node.aws?.operation} ${node.aws?.table_names[0]}, ...`
      : `${node.name} ${node.aws?.operation} ${node.aws?.table_names[0]}`
  }

  return `${node.name} ${node.aws?.operation}`
}

const getContent = (node, parent) => {
  switch (node.name) {
    case 'Lambda':
      return `${node.name} ${node.aws?.operation} ${node.aws?.function_name || ''}`
    case 'DynamoDB':
      return getDynamoDBContent(node)
    case 'SNS':
      return `${node.name} ${node.aws?.operation} ${node.metadata?.default?.['messaging.destination'] || ''}`
    case 'SES':
      return 'SES'
    case 'S3':
      return 'S3'
    case 'SSM':
      return `${node.name} ${node.aws?.operation} | ${node.aws?.request_id}`
    case 'Events':
      return `AWS EventBridge ${node.aws?.operation} | ${node.aws?.request_id}`
    case 'Initialization':
    case 'Invocation':
    case 'Overhead':
      return node.aws?.function_arn
    case 'tls':
      return node.metadata?.http?.tls?.negotiated_protocol
    case 'dial':
      return node.metadata?.http?.connect?.network
    case 'dns':
    case 'connect':
    case 'request':
    case 'attempt':
    case 'marshal':
    case 'unmarshal':
    case 'comprehend':
    case 'response':
      return ''
  }

  // Check for retries
  if (node.error && node.name.match(/Attempt #\d+/)) {
    return node.name
  }

  switch (node.origin) {
    case 'Dashbird':
      return 'Segment Container'
    case 'AWS::ApiGateway::Stage':
    case 'AWS::Lambda':
    case 'AWS::Lambda::Function':
    case 'AWS::StepFunctions::StateMachine':
      return node.origin
  }

  // Some opentelemetry garbage
  const metadata = node.metadata?.default
  const serviceName = metadata?.['aws.service.name']
  switch (serviceName) {
    case 'DynamoDB':
      return `${serviceName} ${metadata?.['db.operation']} ${metadata?.['db.name']}`
  }

  const runtime = isRuntime(node)
  if (runtime) {
    const version = node.service?.runtime_version
    const { method } = node.http?.request || {}
    const { path } = node.metadata?.default || {}
    return `${version} | ${method} ${path}`
  }

  const request = isRequest(node)
  if (request) {
    const { method, url } = request
    if (method && url) return `${method} ${url}`
    if (url) return `${url}`
  }

  // Probably an opentelemetry wrapper
  const opentelemetry = isOpenTelemetry(node)
  if (opentelemetry) return opentelemetry

  const tls = isTLS(node)
  if (tls) return tls

  // all of these can be removed when we decide not to not implemented errors anymore
  if (parent.service === 'stepfunctions') return node.name
  if (parent.service === 'lambda') return node.name
  if (node.namespace === 'local') return node.name

  throwAsyncError('getContent for node not implemented', node)

  return node.name
}

export { getContent }
