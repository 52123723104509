import React, { memo } from 'react'
import classnames from 'classnames'

import styles from './styles.module.less'

const Edge = memo(({ edge, to }: { edge: any, to: any }) => {
  return (
    <polyline
      points={edge?.points?.map((point) => {
        return `${point.x}, ${point.y}`;
      })
        .join(" ")}
      className={classnames(styles.edge)}
      pathLength={1}
      {...(!to.isVirtual && { markerEnd: "url(#arrowhead)" })}
    />
  )
})

export default Edge
