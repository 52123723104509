import React from 'react'
import { Checkbox } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { SEVERITY_TYPES } from 'lib/event-constants'
import { filterInventoryByNamespace } from 'lib/resources/constants'

import { AlarmIcon, AwsServiceIcon } from 'components/icons'
import Select from 'components/antd/select'
import Actions from './actions'

import styles from './styles.module.less'

const AlarmForm = ({ mutations, condition, alarms }) => {
  const handlers = mutations({ condition })

  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <div className={styles.title}>
          <AlarmIcon /><span>Alarms</span>
        </div>
        <div className={styles.stat}>
          <span className={styles.name}>alarms</span>
          <span className={styles.value}>{condition.targets.length ? condition.targets.length : 'ALL'}</span>
        </div>
        <div className={styles.actions}>
          <Actions item={condition} handlers={handlers} />
        </div>
      </div>
      <section>
        <span className={styles.label}>Severities</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Checkbox.Group
            options={[SEVERITY_TYPES.CRITICAL, SEVERITY_TYPES.WARNING]}
            defaultValue={condition.severities}
            onChange={(values) => handlers.update({ severities: values })}
          />
        </PermissionsGate>
      </section>
      <section>
        <span className={styles.label}>Filter by alarms</span>
        <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
          <Select
            mode='multiple'
            optionFilterProp='title'
            defaultValue={condition.targets}
            placeholder='Select alarms'
            solid
            onSelect={(value) => handlers.targets(value).add()}
            onDeselect={(value) => handlers.targets(value).remove()}
            options={alarms?.map(item => ({ id: `alarm:${item.id}`, value: `alarm:${item.id}`, title: item.name, service: filterInventoryByNamespace(item?.namespace)[0]?.service }))}
            optionRender={({ item }) => (
              <span className={styles.option}>
                <AwsServiceIcon service={item.service} size='small' className={styles.icon} />
                <span className={styles.title}>{item.title}</span>
              </span>
            )}
          />
        </PermissionsGate>
      </section>
    </div>
  )
}

export default AlarmForm
