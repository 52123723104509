import React from 'react'
import { Row, Col, Typography } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const Tax = ({ amount, currency } = {}) => {
  if (!amount) return null
  return (
    <Row>
      <Col span={12}>
        <Text>Tax</Text>
      </Col>
      <Col span={12}>
        <Text>{formatStripePrice(amount, currency)}</Text>
      </Col>
    </Row>
  )
}

export default Tax
