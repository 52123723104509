import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { throwAsyncError } from 'hooks/nodes/transform/error'

const getService = (node) => {
  const serviceName = node.aws?.service?.name
  if (serviceName === 'DynamoDB') {
    return INVENTORY_SERVICES.DynamoDB.service
  }

  switch (node.name) {
    case 'Lambda':
      return INVENTORY_SERVICES.Lambda.service
    case 'DynamoDB':
      return INVENTORY_SERVICES.DynamoDB.service
    case 'SNS':
      return INVENTORY_SERVICES.SNS.service
    case 'S3':
      return INVENTORY_SERVICES.S3.service
  }

  const metadata = node.metadata?.default
  const metadataServiceName = metadata?.['aws.service.name']
  switch (metadataServiceName) {
    case 'DynamoDB':
      return INVENTORY_SERVICES.DynamoDB.service
  }

  switch (node.origin) {
    case 'AWS::Lambda':
    case 'AWS::Lambda::Function':
      return INVENTORY_SERVICES.Lambda.service
    case 'AWS::StepFunctions::StateMachine':
      return INVENTORY_SERVICES.StepFunctions.service
    case 'AWS::ApiGateway::Stage':
      // X-Ray only supports tracing for REST APIs through API Gateway.
      // https://docs.aws.amazon.com/xray/latest/devguide/xray-services-apigateway.html
      return INVENTORY_SERVICES.APIGatewayRest.service
    case 'AWS::S3::Bucket':
      return INVENTORY_SERVICES.S3.service
    // Our own custom segment
    case 'Dashbird':
    case undefined:
      return
    default:
      // throw async error so Sentry catches it and notifies us about a new possible service in traces
      throwAsyncError('getService for node not implemented', node)
  }
}

export { getService }
