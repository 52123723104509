import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty, sortBy, groupBy } from 'lodash'

import List from 'features/side-list'
import ListItem from 'features/side-list/item'
import ListEmpty from 'features/side-list/empty'
import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'

import { AwsServiceIcon } from 'components/icons'
import { findInventoryByType } from 'lib/resources/constants'
import { NameAlphabetical, OccurrenceCountLastDay, OccurrenceLast, ServiceType } from 'features/side-list/sorter'

import { useAccountRoutes } from 'containers/routes'
import search from 'lib/list-search'
import { hasMoreDelegations } from 'lib/resources/transformations'
import ResourceGroupListFilters from './filters'
import { useDelegationsQuery } from 'hooks/api'

const Header = ({ filters, onFilterChange, sorters, onSorterChange, selectedSorter, delegations, availableServices }) => {
  const [filtersVisible, setFiltersVisible] = useState(false)

  const routes = useAccountRoutes()
  const { groupId } = useParams()

  return (
    <ListHeader
      sorters={sorters}
      onSorterChange={onSorterChange}
      selectedSorter={selectedSorter}
      overviewURL={routes.resourceGroups.group.url({ groupId })}
      handleFiltersClick={() => setFiltersVisible(!filtersVisible)}
    >
      {filtersVisible &&
        <ResourceGroupListFilters filters={filters} onFilterChange={onFilterChange} delegations={delegations} availableServices={availableServices} />
      }
    </ListHeader>
  )
}

const Item = ({ data, index, style }) => {
  const item = data?.items[index]
  const service = findInventoryByType(item?.type)

  const delegation = item?.delegationName ? `| ${item.delegationName}` : ''

  return <div style={style}>
    <ListItem
      key={item.id}
      icon={<AwsServiceIcon service={service?.service} />}
      title={item?.title}
      left={`${service?.shortTitle} | ${item.region.toUpperCase()} ${delegation}`}
      onClick={() => data?.onSelect(item)}
      selected={item.id === data?.selected}
    />
  </div>
}

const ResourceList = ({ loading, onResourceSelect, selected, resources }) => {
  const { data: delegations } = useDelegationsQuery()
  const sorters = [OccurrenceLast, OccurrenceCountLastDay, NameAlphabetical, ServiceType]
  const [searchItems, setSearchItems] = useState(resources)
  const [query, setQuery] = useState('')
  const [sorter, setSorter] = useState(sorters[0])
  const [servicesFilters, setServicesFilters] = useState([])

  const moreDelegations = hasMoreDelegations(resources)

  const searchResources = () => {
    if (loading) return

    const results = search(resources, query, servicesFilters)
    setSearchItems(results)
  }

  const sortResources = () => {
    setSearchItems(sortBy(searchItems, sorter.apply))
  }

  const onFilterChange = (item) => {
    if (item === 'clear') {
      setServicesFilters([])
    } else if (!isEmpty(servicesFilters.filter(filt => filt.id === item.id))) {
      setServicesFilters(servicesFilters.filter(filter => filter.id !== item.id))
    } else {
      setServicesFilters(servicesFilters.concat([item]))
    }
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  useEffect(searchResources, [resources, query, servicesFilters])
  useEffect(sortResources, [sorter])

  const resourcesByType = groupBy(resources, 'type')
  const availableServices = Object.keys(resourcesByType).map(item => findInventoryByType(item)).filter(item => item)

  return (
    <List
      loading={loading}
      renderItem={Item}
      selected={selected}
      onSelect={onResourceSelect}
      items={searchItems}
      search={<ListSearch onSearchChange={setQuery} />}
      header={<Header
        filters={servicesFilters}
        onFilterChange={onFilterChange}
        sorters={sorters}
        onSorterChange={onSorterChange}
        selectedSorter={sorter.key}
        delegations={moreDelegations ? delegations : null}
        availableServices={availableServices}
      />}

      renderEmpty={<ListEmpty title='No resources found' />}
    />
  )
}

export default ResourceList
