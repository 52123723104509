import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { AngleDownIcon } from 'components/icons'
import styles from './styles.module.less'

const Collapsable = ({ className, bordered, gap, content, onClick, selected, size, children }) => {
  const [collapsed, setCollapse] = useState(!selected)

  const handleToggle = () => {
    onClick && onClick()
    setCollapse(!collapsed)
  }

  return (
    <div className={classnames(styles.collapsable, {
      [styles.bordered]: !!bordered,
      [styles[`gap_${gap}`]]: !!gap
    })}>
      <button onClick={handleToggle} className={className}>
        {children}
        <div className={styles.arrow}>
          <AngleDownIcon
            className={classnames(styles.transition, {
              'fa-rotate-180': !collapsed,
              [styles[`size_${size}`]]: !!size
            })}
          />
        </div>
      </button>
      {!collapsed && <div className={classnames(styles.content, { [styles.selected]: !!selected })}>{content}</div>}
    </div>
  )
}

const Container = ({ url, onClick, content, bordered, gap, size, selected, className, children }) => {
  if (url) return <Link className={className} to={url}>{children}</Link>
  if (content) return <Collapsable className={className} bordered={bordered} gap={gap} content={content} onClick={onClick} selected={selected} size={size}>{children}</Collapsable>
  if (onClick) return <button className={className} onClick={onClick} >{children}</button>

  return <div className={className}>{children}</div>
}

const Icon = ({ separator, children }) => {
  if (!children) return null

  return (
    <div className={classnames(styles.icon, { [styles.separate]: !!separator })}>
      {children}
    </div>
  )
}

const Item = ({ size, gap, bordered, outlined, icon, separator, url, hoverable, selected, count, total, content, onClick, layout, status, solid, className, statusBar, children }) => {
  return (
    <Container
      url={url}
      content={content}
      bordered={bordered}
      gap={gap}
      onClick={onClick}
      selected={selected}
      size={size}
      className={classnames(styles.item, {
        [styles[`size_${size}`]]: !!size,
        [styles.bordered]: !!bordered,
        [styles.progress]: count && total,
        [styles.hoverable]: hoverable !== false,
        [styles[`gap_${gap}`]]: !!gap,
        [styles[`status_${status}`]]: !!status,
        [styles.selected]: selected,
        [styles.outlined]: outlined,
        [styles.solid]: solid,
        [styles[`status_bar_${statusBar}`]]: !!statusBar
      }, className)}
    >
      {count && total ? <div style={{ width: `${count / total * 100}%` }} className={styles.progressbar} /> : null}
      <Icon separator={separator}>{icon}</Icon>
      <div className={classnames(styles.content, {
        [styles.rows]: layout === 'rows',
        [styles.columns]: layout !== 'rows'
      })}>
        {children}
      </div>
    </Container>
  )
}

export { Item }
