import { useMutation } from 'react-query'
import { Auth } from '@aws-amplify/auth'

import { signinErrors } from 'containers/auth/messages'
import { useUser } from '../context/user-context'

export const useLogin = () => {
  const { setUser, setSession, setAuthenticated, setAuthMessage } = useUser()

  return useMutation(
    ({ email, password }) => Auth.signIn(email, password),
    {
      onSuccess: cognitoUser => {
        setUser(cognitoUser)
        setAuthMessage(false)

        if (!cognitoUser?.challengeName) {
          setAuthenticated(true)
          setSession(cognitoUser?.signInUserSession)
        }
        return cognitoUser
      },
      onError: err => {
        return setAuthMessage(signinErrors(err))
      }
    }
  )
}

export const useConfirmLogin = () => {
  const { user, refreshCurrentSession, setAuthMessage } = useUser()

  return useMutation(
    ({ code }) => Auth.confirmSignIn(user, code, user.challengeName),
    {
      onSuccess: async () => {
        await refreshCurrentSession()
        setAuthMessage(false)
      },
      onError: (err) => {
        setAuthMessage({ message: err.message })
      }
    }
  )
}
