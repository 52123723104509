import { useQuery } from 'react-query'
import queryString from 'query-string'

import { apiFetch } from 'lib/action-helpers'
import { INVOICE, billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getNextInvoice = async (organization, identity) => {
  const query = queryString.stringify({ subscriptionId: identity })

  const data = await apiFetch(paths.filter(paths.invoicesUpcoming(organization), query))
  return data
}

export const useGetNextInvoiceQuery = ({ subscriptionId }) => {
  const { organization } = useOrganization()

  return useQuery(
    [...INVOICE, subscriptionId],
    () => getNextInvoice(organization?.id, subscriptionId),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!subscriptionId,
      retry: false
    }
  )
}
