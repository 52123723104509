import { SEGMENT_GROUP } from 'hooks/nodes/transform/get-service-group'

const REGION_COLORS = {
  'us-east-1': '#638475',
  'ap-northeast-1': '#90e39a',
  'ap-northeast-2': '#ddf093',
  'ap-northeast-3': '#f6d0b1',
  'ap-east-1': '#ce4760',
  'ap-south-1': '#b47eb3',
  'ap-southeast-1': '#ffd5ff',
  'ap-southeast-2': '#92d1c3',
  'ca-central-1': '#8bb8a8',
  'eu-central-1': '#3ba7b3',
  'eu-north-1': '#2f4858',
  'eu-west-1': '#f6ae2d',
  'eu-west-2': '#f26419',
  'eu-west-3': '#e3b505',
  'sa-east-1': '#95190c',
  'us-east-2': '#610345',
  'us-west-1': '#107e7d',
  'us-west-2': '#e0777d'
}

const SERVICE_COLORS = {
  sqs: '#1b3022',
  ecs: '#7261a3',
  apigateway: '#42033d',
  dynamodb: '#f06449',
  lambda: '#0eb1d2',
  kinesis: '#322d57',
  stepfunctions: '#FF69B4'
}

const TYPE_COLORS = {
  'AWS::SQS::Queue': '#586994',
  'AWS::ECS::Service': '#7d869c',
  'AWS::ECS::TaskDefinition': '#a2abab',
  'AWS::ECS::Cluster': '#b4c4ae',
  'AWS::ApiGateway::Resource': '#828466',
  'AWS::ApiGateway::RestApi': '#6a687a',
  'AWS::ApiGateway::Stage': '#3e4c5e',
  'AWS::DynamoDB::GlobalSecondaryIndex': '#2c3d55',
  'AWS::DynamoDB::Table': '#36827f',
  'AWS::Lambda::Function': '#e4c562',
  'AWS::Kinesis::Stream': '#322d57',
  'AWS::KinesisFirehose::DeliveryStream': '#69b3f2',
  'AWS::StepFunctions::StateMachine': '#a1f088'
}

const TRACE_COLORS = {
  lambda: '#f58d06',
  'AWS::Lambda': '#f58d06',
  'AWS::Lambda::Function': '#fac682',
  'AWS::ApiGateway::Stage': '#8b91e3',
  'AWS::DynamoDB::Table': '#66b2ff',
  'AWS::DynamoDB': '#66b2ff',
  DynamoDB: '#66b2ff',
  'AWS::SQS::Queue': '#fd9abe',
  'AWS::stepfunctions': '#e63978',
  'AWS::SNS': '#bb7799',
  'AWS::S3::Bucket': 'rgb(254,99,71, 0.6)',
  'AWS::KMS': 'yellow',
  'AWS::AppSync::GraphQLAPI': '#cd2664',
  S3: '#E35342',
  SES: '#D8A741',
  SQS: '#D9A741',
  STS: '#779C3C',
  SSM: '#CC2264',
  'hooks.slack.com:443': '#611F69',
  opentelemetry: '#4F62AD',
  Invocation: '#fac682',
  Initialization: '#fac682',
  Lambda: '#fac682',
  Overhead: '#fac682',
  ColdStart: 'rgba(76, 72, 199, 0.3)',
  'Dwell Time': '#f58d06',
  'cognito-idp': '#C17B9F',
  CognitoIdentityProvider: '#C17B9F',
  CloudWatch: '#F64684',
  SNS: '#C27C9E'
}

const SERVICE_GROUP = {
  [SEGMENT_GROUP.AWS_ANALYTICS]: '#6035bc',
  [SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION]: '#c3195d',
  [SEGMENT_GROUP.AWS_COMPUTE]: '#e97c0b',
  [SEGMENT_GROUP.AWS_DATABASE]: '#4c70f2',
  [SEGMENT_GROUP.AWS_DEVELOPER_TOOLS]: '#3b48cc',
  [SEGMENT_GROUP.AWS_NETWORKING_CONTENT_DELIVERY]: '#E23574',
  [SEGMENT_GROUP.AWS_STORAGE]: '#408624',
  [SEGMENT_GROUP.DASHBIRD]: '#4a46c6'
}

export const CHART_COLORS = {
  grid: '#dbdcdb',
  text: '#989898',
  textDark: '#1a1a1a',
  red: '#fe2c02',
  primaryDark: '#3633ab',
  primary: '#4a46c6',
  primaryLight: '#8b91e3',
  primaryWithOpacity: 'rgba(76, 72, 199, 0.3)',
  green: '#7ed321',
  redLight: '#ffb7b7',
  redDark: '#800000',
  redWithOpacity: 'rgba(255, 183, 183, 0.3)',
  yellow: '#FAC682',
  yellowLight: '#FAC682',
  backgroundPrimary: '#fff',
  backgroundSecondary: 'rgba(235, 235, 244, 0.5)'

}

const COLORS = {
  region: REGION_COLORS,
  service: SERVICE_COLORS,
  type: TYPE_COLORS,
  trace: TRACE_COLORS,
  chart: CHART_COLORS,
  segment: SERVICE_GROUP
}

export default (type = 'region', value = 'us-east-1') => {
  if (COLORS[type.toLowerCase()]?.[value]) return COLORS[type.toLowerCase()][value]
  if (type === 'trace') return '#749B3E' // Xray service color
  return '#8b91e3'
}
