
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { ALARMS_RULES_LIST, alarmsPaths as paths } from './index'
import { useAlarmsQuery } from './get-alarms'

const getAlarmsRules = async (account) => {
  const data = await apiFetch(paths.alarms(account))
  return data?.data
}

export const useAlarmsRulesQuery = () => {
  const { account } = useOrganization()
  const { data: violatedAlarms } = useAlarmsQuery()

  const mapViolationsToAlarmRules = useCallback(data => {
    const result = data?.map(alarm => {
      const violations = violatedAlarms?.filter(violation => violation?.rule === alarm.id) || []
      return {
        ...alarm,
        violations,
        count: alarm?.disabled ? 0 : violations?.length
      }
    })
    return result
  }, [violatedAlarms?.length])

  return useQuery(
    [...ALARMS_RULES_LIST, account?.id],
    () => getAlarmsRules(account?.id),
    {
      staleTime: Infinity,
      select: mapViolationsToAlarmRules,
      enabled: !!account?.id,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false // take away later
    }
  )
}
