import React, { useState } from 'react'
import { Slider } from 'antd'

import styles from './styles.module.less'

export const durationMarkToTime = (value) => {
  if (value < 1000) return value
  else if (value >= 1590) return value === 1600 || value === 1590 ? 60000 : ((value - 1600) / 100 + 1) * 60000
  else return value % 1000 === 0 ? 1000 : ((value - 1000) / 10 + 1) * 1000
}

export const formatDuration = (value) => {
  if (value < 1000) {
    return `${value}ms`
  } else if (value >= 1590) {
    return value === 1600 || value === 1590 ? '1min' : `${(value - 1600) / 100 + 1}min`
  } else {
    return value % 1000 === 0 ? '1s' : `${(value - 1000) / 10 + 1}s`
  }
}

const data = {
  duration: {
    marks: {
      0: '',
      1000: '1s',
      1600: '1min',
      2000: '5min',
      2500: '10min',
      3000: '15min'
    },
    tooltipMapper: value => formatDuration(value),
    valueMapper: (value, setStep, stepChanged) => {
      if (value[0] >= 1600 && stepChanged === 0) setStep(100)
      else if (value[1] >= 1600 && stepChanged === 1) setStep(100)
      else if (value[0] >= 1000 && stepChanged === 0) setStep(10)
      else if (value[1] >= 1000 && stepChanged === 1) setStep(10)
      else if (value[0] < 1000 && stepChanged === 0) setStep(1)
      else if (value[1] < 1000 && stepChanged === 1) setStep(1)
    }
  },
  percentage: {
    marks: {
      25: '25%',
      50: '50%',
      75: '75%',
      100: '100%'
    },
    valueMapper: (value) => value,
    tooltipMapper: value => `${value}%`
  }
}

const decideChangedStep = (value, prevStepValues) => {
  if (prevStepValues[0] === value[0]) return 1
  else if (prevStepValues[1] === value[1]) return 0
}

const FilterSlider = ({ setSliderValue, defaultValue, type = 'duration' }) => {
  const [step, setStep] = useState(1)
  const [prevStepValues, setPrevStepValues] = useState(defaultValue)

  return (
    <div className={styles.filter_container}>
      <Slider marks={data[type]?.marks} defaultValue={defaultValue} className={styles.slider}
        tipFormatter={data[type]?.tooltipMapper} step={step} max={type === 'duration' ? 3000 : 100}
        onChange={value => {
          const stepChanged = decideChangedStep(value, prevStepValues)

          data[type]?.valueMapper(value, setStep, stepChanged)
        }}
        onAfterChange={(value) => {
          setSliderValue(value)
          setPrevStepValues(value)
        }} range
      />
    </div>
  )
}

export default FilterSlider
