import React from 'react'

import { ExternalLink } from 'components/icons'
import IconButton from 'components/buttons/icon'

const ExternalLinkButton = ({ link, title, children }) => {
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      {title && <IconButton icon={<ExternalLink />} title={title} />}
      {children}
    </a>
  )
}

export default ExternalLinkButton
