import React from 'react'

import { useAllResourcesQuery, useRequestQuery, useResolversQuery, useRequestTracesQuery } from 'hooks/api'
import { hasMoreDelegations } from 'lib/resources/transformations'
import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import IconButton from 'components/buttons/icon'
import { AwsServiceIcon, CloseIcon } from 'components/icons'
import classnames from 'classnames'
import { findInventoryById } from 'lib/resources/constants'
import { useParams } from 'react-router-dom'
import ContentError from 'components/layout/content/error'
import RequestMetrics from './metrics'
import Trace from 'features/invocations/details/x-ray'
import XRayLink from 'features/invocations/details/x-ray/link'
import Section from 'components/layout/content/section'
import Query from './query'
import ResolverErrors from './resolver-errors'

const renderError = error => {
  if (!error) return null
  return <ContentError title='Request was not found' />
}

const renderEmpty = () => {
  return <Empty title='No request to show.' />
}

const RequestContent = ({ resource, onClose, className, type }) => {
  const { requestId } = useParams()

  const { data: resources } = useAllResourcesQuery()
  const { data: request, isLoading, isError } = useRequestQuery(requestId)
  const hasTraceId = !!request?.xrayTraceId
  const { data: resolvers, isLoading: isLoadingResolvers } = useResolversQuery({ requestId: request?.requestId, isError: true })
  const { data: traceData, isLoading: isLoadingTraces } = useRequestTracesQuery(request?.eventId, resource?.id, hasTraceId)
  const delegationName = resources && hasMoreDelegations(Object.values(resources)) && resource?.delegationName

  const traces = traceData?.traces
  return (
    <Content
      breadcrumbs={[delegationName, resource?.region]}
      loading={isLoading}
      item={request}
      title={`Request: ${requestId}`}
      renderEmpty={renderEmpty}
      actions={onClose && <IconButton icon={<CloseIcon />} title='Close' onClick={onClose} />}
      className={classnames(className)}
      onDrawerClose={onClose}
      error={renderError(isError)}
      drawer
      icon={<AwsServiceIcon service={findInventoryById(type)?.service} />}
    >
      <RequestMetrics item={request} resolvers={resolvers} loading={isLoading} drawer fixed tabNarrow />
      {hasTraceId &&
        <Section
          title='Trace'
          loading={isLoading || isLoadingTraces}
          titleUpperCase
          actions={hasTraceId && <XRayLink loading={isLoadingTraces} traces={traces} resource={resource} />}
        >
          <Trace traces={traces} loading={isLoadingTraces} enabled={hasTraceId} />
        </Section>
      }
      <Section title='Query' loading={isLoading} titleUpperCase>
        <Query item={request} loading={isLoading} />
      </Section>
      <Section title='Resolver Errors' loading={isLoading || isLoadingResolvers} titleUpperCase>
        <ResolverErrors resolvers={resolvers} />
      </Section>
    </Content>
  )
}

export default RequestContent
