import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { ERRORS_LIST, ERROR_ACTIVE } from './index'
import { eventPaths as paths } from '../index'
import useQueryParams from 'lib/hooks/use-query-params'

const updateError = async (account, error, payload) => {
  const data = await apiFetch(paths.event(account, error), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useErrorUpdate = () => {
  const { errorId } = useParams()
  const queryClient = useQueryClient()
  const { getValue: status } = useQueryParams('status', 'open')

  const { account } = useOrganization()

  return useMutation(
    (status) => updateError(account.id, errorId, status),
    {
      onSuccess: (updatedError) => {
        queryClient.invalidateQueries([...ERRORS_LIST, status, account.id])
        queryClient.setQueryData([...ERROR_ACTIVE, errorId], updatedError)
      }
    }
  )
}
