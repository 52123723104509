import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'RDS_DATABASE_CONNECTIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'database.connections',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'RDS_CLIENT_CONNECTIONS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'client.connections',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'RDS_QUERY_REQUESTS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'query.requests',
    stats: [STATISTICS.SUM]
  }
}]

export default metrics
