import numeral from 'numeral'

import { faNetworkWired, faPlug, faSigma } from '@fortawesome/pro-light-svg-icons'

import { getSparklineByName, getSum } from '../helpers'

export const RDS_DATABASE_CONNECTIONS = 'rds-database-connections'
export const RDS_CLIENT_CONNECTIONS = 'rds-client-connections'
export const RDS_QUERY_REQUESTS = 'rds-query-requests'

const metrics = [{
  key: RDS_DATABASE_CONNECTIONS,
  icon: faPlug,
  title: 'DB connections',
  filter: (collection) => numeral(getSum(collection, 'RDS_DATABASE_CONNECTIONS')).format('0.[00]a'), // avg
  subvalue: (collection) => getSparklineByName(collection, 'RDS_DATABASE_CONNECTIONS'),
  selectable: true
}, {
  key: RDS_CLIENT_CONNECTIONS,
  icon: faNetworkWired,
  title: 'Client Connections',
  filter: (collection) => numeral(getSum(collection, 'RDS_CLIENT_CONNECTIONS')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'RDS_CLIENT_CONNECTIONS'),
  selectable: true
}, {
  key: RDS_QUERY_REQUESTS,
  icon: faSigma,
  title: 'Query Requests',
  filter: (collection) => numeral(getSum(collection, 'RDS_QUERY_REQUESTS')).format('0.[00]a'), // sum
  subvalue: (collection) => getSparklineByName(collection, 'RDS_QUERY_REQUESTS'),
  selectable: true
}]

export default metrics
