import { uniq, partition } from 'lodash'
import { isMatch } from 'micromatch'

const filterResources = (allResources = [], integration) => {
  if (!allResources?.length || !integration) return {}
  const { service, delegation } = integration
  const delegationAndServiceResources = filterByDelegationAndService(allResources, delegation?.id, service)
  const filters = extractFilters(integration)
  const [included, excluded] = applyFilters(delegationAndServiceResources, filters)
  return { included, excluded }
}

const filterByDelegationAndService = (resources, delegationId, service) => {
  return resources.filter(resource => resource.delegation === delegationId && resource.service === service)
}

const extractFilters = (integration) => {
  const { filters, boundaries } = integration
  const regions = mergeRegions(filters?.regions, boundaries?.regions)
  const whitelist = mergeFilters(filters?.whitelist, boundaries?.whitelist)
  const blacklist = mergeFilters(filters?.blacklist, boundaries?.blacklist)
  return { regions, whitelist, blacklist }
}

const mergeRegions = (regions = [], boundaryRegions = []) => {
  if (!boundaryRegions?.length) return regions
  if (!regions?.length) return boundaryRegions
  return boundaryRegions.filter(region => regions.includes(region))
}

const mergeFilters = (filterList = [], boundaryFilterList = []) => {
  return uniq([...filterList, ...boundaryFilterList])
}

const applyFilters = (resources, filters) => {
  if (!resources?.length || !filters) return []
  const [includedByRegions, excludedByRegions] = applyRegionsFilter(resources, filters?.regions)
  const [includedByWhitelist, excludedByWhitelist] = matchByNameAndTag(includedByRegions, filters?.whitelist)
  const [excludedByBlacklist, included] = filters?.blacklist?.length ? matchByNameAndTag(includedByWhitelist, filters?.blacklist) : [[], includedByWhitelist]
  const excluded = [...excludedByRegions, ...excludedByWhitelist, ...excludedByBlacklist]
  return [included, excluded]
}

const applyRegionsFilter = (resources, regionFilters) => {
  if (!regionFilters?.length) return [resources, []]
  return partition(resources, resource => regionFilters.includes(resource.region))
}

const matchName = (name, names) => names.some(n => isMatch(name, n))

const matchTag = (tag, tags) => tags.some(t => isMatch(tag.name, t.key) && isMatch(tag.value, t.value))

const matchByNameAndTag = (resources, namesAndTags) => {
  if (!namesAndTags?.length) return [resources, []]
  const { names, tags } = parseNameTagFilters(namesAndTags)
  return partition(resources, resource => {
    const matchedByTag = resource?.tags?.some(resourceTag => matchTag(resourceTag, tags))
    return matchName(resource?.name, names) || matchedByTag
  })
}

const parseNameTagFilters = (filters) => {
  const names = []
  const tags = []
  filters?.forEach(filter => {
    if (filter.type === 'name') {
      names.push(filter.value)
    } else if (filter.type === 'tag') {
      const [key, value] = filter?.value?.split(':::') || []
      tags.push({ key, value })
    }
  })
  return { names, tags }
}

export default filterResources
