import { generatePath } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

const url = (path) => (parameters) => {
  return generatePath(path, parameters)
}

const entry = (...parts) => {
  const path = parts.join('/')
  return { path, url: url(path) }
}

const build = (path, routes) => {
  return Object.entries(routes).reduce((result, [key, value]) => {
    if (typeof (value) === 'object') {
      const current = entry(path, value.path || key)
      result[key] = { ...current, ...build(current.path, value) }
    } else if (key !== 'path') {
      result[key] = entry(path, value)
    }
    return result
  }, {})
}

const routes = {
  alarms: {
    alarm: ':alarmId',
    resourceGroups: {
      path: 'resource-groups',
      new: 'new',
      group: ':groupId',
      edit: ':groupId/edit'
    }
  },
  dashboards: {
    dashboard: ':dashboardId',
    widget: ':dashboardId/widgets/:widgetId',
    invocation: ':dashboardId/inventory/:resourceId/requests/:invocationHash',
    resourceGroups: {
      path: 'resource-groups',
      new: 'new',
      group: ':groupId',
      edit: ':groupId/edit'
    }
  },
  insights: {
    rule: ':ruleId'
  },
  inventory: {
    resource: ':resourceId',
    request: ':resourceId/requests/:requestId',
    invocation: ':resourceId/requests/:invocationHash',
    resourceGroups: {
      path: 'resource-groups',
      new: 'new',
      group: ':groupId',
      edit: ':groupId/edit'
    }
  },
  errors: {
    error: ':errorId',
    request: ':errorId/requests/:requestId',
    resourceGroups: {
      path: 'resource-groups',
      new: 'new',
      group: ':groupId',
      edit: ':groupId/edit'
    }
  },
  resourceGroups: {
    path: 'resource-groups',
    new: 'new',
    edit: ':groupId/edit',
    group: ':groupId',
    resource: ':groupId/resources/:resourceId'
  },
  onboarding: {
    token: ':token'
  },
  search: {
    query: ':queryId',
    request: ':queryId?/requests/:requestId'
  },
  scheduledSearch: {
    path: 'scheduled-search',
    new: 'new',
    query: ':queryId',
    logMatch: ':queryId?/log-matches/:invocationHash'
  },
  settings: {
    profile: 'profile',
    invitations: 'invitations',
    security: 'security',
    'manage-accounts': {
      account: ':id'
    },
    users: {
      user: ':userId'
    },
    notifications: {
      channels: {
        new: 'new/:type',
        channel: ':channelId'
      },
      policies: {
        policy: ':policyId',
        newChannel: ':policyId/new-channel'
      }
    },
    integrations: {
      new: 'new',
      integration: ':delegationId/:integrationId',
      delegation: ':delegationId'
    },
    subscriptions: 'subscriptions',
    changePlan: 'subscriptions#changePlan',
    payment: {
      billing: ':customerId'
    },
    invoices: 'invoices',
    usage: 'usage'
  }
}

export const ROUTES = build('', routes)

const CACHE = {}

export const useAccountRoutes = () => {
  const { account } = useOrganization()
  if (!account) return ROUTES

  const id = account.id
  if (!CACHE[id]) CACHE[id] = build(`/${account.id}`, routes)

  return CACHE[id]
}
