import React from 'react'
import { useHistory, useParams } from 'react-router'
import { map, isEmpty } from 'lodash'

import { message } from 'antd'

import { useOrganizationInvitationsQuery, useOrganizationUsersQuery, useAccountsQuery, useDeleteUserQuery, useDeleteInvitationQuery, useResendInvitationQuery, useUpdateUserPermissionsQuery, useUpdateUserQuery } from 'hooks/api'

import { useAccountRoutes } from 'containers/routes'
import Content from 'components/layout/content'
import EditUser from './edit-user'

import UserActions from './actions'

export const isUserRoleSuperior = ({ role, permissions } = {}) => {
  if (role === 'owner' || role === 'admin') return true
  if (!permissions) return false
  if (Object.values(permissions || {})?.every(item => item === 'admin')) return true

  else return false
}

const User = () => {
  const history = useHistory()
  const { userId } = useParams()
  const routes = useAccountRoutes()

  const { data: invitations, isLoading: loadingInvitations } = useOrganizationInvitationsQuery()
  const { data: users, isLoading: loadingUsers } = useOrganizationUsersQuery()
  const { data: accounts, isLoading: loadingAccounts } = useAccountsQuery()

  const { mutate: deleteUser } = useDeleteUserQuery()
  const { mutate: deleteInvitation } = useDeleteInvitationQuery()
  const { mutate: resendInvitation } = useResendInvitationQuery()

  const { mutate: updateUser, isLoading: userUpdating } = useUpdateUserQuery()
  const { mutate: updateUserPermissions, isLoading: permissionsUpdating } = useUpdateUserPermissionsQuery()

  const user = users?.find(item => item.id === userId) || invitations?.find(item => item.id === userId)

  const handleResendInvite = (invitationId, invitation) => {
    resendInvitation({ invitationId, payload: invitation })
    message.success(`Invitation sent for user with email ${user.email}`)
    history.goBack()
  }

  const handleDeleteInvite = (invitationId, invitation) => {
    deleteInvitation({ invitationId, email: invitation.email })
    message.success(`User deleted with email ${user.email}`)
    history.goBack()
  }

  const handleDeleteUser = (userId) => {
    deleteUser({ userId })
    message.success({ content: `User deleted with email ${user.email}` })
    history.goBack()
  }

  const handleResend = () => {
    const permissions = map(user.permissions, (value, prop) => ({ account: prop, role: value }))
    const { email, role } = user

    handleResendInvite(user.id, {
      email,
      role,
      permissions
    })
  }

  const handleDelete = () => {
    if (user?.expiresAt) {
      handleDeleteInvite(user.id, user)
    } else {
      handleDeleteUser(user.id, user)
    }
  }

  const handleEditUser = async (user, permissions) => {
    !isEmpty(user) && updateUser({ userId, payload: user })
    updateUserPermissions({ userId, payload: permissions })
    history.goBack()
  }

  const handleActionClick = ({ key }) => {
    switch (key) {
      case 'resend': return handleResend()
      default: return () => { }
    }
  }

  const USER_PENDING_EXPIRED = user?.expiresAt

  return (
    <Content
      item
      title='Edit user'
      breadcrumbs={['settings', 'users & permissions', 'edit']}
      loading={loadingUsers || loadingInvitations || loadingAccounts}
      backRoute={routes.settings.users.url()}
      actions={<UserActions user={user} handleActionClick={handleActionClick} handleDelete={handleDelete} />}
    >
      {USER_PENDING_EXPIRED
        ? (
          <div>
            {`User invite ${new Date(user?.expiresAt) < Date.now() ? 'has expired' : 'is pending'}. You can resend the invite from top right of this page.`}
          </div>)
        : (
          <EditUser
            user={user}
            accounts={accounts}
            loading={loadingAccounts || loadingUsers || loadingInvitations}
            saveUser={handleEditUser}
            saving={userUpdating || permissionsUpdating}
          />)}
    </Content>
  )
}

export default User
