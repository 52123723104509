import React from 'react'
import { isEmpty } from 'lodash'

import { useErrorUpdate } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { MutedIcon, MutedSolidIcon, ResolvedIcon, ResolvedSolidIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

import styles from './styles.module.less'

const Resolve = ({ item, onResolve, onReopen }) => {
  const resolved = item.status === 'resolved'

  const toggleResolve = () => {
    if (resolved) return onReopen(item)
    return onResolve(item)
  }

  return (
    <PermissionsGate
      scopes={[SCOPES.canEdit]}
      errorProps={resolved ? { disabled: true, title: 'Error resolved' } : null}
    >
      <IconButton
        onClick={toggleResolve}
        icon={resolved ? <ResolvedSolidIcon className={styles.resolved} /> : <ResolvedIcon />}
        title={resolved ? 'Reopen error' : 'Resolve error'}
        className={styles.button}
      />
    </PermissionsGate>
  )
}

const Mute = ({ item, onMute, onUnmute }) => {
  const muted = item?.mutedAt

  const toggleMute = () => {
    if (muted) return onUnmute(item)
    return onMute(item)
  }

  return (
    <PermissionsGate
      scopes={[SCOPES.canEdit]}
      errorProps={muted ? { disabled: true, title: 'Error muted' } : null}>
      <IconButton
        onClick={toggleMute}
        icon={muted ? <MutedSolidIcon className={styles.muted} /> : <MutedIcon />}
        title={muted ? 'Unmute error' : 'Mute error'}
        className={styles.button}
      />
    </PermissionsGate>
  )
}

const Actions = ({ item, loading }) => {
  const { mutate: updateErrorStatus } = useErrorUpdate()

  if (isEmpty(item) || loading) return null

  return (
    <div className={styles.actions}>
      <Resolve
        item={item}
        onResolve={() => updateErrorStatus({ status: 'resolved' })}
        onReopen={() => updateErrorStatus({ status: 'open' })}
      />
      <Mute
        item={item}
        onMute={() => updateErrorStatus({ status: 'muted' })}
        onUnmute={() => updateErrorStatus({ status: 'active' })} />
    </div>
  )
}

export default Actions
