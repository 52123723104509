import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, DASHBOARD_ACTIVE, WIDGETS_LIST } from './index'

const getDashboard = async (account, dashboardId, groupId) => {
  if (groupId) {
    const data = await apiFetch(paths.resourceGroupWidgets(account, groupId))
    return data
  }
  const data = await apiFetch(paths.dashboard(account, dashboardId))
  return data
}

export const useDashboardQuery = () => {
  const { dashboardId, groupId } = useParams()
  const queryClient = useQueryClient()

  const { account } = useOrganization()

  return useQuery(
    [...DASHBOARD_ACTIVE, dashboardId || groupId],
    () => getDashboard(account.id, dashboardId, groupId),
    {
      staleTime: Infinity,
      enabled: !!account?.id && (!!dashboardId || !!groupId),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (groupId) {
          const widgets = data.widgets.map(widget => ({ ...widget, id: uuid() }))
          queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], widgets)
        }
      }
    }
  )
}
