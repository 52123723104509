import React from 'react'
import Items from 'containers/inventory/shared/items'

const EventBridgeRules = ({ rules }) => {
  const formatted = rules?.map(rule => {
    return {
      id: rule._id,
      url: `/inventory/${rule._id}`,
      title: rule.name
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default EventBridgeRules
