import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { first, sum } from 'lodash'
import { Row, Col, Typography, Table } from 'antd'

import columns from './columns'
import prepareData from './prepare-data'
import { formatStripePrice } from 'lib/billing-helpers'
import { API } from 'actions/api'

import DiscountForm from './form'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const { Text } = Typography

const Totals = ({ total } = {}) => {
  return (
    <Row className={styles.topMargin}>
      <Col span={12}><Text type='secondary'>You will be charged at the end of the billing cycle.</Text></Col>
      <Col span={12}>
        <Row>
          <Col offset={6} span={8}><Text strong>Estimated total</Text></Col>
          <Col span={10}>
            <Text strong>{total}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const OnDemandSubscription = ({ price, usage, onOk } = {}) => {
  const { organization } = useOrganization()

  const data = prepareData(usage, price)
  const total = sum(data.map(item => item.total))
  const currency = first(data).currency

  const dispatch = useDispatch()
  const formRef = useRef()

  const onSubmit = async (subscriptionId) => {
    const values = await formRef.current.validateFields().then(values =>
      Promise.resolve(values)
    ).catch(errorInfo => {
      console.log(errorInfo)
      return Promise.resolve(null)
    })

    if (!values || values?.promotion) return

    try {
      const body = { promotion: values.promotion }
      dispatch(API.organizations.id(organization.id).billing.subscriptions.id(subscriptionId).put({ ...body, type: 'onDemand' }))
      return onOk && onOk()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className={styles.topMargin}
      />
      <Totals total={formatStripePrice(total, currency)} />
      <DiscountForm onSubmit={onSubmit} ref={formRef} />
    </>
  )
}

export default OnDemandSubscription
