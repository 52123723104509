import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { debounce, isEmpty } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import { Divider, Skeleton, Button, Tooltip } from 'antd'

import { useOrganization } from 'hooks/context/organization-context'
import { useCreateInvoicePreviewQuery, useGetPromotionQuery } from 'hooks/api'
import { Tier, TIERS } from '../tier-config'
import { formatStripePrice } from 'lib/billing-helpers'
import PromoCodeForm from './promo-code-form'
import InvoiceLine from './invoice-line'
import Section from 'components/layout/content/section'
import CheckoutSummary from './summary'
import { Summary, BillingInterval } from '../usePrices'
import compileSubscriptionItemChanges from '../compileSubscriptionItems'

import styles from '../styles.module.less'

const PaymentInfo = ({ summary, preview, IN_TRIAL }) => {
  const nextBillingDate = preview?.lines?.find((item) => item?.period?.end)?.period?.end
  const dateFormatted = moment.unix(nextBillingDate).format('MMMM Do YYYY')
  const price = formatStripePrice(summary.total, preview?.currency)
  const discount = preview?.discount ? `(${preview?.discount?.coupon?.name})` : ''
  return <p className={styles.info}>
    {IN_TRIAL
      ? `NB! You will be charged ${price} ${discount} when trial ends on ${dateFormatted}`
      : `NB! Next billing will be ${price} ${discount} on ${dateFormatted}`}
  </p>
}

const Checkout = ({
  tier,
  billingInterval,
  summary,
  subscription,
  preview,
  setPreview
}: {
  tier: Tier,
  billingInterval: BillingInterval,
  summary: null | Summary,
  subscription: any,
  preview: any,
  setPreview: (...args: any[]) => any,
}) => {
  const { organization } = useOrganization()
  const { mutateAsync: createPreview, isLoading: previewLoading } = useCreateInvoicePreviewQuery()

  const [code, setCode] = useState('')
  const { data: promotion, remove: removePromotion, isError, isLoading } = useGetPromotionQuery({ payload: { promotionCode: code }, options: { placeholderData: subscription?.discount } })

  const handlePromotionValidate = useMemo(() => {
    return debounce(changedValues => setCode(changedValues?.code), 300)
  }, [])

  const IN_TRIAL = subscription?.status === 'trialing'
  const IN_TRIAL_SWITCH = IN_TRIAL && tier === TIERS.free

  const getInvoicePreview = async (discount = {}) => {
    if (!organization || !summary?.items) return
    const payload = {
      subscriptionId: subscription.id,
      items: compileSubscriptionItemChanges(subscription.items, summary.items),
      ...discount
    }

    try {
      const data = await createPreview({ payload } as any)
      setPreview(data)
    } catch (error) { }
  }

  useEffect(() => {
    if (!organization || !summary?.items) return
    getInvoicePreview(!isEmpty(promotion) ? { coupon: promotion?.coupon?.id } : {})
  }, [code, promotion])

  return (
    <Section className={styles.step_container}>
      <h2 className={styles.title}>Checkout overview</h2>

      <CheckoutSummary
        tier={tier}
        billingInterval={billingInterval}
        summary={summary}
      />

      <div className={styles.total_container}>
        <Skeleton loading={previewLoading} paragraph={{ rows: 7, width: [400, 400, 400, 400, 400, 400, 400] }} active title={false}>
          <div className={styles.checkout_prices}>
            {preview?.lines?.map((line) => {
              const description = IN_TRIAL ? 'Selected invocations amount' : line.description
              const price = IN_TRIAL_SWITCH ? 0 : line.amount
              return (
                <InvoiceLine
                  key={line.id}
                  description={description}
                  amount={price}
                  currency={preview?.currency}
                  interval={line.price?.interval}
                />
              )
            })}
            {tier === TIERS.business && !isEmpty(preview) && <PaymentInfo summary={summary} preview={preview} IN_TRIAL={IN_TRIAL} />}
          </div>

          {tier === TIERS.business && (
            <>
              <PromoCodeForm
                promotion={promotion}
                handleValidate={handlePromotionValidate}
                error={isError}
                loading={isLoading}
              />
              {!isEmpty(promotion) && <div className={styles.row}>
                <p className={styles.confirm_text}>
                  Discount applied: {preview?.discount?.coupon?.name}
                </p>
                {!(subscription?.discount?.coupon?.id === preview?.discount?.coupon?.id) &&
                  <Tooltip title='Remove coupon code'>
                    <Button shape='circle' icon={<DeleteOutlined />} onClick={() => removePromotion()} className={styles.remove_btn} />
                  </Tooltip>}
              </div>}
            </>
          )}

          <Divider className={styles.divider} />

          <InvoiceLine description='Subtotal' amount={IN_TRIAL_SWITCH ? 0 : preview?.subtotal || 0} currency={preview?.currency} />
          <InvoiceLine description='Tax' amount={IN_TRIAL_SWITCH ? 0 : preview?.tax || 0} currency={preview?.currency} />
          <InvoiceLine description='Total' amount={IN_TRIAL_SWITCH ? 0 : preview?.total} currency={preview?.currency} total />
        </Skeleton>
      </div>
    </Section >
  )
}

export default Checkout
