import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { RESOURCE_GROUPS_LIST, resourceGroupsPaths as paths } from './index'

const deleteResourceGroup = async (account, groupId) => {
  const data = await apiFetch(paths.group(account, groupId), { method: 'DELETE' })

  return data
}

export const useDeleteResourceGroupQuery = () => {
  const queryClient = useQueryClient()

  const { account } = useOrganization()

  return useMutation(
    (groupId) => deleteResourceGroup(account.id, groupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...RESOURCE_GROUPS_LIST, account.id])
      }
    }
  )
}
