import { useOrganization } from 'hooks/context/organization-context'
import { useMutation, useQueryClient } from 'react-query'
import { QUERIES_LIST, QUERY_ACTIVE, queryPaths } from 'hooks/api/queries'
import { apiFetch } from 'lib/action-helpers'
import { useParams } from 'react-router-dom'

const updateQuery = async (account, queryId, payload) => {
  return await apiFetch(queryPaths.query(account, queryId), { method: 'PUT', body: JSON.stringify(payload) })
}

export const useUpdateQueryQuery = () => {
  const queryClient = useQueryClient()
  const { queryId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ payload, id }) => updateQuery(account.id, id, payload),
    {
      onMutate: ({ payload }) => {
        const queryKey = [...QUERY_ACTIVE, queryId]
        const openQuery = queryClient.getQueryData(queryKey)
        queryClient.setQueryData(queryKey, { ...openQuery, ...payload })
      },
      onError: (err, newItem, context) => {
        if (!err) return
        const queryKey = [...QUERY_ACTIVE, queryId]
        queryClient.setQueryData(queryKey, context.openQuery)
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...QUERIES_LIST, account.id])
      }
    }
  )
}
