import React from 'react'
import { Row } from 'antd'
import { keyBy, difference, uniq, without } from 'lodash'

import Section from 'components/layout/content/section'
import regionGroups from 'lib/aws-regions/regions.json'
import AreaColumn from './area-column'

import styles from './styles.module.less'

const groupRegions = (regions, availableRegions, parentRegions) => {
  return Object.entries(regionGroups).reduce((acc, [area, areaRegions]) => {
    acc[area] = Object.entries(areaRegions)
      .filter(([region]) => availableRegions.includes(region))
      .map(([region, name]) => ({
        region,
        name,
        disabled: regions.length && !regions.includes(region),
        parentDisabled: parentRegions.length && !parentRegions.includes(region)
      }))

    return acc
  }, {})
}

const groupErrors = errors => {
  if (!errors || !errors.length) return {}
  return keyBy(errors, (error) => error.region)
}

const addNewRegion = (regions, newRegion) => uniq([...regions, newRegion])
const areAllPossibleRegionsChosen = (allPossibleRegions, chosenRegions) => difference(allPossibleRegions, chosenRegions)?.length === 0
const removeRegion = (regions, allPossibleRegions, region) => without(!regions?.length ? allPossibleRegions : regions, region)

const Regions = ({ regions = [], availableRegions = [], errors = [], handleChange, updating, parentRegions = [] }) => {
  const areas = groupRegions(regions, availableRegions, parentRegions)

  const handleRegionChange = ({ region, enable }) => {
    let newRegions
    if (enable) {
      newRegions = addNewRegion(regions, region)
      if (areAllPossibleRegionsChosen(availableRegions, newRegions)) {
        newRegions = []
      }
    } else {
      newRegions = removeRegion(regions, availableRegions, region)
    }
    handleChange(newRegions)
  }

  return (
    <Section solid>
      <Row className={styles.regions}>
        {
          Object.entries(areas).map(([area, regions]) => (
            <AreaColumn
              key={area}
              area={area}
              regions={regions}
              errors={groupErrors(errors)}
              handleChange={handleRegionChange}
              updating={updating}
            />
          ))
        }
      </Row>
    </Section>
  )
}

export default Regions
