import React, { useContext } from 'react'
import { isEmpty } from 'lodash'
import { Dropdown } from 'antd'

import { useCreateAlarmQuery } from 'hooks/api'
import { SplitViewContext } from 'features/layout/split-pane'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { AggregationTypes, AlarmTermDurations, TermOperators } from 'lib/alerting-constants'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { AddIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

import styles from './styles.module.less'

const AddNewAlarm = () => {
  const { handleListClose } = useContext(SplitViewContext)
  const { mutate: createAlarm } = useCreateAlarmQuery()

  const handleCreateAlarm = (key) => {
    const service = INVENTORY_SERVICES[key]
    const { title, namespace, metrics } = service
    const payload = {
      name: `${title} alarm`,
      namespace: title === 'Lambda' ? namespace[0] : namespace,
      resourceType: service.type,
      severity: 'critical',
      metric: metrics[0].value,
      stat: AggregationTypes[0].value,
      operator: TermOperators[0].value,
      threshold: 10,
      duration: AlarmTermDurations[0].value
    }

    createAlarm({ payload })
    handleListClose()
  }

  return (
    <PermissionsGate scopes={[SCOPES.canCreate]}>
      <Dropdown
        menu={{
          items: Object.values(INVENTORY_SERVICES)
            .filter(item => !isEmpty(item.metrics))
            .map(service => ({ key: service.id, label: service.title })),
          onClick: ({ key }) => handleCreateAlarm(key)
        }}
        trigger={['click']}
        getPopupContainer={triggerNode => triggerNode?.parentNode}
        overlayClassName={styles.add_dropdown}
      >
        <IconButton icon={<AddIcon />} className={styles.add_btn} />
      </Dropdown>
    </PermissionsGate>

  )
}

export default AddNewAlarm
