import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'ECS_CPU_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'cpu',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'ECS_MEM_UTILIZATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'memory',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: 'ECS_CPU_RESERVATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'reservation.cpu',
    stats: [STATISTICS.MAX]
  }
}, {
  id: 'ECS_MEM_RESERVATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'reservation.memory',
    stats: [STATISTICS.MAX]
  }
}]

export default metrics
