import numeral from 'numeral'

const BYTES_IN_GB = 1073741824
const BYTES_IN_MB = 1048576

const formatIngestedData = (value, precision = '0') => {
  if (value > BYTES_IN_GB) {
    return `${numeral(value / BYTES_IN_GB).format(precision)} GB`
  }

  return `${numeral(value / BYTES_IN_MB).format(precision)} MB`
}

export default formatIngestedData
