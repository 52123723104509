import React from 'react'
import Items from 'containers/inventory/shared/items'

const SNSSubscriptions = ({ items, loading }) => {
  const formatted = items?.map(sub => ({
    id: sub.id,
    title: sub?.name,
    description: sub?.attributes.find(attr => attr.name === 'endpoint')?.value
  }))

  return <Items loading={loading} items={formatted} />
}

export default SNSSubscriptions
