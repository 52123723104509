import React from 'react'
import AuthTitle from '../title/index'
import MFAVerification from './form'

const MFAContainer = (props) => {
  return (
    <>
      <AuthTitle title='2-Step Verification' subtitle='Get a verification code from the authenticator app' />
      <MFAVerification {...props} login />
    </>
  )
}

export default MFAContainer
