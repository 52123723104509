import React, { useContext, useEffect, useState } from 'react'
import { getTime } from 'date-fns'
import { orderBy } from 'lodash'
import { Row, Col } from 'antd'

import { useGetQueriesQuery } from 'hooks/api'
import { SearchMetricsContext } from '../index'
import Content from 'components/layout/content'
import TrendlineCard from 'components/dashboards/trend-chart'
import GlobalDatePicker from 'components/date-picker/global'
import { useAccountRoutes } from 'containers/routes'

import styles from './styles.module.less'

const ScheduledSearchDashboard = () => {
  const routes = useAccountRoutes()
  const { dashboardStart, dashboardEnd, onChangeDashboardDates, dashboardRelativeSpan } = useContext(SearchMetricsContext)
  const { data: savedQueries } = useGetQueriesQuery()
  const [scheduledQueries, setScheduledQueries] = useState(null)
  const [metricsLoad, setMetricsLoad] = useState(null)

  useEffect(() => {
    if (!savedQueries) return

    const scheduled = savedQueries?.filter(item => item?.frequency)
    const sorted = orderBy(scheduled, 'updatedAt', 'desc').slice(0, 4)

    setScheduledQueries(sorted)
    setMetricsLoad(sorted.map(item => ({
      key: 'scheduled-query',
      name: item?.name || item.query,
      format: item => item || 0,
      size: { xs: 24, lg: 12 },
      url: routes.scheduledSearch.query.url({ queryId: item.id }),
      options: {
        dimensions: {
          hashes: [item.id]
        }
      }
    })))
  }, [savedQueries])

  return (
    <Content
      title='Scheduled search'
      breadcrumbs={['scheduled-search']}
      item
      actions={<GlobalDatePicker
        start={dashboardStart}
        end={dashboardEnd}
        onChange={onChangeDashboardDates}
        relativeSpan={dashboardRelativeSpan}
        refetchHidden
      />
      }
    >
      <Row gutter={[8, 8]} className={styles.wrapper}>
        {metricsLoad?.map(load =>
          <Col xs={load.size.xs} lg={load.size.lg} key={load.name}>
            <TrendlineCard
              load={load}
              start={getTime(dashboardStart)}
              end={getTime(dashboardEnd)}
              height={100} />
          </Col>
        )}
      </Row>
    </Content>
  )
}

export default ScheduledSearchDashboard
