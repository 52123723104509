import { useMutation, useQueryClient } from 'react-query'

import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { CUSTOMER, billingPaths as paths } from '../index'

const updateCustomer = async (organization, identity, payload) => {
  const { email, name, vat, country, city, line1, line2, state, postalCode } = payload
  const extraLine = line2 !== '' ? { line2 } : {}
  const address = omitEmpty({ country, city, line1, ...extraLine, postalCode, state })
  const body = JSON.stringify({ email, name, vat, address })

  const data = await apiFetch(paths.customer(organization, identity), { method: 'PUT', body })

  return data
}

export const useUpdateCustomerQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ identity, payload }) => updateCustomer(organization.id, identity, payload),
    {
      onMutate: ({ payload }) => {
        const previousCustomer = queryClient.getQueryData([...CUSTOMER, organization.id])

        const updated = { ...previousCustomer, ...payload }
        queryClient.setQueryData([...CUSTOMER, organization.id], updated)

        return { previousCustomer }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...CUSTOMER, organization.id], context.previousCustomer)
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([...CUSTOMER, organization.id])
      }
    }
  )
}
