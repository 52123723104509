import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { NOTIFICATION_POLICY, NOTIFICATION_POLICY_LIST, policyPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

const createNotificationPolicyCondition = async (account, policy, payload) => {
  const data = await apiFetch(paths.conditions(account, policy), { method: 'POST', body: JSON.stringify(payload) })

  return data?.data
}

export const useCreateNotificationPolicyConditionQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ accountId = account.id, policyId, payload }) => createNotificationPolicyCondition(accountId, policyId, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(NOTIFICATION_POLICY, policyId)

        const policyList = queryClient.getQueryData([...NOTIFICATION_POLICY_LIST, account.id])
        if (!policyList) return

        const newPoliciesList = policyList?.map(item => {
          if (item.id !== data.id) return item
          return {
            ...item,
            conditions: [...item.conditions, data]
          }
        })

        queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], newPoliciesList)
      }
    }
  )
}
