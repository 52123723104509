import { useOrganization } from 'hooks/context/organization-context'
import queryString from 'query-string'
import { apiFetch, omitEmpty } from 'lib/action-helpers'

import { paths } from '../index'
import { eventsStoragePaths, REQUESTS_LIST } from './index'
import { useQuery } from 'react-query'

export const getRequests = async (account, filters = {}) => {
  const query = queryString.stringify(omitEmpty(filters))
  const data = await apiFetch(paths.filter(eventsStoragePaths.requests(account), query))
  return data || []
}

export const useRequestsQuery = (filters) => {
  const { account } = useOrganization()

  return useQuery(
    [...REQUESTS_LIST, filters, account?.id],
    () => getRequests(account?.id, filters),
    {
      staleTime: 1 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false // take away later
    }
  )
}
