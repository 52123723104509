import React from 'react'

import { compact, entries, flatten, get, groupBy, last, sortBy, sum, zipWith, filter } from 'lodash'

import Sparkline from 'components/charts/sparkline'

export const average = (items) => {
  if (!items || items.length === 0) return 0
  return sum(items) / items.length
}

export const getValue = (data, name) => {
  if (!data) return
  const metric = data[name] || []
  if (!metric?.stats) return 0
  const stat = metric?.stats[0]
  return get(metric, `datapoints.default[0].${stat}`)
}

export const getSum = (data, name) => {
  if (!data) return
  const metric = data[name] || []
  if (!metric?.stats) return 0
  const stat = metric?.stats[0]
  return sum(metric?.datapoints?.default?.map(item => item[stat]))
}

export const getLast = (data, name, statistic) => {
  if (!data) return
  const metric = data[name] || []

  if (!metric?.stats) return 0
  const stat = metric?.stats[0]
  const values = metric?.datapoints?.default?.map(item => item[statistic || stat])

  return last(values)
}

export const getSecondToLast = (data, name) => {
  if (!data) return
  const metric = data[name] || []

  if (!metric?.stats) return 0

  const stat = metric?.stats[0]
  const values = metric?.datapoints?.default?.map(item => item[stat])

  return values[values?.length - 2]
}

export const getLastNonZero = (data, name) => {
  if (!data) return
  const metric = data[name] || []
  if (!metric?.stats) return 0

  const stat = metric?.stats[0]
  const values = metric?.datapoints?.default?.map(item => item[stat])
  const nonZeros = filter(values, val => val > 0)
  return last(nonZeros)
}

export const getSparklineByName = (data, name) => {
  if (!data) return null

  const metrics = data[name]
  if (!metrics || !metrics?.datapoints?.default?.length) return null

  const stat = metrics?.stats[0]

  const values = metrics?.datapoints?.default?.map(item => ({
    start: item.date,
    value: item[stat]
  }))

  return getSparkline(values)
}

export const getValues = (data, name) => {
  if (!data || typeof data !== 'object') return null

  const metrics = data[name]
  if (!metrics) return null

  if (!metrics?.stats) return []

  const stat = metrics?.stats[0]

  return metrics?.datapoints?.default?.map(item => ({
    start: item.date,
    value: item[stat]
  }))
}

export const aggregateSparkline = (data, aggregator, ...names) => {
  if (!data) return null

  const metrics = compact(flatten(names.map(name => getValues(data, name))))
  const values = entries(groupBy(metrics, 'start')).map(([timestamp, group]) => {
    return {
      start: timestamp,
      value: aggregator(group.map(item => item.value))
    }
  })
  return getSparkline(sortBy(values, 'start'))
}

export const getSQSDifferenceSparkline = (data, dataInName, dataOutName) => {
  if (!data) return null

  const metricsIn = data[dataInName]
  const metricsOut = data[dataOutName]

  if (!metricsIn || !metricsOut || metricsIn.datapoints?.default?.length === 0 || metricsOut?.datapoints?.default?.length === 0) return null

  if (!metricsIn?.stats) return 0

  const stat = metricsIn?.stats[0]

  const metricsInTimestamps = metricsIn.datapoints?.default?.map(item => item.date)
  const metricsInValues = metricsIn.datapoints?.default?.map(item => item[stat])
  const metricsOutValues = metricsOut.datapoints?.default?.map(item => item[stat])
  const values = zipWith(metricsInTimestamps, metricsInValues, metricsOutValues, (start, valueIn, valueOut) => {
    return { start, value: Math.abs(valueOut - valueIn) }
  })

  return getSparkline(values)
}

export const getKinesisDifferenceSparkline = (data, dataInName, dataOutName) => {
  if (!data) return null

  const metricsIn = data[dataInName]
  const metricsOut = data[dataOutName]

  if (!metricsIn || !metricsOut || metricsIn.datapoints?.default?.length === 0 || metricsOut?.datapoints?.default?.length === 0) return null

  if (!metricsIn?.stats) return 0

  const stat = metricsIn?.stats[0]

  const metricsInTimestamps = metricsIn.datapoints?.default?.map(item => item.date)
  const metricsInValues = metricsIn.datapoints?.default?.map(item => item[stat])
  const metricsOutValues = metricsOut.datapoints?.default?.map(item => item[stat])
  const values = zipWith(metricsInTimestamps, metricsInValues, metricsOutValues, (start, valueIn, valueOut) => {
    return { start, value: Math.abs(valueOut - valueIn) }
  })

  return getSparkline(values)
}

export const getDifferenceFromTotal = (data, metricKey) => {
  if (!data) return null

  const items = data[metricKey]

  if (!items || items.datapoints?.default?.length === 0) return null
  if (!items?.stats) return 0

  const stat = items?.stats[0]

  const datapoints = items.datapoints.default.map((datapoint, index) => {
    if (index === 0) return datapoint

    const prevDatapoint = items.datapoints.default[index - 1]
    const diff = datapoint[stat] - prevDatapoint[stat]
    const updatedDatapoint = diff < 0 ? datapoint[stat] : diff

    return { ...datapoint, [stat]: updatedDatapoint }
  })
    // Remove first datapoint
    .slice(1)

  return {
    ...items,
    datapoints: { default: datapoints }
  }
}

export const getDifferenceFromTotalSparkline = (data, metricKey) => {
  const items = getDifferenceFromTotal(data, metricKey)
  const stat = items?.stats[0]

  const values = items?.datapoints?.default?.map(item => ({
    start: item.date,
    value: item[stat]
  }))

  return <Sparkline data={values} height={20} />
}

export const getSparkline = (values) => {
  return <Sparkline data={values} height={20} />
}
