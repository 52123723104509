import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { message } from 'antd'

import Content from 'components/layout/content'
import ConnectAws from 'containers/onboarding/connect-aws'
import Polling from 'containers/settings/account/integrations/delegation/polling'
import { useDelegationsQuery, useCreateDelegationQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'

const CreateDelegation = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const [externalId, setExternalId] = useState(uuid)
  const [delegationName, setDelegationName] = useState('')
  const [pollingDelegations, setPollingDelegations] = useState(false)

  const { mutate: createDelegation, isLoading: creatingDelegation } = useCreateDelegationQuery()
  const { data: delegations } = useDelegationsQuery({ refetchInterval: pollingDelegations })

  const handlePollingDelegations = (value) => {
    setPollingDelegations(30 * 1000)
    setDelegationName(value)
  }

  const handleCreateDelegation = (values) => {
    const { externalId, arn, delegationName } = values
    setDelegationName(delegationName)
    setExternalId(externalId)
    createDelegation({ name: delegationName, externalId, roleArn: arn })
  }

  const cancelStackCreation = () => {
    setPollingDelegations(false)
  }

  const handleOnboardingEntry = () => {
    // TODO: what if user navigates away and then we don't have the externalId anymore because it is saved in state when user creates stack form here - move create onboarding entry to backend
    const createdDelegation = delegations?.find(item => item.provider.secret === externalId)
    if (!createdDelegation) return
    setPollingDelegations(false)

    history.push(routes.settings.integrations.url())
    message.success('Stack created, hold on while we start fetching your resources')
  }

  useEffect(handleOnboardingEntry, [delegations])

  return (
    <Content item title='Connect new AWS  account' backRoute={routes.settings.integrations.url()}>
      {!pollingDelegations && !creatingDelegation
        ? <ConnectAws
          handlePollingDelegations={handlePollingDelegations}
          createDelegation={handleCreateDelegation}
          externalId={externalId}
          delegationName={delegationName}
          addDelegation />
        : <Polling cancel={cancelStackCreation} polling={pollingDelegations} />
      }
    </Content>
  )
}

export default CreateDelegation
