import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

const metrics = [{
  id: 'APPSYNC_RESOLVER_REQUESTS',
  filter: {
    namespace: NAMESPACES.APPSYNC_RESOLVERS,
    metric: 'requests',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_RESOLVER_ERRORS',
  filter: {
    namespace: NAMESPACES.APPSYNC_RESOLVERS,
    metric: 'errors',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'APPSYNC_RESOLVER_DURATION',
  filter: {
    namespace: NAMESPACES.APPSYNC_RESOLVERS,
    metric: 'duration',
    stats: [STATISTICS.AVERAGE],
    fill: 'gapfill'
  }
}]

export default metrics
