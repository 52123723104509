import React, { useEffect, useState } from 'react'
import ResetPassword from './form'
import { useQuery } from 'lib/hooks/utils'
import { useForgotPasswordSubmit, useLogin } from 'hooks/auth'

const ResetPasswordContainer = () => {
  const query = useQuery()

  const [password, setPassword] = useState('')

  const queryEmail = query.get('email')
  const queryVerification = query.get('verification')

  const { mutate: forgotPasswordSubmit, isLoading, isSuccess } = useForgotPasswordSubmit()
  const { mutate: signIn } = useLogin()

  const onSubmit = async (values) => {
    const { password } = values
    setPassword(password)
    forgotPasswordSubmit({
      email: queryEmail,
      code: queryVerification,
      password
    })
  }

  useEffect(() => {
    if (!isSuccess) return

    if (!password || !queryEmail) return
    signIn({ email: queryEmail, password })
  }, [isSuccess])

  return <ResetPassword onSubmit={onSubmit} loading={isLoading} />
}

export default ResetPasswordContainer
