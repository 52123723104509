import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'

import { useErrorsBatchUpdate } from 'hooks/api'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ResolvedIcon, MutedIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

import styles from './styles.module.less'

const HeaderActions = ({ searchItems, selectAll, bulkSelected, setBulkSelected, setSelectAll }) => {
  const { mutate: bulkUpdate, isSuccess, isLoading } = useErrorsBatchUpdate()

  const [loading, setLoading] = useState(false)

  const resetBulkSelected = () => {
    if (!isSuccess) return
    setBulkSelected([])
  }

  const onSelectAll = (value) => {
    if (value) {
      setBulkSelected(searchItems?.map(event => event.id))
      setSelectAll(true)
    } else {
      setBulkSelected([])
      setSelectAll(false)
    }
  }

  const onBulkStatusChange = (status) => {
    if (bulkSelected?.length === 0) {
      return
    }

    setLoading(status)
    bulkUpdate(bulkSelected.map(id => ({ id, status })))
  }

  useEffect(resetBulkSelected, [isSuccess])

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]} RenderError={() => <div></div>}>
      <div className={styles.left}>
        <Checkbox checked={selectAll} onClick={() => onSelectAll(!selectAll)} />
        <IconButton icon={<MutedIcon />} title='Mute' onClick={() => onBulkStatusChange('muted')} loading={loading === 'muted' && isLoading} />
        <IconButton icon={<ResolvedIcon />} title='Resolve' onClick={() => onBulkStatusChange('resolved')} loading={loading === 'resolved' && isLoading} />
      </div>
    </PermissionsGate>
  )
}

export default HeaderActions
