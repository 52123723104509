import React from 'react'
import classnames from 'classnames'
import { Bar, Line } from '@visx/shape'

import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'
import { ColdstartIcon, BugIcon } from 'components/icons'
import { BAR_HEIGHT } from '../segment-bar'

import styles from './styles.module.less'

const SegmentBar = React.memo(({ item, xScale, handleClick, index, showContent, chartWidth, selected }) => {
  if (!item) return null

  const barPos = index * BAR_HEIGHT
  const dashedLinePos = barPos + 10
  const textPos = dashedLinePos + 3
  const barWidth = Math.abs(xScale(item.end - item.start))
  const itemXCoord = xScale(item.start)
  const offset = 7

  return (
    <>
      <g onClick={() => handleClick(item)} cursor='pointer'>
        <Bar
          x={0}
          y={barPos}
          width={chartWidth}
          height={BAR_HEIGHT}
          className={classnames(styles.background_bar, { [styles.selected]: !!selected })}
        />

        <Bar
          x={itemXCoord}
          y={barPos}
          width={barWidth}
          height={BAR_HEIGHT}
          fill={item.fill}
        />

        {/* line going left */}
        <Line
          from={{ x: -offset, y: dashedLinePos }}
          to={{ x: itemXCoord, y: dashedLinePos }}
          fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='2.5 2.5'
        />

        {item?.reference?.error && <BugIcon width='12' height='12' className={styles.icon_bug} x={itemXCoord + 5} y={barPos + 3} />}
        {item?.reference?.coldstart && <ColdstartIcon width='12' height='12' stroke={colors('chart', 'primary')} fill={colors('chart', 'primary')} x={itemXCoord + 5} y={barPos + 3} />}
        {showContent
          ? (
            <text
              x={item.error || item.coldstart ? itemXCoord + 20 : itemXCoord + 5}
              y={textPos}
              fill={item.color}
              fontSize='10px'
            >
              {`${item.content} (${formatDuration(item.end - item.start)})`}
            </text>)
          : null}
      </g>
    </>)
})

export default SegmentBar
