import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { dashboardsPaths as paths, DASHBOARDS_LIST } from './index'
import { useHistory } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

const createDashboard = async (account, payload) => {
  const data = apiFetch(paths.dashboards(account), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateDashboardQuery = (handleListClose) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const routes = useAccountRoutes()
  const { account } = useOrganization()

  return useMutation(
    payload => createDashboard(account.id, payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([...DASHBOARDS_LIST, account.id])
        history.push(routes.dashboards.dashboard.url({ dashboardId: data?.id }))
        handleListClose()
      }
    }
  )
}
