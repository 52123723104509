import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { uniqBy } from 'lodash'
import classnames from 'classnames'
import { Checkbox, Col, Row, Tooltip } from 'antd'

import useQueryParams from 'lib/hooks/use-query-params'
import { findInventoryByType, INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'
import { AwsIcon, GlobeIcon, InfoIcon } from 'components/icons/font-awesome'
import { Typography } from 'components/typography'
import Select from 'components/antd/select'
import RadioSmall from 'components/antd/radio/small'

import { PILLARS } from 'containers/insights/dashboard/pillars'
import { ERROR_TYPES, SEVERITY_TYPES } from 'lib/event-constants'
import { useAllResourcesQuery, useDelegationsQuery, useResourceGroupsQuery } from 'hooks/api'

import styles from './styles.module.less'

export const FILTERS_TYPE = {
  pillar: 'pillar',
  status: 'status',
  service: 'service',
  region: 'region',
  delegation: 'delegation'
}

const STATUSES = [
  { key: 'ok', value: 'OK' },
  { key: SEVERITY_TYPES.CRITICAL, value: 'Critical' },
  { key: SEVERITY_TYPES.WARNING, value: 'Warning' },
  { key: SEVERITY_TYPES.INFO, value: 'Info' }
]

const SEVERITIES = [
  { key: SEVERITY_TYPES.CRITICAL, value: 'Critical' },
  { key: SEVERITY_TYPES.WARNING, value: 'Warning' }
]

const getAvailableServiceGroups = (resources) => {
  const uniqTypes = uniqBy(resources, 'type')?.map(item => item?.type)
  const services = uniqTypes.map(type => findInventoryByType(type)).filter(item => item)
  return Object.values(INVENTORY_SERVICE_GROUPS).filter(group => services.map(service => service.service === group.service))
}

const getDelegationsItems = (delegations) => delegations?.map(del => {
  return {
    id: del.id,
    value: del.id,
    title: del?.name || del?.provider?.account || del?.id
  }
})

const getGroupsItems = (groups) => groups?.map(group => (
  {
    id: group.id,
    value: group.id,
    title: group.title
  }
))

const ListFilters = ({ filters = FILTERS_TYPE, view = 'inventory' }) => {
  const { pathname } = useLocation()
  const history = useHistory()

  const { data: delegationsData } = useDelegationsQuery()
  const { data: resourcesData } = useAllResourcesQuery()
  const { data: resourceGroupsData } = useResourceGroupsQuery()

  const { getValue: selectedPillars, setValue: setPillars } = useQueryParams('pillar', [])
  const { getValue: selectedSeverity, setValue: setSeverity } = useQueryParams('severity', [])
  const { getValue: selectedServices, setValue: setServices } = useQueryParams('service', [])
  const { getValue: selectedRegions, setValue: setRegions } = useQueryParams('region', [])
  const { getValue: selectedDelegations, setValue: setDelegations } = useQueryParams('delegation', [])
  const { getValue: selectedGroups, setValue: setGroups } = useQueryParams('group', [])
  const { getValue: selectedTypes, setValue: setTypes } = useQueryParams('type', [])
  const { getValue: selectedErrorStatus, setValue: setErrorStatus } = useQueryParams('status')
  const { getValue: searchQuery } = useQueryParams('search')

  const serviceGroups = getAvailableServiceGroups(resourcesData ? Object.values(resourcesData) : [])
  const regions = uniqBy(resourcesData ? Object.values(resourcesData) : [], 'region')?.map(resource => resource?.region)
  const delegations = getDelegationsItems(delegationsData)
  const resourceGroupsItems = getGroupsItems(resourceGroupsData)

  const handleClear = () => {
    if (searchQuery) return history.replace(`${pathname}?search=${searchQuery}`)
    history.replace(pathname)
  }

  return (
    <div className={styles.container} id='db-list-filters'>
      <button className={classnames(styles.clear_btn, (filters.pillar || filters.severity) && styles.absolute)} onClick={() => handleClear()}>Clear filters</button>
      {filters[FILTERS_TYPE.pillar] &&
        <>
          <Typography.Paragraph className={styles.label}>Pillars</Typography.Paragraph>
          <div className={classnames(styles.checkboxes, styles.filter_wrapper, styles.pillars)}>
            <Checkbox.Group onChange={(value) => setPillars(value)} value={selectedPillars}>
              {PILLARS.map(pillar =>
                <Checkbox key={pillar.key} value={pillar.key}>
                  {pillar.name}
                </Checkbox>
              )}
            </Checkbox.Group>
          </div>
        </>}

      {filters[FILTERS_TYPE.status] &&
        <>
          <div className={styles.flex}>
            <Typography.Paragraph className={styles.label}>Status</Typography.Paragraph>
            <Tooltip title='Status OK shows insight rules without violations' placement='right'><InfoIcon className={styles.info_icon} /></Tooltip>
          </div>
          <div className={classnames(styles.checkboxes, styles.filter_wrapper)}>
            <Checkbox.Group onChange={(value) => setSeverity(value)} defaultValue={selectedSeverity} value={selectedSeverity}>
              {STATUSES.map(status =>
                <Checkbox key={status.key} value={status.key} className={styles[status.key]}>
                  {status.value}
                </Checkbox>
              )}
            </Checkbox.Group>
          </div>
        </>}

      {filters?.errorType && filters?.errorStatus &&
        <Row gutter={8}>
          <Col xs={16} lg={8}>
            <div className={classnames(styles.filter_wrapper)}>
              <Typography.Paragraph className={styles.label}>Status</Typography.Paragraph>
              <RadioSmall
                name='muted' onChange={e => setErrorStatus(e.target.value)} value={selectedErrorStatus || 'open'}
                items={[
                  { label: 'Open', value: 'open' },
                  { label: 'Resolved', value: 'resolved' }
                ]}
              />
            </div>
          </Col>
          <Col xs={16} lg={8}>
            <div className={classnames(styles.filter_wrapper)}>
              <Typography.Paragraph className={styles.label}>Severity</Typography.Paragraph>
              <div className={classnames(styles.checkboxes)}>
                <Checkbox.Group onChange={(value) => setSeverity(value)} defaultValue={selectedSeverity} value={selectedSeverity}>
                  {SEVERITIES.map(status =>
                    <Checkbox key={status.key} value={status.key} className={styles[status.key]}>
                      {status.value}
                    </Checkbox>
                  )}
                </Checkbox.Group>
              </div>
            </div>
          </Col>
        </Row>}

      {filters?.errorType &&
        <>
          <Typography.Paragraph className={styles.label}>Error type</Typography.Paragraph>
          <div className={classnames(styles.checkboxes, styles.filter_wrapper, styles.pillars)}>
            <Checkbox.Group onChange={(value) => setTypes(value)} value={selectedTypes}>
              {Object.entries(ERROR_TYPES)?.map(([key, name]) =>
                <Checkbox key={key} value={key.toLowerCase()}>
                  {name}
                </Checkbox>
              )}
            </Checkbox.Group>
          </div>
        </>
      }

      {filters[FILTERS_TYPE.service] &&
        <Select
          mode='multiple'
          label='Services'
          defaultValue={selectedServices}
          value={selectedServices}
          onChange={value => setServices(value)}
          options={serviceGroups.map(group => ({ ...group, value: group?.service }))}
          className={styles.filter_wrapper}
          getPopupContainer={triggerNode => triggerNode?.parentNode}
          solid allowClear
        />}

      {filters[FILTERS_TYPE.region] &&
        <Select
          mode='multiple'
          label={view === 'insights' ? 'Violated Regions' : 'Regions'}
          defaultValue={selectedRegions}
          value={selectedRegions}
          options={regions}
          optionRender={({ item }) => <><GlobeIcon className={styles.option_icon} />{item?.title || item}</>}
          onChange={value => setRegions(value)}
          className={styles.filter_wrapper}
          getPopupContainer={triggerNode => triggerNode?.parentNode}
          solid allowClear
        />}

      {filters[FILTERS_TYPE.delegation] &&
        <Select
          mode='multiple'
          defaultValue={selectedDelegations}
          value={selectedDelegations}
          label={view === 'insights' ? 'Violated Delegations' : 'Delegations'}
          options={delegations}
          optionRender={({ item }) => <><AwsIcon className={styles.option_icon} />{item?.title || item}</>}
          onChange={value => setDelegations(value)}
          className={(view === 'inventory' || view === 'errors') && styles.filter_wrapper}
          getPopupContainer={triggerNode => triggerNode?.parentNode}
          solid allowClear
        />}
      {filters.group &&
        <Select
          mode='multiple'
          defaultValue={selectedGroups}
          value={selectedGroups}
          label='Resource groups'
          options={resourceGroupsItems}
          onChange={value => setGroups(value)}
          getPopupContainer={triggerNode => triggerNode?.parentNode}
          solid allowClear
        />}
    </div>
  )
}

export default ListFilters
