import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'

import { eventsStoragePaths, REQUEST_ACTIVE } from './index'
import { useQuery } from 'react-query'
import queryString from 'query-string'
import { paths } from 'hooks/api'

export const getRequest = async (account, request) => {
  if (!request) return {}
  const query = queryString.stringify({ requestId: request })
  const data = await apiFetch(paths.filter(eventsStoragePaths.requests(account), query))
  return data[0] || {}
}

export const useRequestQuery = (request) => {
  const { account } = useOrganization()

  return useQuery(
    [...REQUEST_ACTIVE, request],
    () => getRequest(account?.id, request),
    {
      staleTime: 60 * 1000,
      enabled: !!account?.id && !!request,
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false // take away later
    }
  )
}
