import React, { useContext } from 'react'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb'
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'

import Metrics from 'components/metrics'
import { formatDuration } from 'components/charts/utils'
import { ScrollContext } from 'components/layout/content'

const InsightMetrics = ({ count, rule, loading }) => {
  const { fixed: fixedHeader } = useContext(ScrollContext)

  const frequency = rule?.frequency
    ? [{ title: 'frequency', icon: faClock, value: formatDuration(rule?.frequency * 60000) }]
    : []

  const threshold = rule?.threshold
    ? [{ title: 'threshold', icon: faSigma, value: rule.threshold }]
    : []

  const tabs = [{
    title: 'violations',
    icon: faBellExclamation,
    loading,
    value: count,
    selected: !fixedHeader
  },
  ...threshold,
  {
    title: 'severity',
    icon: faExclamationTriangle,
    value: rule?.severity.toUpperCase()
  }, {
    title: 'pillar',
    icon: faLightbulb,
    value: rule?.pillar.toUpperCase()
  },
  ...frequency
    // comment back in when units have been configured
    // ...threshold
  ]

  return (
    <>
      <Metrics items={tabs} />
      {fixedHeader && <Metrics items={tabs} fixed />}
    </>
  )
}

export default InsightMetrics
