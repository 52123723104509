import { useUpdateIntegrationQuery } from 'hooks/api'
import { useEffect, useState } from 'react'

const useMutations = () => {
  const { mutate: updateIntegration, isLoading: isUpdating } = useUpdateIntegrationQuery()
  const [isUpdatingInterval, setIsUpdatingInterval] = useState(false)
  const [isUpdatingRegions, setIsUpdatingRegions] = useState(false)
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

  useEffect(() => {
    if (!isUpdating) {
      setIsUpdatingInterval(false)
      setIsUpdatingRegions(false)
      setIsUpdatingFilters(false)
      setIsUpdatingStatus(false)
    }
  }, [isUpdating])

  const handlers = {
    update: {
      interval: (value) => {
        setIsUpdatingInterval(true)
        updateIntegration({ interval: value })
      },
      regions: (value) => {
        setIsUpdatingRegions(true)
        updateIntegration({ filters: value })
      },
      filters: (value) => {
        setIsUpdatingFilters(true)
        updateIntegration({ filters: value })
      },
      status: (value) => {
        setIsUpdatingStatus(true)
        updateIntegration({ disabled: value })
      },
      loading: isUpdating,
      isUpdatingInterval,
      isUpdatingRegions,
      isUpdatingFilters,
      isUpdatingStatus
    }
  }

  return handlers
}

export default useMutations
