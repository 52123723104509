import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { delegationPaths as paths, DELEGATIONS_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getDelegations = async (account) => {
  const data = await apiFetch(paths.delegations(account))
  return data?.data
}

export const useDelegationsQuery = (options = {}, customAccountId) => {
  const { account } = useOrganization()
  const accountId = customAccountId || account?.id
  return useQuery(
    [...DELEGATIONS_LIST, accountId],
    () => getDelegations(accountId),
    {
      staleTime: Infinity,
      enabled: !!accountId,
      retry: false,
      ...options
    }
  )
}
