
export const INSIGHTS_FILTERS = [
  { key: 'pillar', filter: (selected, rule) => selected.pillar?.includes(rule?.pillar) },
  { key: 'severity', filter: (selected, rule) => selected.severity?.includes('ok') ? selected.severity?.includes(rule?.severity) || rule.count === 0 : (selected.severity?.includes(rule?.severity) && rule.count > 0) },
  {
    key: 'service',
    filter: (selected, rule) => {
      if (selected.severity?.includes('ok')) return selected.service?.map(service => rule.id.includes(service)).some(item => item)
      return selected.service?.map(service => rule.id.includes(service)).some(item => item) && rule.count > 0
    }
  },
  { key: 'region', filter: (selected, rule) => rule?.regions?.map(reg => selected.region?.includes(reg)).some(item => item) },
  {
    key: 'delegation',
    filter: (selected, rule) => {
      if (selected.severity?.includes('ok')) return rule?.delegations?.map(del => selected.delegation?.includes(del)).some(item => item)
      return rule?.count > 0 && rule?.delegations?.map(del => selected.delegation?.includes(del)).some(item => item)
    }
  }
]
