import React, { useContext, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Row, Col } from 'antd'
import { differenceInMilliseconds, sub } from 'date-fns'

import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Empty from 'components/layout/content/empty'
import Actions from './actions'
import Metrics from './metrics'
import Chart from './chart'
import SimilarErrorsList from './similar-errors'
import GlobalDatePicker from 'components/date-picker/global'
import ErrorOccurrences from './occurrences'
import StackTrace from './stacktrace'

import { useAccountRoutes } from 'containers/routes'
import { findInventoryByType } from 'lib/resources/constants'
import { useErrorQuery, useResourceQuery } from 'hooks/api'
import { ErrorMetricsContext } from '../index'

import styles from './styles.module.less'

const EmptyError = ({ loading }) => {
  return (
    <Content item title='Errors' breadcrumbs={['error']} loading={loading}>
      <Empty title='We could not find an error with this id.' />
    </Content>
  )
}

const Subtitle = ({ error, routes }) => {
  if (!error || !error.resource || !error.resource.type) return null

  const name = error.resource?.title || error?.resource?.name

  if (!name) {
    return 'Resource not found'
  }

  return <Link to={routes.inventory.resource.url({ resourceId: error.resource.id })} className={styles.resource_link}>{name}</Link>
}

const ErrorDetails = () => {
  const routes = useAccountRoutes()
  const { errorId } = useParams()

  const { dashboardStart, dashboardEnd, dashboardRelativeSpan } = useContext(ErrorMetricsContext)

  const [start, setStart] = useState(dashboardStart)
  const [end, setEnd] = useState(dashboardEnd)
  const [range, setRange] = useState(dashboardRelativeSpan)
  const [fixedChart, setShowFixedChart] = useState(false)
  const [relativeSpan, setRelativeSpan] = useState(dashboardRelativeSpan)

  const { data: error, isLoading: eventLoading } = useErrorQuery(errorId)
  const { isLoading: resourceLoading } = useResourceQuery(error?.resource?.id || error?.resource)
  const service = findInventoryByType(error?.resource?.type)?.id

  const loading = eventLoading || resourceLoading || !error

  const onDateChange = (start, end, relativeSpan) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMilliseconds(end, start))
    setRelativeSpan(relativeSpan || false)
  }

  const getBreadcrumbs = useMemo(() => {
    const delegationName = error?.resource?.delegationName
    const region = error?.resource?.region

    return [delegationName, region]
  }, [resourceLoading, error?.resource?.delegationName])

  return (
    <Content
      loading={loading}
      item={isEmpty(error) || !errorId ? null : (error)}
      title={error?.name}
      subtitle={<Subtitle error={error} routes={routes} />}
      renderEmpty={() => <EmptyError loading={loading} />}
      breadcrumbs={getBreadcrumbs}
      breadcrumbsLoading={resourceLoading}
      backRoute={routes.errors.url()}
      fixed
      actions={
        <GlobalDatePicker
          max={new Date()}
          min={sub(new Date(), { months: 1 })}
          start={start}
          end={end}
          onChange={onDateChange}
          refetchHidden
          relativeSpan={relativeSpan}
        />}
      titleRowActions={<Actions item={error} />}
    >
      <Section cancelMargin>
        <Metrics error={error} data={{}} start={start} end={end} onFixedSelect={() => setShowFixedChart(!fixedChart)} />
      </Section>
      <Section solid disableUserSelection>
        <Chart error={error} start={start} end={end} range={range} showFixed={fixedChart} />
      </Section>
      <StackTrace error={error} service={service} />
      <Row gutter={[8, 8]}>
        <Col xs={24} md={12}>
          <ErrorOccurrences service={service} error={error} className={styles.cancel_margin} />
        </Col>
        <Col xs={24} md={12}>
          <SimilarErrorsList />
        </Col>
      </Row>
    </Content>
  )
}

export default ErrorDetails
