import React from 'react'
import { Row, Col, Typography } from 'antd'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const Subtotal = ({ amount, currency } = {}) => {
  return (
    <Row>
      <Col span={12}>
        <Text>Subtotal</Text>
      </Col>
      <Col span={12}>
        <Text>{formatStripePrice(amount, currency)}</Text>
      </Col>
    </Row>
  )
}

export default Subtotal
