import { useOrganization } from 'hooks/context/organization-context'
import queryString from 'query-string'
import { apiFetch, omitEmpty } from 'lib/action-helpers'

import { paths } from '../index'
import { eventsStoragePaths, RESOLVERS_LIST } from './index'
import { useQuery } from 'react-query'
import { isEmpty } from 'lodash'

export const getResolvers = async (account, filters = {}) => {
  const query = queryString.stringify(omitEmpty(filters))
  const data = await apiFetch(paths.filter(eventsStoragePaths.resolvers(account), query))
  return !isEmpty(data) ? data : []
}

export const useResolversQuery = (filters) => {
  const { account } = useOrganization()

  return useQuery(
    [...RESOLVERS_LIST, filters, account?.id],
    () => getResolvers(account?.id, filters),
    {
      staleTime: 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 60 * 1000,
      refetchOnWindowFocus: false // take away later
    }
  )
}
