import React from 'react'
import { Tooltip } from 'antd'
import { includes } from 'lodash'
import classnames from 'classnames'
import styles from './styles.module.less'

const COLORS = ['green', 'red', 'blue']

const Tag = ({ value, color, tooltip, className } = {}) => {
  const defaultClassName = includes(COLORS, color) ? color : 'default'
  return (
    <Tooltip title={tooltip}>
      <div
        className={classnames(styles[defaultClassName], className)}
      >
        {value}
      </div>
    </Tooltip>
  )
}

export default Tag
