import React from 'react'
import { flatten } from 'lodash'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import StackTrace from 'features/log'

import { useSearchExecutionsQuery } from 'hooks/api'

const LastOccurredStackTrace = ({ service, error }) => {
  if (service !== INVENTORY_SERVICES.Lambda.id) return null

  const { data, isLoading: invocationsLoading } = useSearchExecutionsQuery(
    error && {
      filters: { errorHash: error?.hash },
      service: INVENTORY_SERVICES.Lambda.service,
      limit: 1
    }
  )

  const invocation = flatten(data?.pages?.map(page => page.data))?.[0]
  return (
    <StackTrace
      title='Stack trace'
      loading={invocationsLoading}
      text={!invocationsLoading && invocation?.stackTrace}
      isStacktrace
      collapsed={false}
    />
  )
}

export default LastOccurredStackTrace
