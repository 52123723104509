import numeral from 'numeral'
import { sum } from 'lodash'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faMemory } from '@fortawesome/pro-light-svg-icons/faMemory'

import { getLast, aggregateSparkline, getSparklineByName } from '../helpers'

export const S3_BUCKET_SIZE = 'S3_BUCKET_SIZE_STANDARD'
export const S3_BUCKET_OBJECTS = 'S3_BUCKET_OBJECTS'

export const SIZE_METRIC_KEYS = [
  'S3_BUCKET_SIZE_STANDARD',
  'S3_BUCKET_SIZE_STANDARDIA',
  'S3_BUCKET_SIZE_ONEZONEIA',
  'S3_BUCKET_SIZE_GLACIER',
  'S3_BUCKET_SIZE_DEEPARCHIVE'
]

export const formatObjectCount = (value) => numeral(value).format('0.[00]a')
export const formatBucketSize = (value) => numeral(value).format('0.[0] b')

const metrics = [{
  key: S3_BUCKET_OBJECTS,
  icon: faSigma,
  title: 'Object count',
  filter: (collection) => {
    return formatObjectCount(getLast(collection, 'S3_BUCKET_OBJECTS'))
  },
  subvalue: (collection) => getSparklineByName(collection, 'S3_BUCKET_OBJECTS'),
  selectable: true
}, {
  key: S3_BUCKET_SIZE,
  icon: faMemory,
  title: 'Bucket size',
  filter: (collection) => {
    return formatBucketSize(sum(SIZE_METRIC_KEYS.map((metric) => getLast(collection, metric))))
  },
  subvalue: (collection) => aggregateSparkline(collection, sum, ...SIZE_METRIC_KEYS),
  selectable: true
}]

export default metrics
