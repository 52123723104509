import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { ORGANIZATIONS_LIST, organizationsPaths as paths } from './index'
import { useLocation } from 'react-router-dom'
import { ROUTES } from 'containers/routes'
import { isEmpty } from 'lodash'
import { findById, getLocalStorage, useOrganization } from 'hooks/context/organization-context'
import { useUser } from 'hooks/context/user-context'

const getOrganizations = async () => {
  return await apiFetch(paths.organizations())
}

export const useOrganizationsQuery = (options = {}) => {
  const { pathname } = useLocation()
  const { setOrgAndAccount } = useOrganization()
  const { user, authenticated } = useUser()

  return useQuery(
    [...ORGANIZATIONS_LIST, user?.username],
    () => getOrganizations(),
    {
      staleTime: Infinity,
      enabled: !!authenticated && !!user,
      ...options,
      onSuccess: async (organizations) => {
        // sets value to local storage and organization context
        if (organizations?.length === 0) {
          return setOrgAndAccount({}, {})
        }

        // Get account by URL path
        const path = pathname.split('/')
        const accountId = path[1]
        const rootRoutes = Object.keys(ROUTES).map(key => ROUTES[key].path)

        // Set org and account to account parsed from URL path
        if (accountId && !rootRoutes.includes(`/${accountId}`) && accountId !== 'auth' && accountId !== 'invite') {
          const [organization, account] = findById(organizations, accountId)
          if (!account) {
            setOrgAndAccount(organizations[0]?.organization, account)
            return
          }

          return setOrgAndAccount(organization, account)
        }

        // Get from local storage
        const data = getLocalStorage(organizations)
        if (data.account && !isEmpty(findById(organizations, data?.account?.id))) {
          setOrgAndAccount(data.organization, data.account)
          return
        }

        // Select first account
        const permission = organizations[0]
        setOrgAndAccount(permission?.organization || {}, permission?.accounts[0] || {})
      }
    }
  )
}
