export const POLLING_INTERVALS = [{
  title: '5 minutes',
  value: 5
}, {
  title: '10 minutes',
  value: 10
}, {
  title: '15 minutes',
  value: 15
}, {
  title: '30 minutes',
  value: 30
}, {
  title: '1 hour',
  value: 60
}, {
  title: '4 hour',
  value: 240
}, {
  title: '12 hours',
  value: 720
}]
