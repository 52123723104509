import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { apiFetch } from 'lib/action-helpers'
import { NOTIFICATION_CHANNEL_LIST, channelsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const updateNotificationChannel = async (account, identity, payload) => {
  const data = await apiFetch(paths.channel(account, identity), { method: 'PUT', body: JSON.stringify(payload) })

  return data
}

export const useUpdateNotificationChannelQuery = () => {
  const queryClient = useQueryClient()
  const { channelId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    (payload) => updateNotificationChannel(account.id, channelId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...NOTIFICATION_CHANNEL_LIST, account.id])
      }
    }
  )
}
