export default Object.freeze({
  'us-east-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'us-east-2': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'us-west-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'us-west-2': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'af-south-1': {
    requests: 0.27,
    duration: 0.0000221
  },
  'ap-east-1': {
    requests: 0.28,
    duration: 0.00002292
  },
  'ap-south-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'ap-northeast-2': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'ap-southeast-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'ap-southeast-2': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'ap-northeast-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'ca-central-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'eu-central-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'eu-west-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'eu-west-2': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'eu-west-3': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'eu-south-1': {
    requests: 0.23,
    duration: 0.0000195172
  },
  'eu-north-1': {
    requests: 0.23,
    duration: 0.0000195172
  },
  'me-south-1': {
    requests: 0.25,
    duration: 0.0000206667
  },
  'sa-east-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'us-gov-east-1': {
    requests: 0.2,
    duration: 0.0000166667
  },
  'us-gov-west-1': {
    requests: 0.2,
    duration: 0.0000166667
  }
})
