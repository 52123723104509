import { useUpdateDelegationQuery } from 'hooks/api'
import { useEffect, useState } from 'react'

const useMutations = (delegationId) => {
  const { mutate: updateDelegation, isLoading: isUpdating } = useUpdateDelegationQuery(delegationId)
  const [isUpdatingName, setIsUpdatingName] = useState(false)
  const [isUpdatingRegions, setIsUpdatingRegions] = useState(false)
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false)

  useEffect(() => {
    if (!isUpdating) {
      setIsUpdatingName(false)
      setIsUpdatingRegions(false)
      setIsUpdatingFilters(false)
    }
  }, [isUpdating])

  const handlers = {
    update: {
      name: (value) => {
        setIsUpdatingName(true)
        updateDelegation({ name: value?.trim() })
      },
      regions: (value) => {
        setIsUpdatingRegions(true)
        updateDelegation({ filters: value })
      },
      filters: (value) => {
        setIsUpdatingFilters(true)
        updateDelegation({ filters: value })
      },
      loading: isUpdating,
      isUpdatingName,
      isUpdatingRegions,
      isUpdatingFilters
    }
  }

  return handlers
}

export default useMutations
