import React from 'react'
import { Form, Col, Row } from 'antd'

import Email from 'containers/auth/form-items/email'
import Password from 'containers/auth/form-items/password'
import ConfirmPassword from 'containers/auth/form-items/confirm-password'
import Submit from 'components/antd/form/submit'
import AuthTitle from '../title'
import Input from 'components/antd/input'
import { Typography } from 'components/typography'

import styles from '../styles.module.less'

const Register = ({ loading, onSubmit }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  const validatePasswords = (rule, value) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(rule.message))
  }

  return (
    <>
      <AuthTitle title='Setting up your profile' />
      <Form form={form} onFinish={onSubmitWrapper} layout='vertical' requiredMark=''>
        <Email />
        <Password />
        <ConfirmPassword validator={validatePasswords} />
        <Row gutter={[8, 8]} >
          <Col lg={12} flex={1}>
            <Form.Item label='First name' name='givenName'>
              <Input solid />
            </Form.Item>
          </Col>
          <Col lg={12} flex={1}>
            <Form.Item label='Last name' name='familyName'>
              <Input solid />
            </Form.Item>
          </Col>
        </Row>
        <Submit loading={loading} text='Register' titleUpperCase className={styles.submit_wrapper} />
        <Typography.Paragraph size='xs' align='center'>
          By signing up, I accept the
          <a href='https://www.iubenda.com/terms-and-conditions/16910346' target='_blank' rel='noopener noreferrer' className={styles.link}>Dashbird Terms of Service</a>
          and acknowledge the
          <a href='https://www.iubenda.com/privacy-policy/16910346' target='_blank' rel='noopener noreferrer' className={styles.link}>Privacy Policy</a>
        </Typography.Paragraph>
      </Form>
    </>
  )
}

export default Register
