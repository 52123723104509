import React from 'react'
import { ReactSVG } from 'react-svg'

import classnames from 'classnames'
import { filterInventoryByService } from 'lib/resources/constants'
import { AwsIcon } from './font-awesome'
import styles from './styles.module.less'

enum IconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export const Icon = ({
  service,
  size = IconSize.MD,
  solid,
  className
}: {
  service: string,
  size?: IconSize,
  solid?: boolean,
  className?: string
}) => {
  const icon = service && filterInventoryByService(service)?.[0]?.icon

  if (!icon) return <AwsIcon className={classnames(styles.icon, styles.default_aws)} />

  return (
    <ReactSVG
      src={`/static/vendor/aws/${icon}`}
      className={classnames(
        styles.icon,
        styles.aws,
        {
          [styles[size]]: !!size,
          [styles.solid]: !!solid
        }, className
      )}
    />
  )
}
