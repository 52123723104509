import { get } from 'lodash'

const prepareDynamic = (subscription, usage, price, type) => {
  const roundedUsage = Math.ceil(get(usage, type))
  const typePrice = get(price, type, {})
  const subscriptionItem = get(subscription, ['items', type])
  return {
    key: type,
    usage: roundedUsage,
    quantity: get(subscriptionItem, 'quantity', 0),
    unitPrice: get(typePrice, 'amount', 0),
    currency: typePrice.currency,
    subscriptionId: subscription.stripeId,
    subscriptionItemId: get(subscriptionItem, 'stripeId'),
    canCancel: get(subscription, 'items.inventory') && get(subscription, 'items.dataIngestion')
  }
}

const prepareData = (subscription, usage = {}, price = {}) => {
  return [
    {
      name: 'Resources',
      unit: 'resource',
      ...prepareDynamic(subscription, usage, price, 'inventory')
    },
    {
      name: 'Data ingestion',
      unit: 'GB',
      ...prepareDynamic(subscription, usage, price, 'dataIngestion')
    }
  ]
}

export default prepareData
