import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AccountIcon } from 'components/icons'

import styles from './styles.module.less'
import { useOrganization } from 'hooks/context/organization-context'

export const useSwitchAccount = ({ organizations }) => {
  const history = useHistory()
  const location = useLocation()
  const { account, setOrgAndAccount } = useOrganization()

  const mutation = {
    switchAccount: (keyPath) => {
      const [item, parentKey] = keyPath
      if (parentKey === 'accounts') {
        const selectedOrganizationId = item.split(':')?.[0]
        const selectedAccountId = item.split(':')?.[1]
        if (account?.id === selectedAccountId) return

        const selectedOrganization = organizations?.find(item => item.organization.id === selectedOrganizationId)
        const selectedAccount = selectedOrganization?.accounts?.find(item => item.id === selectedAccountId)
        const [url, accountId, page] = location.pathname?.split('/') || []
        const replaceWith = `${url}/${selectedAccount.id}/${page || ''}`
        history.replace(replaceWith)

        setOrgAndAccount(selectedOrganization?.organization, selectedAccount)
      }
    }
  }

  return mutation
}

const accountPickerMenuItems = ({ organizations, account, organization }) => {
  return [
    { type: 'divider' },
    {
      key: 'accounts',
      popupClassName: styles.accounts_list,
      label: (
        <div className={styles.current}>
          <div className={styles.group} style={{ width: 80 }}>
            <span className={styles.title}>Organization</span>
            <span className={styles.text}>{organization && organization.name}</span>
          </div>
          <span className={styles.arrow}>→</span>
          <div className={styles.group}>
            <span className={styles.title}>Account</span>
            <span className={styles.text}>{account && account.name}</span>
          </div>
        </div>
      ),
      children: organizations?.reduce((result, item) => {
        result.push({ type: 'divider' })
        result.push({ type: 'group', label: <span className={styles.organization}>{item.organization.name}</span> })
        result.push({ type: 'divider' })

        item.accounts.forEach(acc => result.push({
          key: `${item.organization.id}:${acc.id}`,
          label: <span className={styles.account}><AccountIcon className={styles.account_icon} /> {acc.name}</span>
        }))

        return result
      }, [])
    },
    { type: 'divider' }
  ]
}

export default accountPickerMenuItems
