import React from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { Form } from 'antd'

import ConfirmPassword from 'containers/auth/form-items/confirm-password'
import Password from 'containers/auth/form-items/password'
import GenericFormButton from 'components/antd/form/generic-form-button'
import Submit from 'components/antd/form/submit'
import AuthTitle from '../title'

const ForgotPasswordConfirm = ({ loading, onSubmit, onCancel }) => {
  const [form] = Form.useForm()

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
  }

  const validatePasswords = (rule, value) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(rule.message))
  }

  return (<>
    <AuthTitle title='Create new password' />
    <Form form={form} onFinish={onSubmitWrapper} layout='vertical'>
      <Password />
      <ConfirmPassword validator={validatePasswords} />
      <Submit loading={loading} text='Submit new password' icon={<CheckOutlined />} titleUpperCase />
      {onCancel && <GenericFormButton onClick={onCancel} text='Cancel' titleUpperCase />}
    </Form>
  </>
  )
}

export default ForgotPasswordConfirm
