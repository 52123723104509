import React from 'react'
import { List } from 'antd'

import Loading from 'components/layout/content/loading'
import Empty from 'components/billing/stripe/empty'
import Method from './method'

const PaymentMethodList = ({ methods, loading, showOverlay }) => {
  if (loading) {
    return <Loading theme='light' />
  }

  if (!methods.length) {
    return <Empty onClick={showOverlay} text='Payment method is not set up yet. Click here to add payment method.' />
  }

  const canDelete = methods.length > 1
  return (
    <>
      <List
        split
        dataSource={methods}
        renderItem={(method) => (
          <Method data={method} canDelete={canDelete} />
        )}
      />
    </>
  )
}

export default PaymentMethodList
