import React from 'react'
import Numeral from 'numeral'

import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SQS_PAYLOAD } from 'containers/inventory/details/resource/metrics-tabs/services/sqs'

const PayloadChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    size: data.SQS_PAYLOAD_SIZE
  })

  if (!metrics.length) return <Empty height={height} />

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [SQS_PAYLOAD]: metric.size_avg,
      diff: [metric.size_min, metric.size_max]
    }
  })

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff') {
      const format = (num) => Numeral(num).format('0.[00] b')
      return [`${format(value[0])} - ${format(value[1])}`, undefined]
    }
    return [Numeral(value).format('0.[00] b'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0 b'
      span={span}
      tabKey={SQS_PAYLOAD}
      formatTab={value => `${Numeral(value).format('0.[00] b')}`}
      formatTooltip={valueFormatter}
    >
      <Line type='monotone' dataKey={SQS_PAYLOAD} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average' dot={false} />
      <Area type='monotone' dataKey='diff' dot={false} fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} name='Min - max' />
    </ChartContainer>
  )
}

export default PayloadChart
