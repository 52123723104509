import React, { useState, useEffect, useRef } from 'react'
import { Form, message } from 'antd'
import { v4 as uuid } from 'uuid'

import Input from 'components/antd/input'
import ProgressIndicator from './progress-indicator'
import AddNewModal from '../../components/add-new-modal'
import { useCreateAccountQuery, useCreateDelegationQuery, useDelegationsQuery } from 'hooks/api'
import { useUser } from 'hooks/context/user-context'
import ConnectAws from 'containers/onboarding/connect-aws'
import Polling from 'containers/settings/account/integrations/delegation/polling'
import styles from './styles.module.less'

const AddAccountModal = ({ visible, setVisible }) => {
  const didMount = useRef(false)

  const { mutateAsync: createDelegation, isLoading: creatingDelegation } = useCreateDelegationQuery()

  const { mutateAsync: createAccountFetch, isLoading: loadingCreateAccount } = useCreateAccountQuery()

  const { refreshCurrentSession, session } = useUser()

  const [step, setStep] = useState(0)
  const [accountName, setAccountName] = useState('Production')
  const [accountIdHolder, setAccountIdHolder] = useState(null)
  const [accountId, setAccountId] = useState(null)

  const [externalId, setExternalId] = useState(uuid)
  const [delegationName, setDelegationName] = useState('')
  const [pollingDelegations, setPollingDelegations] = useState(false)

  const { data: delegations } = useDelegationsQuery({ refetchInterval: pollingDelegations }, accountId)

  const createAccount = async () => {
    try {
      const accountResponse = await createAccountFetch({ name: accountName })

      refreshCurrentSession()
      setAccountIdHolder(accountResponse?.id)
    } catch { }
  }

  useEffect(() => {
    if (didMount?.current) {
      setAccountId(accountIdHolder)
    } else {
      didMount.current = true
    }
  }, [session])

  const closeModal = () => {
    setVisible(false)
    setStep(0)
    setAccountName('')
    setAccountId(null)
  }

  const accountIsSetup = accountId
  const steps = [
    {
      title: 'Account info',
      next: {
        disabled: !accountName,
        title: 'Add',
        loading: loadingCreateAccount,
        action: createAccount
      }
    },
    { title: 'Setup', back: { disabled: true }, next: { disabled: !accountIsSetup } },
    { title: 'Connect to AWS', back: { disabled: true } }
  ]

  const handlePollingDelegations = (value) => {
    setPollingDelegations(30 * 1000)
    setDelegationName(value)
  }

  const handleCreateDelegationManual = (values) => {
    const { externalId, arn, delegationName } = values
    setDelegationName(delegationName)
    setExternalId(externalId)
    createDelegation({ name: delegationName, externalId, roleArn: arn, accountId })
  }

  const handleDelegationsChanged = () => {
    const createdDelegation = delegations?.find(item => item.account === accountId)
    if (!createdDelegation) return
    setPollingDelegations(false)

    closeModal()
    message.success('Stack created, hold on while we start fetching your resources')
  }

  const cancelStackCreation = () => {
    setPollingDelegations(false)
  }

  useEffect(handleDelegationsChanged, [delegations])

  return (
    <AddNewModal
      title={'New account'}
      visible={visible}
      closeModal={closeModal}
      steps={steps}
      currentStep={step}
      setStep={setStep}
      destroyOnClose
    >
      {step === 0 &&
        <div>
          <Form layout='vertical'>
            <Form.Item label='Enter new account name:' >
              <Input placeholder='Account name' solid value={accountName} onChange={e => setAccountName(e.target.value)} />
            </Form.Item>
          </Form>
        </div>}
      {step === 1 &&
        <div className={styles.progress}>
          <div className={styles.progressItems}>
            <ProgressIndicator completed={!!accountId} text={'Creating your Dashbird account'} />
            <ProgressIndicator completed={!!accountId} text={'Creating default notification settings'} />
          </div>
        </div>
      }
      {step === 2 && <div className={styles.launchStack}>
        {
          !pollingDelegations && !creatingDelegation
            ? <ConnectAws
              handlePollingDelegations={handlePollingDelegations}
              createDelegation={handleCreateDelegationManual}
              externalId={externalId}
              delegationName={delegationName}
              customAccountId={accountId}
              addDelegation />
            : <Polling cancel={cancelStackCreation} polling={pollingDelegations} />
        }
      </div>
      }
    </AddNewModal>
  )
}

export default AddAccountModal
