import React from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import Input from 'components/antd/input'

const Credentials = ({ loading, onSubmit }) => {
  const [form] = Form.useForm()

  const validatePasswords = (rule, value) => {
    if (!value || form.getFieldValue('password') === value) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(rule.message))
  }

  const onSubmitWrapper = async (values) => {
    onSubmit(values)
    form.resetFields()
  }

  return (
    <Form form={form} onFinish={onSubmitWrapper} layout='vertical'>
      <Form.Item name='current' label={'Current password'}
        rules={[{
          required: true,
          message: 'Current password is required'
        }]}>
        <Input type='password' />
      </Form.Item>
      <Form.Item name='password' label={'New password'}
        rules={[{
          required: true,
          message: 'New password is required'
        }]}>
        <Input type='password' />
      </Form.Item>
      <Form.Item name='confirm' label={'Confirm new password'}
        rules={[{
          required: true,
          message: 'Confirm new password is required',
          validateTrigger: 'onChange'
        }, {
          validator: validatePasswords,
          message: 'Passwords do not match'
        }]}>
        <Input type='password' />
      </Form.Item>

      <Button type='primary' htmlType='submit' loading={loading}>Save changes <SaveOutlined /></Button>
    </Form>
  )
}

export default Credentials
