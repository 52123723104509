/**
 * @property {Response} response
 * @property {Array} args
 * @property {Object} json
 */
export class ApiError extends Error {
  /**
   * @param {Response} response
   * @param {Array} args
   * @param {Object} json
   */
  constructor (response, args, json) {
    const method = args.find(arg => arg.method).method

    const methodPrefix = method ? `${method} ` : ''
    const reason = json ? `: ${JSON.stringify(json)}` : ''

    super(`${methodPrefix}${response.url} ${response.status}${reason}`)

    this.name = 'APIError'
    this.response = response
    this.args = args
    this.json = json
  }
}
