import { useQuery } from 'react-query'
import queryString from 'query-string'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch, omitEmpty } from 'lib/action-helpers'

import { paths } from '../index'
import { eventsStoragePaths, INVOCATION_RETRIES } from './index'

export const getRetries = async (account, identity, resourceId) => {
  const query = queryString.stringify(omitEmpty({ resource: resourceId }))

  const data = await apiFetch(paths.filter(eventsStoragePaths.retries(account, identity), query))
  return data
}

export const useInvocationRetriesQuery = (hash, resourceId, isActive) => {
  const { account } = useOrganization()

  return useQuery(
    [...INVOCATION_RETRIES, hash, account?.id],
    () => getRetries(account?.id, hash, resourceId),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!hash && !!resourceId && !!isActive,
      cacheTime: 1 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
