import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form } from 'antd'

import { SCOPES, PermissionsGate } from 'features/permissions-gate'
import Input from 'components/antd/input'
import { Typography } from 'components/typography'

const submit = (values, handlers) => {
  handlers.update({ name: values.name })
}

const Connect = () => {
  return (
    <>
      <Typography.Paragraph>
        Upload Dashbird Bot package to your Teams:
      </Typography.Paragraph>
      <Typography.Paragraph>
        <a href={'/static/teams-dashbird-bot.zip'}>
          Dashbird Bot Package
        </a>
      </Typography.Paragraph>
      <Typography.Paragraph>
        Or add Dashbird to your Teams via Teams Apps (coming soon)
      </Typography.Paragraph>
    </>
  )
}

const TeamsBot = ({ channel, handlers, saving }) => {
  const history = useHistory()

  if (!channel) return <Connect />

  return (
    <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
      <Form onFinish={(values) => submit(values, handlers)} layout='vertical' initialValues={{ name: channel?.name, uri: channel?.uri }}>
        <Form.Item name='name' label='Name' rules={[{ required: true }]}>
          <Input placeholder='name' />
        </Form.Item>
        <Form.Item name='uri' label='URI' rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={saving}>Save</Button>
          <Button type='link' onClick={history.goBack}>Cancel</Button>
        </Form.Item>
      </Form>
    </PermissionsGate>
  )
}

export default TeamsBot
