import { useMutation, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { useParams } from 'react-router-dom'

import { apiBatchFetch } from 'lib/action-helpers'
import { ERRORS_LIST, ERROR_ACTIVE } from './index'
import { eventPaths as paths } from '../index'
import useQueryParams from 'lib/hooks/use-query-params'

const updateErrors = async (account, payload) => {
  const data = await apiBatchFetch(paths.events(account), { method: 'PATCH', body: JSON.stringify(payload) })
  return data
}

export const useErrorsBatchUpdate = () => {
  const { errorId } = useParams()
  const queryClient = useQueryClient()
  const { getValue: status } = useQueryParams('status', 'open')

  const { account } = useOrganization()

  return useMutation(
    (payload) => updateErrors(account.id, payload),
    {
      onMutate: payload => {
        const previousErrors = queryClient.getQueryData([...ERRORS_LIST, status, account.id])
        const openError = queryClient.getQueryData([...ERROR_ACTIVE, errorId])

        const changedIds = payload.map(item => item.id)
        const updatedStatus = payload[0].status

        queryClient.setQueryData([...ERRORS_LIST, status, account.id], [...(previousErrors || []).map(error => {
          if (changedIds?.includes(error.id)) return { ...error, status: updatedStatus }
          return error
        })])

        if (changedIds.includes(errorId)) {
          queryClient.setQueryData([...ERROR_ACTIVE, errorId], { ...openError, status: updatedStatus })
        }
        return { previousErrors, openError }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...ERRORS_LIST, status, account.id], context.previousErrors)
          queryClient.setQueryData([...ERROR_ACTIVE, errorId], context.openError)
        }
      },
      onSuccess: () => queryClient.invalidateQueries([...ERROR_ACTIVE, errorId]),
      onSettled: () => {
        queryClient.invalidateQueries([...ERRORS_LIST, status, account.id])
      }
    }
  )
}
