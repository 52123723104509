import React from 'react'
import classnames from 'classnames'
import { ReactSVG } from 'react-svg'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import styles from './styles.module.less'

const ListItem = ({ item }) => {
  const Container = ({ item, children }) => {
    if (!item.url) {
      return <div className={styles.container}>{children}</div>
    }

    return <Link to={item.url} className={styles.container}>{children}</Link>
  }

  const Icon = ({ item }) => {
    if (typeof item.icon === 'string') {
      return <ReactSVG
        src={item.icon}
        className={classnames(
          styles.icon,
          styles[`icon_${item.type}`],
          item.priority === 'low' && styles.yellow,
          item.priority === 'high' && styles.red
        )}
      />
    }

    return <div className={classnames(
      styles.icon,
      styles[`icon_${item.type}`],
      item.priority === 'low' && styles.yellow,
      item.priority === 'high' && styles.red
    )}>
      {item.icon}
    </div>
  }

  return (
    <Container item={item}>
      <Icon item={item} />
      <div className={styles.sub_container}>
        <div className={styles.info_container}>
          <div>{item.info}</div>
          <p className={styles.info_small}>{item.details}</p>
        </div>
        <Tooltip title={item.title} mouseEnterDelay={1}>
          <h4 className={styles.title}>{item.title}</h4>
        </Tooltip>
      </div>
    </Container>
  )
}

export default ListItem
