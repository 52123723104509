import numeral from 'numeral'

import { faDownload, faMemory, faUpload, faMicrochip, faClock, faFloppyDisk, faServer, faWavePulse } from '@fortawesome/pro-light-svg-icons'

import { getSparklineByName, getLast, getDifferenceFromTotal, getDifferenceFromTotalSparkline, getSum } from '../helpers'

export const KDA_CPU_UTILIZATION = 'kda-cpu-utilization'
export const KDA_HEAP_MEM_UTILIZATION = 'kda-heap-memory-utilization'
export const KDA_CHECKPOINT_DURATION = 'kda-checkpoint-duration'
export const KDA_CHECKPOINT_SIZE = 'kda-checkpoint-size'
export const KDA_CHECKPOINT_FAILURE = 'kda-checkpoint-failure'
export const KDA_INCOMING_RECORDS_RATE = 'kda-incoming-records-rate'
export const KDA_OUTGOING_RECORDS_RATE = 'kda-outgoing-records-rate'
export const KDA_INCOMING_RECORDS = 'kda-incoming-records'
export const KDA_OUTGOING_RECORDS = 'kda-outgoing-records'
export const KDA_UPTIME = 'kda-uptime'
export const KDA_DOWNTIME = 'kda-downtime'
export const KDA_RESTARTS = 'kda-restarts'
export const KDA_TIME_BUSY = 'kda-time-busy'
export const KDA_TIME_BACKPRESSURED = 'kda-time-backpressured'
export const KDA_KPUS = 'kda-kpus'

const metrics = [{
  key: KDA_CPU_UTILIZATION,
  icon: faMicrochip,
  title: 'CPU',
  filter: (collection) => `${numeral(getLast(collection, KDA_CPU_UTILIZATION)).format('0.[00]a')}%`,
  subvalue: (collection) => getSparklineByName(collection, KDA_CPU_UTILIZATION),
  selectable: true
}, {
  key: KDA_HEAP_MEM_UTILIZATION,
  icon: faMemory,
  title: 'Memory',
  filter: (collection) => `${numeral(getLast(collection, KDA_HEAP_MEM_UTILIZATION)).format('0.[00]a')}%`,
  subvalue: (collection) => getSparklineByName(collection, KDA_HEAP_MEM_UTILIZATION),
  selectable: true
}, {
  key: KDA_TIME_BUSY,
  icon: faClock,
  title: 'Busy time',
  filter: (collection) => {
    const ms = getSum(collection, KDA_TIME_BUSY)
    return `${numeral(ms / 1000 / 60).format('0.[00]')}m`
  },
  subvalue: (collection) => getSparklineByName(collection, KDA_TIME_BUSY),
  selectable: true
}, {
  key: KDA_UPTIME,
  icon: faWavePulse,
  title: 'Uptime',
  filter: (collection) => {
    const ms = getLast(collection, KDA_UPTIME)
    return `${numeral(ms / 1000 / 60 / 60).format('0')}h`
  },
  subvalue: (collection) => getSparklineByName(collection, KDA_UPTIME),
  selectable: true
}, {
  key: KDA_INCOMING_RECORDS,
  icon: faDownload,
  title: 'Incoming records',
  filter: (collection) => {
    const diffFromTotal = getDifferenceFromTotal(collection, KDA_INCOMING_RECORDS)
    const sum = getSum({ ...collection, [KDA_INCOMING_RECORDS]: diffFromTotal }, KDA_INCOMING_RECORDS)

    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getDifferenceFromTotalSparkline(collection, KDA_INCOMING_RECORDS),
  selectable: true
}, {
  key: KDA_OUTGOING_RECORDS,
  icon: faUpload,
  title: 'Outgoing records',
  filter: (collection) => {
    const diffFromTotal = getDifferenceFromTotal(collection, KDA_OUTGOING_RECORDS)
    const sum = getSum({ ...collection, [KDA_OUTGOING_RECORDS]: diffFromTotal }, KDA_OUTGOING_RECORDS)

    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getDifferenceFromTotalSparkline(collection, KDA_INCOMING_RECORDS),
  selectable: true
}, {
  key: KDA_CHECKPOINT_DURATION,
  icon: faFloppyDisk,
  title: 'Checkpoint duration',
  filter: (collection) => {
    const ms = getLast(collection, KDA_CHECKPOINT_DURATION)
    return `${numeral(ms).format('0')}ms`
  },
  subvalue: (collection) => getSparklineByName(collection, KDA_CHECKPOINT_DURATION),
  selectable: true
}, {
  key: KDA_KPUS,
  icon: faServer,
  title: 'KPUs',
  filter: (collection) => numeral(getLast(collection, KDA_KPUS)).format('0'),
  subvalue: (collection) => getSparklineByName(collection, KDA_KPUS),
  selectable: true
}]

export default metrics
