import React, { useState } from 'react'
import { keyBy, get, map } from 'lodash'
import { Card, Skeleton, Button, Divider, Typography } from 'antd'

import RouterLink from 'components/router-link'
import ResourceTable from './resource-table'
import UsageTable from './usage-table'
import Calculator from './calculator'

import { BILLABLE_SERVICES } from 'lib/billing-constants'

import styles from './styles.module.less'

const { Text } = Typography

const prepareResourceData = (usage) => {
  const inventory = get(usage, 'inventory', [])
  const resourcesWithUsage = keyBy(inventory, item => item.type)

  return map(BILLABLE_SERVICES, (typeConfig, type) => {
    return {
      key: type,
      type,
      name: `${typeConfig.name} (${type})`,
      count: get(resourcesWithUsage, [type, 'count'], 0)
    }
  })
}

const prepareUsageData = (usage, estimated) => {
  const key = 'AWS::Lambda::Function'
  const currentUsageInGb = get(usage, 'dataIngestion', 0) / ((1024 ** 3) * 1.0)
  const estimatedUsageInGb = get(estimated, 'dataIngestion', 0)
  return [{
    key,
    type: key,
    current: currentUsageInGb.toFixed(2),
    currentNotFixed: currentUsageInGb,
    estimated: estimatedUsageInGb.toFixed(2),
    estimatedNotFixed: estimatedUsageInGb,
    name: `${get(BILLABLE_SERVICES, [key, 'name'])} (${key})`
  }]
}

const Title = () => {
  return (
    <div className={styles.title}>
      <Text strong>Usage</Text><Text className={styles.textSecondary} type='secondary'>All AWS accounts combined</Text>
    </div>
  )
}

const Usage = ({ usage, estimated, prices, loading, className } = {}) => {
  const [showCalculator, setShowCalculator] = useState(false)
  const resourceData = prepareResourceData(usage)
  const usageData = prepareUsageData(usage, estimated)

  return (
    <Skeleton loading={loading}>
      <Card
        title={<Title />}
        className={className}
        extra={[
          <RouterLink key='configure' to='/settings/integrations'>Configure</RouterLink>,
          <Button
            key='cost-calculator'
            type='primary'
            size='small'
            onClick={() => setShowCalculator(!showCalculator)}
            className={styles.calculatorBtn}
          >Cost calculator</Button>
        ]}
      >
        <ResourceTable data={resourceData} />
        <Divider />
        <UsageTable data={usageData} />
        <Calculator
          prices={prices}
          inventoryData={resourceData}
          dataIngestionData={usageData}
          onClose={() => setShowCalculator(false)}
          visible={showCalculator}
        />
      </Card>
    </Skeleton>
  )
}

export default Usage
