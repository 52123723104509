import React from 'react'

import styles from './styles.module.less'

const ContentError = ({ title }) => {
  return (
    <div className={styles.content}>
      <h2 className={styles.title}>{title}</h2>
    </div>
  )
}

export default ContentError
