import React, { useState, useEffect } from 'react'
import { Button, Col, Tooltip } from 'antd'
import classnames from 'classnames'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { ResolvedIcon, CloseIcon, MinusIcon } from 'components/icons'

import styles from './styles.module.less'

const DEFAULT_SPAN = 11

const getIcon = (error, disabled, parentDisabled) => {
  if (parentDisabled) return <MinusIcon />
  if (error) return <CloseIcon color='#e9e8e8' />
  if (!disabled) return <ResolvedIcon color='#e9e8e8' />
  return ' '
}

const TooltipWrapper = ({ show, title, children }) => {
  if (!show) return children
  return (
    <Tooltip title={title} classname={styles.tooltip} style={{ display: 'flex' }}>
      {children}
    </Tooltip>
  )
}

const RegionButton = ({ region, errors, updating, handleChange }) => {
  const [buttonClicked, setButtonClicked] = useState(false)
  const [displayLoading, setDisplayLoading] = useState(false)

  useEffect(() => {
    if (!updating) {
      setDisplayLoading(false)

      if (buttonClicked) {
        setButtonClicked(false)
      }
    }
  }, [updating])

  const handleClick = () => {
    if (updating) return
    setButtonClicked(true)
    setDisplayLoading(true)
    handleChange({ region: region?.region, enable: region?.disabled })
  }

  if (!region) return <Col span={DEFAULT_SPAN} />

  const error = errors[region?.region]
  const buttonIcon = getIcon(error, region?.disabled, region?.parentDisabled)
  const buttonClassNames = classnames(styles.button, {
    [styles.button_active]: !error && (!region?.disabled && !region?.parentDisabled),
    [styles.button_error]: error
  })

  const showTooltip = region?.parentDisabled || error
  const tooltipTitle = region?.parentDisabled
    ? 'This region is disabled from delegation filters'
    : <span>{error?.reason}<br />Click to dismiss this error.</span>

  return (
    <TooltipWrapper show={showTooltip} title={tooltipTitle}>
      <Col span={DEFAULT_SPAN} className={styles.container}>
        <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ disabled: true }}>
          <Button
            loading={displayLoading && updating}
            shape='circle'
            size='small'
            className={buttonClassNames}
            disabled={region?.parentDisabled}
            onClick={handleClick}
          >
            {!(buttonClicked && updating) && buttonIcon}
          </Button>
        </PermissionsGate>
        <PermissionsGate
          scopes={[SCOPES.canManageIntegrationSettings]}
          errorProps={{ onClick: () => { }, className: classnames(styles.body, styles.uneditable) }}
        >
          <div
            className={classnames(styles.body, { [styles.uneditable]: region?.parentDisabled })}
            {...(!region?.parentDisabled && { onClick: handleClick })}
          >
            <h4>{region?.name}</h4>
            <span>{region?.region}</span>
          </div>
        </PermissionsGate>
      </Col>
    </TooltipWrapper>
  )
}

export default RegionButton
