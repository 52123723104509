import { clone } from 'lodash'
import RJSON from 'relaxed-json'

const MATCHES = /\$\$\$PRE\$\$\$(.*?)\$\$\$POS\$\$\$/g
const MATCH_ONE = /\$\$\$PRE\$\$\$(.*?)\$\$\$POS\$\$\$/

export function mapStr (str) {
  let clonedStr = clone(str)
  const matches = str.match(MATCHES) || []
  const mapped = []
  for (let i = 0; i < matches.length; i += 1) {
    const match = matches[i]
    const matchChunks = MATCH_ONE.exec(match)
    if (!matchChunks) {
      mapped.push({ type: 'raw', content: clonedStr })
      continue
    }
    const content = matchChunks[1]
    const index = clonedStr.indexOf(match)
    str = clonedStr.substr(0, index)
    mapped.push({ type: 'raw', content: str })
    mapped.push({ type: 'match', content })
    clonedStr = clonedStr.substr(index + match.length)
  }
  mapped.push({ type: 'raw', content: clonedStr })
  return mapped
}

export function extractTime (line) {
  const date = line.match(/^[0-9A-Z\:\.\-\+]{24}/) //eslint-disable-line
  if (!date) {
    return null
  }

  return date[0]
}

export function removeLineStart (line) {
  const res = line.replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/, '')
  return res.replace(/^[\t][0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}[\t]/i, '')
}

export function removeReqIdFromLine (line) {
  return line.replace(/(RequestId: )[^( \t)]*/, '')
}

export function isJsonString (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function clearStr (str) {
  return str.replace(/\$\$\$PRE\$\$\$/g, '').replace(/\$\$\$POS\$\$\$/g, '')
}

export function transformJson (potentialJson) {
  let transformedJson = null
  const potentialStringified = JSON.stringify(potentialJson)
  if (isJsonString(potentialStringified) && !isJsonString(potentialJson)) {
    try {
      transformedJson = RJSON.transform(potentialJson)
      JSON.parse(transformedJson)
    } catch (e) {
      transformedJson = null
    }
  }
  return transformedJson
}

export function stripMatchTags (string) {
  const temp = string.replace(/\$\$\$PRE\$\$\$/g, '')
  return temp.replace(/\$\$\$POS\$\$\$/g, '')
}

export const extractParsedJson = (line) => {
  const stripped = line.substring(line.indexOf('{'), line.lastIndexOf('}') + 1)

  let parsed = null

  try {
    parsed = JSON.parse(stripped)
  } catch (e) {
    console.log(e)
  }

  return parsed
}
