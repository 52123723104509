import React from 'react'

import { Dropdown, Switch } from 'antd'

import { PermissionsGate, SCOPES } from 'features/permissions-gate/index'
import { EllipsisVIcon } from 'components/icons'

import styles from './styles.module.less'

const Actions = ({ item, handlers }) => {
  if (!item) return null
  return (
    <>
      <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
        <Switch
          size='small'
          defaultChecked={!item.disabled}
          checked={!item.disabled}
          onChange={(value) => handlers.update({ disabled: !value })}
        />
      </PermissionsGate>
      <PermissionsGate scopes={[SCOPES.canManageNotificationSettings]} errorProps={{ disabled: true }}>
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'delete',
                label: 'Delete'
              }
            ],
            onClick: handlers.remove
          }}
        >
          <span className={styles.extra}><EllipsisVIcon /></span>
        </Dropdown>
      </PermissionsGate>
    </>
  )
}

export default Actions
