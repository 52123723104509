import React, { useState } from 'react'
import { keys, last, isEmpty } from 'lodash'

import { useOrgResourcesUsageQuery, useOrganizationUsersQuery, useAccountsQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import { prepareUsageData } from 'containers/settings/billing/subscriptions/usage'

import TableLayout from '../../components/table-layout'
import AddAccountModal from '../add-account-modal'
import { accessColumn, nameColumn, resourceCountColumn, statusColumn } from './columns'

const FILTER_KEYS = [
  { val: 'active', name: 'Active' },
  { val: 'disabled', name: 'Disabled' }
]

const AccountsTable = () => {
  const routes = useAccountRoutes()
  const { data: resourcesUsageByAccount, isLoading: loadingResourcesUsage } = useOrgResourcesUsageQuery()
  const { data: users, isLoading: loadingUsers } = useOrganizationUsersQuery()

  const { data: accounts, isLoading: loadingAccounts } = useAccountsQuery()

  const loading = loadingAccounts || loadingUsers || loadingResourcesUsage

  const [displayAccounts, setDisplayAccounts] = useState('all')
  const [accountModalVisible, setAccountModalVisible] = useState(false)

  const resourcesCount = Object.entries(resourcesUsageByAccount || {}).map(([accountId, values]) => {
    const lastDayRecords = last(values.data)?.records || []

    const { inventory } = prepareUsageData(lastDayRecords)
    const count = inventory.reduce((acc, item) => acc + item.count, 0)
    return {
      id: accountId, count
    }
  })

  const formattedAccounts = accounts?.map(account => {
    const usersList = users?.filter(user => keys(user.permissions).includes(account.id) || isEmpty(user.permissions))
    const resourceCount = resourcesCount.find(item => item.id === account.id)?.count || 0
    return {
      key: account.id,
      account: account.name,
      status: 'active',
      access: usersList || ['not available'],
      resources: resourceCount
    }
  }) || []

  const filteredByStatus = displayAccounts !== 'all' ? formattedAccounts?.filter(item => item.status === displayAccounts) : formattedAccounts

  return (
    <>
      <PermissionsGate
        scopes={[SCOPES.canCreateAccount, SCOPES.canEditAccount]}
        errorProps={{
          columns: [
            nameColumn,
            statusColumn,
            resourceCountColumn
          ]
        }}
      >
        <TableLayout
          columns={[
            nameColumn,
            statusColumn,
            accessColumn,
            resourceCountColumn
          ]}
          data={filteredByStatus}
          loading={loading}
          filterOptions={FILTER_KEYS}
          setDataFilter={setDisplayAccounts}
          setModalVisible={setAccountModalVisible}
          tableTitle={'account'}
          rowClickUrl={(record) => routes.settings['manage-accounts'].account.url({ id: record.key })}
          showAddButton
        />
      </PermissionsGate>
      <PermissionsGate scopes={[SCOPES.canCreateAccount]}>
        <AddAccountModal visible={accountModalVisible} setVisible={setAccountModalVisible} />
      </PermissionsGate>
    </>
  )
}

export default AccountsTable
