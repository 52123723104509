import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { apiFetch } from 'lib/action-helpers'

import { NOTIFICATION_POLICY, NOTIFICATION_POLICY_LIST, policyPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createNotificationPolicyChannel = async (account, policy, channel) => {
  const data = await apiFetch(paths.channel(account, policy, channel), { method: 'POST' })

  return data?.data
}

export const useCreateNotificationPolicyChannelQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ accountId = account.id, policyId, channelId }) => createNotificationPolicyChannel(accountId, policyId, channelId),
    {
      onMutate: ({ policyId, channelId }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])
        const previousPolicyList = queryClient.getQueryData([...NOTIFICATION_POLICY_LIST, account.id])

        const newPolicy = { ...previousPolicy, channels: [...previousPolicy.channels, channelId] }
        const newPolicyList = previousPolicyList?.map(item => {
          if (item.id !== policyId) return item
          return {
            ...item,
            channels: [...item.channels, channelId]
          }
        })
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)
        queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], newPolicyList)

        return { previousPolicy, previousPolicyList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
          queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicyList)
        }
      },
      onSuccess: (data) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], { ...previousPolicy, ...data })
      }
    }
  )
}

const deleteNotificationPolicyChannel = async (account, policy, channel) => {
  const data = await apiFetch(paths.channel(account, policy, channel), { method: 'DELETE' })

  return data?.data
}
export const useDeleteNotificationPolicyChannelQuery = () => {
  const queryClient = useQueryClient()
  const { policyId } = useParams()
  const { account } = useOrganization()

  return useMutation(
    ({ policyId, channelId }) => deleteNotificationPolicyChannel(account.id, policyId, channelId),
    {
      onMutate: ({ policyId, channelId }) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])
        const previousPolicyList = queryClient.getQueryData([...NOTIFICATION_POLICY_LIST, account.id])

        const newPolicy = { ...previousPolicy, channels: previousPolicy.channels.filter(channel => channel !== channelId) }
        const newPolicyList = previousPolicyList?.map(item => {
          if (item.id !== policyId) return item
          return {
            ...item,
            channels: item.channels.filter(item => item !== channelId)
          }
        })
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], newPolicy)
        queryClient.setQueryData([...NOTIFICATION_POLICY_LIST, account.id], newPolicyList)

        return { previousPolicy, previousPolicyList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicy)
          queryClient.setQueryData([NOTIFICATION_POLICY, policyId], context.previousPolicyList)
        }
      },
      onSuccess: (data) => {
        const previousPolicy = queryClient.getQueryData([NOTIFICATION_POLICY, policyId])
        queryClient.setQueryData([NOTIFICATION_POLICY, policyId], { ...previousPolicy, ...data })
      }
    }
  )
}
