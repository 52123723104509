import React from 'react'
import Numeral from 'numeral'
import { Area, Bar } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import ChartContainer from 'components/charts/container'
import { LAMBDA_INVOCATIONS } from 'containers/inventory/details/resource/metrics-tabs/services/lambda'

const InvocationsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    total: data.INVOCATIONS_TOTAL,
    errors: data.INVOCATIONS_FATAL_ERRORS,
    coldstarts: data.INVOCATIONS_COLDSTARTS
  })
  const calculatedMetrics = metrics.reduce((acc, value) => {
    acc.push({ ...value, [LAMBDA_INVOCATIONS]: value.total - value.errors })
    return acc
  }, [])

  return (
    <ChartContainer height={height}
      data={calculatedMetrics}
      span={span}
      tabKey={LAMBDA_INVOCATIONS}
      formatTab={value => Numeral(value).format('0.[0]a')}
      yTicksFormatting='0.[0]a'>
      <Bar dataKey={LAMBDA_INVOCATIONS} stackId='a' fill={colors('chart', 'primaryDark')} name='Successful' barSize={4} />
      <Bar dataKey='errors' stackId='a' fill={colors('chart', 'red')} name='Fatal Errors' barSize={4} />
      <Area type='step' dataKey='coldstarts' fill={colors('chart', 'primaryLight')} stroke={'none'} activeDot={false} name='Cold starts' />
    </ChartContainer>
  )
}

export default InvocationsChart
