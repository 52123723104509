import React from 'react'
import { countBy, flatten } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import Section from 'components/layout/content/section'
import { Delegation } from 'components/items/progress-item'
import { useAllResourcesQuery, useDelegationsQuery, useAlarmsRulesQuery } from 'hooks/api'

const AlarmsDelegations = () => {
  const { data: alarms } = useAlarmsRulesQuery()
  const { isLoading: loadingResources } = useAllResourcesQuery()
  const { data: delegations, isLoading: loadingDelegations } = useDelegationsQuery()

  const { getValue: selectedDelegations, setValue: setDelegations } = useQueryParams('delegation', [])

  const loadingGeneral = loadingResources || loadingDelegations

  const violatedDelegations = flatten(alarms?.map(alarm => {
    return alarm?.violations?.map(item => item?.resource?.delegation)?.filter(item => item)
  }))

  const countByDelegation = countBy(violatedDelegations)

  const items = Object.keys(countByDelegation).filter(item => item)?.map(item => {
    const delegation = delegations?.find(del => del.id === item)

    return {
      title: delegation?.provider?.account,
      description: delegation?.name,
      count: countByDelegation[item],
      total: violatedDelegations?.length,
      id: item
    }
  })

  const handleDelegationsFilter = (item) => {
    setDelegations(filterHelper(item, selectedDelegations))
  }

  return (
    <Section title='Delegations' loading={loadingGeneral} titleUpperCase>
      {items?.length !== 0
        ? items.sort((a, b) => b.count - a.count)?.map(delegation =>
          <Delegation
            {...delegation}
            key={delegation.id}
            selected={selectedDelegations}
            onSelect={handleDelegationsFilter}
          />
        )
        : <p>No violations found</p>}
    </Section>
  )
}

export default AlarmsDelegations
