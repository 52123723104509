import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { NOTIFICATION_CHANNEL_LIST, channelsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const deleteNotificationChannel = async (account, identity) => {
  const data = await apiFetch(paths.channel(account, identity), { method: 'DELETE' })

  return data
}

export const useDeleteNotificationChannelQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    (identity) => deleteNotificationChannel(account.id, identity),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...NOTIFICATION_CHANNEL_LIST, account.id])
      }
    }
  )
}
