import React from 'react'
import { isEmpty } from 'lodash'
import { Skeleton } from 'antd'

import Section from 'components/layout/content/section'
import { Item } from 'components/items'
import EndpointItem from './endpoint-item'
import ResolverItem from './resolver-item'
import { ProgressItem } from 'components/items/progress-item'

import styles from './styles.module.less'

const noItems = (title) => {
  return (
    <div className={styles.noitems}>No {title || 'items'} to show</div>
  )
}

const ItemByType = props => {
  const { type } = props
  if (type === 'endpoint') return <EndpointItem {...props} />
  if (type === 'resolver') return <ResolverItem {...props} />
  if (type === 'overview') return <ProgressItem {...props.item} />

  return <Item.Event {...props} />
}

const Items = ({ loading, title, items, type, resolved, className, loadingCount }) => {
  return <Section className={className} title={title} titleUpperCase>
    {loading
      ? <Skeleton active />
      : <>
        {!isEmpty(items)
          ? items?.map(item =>
            <ItemByType
              key={item.id}
              item={item}
              type={type}
              resolved={resolved}
              loadingCount={loadingCount}
            />)
          : noItems(title)}
      </>}
  </Section>
}

export default Items
