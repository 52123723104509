import { combineReducers } from 'redux'
import { GET_PROMOTION } from 'actions/billing.actions'

function promotion (state = [], action) {
  switch (action.type) {
    case GET_PROMOTION:
      return action.payload
  }
  return state
}

export default combineReducers({
  promotion
})
