import React from 'react'

import InvoiceList from './list'
import styles from './styles.module.less'

const Invoices = () => {
  return (
    <InvoiceList className={styles.list} />
  )
}

export default Invoices
