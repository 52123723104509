import NAMESPACES from '../../namespaces'
import STATISTICS from '../../statistics'

import {
  KDA_CPU_UTILIZATION,
  KDA_HEAP_MEM_UTILIZATION,
  KDA_CHECKPOINT_DURATION,
  KDA_CHECKPOINT_SIZE,
  KDA_CHECKPOINT_FAILURE,
  KDA_INCOMING_RECORDS_RATE,
  KDA_OUTGOING_RECORDS_RATE,
  KDA_INCOMING_RECORDS,
  KDA_OUTGOING_RECORDS,
  KDA_UPTIME,
  KDA_DOWNTIME,
  KDA_RESTARTS,
  KDA_TIME_BUSY,
  KDA_TIME_BACKPRESSURED,
  KDA_KPUS,
} from '../../../metrics-tabs/services/kinesis-analytics'

const metrics = [{
  id: KDA_CPU_UTILIZATION,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'cpu.utilization',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: KDA_HEAP_MEM_UTILIZATION,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'memory.heap.utilization',
    stats: [STATISTICS.AVERAGE, STATISTICS.MIN, STATISTICS.MAX]
  }
}, {
  id: KDA_UPTIME,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'uptime',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_DOWNTIME,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'downtime',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_RESTARTS,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'restarts',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_INCOMING_RECORDS_RATE,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.records.rate',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: KDA_OUTGOING_RECORDS_RATE,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.records.rate',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: KDA_INCOMING_RECORDS,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'incoming.records',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_OUTGOING_RECORDS,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'outgoing.records',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_CHECKPOINT_DURATION,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'checkpoint.duration',
    stats: [STATISTICS.AVERAGE]
  }
}, {
  id: KDA_CHECKPOINT_SIZE,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'checkpoint.size',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_CHECKPOINT_FAILURE,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'checkpoint.failures',
    stats: [STATISTICS.MAX]
  }
}, {
  id: KDA_TIME_BUSY,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'time.busy',
    stats: [STATISTICS.SUM],
  }
}, {
  id: KDA_TIME_BACKPRESSURED,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'time.backpressure',
    stats: [STATISTICS.SUM],
  }
}, {
  id: KDA_KPUS,
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'kpus',
    stats: [STATISTICS.MAX]
  }
}]

export default metrics
