
import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, CUSTOMER } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getCustomer = async (organization, identity) => {
  const data = await apiFetch(paths.customer(organization, identity))

  return data
}

export const useGetCustomerQuery = ({ customerId }) => {
  const { organization } = useOrganization()

  return useQuery(
    [...CUSTOMER, organization.id],
    () => getCustomer(organization?.id, customerId),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!customerId,
      retry: false
    }
  )
}
