import React from 'react'

import { Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { DYNAMOBD_LATENCY } from 'containers/inventory/details/resource/metrics-tabs/services/dynamodb'
import { formatDuration } from 'components/charts/utils'

const MessagesChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    scans: data.DYNAMODB_LATENCY_SCAN,
    queries: data.DYNAMODB_LATENCY_QUERY,
    [DYNAMOBD_LATENCY]: data.DYNAMODB_LATENCY_ITEM_GET,
    puts: data.DYNAMODB_LATENCY_ITEM_PUT,
    updates: data.DYNAMODB_LATENCY_ITEM_UPDATE
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [formatDuration(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={DYNAMOBD_LATENCY}
      formatTab={formatDuration}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatDuration}
    >
      <Line type='monotone' dataKey='scans' stroke={colors('chart', 'primaryDark')} strokeWidth={1} name='Scans' dot={false} />
      <Line type='monotone' dataKey='queries' stroke={colors('chart', 'primaryLight')} strokeWidth={1} name='Queries' dot={false} />
      <Line type='monotone' dataKey={DYNAMOBD_LATENCY} stroke={colors('chart', 'yellow')} strokeWidth={1} name='Item gets' dot={false} />
      <Line type='monotone' dataKey='puts' stroke={colors('chart', 'green')} strokeWidth={1} name='Item puts' dot={false} />
      <Line type='monotone' dataKey='updates' stroke={colors('chart', 'red')} strokeWidth={1} name='Item updates' dot={false} />
    </ChartContainer>
  )
}

export default MessagesChart
