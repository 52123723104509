import React from 'react'

const Endpoint = ({ address, port, hostedZoneId }) => {
  return (
    <>
      <p>{address}</p>
      <p>PORT: {port}</p>
      <p>HOSTED ZONE ID: {hostedZoneId}</p>
    </>
  )
}

export default Endpoint
