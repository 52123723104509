import React, { useState } from 'react'
import { Card, Button } from 'antd'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { GET_CUSTOMER } from 'actions/billing.actions'

import Details from './details'
import Modal from './modal'

const UpdateButton = ({ address, showOverlay, loading } = {}) => {
  if (!address) return null

  return (
    <Button
      size='small'
      type='primary'
      disabled={loading}
      onClick={showOverlay}
    >Update</Button>
  )
}

const BillingInfo = ({ className }) => {
  const [overlay, setOverlay] = useState(false)
  const hideOverlay = () => setOverlay(false)
  const showOverlay = () => setOverlay(true)

  const loading = useSelector(state => get(state, ['waiting', 'list', GET_CUSTOMER]))
  const customer = useSelector(state => get(state, 'billing.customers.active'))

  const address = get(customer, 'address')

  return (
    <Card
      className={className}
      title='Billing information'
      extra={<UpdateButton address={address} showOverlay={showOverlay} loading={loading} />}
    >
      <Details
        loading={loading}
        customer={customer}
        showOverlay={showOverlay}
      />
      <Modal
        open={overlay}
        customer={customer}
        onOk={hideOverlay}
        onCancel={hideOverlay}
      />
    </Card>
  )
}

export default BillingInfo
