import React, { useEffect } from 'react'
import { Alert } from 'antd'
import { useLocation, Link } from 'react-router-dom'
import MarketingColumnLayout from 'components/layout/column-marketing/index'
import { useUser } from 'hooks/context/user-context'
import AuthHexagons from './hexagons'
import styles from './styles.module.less'

const LOGIN_TITLE = 'It\'s always nice to see you back'
const LOGIN_SUBTITLE = 'We have been keeping an eye on things for you'

const SIGNUP_TITLE = 'Welcome to the most widely used serverless monitoring platform on the planet!'

const AuthLayout = ({ children }) => {
  const { pathname } = useLocation()
  const { authMessage: message, setAuthMessage } = useUser()
  const isRegisterPage = pathname === '/auth/register'
  const isConfirmPage = pathname === '/auth/confirm'

  const title = isRegisterPage ? SIGNUP_TITLE : LOGIN_TITLE
  const subtitle = !isRegisterPage ? LOGIN_SUBTITLE : ''

  useEffect(() => {
    setAuthMessage(null)
  }, [pathname])

  return (
    <MarketingColumnLayout title={title} subtitle={subtitle} view={isRegisterPage ? 'register' : 'auth'} hexagons={isRegisterPage ? <AuthHexagons /> : null}>
      <div className={styles.form_wrapper}>
        {message && (
          <Alert
            className={styles.message}
            message={message.title}
            description={message.message}
            type={message.type} showIcon />)}

        {children}
      </div>
      {isRegisterPage || isConfirmPage
        ? <div className={styles.link}><Link to='/'>Sign in to existing account</Link></div>
        : (
          <div className={styles.link}>
            <Link to='/auth/register'>Sign up for account</Link>
          </div>)}
    </MarketingColumnLayout>
  )
}

export default AuthLayout
