import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Card } from 'antd'

import styles from './styles.module.less'

const PageError = ({ accountError, reset }) => {
  const errorCode = accountError ? 404 : 500
  const { pathname } = useLocation()

  const didMount = useRef(false)
  useEffect(() => {
    if (didMount?.current) {
      reset && reset()
    } didMount.current = true
  }, [pathname])

  return (
    <Card className={styles.error_body} bordered>
      <h3 className={styles.error_title}>Error has occured</h3>
      <h1 className={styles.error_code}>{errorCode}</h1>
      <p className={styles.error_description}>
        {errorCode === 404
          ? 'You have no permissions for this account, change account from menu or ask for permissions from your admin.'
          : 'The app encountered an internal error and was unable to complete your request. Dashbird team will be notified.'}
      </p>
    </Card>
  )
}

export default PageError
