import React from 'react'
import colors from 'lib/colors'

const RefLine = ({ activeCoordinate, height }) => {
  if (!activeCoordinate) return null
  const startY = 70
  const xAxisHeight = 38
  const tooltipHeight = height - startY - xAxisHeight
  return (
    <>
      <line x1={activeCoordinate.x} x2={activeCoordinate.x} y1={startY} y2={startY + tooltipHeight} stroke={colors('chart', 'primary')} />
      <circle r='5' cx={activeCoordinate.x} cy={75} fill={colors('chart', 'primary')} />
    </>
  )
}

export default RefLine
