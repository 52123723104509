import { get, first, groupBy } from 'lodash'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

export const getResourceName = resource => {
  if (resource.type !== INVENTORY_SERVICES.APIGatewayRest.type && resource.type !== INVENTORY_SERVICES.APIGatewayHttp.type) return resource.name

  const apiNameAttr = resource.attributes.filter(attr => attr.name === 'apiName')
  const apiName = get(first(apiNameAttr), 'value')
  return `${apiName} / ${resource.name}`
}

export const getDelegationName = (delegations, resource) => {
  return delegations?.find(del => del?.id === resource?.delegation)?.name ||
    delegations?.find(del => del?.id === resource?.delegation)?.provider?.account
}

export const transformResourceName = resource => {
  return {
    ...resource,
    title: getResourceName(resource)
  }
}

export const transformResourceDelegation = (resource, delegations) => {
  return {
    ...resource,
    delegationName: getDelegationName(delegations, resource)
  }
}

export const hasMoreDelegations = (resources) => {
  const groupedByDelegation = groupBy(resources, 'delegation')
  const delegationCount = Object.keys(groupedByDelegation).length

  return delegationCount > 1
}

export const getResourceService = resource => resource.type.split('::')[1]

export const resourceTitle = resource => resource?.title || resource?.name || '<resource deleted or missing>'
