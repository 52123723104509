import React from 'react'

import { Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { formatDuration } from 'components/charts/utils'
import { APPSYNC_RESOLVER_DURATION } from 'containers/inventory/details/resource/metrics-tabs/services/appsync-resolver'

const AppSyncResolverDurationChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [APPSYNC_RESOLVER_DURATION]: data.APPSYNC_RESOLVER_DURATION
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [formatDuration(value / 1000000), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={APPSYNC_RESOLVER_DURATION}
      formatYTicks={value => formatDuration(value / 1000000)}
      formatTab={value => formatDuration(value / 1000000)}
      formatTooltip={tooltipFormatter}
    >
      <Area type='monotone' dataKey={APPSYNC_RESOLVER_DURATION} fill={colors('chart', 'primary')} stroke={colors('chart', 'primary')} name='Duration' dot={false} />
    </ChartContainer>
  )
}

export default AppSyncResolverDurationChart
