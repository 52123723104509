import React, { useMemo } from 'react'
import { sub } from 'date-fns'

import { useMetricsFetcher } from 'containers/dashboards/hooks'
import { formatWidgetPayload } from 'containers/dashboards/hooks/use-mutations'
import { WIDGET_KIND } from 'lib/dashboards-constants'
import { MetricsWidgetChart } from 'features/widgets/kinds/metrics'

const start = sub(new Date(), { days: 1 })
const end = new Date()
const relativeSpan = 24 * 60
const refreshInterval = false

const ChartPreview = ({ className, resources, values }) => {
  const widget = useMemo(() => ({
    id: 'preview',
    kind: WIDGET_KIND.METRICS,
    layout: { width: 10, height: 5 },
    ...formatWidgetPayload({ creating: false, kind: WIDGET_KIND.METRICS, data: values })
  }), [values])

  const metrics = useMetricsFetcher({ widgets: [widget], resources, start, end, relativeSpan, refreshInterval })

  return (
    <div className={className}>
      <MetricsWidgetChart
        widget={widget}
        metrics={{ data: metrics.data, loading: metrics.loading || metrics.fetching }}
        options={{ range: relativeSpan, start, end }}
      />
    </div>
  )
}

export default ChartPreview
