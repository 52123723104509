const getName = (node) => {
  switch (node.name) {
    case 'Lambda':
      return node.aws?.function_name || node.name
    case 'DynamoDB':
      return node.aws?.table_name || node.name
  }

  return node.name
}

export { getName }
