import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { message } from 'antd'

import { useAccountRoutes } from 'containers/routes'
import { useDelegationQuery, useProviderRegionsQuery } from 'hooks/api'
import { isDelegationOutOfDate } from 'lib/delegation-helpers'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'

import EditableTitle from 'features/editable-title'
import { Typography } from 'components/typography'
import { Descriptions } from 'components/descriptions'
import Input from 'components/antd/input'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Metrics from 'components/metrics'
import { SettingsIcon, GlobeIcon, FilterIcon } from 'components/icons'
import UpgradeStack from './upgrade-stack'
import Integrations from './integrations'
import Regions from '../polling-regions'
import Filters from '../polling-filters'
import { useMutations } from './hooks'

import styles from './styles.module.less'

const TAB_CONFIG = 'configuration'
const TAB_REGIONS = 'regions'
const TAB_FILTERS = 'filters'

const regionsTabTitle = (regions, availableRegions) => {
  if (!availableRegions) return null
  if (!regions || !regions?.length || regions?.length === availableRegions) return 'ALL'
  return regions.length
}

const filtersTabTitle = (delegation) => {
  if (!delegation) return null
  if (!delegation?.filters?.whitelist && !delegation?.filters?.blacklist) return 'NONE'
  const count = (delegation?.filters?.whitelist?.length || 0) + (delegation?.filters?.blacklist?.length || 0)
  return count || 'NONE'
}

const Delegation = () => {
  const routes = useAccountRoutes()
  const { delegationId } = useParams()

  const { data: delegation, isLoading, refetch, isRefetching } = useDelegationQuery()
  const { data: regions, isLoading: isLoadingRegions } = useProviderRegionsQuery({ provider: delegation?.provider?.id }, { skip: !delegation })

  const handlers = useMutations(delegationId)
  const [showReloadBtn, setShowReloadButton] = useState(false)
  const [selectedTab, setSelectedTab] = useState(TAB_CONFIG)
  const canUpgrade = isDelegationOutOfDate(delegation)
  const availableRegions = regions?.ALL

  useEffect(() => {
    if (!isRefetching && delegation && showReloadBtn) {
      if (canUpgrade) {
        message.error('Stack is still out of date')
      } else {
        message.success('Stack upgraded successfully!')
      }
    }
  }, [isRefetching])

  const handleRegionsChange = (regions) => {
    const filters = { ...delegation?.filters, regions }
    handlers.update.regions(filters)
  }

  const handleFiltersChange = ({ whitelist, blacklist }) => {
    const filters = {
      ...delegation?.filters,
      ...(whitelist && { whitelist }),
      ...(blacklist && { blacklist })
    }
    handlers.update.filters(filters)
  }

  const metricsTabs = [
    { title: 'Configuration', icon: <SettingsIcon />, value: delegation?.provider?.account, selected: selectedTab === TAB_CONFIG, onClick: () => setSelectedTab(TAB_CONFIG), loading: isLoading },
    { title: 'Regions', icon: <GlobeIcon />, value: regionsTabTitle(delegation?.filters?.regions, availableRegions), selected: selectedTab === TAB_REGIONS, onClick: () => setSelectedTab(TAB_REGIONS), loading: isLoading },
    { title: 'Filters', icon: <FilterIcon />, value: filtersTabTitle(delegation), selected: selectedTab === TAB_FILTERS, onClick: () => setSelectedTab(TAB_FILTERS), loading: isLoading }
  ]

  return (
    <Content
      item
      breadcrumbs={['Settings', 'Integrations', 'AWS connection']}
      loading={isLoading || isLoadingRegions}
      backRoute={routes.settings.integrations.url()}
      title={
        <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]} errorProps={{ readOnly: true }}>
          <EditableTitle
            loading={handlers.update.isUpdatingName || isLoading}
            value={delegation?.name}
            displayContent={delegation?.name}
            onSubmit={handlers.update.name} />
        </PermissionsGate>
      }
    >
      {canUpgrade &&
        <PermissionsGate scopes={[SCOPES.canManageIntegrationSettings]}>
          <UpgradeStack
            delegation={delegation}
            onUpgrade={() => setShowReloadButton(true)}
            onRefresh={refetch}
            showReload={showReloadBtn}
            isReLoading={isRefetching}
          />
        </PermissionsGate>
      }
      <Section cancelMargin>
        <Metrics items={metricsTabs} />
      </Section>
      {selectedTab === TAB_CONFIG &&
        <Section solid>
          <Descriptions className={styles.configuration}>
            <Descriptions.Item layout='vertical' label='External ID' titleClassName={styles.label}>
              <Typography.Paragraph>
                <Input defaultValue={delegation?.provider?.secret} disabled />
              </Typography.Paragraph>
            </Descriptions.Item>
            <Descriptions.Item layout='vertical' label='Role ARN' titleClassName={styles.label}>
              <Typography.Paragraph>
                <Input defaultValue={delegation?.roleArn} disabled />
              </Typography.Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </Section>
      }
      {selectedTab === TAB_REGIONS &&
        <Regions
          regions={delegation?.filters?.regions}
          availableRegions={availableRegions}
          handleChange={handleRegionsChange}
          updating={handlers.update.isUpdatingRegions || isLoading}
        />
      }
      {selectedTab === TAB_FILTERS &&
        <Filters
          whitelist={delegation?.filters?.whitelist}
          blacklist={delegation?.filters?.blacklist}
          handleChange={handleFiltersChange}
          updating={handlers.update.isUpdatingFilters || isLoading}
        />
      }
      <Integrations />
    </Content>
  )
}

export default Delegation
