import React from 'react'

import { Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { formatDuration } from 'components/charts/utils'
import { APPSYNC_LATENCY } from 'containers/inventory/details/resource/metrics-tabs/services/appsync'

const AppSyncLatencyChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [APPSYNC_LATENCY]: data.APPSYNC_LATENCY
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [formatDuration(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={APPSYNC_LATENCY}
      formatYTicks={value => formatDuration(value)}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
    >
      <Area type='monotone' dataKey={APPSYNC_LATENCY} fill={colors('chart', 'red')} stroke={colors('chart', 'red')} name='Latency' dot={false} />
    </ChartContainer>
  )
}

export default AppSyncLatencyChart
