import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SET_PRIMARY_PAYMENT_METHOD } from 'actions/billing.actions'
import { API } from 'actions/api'
import { useOrganization } from 'hooks/context/organization-context'

import { Button, Tooltip } from 'antd'

const PrimaryButton = ({ entityId, isPrimary }) => {
  const dispatch = useDispatch()
  const { organization } = useOrganization()
  const loading = useSelector(state => state.waiting.list[SET_PRIMARY_PAYMENT_METHOD])

  const setPrimary = (paymentMethodId) => dispatch(API.organizations.id(organization.id).billing.payment.methods.id(paymentMethodId).setPrimary())

  const tooltipText = isPrimary ? 'Primary' : 'Set as primary'
  const onClick = () => !isPrimary && setPrimary(entityId)

  return (
    <Tooltip title={tooltipText}>
      <Button
        onClick={onClick}
        loading={loading}
        type='primary'
      />
    </Tooltip>
  )
}

export default PrimaryButton
