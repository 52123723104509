import React from 'react'
import Numeral from 'numeral'

import { Area } from 'recharts'

import { objectify } from 'lib/utils'
import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import Disclaimer from 'components/charts/disclaimer'
import ChartContainer from 'components/charts/container'
import { KDA_TIME_BUSY, KDA_TIME_BACKPRESSURED } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const MIN_FLINK_RUNTIME_REQUIRED = 1.13

const parseRuntimeToFloat = (runtime) => {
  if (!runtime.startsWith('FLINK')) return null

  const splitRuntime = runtime.split('-')
  const stringRuntimeNumber = splitRuntime[1].replace('_', '.') || null
  const floatRuntimeNumber = parseFloat(stringRuntimeNumber) || null

  return floatRuntimeNumber
}

const KinesisAnalyticsBusyBackPressuredTime = ({ loading, data, item, height, span }) => {
  if (loading || !data) return null

  const attributes = objectify(item.attributes)
  const runtime = attributes.runtimeEnvironment || null

  if (parseRuntimeToFloat(runtime) < MIN_FLINK_RUNTIME_REQUIRED) {
    return (
      <Disclaimer
        height={height}
        text={`Not available for runtime ${runtime}`}
      />
    )
  }

  const metrics = transform({
    [KDA_TIME_BUSY]: data[KDA_TIME_BUSY],
    [KDA_TIME_BACKPRESSURED]: data[KDA_TIME_BACKPRESSURED]
  })

  if (!metrics.length) return <Empty height={height} />

  const formatTooltip = value => `${Numeral(value / 1000).format('00:00:00')}`
  const formatTab = value => `${Numeral(value / 1000 / 60).format('0.[00]')}m`

  const tooltipFormatter = (value) => {
    return [formatTooltip(value), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={KDA_TIME_BUSY}
      formatTab={formatTab}
      formatTooltip={tooltipFormatter}
      formatYTicks={formatTooltip}
    >
      <Area type='monotone' dataKey={KDA_TIME_BUSY} fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} name='Busy time' dot={false} />
      <Area type='monotone' dataKey={KDA_TIME_BACKPRESSURED} fill={colors('chart', 'redWithOpacity')} stroke={colors('chart', 'red')} strokeWidth={0.5} name='Back pressured time' dot={false} />
    </ChartContainer>
  )
}

export default KinesisAnalyticsBusyBackPressuredTime
