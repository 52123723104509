import React from 'react'
import classnames from 'classnames'

import { formatStripePrice } from 'lib/billing-helpers'

import styles from '../styles.module.less'

const intervalAbbr = {
  month: 'mo',
  year: 'yr'
}

const InvoiceLine = ({ description, amount, currency, interval, total }: {
  description: string
  amount: number
  currency: string
  interval?: 'year' | 'month'
  total?: boolean
}) => {
  return (
    <div className={styles.invoice_line}>
      <p className={classnames(total ? styles.total_title : '')}>{description}: </p>
      <p className={classnames(styles.text, styles.align_right, total && styles.total_title)}>{formatStripePrice(amount, currency)}{interval && `/${intervalAbbr[interval]}`}</p>
    </div>
  )
}

export default InvoiceLine
