import { reduce } from 'lodash'

export function objectify (collection, nameKey = 'name', valueKey = 'value') {
  return reduce(collection, (acc, cur) => {
    acc[cur[nameKey]] = cur[valueKey]
    return acc
  }, {})
}

export const formatBoolean = (data) => data === 'true' ? true : data === 'false' ? false : data
