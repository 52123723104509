import numeral from 'numeral'

import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'
import { faBug } from '@fortawesome/pro-light-svg-icons/faBug'
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'

import { formatDuration } from 'components/charts/utils'

import { getValues, getSparklineByName, average, getSum } from '../helpers'

export const STEPFUNCTIONS_EXECUTION = 'stepfunctions-execution'
export const STEPFUNCTIONS_ERRORS = 'stepfunctions-errors'
export const STEPFUNCTIONS_DURATION = 'stepfunctions-duration'

const metrics = [{
  key: STEPFUNCTIONS_EXECUTION,
  icon: faSigma,
  title: 'Executions',
  filter: (collection) => {
    const sum = getSum(collection, 'STEPFUNCTIONS_EXECUTIONS_STARTED')

    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'STEPFUNCTIONS_EXECUTIONS_STARTED'),
  selectable: true
}, {
  key: STEPFUNCTIONS_ERRORS,
  icon: faBug,
  title: 'Errors',
  filter: (collection) => {
    const sum = getSum(collection, 'STEPFUNCTIONS_EXECUTIONS_FAILED')
    return numeral(sum).format('0.[00]a')
  },
  subvalue: (collection) => getSparklineByName(collection, 'STEPFUNCTIONS_EXECUTIONS_FAILED'),
  selectable: true
}, {
  key: STEPFUNCTIONS_DURATION,
  icon: faClock,
  title: 'Duration',
  filter: (collection) => {
    const values = getValues(collection, 'STEPFUNCTIONS_EXECUTIONS_DURATION')

    const valuesList = values?.map(value => {
      return value.value
    })
    return formatDuration(average(valuesList))
  },
  subvalue: (collection) => getSparklineByName(collection, 'STEPFUNCTIONS_EXECUTIONS_DURATION'),
  selectable: true
}]

export default metrics
