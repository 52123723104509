export const ROLES = {
  viewer: 'viewer',
  user: 'user',
  admin: 'admin',
  owner: 'owner'
}

export const SCOPES = {
  canCreate: 'can-create',
  canEdit: 'can-edit',
  canDelete: 'can-delete',
  canView: 'can-view',

  canViewOnboardingChecklist: 'can-view-onboarding-checklist',

  canManageUsersAndViewers: 'can-invite-users-viewers',
  canEditAccount: 'can-edit-account',
  canEditPolling: 'can-edit-polling',

  canManageAllUsers: 'can-invite-all',
  canEditOrgSettings: 'can-edit-org-settings',
  canCreateAccount: 'can-create-account',

  // SETTINGS
  canManageNotificationSettings: 'can-manage-notification-settings',
  canManageIntegrationSettings: 'can-manage-integration-settings',
  canManageUserSettings: 'can-manage-user-settings',
  canManageBillingSettings: 'can-manage-billing-settings'
}

export const PERMISSIONS = {
  [ROLES.viewer]: [SCOPES.canView],
  [ROLES.user]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canManageNotificationSettings
  ],
  [ROLES.admin]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canViewOnboardingChecklist,
    SCOPES.canManageUsersAndViewers,
    SCOPES.canEditAccount,
    SCOPES.canEditPolling,
    SCOPES.canManageNotificationSettings,
    SCOPES.canManageIntegrationSettings,
    SCOPES.canManageUserSettings
  ],
  [ROLES.owner]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canViewOnboardingChecklist,
    SCOPES.canManageAllUsers,
    SCOPES.canEditOrgSettings,
    SCOPES.canCreateAccount,
    SCOPES.canManageUsersAndViewers,
    SCOPES.canEditAccount,
    SCOPES.canEditPolling,
    SCOPES.canManageNotificationSettings,
    SCOPES.canManageIntegrationSettings,
    SCOPES.canManageUserSettings,
    SCOPES.canManageBillingSettings
  ]
}
