import React from 'react'
import { Row, Col } from 'antd'
import numeral from 'numeral'

import TrendlineCard from 'components/dashboards/trend-chart'

import { faBug } from '@fortawesome/pro-regular-svg-icons/faBug'
import { faSigma } from '@fortawesome/pro-regular-svg-icons/faSigma'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faUsdCircle } from '@fortawesome/pro-regular-svg-icons/faUsdCircle'

import { formatDuration } from 'components/charts/utils'

const loadData = [{
  name: 'total invocations',
  key: 'invocations',
  size: { xs: 24, lg: 8 },
  format: item => numeral(item).format('0a') || 0,
  icon: faSigma,
  singleValue: true
}, {
  name: 'total errors',
  key: 'errors',
  size: { xs: 24, lg: 8 },
  format: item => item || 0,
  color: '#f50',
  icon: faBug,
  singleValue: true
}, {
  name: 'total warnings',
  key: 'warnings',
  size: { xs: 24, lg: 8 },
  format: item => item || 0,
  color: '#f58d06',
  icon: faBug,
  singleValue: true
}, {
  name: 'cost total',
  key: 'cost',
  size: { xs: 24, lg: 12 },
  format: item => `${numeral(item).format('$ 0.00 a')}`,
  icon: faUsdCircle,
  singleValue: true
}, {
  name: 'billed duration',
  key: 'billed-duration',
  size: { xs: 24, lg: 12 },
  format: item => formatDuration(item),
  icon: faClock,
  singleValue: true
}]

const GeneralStatistics = ({ start, end, span, gutter, ...options }) => {
  return (<Row gutter={[gutter, gutter]}>
    {loadData.map(load =>
      <Col xs={load.size.xs} lg={load.size.lg} key={load.name}>
        <TrendlineCard
          load={load}
          start={start}
          end={end}
          span={span}
          height={100} />
      </Col>
    )}
  </Row>)
}

export default GeneralStatistics
