import React from 'react'
import numeral from 'numeral'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { FunctionIcon, BugIcon, SigmaIcon, ClockIcon } from 'components/icons'
import { Item } from 'components/items/item'
import classnames from 'classnames'
import styles from './styles.module.less'

const Metrics = ({ metrics }) => {
  if (!metrics) return null

  return (
    <>
      <span className={styles.metrics_icon}> <SigmaIcon /> {numeral(metrics?.requests).format('0a') || 0}</span>
      <span className={classnames(styles.metrics_icon, metrics.errors > 0 && styles.bug)}><BugIcon /> {metrics.errors || 0}</span>
      <span className={styles.metrics_icon}><ClockIcon />  {metrics.duration || 0}</span>
    </>
  )
}

const Arguments = ({ item }) => {
  if (!item.args) return null

  return (
    <Item bordered hoverable={false} className={styles.arguments}>
      {item.args.map((arg, index) => <div className={styles.argument} key={index}>
        <span>{arg?.name}</span> <span className={styles.type}>{arg?.type}</span>
      </div>)}
    </Item>
  )
}

const DataSource = ({ item, url }) => {
  if (!item.icon) return null

  const metrics = item.metrics?.reduce((result, x) => {
    result[x.metric] = x.aggregate
    return result
  }, {})

  return (
    <Item icon={item.icon} url={url} className={styles.item} bordered size='md'>
      <div className={styles.data_source}>
        <div className={styles.metrics}><Metrics metrics={metrics} /></div>
        <div className={styles.title}>{item.iconTitle}</div>
      </div>
    </Item>
  )
}

const ResolverItem = ({ item }) => {
  const match = useRouteMatch()
  const { search } = useLocation()
  const resolverUrl = `${match.url}/resolvers/${item.id}${search || ''}`

  if (item.args || item.icon) {
    return (
      <Item size='xs' gap='sm' className={styles.item}
        content={(
          <>
            <Arguments item={item} />
            <DataSource item={item} url={resolverUrl} />
          </>
        )}
      >
        <div className={styles.title}>
          {item.returns && <FunctionIcon />}
          <span>{item.title}</span>
          {item.returns && <><span className={styles.arrow}>→</span> <span>{item.returns}</span></>}
        </div>
      </Item>
    )
  }

  return (
    <Item size='xs' gap='sm' hoverable={false} className={styles.item}>
      <span className={styles.title}>{item.title}</span>
    </Item>
  )
}

export default ResolverItem
