import React from 'react'
import { get, isInteger } from 'lodash'
import { Typography, InputNumber, Form } from 'antd'

import RemoveButton from './buttons/remove'

import { formatStripePrice } from 'lib/billing-helpers'

const { Text } = Typography

const columns = ({ quantities, setQuantities, invoice, cancelSubscriptionItem } = {}) => {
  return [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Estimated usage',
      dataIndex: 'usage',
      key: 'usage',
      render: (usage, data) => <><Text style={{ marginRight: 10 }}>{usage}</Text><Text type='secondary'>{`${data.unit}/month`}</Text></>
    },
    {
      title: 'Reserved amount',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity, data) => {
        return (
          <Form.Item name={data.key} initialValue={quantity} rules={[{ type: 'integer', required: true, message: 'Please input an integer!' }]}>
            <InputNumber min={quantity} onBlur={(event) => {
              const value = parseInt(get(event, 'target.value', 0))
              if (!isInteger(value)) return
              setQuantities({ ...quantities, [data.key]: value })
            }} />
          </Form.Item>
        )
      }
    },
    {
      dataIndex: 'unitPrice',
      key: 'unitprice',
      render: (price, data) => <Text type='secondary'>{`x ${formatStripePrice(price, data.currency)} per ${data.unit} (12 months)`}</Text>
    },
    {
      title: 'Price',
      key: 'total',
      render: (value, data) => {
        const invoiceAmount = get(invoice, ['items', data.key, 'amount'], 0)
        const paidInvoiceAmount = data.quantity * data.unitPrice
        const total = invoiceAmount > paidInvoiceAmount ? invoiceAmount - paidInvoiceAmount : 0
        return (
          <Text>{formatStripePrice(total, data.currency)}</Text>
        )
      }
    },
    {
      key: 'action',
      render: (value, data) => <RemoveButton hide={!data.canCancel || data.quantity < 1} onClick={() => cancelSubscriptionItem(data.subscriptionId, data.subscriptionItemId, data.key)} />
    }
  ]
}

export default columns
