import React from 'react'

import { Row, Col } from 'antd'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { RDS_CPU_MEMORY } from '../../../metrics-tabs/services/rds-instance'
import InventoryTags from 'containers/inventory/shared/tags'
import InventoryResourceContainer from '../../../container'
import Events from 'containers/inventory/shared/events'
import Config from './config'
import Tabs from 'components/antd/tabs'
import Section from 'components/layout/content/section'

const Configuration = ({ item }) => {
  return (<>
    {item.tags.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <Config attributes={item?.attributes} inverse />
  </>)
}

const RDSInstance = ({ item }) => {
  return (
    <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.RDSInstance.id} initialChart={RDS_CPU_MEMORY}>
      <Row gutter={12}>
        <Col xs={24} md={16}>
          <Section>
            <Tabs defaultActiveKey="configuration" items={[{ key: 'configuration', label: 'Configuration', children: <Configuration item={item} /> }]} />
          </Section>
        </Col>
        <Col xs={24} md={8}>
          <Events resourceId={item.id} />
        </Col>
      </Row>
    </InventoryResourceContainer>
  )
}

export default RDSInstance
