import React from 'react'

import { Select, Form } from 'antd'
import Input from 'components/antd/input'

import { EU_MEMBER_STATES } from 'lib/billing-constants'
import countries from 'components/billing/countries'

const vatValidator = (validator, country) => async (rule, value) => {
  if (!value) return Promise.resolve()
  const { payload } = await validator({ country, vat: value })
  if (!payload.valid) {
    throw new Error(rule.message)
  }

  Promise.resolve()
}

const BillingForm = React.forwardRef(({ customer, validateVat }, ref) => {
  const handleSearch = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <Form
      ref={ref}
      name='basic'
      labelAlign='left'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{
        country: customer?.address ? customer.address.country : null,
        street: customer?.address ? customer.address.street : null,
        city: customer?.address ? customer.address.city : null,
        state: customer?.address ? customer.address.state : null,
        zip: customer?.address ? customer.address.zip : null,
        vat: customer?.vat,
        email: customer ? customer.email : null,
        name: customer ? customer.name : null
      }}
    >
      <Form.Item name='country' label='Country' rules={[{ required: true, message: 'Please select a country' }]}>
        <Select
          showSearch
          filterOption={handleSearch}
        >
          {
            countries.map((country) => (
              <Select.Option
                key={country.value}
              >
                {country.label}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>

      <Form.Item name='street' label='Street' rules={[{ required: true, message: 'Please enter street.' }]}>
        <Input placeholder='Street' solid />
      </Form.Item>

      {/* <Form.Item name='line2' label='Building number' rules={[{ message: 'Please enter building number.' }]}>
        <Input placeholder='Number' />
      </Form.Item> */}

      <Form.Item name='city' label='City' rules={[{ required: true, message: 'Please enter city.' }]}>
        <Input placeholder='City' solid />
      </Form.Item>

      <Form.Item name='state' label='State'
        rules={[{ required: true, message: 'Please enter state.' }]}>
        <Input placeholder='State' solid />
      </Form.Item>

      <Form.Item name='zip' label='Zip/Postal code'
        rules={[{ required: true, message: 'Please enter zip/postal code' }]}>
        <Input placeholder='Zip/Postal code' solid />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          EU_MEMBER_STATES[getFieldValue('country') || customer?.address?.country]
            ? (<Form.Item
              name='vat' label='VAT number'
              validateTrigger='onBlur'
              rules={[{ required: false, validator: vatValidator(validateVat, getFieldValue('country') || customer?.address?.country), message: 'VAT validation failed' }]}
            >
              <Input placeholder='VAT number' solid />
            </Form.Item>)
            : null
        }
      </Form.Item>

      <Form.Item name='email' label='Contact email'
        rules={[{
          required: true,
          message: 'Please enter contact email'
        }, {
          type: 'email',
          message: 'Please enter valid contact email'
        }]}>
        <Input placeholder='Contact email' solid />
      </Form.Item>

      <Form.Item name='name' label='Contact name' rules={[{ required: true, message: 'Please enter contact name' }]}>
        <Input placeholder='Contact name' solid />
      </Form.Item>
    </Form >
  )
})

export default BillingForm
