import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Divider, Drawer, Card } from 'antd'

import BillingSwitch from './billing-switch'
import Resources from './resources'
import DataIngestion from './data-ingestion'
import Totals from './totals'

import { SUBSCRIPTION_TYPES } from 'lib/billing-constants'

const preparePrices = (prices, activeType) => {
  const isReserved = activeType === SUBSCRIPTION_TYPES.RESERVED
  const inventoryPrice = get(prices, [activeType, 'inventory'], {})
  const dataIngestionPrice = get(prices, [activeType, 'dataIngestion'], {})

  const inventoryAmount = get(inventoryPrice, 'amount', 0)
  const dataIngestionAmount = get(dataIngestionPrice, 'amount', 0)
  return {
    inventory: { ...inventoryPrice, amount: isReserved ? inventoryAmount / 12 : inventoryAmount },
    dataIngestion: { ...dataIngestionPrice, amount: isReserved ? dataIngestionAmount / 12 : dataIngestionAmount },
    isReserved
  }
}

const prepareCalculatorData = resources => {
  return resources.reduce((carry, resource) => {
    carry[resource.type] = resource
    return carry
  }, {})
}

const UsageCalculator = ({ prices, inventoryData, dataIngestionData, onClose, visible } = {}) => {
  const [priceType, setPriceType] = useState(SUBSCRIPTION_TYPES.ON_DEMAND)
  const [priceData, setPriceData] = useState({})
  const [resources, setResources] = useState({})
  const [dataIngested, setDataIngested] = useState({})

  useEffect(() => {
    const priceData = preparePrices(prices, priceType)
    setPriceData(priceData)
  }, [priceType])

  useEffect(() => {
    const resourcesByKey = prepareCalculatorData(inventoryData)
    setResources({ ...resourcesByKey })
  }, [inventoryData])

  useEffect(() => {
    const dataIngestedByKey = prepareCalculatorData(dataIngestionData)
    setDataIngested({ ...dataIngestedByKey })
  }, [dataIngestionData])

  const changeResourceCount = (type, count) => {
    const currentResource = get(resources, type)
    setResources({ ...resources, [type]: { ...currentResource, count } })
  }
  const toggleResourceEnable = (type, enabled) => {
    const currentResource = get(resources, type)
    setResources({ ...resources, [type]: { ...currentResource, enabled } })
  }

  const changeDataIngested = (type, usage) => {
    const current = get(dataIngested, type)
    setDataIngested({ ...dataIngested, [type]: { ...current, estimatedNotFixed: usage } })
  }

  return (
    <Drawer
      placement='right'
      closable={false}
      onClose={onClose}
      open={visible}
      getContainer={false}
      width={'50%'}
    >
      <Card
        title='Cost calculator'
        bordered={false}
        headStyle={{ borderBottom: 'none' }}
        bodyStyle={{ padding: 0 }}
        extra={[
          <BillingSwitch
            key='billing-switch'
            onChange={(checked) => setPriceType(checked ? SUBSCRIPTION_TYPES.RESERVED : SUBSCRIPTION_TYPES.ON_DEMAND)} />
        ]}
      >
        <Divider>Totals</Divider>
        <Totals
          resources={resources}
          dataIngested={dataIngested}
          prices={priceData}
          info={priceData.isReserved ? '(billed yearly)' : null} />
        <Divider>Inventory</Divider>
        <Resources
          resources={resources}
          price={priceData.inventory}
          changeCount={changeResourceCount}
          toggleEnable={toggleResourceEnable} />
        <Divider>Data Ingestion</Divider>
        <DataIngestion
          dataIngested={dataIngested}
          price={priceData.dataIngestion}
          changeDataIngested={changeDataIngested}
        />
      </Card>
    </Drawer>
  )
}

export default UsageCalculator
