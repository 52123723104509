import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, message } from 'antd'
import { isEmpty } from 'lodash'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { SearchContext } from 'hooks/context/search-context'
import { useAllResourcesQuery, useResourceGroupsQuery } from 'hooks/api'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { SEVERITY_TYPES } from 'lib/event-constants'
import { getResourcesByType } from 'lib/resources/filters'
import TargetSelector from 'features/target-selector'
import QueryEditor from 'containers/search/details/form/query-editor'
import Section from 'components/layout/content/section'
import Radio from 'components/antd/radio'
import RadioSmall from 'components/antd/radio/small'
import Submit from 'components/antd/form/submit'
import { SearchIcon, AwsServiceIcon } from 'components/icons'
import { useMutations } from '../hooks'

import styles from './styles.module.less'

const QueryForm = React.forwardRef(({ query }, ref) => {
  const { queryId } = useParams()
  const serviceValue = Form.useWatch('service', ref)
  const handlers = useMutations({ query })

  const { editorState, setEditorState, queryRef, validateQuery } = useContext(SearchContext)

  const { data: resourcesAll } = useAllResourcesQuery()
  const { data: groups } = useResourceGroupsQuery()
  const lambdas = Object.values(resourcesAll || {}).filter(item => item.type === INVENTORY_SERVICES.Lambda.type)

  const getServiceResources = (service) => {
    switch (service) {
      case INVENTORY_SERVICES.Lambda.service:
        return lambdas
      case INVENTORY_SERVICES.KinesisAnalytics.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.KinesisAnalytics.type)
      case INVENTORY_SERVICES.ECSService.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.ECSService.type)
      case INVENTORY_SERVICES.OpenSearch.service:
        return getResourcesByType(resourcesAll, INVENTORY_SERVICES.OpenSearch.type)
      default: return []
    }
  }

  const handleSubmit = (values) => {
    if (isEmpty(queryRef?.current)) return message.error('Cannot save search with empy query')
    if (!validateQuery(queryRef?.current)?.isValid) return message.error('Query validation failed.')

    if (!queryId) {
      handlers.create.query(values, queryRef)
      return
    }

    handlers.update.query(values, queryRef)
  }

  useEffect(() => {
    ref.setFieldsValue({ targets: query?.targets?.map(target => target.includes(':') ? target?.split(':')[1] : target), severity: query?.severity || 'info' })
  }, [query])

  useEffect(() => {
    if (!ref.isFieldTouched('service')) return
    // when currentQuery targets matches changes serviceValue, set these as targets
    ref.setFieldsValue({ targets: [] })
    ref.validateFields()
  }, [serviceValue])

  return (
    <Section solid>
      <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true }}>
        <Form form={ref} className={styles.form_wrapper} layout='vertical' onFinish={handleSubmit}>
          <Form.Item>
            <div className={styles.editor_container}>
              <QueryEditor editorState={editorState} setEditorState={setEditorState} handleSubmit={handleSubmit} />
              <SearchIcon className={styles.search_icon} />
            </div>
          </Form.Item>
          <Form.Item label='Severity' name='severity'>
            <RadioSmall
              items={[
                { value: SEVERITY_TYPES.CRITICAL, label: SEVERITY_TYPES.CRITICAL, severity: SEVERITY_TYPES.CRITICAL },
                { value: SEVERITY_TYPES.WARNING, label: SEVERITY_TYPES.WARNING, severity: SEVERITY_TYPES.WARNING },
                { value: SEVERITY_TYPES.INFO, label: SEVERITY_TYPES.INFO, severity: SEVERITY_TYPES.INFO }
              ]}
            />
          </Form.Item>
          {!queryId && <Form.Item name='service' label='Resources' className={styles.service_item}>
            <Radio
              bordered
              items={[
                {
                  value: INVENTORY_SERVICES.Lambda.service,
                  content: (
                    <span>
                      Lambda <AwsServiceIcon service={INVENTORY_SERVICES.Lambda.service} size='sm' />
                    </span>
                  )
                },
                {
                  value: INVENTORY_SERVICES.ECSService.service,
                  content: (
                    <span>
                      ECS <AwsServiceIcon service={INVENTORY_SERVICES.ECSService.service} size='sm' />
                    </span>
                  )
                },
                {
                  value: INVENTORY_SERVICES.KinesisAnalytics.service,
                  content: (
                    <span>
                      Kinesis Analytics <AwsServiceIcon service={INVENTORY_SERVICES.KinesisAnalytics.service} size='sm' />
                    </span>
                  )
                },
                {
                  value: INVENTORY_SERVICES.OpenSearch.service,
                  content: (
                    <span>
                      Opensearch <AwsServiceIcon service={INVENTORY_SERVICES.OpenSearch.service} size='sm' />
                    </span>
                  )
                }
              ]}
            />
          </Form.Item>}
          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.service !== curValues.service}>
            {({ getFieldValue }) => (
              <Form.Item
                name='targets'
                label={queryId ? 'Resources' : ''}
                rules={[{ required: getFieldValue('service') !== INVENTORY_SERVICES.Lambda.service, type: 'array', max: 20, message: 'Choose at least one target resource' }]}>
                <TargetSelector
                  placeholder='Select resources'
                  bordered
                  resources={getServiceResources(getFieldValue('service'))}
                  resourceGroups={getFieldValue('service') === INVENTORY_SERVICES.Lambda.service ? groups : null}
                />
              </Form.Item>)}
          </Form.Item>
          <Submit text='Save query' loading={handlers.update.loading || handlers.create.loading} />
        </Form>
      </PermissionsGate>
    </Section>
  )
})

export default QueryForm
