import React from 'react'

import { Line } from 'recharts'

import transform from '../transform'
import Empty from 'components/charts/empty'

import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'
import ChartContainer from 'components/charts/container'
import { STEPFUNCTIONS_DURATION } from 'containers/inventory/details/resource/metrics-tabs/services/stepfunctions'

const StepFunctionsDurationChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [STEPFUNCTIONS_DURATION]: data.STEPFUNCTIONS_EXECUTIONS_DURATION
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => [formatDuration(value), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={STEPFUNCTIONS_DURATION}
      formatTab={value => formatDuration(value)}
      formatTooltip={tooltipFormatter}
      formatYTicks={value => formatDuration(value)}
    >
      <Line name='Duration' dataKey={STEPFUNCTIONS_DURATION} type='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />
    </ChartContainer>
  )
}

export default StepFunctionsDurationChart
