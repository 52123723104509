import React from 'react'

import ListItem from 'features/side-list/item'
import SeverityBadge from 'components/badge/severity'
import Badge from 'components/badge/info'
import { AwsServiceIcon, MutedIcon } from 'components/icons'

import { INSIGHT_RULE_STATUSES } from 'lib/event-constants'

const Info = ({ status, count, severity }) => {
  if (status === INSIGHT_RULE_STATUSES.DISABLED) return <MutedIcon />
  return count > 0 ? <SeverityBadge severity={severity} text={count} /> : null
}

const RuleItem = ({ data, index, style }) => {
  const item = data?.items[index]

  return (
    <div style={style} key={item?.id}>
      <ListItem
        title={item?.name}
        icon={<AwsServiceIcon service={item?.service} />}
        left={<><SeverityBadge severity={item?.severity} /><Badge text={item?.pillar} /></>}
        right={<Info status={item?.status} count={item?.count} severity={item?.severity} />}
        selected={item?.id === data?.selected}
        onClick={() => data?.onSelect(item?.id)}
      />
    </div>
  )
}

export default RuleItem
