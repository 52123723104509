import React, { useEffect, useState } from 'react'

import Login from './form'
import MFAVerification from 'containers/auth/mfa-verification'
import ResetPassword from '../reset-password/form'
import GenericAction from 'containers/auth/generic-action'
import { MSG_PASSWORD_CHANGE_REQUIRED } from 'containers/auth/messages'
import { getEmailConfirmedKey, getOnEmailConfirmedAction, listen } from 'lib/auth/local-storage-listener'
import { useUser } from 'hooks/context/user-context'
import { useLogin, useConfirmLogin, useForgotPassword, useCompleteNewPassword, useResendSignUp } from 'hooks/auth'
import { useQuery } from 'lib/hooks/utils'

const LoginContainer = () => {
  const params = useQuery()
  const queryEmail = params.get('email')

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmSignUpSuccess, setConfirmSignUpSuccess] = useState(false)

  const { user, authMessage: authError, setAuthMessage } = useUser()

  const { mutate: login, isLoading: loadingLogin } = useLogin()
  const { mutate: confirmLogin, isLoading: loadingConfirmSignin } = useConfirmLogin()
  const { mutate: forgotPassword, isSuccess: forgotPasswordEmailSent } = useForgotPassword()
  const { mutate: completeNewPassword, isLoading: completeNewPasswordLoading } = useCompleteNewPassword()
  const { mutate: resendSignUp, isSuccess: resendSuccess, isLoading: resendLoading } = useResendSignUp()

  const userConfirmLogin = user && user.challengeName === 'SOFTWARE_TOKEN_MFA'
  const userNewPassword = user && user.challengeName === 'NEW_PASSWORD_REQUIRED'

  const userNotConfirmed = authError?.code === 'UserNotConfirmedException'
  const resetRequired = authError?.code === 'PasswordResetRequiredException'
  const limitExceeded = authError?.code === 'LimitExceededException'

  const onSubmitLogin = async (values) => {
    const { email, password } = values
    setEmail(email)
    setPassword(password)
    // await dispatch(signIn(email, password))
    login({ email, password })
  }

  const onSignIn = () => {
    if (!password || !email) return
    login({ email, password })
  }

  const onSubmitCompleteNewPassword = (values) => {
    const { password } = values

    completeNewPassword({ password })
  }

  const onSubmitConfirmLogin = (values) => {
    const { code } = values

    confirmLogin({ code })
  }

  const handleSignUpSuccess = () => {
    if (!confirmSignUpSuccess) return
    onSignIn()
  }

  const onResendVerification = () => {
    if (!email) return
    resendSignUp({ email })
  }

  const onForgotPassword = () => {
    if (!email) return
    forgotPassword({ email })
  }

  useEffect(() => {
    if (userNewPassword) {
      setAuthMessage(MSG_PASSWORD_CHANGE_REQUIRED)
    }
  }, [user])

  useEffect(handleSignUpSuccess, [confirmSignUpSuccess])

  useEffect(() => {
    if (!queryEmail || queryEmail === '') return
    setEmail(queryEmail)
  }, [queryEmail])

  // check if use has verified his email (confirmed sign up) in this browser
  useEffect(() => {
    if (!userNotConfirmed) return

    const emailConfirmedKey = getEmailConfirmedKey(email)
    const onEmailConfirmedAction = getOnEmailConfirmedAction(emailConfirmedKey, setConfirmSignUpSuccess)
    return listen(emailConfirmedKey, onEmailConfirmedAction)
  }, [userNotConfirmed])

  const getInput = () => {
    if (userNotConfirmed || resendSuccess) {
      return <GenericAction onSubmit={onSignIn} loading={loadingLogin} text='Log in' altLoading={resendLoading} onAltClick={onResendVerification} altText='Resend verification' />
    } else if (resetRequired || forgotPasswordEmailSent) {
      return <GenericAction onSubmit={onForgotPassword} loading={resendLoading} text='Resend link' />
    } else if (userConfirmLogin) {
      return <MFAVerification onSubmit={onSubmitConfirmLogin} loading={loadingConfirmSignin} />
    } else if (userNewPassword) {
      return <ResetPassword onSubmit={onSubmitCompleteNewPassword} loading={completeNewPasswordLoading} />
    }

    return <Login onSubmit={onSubmitLogin} loading={loadingLogin} email={email} password={password} />
  }

  return getInput()
}

export default LoginContainer
