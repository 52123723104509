import React from 'react'
import { uniq, uniqBy } from 'lodash'

import { Bar, Line } from '@visx/shape'

import colors from 'lib/colors'
import { formatDuration } from 'components/charts/utils'
import { ColdstartIcon, BugIcon } from 'components/icons'

import styles from './styles.module.less'

export const BAR_HEIGHT = 20

const SegmentBar = ({ item, xScale, connectedParents, showContent, showConnections }) => {
  if (!item) return null

  const dashedLinePos = 10
  const textPos = dashedLinePos + 3
  const barWidth = Math.abs(xScale(item.end - item.start))
  const itemXCoord = xScale(item.start)
  const parentXCoord = xScale(item?.directParent?.start || 0)
  const offset = 7

  const coords = uniqBy(connectedParents, 'id')?.filter(item => item)?.map(item => {
    const coord = xScale(item.start)

    return coord - offset
  })?.filter(item => item)

  // if item starts earlier than parent then use itemCoord
  let connectionXCoord = parentXCoord > itemXCoord ? itemXCoord - offset : parentXCoord - offset
  connectionXCoord = connectionXCoord < -offset ? -offset : connectionXCoord

  return (
    <g cursor='pointer'>
      <Bar
        x={itemXCoord}
        y={0}
        width={barWidth}
        height={BAR_HEIGHT}
        fill={item.fill}
      />

      {showConnections && <g>
        {/* connectionlines if item is before its parent */}
        {parentXCoord > itemXCoord && <g>
          <Line
            from={{ x: itemXCoord - offset, y: -10 }}
            to={{ x: parentXCoord, y: -10 }}
            fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='2.5 2.5'
          />
          <Line
            from={{ x: itemXCoord - offset, y: -10 }}
            to={{ x: itemXCoord - offset, y: 10 }}
            fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='2.5 2.5'
          />
        </g>}

        {/* line going left */}
        <Line
          from={{ x: item.directParent ? connectionXCoord : -offset, y: dashedLinePos }}
          to={{ x: itemXCoord, y: dashedLinePos }}
          fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='2.5 2.5'
        />

        {/* connection lines going up  */}
        {uniq(coords)?.map(coord =>
          <Line
            key={coord}
            from={{ x: coord, y: -10 }}
            to={{ x: coord, y: 10 }}
            fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')} strokeDasharray='2.5 2.5'
          />
        )}
      </g>}

      {item.error && <BugIcon width='12' height='12' className={styles.icon_bug} x={itemXCoord + 5} y={3} />}
      {item.coldstart && <ColdstartIcon width='12' height='12' stroke={colors('chart', 'primary')} fill={colors('chart', 'primary')} x={itemXCoord + 5} y={3} />}
      {showContent
        ? (
          <text
            x={item.error || item.coldstart ? itemXCoord + 20 : itemXCoord + 5}
            y={textPos}
            fill={item.color}
            fontSize='10px'
          >
            {`${item.content} (${formatDuration(item.end - item.start)})`}
          </text>)
        : null}
    </g>
  )
}

export default SegmentBar
