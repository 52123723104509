import { get, isNil, isEmpty } from 'lodash'
import numeral from 'numeral'

export const formatPrice = (price, currency = 'usd', locale = 'en-US') => {
  if (isNil(price)) price = 0

  return price.toLocaleString(locale, { style: 'currency', currency })
}

export const formatPriceForSubscription = price => {
  const amount = get(price, 'amount', 0)
  const currency = get(price, 'currency')
  const interval = get(price, 'interval', 'month')

  if (interval === 'month') return formatStripePrice(amount, currency)

  const perMonth = amount / 12
  return `12 x ${formatStripePrice(perMonth, currency)}`
}

export const formatStripePrice = (amount, currency, locale) => {
  // unit price is in cents
  return formatPrice(amount / 100, currency, locale)
}

export const getSubscriptionItemPrice = (item, locale) => {
  if (isEmpty(item)) return '-'
  const amount = get(item, 'price.amount')
  if (!amount) return formatPrice(0)
  return formatStripePrice(amount, get(item, 'price.currency'), locale)
}

export const formatInvocations = (value) => numeral(value).format('0a').toUpperCase()
