import { paths } from '../../index'

export const NOTIFICATION_CHANNEL = 'notification_channel'
export const NOTIFICATION_CHANNEL_LIST = [NOTIFICATION_CHANNEL, 'list']
export const NOTIFICATION_CHANNEL_CONNECTIONS = [NOTIFICATION_CHANNEL, 'connections']
export const CHANNELS = 'channels'
export const CHANNELS_LAST_NOTIFICATIONS = [CHANNELS, 'last_notifications']
export const CHANNELS_TEST = [CHANNELS, 'test']

export * from './get-channels'
export * from './get-channel'
export * from './create-channel'
export * from './delete-channel'
export * from './update-channel'
export * from './create-slack-connection'
export * from './last-notifications/get'
export * from './test/get'

export const channelsPaths = {
  channels: (account) => `${paths.account(account)}/notifications/channels`,
  channel: (account, identity) => `${channelsPaths.channels(account)}/${identity}`,
  lastNotifications: (account, identity) => `${channelsPaths.channel(account, identity)}/last-notifications`,
  test: (account, identity) => `${channelsPaths.channel(account, identity)}/test`,
  connect: (account, type) => `${channelsPaths.channels(account)}/connections/${type}`
}
