import React, { useContext, useMemo } from 'react'
import { isEmpty, first } from 'lodash'
import { Line } from '@visx/shape'
import { Group } from '@visx/group'
import { AnimatedAxis } from '@visx/react-spring'
import { scaleLinear } from '@visx/scale'
import { ParentSize } from '@visx/responsive/lib'

import { formatDuration } from 'components/charts/utils'
import colors from 'lib/colors'

import SegmentBar from './segment-bar'
import { useNodes } from 'hooks/nodes'
import { BAR_HEIGHT } from '../segment-bar'
import { formatNode } from '../collapsed/use-traces-formatter'
import { TraceContext } from 'features/invocations/details/x-ray/hooks/trace-context'

const flattenTree = (node) => {
  if (isEmpty(node)) return []

  const { children, ...rest } = node

  return [rest, ...children.flatMap(flattenTree)]
}

const Traces = ({ traces, width }) => {
  const { transformed, metrics } = useNodes(traces)
  const { tooltipData, handleSegmentClick } = useContext(TraceContext)

  const fullScreen = width > 1000
  const svgWidth = Math.abs(width - 20)
  const chartWidth = fullScreen ? svgWidth - 120 : svgWidth // margin on the right to show overflow text
  const startTime = metrics?.min_start_time
  const endTime = metrics?.max_end_time

  const formatSegment = (seg) => {
    delete tooltipData?.children
    delete tooltipData?.subsegments

    return {
      ...formatNode(seg, startTime, endTime),
      error: seg.data.isError,
      tooltipData
    }
  }

  const trace = first(traces)
  const segments = useMemo(() => flattenTree(transformed), [transformed])

  const formattedSegments = segments?.map((doc, index) => {
    return formatSegment(doc, index)
  })

  const xScale = useMemo(() => scaleLinear({
    domain: [0, trace.duration * 1000],
    range: [0, chartWidth],
    nice: false
  }), [chartWidth])

  if (isEmpty(transformed) || width === 0) {
    return null
  }

  const viewBoxWidth = svgWidth + 15
  const segmentRows = segments?.length + 1 || 0
  const height = segmentRows * BAR_HEIGHT + 50

  return (<>
    <div style={{ position: 'relative' }}>
      <svg width={viewBoxWidth} height={height}>
        <Line from={{ x: 3, y: 10 }}
          to={{ x: 3, y: segmentRows * BAR_HEIGHT + 10 }}
          fill={colors('chart', 'grid')} stroke={colors('chart', 'grid')}
        />
        <Group left={10}>
          {formattedSegments.filter(item => item).map((item, index) => {
            return (
              <SegmentBar
                key={index}
                item={item}
                index={index}
                xScale={xScale}
                handleClick={handleSegmentClick}
                showContent={width > 800}
                chartWidth={viewBoxWidth}
                selected={item.id === tooltipData?.id}
              />)
          })}
          <AnimatedAxis
            scale={xScale}
            top={segmentRows * BAR_HEIGHT + 10}
            stroke={colors('chart', 'grid')}
            tickStroke={colors('chart', 'text')}
            tickFormat={num => formatDuration(num)}
            animationTrajectory={'min'}
          />
        </Group>
      </svg>
    </div>
  </>)
}

const ResponsiveTraces = ({ traces }) => {
  return (
    <ParentSize>
      {({ width }) => <Traces width={width} traces={traces} />}
    </ParentSize>
  )
}

export default ResponsiveTraces
