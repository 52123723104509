import { parseQuery } from 'lib/hooks/use-query-params'

export const filterHelper = (selected, list = []) => {
  if (list.includes(selected)) return list.filter(item => item !== selected)
  return list.concat([selected])
}

export const filterListFromQuery = (items, filtersAll, search, extra) => {
  const filtersSelected = parseQuery(search)
  const searchQuery = filtersSelected.search

  const filtered = items?.filter(item => {
    const conditions = filtersAll.map(({ key, filter }) => {
      if (!filtersSelected[key]) return true

      return filter(filtersSelected, item, extra)
    })

    if (searchQuery) {
      const itemIncludesQuery = JSON.stringify(item).toLowerCase().includes(searchQuery?.toLowerCase())
      conditions.push(itemIncludesQuery)
    }

    return conditions.every(item => item)
  })

  return filtered
}
