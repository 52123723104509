import React from 'react'
import Metadata from 'containers/inventory/shared/metadata'
const KinesisAnalyticsMetadata = ({ attributes, inverse }) => {
  const snapshotConfiguration = JSON.parse(attributes.applicationSnapshotConfigurationDescription || null)
  const flinkConfiguration = JSON.parse(attributes.flinkApplicationConfigurationDescription || null)

  const data = [{
    title: 'Application mode',
    value: attributes.applicationMode
  }, {
    title: 'Runtime environment',
    value: attributes.runtimeEnvironment
  }, {
    title: 'Application status',
    value: attributes.applicationStatus
  }, {
    title: 'Snapshots',
    children: [
      { key: 'Snapshots enabled', value: snapshotConfiguration?.snapshotsEnabled.toString() },
    ]
  }, {
    title: 'Checkpoint configuration',
    children: [
      {
        key: 'Configuration type',
        value: flinkConfiguration?.checkpointConfigurationDescription.configurationType,
      },
      {
        key: 'Checkpointing enabled',
        value: flinkConfiguration?.checkpointConfigurationDescription.checkpointingEnabled.toString(),
      },
      {
        key: 'Checkpointing interval update (ms)',
        value: flinkConfiguration?.checkpointConfigurationDescription.checkpointInterval,
      },
      {
        key: 'Min pause between checkpoints (ms)',
        value: flinkConfiguration?.checkpointConfigurationDescription.minPauseBetweenCheckpoints,
      },
    ]
  }, {
    title: 'Scaling',
    children: [
      {
        key: 'Parallelism',
        value: flinkConfiguration?.parallelismConfigurationDescription.parallelism,
      },
      {
        key: 'Parallelism per KPU',
        value: flinkConfiguration?.parallelismConfigurationDescription.parallelismPerKPU,
      },
      {
        key: 'Automatic scaling enabled',
        value: flinkConfiguration?.parallelismConfigurationDescription.autoScalingEnabled.toString(),
      },
    ]
  }]

  return <Metadata items={data} inverse={inverse} />
}

export default KinesisAnalyticsMetadata
