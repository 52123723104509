import InsightForm from './insight'
import ErrorForm from './error'
import AlarmForm from './alarm'
import LogMatchForm from './log-match'

const types = {
  alarm: AlarmForm,
  insight: InsightForm,
  error: ErrorForm,
  'log-match': LogMatchForm
}

const Condition = (props) => {
  if (!types[props.condition.type]) return null
  return types[props.condition.type](props)
}

export default Condition
