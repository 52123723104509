import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'
import { apiFetch } from 'lib/action-helpers'
import { getWidgetLayout } from 'lib/dashboards-helpers'

import { dashboardsPaths as paths, WIDGETS_LIST } from './index'
import { WIDGET_KIND } from 'lib/dashboards-constants'

const PLACEHOLDER_DEFINITION = {
  id: 'placeholder',
  kind: 'placeholder'
}

const createWidget = async (account, identity, payload) => {
  const data = apiFetch(paths.widgets(account, identity), { method: 'POST', body: JSON.stringify(payload) })
  return data
}

export const useCreateWidgetQuery = () => {
  const { dashboardId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    payload => createWidget(account.id, dashboardId, payload),
    {
      onMutate: (payload) => {
        // define size of placeholder
        const text = payload.kind === WIDGET_KIND.TEXT
        const WIDGET_SIZE = payload?.layout ? payload?.layout : text ? { height: 1, width: 8 } : undefined

        const previousList = queryClient.getQueryData([...WIDGETS_LIST, account.id, dashboardId])
        const placeholderWidget = { ...PLACEHOLDER_DEFINITION, layout: getWidgetLayout(previousList, WIDGET_SIZE) }
        const updatedList = [...previousList, placeholderWidget]

        queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], updatedList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], context.previousList)
        }
      },
      onSuccess: (item) => {
        const previousList = queryClient.getQueryData([...WIDGETS_LIST, account.id, dashboardId])
        const updatedList = [...previousList?.filter(item => item.kind !== 'placeholder'), item]

        queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], updatedList)
      }
    }
  )
}
