import React from 'react'
import { Form } from 'antd'

import Input from 'components/antd/input'

const ConfirmPassword = ({ validator }) => {
  return <Form.Item
    name='confirmPassword'
    label='Confirm password'
    rules={[{
      required: true,
      validator,
      message: 'Passwords do not match'
    }]}>

    <Input type='password' solid />
  </Form.Item>
}

export default ConfirmPassword
