class MetricGroupNotFound extends Error {
  constructor (obj) {
    super()
    this.name = 'MetricGroupNotFound'
    this.namespace = obj.namespace
    this.metric = obj.metric
    this.stat = obj.stat
    this.message = `Metric '${this.metric}' with statistic '${this.stat}' doesn\'t exist.`
  }
}

export default MetricGroupNotFound
