import numeral from 'numeral'
import { find } from 'lodash'

import { faDownload, faList, faUpload, faClock, faWaveSquare, faTilde } from '@fortawesome/pro-light-svg-icons'

import { getSum, getSparklineByName, getLast, getKinesisDifferenceSparkline } from '../helpers'
import STATISTICS from '../../metrics/statistics'

export const KINESIS_DS_RATIO = 'kinesis-data-stream-ratio'
export const KINESIS_DS_DIFFERENCE = 'kinesis-data-stream-difference'
export const KINESIS_DS_INCOMING = 'kinesis-data-stream-incoming'
export const KINESIS_DS_OUTGOING = 'kinesis-data-stream-outgoing'
export const KINESIS_DS_OUTGOING_AGE = 'kinesis-data-stream-outgoing-age'

const metrics = [{
  key: KINESIS_DS_INCOMING,
  icon: faDownload,
  title: 'Incoming',
  filter: (collection) => numeral(getSum(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS'),
  selectable: true
}, {
  key: KINESIS_DS_OUTGOING,
  icon: faUpload,
  title: 'Outgoing',
  filter: (collection) => numeral(getSum(collection, 'KINESIS_DATA_STREAM_OUTGOING_RECORDS')).format('0.[00]a'),
  subvalue: (collection) => getSparklineByName(collection, 'KINESIS_DATA_STREAM_OUTGOING_RECORDS'),
  selectable: true
}, {
  key: KINESIS_DS_DIFFERENCE,
  icon: faWaveSquare,
  title: 'Difference',
  filter: (collection) => {
    const recordsIn = getLast(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS')
    const recordsOut = getLast(collection, 'KINESIS_DATA_STREAM_OUTGOING_RECORDS')
    return numeral(recordsIn - recordsOut).format('0.[00]a')
  },
  subvalue: (collection) => getKinesisDifferenceSparkline(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS', 'KINESIS_DATA_STREAM_OUTGOING_RECORDS'),
  selectable: true
}, {
  key: KINESIS_DS_RATIO,
  icon: faTilde,
  title: 'Ratio',
  filter: (collection) => {
    const recordsIn = getLast(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS')
    const bytesIn = getLast(collection, 'KINESIS_DATA_STREAM_INCOMING_BYTES')
    const incomingRatio = bytesIn / recordsIn
    return numeral(incomingRatio).format('0.[00] b')
  },
  subvalue: (collection) => getKinesisDifferenceSparkline(collection, 'KINESIS_DATA_STREAM_INCOMING_RECORDS', 'KINESIS_DATA_STREAM_INCOMING_BYTES'),
  selectable: true
}, {
  key: KINESIS_DS_OUTGOING_AGE,
  icon: faClock,
  title: 'Last record age',
  filter: (collection) => {
    const ms = getLast(collection, 'KINESIS_DATA_STREAM_MESSAGE_AGE', STATISTICS.AVERAGE)
    return numeral(ms / 1000).format('00:00:00')
  },
  subvalue: (collection) => getSparklineByName(collection, 'KINESIS_DATA_STREAM_MESSAGE_AGE'),
  selectable: true
}, {
  key: 'shards',
  icon: faList,
  title: 'Shards',
  filter: (_, item) => {
    if (!item.attributes) return null

    const result = find(item.attributes, { name: 'shards' })

    if (!result) return null

    const shards = JSON.parse(result.value)
    return shards.length || 0
  }
}]

export default metrics
