import React from 'react'

import { Button } from 'antd'
import { EllipsisVIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

export const ActionsDropdownButton = (props) => {
  return <Button type='link' {...props} className={styles.actions}><EllipsisVIcon /></Button>
}
