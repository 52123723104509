import React from 'react'
import { capitalize, keyBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import { Card } from 'antd'

import ListItem from 'components/dashboards/list-item'
import SeverityBadge from 'components/badge/severity'
import { TypeIcon } from 'components/icons'
import { useAccountRoutes } from 'containers/routes'
import { stringifyQuery } from 'lib/hooks/use-query-params'
import { parseInsightRule } from 'containers/insights'
import { resourceTitle } from 'lib/resources/transformations'

import styles from './styles.module.less'

const EventListItems = ({ items, resources, types }) => {
  const routes = useAccountRoutes()
  const resourcesById = keyBy((resources || []), 'id')

  const events = items?.filter(event => types.includes(event.type))

  const getEventUrl = (event) => {
    if (event.type === 'alarm') return { pathname: routes.alarms.alarm.url({ alarmId: event.rule }), search: stringifyQuery({ selected: event.id }) }
    if (event.type === 'insight') return { pathname: routes.insights.rule.url({ ruleId: parseInsightRule(event.rule) }), search: stringifyQuery({ selected: event.id }) }
    return routes.errors.error.url({ errorId: event.id })
  }

  const item = (event) => {
    const resource = resourcesById[event.resource?.id || event.resource] || {}
    const delegation = resource.delegationName ? `| ${resource.delegationName}` : ''
    return {
      title: event.name,
      info: (<>
        <p className={styles.info}>{`${resourceTitle(resource)} ${delegation}`}</p>
        {event.count && <p className={styles.error_count}>{event.count}</p>}
        {event.severity && <SeverityBadge severity={event.severity} />}
      </>),
      details: moment(event.lastOccurredAt ? event.lastOccurredAt : event.openedAt).fromNow(),
      icon: <TypeIcon type={event.type} status={event.severity} />,
      url: getEventUrl(event),
      type: event.type
    }
  }

  const pluralize = (str) => `${str}s`

  const getTitle = () => {
    const plurals = types.map(pluralize)

    return capitalize(plurals.join(' and '))
  }

  const getNoEventsTitle = () => {
    const plurals = types.map(pluralize)

    return `No ${plurals.join(' or ')} found`
  }

  const getNoEventsIcon = () => {
    if (types[0] === 'error') {
      return <FontAwesomeIcon icon={faBug} size='lg' className={styles.error} />
    }

    return `/static/vendor/icons/${types[0]}.svg`
  }

  return (
    <Card title={getTitle()}>
      {events?.length
        ? events.map(event => <ListItem key={event.id} item={item(event)} />)
        : <ListItem item={{ title: getNoEventsTitle(), type: types[0], icon: getNoEventsIcon() }} />
      }
    </Card>
  )
}

export default EventListItems
