import React from 'react'
import { Tabs as AntdTabs } from 'antd'
import classnames from 'classnames'

import styles from './styles.module.less'

const Tabs = ({ children, className, ...props }) => {
  return (
    <AntdTabs className={classnames(styles.tabs, className)} {...props}>
      {children}
    </AntdTabs>
  )
}

export default Tabs
