import React, { useCallback, useContext, useEffect } from 'react'
import { Form, message, Radio } from 'antd'

import { ContentState, EditorState } from 'draft-js'
import QueryEditor, { decorator } from 'containers/search/details/form/query-editor'

import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import SearchContainer, { SearchContext } from 'hooks/context/search-context'
import { getResourcesByType } from 'lib/resources/filters'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import Submit from 'components/antd/form/submit'
import TargetSelector from 'features/target-selector'
import { AwsServiceIcon } from 'components/icons'

import styles from './styles.module.less'

const getInitialValues = (widget, editing) => {
  if (editing) {
    return {
      service: widget?.definition?.service || INVENTORY_SERVICES.Lambda.service,
      query: widget?.definition?.query,
      targets: widget?.definition?.targets
    }
  }
  return { service: 'lambda', query: '', targets: [] }
}

const FormDefinition = (props) => {
  const [form] = Form.useForm()
  const serviceValue = Form.useWatch('service', form)

  const { editing, widget, kind, handlers, onClose, resources } = props
  const lambdas = getResourcesByType(resources, INVENTORY_SERVICES.Lambda.type)
  const { editorState, setEditorState, queryRef, validateQuery } = useContext(SearchContext)

  const getServiceResources = (service) => {
    switch (service) {
      case INVENTORY_SERVICES.Lambda.service:
        return lambdas
      case INVENTORY_SERVICES.KinesisAnalytics.service:
        return getResourcesByType(resources, INVENTORY_SERVICES.KinesisAnalytics.type)
      case INVENTORY_SERVICES.ECSService.service:
        return getResourcesByType(resources, INVENTORY_SERVICES.ECSService.type)
      case INVENTORY_SERVICES.OpenSearch.service:
        return getResourcesByType(resources, INVENTORY_SERVICES.OpenSearch.type)
      default: return []
    }
  }

  const handleFormSubmit = useCallback((values) => {
    if (!validateQuery().isValid) return message.error('Query validation failed')

    const payload = { ...values, query: queryRef?.current }

    if (editing) {
      handlers.widgets.update(widget, payload)
    } else {
      handlers.widgets.add(kind, payload)
    }

    onClose()
  }, [handlers, widget, editing, kind])

  useEffect(() => {
    if (!form.isFieldTouched('service')) return
    // when currentQuery targets matches changes serviceValue, set these as targets
    form.setFieldsValue({ targets: widget?.definition?.service === serviceValue ? widget?.definition?.targets : [] })
    form.validateFields()
  }, [serviceValue])

  useEffect(() => {
    if (!widget) return
    const stateWithContent = EditorState.createWithContent(ContentState.createFromText(widget?.definition?.query || ''), decorator)
    setEditorState(EditorState.moveFocusToEnd(stateWithContent))
  }, [widget])

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]} errorProps={{ disabled: true }}>
      <Form form={form} layout='vertical' onFinish={handleFormSubmit} initialValues={getInitialValues(widget, editing)}>
        <Form.Item label='Search query' name='query'>
          <div className={styles.query_wrapper}>
            <QueryEditor editorState={editorState} setEditorState={setEditorState} handleSubmit={handleFormSubmit} />
          </div>
        </Form.Item>
        <Form.Item name='service' label='Targets' className={styles.radio_wrapper}>
          <Radio.Group>
            <Radio.Button value={INVENTORY_SERVICES.Lambda.service} className={styles.radio}>
              <span className={styles.radio_content}>
                Lambda <AwsServiceIcon service={INVENTORY_SERVICES.Lambda.service} size='sm' />
              </span>
            </Radio.Button>
            <Radio.Button value={INVENTORY_SERVICES.ECSService.service} className={styles.radio}>
              <span className={styles.radio_content}>
                ECS <AwsServiceIcon service={INVENTORY_SERVICES.ECSService.service} size='sm' />
              </span>
            </Radio.Button>
            <Radio.Button value={INVENTORY_SERVICES.KinesisAnalytics.service} className={styles.radio}>
              <span className={styles.radio_content}>
                Kinesis Analytics <AwsServiceIcon service={INVENTORY_SERVICES.KinesisAnalytics.service} size='sm' />
              </span>
            </Radio.Button>
            <Radio.Button value={INVENTORY_SERVICES.OpenSearch.service} className={styles.radio}>
              <span className={styles.radio_content}>
                OpenSearch <AwsServiceIcon service={INVENTORY_SERVICES.OpenSearch.service} size='sm' />
              </span>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.service !== curValues.service}>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name='targets'
                rules={[{ required: getFieldValue('service') !== INVENTORY_SERVICES.Lambda.service, type: 'array', max: 20, message: 'Choose at least one target resource' }]}>
                <TargetSelector
                  resources={getServiceResources(getFieldValue('service'))}
                  solid />
              </Form.Item>)
          }}
        </Form.Item>
        <Submit text={editing ? 'Update widget' : 'Add widget'} />
      </Form>
    </PermissionsGate>
  )
}

const LogsWidgetDetails = (props) => {
  return (
    <SearchContainer>
      <FormDefinition {...props} />
    </SearchContainer>
  )
}

export default LogsWidgetDetails
