import React from 'react'
import { mapValues } from 'lodash'

import { useGetPricesQuery } from 'hooks/api'
import { Tier } from './tier-config'

export type Extras = {
  lambdaInvocations: number
  awsAccounts: number
}
export type Summary = {
  items: { priceId: string, quantity?: number }[],
  limits: Extras,
  total: number,
}
export enum BillingInterval {
  year = 'year',
  month = 'month',
}

export const usePrices = () => {
  const { data: prices = [], isLoading } = useGetPricesQuery({ v: 3 })

  const index = React.useMemo(() => {
    return prices.reduce((acc, price) => {
      acc[price.interval].push(price)
      return acc
    }, { year: [] as typeof prices, month: [] as typeof prices })
  }, [prices])

  // calculates total price, total limits, feature set and subscription items based on prices, tier, interval and extras
  const calculate = React.useCallback((tier: Tier, interval: BillingInterval, extras: Extras): null | Summary => {
    if (isLoading) {
      return null
    }
    if (!Array.isArray(index[interval])) {
      throw new Error(`Unknown interval: ${interval}`)
    }
    const tierItem = index[interval].find(({ metadata }) => metadata.type === 'tier' && metadata?.tier === tier)
    if (!tierItem) {
      throw new Error(`Tier item not found: ${tier}`)
    }
    const extraItems = Object.entries(extras).reduce((acc, [article, amount]) => {
      if (amount) {
        const price = index[interval].find(({ metadata }) => metadata.type === article && (!metadata?.tier || metadata?.tier === tier))

        if (price) {
          const unitSize = parseInt(price.metadata[article], 10)
          const quantity = Math.ceil(amount / unitSize)

          acc[article] = {
            price,
            quantity,
          }
        } else {
          throw new Error(`No price found for article: ${article} in tier ${tier} of interval ${interval}`)
        }
      }

      return acc
    }, {} as Record<keyof Extras, { price: any, quantity: number, unitPrice: number }>)

    const extraItemsTotal = Object.values(extraItems).reduce((sum, { price, quantity }) => {
      return sum + price.amount * quantity
    }, 0)

    // features can only be increased by tier
    const limits: Extras = mapValues(extras, (amount, article) => {
      return amount + (parseInt(tierItem.metadata[article]) ||  0)
    })

    return {
      // TODO: get subscription items
      items: [{ priceId: tierItem.id, }].concat(
        Object.values(extraItems)
          .map(({ price, quantity }) => ({ priceId: price.id, quantity }))
      ),
      limits,
      total: tierItem.amount + extraItemsTotal
    }
  }, [index])

  return {
    isLoading,
    calculate,
    prices
  }
}
