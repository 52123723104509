import { useQuery } from 'react-query'
import { apiFetch, omitEmpty } from 'lib/action-helpers'
import queryString from 'query-string'

import { INTEGRATION_REGIONS, integrationsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const findRegions = async (account, provider, service) => {
  const query = queryString.stringify(omitEmpty({ provider, service }))
  return apiFetch(paths.filter(paths.integrationRegions(account), query), { method: 'GET' })
}

export const useProviderRegionsQuery = (payload) => {
  const { account } = useOrganization()
  const { provider, service } = payload

  return useQuery(
    [INTEGRATION_REGIONS, account?.id, provider, service],
    () => findRegions(account?.id, provider, service),
    {
      staleTime: 30 * 60 * 1000,
      enabled: !!account?.id && !!provider,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
