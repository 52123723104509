import NAMESPACES from '../namespaces'
import STATISTICS from '../statistics'

const metrics = [{
  id: 'STEPFUNCTIONS_EXECUTIONS_DURATION',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.duration',
    stats: [STATISTICS.AVERAGE],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_THROTTLES',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.throttles',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_ABORTED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.aborted',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_FAILED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.failed',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_STARTED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.started',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_SUCCEEDED',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.succeeded',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}, {
  id: 'STEPFUNCTIONS_EXECUTIONS_TIMEOUTS',
  filter: {
    namespace: NAMESPACES.AWS,
    metric: 'executions.timeouts',
    stats: [STATISTICS.SUM],
    fill: 'gapfill'
  }
}]

export default metrics
