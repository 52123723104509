import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { WIDGETS_LIST } from './index'
import { dashboardsPaths as paths } from '../index'


const getWidgets = async (account, identity) => {
  const data = await apiFetch(paths.widgets(account, identity))

  return data
}

export const useWidgetsQuery = () => {
  const { dashboardId } = useParams()
  const { account } = useOrganization()

  return useQuery(
    [...WIDGETS_LIST, account.id, dashboardId],
    () => getWidgets(account.id, dashboardId),
    {
      // Never refetch widgets since we update cache manually
      // https://stackoverflow.com/questions/72461591/is-it-possible-to-re-render-a-component-after-updating-cache-with-react-query
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!account?.id && !!dashboardId,
      refetchOnWindowFocus: false
    }
  )
}
