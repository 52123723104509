import React from 'react'
import Numeral from 'numeral'

import { Line, Area } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'

const OpensearchCPUMemoryChart = ({ loading, data, height, span, chartKey }) => {
  if (loading || !data) return null

  const type = chartKey?.split('-')[1]?.toUpperCase()

  const metrics = transform({
    cpu: data[`OPENSEARCH_${type}_CPU_UTILIZATION`],
    mem: data[`OPENSEARCH_${type}_MEMORY`]
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diff' || dataKey === 'diffMem') return [`${Numeral(value[0]).format('0.[00]')} - ${Numeral(value[1]).format('0.[00]')}%`, undefined]
    return [`${Numeral(value).format('0.[00]')}%`, undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [chartKey]: metric.cpu_avg,
      mem: metric.mem_avg,
      diff: [metric.cpu_min, metric.cpu_max],
      diffMem: [metric.mem_min, metric.mem_max]
    }
  })

  return (
    <ChartContainer height={height}
      data={formatted}
      yTicksFormatting='0.[0]'
      span={span}
      tabKey={chartKey}
      formatTab={value => `${Numeral(value).format('0.[00]')}%`}
      formatTooltip={valueFormatter}
      percentageChart
    >
      {data[`OPENSEARCH_${type}_CPU_UTILIZATION`] && <Line type='monotone' dataKey={chartKey} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average CPU' dot={false} />}
      {formatted[0]?.diff && <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='CPU Min - Max' />}

      {data[`OPENSEARCH_${type}_MEMORY`] && <Line type='monotone' dataKey='mem' stroke={colors('chart', 'redLight')} strokeWidth={1} name='Average mem' dot={false} />}
      {formatted[0]?.diffMem && <Area type='monotone' dataKey='diffMem' fill={colors('chart', 'redWithOpacity')} stroke={colors('chart', 'redWithOpacity')} strokeWidth='0.5' name='Mem min - Max' />}
    </ChartContainer>
  )
}

export default OpensearchCPUMemoryChart
