const isRuntime = (node) => {
  return node.service?.runtime
}

const isRequest = (node) => {
  return node.http?.request
}

const isOpenTelemetry = (node) => {
  return node.metadata?.default?.['otel.resource.telemetry.sdk.name']
}

const isTLS = (node) => {
  return node.metadata?.default?.['tls.cipher.name']
}

export {
  isRuntime,
  isRequest,
  isOpenTelemetry,
  isTLS
}
