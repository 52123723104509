import React from 'react'
import styles from './styles.module.less'

import RawLog from 'features/log/raw-log'
import Metadata from 'containers/inventory/shared/metadata/index'

const StepFunctionsMetadata = ({ attributes, inverse }) => {
  const data = [{
    title: 'Definition',
    value: <RawLog
      dataToDisplay={attributes.definition}
      isText={attributes.definition}
      logStyle={styles.pre}
      isStacktrace />
  }]
  return <Metadata items={data} inverse={inverse} />
}

export default StepFunctionsMetadata
