import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'

import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { SQS_DIFFERENCE } from 'containers/inventory/details/resource/metrics-tabs/services/sqs'

const DifferenceChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    messagesIn: data.SQS_MESSAGES_IN,
    messagesOut: data.SQS_MESSAGES_OUT
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = value => [Numeral(value).format('0.[000]a'), undefined]

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0.[00]a'
      span={span}
      tabKey={SQS_DIFFERENCE}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={valueFormatter}
    >
      <Bar dataKey='messagesIn' fill={colors('chart', 'primaryDark')} name='Messages in' barSize={4} />
      <Bar dataKey='messagesOut' fill={colors('chart', 'red')} name='Messages out' barSize={4} />
    </ChartContainer>)
}

export default DifferenceChart
