import React, { useContext, useMemo } from 'react'
import { useNodes } from 'hooks/nodes'
import { isEmpty } from 'lodash'

import { TraceContext } from '../hooks/trace-context'
import { FlowChartTheme } from 'features/flow-chart/hooks/flow-chart-context'
import FlowChartWrapper, { graphFromXRayTraces } from 'features/flow-chart'
import { CHART_COLORS } from 'lib/colors'

const TracesFlowChart = ({ traces }) => {
  const { tooltipData, handleSegmentClick } = useContext(TraceContext)
  const { transformed } = useNodes(traces, { inferred: true })
  const graphIds = traces?.[0]?.segments?.map(item => item?.Document?.id)

  const graph = useMemo(() => graphFromXRayTraces(transformed, graphIds), [transformed])
  if (isEmpty(transformed)) return null

  return (
    <>
      <FlowChartTheme.Provider value={{ background: CHART_COLORS.backgroundSecondary }}>
        <FlowChartWrapper graph={graph} handleNodeClick={handleSegmentClick} selected={tooltipData?.id} />
      </FlowChartTheme.Provider >
    </>
  )
}

export default TracesFlowChart
