import React from 'react'
import Numeral from 'numeral'

import { Area, Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { OPENSEARCH_NODES } from 'containers/inventory/details/resource/metrics-tabs/services/opensearch'

const OpensearchNodesChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    nodes: data.OPENSEARCH_CLUSTER_NODES
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, { dataKey }) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [OPENSEARCH_NODES]: metric.nodes_avg,
      diff: [metric.nodes_min, metric.nodes_max]
    }
  })

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={OPENSEARCH_NODES}
      yTicksFormatting='0 a'
      formatTab={value => `${Numeral(value).format('0.[000]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={OPENSEARCH_NODES} stroke={colors('chart', 'primary')} strokeWidth={1} name='Average Nodes' dot={false} />
      <Area type='monotone' dataKey='diff' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth='0.5' name='Nodes Min - Max' />
    </ChartContainer>
  )
}

export default OpensearchNodesChart
