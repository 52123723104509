import React from 'react'
import { useParams } from 'react-router-dom'
import { keyBy, groupBy } from 'lodash'
import classnames from 'classnames'

import Section from 'components/layout/content/section'
import { Item } from 'components/items/item'
import { AwsServiceIcon, SpinnerIcon } from 'components/icons'
import { INVENTORY_SERVICE_GROUPS, findInventoryByType } from 'lib/resources/constants'
import SeverityBadge from 'components/badge/severity'
import { GlobeAmericaIcon, PollingIntervalIcon, NoConnectionIcon, AlertCircleIcon } from 'components/icons/font-awesome'
import { IconWithText } from 'components/icons/icon-with-text'

import { useDelegationIntegrationsQuery, useAllResourcesQuery } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'

import styles from './styles.module.less'

const GROUPINGS = [
  {
    title: 'Networking & content delivery',
    services: [
      INVENTORY_SERVICE_GROUPS['apigateway.rest'].service,
      INVENTORY_SERVICE_GROUPS['apigateway.http'].service,
      INVENTORY_SERVICE_GROUPS.elb.service,
      INVENTORY_SERVICE_GROUPS.appsync.service
    ]
  },
  {
    title: 'Compute',
    services: [
      INVENTORY_SERVICE_GROUPS.lambda.service,
      INVENTORY_SERVICE_GROUPS.ecs.service
    ]
  },
  {
    title: 'Application integration',
    services: [
      INVENTORY_SERVICE_GROUPS.eventbridge.service,
      INVENTORY_SERVICE_GROUPS.sqs.service,
      INVENTORY_SERVICE_GROUPS.sns.service,
      INVENTORY_SERVICE_GROUPS.stepfunctions.service
    ]
  },
  {
    title: 'Databases',
    services: [
      INVENTORY_SERVICE_GROUPS.dynamodb.service,
      INVENTORY_SERVICE_GROUPS.rds.service
    ]
  },
  {
    title: 'Analytics',
    services: [
      INVENTORY_SERVICE_GROUPS['kinesis.data-streams'].service,
      INVENTORY_SERVICE_GROUPS['kinesis.firehose'].service,
      INVENTORY_SERVICE_GROUPS['kinesis.analytics'].service,
      INVENTORY_SERVICE_GROUPS.opensearch.service
    ]
  },
  {
    title: 'Storage',
    services: [
      INVENTORY_SERVICE_GROUPS.s3.service
    ]
  }
]

const getResourceCounts = (allResources) => {
  if (!allResources) return {}
  const allResourcesByType = groupBy(Object.values(allResources), 'type')
  return Object.keys(allResourcesByType).reduce((counts, type) => {
    const service = findInventoryByType(type)?.service
    if (!service) return counts
    counts[service] = allResourcesByType[type].length
    return counts
  }, {})
}

const divideByGroup = (integrations, resourceCounts = {}) => {
  const byService = keyBy(integrations, 'service')
  return GROUPINGS.reduce((carry, group, idx) => {
    const groupIntegrations = group.services.map(service => {
      const resourceCount = resourceCounts[service] || 0
      return { key: service, integration: byService[service], resourceCount }
    })
    carry[idx] = groupIntegrations
    return carry
  }, {})
}

const Regions = ({ regions = [] }) => {
  const text = regions.length ? `${regions.length} REGIONS` : 'ALL REGIONS'
  return (
    <IconWithText icon={<GlobeAmericaIcon />} text={text} className={styles.detail} />
  )
}

const PollInterval = ({ interval }) => {
  const intervalText = interval < 60 ? `${interval} min` : `${interval / 60} h`
  return (
    <IconWithText icon={<PollingIntervalIcon />} text={intervalText} className={styles.detail} />
  )
}

const LeftDetails = ({ integration, disabled }) => {
  return (
    <div className={styles.left_details}>
      {disabled
        ? <span>DISABLED</span>
        : <>
          <PollInterval interval={integration?.interval} />
          <Regions regions={integration?.filters?.regions} />
        </>
      }
    </div>
  )
}

const ResourceBadge = ({ count, loading }) => {
  if (loading) return <SpinnerIcon className={styles.icon} />
  return <SeverityBadge severity={count ? 'ok' : 'neutral'} text={count || '0'} />
}

const ErrorBadge = ({ count }) => {
  if (!count) return null
  return (
    <div className={styles.error_badge}>
      <AlertCircleIcon className={styles.icon} />
      <span className={styles.error_count}>{count}</span>
    </div>
  )
}

const RightDetails = ({ resourceCount, loadingResources, disabled, errorCount }) => {
  if (disabled) return <NoConnectionIcon className={styles.icon} />
  return (
    <div className={styles.right_details}>
      <ErrorBadge count={errorCount} />
      <ResourceBadge count={resourceCount} loading={loadingResources} />
    </div>
  )
}

const Integration = ({ integration, resourceCount, loadingResources }) => {
  const { delegationId } = useParams()
  const routes = useAccountRoutes()
  const serviceGroup = INVENTORY_SERVICE_GROUPS[integration?.service]
  const isDisabled = integration?.status === 'disabled'
  return (
    <Item
      url={integration?.id ? routes.settings.integrations.integration.url({ delegationId, integrationId: integration?.id }) : null}
      icon={<AwsServiceIcon service={integration?.service} />}
      className={classnames(styles.item, styles.integration)}
      gap='lg'
    >
      <div className={styles.header}>
        <LeftDetails integration={integration} disabled={isDisabled} />
        <RightDetails resourceCount={resourceCount} loadingResources={loadingResources} disabled={isDisabled} errorCount={integration?.errors?.length} />
      </div>
      <div className={styles.content}>
        <div className={classnames(styles.title, isDisabled && styles.disabled)}>{serviceGroup?.title}</div>
      </div>
    </Item>
  )
}

const Group = ({ title, integrations, loading, loadingResources }) => {
  const groupIntegrations = integrations.map(({ key, integration, resourceCount }) => <Integration key={key} integration={integration} resourceCount={resourceCount} loadingResources={loadingResources} />)
  return (
    <Section
      title={title}
      loading={loading}
      titleUpperCase
      contentWrapperClassName={styles.group}
    >
      {groupIntegrations}
    </Section>
  )
}

const DelegationIntegrations = () => {
  const { delegationId } = useParams()
  const { data, isLoading } = useDelegationIntegrationsQuery(delegationId)
  const { data: allResources, isLoading: isLoadingResources } = useAllResourcesQuery()
  const integrations = data?.data

  if (integrations && !integrations?.length) return null

  const resourceCounts = getResourceCounts(allResources)

  const integrationsByGroup = divideByGroup(integrations, resourceCounts)
  return (
    <Section loading={isLoading} className={styles.groups}>
      {
        GROUPINGS.map((group, idx) =>
          <Group
            key={idx}
            title={group.title}
            integrations={integrationsByGroup[idx]}
            loading={isLoading}
            loadingResources={isLoadingResources}
          />
        )
      }
    </Section>
  )
}

export default DelegationIntegrations
