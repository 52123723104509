import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'

export const PolicySeverities = [{
  title: 'critical',
  icon: faTimesCircle
}, {
  title: 'warning',
  icon: faExclamationCircle
}, {
  title: 'info',
  icon: faInfoCircle
}]

export const ErrorOccurrences = [{
  title: 'new'
}, {
  title: 'all'
}]

export const ErrorTypes = [{
  title: 'crash',
  value: 'crash'
}, {
  title: 'timeout',
  value: 'timeout'
}, {
  title: 'out of memory',
  value: 'out_of_memory'
}, {
  title: 'configuration error',
  value: 'configuration_error'
}, {
  title: 'early exit',
  value: 'early_exit'
}]

export const InsightRules = [{
  title: 'Lambda',
  value: 'aws.lambda'
}, {
  title: 'API Gateway HTTP',
  value: 'aws.apigateway.http'
}, {
  title: 'API Gateway REST',
  value: 'aws.apigateway.rest'
}, {
  title: 'SQS',
  value: 'aws.sqs'
}, {
  title: 'DynamoDB',
  value: 'aws.dynamodb'
}, {
  title: 'ECS services',
  value: 'aws.ecs.service'
}, {
  title: 'ECS clusters',
  value: 'aws.ecs.cluster'
}, {
  title: 'Kinesis data streams',
  value: 'aws.kinesis.data-streams'
}, {
  title: 'Kinesis Firehose',
  value: 'aws.kinesis.firehose'
}, {
  title: 'Step Functions',
  value: 'aws.stepfunctions'
}, {
  title: 'ELB Application Load balancer',
  value: 'aws.elb.application'
}, {
  title: 'ELB Application Gateway',
  value: 'aws.elb.gateway'
}, {
  title: 'ELB Application Network',
  value: 'aws.elb.network'
}, {
  title: 'Kinesis Analytics',
  value: 'aws.kinesis.analytics'
}, {
  title: 'SNS',
  value: 'aws.sns.topic'
}, {
  title: 'RDS Cluster',
  value: 'aws.rds.cluster'
}, {
  title: 'RDS Instance',
  value: 'aws.rds.instance'
}, {
  title: 'OpenSearch',
  value: 'aws.opensearch'
}]
