import { faClock } from '@fortawesome/pro-light-svg-icons/faClock'
import { faFileLines } from '@fortawesome/pro-light-svg-icons'

import { formatDuration } from 'components/charts/utils'

import { getValues, getSparklineByName, average } from '../helpers'

export const EVENTBRIDGE_START_LATENCY = 'eventbridge-start-latency'

const metrics = [{
  key: EVENTBRIDGE_START_LATENCY,
  icon: faClock,
  title: 'Duration',
  filter: (collection) => {
    const values = getValues(collection, 'EVENTBRIDGE_EVENTBUS_START_LATENCY')

    const valuesList = values?.map(value => {
      return value.value
    })
    return formatDuration(average(valuesList))
  },
  subvalue: (collection) => getSparklineByName(collection, 'EVENTBRIDGE_EVENTBUS_START_LATENCY'),
  selectable: true
},
{
  key: 'rules',
  icon: faFileLines,
  title: 'Rules',
  filter: (c, i, metadata) => {
    return Object.keys(metadata?.rules || {})?.length
  }
}]

export default metrics
