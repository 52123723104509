import { useQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { DELEGATION_INTEGRATIONS_LIST, integrationsPaths as paths } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const findIntegrations = async (account, delegation) => apiFetch(paths.delegationIntegrations(account, delegation), { method: 'GET' })

export const useDelegationIntegrationsQuery = (delegationId) => {
  const { account } = useOrganization()

  return useQuery(
    [DELEGATION_INTEGRATIONS_LIST, account?.id, delegationId],
    () => findIntegrations(account?.id, delegationId),
    {
      staleTime: 30 * 60 * 1000,
      enabled: !!account?.id && !!delegationId,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
