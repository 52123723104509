import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Menu } from 'antd'

import { SCOPES, useUserPermissions } from 'features/permissions-gate'
import accountPickerMenuItems, { useSwitchAccount } from 'features/layout/navigation/account-picker'
import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery } from 'hooks/api'
import { Breakpoint, useResize } from 'hooks/resize'
import { SplitViewContext } from 'features/layout/split-pane'

import styles from './styles.module.less'

const getMenuItem = (account, key, label) => {
  const accountKey = !account ? key : `/${account.id}${key}`

  return {
    key: accountKey,
    label: <span className={styles.item}>{label}</span>
  }
}

const common = (account, organizations, organization) => [
  getMenuItem(account, '/settings/profile', 'Profile'),
  getMenuItem(account, '/settings/security', 'Security'),
  getMenuItem(account, '/settings/invitations', 'Invitations'),
  ...accountPickerMenuItems({ organizations, account, organization }),
  getMenuItem(account, '/settings/manage-accounts', 'Manage accounts')
]

const notifications = (account) => getMenuItem(account, '/settings/notifications', 'Notifications')
const integrations = (account) => getMenuItem(account, '/settings/integrations', 'Integrations')
const users = (account) => getMenuItem(account, '/settings/users', 'Users & permissions')
const billing = (account) => getMenuItem(account, '/settings/subscriptions', 'Billing')
const payment = (account) => getMenuItem(account, '/settings/payment', 'Payment methods')
const invoices = (account) => getMenuItem(account, '/settings/invoices', 'Invoices')
const usage = (account) => getMenuItem(account, '/settings/usage', 'Usage reports')

const organizationBilling = (account, organization) => {
  const awsCustomer = organization?.metadata?.billing === 'aws'
  return [
    billing(account),
    !awsCustomer && payment(account),
    !awsCustomer && invoices(account),
    usage(account)
  ]
}

const SettingsMenu = () => {
  const history = useHistory()
  const location = useLocation()

  const { hasPermission } = useUserPermissions()
  const { account, organization } = useOrganization()
  const { data: organizations } = useOrganizationsQuery()
  const { switchAccount } = useSwitchAccount({ organizations })
  const { handleListClose } = useContext(SplitViewContext)

  const { isVisibleUntil } = useResize()
  const showInlineMenu = isVisibleUntil(Breakpoint.lg)

  const onSelect = ({ key, keyPath, selectedKeys }) => {
    const [item, parentKey] = keyPath
    if (parentKey === 'accounts') {
      switchAccount(keyPath)
    } else {
      history.push(key)
      handleListClose()
    }
  }

  return (
    <Menu
      onSelect={onSelect}
      mode={showInlineMenu ? 'inline' : 'vertical'}
      selectedKeys={[location.pathname, `${organization.id}:${account.id}`]}
      className={styles.menu}
      items={[
        ...common(account, organizations, organization),
        hasPermission([SCOPES.canManageNotificationSettings]) && {
          type: 'group',
          label: <span className={styles.group_title}>Account</span>,
          children: [
            notifications(account),
            hasPermission([SCOPES.canManageIntegrationSettings]) && integrations(account)
          ]
        },
        hasPermission([SCOPES.canManageUserSettings]) && {
          type: 'group',
          label: <span className={styles.group_title}>Organization</span>,
          children: [
            users(account),
            ...(hasPermission([SCOPES.canManageBillingSettings]) ? organizationBilling(account, organization) : [])
          ]
        }
      ]}
    />
  )
}

export default SettingsMenu
