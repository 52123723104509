import { useQuery, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import querystring from 'query-string'

import { getResourceName, getDelegationName } from 'lib/resources/transformations'
import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { DELEGATIONS_LIST } from 'hooks/api'
import { resourcePaths as paths, RESOURCE_ACTIVE } from './index'

const getResource = async (account, { type, ids }) => {
  const query = querystring.stringify(omitEmpty({ type, ids }))

  const data = await apiFetch(paths.filter(paths.resources(account), query))
  return data?.data[0]
}

export const useResourceQuery = (resourceId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  const delegations = queryClient.getQueryData([...DELEGATIONS_LIST, account?.id]) || []

  const transformResource = resource => (
    {
      ...resource,
      title: getResourceName(resource),
      ...(delegations?.length !== 0 ? { delegationName: getDelegationName(delegations, resource) } : [])
    })

  return useQuery(
    [...RESOURCE_ACTIVE, resourceId],
    () => getResource(account.id, { ids: [resourceId] }),
    {
      select: transformResource,
      enabled: !!account?.id && !!resourceId,
      refetchOnWindowFocus: false
    }
  )
}
