import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import StatusComponent from 'components/billing/stripe/status'
import { GET_PAYMENT_METHODS } from 'actions/billing.actions'
import { STATUSES } from 'lib/organization-constants'
import { useOrganization } from 'hooks/context/organization-context'

const shouldUpgrade = (organization = {}, methods = []) => {
  const hasPaymentMethods = methods.length > 1
  const isActive = organization.status === STATUSES.normal
  return !hasPaymentMethods && !isActive
}

const Status = ({ onClick, className } = {}) => {
  const { organization } = useOrganization()
  if (!organization) return null

  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))
  const loadingMethods = useSelector(state => state.waiting.list[GET_PAYMENT_METHODS])

  return (
    <StatusComponent
      loading={loadingMethods}
      tier={get(organization, 'tier')}
      status={get(organization, 'status')}
      shouldUpgrade={shouldUpgrade(organization, paymentMethods)}
      onClick={onClick}
      className={className}
    />
  )
}

export default Status
