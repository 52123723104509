import React from 'react'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
import classnames from 'classnames'

import successAnimation from 'containers/onboarding/steps/animations/success-animation.json'
import loadingAnimation from 'containers/onboarding/steps/animations/loading-animation.json'
import errorAnimation from 'containers/onboarding/steps/animations/error-animation.json'

import { useAllResourcesQuery, useAlarmsRulesQuery, useOrganizationUsersQuery, useNotificationChannelsQuery, useNotificationPoliciesQuery, useGetPaymentMethods, useGetPaymentMethodsV1 } from 'hooks/api'
import { useAccountRoutes } from 'containers/routes'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const OnboardingChecklist = ({ className }) => {
  const routes = useAccountRoutes()

  const { organization } = useOrganization()
  const isUsingBillingV2 = organization?.metadata?.billing === 'v2'

  const { data: users, isLoading: usersLoading } = useOrganizationUsersQuery()

  const { data: resources, isLoading: resourcesLoading } = useAllResourcesQuery()
  const { data: alarmsRules, isLoading: alarmsLoading } = useAlarmsRulesQuery()
  const { data: channels, isLoading: channelsLoading } = useNotificationChannelsQuery()
  const { data: paymentMethodsV2, isLoading: paymentMethodsV2Loading } = useGetPaymentMethods({ enabled: isUsingBillingV2 })
  const { data: paymentMethods, isLoading: paymentMethodsV1Loading } = useGetPaymentMethodsV1({ enabled: !isUsingBillingV2 })
  const { data: notificationPolicies, isLoading: notificationPoliciesLoading } = useNotificationPoliciesQuery()

  const paymentMethodsLoading = paymentMethodsV1Loading || paymentMethodsV2Loading

  const checklist = [{
    loading: () => resourcesLoading,
    checked: () => (resources ? Object.keys(resources) : []).length > 0,
    text: 'Configure resources',
    url: routes.settings.integrations.url()
  }, {
    // can also use !!channels.find(channel => channel.email !== currentUser.email) if we want unique channel check
    loading: () => channelsLoading,
    checked: () => (channels || []).length > 1,
    text: 'Set up additional notification channels',
    url: routes.settings.notifications.url()
  }, {
    loading: () => alarmsLoading,
    checked: () => (alarmsRules || []).length > 0,
    text: 'Set up alarms',
    url: routes.alarms.url()
  }, {
    loading: () => notificationPoliciesLoading,
    checked: () => (notificationPolicies || []).length > 1,
    text: 'Set up additional event notifications',
    url: routes.settings.notifications.url()
  }, {
    loading: () => usersLoading,
    checked: () => (users || []).length > 1,
    text: 'Invite team members',
    url: routes.settings.users.url()
  }, {
    loading: () => paymentMethodsLoading,
    checked: () => (paymentMethods || paymentMethodsV2 || []).length > 0,
    text: 'Set up payment methods',
    url: routes.settings.payment.url()
  }]

  const lottie = (loading = true, success) => {
    let animationData = loadingAnimation
    if (!loading) {
      animationData = success ? successAnimation : errorAnimation
    }

    return (
      <Lottie
        animationData={animationData}
        style={{ flexShrink: 0, margin: '0 9px 0 0', lineHeight: 0.9, height: 30, width: 30 }}
        loop={loading}
        width={30}
        height={30}
      />
    )
  }

  return (
    <div className={className}>
      <Card title='Onboarding checklist'>
        <div className={styles.container}>
          {checklist.map((item, index) => (
            <Link
              to={item.url} key={index} className={
                classnames({
                  [styles.success]: !item.loading() && item.checked(),
                  [styles.error]: !item.loading() && !item.checked()
                })
              }
            >
              {lottie(item.loading(), item.checked())}
              {item.text}
            </Link>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default OnboardingChecklist
