import React from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { InfoIcon, AlertIcon, SuccessIcon } from 'components/icons/font-awesome'
import { SEVERITY_TYPES } from 'lib/event-constants'
import styles from './styles.module.less'

const Icon = ({ status }) => {
  if (status === SEVERITY_TYPES.INFO) return <InfoIcon />
  if (status === SEVERITY_TYPES.WARNING || status === SEVERITY_TYPES.CRITICAL) return <AlertIcon />
  return <SuccessIcon />
}

const Wrapper = ({ url, onClick, className, children }) => {
  if (url) return <Link to={url} className={className}> {children}</Link>

  return (
    <button onClick={onClick} className={className}>
      {children}
    </button>
  )
}

const GroupItem = ({ status, id, title, icon, count = 0, wrapperClassName, selected = [], onSelect, large = false, url }) => {
  return (
    <Wrapper
      url={url}
      onClick={() => onSelect(id)}
      className={classnames(styles.container, status && styles[status], selected.includes(id) && styles.selected, large && styles.large, wrapperClassName)}
    >
      <div className={styles.wrapper}>
        <h5 className={styles.title}>{status || title}</h5>
        <p className={classnames(styles.count, selected.includes(id) && styles.selected, large && styles.large)}>{count}</p>
      </div>
      {(icon || status) && <div className={classnames(styles.icon_wrapper, styles[status])}>{icon || <Icon status={status} />}</div>}
    </Wrapper>

  )
}

export default GroupItem
