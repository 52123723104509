import { useEffect, useState } from 'react'
import { differenceInMinutes, sub } from 'date-fns'

const useGlobalDatePicker = (initialValues = {}) => {
  const { disabled, refresh } = initialValues
  const [relativeSpan, setRelativeSpan] = useState(disabled ? null : initialValues?.relativeSpan || 24 * 60)
  const [start, setStart] = useState(disabled ? null : initialValues?.start || sub(new Date(), { minutes: relativeSpan }))
  const [end, setEnd] = useState(disabled ? null : initialValues?.end || new Date())
  const [range, setRange] = useState(24 * 60)
  const [refreshInterval, setRefreshInterval] = useState(false)

  const handleSelectRange = (start, end, relativeSpan) => {
    setStart(start)
    setEnd(end)
    setRange(differenceInMinutes(end, start))
    setRefreshInterval(false)
    setRelativeSpan(relativeSpan || false)
  }

  const handleIntervalChange = (e) => {
    const valueInMs = e.target.value
    if (valueInMs === 'false') return setRefreshInterval(false)
    setRefreshInterval(parseInt(valueInMs))
  }

  useEffect(() => {
    if (disabled) return
    handleSelectRange(sub(new Date(), { minutes: initialValues?.relativeSpan }), new Date(), initialValues?.relativeSpan)
  }, [disabled, refresh])

  return { start, end, range, relativeSpan, refreshInterval, handleSelectRange, handleIntervalChange }
}

export default useGlobalDatePicker
