import React from 'react'
import { useHistory } from 'react-router-dom'
import { groupBy, reduce, sortBy } from 'lodash'

import { Button, List } from 'antd'
import { ResourceIcon, ErrorIcon, AlarmIcon, InsightIcon } from 'components/icons'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import Empty from 'components/layout/content/empty'

import { useAccountRoutes } from 'containers/routes'
import { useResourceGroupsQuery, useAlarmsQuery, useInsightsQuery, useErrorsQuery } from 'hooks/api'
import styles from './styles.module.less'

const ResourceGroups = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { data: groups, isLoading } = useResourceGroupsQuery()
  const { data: alarms, isLoading: loadingAlarms } = useAlarmsQuery()
  const { data: insights, isLoading: loadingInsights } = useInsightsQuery()
  const { data: errors, isLoading: loadingErrors } = useErrorsQuery()

  const events = alarms?.concat(insights)?.concat(errors)

  const loading = isLoading || loadingAlarms || loadingInsights || loadingErrors

  return (
    <Content
      loading={loading}
      item
      title='Resource groups'
      breadcrumbs={['Resource Groups']}
      actions={<Button type='primary' onClick={() => history.push(routes.resourceGroups.new.url())}>Create resource group</Button>}
    >
      <Section>
        {!groups && <EmptyGrid />}
        {groups && <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 3
          }}
          dataSource={groups && sortBy(groups, group => group.title)}
          renderItem={item => (
            <List.Item>
              <Card item={item} events={events} />
            </List.Item>
          )}
        />}
      </Section>
    </Content>
  )
}

const stats = (events) => {
  if (!events) return

  const severities = reduce(groupBy(events, event => event.severity), (result, value, key) => {
    result[key] = { severity: key, events: value }
    return result
  }, {})

  const severity = severities.critical || severities.warning || severities.info
  return severity
}

const Card = ({ item, events }) => {
  const history = useHistory()
  const routes = useAccountRoutes()
  const filteredEvents = events?.filter(event => item.resources?.includes(event.resource?.id || event.resource))
  const { insight, error, alarm } = groupBy(filteredEvents, event => event.type)
  const insights = stats(insight)
  const errors = stats(error)
  const alarms = stats(alarm)

  return (
    <div onClick={() => history.push(routes.resourceGroups.group.url({ groupId: item.id }))} className={styles.card}>
      <div className={styles.stats}>
        <div><ResourceIcon /> {item.resources.length}</div>
        <div className={styles.issues}>
          {insights && <><InsightIcon status={insights.severity} size='small' /><span className={styles[insights.severity]}>{insights.events.length}</span></>}
          {alarms && <><AlarmIcon status={alarms.severity} size='small' /><span className={styles[alarms.severity]}>{alarms.events.length}</span></>}
          {errors && <><ErrorIcon status={errors.severity} size='small' /><span className={styles[errors.severity]}>{errors.events.length}</span></>}
        </div>
      </div>
      <div className={styles.details}>{item.description}</div>
      <div className={styles.title}>{item.title}</div>
    </div>
  )
}

const EmptyGrid = () => {
  return (
    <Empty title='We could not find any resource groups.'>
      <div>Click on the new group button to get started.</div>
    </Empty>
  )
}

export default ResourceGroups
