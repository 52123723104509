import { useMutation, useQueryClient } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { channelsPaths as paths, CHANNELS_TEST, CHANNELS_LAST_NOTIFICATIONS } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getTestChannel = async (account, identity) => {
  return await apiFetch(paths.test(account, identity))
}

export const useTestChannelQuery = (channelId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    () => getTestChannel(account?.id, channelId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...CHANNELS_TEST, account?.id])
        // wait 1 second to invalidate last notifications due to SNS delay
        setTimeout(() => {
          queryClient.invalidateQueries([...CHANNELS_LAST_NOTIFICATIONS, account?.id, channelId])
        }, 1000)
      }
    }
  )
}
