import React from 'react'

import { useOrganizationQuery, useUpdateOrganizationQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import EditableTitle from 'features/editable-title'

import Content from 'components/layout/content'
import { Typography } from 'components/typography'
import AccountsTable from './accounts-table'
import DangerZone from './danger-zone'

import styles from './styles.module.less'

const ManageAccounts = () => {
  const { data: org, isLoading: isGetLoading } = useOrganizationQuery()
  const { mutate, isLoading: isUpdateLoading } = useUpdateOrganizationQuery()

  const isLoading = isGetLoading || isUpdateLoading

  const onSubmit = async (name) => {
    if (!name) return

    await mutate({
      name
    })
  }

  const title = (
    <PermissionsGate scopes={[SCOPES.canEditOrgSettings]} errorProps={{ readOnly: true }}>
      <EditableTitle
        value={org?.organization?.name}
        displayContent={org?.organization?.name}
        onSubmit={onSubmit}
        loading={isLoading}
        wrapperClassName={styles.title}
      />
    </PermissionsGate>)

  return (
    <Content
      item
      title={title}
      loading={isLoading}
      breadcrumbs={['settings', 'manage-accounts']}
    >
      <PermissionsGate
        scopes={[SCOPES.canEditOrgSettings]}
        RenderError={() => <Typography.Paragraph>Only organisation owners can create, edit and remove accounts</Typography.Paragraph>}
      >
        <Typography.Paragraph>Create, edit and remove accounts</Typography.Paragraph>
      </PermissionsGate>
      <AccountsTable />
      <PermissionsGate scopes={[SCOPES.canEditOrgSettings]} >
        <DangerZone className={styles.suspend_container} />
      </PermissionsGate>
    </Content >
  )
}

export default ManageAccounts
