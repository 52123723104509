import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { dashboardsPaths as paths, WIDGETS_LIST } from './index'

const updateWidget = async (account, dashboardId, widgetId, payload) => {
  const data = await apiFetch(paths.widget(account, dashboardId, widgetId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateWidgetQuery = () => {
  const { dashboardId } = useParams()
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    ({ widgetId, payload }) => updateWidget(account.id, dashboardId, widgetId, payload),
    {
      onMutate: ({ widgetId, payload}) => {
        const previousList = queryClient.getQueryData([...WIDGETS_LIST, account.id, dashboardId])
        const updatedList = previousList?.map(item => item.id === widgetId ? { ...item, ...payload } : item)
        queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], updatedList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...WIDGETS_LIST, account.id, dashboardId], context.previousList)
        }
      },
    }
  )
}
