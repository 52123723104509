import NAMESPACES from 'containers/inventory/details/resource/metrics/namespaces'
import STATISTICS from 'containers/inventory/details/resource/metrics/statistics'

const metrics = [
  {
    id: 'ELB_LOADBALANCER_LCU',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'lcu',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_REQUESTS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'request.count',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_CONNECTIONS_ACTIVE',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'connections.active',
      stats: [STATISTICS.AVERAGE]
    }
  },
  {
    id: 'ELB_LOADBALANCER_PROCESSED_BYTES',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'processed.bytes',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_DROPPED_HEADERS',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'dropped.invalid.headers',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_2XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'targets.response.code.2XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_3XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'targets.response.code.3XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_4XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'targets.response.code.4XX',
      stats: [STATISTICS.SUM]
    }
  },
  {
    id: 'ELB_LOADBALANCER_TARGETS_RESPONSE_CODE_5XX',
    filter: {
      namespace: NAMESPACES.AWS,
      metric: 'targets.response.code.5XX',
      stats: [STATISTICS.SUM]
    }
  }
]

export default metrics
