import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import SplitPane from 'features/layout/split-pane'
import RulesList from './list'
import InsightDetails from './details'
import InsightsDashboard from './dashboard'

export const parseInsightRule = (rule) => {
  if (!rule) return
  if (rule?.includes('_')) return rule.replace(/_/g, '.')
  else return rule.replace(/\./g, '_')
}

const InsightsView = () => {
  const { ruleId } = useParams()

  useEffect(() => (document.title = 'Dashbird.io - Insights'), [])

  return (
    <SplitPane
      left={
        <RulesList />
      }
      right={ruleId
        ? <InsightDetails />
        : <InsightsDashboard />}
    />
  )
}

export default InsightsView
