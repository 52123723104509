import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { organizationsPaths as paths, ACCOUNTS_LIST } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getAccounts = async (organizationId) => {
  const data = await apiFetch(paths.accounts(organizationId))
  return data
}

export const useAccountsQuery = () => {
  const { organization } = useOrganization()

  return useQuery(
    [...ACCOUNTS_LIST, organization.id],
    () => getAccounts(organization.id),
    {
      staleTime: Infinity,
      enabled: !!organization.id
    }
  )
}
