const SEGMENT_GROUP = {
  AWS_ANALYTICS: 'AWS_ANALYTICS',
  AWS_APPLICATION_INTEGRATION: 'AWS_APPLICATION_INTEGRATION',
  AWS_COMPUTE: 'AWS_COMPUTE',
  AWS_DATABASE: 'AWS_DATABASE',
  AWS_DEVELOPER_TOOLS: 'AWS_DEVELOPER_TOOLS',
  AWS_MANAGEMENT_GOVERNANCE: 'AWS_MANAGEMENT_GOVERNANCE',
  AWS_NETWORKING_CONTENT_DELIVERY: 'AWS_NETWORKING_CONTENT_DELIVERY',
  AWS_STORAGE: 'AWS_STORAGE',
  DASHBIRD: 'DASHBIRD' // default
}

const formatSegmentGroup = (segmentGroup, level) => {
  return {
    name: segmentGroup,
    level: level || 0
  }
}

const getSegmentGroup = (node, parent) => {
  if (node.inferred) return

  const serviceName = node.aws?.service?.name
  if (serviceName === 'DynamoDB') {
    return formatSegmentGroup(SEGMENT_GROUP.AWS_DATABASE)
  }

  const metadata = node.metadata?.default
  const metadataServiceName = metadata?.['aws.service.name']
  switch (metadataServiceName) {
    case 'DynamoDB':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_DATABASE)
  }

  switch (node.name) {
    case 'S3':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_STORAGE)
    case 'Lambda':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_COMPUTE)
    case 'DynamoDB':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_DATABASE)
    case 'SES':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION)
    case 'SSM':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_MANAGEMENT_GOVERNANCE)
    case 'SNS':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION)
    case 'SQS':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION)
    case 'Events':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION)
  }

  switch (node.origin) {
    case 'AWS::Lambda':
    case 'AWS::Lambda::Function':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_COMPUTE)
    case 'AWS::ApiGateway::Stage':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_NETWORKING_CONTENT_DELIVERY)
    case 'AWS::StepFunctions::StateMachine':
      return formatSegmentGroup(SEGMENT_GROUP.AWS_APPLICATION_INTEGRATION)
  }

  if (parent && parent.segmentGroup) {
    const { name, level } = parent.segmentGroup
    return formatSegmentGroup(name, level + 1)
  }

  return formatSegmentGroup(SEGMENT_GROUP.DASHBIRD)
}

export { SEGMENT_GROUP, getSegmentGroup }
