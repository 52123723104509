import React from 'react'
import classnames from 'classnames'
import { ResponsiveContainer } from 'recharts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt'

import styles from './styles.module.less'

const THEMES = {
  LIGHT: 'light'
}

const Loading = ({ height, theme }) => {
  return (
    <ResponsiveContainer className={styles.container} height={height} width='100%' debounce={300}>
      <div className={classnames(styles.wrapper, { [styles.light]: theme === THEMES.LIGHT })}>
        <FontAwesomeIcon className={styles.icon} size='3x' icon={faSyncAlt} spin />
        <h3>Loading</h3>
      </div>
    </ResponsiveContainer>
  )
}

export default Loading
