import { useInfiniteQuery } from 'react-query'
import { apiFetch } from 'lib/action-helpers'
import { useOrganization } from 'hooks/context/organization-context'

import {
  SEARCH,
  searchPaths as paths,
  serializeExecutionsPayload as serializePayload
} from './index'

const searchExecutions = async (account, payload, nextToken) => apiFetch(paths.executions(account), { method: 'POST', body: JSON.stringify({ ...payload, nextToken }) })

export const useSearchExecutionsQuery = (data) => {
  const { account } = useOrganization()
  const payload = serializePayload(data)

  return useInfiniteQuery(
    [SEARCH, payload, payload?.filters, account?.id],
    ({ pageParam }) => searchExecutions(account?.id, payload, pageParam),
    {
      getNextPageParam: (lastPage, pages) => lastPage.next,
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id && !!payload,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
