import React from 'react'

import Loading from 'components/layout/content/loading'
import { chartHeight } from 'components/charts/utils'

import CPUChart from './cpu'

import MessagesChart from './sqs/messages'
import DifferenceChart from './sqs/difference'
import DelayChart from './sqs/delay'
import PayloadChart from './sqs/payload'
import ReadsChart from './dynamodb/reads'
import WritesChart from './dynamodb/writes'
import LatencyChart from './dynamodb/latency'
import ErrorsChart from './dynamodb/errors'
import ApiGatewayLatencyChart from './apigateway/apigw-latency'
import ApiGatewayErrorsChart from './apigateway/apigw-errors'
import AppSyncRequestsChart from './appsync/requests'
import AppSyncLatencyChart from './appsync/appsync-latency'
import AppSyncErrorsChart from './appsync/appsync-errors'
import AppSyncResolverRequestsChart from './appsync/resolver-requests'
import AppSyncResolverErrorsChart from './appsync/resolver-errors'
import AppSyncResolverDurationChart from './appsync/resolver-duration'
import RequestsChart from './apigateway/requests'
import MemoryChart from './ecs/memory'
import ELBErrorsChart from './elb/errors'
import ELBRequestsChart from './elb/requests'
import ELBConnectionsChart from './elb/connections'
import ELBLCUChart from './elb/lcu'
import ELBDataChart from './elb/data'
import ELBHealthyChart from './elb/healthy'
import ELBResponseTimeChart from './elb/response-time'
import EventBridgeRuleInvocationsChart from './eventbridge/rule/invocations'
import EventBridgeRuleRulesChart from './eventbridge/rule/rules'
import EventBridgeEventBusLatencyChart from './eventbridge/eventbus/latency'
import StepFunctionsChart from './stepfunctions/stepfunctions'
import StepFunctionsFailedChart from './stepfunctions/failed'
import StepFunctionsDurationChart from './stepfunctions/duration'
import KinesisAnalyticsCPUUtilization from './kinesis/analytics/cpu-utilization'
import KinesisAnalyticsMemoryUtilization from './kinesis/analytics/memory-utilization'
import KinesisAnalyticsRecords from './kinesis/analytics/records'
import KinesisAnalyticsCheckpoints from './kinesis/analytics/checkpoint-duration-size'
import KinesisAnalyticsUptimeDowntimeRestarts from './kinesis/analytics/uptime-downtime-restarts'
import KinesisAnalyticsBusyBackPressuredTime from './kinesis/analytics/busy-and-backpressured-time'
import KinesisAnalyticsKPUs from './kinesis/analytics/kpus'
import KinesisDataStreamRatio from './kinesis/data-stream-ratio'
import KinesisDataStreamDifference from './kinesis/data-stream-difference'
import KinesisDataStreamIncoming from './kinesis/data-stream-incoming'
import KinesisDataStreamOutgoing from './kinesis/data-stream-outgoing'
import KinesisDataStreamOutgoingAge from './kinesis/data-stream-outgoing-age'
import KinesisFirehoseIncoming from './kinesis/firehose-incoming'
import KinesisFirehoseOutgoing from './kinesis/firehose-outgoing'
import LambdaInvocationsChart from './lambda/invocations'
import LambdaErrorsChart from './lambda/errors'
import LambdaMemoryChart from './lambda/memory'
import LambdaCostChart from './lambda/cost'
import LambdaConcurrencyChart from './lambda/concurrency'
import LambdaDurationChart from './lambda/duration'
import S3BucketSizeChart from './s3/bucket-size'
import S3ObjectCountChart from './s3/object-count'
import SNSMessagesChart from './sns/messages'
import SNSPayloadChart from './sns/payload'
import SNSNotificationsChart from './sns/notifications'
import ConnectionsChart from './rds/connections'
import RDSCpuMemoryChart from './rds/cpu-memory'
import RDSReadsWritesChart from './rds/reads-writes'
import RDSReplicaLagChart from './rds/replica-lag'
import RDSNetworkTrafficChart from './rds/network-traffic'
import RDSStorageChart from './rds/storage'
import RDSClientConnectionsChart from './rds/client-connections'
import RDSQueryRequestsChart from './rds/query-requests'
import OpensearchRequestsChart from './opensearch/requests'
import OpensearchShardsChart from './opensearch/shards'
import OpensearchDocumentsChart from './opensearch/documents'
import OpensearchStorageChart from './opensearch/storage'
import OpensearchCPUMemoryChart from './opensearch/cpu-memory'
import OpensearchSearchChart from './opensearch/search'
import OpensearchIndexingChart from './opensearch/indexing'
import OpensearchNodesChart from './opensearch/nodes'

import { HEALTHY, RESPONSE_TIME } from '../metrics-tabs/services/elb-target-group'
import { CONNECTIONS, DATA, ERRORS, LCU, REQUESTS } from '../metrics-tabs/services/elb-application-load-balancer'
import { RDS_CONNECTIONS, RDS_CPU_MEMORY, RDS_REPLICA_LAG, RDS_READ, RDS_WRITE, RDS_NETWORK_TRAFFIC, RDS_STORAGE, RDS_CPU } from '../metrics-tabs/services/rds-instance'
import { RDS_DATABASE_CONNECTIONS, RDS_CLIENT_CONNECTIONS, RDS_QUERY_REQUESTS } from '../metrics-tabs/services/rds-proxy'
import { APIGW_ERRORS, APIGW_LATENCY, APIGW_REQUESTS } from '../metrics-tabs/services/apigateway'
import { DYNAMOBD_ERRORS, DYNAMOBD_LATENCY, DYNAMOBD_WRITES, DYNAMODB_READS } from '../metrics-tabs/services/dynamodb'
import { ECS_CPU, ECS_MEMORY } from '../metrics-tabs/services/ecs-cluster'
import { EVENTBRIDGE_START_LATENCY } from '../metrics-tabs/services/eventbridge-eventbus'
import { EVENTBRIDGE_RULE_INVOCATIONS, EVENTBRIDGE_RULE_TRIGGERED_RULES } from '../metrics-tabs/services/eventbridge-rule'
import { KDA_CPU_UTILIZATION, KDA_HEAP_MEM_UTILIZATION, KDA_CHECKPOINT_DURATION, KDA_KPUS, KDA_UPTIME, KDA_INCOMING_RECORDS, KDA_OUTGOING_RECORDS, KDA_TIME_BUSY } from '../metrics-tabs/services/kinesis-analytics'
import { KINESIS_DS_INCOMING, KINESIS_DS_OUTGOING, KINESIS_DS_OUTGOING_AGE, KINESIS_DS_DIFFERENCE, KINESIS_DS_RATIO } from '../metrics-tabs/services/kinesis-datastream'
import { KINESIS_FIREHOSE_INCOMING, KINESIS_FIREHOSE_OUTGOING } from '../metrics-tabs/services/kinesis-firehose'
import { LAMBDA_CONCURRENCY, LAMBDA_COST, LAMBDA_DURATION, LAMBDA_ERRORS, LAMBDA_INVOCATIONS, LAMBDA_MEMORY } from '../metrics-tabs/services/lambda'
import { S3_BUCKET_SIZE, S3_BUCKET_OBJECTS } from '../metrics-tabs/services/s3'
import { SQS_DELAY, SQS_DIFFERENCE, SQS_MESSAGES, SQS_PAYLOAD } from '../metrics-tabs/services/sqs'
import { STEPFUNCTIONS_DURATION, STEPFUNCTIONS_ERRORS, STEPFUNCTIONS_EXECUTION } from '../metrics-tabs/services/stepfunctions'
import { SNS_MESSAGES, SNS_NOTIFICATIONS, SNS_PAYLOAD_SIZE } from '../metrics-tabs/services/sns'
import { OPENSEARCH_DATANODES, OPENSEARCH_DOCUMENTS, OPENSEARCH_MASTER, OPENSEARCH_REQUESTS, OPENSEARCH_SHARDS, OPENSEARCH_STORAGE, OPENSEARCH_ULTRAWARM, OPENSEARCH_SEARCH, OPENSEARCH_INDEXING, OPENSEARCH_NODES } from '../metrics-tabs/services/opensearch'
import { APPSYNC_ERRORS, APPSYNC_LATENCY, APPSYNC_REQUESTS } from '../metrics-tabs/services/appsync'
import { APPSYNC_RESOLVER_REQUESTS, APPSYNC_RESOLVER_ERRORS, APPSYNC_RESOLVER_DURATION } from '../metrics-tabs/services/appsync-resolver'

const CHARTS = {
  [S3_BUCKET_OBJECTS]: S3ObjectCountChart,
  [S3_BUCKET_SIZE]: S3BucketSizeChart,
  [SQS_MESSAGES]: MessagesChart,
  [SQS_DIFFERENCE]: DifferenceChart,
  [SQS_DELAY]: DelayChart,
  [SQS_PAYLOAD]: PayloadChart,
  [DYNAMODB_READS]: ReadsChart,
  [DYNAMOBD_WRITES]: WritesChart,
  [DYNAMOBD_LATENCY]: LatencyChart,
  [APIGW_LATENCY]: ApiGatewayLatencyChart,
  [DYNAMOBD_ERRORS]: ErrorsChart,
  [APIGW_ERRORS]: ApiGatewayErrorsChart,
  [APIGW_REQUESTS]: RequestsChart,
  [APPSYNC_REQUESTS]: AppSyncRequestsChart,
  [APPSYNC_ERRORS]: AppSyncErrorsChart,
  [APPSYNC_LATENCY]: AppSyncLatencyChart,
  [APPSYNC_RESOLVER_REQUESTS]: AppSyncResolverRequestsChart,
  [APPSYNC_RESOLVER_ERRORS]: AppSyncResolverErrorsChart,
  [APPSYNC_RESOLVER_DURATION]: AppSyncResolverDurationChart,
  [ECS_CPU]: CPUChart,
  [ECS_MEMORY]: MemoryChart,
  [REQUESTS]: ELBRequestsChart,
  [ERRORS]: ELBErrorsChart,
  [CONNECTIONS]: ELBConnectionsChart,
  [LCU]: ELBLCUChart,
  [DATA]: ELBDataChart,
  [HEALTHY]: ELBHealthyChart,
  [RESPONSE_TIME]: ELBResponseTimeChart,
  [EVENTBRIDGE_RULE_TRIGGERED_RULES]: EventBridgeRuleRulesChart,
  [EVENTBRIDGE_RULE_INVOCATIONS]: EventBridgeRuleInvocationsChart,
  [EVENTBRIDGE_START_LATENCY]: EventBridgeEventBusLatencyChart,
  [STEPFUNCTIONS_EXECUTION]: StepFunctionsChart,
  [STEPFUNCTIONS_ERRORS]: StepFunctionsFailedChart,
  [STEPFUNCTIONS_DURATION]: StepFunctionsDurationChart,
  [KDA_CPU_UTILIZATION]: KinesisAnalyticsCPUUtilization,
  [KDA_HEAP_MEM_UTILIZATION]: KinesisAnalyticsMemoryUtilization,
  [KDA_INCOMING_RECORDS]: KinesisAnalyticsRecords,
  [KDA_OUTGOING_RECORDS]: KinesisAnalyticsRecords,
  [KDA_CHECKPOINT_DURATION]: KinesisAnalyticsCheckpoints,
  [KDA_UPTIME]: KinesisAnalyticsUptimeDowntimeRestarts,
  [KDA_TIME_BUSY]: KinesisAnalyticsBusyBackPressuredTime,
  [KDA_KPUS]: KinesisAnalyticsKPUs,
  [KINESIS_DS_INCOMING]: KinesisDataStreamIncoming,
  [KINESIS_DS_OUTGOING]: KinesisDataStreamOutgoing,
  [KINESIS_DS_DIFFERENCE]: KinesisDataStreamDifference,
  [KINESIS_DS_RATIO]: KinesisDataStreamRatio,
  [KINESIS_DS_OUTGOING_AGE]: KinesisDataStreamOutgoingAge,
  [KINESIS_FIREHOSE_INCOMING]: KinesisFirehoseIncoming,
  [KINESIS_FIREHOSE_OUTGOING]: KinesisFirehoseOutgoing,
  [LAMBDA_INVOCATIONS]: LambdaInvocationsChart,
  [LAMBDA_ERRORS]: LambdaErrorsChart,
  [LAMBDA_DURATION]: LambdaDurationChart,
  [LAMBDA_MEMORY]: LambdaMemoryChart,
  [LAMBDA_COST]: LambdaCostChart,
  [LAMBDA_CONCURRENCY]: LambdaConcurrencyChart,
  [SNS_MESSAGES]: SNSMessagesChart,
  [SNS_PAYLOAD_SIZE]: SNSPayloadChart,
  [SNS_NOTIFICATIONS]: SNSNotificationsChart,
  [RDS_CONNECTIONS]: ConnectionsChart,
  [RDS_WRITE]: RDSReadsWritesChart,
  [RDS_READ]: RDSReadsWritesChart,
  [RDS_REPLICA_LAG]: RDSReplicaLagChart,
  [RDS_CPU_MEMORY]: RDSCpuMemoryChart,
  [RDS_NETWORK_TRAFFIC]: RDSNetworkTrafficChart,
  [RDS_STORAGE]: RDSStorageChart,
  [RDS_CPU]: CPUChart,
  [RDS_DATABASE_CONNECTIONS]: ConnectionsChart,
  [RDS_CLIENT_CONNECTIONS]: RDSClientConnectionsChart,
  [RDS_QUERY_REQUESTS]: RDSQueryRequestsChart,
  [OPENSEARCH_DATANODES]: OpensearchCPUMemoryChart,
  [OPENSEARCH_MASTER]: OpensearchCPUMemoryChart,
  [OPENSEARCH_ULTRAWARM]: OpensearchCPUMemoryChart,
  [OPENSEARCH_REQUESTS]: OpensearchRequestsChart,
  [OPENSEARCH_SHARDS]: OpensearchShardsChart,
  [OPENSEARCH_DOCUMENTS]: OpensearchDocumentsChart,
  [OPENSEARCH_STORAGE]: OpensearchStorageChart,
  [OPENSEARCH_SEARCH]: OpensearchSearchChart,
  [OPENSEARCH_INDEXING]: OpensearchIndexingChart,
  [OPENSEARCH_NODES]: OpensearchNodesChart
}

const Chart = ({ loading, type, data, item, span, service, height = chartHeight }) => {
  if (loading) return <Loading height={400} />

  const SelectedChart = CHARTS[type]

  if (!SelectedChart) {
    throw new Error(`Missing chart definition for ${type}`)
  }

  return (
    <SelectedChart
      loading={loading}
      data={data}
      item={item}
      height={height}
      span={span}
      service={service}
      chartKey={type} />
  )
}

export default Chart
