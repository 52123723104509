import React from 'react'

import { EllipsisHIcon } from 'components/icons'
import IconButton from 'components/buttons/icon'

import styles from './styles.module.less'

const LoadMoreButton = ({ onClick, isLoading, hidden, ...props }) => {
  if (hidden) return null

  return (
    <div className={styles.wrapper}>
      <IconButton
        {...props}
        onClick={onClick}
        loading={isLoading}
        className={styles.button}
        title='Load more'
        icon={<EllipsisHIcon />}
      />
    </div>
  )
}

export default LoadMoreButton
