import { EventItem } from './event'
import { ProgressItem, Resource } from './progress-item'
import { ExecutionItem } from './execution-item'

const Item = {}
Item.Event = EventItem
Item.Filter = ProgressItem
Item.Resource = Resource
Item.Execution = ExecutionItem

export { Item }
