import { useQuery } from 'react-query'
import { keyBy } from 'lodash'
import { sub, getTime } from 'date-fns'

import { apiFetch } from 'lib/action-helpers'
import { usageServicePaths as paths, ORG_RESOURCES } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getOrganizationResourcesHistogram = async (organization, { from, until, onlyBillable = false }) => {
  const body = JSON.stringify({ from, until, onlyBillable })
  const data = await apiFetch(paths.organizationResourcesHistogram(organization), { method: 'POST', body })

  return keyBy(data, (accountUsage) => accountUsage.account.id)
}

export const useOrgResourcesUsageQuery = (payload, condition = true) => {
  const { organization } = useOrganization()

  const from = payload?.start || getTime(sub(new Date(), { days: 30 }))
  const until = payload?.end || Date.now()
  const onlyBillable = payload?.onlyBillable || false

  return useQuery(
    [...ORG_RESOURCES, organization?.id],
    () => getOrganizationResourcesHistogram(organization?.id, ({ from, until, onlyBillable })),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!condition
    }
  )
}
