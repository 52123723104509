import { useMutation } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const createInvoicePreview = async (identity, payload) => {
  const data = await apiFetch(paths.invoicesPreview(identity), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateInvoicePreviewQuery = () => {
  const { organization } = useOrganization()

  return useMutation(
    ({ payload }) => createInvoicePreview(organization.id, payload),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries([...PAYMENT_METHODS, organization.id])
      }
    }
  )
}
