import { useMemo, useEffect, useState } from 'react'
import { isEmpty, last, get } from 'lodash'

import colors from 'lib/colors'

const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(R * (100 + percent) / 100)
  G = parseInt(G * (100 + percent) / 100)
  B = parseInt(B * (100 + percent) / 100)

  R = (R < 255) ? R : 255
  G = (G < 255) ? G : 255
  B = (B < 255) ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
  const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
  const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

  return '#' + RR + GG + BB
}

const findConnectedParents = (parents, directParent) => {
  return parents?.filter(item => {
    if (directParent?.id === item?.id) {
      return true
    }
    const chiCount = item.children.length
    if (item.childNo === item?.children?.length) {
      // i am last in my parent, do not drag me along'
      return false
    }

    if (chiCount > 1) {
      // if my parents parent is last of its siblings then do not add the parents parent
      if (last(item?.children)?.id === directParent.id) {
        return false
      }

      return true
    }

    return false
  }) || []
}

export const formatNode = (node, traceStart, traceEnd) => {
  return {
    ...node,
    start: (node.startTime - traceStart) * 1000,
    end: (get(node, 'endTime', traceEnd) - traceStart) * 1000,
    fill: shadeColor(colors('segment', node?.segmentGroup?.name), node?.segmentGroup?.level * 10 || 0),
    color: node?.data?.isError ? colors('chart', 'red') : '#000000'
  }
}

const useTracesFormatter = ({ transformed, traceStart, traceEnd, search, searchByServices, query, serviceFilter, filterType }) => {
  const [filtered, setFiltered] = useState([])
  const flattenTree = (node, parents = [], directParent, childNo = 0, level = 0) => {
    if (isEmpty(node)) return []

    const { children } = node

    const formatted = {
      ...formatNode(node, traceStart, traceEnd),
      childNo,
      level,
      directParent,
      parents,
      connectedParents: findConnectedParents(parents, directParent)
    }

    const rec = children?.flatMap((chi, index) => flattenTree(chi, [...parents, formatted], formatted, index, level + 1))
    return [formatted, ...rec]
  }

  const flatAll = useMemo(() => flattenTree(transformed, [], {}), [transformed])

  useEffect(() => {
    if (isEmpty(query) && isEmpty(serviceFilter)) return setFiltered(flatAll)

    if (filterType === 'standalone') {
      if (isEmpty(serviceFilter)) return setFiltered(flatAll?.filter(item => item?.searchable.includes(query?.toLowerCase())))
      const results = flatAll?.filter(item => item?.searchable.includes(query?.toLowerCase()) && serviceFilter.includes(item?.service))
      return setFiltered(results)
    }

    const result = search(transformed, query, serviceFilter)

    const flat = flattenTree(result, [], {})

    return setFiltered(flat)
  }, [query, filterType, flatAll, serviceFilter])

  return { flattened: flatAll, filtered }
}

export default useTracesFormatter
