import { useInfiniteQuery } from 'react-query'
import queryString from 'query-string'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { INVOICES_LIST, billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getInvoices = async (organization, { limit, next }) => {
  const query = queryString.stringify(omitEmpty({ limit, next }))

  const data = await apiFetch(paths.filter(paths.invoices(organization), query))
  return data
}

export const useGetInvoicesQuery = () => {
  const { organization } = useOrganization()

  return useInfiniteQuery(
    [...INVOICES_LIST, organization?.id],
    ({ pageParam }) => getInvoices(organization?.id, { next: pageParam }),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      retry: false,
      getNextPageParam: (lastPage, pages) => lastPage.next
    }
  )
}
