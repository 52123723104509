import { useMutation, useQueryClient } from 'react-query'
import queryString from 'query-string'

import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { PAYMENT_METHODS, billingPaths as paths } from '../index'

const updatePaymentMethod = async (organization, identity, payload = { setPrimary: true }) => {
  const query = queryString.stringify(payload)
  const data = await apiFetch(paths.filter(paths.paymentMethod(organization, identity), query), { method: 'PUT' })
  return data
}

export const useUpdatePaymentMethodQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ methodId }) => updatePaymentMethod(organization.id, methodId),
    {
      onMutate: ({ methodId }) => {
        const previousList = queryClient.getQueryData([...PAYMENT_METHODS, organization.id])

        const updatedList = previousList?.map(item => item.id === methodId ? { ...item, isPrimary: true } : { ...item, isPrimary: false })
        queryClient.setQueryData([...PAYMENT_METHODS, organization.id], updatedList)

        return { previousList }
      },
      onError: (err, newItem, context) => {
        if (err) {
          queryClient.setQueryData([...PAYMENT_METHODS, organization.id], context.previousList)
        }
      }
    }
  )
}
