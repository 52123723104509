import { format, parseISO } from 'date-fns'
import React from 'react'
import { extractParsedJson, extractTime, isJsonString, removeLineStart } from './log-helper'
import LogRow from './search-log-row'

const LogsParser = ({ hits, keywords }) => {
  const getTime = (line, time) => {
    if (time) {
      return format(time, 'HH:mm:ss.SS')
    }
    const isJson = isJsonString(line)
    const timeString = isJson ? extractParsedJson(line)?.time : extractTime(line)

    return timeString ? format(parseISO(timeString), 'HH:mm:ss.SS') : ''
  }

  const getLogline = (line) => {
    const isJson = isJsonString(line)
    if (isJson) return line

    // removes timestamp and requestId from beginning of log line
    return removeLineStart(line)
  }

  return hits.map((item, index) => {
    if (!item?.logline) return null
    const time = getTime(item?.logline, item?.timestamp)
    const logline = getLogline(item?.logline)

    return (
      <LogRow key={index} timestamp={time} logline={logline} highlighted={keywords} />
    )
  })
}

export default LogsParser
