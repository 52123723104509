import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { delegationPaths as paths, DELEGATIONS_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createDelegation = async (account, payload) => {
  const data = await apiFetch(paths.delegations(account), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useCreateDelegationQuery = (customAccountId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  const accountId = customAccountId || account?.id
  return useMutation(
    (payload) => createDelegation(accountId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...DELEGATIONS_LIST, accountId])
      }
    }
  )
}
