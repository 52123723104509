export default async (formRef) => {
  return new Promise((resolve) => formRef.validateFields()
    .then(values => {
      return resolve(values)
    })
    .catch(errorInfo => {
      console.log(errorInfo)
      return resolve(null)
    })
  )
}
