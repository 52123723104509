import React, { useState } from 'react'
import { Button, Form } from 'antd'
import Input from 'components/antd/input'

import styles from '../styles.module.less'

const discountValidator = async (rule, value, error, setValidateStatus) => {
  if (!value || value === '') {
    setValidateStatus('')
    return Promise.resolve()
  }

  if (error) {
    setValidateStatus('error')
    throw new Error(rule.message)
  } else {
    setValidateStatus('success')
    return Promise.resolve()
  }
}

const PromoCodeForm = ({ promotion, error, handleValidate, loading }) => {
  const [validateStatus, setValidateStatus] = useState(promotion?.code ? 'success' : '')

  return (
    <Form
      className={styles.coupon_form}
      layout='inline'
      initialValues={{ promotion: promotion?.code }}
      validateTrigger='onSubmit'
      onValuesChange={handleValidate}
    >
      <Form.Item
        name='code'
        label='Coupon code'
        hasFeedback
        validateStatus={validateStatus}
        rules={[{ validator: (rule, value) => discountValidator(rule, value, promotion, error, setValidateStatus), message: 'Invalid code' }]}>
        <Input placeholder='Code' solid />
      </Form.Item>
      <Form.Item className={styles.coupon_input} noStyle>
        <Button loading={loading} type='secondary' htmlType='submit'>Apply</Button>
      </Form.Item>
    </Form>
  )
}

export default PromoCodeForm
