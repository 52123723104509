import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getTime, sub } from 'date-fns'
import { flatten, isEmpty } from 'lodash'

import {
  useCreateQueryQuery,
  useDeleteQueryQuery,
  useGetQueryQuery,
  useSearchLogsQuery,
  useSearchMetricsQuery,
  useUpdateQueryQuery
  , useResourceGroupsQuery
} from 'hooks/api'
import Content from 'components/layout/content'
import Section from 'components/layout/content/section'
import GlobalDatePicker from 'components/date-picker/global'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import EditableTitle from 'features/editable-title'
import SearchActions from './actions'
import SearchForm from './form'
import SearchMetrics from './charts/search-results'
import { AwsServiceIcon } from 'components/icons'
import { RELATIVE_VALUES } from 'components/date-picker/global/relative'
import SearchResults from './results'

import styles from './styles.module.less'

const getEmptyPayload = (start, end) => ({
  query: '',
  service: INVENTORY_SERVICES.Lambda.service,
  groupBy: 'executions',
  relativeTimespan: 15,
  start: new Date(start).toISOString(),
  end: new Date(end).toISOString(),
  targets: [],
  limit: 30
})

const SearchDetails = ({ form }) => {
  const { search } = useLocation()
  const { queryId } = useParams()

  const [start, setStart] = useState(getTime(sub(new Date(), { minutes: 15 })))
  const [end, setEnd] = useState(Date.now())
  const [relativeSpan, setRelativeSpan] = useState(15)
  const [payload, setPayload] = useState(getEmptyPayload(start, end))

  const { data: query, isLoading: isGetLoading } = useGetQueryQuery()
  const { mutateAsync: createQuery, isLoading: isCreateLoading } = useCreateQueryQuery()
  const { mutate: updateQuery, mutateAsync: createScheduledQuery, isLoading: isUpdateLoading } = useUpdateQueryQuery()
  const { mutateAsync: deleteQuery, isLoading: isDeleteLoading } = useDeleteQueryQuery()

  const { data: resourceGroups } = useResourceGroupsQuery()

  const isLoading = isGetLoading || isCreateLoading || isUpdateLoading || isDeleteLoading

  const {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading: searchFirstLoading,
    isFetchingNextPage,
    status
  } = useSearchLogsQuery(payload)

  const { data: searchMetrics, isLoading: searchMetricsLoading } = useSearchMetricsQuery(payload)

  const searchResults = flatten(data?.pages?.map(page => page.data))
  const hasEmptyResult = typeof data !== 'undefined' && !searchResults.length

  const onDateChange = (start, end, relativeSpan) => {
    setStart(start)
    setEnd(end)
    setRelativeSpan(relativeSpan)
  }

  const prefixTarget = (target) => {
    if (!(resourceGroups || []).map(group => group.id).includes(target)) return target
    return `group:${target}`
  }

  const onUpdateName = async (name) => {
    const values = form.getFieldsValue()
    const payload = {
      ...values,
      targets: values?.targets?.map(identity => prefixTarget(identity)),
      name,
      query: query.query,
      relativeTimespan: 15
    }

    await updateQuery({ payload, id: query?.id })
  }

  // set empty payload on new (empty) search
  useEffect(() => {
    if (queryId || !isEmpty(search)) return

    setPayload(getEmptyPayload(start, end))
  }, [queryId, search])

  const renderTitle = () => {
    if (!query) return 'Search'

    return <EditableTitle
      loading={isLoading}
      value={query?.name}
      onSubmit={onUpdateName}
      displayContent={query?.name}
    />
  }

  return (
    <Content item fixed
      title={renderTitle()}
      fixedTitle={query?.name || 'Search'}
      breadcrumbs={['search']}
      loading={isLoading}
      icon={query && <AwsServiceIcon service={query?.service} solid />}
      actions={
        <GlobalDatePicker
          start={start}
          end={end}
          onChange={onDateChange}
          relativeSpan={relativeSpan}
          refetchHidden
          relativeOptions={{ 15: '15m', ...RELATIVE_VALUES }}
        />
      }
      hideTitleActions
      titleRowActions={<SearchActions form={form} createQuery={createQuery} deleteQuery={deleteQuery} createScheduledQuery={createScheduledQuery} />}
    >
      <SearchForm
        form={form}
        start={start}
        end={end}
        relativeSpan={relativeSpan}
        setPayload={setPayload}
        loading={status === 'loading' || isFetching}
      />
      {/* change tooltip height */}
      <Section solid className={styles.result_metrics}>
        <SearchMetrics
          data={searchMetrics}
          loading={searchMetricsLoading}
          height={170}
        />
      </Section>
      <SearchResults
        service={payload?.service}
        results={searchResults}
        loading={searchFirstLoading}
        nextBtnHidden={!hasNextPage || isEmpty(searchResults)}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        error={isError}
        empty={hasEmptyResult}
      />

    </Content>
  )
}

export default SearchDetails
