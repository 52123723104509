import React from 'react'
import { useParams } from 'react-router-dom'
import Numeral from 'numeral'

import { Area, Line } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { objectify } from 'lib/utils'
import { useResourceQuery } from 'hooks/api'
import { RDS_CPU } from 'containers/inventory/details/resource/metrics-tabs/services/rds-instance'

const RDSCpuMemoryChart = ({ loading, data, height, span }) => {
  const { resourceId } = useParams()
  const { data: resource } = useResourceQuery(resourceId)

  const attributes = objectify(resource?.attributes)
  const dbInstance = JSON.parse(attributes?.dbInstance || '{}')
  const dbMaxMemory = parseFloat(dbInstance?.memory) // can be a float
  const burstable = dbInstance?.burstable

  if (loading || !data) return null

  const metrics = transform({
    mem: data.RDS_FREEABLE_MEMORY,
    cpu: data.RDS_CPU_UTILIZATION,
    burstBalance: data.RDS_BURST_BALANCE
  })

  if (!metrics.length) return <Empty height={height} />

  const valueFormatter = (value, name, { dataKey }) => {
    if (dataKey === 'diffCpu' || dataKey === 'diffMem') {
      return [`${Numeral(value[0]).format('0.[00]')} - ${Numeral(value[1]).format('0.[00]')}%`, undefined]
    }
    return [`${Numeral(value).format('0.[00]')}%`, undefined]
  }

  const calculateUsedMemoryPercentage = (free) => {
    const dbMaxBytes = dbMaxMemory * 1024 * 1024 * 1024
    const used = dbMaxBytes - parseFloat(free)
    return (used / dbMaxBytes) * 100
  }

  const formatted = metrics.map((metric) => {
    return {
      date: metric.date,
      [RDS_CPU]: metric.cpu_avg,
      mem: calculateUsedMemoryPercentage(metric.mem_avg),
      diffMem: [calculateUsedMemoryPercentage(metric.mem_min), calculateUsedMemoryPercentage(metric.mem_max)],
      diffCpu: [metric.cpu_min, metric.cpu_max]
    }
  })

  return (
    <ChartContainer height={height}
      data={formatted}
      span={span}
      tabKey={RDS_CPU}
      formatTab={value => `${Numeral(value).format('0.[00]')}%`}
      formatTooltip={valueFormatter}
      percentageChart
    >
      <Line dataKey={RDS_CPU} name='CPU' type='monotone' stroke={colors('chart', 'redLight')} strokeWidth={1} dot={false} />
      <Area dataKey='diffCpu' name='CPU min - max' type='monotone' fill={colors('chart', 'redWithOpacity')} stroke={colors('chart', 'redWithOpacity')} strokeWidth={0.5} />

      <Line dataKey='mem' name='Used memory' type='monotone' stroke={colors('chart', 'primary')} strokeWidth={1} dot={false} />
      <Area dataKey='diffMem' name='Mem min - max' type='monotone' fill={colors('chart', 'primaryWithOpacity')} stroke={colors('chart', 'primaryWithOpacity')} strokeWidth={0.5} />

      {burstable && <Line dataKey='burstBalance' name='Burst Balance' type='monotone' stroke={colors('chart', 'green')} strokeWidth={1} dot={false} />}
    </ChartContainer>
  )
}

export default RDSCpuMemoryChart
