import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { organizationsPaths as paths, USERS_LIST } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const putUser = async (organization, userId, payload) => {
  const data = await apiFetch(paths.user(organization, userId), { method: 'PUT', body: JSON.stringify(payload) })
  return data
}

export const useUpdateUserQuery = () => {
  const queryClient = useQueryClient()
  const { organization } = useOrganization()

  return useMutation(
    ({ userId, payload }) => putUser(organization.id, userId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...USERS_LIST, organization.id])
      }
    }
  )
}
