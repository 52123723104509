import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import dagre from 'dagre'
import FlowChart from './chart'
import { Label } from './node'

export * from './utils'

const hasDimensions = (graph) => {
  const nodes = graph.nodes()
  if (!nodes.length) {
    return true
  }
  return !!graph.node(nodes[0]).width
}
const hasLayout = (graph) => {
  const nodes = graph.nodes()
  if (!nodes.length) {
    return true
  }
  return !!graph.node(nodes[0]).x
}

const FlowChartWrapper = ({ graph: inputGraph, handleNodeClick, selected }) => {
  const mainRef = useRef<HTMLInputElement>(null)
  const [graph, setGraph] = useState(inputGraph)
  const [isRendering, setIsRendering] = useState(true)

  useEffect(() => {
    if (!inputGraph) return
    setGraph(inputGraph)
  }, [inputGraph])

  useLayoutEffect(() => {
    const nodes = graph.nodes()
    if (mainRef?.current?.children && (!hasDimensions(graph) || isRendering)) {
      [...mainRef.current.children].forEach((el, idx) => {
        const node = graph.node(nodes[idx])
        if (node) {
          const rects = el.getBoundingClientRect()
          node.width = rects.width
          node.height = rects.height
        } else {
          console.warn('missing node in graph with index:', idx, el)
        }
      })

      dagre.layout(graph)
    }

    setIsRendering(!hasLayout(graph))
  }, [isRendering, graph])

  return (
    <>
      {
        isRendering ?
          <div ref={mainRef}>
            {graph.nodes().map((nodeName) => {
              const node = graph.node(nodeName)
              if (node) {
                return <Label key={nodeName} label={node.label} shape={node.shape} className={node.className} />
              } else {
                console.warn('missing node in graph:', nodeName)
                // node that wasn't created but has edges to it
                const label = `?${nodeName}?`
                graph.setNode(nodeName, { label })
                return <Label key={nodeName} label={node.label} shape={node.shape} />
              }
            })}
          </div>
          :
          <>
            <FlowChart
              graph={graph}
              handleNodeClick={handleNodeClick}
              selected={selected}
            />
          </>
      }
    </>
  )
}

export default FlowChartWrapper
