import React from 'react'

import Content from 'components/layout/content'
import BillingDetails from './billing-details'
import PaymentMethods from './payment-methods'
import { useGetBillingQuery, useGetCustomerQuery } from 'hooks/api'

import styles from './styles.module.less'

const PaymentContainer = () => {
  const { data: billing, isLoading } = useGetBillingQuery()
  const { data: customer, isLoading: customerLoading } = useGetCustomerQuery({ customerId: billing?.customer.id })

  const Title = () => <h1>Payment for <span className={styles.highlighted}>Dashbird.io</span> organization</h1>

  return (
    <Content item title={<Title />} breadcrumbs={['settings', 'payment methods']} loading={isLoading || customerLoading}>
      <BillingDetails customer={customer} />
      <PaymentMethods customer={customer} />
    </Content>
  )
}

export default PaymentContainer
