import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'

import Section from 'components/layout/content/section'
import InventoryTags from 'containers/inventory/shared/tags'
import Events from 'containers/inventory/shared/events'
import InventoryResourceContainer from '../../../container'
import ELBTargetGroups from './target-groups'
import LoadBalancerMetadata from './metadata'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { REQUESTS } from '../../../metrics-tabs/services/elb-application-load-balancer'
import { useAllResourcesQuery } from 'hooks/api'

import Tabs from 'components/antd/tabs'

const Config = ({ item }) => {
  return (<>
    {item.tags.length && <InventoryTags tags={item.tags} inverse />}
    <LoadBalancerMetadata attributes={item.attributes} inverse />
  </>)
}

const ELBLoadBalancer = ({ item }) => {
  const [targetGroups, setTargetGroups] = useState([])
  const { data: resources } = useAllResourcesQuery()

  useEffect(() => {
    if (!resources) return
    const attributes = objectify(item.attributes)
    const parsedTargetGroups = JSON.parse(attributes.targetGroups)
    if (!parsedTargetGroups || !parsedTargetGroups.length) return

    // Set load balancer ids for links
    setTargetGroups(parsedTargetGroups.map(targetGroup => {
      return {
        ...targetGroup,
        id: Object.values(resources).find(resource => resource.externalId === targetGroup.targetGroupArn)?.id
      }
    }))
  }, [item, resources && Object.keys(resources).length])

  return (
    <>
      <InventoryResourceContainer item={item} metadata={{ targetGroups }} service={INVENTORY_SERVICES.ELBApplicationLoadBalancer.id} initialChart={REQUESTS}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="targetGroups" items={[
                { key: 'targetGroups', label: 'Target Groups', children: <ELBTargetGroups targetGroups={targetGroups} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ELBLoadBalancer
