import React from 'react'
import { Item } from 'components/items'
import Empty from 'components/layout/content/empty'
import { useDelegationsQuery } from 'hooks/api'

import { useAccountRoutes } from 'containers/routes'
import { findInventoryByType } from 'lib/resources/constants'

const Resources = ({ resources }) => {
  const routes = useAccountRoutes()
  const { data: delegations } = useDelegationsQuery()

  const resourceItems = resources?.map(resource => ({
    ...resource,
    url: routes.inventory.resource.url({ resourceId: resource?.id }), // open drawer?
    service: findInventoryByType(resource?.type)?.service,
    delegation: delegations?.find(item => item.id === resource.delegation)?.name
  }))

  if (!resources?.length) return <Empty title="No resources in this group" height='100%'>Edit group and add resources if you want to see metrics</Empty>

  return resourceItems.map((resource, index) => <Item.Resource key={index} {...resource} />)
}

export default Resources
