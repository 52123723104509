import React from 'react'
import { Row, Col, Button } from 'antd'

import TierFeatures from './tier-features'
import Tag from '../status/tag'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const openMailTo = name => {
  const email = 'sales@dashbird.io'
  const subject = 'Dashbird Enterprise Subscription'
  const body = `My organization ${name} is interested in the Enterprise plan`
  const newWindow = window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const InfoButton = ({ show, name } = {}) => {
  if (!show) return null
  return (
    <Row type='flex' justify='center' gutter={48} className={styles.topMargin}>
      <Col span={10}>
        <Button type='primary' block onClick={() => openMailTo(name)}>I am interested</Button>
      </Col>
    </Row>
  )
}

const Pricing = () => {
  return (
    <Row type='flex' justify='center' gutter={48} style={{ marginTop: 10 }}>
      <Col>
        <Tag value='CUSTOM PRICING' />
      </Col>
    </Row>
  )
}

const EnterpriseTierInfo = ({ selected } = {}) => {
  const { organization } = useOrganization()

  return (
    <>
      <TierFeatures tier='enterprise' />
      <Pricing />
      <InfoButton show={!selected} name={organization.name} />
    </>
  )
}

export default EnterpriseTierInfo
