import React from 'react'
import { first } from 'lodash'

import IconButton from 'components/buttons/icon'
import { AwsIcon } from 'components/icons'

const URI = 'https://console.aws.amazon.com/xray/home#/traces'

const links = {
  trace (trace) {
    return {
      name: 'View trace in X-Ray',
      uri: `${URI}/${trace.id}?timeRange=PTH1`
    }
  },

  search (resource) {
    return {
      name: 'Search for traces in X-Ray',
      uri: `${URI}?filter=service("${resource.service}")&timeRange=PT1H`
    }
  }
}

const XRayLink = ({ loading, resource, traces }) => {
  if (loading || !traces) return null

  const trace = first(traces)
  const link = trace ? links.trace(trace) : links.search(resource)
  return (
    <a href={link.uri} target='_blank' rel='noopener noreferrer'>
      <IconButton icon={<AwsIcon />} title={link.name} />
    </a>
  )
}

export default XRayLink
