import { useQuery } from 'react-query'
import { isEqual, sumBy, meanBy, some } from 'lodash'
import memoizeOne from 'memoize-one'
import sha1 from 'sha1'

import { getResourceName, getDelegationName, hasMoreDelegations } from 'lib/resources/transformations'
import { apiFetch } from 'lib/action-helpers'
import { resourcePaths as paths, RESOURCES_LIST } from './index'
import { useResourcesMetricsQuery } from '../metrics/index'
import { useDelegationsQuery } from '../delegations/index'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { useOrganization } from 'hooks/context/organization-context'

const lambdaNamespace = 'dashbird/lambda'
const appsyncRequestsNamespace = 'dashbird/appsync/requests'
const appsyncResolversNamespace = 'dashbird/appsync/resolvers'
const supportedMetrics = {
  [INVENTORY_SERVICES.Lambda.type]: lambdaNamespace,
  [INVENTORY_SERVICES.AppSyncGraphQLApi.type]: appsyncRequestsNamespace,
  [INVENTORY_SERVICES.AppSyncResolver.type]: appsyncResolversNamespace
}

const dataFinder = (metric, namespace) => data => data?.metric === metric && data?.namespace === namespace

const buildAppSyncRequestsMetrics = (resource, namespace, metrics) => {
  const { arn } = resource

  const resourceId = sha1(arn)

  const errorData = metrics.find(dataFinder('errors', namespace))
  const requestsData = metrics.find(dataFinder('requests', namespace))
  const durationData = metrics.find(dataFinder('duration', namespace))

  const errors = sumBy(errorData?.datapoints?.[resourceId], 'sum') || 0
  const requests = sumBy(requestsData?.datapoints?.[resourceId], 'sum') || 0
  const duration = (meanBy(durationData?.datapoints?.[resourceId], 'avg') || 0) / 1000000

  return {
    errors,
    requests,
    duration
  }
}

const buildAppSyncResolversMetrics = (resource, namespace, metrics) => {
  const { id: resourceId } = resource

  const errorData = metrics.find(dataFinder('errors', namespace))
  const requestsData = metrics.find(dataFinder('requests', namespace))
  const durationData = metrics.find(dataFinder('duration', namespace))

  const errors = sumBy(errorData?.datapoints?.[resourceId], 'sum') || 0
  const requests = sumBy(requestsData?.datapoints?.[resourceId], 'sum') || 0
  const duration = (meanBy(durationData?.datapoints?.[resourceId], 'avg') || 0) / 1000000

  return {
    errors,
    requests,
    duration
  }
}

const buildLambdaMetrics = (resource, namespace, metrics) => {
  const { id: resourceId } = resource

  const errorData = metrics.find(dataFinder('errors', namespace))
  const invocationsData = metrics.find(dataFinder('invocations', namespace))
  const durationData = metrics.find(dataFinder('duration', namespace))

  const errors = sumBy(errorData?.datapoints?.[resourceId], 'sum') || 0
  const invocations = sumBy(invocationsData?.datapoints?.[resourceId], 'sum') || 0
  const duration = meanBy(durationData?.datapoints?.[resourceId], 'avg') || 0

  return {
    errors,
    invocations,
    duration
  }
}

const metricBuilders = {
  [INVENTORY_SERVICES.Lambda.type]: buildLambdaMetrics,
  [INVENTORY_SERVICES.AppSyncGraphQLApi.type]: buildAppSyncRequestsMetrics,
  [INVENTORY_SERVICES.AppSyncResolver.type]: buildAppSyncResolversMetrics
}

const buildLastDayMetrics = (resource, metrics) => {
  const namespace = supportedMetrics[resource.type]
  if (!namespace || !metrics) return {}

  const metricBuilder = metricBuilders[resource.type]
  if (!metricBuilder) return {}

  return {
    ...metricBuilder(resource, namespace, metrics)
  }
}

const transform = (resources, delegations, metrics) => {
  if (!resources) return undefined
  const moreDelegations = hasMoreDelegations(Object.values(resources))
  return Object.keys(resources)?.reduce((acc, resourceId) => {
    const resource = resources[resourceId]
    acc[resourceId] = {
      ...resource,
      title: getResourceName(resource),
      ...(moreDelegations ? { delegationName: getDelegationName(delegations, resource) } : []),
      ...buildLastDayMetrics(resource, metrics)
    }

    return acc
  }, {})
}

const memoizedResponse = memoizeOne(transform, isEqual)
const memoizedResponseWithMetrics = memoizeOne(transform, isEqual)

const getAllResources = async (account) => {
  const limit = 10000
  const allResources = []

  let page = 0
  let next = true
  while (next) {
    const data = await apiFetch(`${paths.all(account)}?limit=${limit}&page=${page++}`)
    next = data?.data?.length === limit
    allResources.push(...data?.data)
  }

  return allResources?.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})
}

export const useAllResourcesQuery = () => {
  const { account } = useOrganization()

  const { data: delegationsData } = useDelegationsQuery()
  const metricsData = useResourcesMetricsQuery()

  const queryOpts = useQuery(
    [...RESOURCES_LIST, account?.id],
    () => getAllResources(account?.id, {}),
    {
      staleTime: 30 * 60 * 1000,
      enabled: !!account?.id,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked'
    }
  )

  const loadingMetrics = some(metricsData?.map(item => item.isLoading))
  const metrics = loadingMetrics ? [] : metricsData?.map(item => item.data)

  const data = loadingMetrics ? memoizedResponse(queryOpts?.data, delegationsData, []) : memoizedResponseWithMetrics(queryOpts?.data, delegationsData, metrics)

  return {
    ...queryOpts,
    data
  }
}
