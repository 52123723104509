import { useQuery } from 'react-query'
import queryString from 'query-string'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { PROMOTION, billingPaths as paths } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getPromotion = async (organization, payload) => {
  const query = queryString.stringify(omitEmpty(payload))

  const data = await apiFetch(paths.filter(paths.discountPromotion(organization), query))
  return data
}

export const useGetPromotionQuery = ({ payload, options }: {
  payload?: any;
  options?: any;
}) => {
  const { organization } = useOrganization()

  return useQuery(
    [...PROMOTION, payload],
    () => getPromotion(organization?.id, payload),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!payload?.promotionCode,
      retry: false,
      ...options
    }
  )
}
