import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { sumBy } from 'lodash'
import { Bar } from 'recharts'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faSigma } from '@fortawesome/pro-light-svg-icons/faSigma'

import Section from 'components/layout/content/section'
import Metrics from 'components/metrics'
import ChartContainer from 'components/charts/container'
import colors from 'lib/colors'
import Loading from 'components/layout/content/loading'
import { chartHeight } from 'components/charts/utils'

import { useErrorMetricsQuery } from 'hooks/api'
import { ErrorMetricsContext } from '../index'

const ErrorsDashboardTotals = () => {
  const { dashboardStart, dashboardEnd } = useContext(ErrorMetricsContext)
  const [selectedChart, setSelectedChart] = useState('total')

  const tooltipValue = useSelector(state => state.metric.active)

  const { data, isLoading } = useErrorMetricsQuery({ key: 'total-errors-warnings' }, dashboardStart, dashboardEnd)

  const mapData = (data) => {
    return {
      ...data,
      key: 'total',
      data: data?.datapoints?.CRITICAL?.map((datapoint, index) => ({
        date: datapoint?.date.valueOf(),
        warnings: data?.datapoints?.WARNING ? data?.datapoints?.WARNING[index]?.sum : 0,
        errors: datapoint?.sum
      })),
      totalSum: sumBy(data?.datapoints?.CRITICAL, 'sum') + sumBy(data?.datapoints?.WARNING, 'sum'),
      warningSum: sumBy(data?.datapoints?.WARNING, 'sum'),
      errorSum: sumBy(data?.datapoints?.CRITICAL, 'sum')
    }
  }

  const getTabValue = (key) => {
    const baseValue = numeral(mapData(data)?.[key]).format('0a')
    if (!tooltipValue) return baseValue
    if (selectedChart === key) return tooltipValue
    return baseValue
  }

  const tabs = [{
    title: 'Total',
    key: 'total',
    icon: faSigma,
    value: numeral(mapData(data)?.totalSum).format('0.0a'),
    selected: selectedChart === 'total',
    onClick: () => setSelectedChart('total')
  }, {
    title: 'Errors',
    key: 'errors',
    icon: faExclamationTriangle,
    value: getTabValue('errorSum'),
    selected: selectedChart === 'errors',
    onClick: () => setSelectedChart('errors')
  }, {
    title: 'Warnings',
    key: 'warnings',
    icon: faInfoCircle,
    value: getTabValue('warningSum'),
    selected: selectedChart === 'warnings',
    onClick: () => setSelectedChart('warnings')
  }]

  return (
    <>
      <Metrics items={tabs} />
      <Section cancelMargin solid>
        {isLoading || !mapData(data)
          ? <Loading height={chartHeight} />
          : (
            <ChartContainer
              data={mapData(data)?.data}
              formatTab={value => numeral(value).format('0.[0]a')}
              yTicksFormatting='0.[0]a'
              tabKey={selectedChart}
              height={chartHeight}
            >
              {selectedChart !== 'warnings' && <Bar dataKey='errors' fill={colors('chart', 'red')} barSize={4} name={'Errors'} />}
              {selectedChart !== 'errors' && <Bar dataKey='warnings' fill={colors('chart', 'yellow')} barSize={4} name={'Warnings'} />}
            </ChartContainer>)}
      </Section>
    </>
  )
}

export default ErrorsDashboardTotals
