
export const METRICS = 'metrics'
export const METRICS_ERROR_LIST = [METRICS, 'errors', 'list']
export const METRICS_CHART = [METRICS, 'chart']
export const METRICS_TOTAL = [METRICS, 'total']

export * from './get-total-metrics'
export * from './get-error-metrics'
export * from './get-chart-metrics'
export * from './get-resources-metrics'

export const metricsPaths = {
  account: (identity) => `/v1/accounts/${identity}`,
  metrics: (account) => `${metricsPaths.account(account)}/metrics`
  // metrics: (account) => `${metricsPaths.account(account)}/metrics`,
  // db: (account) => `${metricsPaths.metrics(account)}/db`,
  // aws: (account) => `${metricsPaths.metrics(account)}/aws`,
  // measurements: (account) => `${metricsPaths.account(account)}/measurements`
}
