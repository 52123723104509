import React from 'react'
import classnames from 'classnames'
import { Tooltip } from 'antd'

import { FilterIcon, ChevronLeftSolidIcon, ChevronRightSolidIcon } from 'components/icons'

import styles from './styles.module.less'

const ToggleListBtn = ({ toggle, listFixed, showFilter }) => {
  return (
    <div className={styles.sidebar_buffer}>
      <Tooltip title={listFixed ? 'Collapse sidebar' : 'Expand sidebar'} placement='right' overlayClassName={styles.tooltip}>
        <button
          onClick={() => toggle(!listFixed)}
          className={classnames(
            {
              db_sidebar_btn_fixed: listFixed,
              db_sidebar_btn: true,
              [styles.filter]: showFilter
            })}>
          {showFilter
            ? <FilterIcon className={styles.filter_icon} />
            : listFixed ? <ChevronLeftSolidIcon /> : <ChevronRightSolidIcon />}
        </button>
      </Tooltip>
    </div>
  )
}

export default ToggleListBtn
