import React from 'react'
import Numeral from 'numeral'

import { Bar } from 'recharts'

import transform from '../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { DYNAMOBD_ERRORS } from 'containers/inventory/details/resource/metrics-tabs/services/dynamodb'

const ErrorsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [DYNAMOBD_ERRORS]: data.DYNAMODB_ERRORS_SYSTEM,
    user: data.DYNAMODB_ERRORS_USER
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[000]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0'
      span={span}
      tabKey={DYNAMOBD_ERRORS}
      formatTab={value => `${Numeral(value).format('0.[000] a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={DYNAMOBD_ERRORS} stackId='a' fill={colors('chart', 'primary')} name='System errors' />
      <Bar dataKey='user' stackId='a' fill={colors('chart', 'red')} name='User errors' />
    </ChartContainer>
  )
}

export default ErrorsChart
