import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useAllResourcesQuery, useGetQueryQuery } from 'hooks/api'

import Section from 'components/layout/content/section'
import { Item } from 'components/items'
import Empty from 'components/layout/content/empty'
import { SpinnerIcon } from 'components/icons/font-awesome'

import styles from './styles.module.less'

const TargetResources = ({ data, loadingExecutions }) => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('selected', [])
  const [targetResources, setTargetResources] = useState([])
  const { data: resources, isLoading: loadingResources } = useAllResourcesQuery()
  const { data: currentQuery, isLoading: loadingQuery } = useGetQueryQuery()

  const getItems = (targets, executions, loading) => {
    return Object.keys(resources).filter(item => targets?.includes(item)).map(resourceId => ({
      ...resources?.[resourceId],
      count: loadingExecutions || currentQuery?.service !== 'lambda' ? null : executions?.filter(item => item.resource === resourceId)?.length,
      extra: loading ? <SpinnerIcon className={styles.spinner} /> : null
    }))
  }

  const getTargetResources = () => {
    if (!resources || !currentQuery) return

    // maps from all executions
    if (isEmpty(currentQuery?.targets)) {
      const executionTargets = data?.map(item => item.resource)
      return setTargetResources(getItems(executionTargets, data))
    }
    return setTargetResources(getItems(currentQuery?.targets.filter(item => !item.includes('group')), data, loadingExecutions))
  }

  useEffect(getTargetResources, [data, resources, currentQuery?.id])

  return (
    <Section title='Filter by resources' loading={loadingQuery || loadingResources || (isEmpty(currentQuery?.targets) && loadingExecutions)} titleUpperCase>
      {isEmpty(targetResources) && <Empty>No single resources found as query targets</Empty>}
      {targetResources?.sort((a, b) => b.count - a.count)?.map(item => {
        return (
          <Item.Resource
            {...item}
            key={item.id}
            total={data?.length}
            onSelect={(item) => setSelected(filterHelper(item, selected))}
            selected={selected}
          />
        )
      })}
    </Section>
  )
}

export default TargetResources
