import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { channelsPaths as paths, NOTIFICATION_CHANNEL_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getChannels = async (account) => {
  const data = await apiFetch(paths.channels(account))
  return data?.data
}

export const useNotificationChannelsQuery = () => {
  const { account } = useOrganization()

  return useQuery(
    [...NOTIFICATION_CHANNEL_LIST, account?.id],
    () => getChannels(account?.id),
    {
      staleTime: Infinity,
      enabled: !!account?.id
    }
  )
}
