import React from 'react'
import { TIERS, TIER_CONFIG } from '../tier-config'
import { formatInvocations } from 'lib/billing-helpers'
import { Typography } from 'components/typography'
import styles from '../styles.module.less'

const CheckoutSummary = ({ summary, tier, billingInterval }) => {
  return (
    <div className={styles.checkout_info}>
      <div>
        <Typography.Title level={4} className={styles.label}>Selected tier: </Typography.Title>
        <p className={styles.tier}>{TIER_CONFIG[tier]?.title} {tier === TIERS.free && ' - free'}</p>
      </div>
      <div>
        <Typography.Title level={4} className={styles.label}>Limits: </Typography.Title>
        {summary?.limits?.lambdaInvocations && <p className={styles.text}>{formatInvocations(summary.limits.lambdaInvocations)} invocations/mo</p>}
        {summary?.limits?.awsAccounts && <p className={styles.text}>{summary.limits.awsAccounts} AWS accounts</p>}
      </div>
      <div>
        <Typography.Title level={4} className={styles.label}>Billing cycle: </Typography.Title>
        {tier === TIERS.free
          ? <p className={styles.text}>No billing</p>
          : <p className={styles.text}>{billingInterval}ly</p>}
      </div>
    </div>
  )
}

export default CheckoutSummary
