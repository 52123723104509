import React, { useEffect, useState } from 'react'
import { Card, Skeleton, List } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { get, last } from 'lodash'
import moment from 'moment'

import PaymentIndicator from 'components/billing/stripe/invoice/indicator'
import BillingPeriod from 'components/billing/stripe/period'
import Content from 'components/layout/content'
import LoadButton from './load-more'
import DownloadButton from 'components/buttons/download'
import ExternalLink from 'components/buttons/external-link'
import { GET_INVOICES } from 'actions/billing.actions'
import { API } from 'actions/api'
import { formatStripePrice } from 'lib/billing-helpers'
import { useOrganization } from 'hooks/context/organization-context'

const useFetchInvoices = filters => {
  const dispatch = useDispatch()

  const [count, setCount] = useState(0)
  const [hasMore, setHasMore] = useState(true)

  const { organization } = useOrganization()
  const invoices = useSelector(state => get(state, 'billing.invoices.list'))
  const loading = useSelector(state => state.waiting.list[GET_INVOICES])

  const fetch = (filters) => dispatch(API.organizations.id(organization.id).billing.invoices.get(filters))

  useEffect(() => {
    if (!organization.id) return
    fetch(filters)
  }, [organization.id])

  useEffect(() => {
    if (invoices.length === count) {
      setHasMore(false)
    } else {
      setCount(invoices.length)
      setHasMore(true)
    }
  }, [invoices])

  return { invoices, loading, fetch, hasMore }
}

const Invoice = ({ item, loading }) => {
  return (
    <Skeleton avatar title={false} loading={loading} active>
      <List.Item.Meta
        avatar={<PaymentIndicator isPaid={item.isPaid} />}
        title={<a href={item.invoiceUrl}>#{item.number}</a>}
        description={<BillingPeriod start={moment.unix(item.start)} end={moment.unix(item.end)} />}
      />
      <b>{formatStripePrice(item.total, item.currency)}</b>
    </Skeleton>
  )
}

const Invoices = ({ className }) => {
  const { invoices, loading, fetch: fetchMore, hasMore } = useFetchInvoices()

  return (
    <Content item breadcrumbs={['settings', 'invoices']}>
      <Card
        className={className}
        title='Invoices'
      >
        <List
          loading={loading}
          itemLayout='horizontal'
          loadMore={hasMore ? <LoadButton next={last(invoices)} fetch={fetchMore} /> : ''}
          dataSource={invoices}
          renderItem={invoice => (
            <List.Item
              actions={[
                <DownloadButton key='download-invoice' title='Download PDF' link={invoice.invoiceUrl} />,
                <ExternalLink key='invoice-stripe' title='Go to Stripe' link={invoice.hostedInvoiceUrl} />
              ]}
            >
              <Invoice item={invoice} />
            </List.Item>
          )}
        />
      </Card>
    </Content>
  )
}

export default Invoices
