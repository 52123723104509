import React, { useContext, useEffect, useState } from 'react'
import { INVENTORY_SERVICES } from 'lib/resources/constants'

import Invocations from 'features/invocations'
import { durationMarkToTime } from 'features/invocations/filter/slider'
import { flatten, isEmpty, isEqual } from 'lodash'
import { ServiceContext } from '../../index'
import { useHistory, useLocation } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'
import { useSearchExecutionsQuery } from 'hooks/api'
import { SearchContext } from 'hooks/context/search-context'

const INITIAL_MEMORY = [0, 100]
const INITIAL_DURATION = [0, 3000]

const buildMemoryPayload = (memoryFilter, lambda) => ({ min: parseInt(lambda?.attributes?.find(item => item?.name === 'memorySize')?.value) * (memoryFilter[0] / 100), max: parseInt(lambda?.attributes?.find(item => item?.name === 'memorySize')?.value) * (memoryFilter[1] / 100) })
const buildDurationPayload = (durationFilter) => ({ min: durationMarkToTime(durationFilter[0]), max: durationMarkToTime(durationFilter[1]) })

const LambdaInvocations = ({ resource }) => {
  const history = useHistory()
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const { start, end } = useContext(ServiceContext)

  const { editorState, setEditorState, queryRef } = useContext(SearchContext)

  const [flag, setFlag] = useState(null)
  const [durationFilter, setDurationFilter] = useState(INITIAL_DURATION)
  const [memoryFilter, setMemoryFilter] = useState(INITIAL_MEMORY)
  const [filters, setFilters] = useState({})

  const { data, isLoading, isFetching } = useSearchExecutionsQuery(
    {
      start,
      end,
      ...filters,
      limit: 100,
      targets: [resource.id],
      service: INVENTORY_SERVICES.Lambda.service
    }
  )
  const invocations = flatten(data?.pages?.map(page => page.data))

  const buildFilters = () => {
    if (isEmpty(queryRef.current) && isEqual(memoryFilter, INITIAL_MEMORY) && isEqual(durationFilter, INITIAL_DURATION) && !flag) return {}
    const finalQuery = isEmpty(queryRef.current) ? {} : { query: queryRef?.current }
    const memory = isEqual(memoryFilter, INITIAL_MEMORY) ? {} : { memory: buildMemoryPayload(memoryFilter, resource) }
    const duration = isEqual(durationFilter, INITIAL_DURATION) ? {} : { duration: buildDurationPayload(durationFilter) }
    const finalFlag = flag ? { flag } : {}

    return {
      filters: {
        ...finalQuery,
        ...memory,
        ...duration,
        ...finalFlag
      }
    }
  }
  const handleSearch = () => {
    setFilters(buildFilters)
  }

  const selectInvocation = invocation => history.push({ pathname: routes.inventory.invocation.url({ resourceId: resource.id, invocationHash: invocation.eventId }), search })
  const closeInvocation = () => history.push({ pathname: routes.inventory.resource.url({ resourceId: resource.id }), search })
  useEffect(() => {
    setFilters(buildFilters())
  }, [memoryFilter, durationFilter, flag])

  return (
    <Invocations
      loading={isLoading || isFetching}
      invocations={invocations}
      service={INVENTORY_SERVICES.Lambda.id}
      resource={resource}
      filter={flag}
      setFilter={value => setFlag(value)}
      durationFilter={durationFilter}
      setDurationFilter={setDurationFilter}
      memoryFilter={memoryFilter}
      setMemoryFilter={setMemoryFilter}
      onSelect={selectInvocation}
      onClose={closeInvocation}
      editorState={editorState}
      setEditorState={setEditorState}
      onSearch={handleSearch}
      url={invocation => ({ pathname: routes.inventory.invocation.url({ resourceId: resource.id, invocationHash: invocation.eventId }), search })}
    />
  )
}

export default LambdaInvocations
