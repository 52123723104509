import React from 'react'
import { Icon as TypeIcon } from './type'

export { Icon as TypeIcon } from './type'
export { Icon as AwsServiceIcon } from './aws-service'
export { IconWrapper } from './icon-wrapper'
export { IconWithText } from './icon-with-text'
export * from './font-awesome'
export * from './notification-channel'
export { BurgerMenuIcon } from './burger-menu'
export { DashbirdIcon } from './dashbird-icon'

export const AlarmIcon = (props) => (<TypeIcon {...props} type='alarm' />)
export const InsightIcon = (props) => (<TypeIcon {...props} type='insight' />)
export const ErrorIcon = (props) => (<TypeIcon {...props} type='error' />)
