import React from 'react'
import Numeral from 'numeral'

import { Line } from 'recharts'

import transform from '../../transform'
import colors from 'lib/colors'

import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { KDA_KPUS } from 'containers/inventory/details/resource/metrics-tabs/services/kinesis-analytics'

const KinesisAnalyticsKPUs = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [KDA_KPUS]: data[KDA_KPUS]
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value) => {
    return [Numeral(value).format('0'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      span={span}
      tabKey={KDA_KPUS}
      formatTab={value => Numeral(value).format('0')}
      formatTooltip={tooltipFormatter}
    >
      <Line type='monotone' dataKey={KDA_KPUS} stroke={colors('chart', 'primary')} strokeWidth={1} name='KPUs' dot={false} />
    </ChartContainer>
  )
}

export default KinesisAnalyticsKPUs
