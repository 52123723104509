import React, { useState, useRef, createContext, useCallback, useContext } from 'react'
import { DrawerContext } from 'components/layout/drawer'

export const TraceContext = createContext<{
  tooltipData: any,
  setTooltipData: (value: any) => void,
  tooltipRef: any,
  handleSegmentClick: any
}>({
  tooltipData: {},
  setTooltipData: () => { },
  tooltipRef: {},
  handleSegmentClick: () => { }
})

export const TraceContextProvider = ({ children }) => {
  const { setExpanded } = useContext(DrawerContext)
  const [tooltipData, setTooltipData] = useState({})
  const tooltipRef = useRef({ fixedId: undefined })

  const handleSegmentClick = useCallback((node) => {
    if (!node?.id) return
    const isCurrentNode = node?.id !== tooltipRef?.current?.fixedId
    tooltipRef.current = { fixedId: isCurrentNode ? node.id : undefined }
    setExpanded(true)
    setTooltipData(isCurrentNode ? node : false)
  }, [])

  return (
    <TraceContext.Provider
      value={{
        tooltipData,
        tooltipRef,
        setTooltipData,
        handleSegmentClick
      }}>
      {children}
    </TraceContext.Provider>
  )
}
