import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { Layout } from 'antd'

import Content from 'components/layout/content'
import Empty from 'components/layout/content/empty'
import ContentError from 'components/layout/content/error'
import Section from 'components/layout/content/section'
import Metrics from './metrics'
import Retries from './retries'
import Logs from 'features/log'
import Trace from 'features/invocations/details/x-ray'
import XRayLink from 'features/invocations/details/x-ray/link'
import CloudWatchLink from './cloudwatch-link'
import TracesExplorer from '../x-ray/explorer'

import { objectify } from 'lib/utils'
import { useInvocationQuery, useInvocationLogsQuery, useInvocationTracesQuery, useInvocationRetriesQuery, useAllResourcesQuery } from 'hooks/api'
import IconButton from 'components/buttons/icon'
import { CloseIcon, AwsServiceIcon } from 'components/icons'
import { findInventoryById, INVENTORY_SERVICES } from 'lib/resources/constants'
import { Item } from 'components/items/index'
import { LinkIcon } from 'components/icons/font-awesome'
import { useAccountRoutes } from 'containers/routes'
import styles from './styles.module.less'

const Title = ({ invocation }) => {
  if (!invocation) return null
  return `Request: ${invocation.requestId}`
}

const EmptyInvocation = ({ onClose }) => {
  return (
    <div className={styles.empty_wrapper}>
      <IconButton icon={<CloseIcon />} title='Close' onClick={onClose} className={styles.close_icon} />
      <Empty title='No invocation to show.' />
    </div>
  )
}

const renderError = error => {
  if (!error) return null
  return <ContentError title='Invocation was not found' />
}

const checkTracing = (resource = {}) => {
  const attributes = objectify(resource.attributes)
  return attributes.tracingConfig === 'Active'
}

const Details = ({ resource, hash, onClose, error, className, type }) => {
  const routes = useAccountRoutes()
  const history = useHistory()
  const { data: resources } = useAllResourcesQuery()
  const { data: invocation, isLoading: loadingInvocation } = useInvocationQuery(hash)

  const invocationResource = resource || (resources ? resources[invocation?.resource] : null)
  const isTracingActive = checkTracing(resource)

  const { data: logs, isLoading: loadingLogs } = useInvocationLogsQuery(hash)
  const { data: tracesData, isLoading: loadingTraces } = useInvocationTracesQuery(hash, invocationResource?.id, isTracingActive)
  const { data: retries, isLoading: loadingRetries } = useInvocationRetriesQuery(hash, invocationResource?.id, isTracingActive)

  const traces = tracesData?.traces
  const delegationName = resource?.delegationName

  return (
    <Layout style={{ background: 'white' }}>
      <Content
        breadcrumbs={[delegationName, invocationResource?.region]}
        loading={loadingInvocation}
        item={invocation}
        title={<Title invocation={invocation} />}
        renderEmpty={<EmptyInvocation onClose={onClose} />}
        error={renderError(error)}
        actions={onClose && <IconButton icon={<CloseIcon />} title='Close' onClick={onClose} />}
        className={classnames(styles.invocation, className)}
        onDrawerClose={onClose}
        drawer
        icon={<AwsServiceIcon service={findInventoryById(type)?.service} />}
      >
        <Metrics item={invocation} drawer fixed tabNarrow />
        <Section title='Resource' loading={loadingInvocation} titleUpperCase>
          {invocationResource && <Item.Filter
            description={invocationResource?.title}
            hoverable={false}
            title={`${delegationName ? `${delegationName} |` : ''} ${invocationResource?.region}`}
            icon={<AwsServiceIcon service={INVENTORY_SERVICES.Lambda.service} />}
            extra={<IconButton
              className={styles.icon}
              icon={<LinkIcon />}
              titlePlacement='topRight'
              title='Go to resource page'
              onClick={() => history.push(routes.inventory.resource.url({ resourceId: invocationResource?.id }))} />}
          />}
        </Section>
        <Section
          title='Trace' loading={loadingTraces}
          actions={isTracingActive && <XRayLink loading={loadingTraces} traces={traces} resource={invocationResource} />}
          titleUpperCase
        >
          <Trace loading={loadingTraces} traces={traces} enabled={isTracingActive} segmentId={invocation?.xraySegmentId} />
        </Section>
        <Section title='Retries' loading={loadingRetries} titleUpperCase>
          <Retries resourceId={invocationResource?.id} item={invocation} retries={retries} />
        </Section>
        <Logs
          title='Logs'
          loading={loadingLogs}
          logs={logs}
          errorId={invocation?.errorMessage?.id || invocation?.errorMessage?.eventId}
          extraAction={<CloudWatchLink invocation={invocation} resource={invocationResource} />}
          simplify
          drawer
          titleUpperCase
        />
      </Content>
      <TracesExplorer />
    </Layout>
  )
}

export default Details
