import React from 'react'
import { countBy } from 'lodash'

import Section from 'components/layout/content/section'
import { Delegation } from 'components/items/progress-item'
import useQueryParams from 'lib/hooks/use-query-params'
import { filterHelper } from 'features/side-list/filter/filter-by-query-params'
import { useErrorsQuery, useAllResourcesQuery, useDelegationsQuery } from 'hooks/api'

const DelegationsList = () => {
  const { getValue: selected, setValue: setSelected } = useQueryParams('delegation', [])

  const { data: errors, status } = useErrorsQuery()
  const { status: resourcesStatus } = useAllResourcesQuery()
  const { data: delegations } = useDelegationsQuery()

  const countByDelegation = countBy(errors, 'resource.delegation')

  const items = delegations?.map(delegation => {
    if (!countByDelegation[delegation?.id]) return null

    return {
      title: delegation?.provider?.account,
      description: delegation?.name,
      count: countByDelegation[delegation?.id],
      total: errors?.length,
      id: delegation?.id
    }
  })?.filter(item => item)

  return (
    <Section title='Delegations' loading={status?.loading || resourcesStatus?.loading || !errors || !delegations} titleUpperCase>
      {items?.length !== 0
        ? items?.sort((a, b) => b.count - a.count).map(item =>
          <Delegation
            key={item.id}
            {...item}
            selected={selected}
            onSelect={delegation => setSelected(filterHelper(delegation, selected))}
          />)
        : <p>No errors found</p>}
    </Section>
  )
}

export default DelegationsList
