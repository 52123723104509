import { useMutation, useQueryClient } from 'react-query'
import { apiFetch } from 'lib/action-helpers'

import { policyPaths as paths, NOTIFICATION_POLICY_LIST } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const createNotificationPolicy = async (account, payload) => {
  const data = await apiFetch(paths.policies(account), { method: 'POST', body: JSON.stringify(payload) })
  return data?.data
}

export const useCreateNotificationPolicyQuery = () => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  return useMutation(
    ({ accountId = account.id, payload }) => createNotificationPolicy(accountId, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...NOTIFICATION_POLICY_LIST, account.id])
      }
    }
  )
}
