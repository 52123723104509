import { SEVERITY_TYPES } from 'lib/event-constants'

const PRIORITIES = {
  [SEVERITY_TYPES.CRITICAL]: 0,
  [SEVERITY_TYPES.WARNING]: 1,
  [SEVERITY_TYPES.INFO]: 2
}

export const sortByStatus = (items) => [].concat(items).sort((a, b) => {
  if (a.disabled) return 1
  if (b.disabled) return -1

  if (a.severity === b.severity) {
    return a.count > b.count ? -1 : 1
  }
  if ((PRIORITIES[a.severity] - PRIORITIES[b.severity]) < 0) {
    if (a.count === b.count) return -1
    return a.count === 0 ? 1 : -1
  }
  if ((PRIORITIES[a.severity] - PRIORITIES[b.severity]) > 0) {
    if (a.count === b.count) return 1
    return b.count === 0 ? -1 : 1
  }
})

export const sortBySeverity = (items) => [].concat(items).sort((a, b) => {
  return PRIORITIES[a.severity] - PRIORITIES[b.severity]
})

export const sortByName = (items) => [].concat(items).sort((a, b) => {
  if (a.mutedAt) return 1
  if (b.mutedAt) return -1
  return a.name.localeCompare(b.name)
})

export const sortByOccurrenceCountLastDay = (items) => [].concat(items).sort((a, b) => {
  if (a.mutedAt) return 1
  if (b.mutedAt) return -1
  return b.countLastDay - a.countLastDay
})

export const sortByOccurrenceCount = (items) => [].concat(items).sort((a, b) => {
  if (a.mutedAt) return 1
  if (b.mutedAt) return -1
  return b.count - a.count
})

export const sortByLastOccurrence = (items) => [].concat(items).sort((a, b) => {
  if (a.mutedAt) return 1
  if (b.mutedAt) return -1
  return (b.lastOccurredAt || b.openedAt || b.updatedAt) - (a.lastOccurredAt || a.openedAt || a.updatedAt)
})
