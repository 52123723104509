import { find, filter, get, first } from 'lodash'
import numeral from 'numeral'
import { formatDuration, formatPercentage, formatBytes, formatCost, formatThousands } from 'components/charts/utils'

const findAttribute = (item, name) => {
  const attrs = get(item, 'attributes', [])
  return first(attrs.filter(attr => attr.name === name))
}

const generateLinkBase = (resource) => `https://${resource?.region}.console.aws.amazon.com`
const generateAwsArnLink = ({ region, arn } = {}) => `https://${region}.console.aws.amazon.com/go/view?arn=${encodeURIComponent(arn)}`

export const INVENTORY_SERVICES = {
  AppSyncFunctionConfiguration: {
    id: 'AppSyncFunction',
    title: 'AppSync Function',
    shortTitle: 'AppSync Function',
    type: 'AWS::AppSync::FunctionConfiguration',
    service: 'appsync',
    namespace: 'aws/appsync',
    icon: 'appsync-icon.svg',
    metrics: [],
    excludeFromList: true // don't show in inventory view list container
  },
  AppSyncGraphQLApi: {
    id: 'AppSyncAPI',
    title: 'AppSync API',
    shortTitle: 'AppSync API',
    type: 'AWS::AppSync::GraphQLApi',
    service: 'appsync',
    namespace: 'aws/appsync',
    awsLink: (resource) => `${generateLinkBase(resource)}/appsync/home?region=${resource?.region}#/${resource?.attributes?.find(item => item?.name === 'apiId')?.value}/v1/home`, // resource api id?
    icon: 'appsync-icon.svg',
    metrics: []
  },
  AppSyncResolver: {
    id: 'AppSyncResolver',
    title: 'AppSync Resolver',
    shortTitle: 'AppSync Resolver',
    type: 'AWS::AppSync::Resolver',
    service: 'appsync',
    namespace: 'aws/appsync',
    icon: 'appsync-icon.svg',
    metrics: [],
    excludeFromList: true // don't show in inventory view list container
  },
  Lambda: {
    id: 'Lambda',
    title: 'Lambda',
    shortTitle: 'Lambda',
    type: 'AWS::Lambda::Function',
    service: 'lambda',
    namespace: ['aws/lambda', 'dashbird/lambda'],
    icon: 'lambda-icon.svg',
    nameFilterEnabled: true,
    search: true,
    awsLink: (resource) => `${generateLinkBase(resource)}/${findInventoryByType(resource?.type)?.service}/home?region=${resource?.region}#/functions/${resource?.name}`,
    metrics: [
      {
        value: 'errors',
        title: 'Fatal errors count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'count_coldstart',
        title: 'Coldstarts count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'count_retry',
        title: 'Retry count'
      },
      {
        value: 'invocations',
        title: 'Invocations count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'throttles',
        title: 'Throttled lambdas',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })

      },
      {
        value: 'duration',
        title: 'Duration (in ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'billed_duration',
        title: 'Billed Duration (in ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'max_memory_used',
        title: 'Max memory used (in MB)',
        unit: 'MB',
        formatData: value => value * 1024 * 1024,
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'percentage_memory_used',
        title: '% of memory used',
        percentageChart: true,
        unit: '%',
        formatData: value => value * 100,
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, unit: '' })
      },
      {
        value: 'cost',
        title: 'cost (in dollars)',
        unit: '$',
        formatTooltip: (value) => formatCost({ value }),
        formatYTicks: (value) => formatCost({ value, unit: '' })
      },
      {
        value: 'invocations.concurrent',
        title: 'Concurrent lambda executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })

      }
    ],
    sortBy: {
      invocations: {
        key: 'highest_throughput',
        name: 'Highest throughput',
        apply: [item => -1 * get(item, 'invocations', 0)]
      },
      errors: {
        key: 'most_errors',
        name: 'Most errors',
        apply: [item => -1 * get(item, 'errors', 0)]
      },
      name: {
        name: 'Name alphabetical',
        apply: [item => item.name]
      },
      duration: {
        key: 'response_time',
        name: 'Slowest response time',
        apply: [item => -1 * get(item, 'duration', 0)]
      },
      time: {
        key: 'time_consumption',
        name: 'Most time consuming',
        apply: [item => -1 * get(item, 'duration', 0) * get(item, 'invocations', 0)]
      }
    }
  },
  S3: {
    id: 'S3',
    title: 'S3',
    shortTitle: 'S3',
    type: 'AWS::S3::Bucket',
    service: 's3',
    namespace: 'aws/s3',
    icon: 's3-icon.svg',
    awsLink: generateAwsArnLink,
    metrics: [
      {
        value: 'bucket.objects',
        title: 'Object count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  SQS: {
    id: 'SQS',
    title: 'SQS',
    shortTitle: 'SQS',
    type: 'AWS::SQS::Queue',
    service: 'sqs',
    namespace: 'aws/sqs',
    icon: 'sqs-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/sqs/v2/home?region=${resource?.region}#/queues/${encodeURIComponent(resource?.externalId)}`,
    metrics: [
      {
        value: 'messages',
        title: 'Messages in queue',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'messages.in',
        title: 'Incoming messages',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'messages.out',
        title: 'Outgoing messages',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'messages.processing',
        title: 'Processing messages',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'messages.empty',
        title: 'Empty messages',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      { value: 'messages.age', title: 'Oldest message age (seconds)', unit: 's', formatTooltip: value => numeral(value).format('00:00:00'), formatYTicks: value => numeral(value).format('00:00:00') },
      {
        value: 'messages.size',
        title: 'Sent message size (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      }
    ]
  },
  SNS: {
    id: 'SNS',
    title: 'SNS',
    shortTitle: 'SNS',
    type: 'AWS::SNS::Topic',
    service: 'sns',
    namespace: 'aws/sns',
    icon: 'sns-icon.svg',
    awsLink: resource => `${generateLinkBase(resource)}/sns/v3/home?region=${resource?.region}#/topic/${resource?.externalId}`,
    metrics: [
      {
        value: 'messages.published',
        title: 'Messages published',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'messages.size',
        title: 'Messages size',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      }, // no unit
      {
        value: 'notifications.delivered',
        title: 'Notifications delivered',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'notifications.failed',
        title: 'Notifications failed',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'notifications.filtered.out',
        title: 'Filtered out notifications',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  DynamoDB: {
    id: 'DynamoDB',
    title: 'DynamoDB',
    shortTitle: 'DynamoDB',
    type: 'AWS::DynamoDB::Table',
    service: 'dynamodb',
    namespace: 'aws/dynamodb',
    icon: 'dynamodb-icon.svg',
    awsLink: resource => `${generateLinkBase(resource)}/dynamodbv2/home?region=${resource?.region}#table?name=${resource?.name}`,
    metrics: [
      { value: 'read', title: 'Consumed read capacity units' },
      { value: 'write', title: 'Consumed write capacity units' },
      { value: 'capacity.read', title: 'Provisioned read capacity units' },
      { value: 'capacity.write', title: 'Provisioned write capacity units' },
      {
        value: 'utilization.read',
        title: 'Max provisioned table read capacity utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'utilization.write',
        title: 'Max provisioned table write capacity utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'throttles',
        title: 'Throttled requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'throttles.read',
        title: 'Throttled read events',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'throttles.write',
        title: 'Throttled write events',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'latency.scan',
        title: 'Successful scan request latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'latency.query',
        title: 'Successful query request latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'latency.item.get',
        title: 'Successful get request latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'latency.item.put',
        title: 'Successful put request latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'latency.item.update',
        title: 'Successful update request latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'errors.system',
        title: 'System errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'errors.user',
        title: 'User errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  APIGatewayHttp: {
    id: 'APIGatewayHttp',
    title: 'HTTP API Gateway',
    shortTitle: 'HTTP API Gateway',
    type: 'AWS::ApiGatewayV2::Stage',
    resourceType: 'AWS::ApiGatewayV2::Api',
    service: 'apigateway.http',
    namespace: 'aws/apigateway',
    icon: 'apigateway-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/apigateway/main/api-detail?api=${resource?.attributes?.find(item => item?.name === 'apiId')?.value}&region=${resource?.region}`,
    metrics: [
      {
        value: 'requests',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'latency',
        title: 'Latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'latency.integration',
        title: 'Integration latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'errors.5xx',
        title: '5XX errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'errors.4xx',
        title: '4XX errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ],
    sortBy: {
      name: {
        name: 'Name alphabetical',
        apply: [item => {
          const attr = findAttribute(item, 'apiName')
          return attr && attr.value
        }]
      }
    }
  },
  APIGatewayRest: {
    id: 'APIGatewayRest',
    title: 'Rest API Gateway',
    shortTitle: 'Rest API Gateway',
    type: 'AWS::ApiGateway::Stage',
    resourceType: 'AWS::ApiGateway::Api',
    service: 'apigateway.rest',
    namespace: 'aws/apigateway',
    icon: 'apigateway-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/apigateway/home?region=${resource?.region}#/apis/${resource?.attributes?.find(item => item?.name === 'apiId')?.value}/resources`,
    metrics: [
      {
        value: 'requests',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'latency',
        title: 'Latency (milliseconds)',
        unit: 'ms',
        formatTooltip: (value) => formatDuration(value),
        formatYTicks: (value) => formatDuration(value)
      },
      {
        value: 'latency.integration',
        title: 'Integration latency (milliseconds)',
        unit: 'ms',
        formatTooltip: (value) => formatDuration(value),
        formatYTicks: (value) => formatDuration(value)
      },
      {
        value: 'errors.5xx',
        title: '5XX errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'errors.4xx',
        title: '4XX errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ],
    sortBy: {
      name: {
        name: 'Name alphabetical',
        apply: [item => {
          const attr = findAttribute(item, 'apiName')
          return attr && attr.value
        }]
      },
      endpoints: {
        key: 'endpoint_count',
        name: 'Endpoint count',
        apply: [item => {
          const attr = findAttribute(item, 'endpoints')
          const endpoints = JSON.parse(get(attr, 'value', '{}'))
          return get(endpoints, 'length', 0) * -1
        }]
      }
    }
  },
  ECSCluster: {
    id: 'ECSCluster',
    title: 'ECS Clusters',
    shortTitle: 'ECS Clusters',
    type: 'AWS::ECS::Cluster',
    service: 'ecs',
    namespace: 'aws/ecs',
    icon: 'ecs-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/${findInventoryByType(resource?.type)?.service}/v2/clusters/services/${resource?.name}?region=${resource?.region}`,
    metrics: [
      {
        value: 'cpu',
        title: 'CPU utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'memory',
        title: 'Memory utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'reservation.cpu',
        title: 'CPU reservation (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'reservation.memory',
        title: 'Memory reservation (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })

      }
    ]
  },
  ECSService: {
    id: 'ECSService',
    title: 'ECS Services',
    shortTitle: 'ECS Services',
    type: 'AWS::ECS::Service',
    service: 'ecs',
    namespace: 'aws/ecs',
    icon: 'ecs-icon.svg',
    awsLink: (resource, parent) => `${generateLinkBase(resource)}/${findInventoryByType(resource?.type)?.service}/v2/clusters/services/${parent?.name}/${resource?.name}/health?region=${resource?.region}`,
    metrics: [
      {
        value: 'cpu',
        title: 'CPU utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'memory',
        title: 'Memory utilization (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'reservation.cpu',
        title: 'CPU reservation (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      },
      {
        value: 'reservation.memory',
        title: 'Memory reservation (%)',
        unit: '%',
        formatYTicks: value => formatPercentage({ value }),
        formatTooltip: value => formatPercentage({ value })
      }
    ]
  },
  ELBLoadBalancer: {
    id: 'ELBLoadBalancer',
    title: 'ELB Load Balancer',
    shortTitle: 'ELB Load Balancer',
    type: 'AWS::ElasticLoadBalancingV2::LoadBalancer',
    service: 'elb',
    namespace: 'aws/elb',
    icon: 'elb-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/ec2/home?region=${resource?.region}#LoadBalancer:loadBalancerArn=${resource?.arn}`,
    metrics: [
      {
        value: 'connections.active',
        title: 'Active connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'connections.new',
        title: 'New connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'processed.bytes',
        title: 'Data throughput',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0a' })
      },
      {
        value: 'request.count',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'dropped.invalid.headers',
        title: 'Dropped invalid headers',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  ELBApplicationLoadBalancer: {
    id: 'ELBApplicationLoadBalancer',
    title: 'ELB Application Load Balancer',
    shortTitle: 'ELB Application Load Balancer',
    type: 'AWS::ElasticLoadBalancingV2::ApplicationLoadBalancer',
    service: 'elb',
    namespace: 'aws/elb',
    icon: 'elb-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/ec2/home?region=${resource?.region}#LoadBalancer:loadBalancerArn=${resource?.arn}`,
    metrics: [
      {
        value: 'connections.active',
        title: 'Active connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'connections.new',
        title: 'New connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'processed.bytes',
        title: 'Data throughput',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0a' })
      },
      {
        value: 'request.count',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'dropped.invalid.headers',
        title: 'Dropped invalid headers',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }]
  },
  ELBGatewayLoadBalancer: {
    id: 'ELBGatewayLoadBalancer',
    title: 'ELB Gateway Load Balancer',
    shortTitle: 'ELB Gateway Load Balancer',
    type: 'AWS::ElasticLoadBalancingV2::GatewayLoadBalancer',
    service: 'elb',
    namespace: 'aws/elb',
    icon: 'elb-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/ec2/home?region=${resource?.region}#LoadBalancer:loadBalancerArn=${resource?.arn}`,
    metrics: [
      {
        value: 'connections.active',
        title: 'Active connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'connections.new',
        title: 'New connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'processed.bytes',
        title: 'Data throughput',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0a' })
      },
      {
        value: 'request.count',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'dropped.invalid.headers',
        title: 'Dropped invalid headers',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }]
  },
  ELBNetworkLoadBalancer: {
    id: 'ELBNetworkLoadBalancer',
    title: 'ELB Network Load Balancer',
    shortTitle: 'ELB Network Load Balancer',
    type: 'AWS::ElasticLoadBalancingV2::NetworkLoadBalancer',
    service: 'elb',
    namespace: 'aws/elb',
    icon: 'elb-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/ec2/home?region=${resource?.region}#LoadBalancer:loadBalancerArn=${resource?.arn}`,
    metrics: [
      {
        value: 'connections.active',
        title: 'Active connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'connections.new',
        title: 'New connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'processed.bytes',
        title: 'Data throughput',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0a' })
      },
      {
        value: 'request.count',
        title: 'Request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'dropped.invalid.headers',
        title: 'Dropped invalid headers',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }]
  },
  ELBTargetGroup: {
    id: 'ELBTargetGroup',
    title: 'ELB Target Group',
    shortTitle: 'ELB Target Group',
    type: 'AWS::ElasticLoadBalancingV2::TargetGroup',
    service: 'elb',
    namespace: 'aws/elb',
    icon: 'elb-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/ec2/home?region=${resource?.region}#TargetGroup:targetGroupArn=${resource?.arn}`,
    metrics: [
      {
        value: 'targets.response.code.2XX',
        title: '2XX requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'targets.response.code.3XX',
        title: '3XX requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'targets.response.code.4XX',
        title: '4XX requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'targets.response.code.5XX',
        title: '5XX requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'targets.response.time',
        title: 'Response time',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration

      }, // no unit
      {
        value: 'targets.healthy',
        title: 'Healthy targets',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'targets.unhealthy',
        title: 'Unhealthy targets',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  EventBridgeEventBus: {
    id: 'EventBridgeEventBus',
    title: 'EventBridge Event Buses',
    shortTitle: 'Event Buses',
    type: 'AWS::Events::EventBus',
    service: 'eventbridge',
    namespace: 'aws/events',
    icon: 'eventbridge-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/events/home?region=${resource?.region}#/eventbuses/${resource?.name}`,
    metrics: []
  },
  EventBridgeRule: {
    id: 'EventBridgeRule',
    title: 'EventBridge Rules',
    shortTitle: 'Rules',
    type: 'AWS::Events::Rule',
    service: 'eventbridge',
    namespace: 'aws/events',
    icon: 'eventbridge-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/events/home?region=${resource?.region}#/eventbuses/${resource?.name}`,
    metrics: [
      {
        value: 'invocations',
        title: 'Invocations',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'invocations.failed',
        title: 'Failed invocations',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'invocations.dlq',
        title: 'DeadLetter invocations',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'invocations.dlq.sent',
        title: 'Invocations sent to Deadletter Queue',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'invocations.dlq.failed',
        title: 'Invocations failed to be sent to Deadletter Queue',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'rules.throttled',
        title: 'Throttled events',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'rules.triggered',
        title: 'Triggered rules',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  KinesisAnalytics: {
    id: 'KinesisAnalytics',
    title: 'Kinesis Data Analytics',
    shortTitle: 'Kinesis Analytics',
    type: 'AWS::KinesisAnalyticsV2::Application',
    service: 'kinesis.analytics',
    namespace: 'aws/kinesisanalytics',
    icon: 'kinesis-analytics-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/kinesisanalytics/home?region=${resource?.region}#/application/${resource?.name}/details/monitoring`,
    metrics: [
      {
        value: 'cpu.utilization',
        title: 'CPU utilization (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })

      },
      {
        value: 'memory.heap.utilization',
        title: 'Memory utilization (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'uptime',
        title: 'Uptime (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'downtime',
        title: 'Downtime (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'restarts',
        title: 'Full restarts',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.records',
        title: 'Incoming records (total since restart)',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.records',
        title: 'Outgoing records (total since restart)',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.records.rate',
        title: 'Incoming records rate',
        unit: 'rps',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.records.rate',
        title: 'Outgoing records rate',
        unit: 'rps',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      { value: 'kpus', title: 'KPUs' },
      {
        value: 'checkpoint.size',
        title: 'Checkpoint size (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'checkpoint.duration',
        title: 'Checkpoint duration (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'checkpoint.failures',
        title: 'Failed checkpoints',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  KinesisDataStream: {
    id: 'KinesisDataStream',
    title: 'Kinesis Data Streams',
    shortTitle: 'Kinesis Streams',
    type: 'AWS::Kinesis::Stream',
    service: 'kinesis.data-streams',
    namespace: 'aws/kinesis',
    icon: 'kinesis-data-streams-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/kinesis/home?region=${resource?.region}#/streams/details/${resource?.name}/monitoring`,
    metrics: [
      {
        value: 'incoming.records',
        title: 'Incoming records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.bytes',
        title: 'Incoming bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'incoming.success',
        title: 'Successful incoming operations',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.records',
        title: 'Outgoing records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.bytes',
        title: 'Outgoing bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'outgoing.latency',
        title: 'Outgoing operation latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'outgoing.success',
        title: 'Successful outgoing records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.age',
        title: 'Outgoing last record age (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'throttles.read',
        title: 'Read throttles',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'throttles.write',
        title: 'Write throttles',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  KinesisFirehose: {
    id: 'KinesisFirehose',
    title: 'Kinesis Firehose Delivery Streams',
    shortTitle: 'Kinesis Firehose',
    type: 'AWS::KinesisFirehose::DeliveryStream',
    service: 'kinesis.firehose',
    namespace: 'aws/firehose',
    icon: 'kinesis-firehose-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/firehose/home?region=${resource?.region}#/streams/details/${resource?.name}/monitoring`,
    metrics: [
      {
        value: 'incoming.records',
        title: 'Incoming records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.bytes',
        title: 'Incoming bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'incoming.throttles',
        title: 'Incoming throttled records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.batch.records',
        title: 'Incoming record batches',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'incoming.batch.bytes',
        title: 'Incoming record batches (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'incoming.batch.latency',
        title: 'Incoming record batches latency (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'outgoing.elasticsearch.records',
        title: 'Outgoing Elasticsearch records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.elasticsearch.bytes',
        title: 'Outgoing Elasticsearch bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'outgoing.elasticsearch.success',
        title: 'Outgoing Elasticsearch successful records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.elasticsearch.age',
        title: 'Outgoing Elasticsearch oldest record age (seconds)',
        unit: 's',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'outgoing.redshift.records',
        title: 'Outgoing Redshift records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.redshift.bytes',
        title: 'Outgoing Redshift bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'outgoing.redshift.success',
        title: 'Successful outgoing Redshift commands',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.s3.records',
        title: 'Outgoing S3 records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.s3.bytes',
        title: 'Outgoing S3 bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'outgoing.s3.success',
        title: 'Successful outgoing S3 commands',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.s3.age',
        title: 'Oldest outgoing S3 record (seconds)',
        unit: 's',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'outgoing.splunk.records',
        title: 'Outgoing Splunk records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.splunk.bytes',
        title: 'Outgoing Splunk bytes',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'outgoing.splunk.success',
        title: 'Successful outgoing Splunk records',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'outgoing.splunk.age',
        title: 'Oldest outgoing Splunk record (seconds)',
        unit: 's',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      }
    ]
  },
  StepFunctions: {
    id: 'StepFunctions',
    title: 'Step Function State Machines',
    shortTitle: 'Step Functions',
    type: 'AWS::StepFunctions::StateMachine',
    service: 'stepfunctions',
    namespace: 'aws/stepfunctions',
    icon: 'stepfunctions-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/states/home?region=${resource?.region}#/statemachines/view/${resource?.arn}`,
    metrics: [
      {
        value: 'executions.duration',
        title: 'Execution Time (milliseconds)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'executions.throttles',
        title: 'Throttled executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'executions.aborted',
        title: 'Aborted executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'executions.failed',
        title: 'Failed executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'executions.started',
        title: 'Started executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'executions.succeeded',
        title: 'Successful executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'executions.timeouts',
        title: 'Timed out executions',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  RDSCluster: {
    id: 'RDSCluster',
    title: 'RDS DB Cluster',
    shortTitle: 'RDS Cluster',
    type: 'AWS::RDS::DBCluster',
    service: 'rds',
    namespace: 'aws/rds',
    icon: 'rds-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/rds/home?region=${resource?.region}#database:id=${resource?.name};is-cluster=true`,
    metrics: [
      {
        value: 'cpu.utilization',
        title: 'CPU utilization (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'database.connections',
        title: 'Database connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'read.latency',
        title: 'Read latency (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'write.latency',
        title: 'Write latency (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'replica.lag',
        title: 'Replica lag (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'network.receive.throughput',
        title: 'Network incoming (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'network.transmit.throughput',
        title: 'Network outgoing (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'burst.balance',
        title: 'Burst balance (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      }
    ]
  },
  RDSInstance: {
    id: 'RDSInstance',
    title: 'RDS DB Instance',
    shortTitle: 'RDS Instance',
    type: 'AWS::RDS::DBInstance',
    service: 'rds',
    namespace: 'aws/rds',
    icon: 'rds-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/rds/home?region=${resource?.region}#database:id=${resource?.name};is-cluster=false`,
    metrics: [
      {
        value: 'cpu.utilization',
        title: 'CPU utilization (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'freeable.memory',
        title: 'Freeable memory (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'free.storage.space',
        title: 'Free storage space (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'queue.depth',
        title: 'Queue  depth',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'database.connections',
        title: 'Database connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'read.latency',
        title: 'Read latency (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'read.operations',
        title: 'Read IOPS (count)',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'read.throughput',
        title: 'Read throughput (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'write.latency',
        title: 'Write latency (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'write.operations',
        title: 'Write IOPS (count)',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'write.throughput',
        title: 'Write throughput (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'replica.lag',
        title: 'Replica lag (ms)',
        unit: 'ms',
        formatTooltip: formatDuration,
        formatYTicks: formatDuration
      },
      {
        value: 'network.receive.throughput',
        title: 'Network incoming (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'network.transmit.throughput',
        title: 'Network outgoing (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'burst.balance',
        title: 'Burst balance (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      }
    ]
  },
  RDSProxy: {
    id: 'RDSProxy',
    title: 'RDS DB Proxy',
    shortTitle: 'RDS Proxy',
    type: 'AWS::RDS::DBProxy',
    service: 'rds',
    namespace: 'aws/rds',
    icon: 'rds-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/rds/home?region=${resource?.region}#proxy:id=${resource?.name}`,
    metrics: [
      {
        value: 'database.connections',
        title: 'Database connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'client.connections',
        title: 'Client connections',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'query.requests',
        title: 'Query requests',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  },
  OpenSearch: {
    id: 'OpenSearch',
    title: 'OpenSearch Domain',
    shortTitle: 'OpenSearch',
    type: 'AWS::OpenSearchService::Domain',
    service: 'opensearch',
    namespace: 'aws/es',
    icon: 'opensearch-icon.svg',
    awsLink: (resource) => `${generateLinkBase(resource)}/esv3/home?region=${resource?.region}#/opensearch/domains/${resource?.name}`,
    metrics: [
      {
        value: 'cluster.opensearch.requests',
        title: 'OpenSearch request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.elasticsearch.requests',
        title: 'ElasticSearch request count',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.requests.2xx',
        title: '2xx errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.requests.3xx',
        title: '3xx errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.requests.4xx',
        title: '4xx errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.requests.5xx',
        title: '5xx errors',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      { value: 'cluster.status.green', title: 'Green status' },
      { value: 'cluster.status.yellow', title: 'Yellow status' },
      { value: 'cluster.status.red', title: 'Red status' },
      {
        value: 'cluster.shards.active',
        title: 'Active shards',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.shards.unassigned',
        title: 'Unassigned shards',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.primary.shards.active',
        title: 'Active primary shards',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.nodes',
        title: 'Nodes',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.documents.searchable',
        title: 'Searchable documents',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.documents.deleted',
        title: 'Deleted documents',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.cpu.utilization',
        title: 'CPU utilization (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'cluster.cpu.credit.balance',
        title: 'CPU credit balance',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'cluster.free.storage.space',
        title: 'Free storage space (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'cluster.used.space',
        title: 'Used space (bytes)',
        unit: 'B',
        formatTooltip: (value) => formatBytes({ value }),
        formatYTicks: (value) => formatBytes({ value, format: '0' })
      },
      {
        value: 'cluster.index.writes.blocked',
        title: 'Blocked index writes',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      },
      {
        value: 'cluster.jvm.memory.pressure',
        title: 'JVM memory pressure (%)',
        unit: '%',
        formatTooltip: (value) => formatPercentage({ value }),
        formatYTicks: (value) => formatPercentage({ value, format: '0a' })
      },
      {
        value: 'cluster.dashboard.healthy.nodes',
        title: 'Healthy nodes',
        formatTooltip: (value) => formatThousands({ value }),
        formatYTicks: (value) => formatThousands({ value, format: '0a' })
      }
    ]
  }
}

export const INVENTORY_SERVICE_GROUPS = {
  lambda: {
    id: 'Lambda',
    title: 'AWS Lambda',
    service: 'lambda',
    icon: 'lambda-icon.svg',
    statistics: (collection) => ({
      functions: collection[INVENTORY_SERVICES.Lambda.type] || 0
    })
  },
  s3: {
    id: 'S3',
    title: 'AWS S3',
    service: 's3',
    icon: 's3-icon.svg',
    statistics: (collection) => ({
      buckets: collection[INVENTORY_SERVICES.S3.type] || 0
    })
  },
  sqs: {
    id: 'SQS',
    title: 'AWS SQS',
    service: 'sqs',
    icon: 'sqs-icon.svg',
    statistics: (collection) => ({
      queues: collection[INVENTORY_SERVICES.SQS.type] || 0
    })
  },
  sns: {
    id: 'SNS',
    title: 'AWS SNS',
    service: 'sns',
    icon: 'sns-icon.svg',
    statistics: (collection) => ({
      topics: collection[INVENTORY_SERVICES.SNS.type] || 0
    })
  },
  dynamodb: {
    id: 'DynamoDB',
    title: 'AWS DynamoDB',
    service: 'dynamodb',
    icon: 'dynamodb-icon.svg',
    statistics: (collection) => ({
      tables: collection[INVENTORY_SERVICES.DynamoDB.type] || 0
    })
  },
  'apigateway.rest': {
    id: 'APIGatewayRest',
    title: 'AWS Rest API Gateway',
    service: 'apigateway.rest',
    icon: 'apigateway-icon.svg',
    statistics: (collection) => ({
      gateways: collection[INVENTORY_SERVICES.APIGatewayRest.type] || 0
    })
  },
  'apigateway.http': {
    id: 'APIGatewayHttp',
    title: 'AWS HTTP API Gateway',
    service: 'apigateway.http',
    icon: 'apigateway-icon.svg',
    statistics: (collection) => ({
      gateways: collection[INVENTORY_SERVICES.APIGatewayHttp.type] || 0
    })
  },
  ecs: {
    id: 'ECSCluster',
    title: 'AWS ECS',
    service: 'ecs',
    icon: 'ecs-icon.svg',
    statistics: (collection) => ({
      clusters: collection[INVENTORY_SERVICES.ECSCluster.type] || 0,
      services: collection[INVENTORY_SERVICES.ECSService.type] || 0
    })
  },
  elb: {
    id: 'ELBLoadBalancer',
    title: 'AWS ELB',
    service: 'elb',
    icon: 'elb-icon.svg',
    statistics: (collection) => ({
      'load-balancers': collection[INVENTORY_SERVICES.ELBLoadBalancer.type] || 0,
      'target-groups': collection[INVENTORY_SERVICES.ELBTargetGroup.type] || 0
    })
  },
  eventbridge: {
    id: 'EventBridgeEventBus',
    title: 'AWS EventBridge',
    service: 'eventbridge',
    icon: 'eventbridge-icon.svg',
    statistics: (collection) => ({
      'event-buses': collection[INVENTORY_SERVICES.EventBridgeEventBus.type] || 0,
      rules: collection[INVENTORY_SERVICES.EventBridgeRule.type] || 0
    })
  },
  'kinesis.analytics': {
    id: 'KinesisAnalytics',
    title: 'AWS Kinesis Analytics',
    service: 'kinesis.analytics',
    icon: 'kinesis-analytics-icon.svg',
    statistics: (collection) => ({
      applications: collection[INVENTORY_SERVICES.KinesisAnalytics.type] || 0
    })
  },
  'kinesis.data-streams': {
    id: 'KinesisDataStream',
    title: 'AWS Kinesis Data Streams',
    service: 'kinesis.data-streams',
    icon: 'kinesis-data-streams-icon.svg',
    statistics: (collection) => ({
      'data streams': collection[INVENTORY_SERVICES.KinesisDataStream.type] || 0,
      'firehose delivery streams': collection[INVENTORY_SERVICES.KinesisFirehose.type] || 0
    })
  },
  'kinesis.firehose': {
    id: 'KinesisFirehose',
    title: 'AWS Kinesis Firehose',
    service: 'kinesis.firehose',
    icon: 'kinesis-firehose-icon.svg',
    statistics: (collection) => ({
      'data streams': collection[INVENTORY_SERVICES.KinesisDataStream.type] || 0,
      'firehose delivery streams': collection[INVENTORY_SERVICES.KinesisFirehose.type] || 0
    })
  },
  stepfunctions: {
    id: 'StepFunctions',
    title: 'AWS Step Functions',
    service: 'stepfunctions',
    icon: 'stepfunctions-icon.svg',
    statistics: (collection) => ({
      'step functions': collection[INVENTORY_SERVICES.StepFunctions.type] || 0
    })
  },
  rds: {
    id: 'RDS',
    title: 'AWS Relational Databases',
    service: 'rds',
    icon: 'rds-icon.svg',
    statistics: (collection) => ({
      'rds cluster': collection[INVENTORY_SERVICES.RDSCluster.type] || 0,
      'rds instance': collection[INVENTORY_SERVICES.RDSInstance.type] || 0,
      'rds proxy': collection[INVENTORY_SERVICES.RDSProxy.type] || 0
    })
  },
  opensearch: {
    id: 'OpenSearch',
    title: 'AWS OpenSearch',
    service: 'opensearch',
    icon: 'opensearch-icon.svg',
    statistics: (collection) => ({
      opensearch: collection[INVENTORY_SERVICES.OpenSearch.type] || 0
    })
  },
  appsync: {
    id: 'AppSync',
    title: 'AWS AppSync',
    service: 'appsync',
    icon: 'appsync-icon.svg',
    statistics: (collection) => ({
      appsync: collection[INVENTORY_SERVICES.AppSyncGraphQLApi.type] || 0
    })
  }
}

export const findInventoryByType = (type) => {
  if (!type) return
  return find(INVENTORY_SERVICES, service => service.type.toLowerCase() === type.toLowerCase() || service?.resourceType?.toLowerCase() === type.toLowerCase())
}

export const findInventoryById = (id) => {
  if (!id) return
  return find(INVENTORY_SERVICES, service => service.id.toLowerCase() === id.toLowerCase())
}

export const filterInventoryByNamespace = (namespace) => {
  if (!namespace) return
  return filter(INVENTORY_SERVICES, service => service.namespace.includes(namespace.toLowerCase()))
}

export const filterInventoryByService = (id) => {
  if (!id) return
  return filter(INVENTORY_SERVICES, service => service.service.toLowerCase() === id.toLowerCase())
}

export const getServiceByTypeRegex = (type) => {
  if (!type) return
  return type.split('::')[1].toLowerCase()
}
