import React, { useContext } from 'react'
import classnames from 'classnames'
import Item from './item'
import { ScrollContext } from 'components/layout/content'
import styles from './styles.module.less'

const Metrics = ({ items, tabNarrow, drawer = false, fixed, className }: {
  items: any,
  tabNarrow?: boolean,
  drawer?: boolean,
  fixed?: boolean,
  className?: string
}) => {
  const { width } = useContext(ScrollContext)
  if (!items) return null

  const extraClassNames = {
    [styles.fixed]: fixed || drawer,
    [className || '']: !!className
  }

  return (
    <div className={classnames(styles.metrics, extraClassNames)} style={fixed || drawer ? { width } : {}}>
      {items.map((item, index) => item
        ? <Item key={index} {...item} fixed={fixed || drawer} drawer={drawer} tabNarrow={tabNarrow} />
        : null
      )}
    </div>
  )
}

export default Metrics
