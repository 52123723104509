import React from 'react'
import Numeral from 'numeral'
import { Bar } from 'recharts'

import transform from 'containers/inventory/details/resource/charts/transform'
import colors from 'lib/colors'
import Empty from 'components/charts/empty'
import ChartContainer from 'components/charts/container'
import { LCU } from 'containers/inventory/details/resource/metrics-tabs/services/elb-application-load-balancer'

const ELBRequestsChart = ({ loading, data, height, span }) => {
  if (loading || !data) return null

  const metrics = transform({
    [LCU]: data.ELB_LOADBALANCER_LCU
  })

  if (!metrics.length) return <Empty height={height} />

  const tooltipFormatter = (value, name, props) => {
    return [Numeral(value).format('0.[00]a'), undefined]
  }

  return (
    <ChartContainer height={height}
      data={metrics}
      yTicksFormatting='0 a'
      span={span}
      tabKey={LCU}
      formatTab={value => `${Numeral(value).format('0.[00]a')}`}
      formatTooltip={tooltipFormatter}
    >
      <Bar dataKey={LCU} fill={colors('chart', 'primary')} name='Consumed LCUs' barSize={4} />
    </ChartContainer>
  )
}

export default ELBRequestsChart
