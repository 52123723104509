import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { formatDistance, parseISO } from 'date-fns'
import ReactGA from 'react-ga'

import { useAcceptInvitationQuery, useUserInvitationsQuery } from 'hooks/api'

import TableLayout from '../../components/table-layout'

import styles from './styles.module.less'

const InvitationsTable = () => {
  const { mutateAsync: acceptInvitation, isLoading: acceptInvitationLoading } = useAcceptInvitationQuery()
  const { data: invitations, isLoading } = useUserInvitationsQuery()

  const [tableData, setTableData] = useState([])

  const onClick = val => {
    const { id, email, token } = val
    try {
      acceptInvitation({
        invitationId: id,
        payload: {
          email,
          token
        }
      })
      ReactGA.event({ category: 'Invitation', action: 'Invitation accepted', label: email.replace('@', '.at.') })
    } catch (err) { return console.log(err) }
  }

  const columns = [{
    title: 'Organization',
    key: 'organization',
    sorter: (a, b) => a.name.localeCompare(b.name),
    sortDirections: ['descend', 'ascend'],
    render: val => val.organization.name
  }, {
    title: 'Expiration',
    dataIndex: 'expiresAt',
    key: 'expiresAt',
    render: val => <span>In {formatDistance(parseISO(val), new Date())}</span>
  }, {
    title: 'Actions',
    key: 'actions',
    render: val => <Button
      type='link'
      size='small'
      loading={acceptInvitationLoading}
      className={styles.action}
      onClick={() => onClick(val)}
    >Accept</Button>
  }]

  useEffect(() => {
    if (!invitations) return
    setTableData(invitations.map(invitation => ({ ...invitation, key: invitation.id })))
  }, [invitations])

  return (
    <TableLayout
      columns={columns}
      data={tableData}
      tableTitle={'invitations'}
      loading={isLoading}
      locale={{ emptyText: 'No invitations' }}
    />
  )
}

export default InvitationsTable
