import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { Tabs } from 'antd'

import TabTitle from './tab-title'
import Prices from './prices'
import TierFeatures from './tier-features'
import OnDemandSubscription from './subscription/on-demand'
import ReservedSubscription from './subscription/reserved'
import PaymentInfo from 'containers/settings/billing/payment/payment-methods'

import styles from './styles.module.less'

const Payment = ({ visible } = {}) => {
  if (!visible) return null
  return (
    <PaymentInfo className={styles.topMargin} />
  )
}
const ProTierInfo = ({ price, usage, closeModal } = {}) => {
  const paymentMethods = useSelector(state => get(state, 'billing.payment.methods'))
  const hasPaymentMethod = paymentMethods.length > 0

  return (
    <>
      <TierFeatures tier='pro' />
      <Tabs
        type='card'
        tabBarGutter={24}
        tabBarStyle={{
          display: 'flex',
          justifyContent: 'center'
        }}
        items={[
          {
            key: 'reserved',
            label: <TabTitle title='Reserved' tag='green' tagTitle='cheaper' />,
            children: (
              <>
                <Prices data={price.reserved} isAnnual />
                <ReservedSubscription price={price.reserved} usage={usage} onOk={closeModal} />
              </>
            )
          },
          {
            key: 'onDemand',
            label: <TabTitle title='On demand' tag='geekblue' tagTitle='flexible' />,
            children: (
              <>
                <Prices data={price.onDemand} />
                <OnDemandSubscription price={price.onDemand} usage={usage} onOk={closeModal} />

              </>
            )
          }
        ]}
      />
      <Payment visible={!hasPaymentMethod} />
    </>
  )
}

export default ProTierInfo
