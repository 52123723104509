import { TIMESCALE_METRICS } from 'lib/metric-constants'

const mutations = ({ history, alarm, groups, routes, updateAlarm, deleteAlarm, addAlarmTarget, deleteAlarmTarget, queryClient }) => {
  const prefixTarget = (target) => {
    if (!(groups || []).map(group => group.id).includes(target)) return target
    return `group:${target}`
  }

  return {
    remove: (alarmId) => {
      deleteAlarm(alarmId)
      history.push(routes.alarms.url())
    },
    update: {
      title: (value) => {
        updateAlarm({ name: value })
      },
      status: () => {
        updateAlarm({ disabled: !alarm.disabled })
      },
      condition: (changes) => {
        if (changes.targets) return
        const payload = { ...changes }

        if (['dashbird/lambda', 'aws/lambda'].includes(alarm.namespace) && changes.metric) {
          payload.namespace = TIMESCALE_METRICS.includes(changes.metric) ? 'dashbird/lambda' : 'aws/lambda'
        }

        if (changes.threshold) payload.threshold = parseInt(changes.threshold)

        updateAlarm(payload)
      }
    },
    targets: {
      add: (target) => {
        addAlarmTarget(prefixTarget(target))
      },
      remove: (target) => {
        deleteAlarmTarget(prefixTarget(target))
      }
    }
  }
}

export default mutations
