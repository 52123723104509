import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useOrganization } from 'hooks/context/organization-context'
import Layout from 'features/layout'
import ConfirmContainer from 'containers/auth/confirm'
import Onboarding from 'containers/onboarding'
import DashboardContainer from 'containers/dashboard'
import SettingsContainer from 'containers/settings'
import Alarms from 'containers/alarms'
import Dashboards from 'containers/dashboards'
import Errors from 'containers/errors'
import Inventory from 'containers/inventory'
import InsightsContainer from 'containers/insights'
import ResourceGroups from 'containers/resource-groups'
import GlobalSearchContainer from 'containers/search'
import ScheduledSearch from 'containers/scheduled-search'
import accountRoutes from '../account-routes'
import accountRedirects from '../account-redirects'

const AuthorizedRoutes = () => {
  const location = useLocation()
  const { account: currentAccount } = useOrganization()

  const routes = [
    { path: '/settings', component: SettingsContainer }
  ]

  const redirects = [
    { path: '/alarms/:alarmId?', component: Alarms },
    { path: '/dashboards/:dashboardId?', component: Dashboards },
    { path: '/errors/:errorId?', component: Errors },
    { path: '/inventory/:resourceId?', component: Inventory },
    { path: '/insights/:ruleId?', component: InsightsContainer },
    { path: '/resource-groups', component: ResourceGroups },
    { path: '/search/:queryId?', component: GlobalSearchContainer },
    { path: '/scheduled-search/:queryId?', component: ScheduledSearch }
  ]

  const render = (props) => (
    <Layout {...props}>
      <Switch>
        <Route path='/auth/confirm' component={ConfirmContainer} />
        <Redirect from='/auth/*' to='/' />
        <Redirect from='/invite' to='/settings/invitations' />
        <Redirect from='/billing' to='/settings/subscriptions' exact />
        <Redirect from='/settings/billing' to='/settings/subscriptions' exact />
        <Redirect from='/:accountId/well-architected-lens' to='/:accountId/insights' />

        <Route path='/onboarding' component={Onboarding} />
        <Route path='/onboarding/:token' component={Onboarding} exact />

        {accountRoutes(currentAccount, routes)}
        {accountRedirects(currentAccount, redirects, location.search)}

        <Route exact path='/' component={DashboardContainer} />
        <Route exact path={`/${currentAccount?.id}`} component={DashboardContainer} />

      </Switch>
    </Layout>
  )

  return (
    <Route path='/:accountId?' render={render} />
  )
}

export default AuthorizedRoutes
