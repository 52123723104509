import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { flatten, sortBy } from 'lodash'

import { useAccountRoutes } from 'containers/routes'
import { SplitViewContext } from 'features/layout/split-pane'
import useQueryParams from 'lib/hooks/use-query-params'
import { useErrorsQuery, useAllResourcesQuery, useDelegationsQuery, useResourceGroupsQuery } from 'hooks/api'
import { PermissionsGate, SCOPES } from 'features/permissions-gate'
import { filterListFromQuery } from 'features/side-list/filter/filter-by-query-params'
import List from 'features/side-list'
import ListEmpty from 'features/side-list/empty'
import { NameAlphabetical, OccurrenceCountLastDay, OccurrenceLast, Muted } from 'features/side-list/sorter'
import ListSearch from 'features/side-list/search'
import ListHeader from 'features/side-list/header'

import Item from './item'
import { ERRORS_FILTERS } from './filters'
import { ErrorMetricsContext } from '../index'
import HeaderActions from './header-actions'

const ErrorsList = () => {
  const history = useHistory()
  const routes = useAccountRoutes()

  const { errorId: selected } = useParams()
  const { search } = useLocation()
  const { getValue: groupInUrl } = useQueryParams('group', [])

  const { dashboardStart, dashboardEnd } = useContext(ErrorMetricsContext)
  const { handleListClose } = useContext(SplitViewContext)

  const sorters = [OccurrenceLast, OccurrenceCountLastDay, NameAlphabetical, Muted]

  const [sorter, setSorter] = useState(sorters[0])
  const [bulkSelected, setBulkSelected] = useState([])
  const [searchItems, setSearchItems] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const { isLoading: loadingErrors, isFetching, data: errorsData } = useErrorsQuery({ start: dashboardStart, end: dashboardEnd })
  const { isLoading: loadingResources, isSuccess: resourcesSuccess } = useAllResourcesQuery()
  const { isLoading: loadingDelegations, isSuccess: delegationsSuccess } = useDelegationsQuery()

  const { data: resourceGroups } = useResourceGroupsQuery()

  const onSelect = (error) => {
    history.push({ pathname: routes.errors.error.url({ errorId: error.id }), search })
    handleListClose()
  }

  const onBulkSelect = (id, value) => {
    if (value) {
      setBulkSelected(bulkSelected.concat(id))
    } else {
      setBulkSelected(bulkSelected.filter(item => item !== id))
      setSelectAll(false)
    }
  }

  const onSorterChange = (key) => {
    setSorter(sorters.find(sorter => sorter.key === key))
  }

  const sortErrors = () => {
    setSearchItems(sorter?.sort ? sorter.sort(searchItems) : sortBy(searchItems, sorter.apply))
  }

  const searchErrors = () => {
    if (!errorsData || isFetching) return
    const groupsResourceIds = flatten(groupInUrl?.map(id => resourceGroups?.find(item => item.id === id)?.resources))

    const results = filterListFromQuery(errorsData, ERRORS_FILTERS, search, groupsResourceIds)

    if (bulkSelected?.length !== 0) {
      const bulkIds = bulkSelected?.filter(bulkId => results?.map(error => error.id)?.includes(bulkId))
      setBulkSelected(bulkIds)
      if (bulkIds?.length === 0) setSelectAll(false)
    }

    setSearchItems(sorter?.sort ? sorter.sort(results) : sortBy(results, sorter.apply))
  }

  useEffect(sortErrors, [sorter])
  useEffect(searchErrors, [search, delegationsSuccess, resourcesSuccess, isFetching, errorsData])

  return (
    <PermissionsGate
      scopes={[SCOPES.canEdit]}
      errorProps={{ onBulkSelect: null, itemHeight: 61.6 }}
    >
      <List
        search={<ListSearch />}
        header={
          <ListHeader
            sorters={sorters}
            selectedSorter={sorter.key}
            onSorterChange={onSorterChange}
            total={errorsData?.length}
            searchTotal={searchItems?.length}
            overviewURL={routes.errors.url()}
            left={<HeaderActions selectAll={selectAll} setSelectAll={setSelectAll} searchItems={searchItems} bulkSelected={bulkSelected} setBulkSelected={setBulkSelected} />}
            filters={ERRORS_FILTERS.reduce((acc, item) => {
              acc[item.name] = item.key
              return acc
            }, {})}
            view='errors'
          />
        }
        loading={loadingErrors || !errorsData}
        loadingMetrics={loadingResources || loadingDelegations}
        items={searchItems}
        selected={selected}
        renderItem={Item}
        bulkSelected={bulkSelected}
        onSelect={onSelect}
        onBulkSelect={onBulkSelect}
        renderEmpty={<ListEmpty title='No errors found' />}
        itemHeight={64.8}
      />
    </PermissionsGate>
  )
}

export default ErrorsList
