import { useQuery } from 'react-query'
import { isEqual } from 'lodash'
import querystring from 'query-string'
import memoizeOne from 'memoize-one'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { ERRORS_LIST } from './index'
import { eventPaths as paths } from '../index'
import { useAllResourcesQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'

const mapResourcesToErrors = (errors, resources) => {
  if (!errors) return undefined
  if (!resources) return errors

  return errors?.map(error => {
    return {
      ...error,
      resource: resources[error.resource]
    }
  })
}

const memoizedResponse = memoizeOne(mapResourcesToErrors, isEqual)

const getErrors = async (account, status = 'open', filters) => {
  const query = querystring.stringify(omitEmpty({ status, type: 'error', ...filters }))
  const data = await apiFetch(paths.filter(paths.events(account), query))
  return data?.data
}

export const useResourceErrorsQuery = (query) => {
  const { account } = useOrganization()

  const { data: resources } = useAllResourcesQuery()

  const queryOpts = useQuery(
    [...ERRORS_LIST, query && query, account?.id],
    () => getErrors(account?.id, 'open', query),
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 5 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, resources)

  return {
    ...queryOpts,
    data
  }
}
