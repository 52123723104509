import { useEffect, useMemo, useState } from 'react'
import { useEventsBatchQuery } from 'hooks/api'

import { WIDGET_KIND } from 'lib/dashboards-constants'
import { getEventsKey } from 'lib/dashboards-helpers'

const getPayloads = (widgets, start, end) => {
  if (!widgets) return []

  const eventsWidgets = widgets?.filter(widget => widget.kind === WIDGET_KIND.EVENTS)

  return eventsWidgets.reduce((accumulator, widget) => {
    const payloads = widget.definition.type.map((type) => ({
      id: getEventsKey(widget, type),
      payload: {
        type,
        filters: {
          // LOAD ALL EVENTS AT ONCE, THEN FILTER WITHIN WIDGET
          //...(widget.definition?.targets?.length && { resource: widget.definition.targets[0] }),
          //start: getUnixTime(start) * 1000,
          //end: getUnixTime(end) * 1000,
        }
      }
    }))

    accumulator.push(...payloads)

    return accumulator
  }, [])
}

const useEventsFetcher = ({ widgets, start, end, refreshInterval }) => {
  const [events, setEvents] = useState([])

  const payloads = useMemo(() => getPayloads(widgets, start, end), [widgets, start, end])
  const data = useEventsBatchQuery(payloads, refreshInterval, start, end)

  const loading = useMemo(() => data.some(item => item.isLoading), [data])
  const fetching = useMemo(() => data.some(item => item.isFetching), [data])

  const refetch = (e) => {
    e?.stopPropagation()
    data.map(item => item?.refetch())
  }

  const formatData = () => {
    if (loading || fetching) return

    const mappedEvents = data?.reduce((acc, item) => {
      acc[item?.data?.id] = item.data
      return acc
    }, {})

    setEvents(mappedEvents)
  }

  useEffect(formatData, [payloads, loading, fetching])

  return { data: events, loading, fetching, refetch }
}

export default useEventsFetcher
